@font-face {
  font-family: 'fontello';
  src: url('../font/fontello.eot?46098763');
  src: url('../font/fontello.eot?46098763#iefix') format('embedded-opentype'),
       url('../font/fontello.woff?46098763') format('woff'),
       url('../font/fontello.ttf?46098763') format('truetype'),
       url('../font/fontello.svg?46098763#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../font/fontello.svg?46098763#fontello') format('svg');
  }
}
*/
 
 [class^="fontello-icon-"]:before, [class*=" fontello-icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
 
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */
 
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
     
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
 
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;
 
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
 
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}
 
.fontello-icon-paypal:before { content: '\e87f'; } /* '' */
.fontello-icon-android:before { content: '\e880'; } /* '' */
.fontello-icon-plus-1:before { content: '\e86f'; } /* '' */
.fontello-icon-gplus:before { content: '\e881'; } /* '' */
.fontello-icon-plus:before { content: '\e80d'; } /* '' */
.fontello-icon-minus-1:before { content: '\e870'; } /* '' */
.fontello-icon-minus:before { content: '\e80e'; } /* '' */
.fontello-icon-html5:before { content: '\e883'; } /* '' */
.fontello-icon-ie:before { content: '\e882'; } /* '' */
.fontello-icon-call:before { content: '\e884'; } /* '' */
.fontello-icon-grooveshark:before { content: '\e885'; } /* '' */
.fontello-icon-spotify:before { content: '\e886'; } /* '' */
.fontello-icon-google:before { content: '\e887'; } /* '' */
.fontello-icon-youtube:before { content: '\e888'; } /* '' */
.fontello-icon-email:before { content: '\e889'; } /* '' */
.fontello-icon-itunes:before { content: '\e88a'; } /* '' */
.fontello-icon-left-big:before { content: '\e83b'; } /* '' */
.fontello-icon-left:before { content: '\e872'; } /* '' */
.fontello-icon-up:before { content: '\e874'; } /* '' */
.fontello-icon-up-big:before { content: '\e839'; } /* '' */
.fontello-icon-right:before { content: '\e873'; } /* '' */
.fontello-icon-right-big:before { content: '\e83a'; } /* '' */
.fontello-icon-down-big:before { content: '\e83c'; } /* '' */
.fontello-icon-down:before { content: '\e871'; } /* '' */
.fontello-icon-home-1:before { content: '\e85a'; } /* '' */
.fontello-icon-home:before { content: '\e800'; } /* '' */
.fontello-icon-pause-1:before { content: '\e876'; } /* '' */
.fontello-icon-pause:before { content: '\e829'; } /* '' */
.fontello-icon-fast-fw:before { content: '\e82d'; } /* '' */
.fontello-icon-fast-bw:before { content: '\e82c'; } /* '' */
.fontello-icon-stop:before { content: '\e828'; } /* '' */
.fontello-icon-up-dir:before { content: '\e843'; } /* '' */
.fontello-icon-play-1:before { content: '\e875'; } /* '' */
.fontello-icon-play-3:before { content: '\e827'; } /* '' */
.fontello-icon-right-dir:before { content: '\e841'; } /* '' */
.fontello-icon-down-dir:before { content: '\e853'; } /* '' */
.fontello-icon-left-dir:before { content: '\e842'; } /* '' */
.fontello-icon-star-3:before { content: '\e87d'; } /* '' */
.fontello-icon-star-2:before { content: '\e86c'; } /* '' */
.fontello-icon-star:before { content: '\e803'; } /* '' */
.fontello-icon-star-1:before { content: '\e858'; } /* '' */
.fontello-icon-star-empty:before { content: '\e804'; } /* '' */
.fontello-icon-star-empty-1:before { content: '\e859'; } /* '' */
.fontello-icon-th-list-1:before { content: '\e87e'; } /* '' */
.fontello-icon-th-list:before { content: '\e80b'; } /* '' */
.fontello-icon-heart-empty:before { content: '\e802'; } /* '' */
.fontello-icon-heart:before { content: '\e801'; } /* '' */
.fontello-icon-music:before { content: '\e836'; } /* '' */
.fontello-icon-cog:before { content: '\e822'; } /* '' */
.fontello-icon-attention:before { content: '\e81f'; } /* '' */
.fontello-icon-attention-1:before { content: '\e862'; } /* '' */
.fontello-icon-cog-alt:before { content: '\e823'; } /* '' */
.fontello-icon-pencil:before { content: '\e85f'; } /* '' */
.fontello-icon-ok:before { content: '\e86d'; } /* '' */
.fontello-icon-cancel:before { content: '\e80c'; } /* '' */
.fontello-icon-cancel-1:before { content: '\e86e'; } /* '' */
.fontello-icon-cw:before { content: '\e879'; } /* '' */
.fontello-icon-list-add:before { content: '\e86a'; } /* '' */
.fontello-icon-list:before { content: '\e86b'; } /* '' */
.fontello-icon-warning:before { content: '\e897'; } /* '' */
.fontello-icon-thumbs-up-2:before { content: '\e893'; } /* '' */
.fontello-icon-thumbs-down-2:before { content: '\e894'; } /* '' */
.fontello-icon-tags-1:before { content: '\e892'; } /* '' */
.fontello-icon-tag-1:before { content: '\e891'; } /* '' */
.fontello-icon-star-empty-2:before { content: '\e88f'; } /* '' */
.fontello-icon-basket:before { content: '\e89a'; } /* '' */
.fontello-icon-shuffle-1:before { content: '\e89c'; } /* '' */
.fontello-icon-play:before { content: '\e89d'; } /* '' */
.fontello-icon-pause-2:before { content: '\e89e'; } /* '' */
.fontello-icon-off:before { content: '\e8a3'; } /* '' */
.fontello-icon-list-3:before { content: '\e8a2'; } /* '' */
.fontello-icon-fast-forward:before { content: '\e89f'; } /* '' */
.fontello-icon-fast-backward:before { content: '\e8a0'; } /* '' */
.fontello-icon-facebook-1:before { content: '\e8a6'; } /* '' */
.fontello-icon-eye-1:before { content: '\e890'; } /* '' */
.fontello-icon-credit-card:before { content: '\e8a5'; } /* '' */
.fontello-icon-comment-alt:before { content: '\e896'; } /* '' */
.fontello-icon-asterisk:before { content: '\e8a4'; } /* '' */
.fontello-icon-headphones-1:before { content: '\e89b'; } /* '' */
.fontello-icon-align-justify-1:before { content: '\e8a1'; } /* '' */
.fontello-icon-comment-2:before { content: '\e895'; } /* '' */
.fontello-icon-cogs:before { content: '\e899'; } /* '' */
.fontello-icon-cog-1:before { content: '\e898'; } /* '' */
.fontello-icon-eye:before { content: '\e838'; } /* '' */
.fontello-icon-tag:before { content: '\e812'; } /* '' */
.fontello-icon-tags:before { content: '\e813'; } /* '' */
.fontello-icon-retweet:before { content: '\e81a'; } /* '' */
.fontello-icon-comment:before { content: '\e81b'; } /* '' */
.fontello-icon-comment-1:before { content: '\e860'; } /* '' */
.fontello-icon-chat:before { content: '\e81c'; } /* '' */
.fontello-icon-chat-1:before { content: '\e861'; } /* '' */
.fontello-icon-trash:before { content: '\e820'; } /* '' */
.fontello-icon-down-open:before { content: '\e83d'; } /* '' */
.fontello-icon-left-open:before { content: '\e83e'; } /* '' */
.fontello-icon-right-open:before { content: '\e83f'; } /* '' */
.fontello-icon-up-open:before { content: '\e840'; } /* '' */
.fontello-icon-arrows-cw:before { content: '\e826'; } /* '' */
.fontello-icon-left-open-mini:before { content: '\e869'; } /* '' */
.fontello-icon-to-end-alt:before { content: '\e82a'; } /* '' */
.fontello-icon-right-open-mini:before { content: '\e868'; } /* '' */
.fontello-icon-to-start-alt:before { content: '\e82b'; } /* '' */
.fontello-icon-up-open-mini:before { content: '\e867'; } /* '' */
.fontello-icon-down-open-big:before { content: '\e866'; } /* '' */
.fontello-icon-list-2:before { content: '\e877'; } /* '' */
.fontello-icon-left-open-big:before { content: '\e865'; } /* '' */
.fontello-icon-right-open-big:before { content: '\e864'; } /* '' */
.fontello-icon-up-open-big:before { content: '\e863'; } /* '' */
.fontello-icon-align-justify:before { content: '\e830'; } /* '' */
.fontello-icon-list-1:before { content: '\e82f'; } /* '' */
.fontello-icon-equalizer:before { content: '\e87b'; } /* '' */
.fontello-icon-spin3:before { content: '\e845'; } /* '' */
.fontello-icon-spin4:before { content: '\e846'; } /* '' */
.fontello-icon-menu:before { content: '\e821'; } /* '' */
.fontello-icon-comment-empty:before { content: '\e81d'; } /* '' */
.fontello-icon-chat-empty:before { content: '\e81e'; } /* '' */
.fontello-icon-download-cloud:before { content: '\e818'; } /* '' */
.fontello-icon-upload-cloud:before { content: '\e819'; } /* '' */
.fontello-icon-angle-left:before { content: '\e84f'; } /* '' */
.fontello-icon-angle-right:before { content: '\e850'; } /* '' */
.fontello-icon-angle-up:before { content: '\e851'; } /* '' */
.fontello-icon-angle-down:before { content: '\e852'; } /* '' */
.fontello-icon-desktop:before { content: '\e857'; } /* '' */
.fontello-icon-laptop:before { content: '\e856'; } /* '' */
.fontello-icon-tablet:before { content: '\e855'; } /* '' */
.fontello-icon-mobile:before { content: '\e854'; } /* '' */
.fontello-icon-smile:before { content: '\e833'; } /* '' */
.fontello-icon-frown:before { content: '\e834'; } /* '' */
.fontello-icon-meh:before { content: '\e835'; } /* '' */
.fontello-icon-help:before { content: '\e80f'; } /* '' */
.fontello-icon-info:before { content: '\e810'; } /* '' */
.fontello-icon-attention-alt:before { content: '\e847'; } /* '' */
.fontello-icon-mic:before { content: '\e848'; } /* '' */
.fontello-icon-mute:before { content: '\e849'; } /* '' */
.fontello-icon-ellipsis:before { content: '\e832'; } /* '' */
.fontello-icon-ellipsis-vert:before { content: '\e831'; } /* '' */
.fontello-icon-thumbs-up-alt:before { content: '\e816'; } /* '' */
.fontello-icon-thumbs-down-alt:before { content: '\e817'; } /* '' */
.fontello-icon-facebook:before { content: '\e88b'; } /* '' */
.fontello-icon-facebook-rect:before { content: '\e88c'; } /* '' */
.fontello-icon-twitter:before { content: '\e88d'; } /* '' */
.fontello-icon-twitter-bird:before { content: '\e88e'; } /* '' */
.fontello-icon-picture:before { content: '\e809'; } /* '' */
.fontello-icon-globe:before { content: '\e82e'; } /* '' */
.fontello-icon-videocam:before { content: '\e807'; } /* '' */
.fontello-icon-headphones:before { content: '\e84d'; } /* '' */
.fontello-icon-video:before { content: '\e808'; } /* '' */
.fontello-icon-thumbs-up:before { content: '\e814'; } /* '' */
.fontello-icon-thumbs-up-1:before { content: '\e85d'; } /* '' */
.fontello-icon-thumbs-down:before { content: '\e815'; } /* '' */
.fontello-icon-thumbs-down-1:before { content: '\e85e'; } /* '' */
.fontello-icon-user:before { content: '\e805'; } /* '' */
.fontello-icon-users:before { content: '\e806'; } /* '' */
.fontello-icon-cd:before { content: '\e844'; } /* '' */
.fontello-icon-cd-1:before { content: '\e87c'; } /* '' */
.fontello-icon-attach:before { content: '\e811'; } /* '' */
.fontello-icon-upload:before { content: '\e85c'; } /* '' */
.fontello-icon-download:before { content: '\e85b'; } /* '' */
.fontello-icon-camera:before { content: '\e80a'; } /* '' */
.fontello-icon-shuffle:before { content: '\e825'; } /* '' */
.fontello-icon-loop-alt:before { content: '\e87a'; } /* '' */
.fontello-icon-loop:before { content: '\e878'; } /* '' */
.fontello-icon-volume-off:before { content: '\e84a'; } /* '' */
.fontello-icon-volume-down:before { content: '\e84b'; } /* '' */
.fontello-icon-volume-up:before { content: '\e84c'; } /* '' */
.fontello-icon-search:before { content: '\e837'; } /* '' */
.fontello-icon-wrench:before { content: '\e824'; } /* '' */
.fontello-icon-block:before { content: '\e84e'; } /* '' */