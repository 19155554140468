    .infiniteListInnerContainer{
        background-color: inherit;
        height: 0%;
        width: 100%;
        overflow: visible;
        position: relative;
    }
    
    .infiniteListOuterContainer{
        background-color: inherit;
        overflow-y: auto;
        overflow-x: hidden;
        -webkit-overflow-scrolling: touch;
        position: relative;
        width: 100%;
        height: 100%;
    }
    
.infiniteListInnerContainer > div {
	    background-color: inherit;
	    position: absolute;
        width: 100%;
        left : 0px;
        right: 0px;
        -webkit-transform: translateZ(0);
        
        
	    display: flex;
	    justify-content: center;
	    align-items: center;
	    flex-direction: row;

	    display: -webkit-flex;
	    -webkit-justify-content: center;
	    -webkit-align-items: center;
	    -webkit-flex-direction: row;
}
