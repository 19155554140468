.mention_name{
	font-size:16px;
	}
.mention_username{
	font-weight:400;
	font-size: 16px;
	color:#fff;
	}
.mention_image{
	float: left;
	margin-right: 5px;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	border-radius: 3px;
	width: 20px;
	height: 20px;
	}
.active .mention_username{
	color:#fff;
	}