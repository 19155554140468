@font-face {
  font-family: 'icomoon-trebble-logo';
  src:  url('fonts/icomoon.eot?6a9c60');
  src:  url('fonts/icomoon.eot?6a9c60#iefix') format('embedded-opentype'),
  url('fonts/icomoon.ttf?6a9c60') format('truetype'),
  url('fonts/icomoon.woff?6a9c60') format('woff'),
  url('fonts/icomoon.svg?6a9c60#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-trebbleLogo"], [class*=" icon-trebbleLogo"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon-trebble-logo' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-trebbleLogo:before {
  font-family: 'icomoon-trebble-logo' !important;
  content: "\e900";
  width: auto;
}
