/* phonegap quirks */

@tailwind base;
@tailwind components;
@tailwind utilities;


/* Root variables for consistent theming */
:root {
    /* Core Brand Colors */
    --trebble-primary-rgb: 233, 96, 93;
   
    /* Blues */
    --color-blue-300: #1389f0;
    --color-blue-400: #2980b9;
    --color-blue-450: #509bf5;
    --color-blue-500: #62b8e3;
    --color-blue-600: #3b5998;
    /* Facebook blue */
   
    /* Greens */
    --color-green-400: #2ecc71;
    --color-green-500: #27ae60;
    --color-green-600: #48af4a;
   
    /* Purples */
    --color-purple-300: #9c4df9;
    --color-purple-450: #7062cf;
    --color-purple-500: #8e44ad;
    --color-purple-550: #9b59b6;
    --color-purple-700: #730886;
   
    /* Grays - adding to existing gray scale */
    --color-gray-100: #eeeeee;
    /* lightest - for backgrounds, borders */
    --color-gray-150: #dddddd;
    /* rgb(221, 221, 221) */
    --color-gray-200: #e5e5e5;
    /* your existing --color-gray-light */
    --color-gray-300: #cccccc;
    /* your existing --color-disabled */
    /* New suggested variable */
    --color-gray-350: #c0c0c7;
    /* rgb(192, 192, 199) - sits between 300 and 400 */
    --color-gray-400: #bdc3c7;
    --color-gray-500: #919191;
    --color-gray-550: #898686;
    --color-gray-600: #6d6b6b;
    --color-gray-700: #595959;
    --color-gray-750: #aba7a7;
    --color-gray-800: #a8aeb9;
    --color-gray-850: #222222;
    /* Very dark gray */
    --color-gray-900: #444444;
    /* your existing --color-gray-dark */
   
    /* Grays */
    /* New variables to create (most used first) */
    --bg-warm: #fbdfdf;
    /* Used 10 times - warm background */
    --color-error-bright: #ff3c4c;
    /* Used 2 times - bright error */
   
    /* Single-use colors that need variables (if semantically important) */
    --color-blue-gradient: #6686df;
    --color-pink-gradient: #ec4c7a;
    --color-purple-dark: #af2896;
    --color-pink-light: #fdd0d0;
    --color-warning-bright: #ffdf65;
   
    /* Black with opacity */
    --color-black-0: rgba(0, 0, 0, 0);
    --color-black-5: rgba(0, 0, 0, 0.05);
    --color-black-10: rgba(0, 0, 0, 0.1);
    --color-black-20: rgba(0, 0, 0, 0.2);
    --color-black-30: rgba(0, 0, 0, 0.3);
    --color-black-40: rgba(0, 0, 0, 0.4);
    --color-black-50: rgba(0, 0, 0, 0.5);
    --color-black-60: rgba(0, 0, 0, 0.6);
    --color-black-70: rgba(0, 0, 0, 0.7);
    --color-black-80: rgba(0, 0, 0, 0.8);
    --color-black-100: rgba(0, 0, 0, 1);
   
    /* white with opacity */
    --color-white-0: rgba(255, 255, 255, 0);
    --color-white-5: rgba(255, 255, 255, 0.05);
    --color-white-10: rgba(255, 255, 255, 0.1);
    --color-white-20: rgba(255, 255, 255, 0.2);
    --color-white-30: rgba(255, 255, 255, 0.3);
    --color-white-40: rgba(255, 255, 255, 0.4);
    --color-white-50: rgba(255, 255, 255, 0.5);
    --color-white-60: rgba(255, 255, 255, 0.6);
    --color-white-70: rgba(255, 255, 255, 0.7);
    --color-white-80: rgba(0255, 255, 255, 0.8);
    --color-white-90: rgba(0255, 255, 255, 0.9);
    --color-white-100: rgba(255, 255, 255, 1);
   
    /* Box Shadows */
    --shadow-color-light: rgba(74, 74, 74, 0.05);
   
    --color-purple-400: #800080;
    /* standard purple */
    --color-purple-500: #9c27b0;
    /* brighter purple */
    --color-purple-600: #6a3093;
    /* deep purple */
   
    /* Colors */
    --color-white: #fff;
    --color-black: #111;
   
    /* Brand Colors */
    --trebble-primary: #e9605d;
    --trebble-primary-dark: #ba4c4a;
    --trebble-primary-light: #eb6f6d;
    --trebble-secondary: #19b5fe;
    --trebble-blue: #275ef5;
    /* Purple/Brand Colors */
    --trebble-purple-active: #6327f5;
    /* Used for playing/active states */
   
    /* Color Scales */
    /* Trebble Primary Scale */
    --trebble-primary-200: #eda1a0;
    --trebble-primary-300: #e77d7b;
   
    /* Other colors that need variables */
    --color-red-600: #c72624;
    --color-red-800: #d80707;
    --color-red-300: #e6a3a2;
    --color-orange-500: #ca8a30;
    --color-gray-400: #c1c1c1;
    --color-orange-200: rgb(228, 171, 87);
    /* Used 6 times */
    --color-orange-400: #e39b2d;
    /* Darker shade */
    --color-orange-300: #fdb547;
    /* Lighter shade */
   
    /* Pinks */
    --color-pink-500: #e02d6f;
    /* Used 5 times - distinct pink shade */
   
    /* Oranges */
    --color-warning-400: #f1c40f;
    /* Used 3 times - yellow warning */
    --color-warning-500: #f4b400;
    /* Used 3 times - another warning shade */
   
    --color-twitter: #00aced;
    /* Twitter blue */
    --color-success-alt: #0ab5aa;
    /* Distinct teal color */
   
    --gradient-pink-light: #f38198;
    /* Used in gradient */
    --gradient-purple-light: #bd8ef1;
    /* Used in gradient */
   
    /* Success Colors */
    --success-green: #62c462;
    /* Used in success gradient */
    --success-green-alt: #75beaa;
    /* Used in success gradient */
   
    /* Gradient Base Colors */
    --trebble-gradient-pink: #fc466b;
    /* Pink used in gradients */
    --trebble-gradient-purple: #a044ff;
    /* Purple used in gradients */
   
    /* Gradient Definitions */
    --gradient-primary: linear-gradient(
     270deg,
     var(--trebble-gradient-pink) 25%,
     var(--trebble-blue) 100%
    );
    --gradient-primary-alt: linear-gradient(
     135deg,
     var(--trebble-gradient-pink) 25%,
     var(--trebble-blue) 100%
    );
    --gradient-primary-alt-reverse: linear-gradient(
     -135deg,
     var(--trebble-gradient-pink) 25%,
     var(--color-blue-gradient) 100%
    );
    --gradient-error: linear-gradient(
     270deg,
     var(--color-pink-gradient) 25%,
     var(--error) 100%
    );
    --gradient-pink-purple: linear-gradient(
     135deg,
     var(--gradient-pink-light) 25%,
     var(--gradient-purple-light) 100%
    );
   
    --gradient-fade-dark: linear-gradient(
     rgba(var(--color-black), 0) 0%,
     rgba(var(--color-black), 0.4) 30%,
     rgba(var(--color-black), 1) 100%
    );
   
    --gradient-fade-dark-simple: linear-gradient(
     var(--color-black-20) 0%,
     var(--color-black-80) 100%
    );
   
    /* New top fade gradient */
    --gradient-fade-dark-top: linear-gradient(
     rgba(17, 17, 17, 0.5) 0%,
     rgba(17, 17, 17, 0) 100%
    );
   
    /* OR using your color variables */
    --gradient-fade-dark-top: linear-gradient(
     rgba(var(--color-black), 0.5) 0%,
     rgba(var(--color-black), 0) 100%
    );
   
    /* Text Colors */
    --text-dark: #121212;
    --text-light: var(--color-white);
    --text-muted: var(--color-white-70);
    --text-muted-dark: rgba(0, 0, 0, 0.5);
   
    /* Background Colors */
    --bg-light: #f5f5f5;
    --bg-overlay-light: var(--color-white-5);
    --bg-overlay-dark: rgba(0, 0, 0, 0.03);
   
    /* Status Colors */
    --success: #6fcf97;
    --warning: #f2c94c;
    --error: #ec4747;
    --info: #66aaf3;
   
    /* Social Colors */
    --pink: #d10583;
   
    /* Brand/Accent Colors */
   
    /* or */
    --color-purple-600: #6a3093;
    /* if following a numeric scale */
    /* or */
   
    /* Pulse Colors */
    --pulse-yellow: #cca92c;
    /* rgba(204,169,44) */
    --pulse-green: #17b47a;
    /* rgba(23,180,122) */
    --pulse-gray: #bbbbbb;
    /* rgba(187,187,187) */
   
    /* Pulse Animation Properties */
    --pulse-size: 10px;
    --pulse-opacity: 0.4;
    --pulse-duration: 2s;
    --pulse-scale-small: 1.05;
    --pulse-scale-medium: 1.1;
   
    /* Frequently Used Colors (5+ times) */
    --color-pink-alt: rgb(232, 31, 77);
    /* Used 5 times */
   
    /* Alpha Variations */
    --trebble-primary-0: rgba(var(--trebble-primary-rgb), 0);
    --trebble-primary-20: rgba(var(--trebble-primary-rgb), 0.2);
    --trebble-primary-25: rgba(var(--trebble-primary-rgb), 0.25);
    --trebble-primary-30: rgba(var(--trebble-primary-rgb), 0.3);
    --trebble-primary-70: rgba(var(--trebble-primary-rgb), 0.7);
    --trebble-primary-dark-35: rgba(186, 76, 74, 0.35);
   
    /* Alpha Variations of Secondary Colors */
    --trebble-secondary-90: rgba(25, 181, 254, 0.9);
   
    /* Warning/Error Colors with Alpha */
    --error-90: rgba(239, 72, 54, 0.9);
    --warning-90: rgba(245, 171, 53, 0.9);
   
    /* Gray Scale with Alpha */
    --gray-overlay-20: rgba(94, 97, 99, 0.2);
    --gray-overlay-40: rgba(94, 97, 99, 0.4);
    --gray-light-15: rgb(173 159 163 / 15%);
    --gray-light-30: rgb(173 159 163 / 30%);
   
    /* Base Pulse Colors */
    --pulse-yellow-rgb: 204, 169, 44;
    /* #CCA92C */
    --pulse-green-rgb: 23, 180, 122;
    /* #17B47A */
    --pulse-gray-rgb: 187, 187, 187;
    /* #BBBBBB */
   
    /* Pulse Colors with Opacity */
    --pulse-yellow-alpha: rgba(var(--pulse-yellow-rgb), var(--pulse-opacity));
    --pulse-yellow-base: rgba(var(--pulse-yellow-rgb), 0);
   
    --pulse-green: rgb(var(--pulse-green-rgb));
    --pulse-green-alpha: rgba(var(--pulse-green-rgb), var(--pulse-opacity));
    --pulse-green-base: rgba(var(--pulse-green-rgb), 0);
   
    --pulse-gray: rgb(var(--pulse-gray-rgb));
    --pulse-gray-alpha: rgba(var(--pulse-gray-rgb), var(--pulse-opacity));
    --pulse-gray-base: rgba(var(--pulse-gray-rgb), 0);
   
    /* Misc Overlay Colors */
    --overlay-dark-15: rgba(41, 34, 34, 0.15);
    --overlay-gray-05: rgba(117, 117, 117, 0.05);
   
    /* Special Purpose Colors */
    --color-success-70: rgba(23, 180, 122, 0.7);
    --color-olive: rgb(90 96 8);
    --color-olive-50: rgb(90 96 8 / 50%);
    --bg-warm-yellow: rgb(250, 237, 206);
   
    --gray-600-15: rgb(130 130 130 / 15%);
    --gray-500-5: rgb(74 74 74 / 5%);
    /* Light gray with very low opacity */
   
    /* White with Alpha */
    --color-white-10: rgb(255 255 255 / 10%);
   /* Base text scale (em-based) - These stay the same */
--text-3xs: 0.5em;      /* 8px at 16px base */
--text-2xs: 0.625em;    /* 10px at 16px base */
--text-xs: 0.75em;      /* 12px at 16px base */
--text-sm: 0.875em;     /* 14px at 16px base */
--text-base: 1em;       /* 16px base */
--text-md: 1.125em;     /* 18px at 16px base */
--text-lg: 1.25em;      /* 20px at 16px base */
--text-xl: 1.5em;       /* 24px at 16px base */
--text-2xl: 1.75em;     /* 28px at 16px base */
--text-3xl: 1.875em;    /* 30px at 16px base */
--text-4xl: 2em;        /* 32px at 16px base */
--text-5xl: 2.5em;      /* 40px at 16px base */
--text-6xl: 3em;        /* 48px at 16px base */
--text-7xl: 3.75em;     /* 60px at 16px base */
--text-8xl: 5em;        /* 80px at 16px base */
--text-9xl: 7.5em;      /* 120px at 16px base */
--text-10xl: 8.75em;    /* 140px at 16px base */

/* Pixel-based alternatives - Adjusted for 16px base */
--text-3xs-px: 8px;     /* Minimum recommended for very short, supplementary text */
--text-2xs-px: 10px;    /* Use sparingly, for labels only */
--text-xs-px: 12px;     /* Minimum for short, supplementary text */
--text-sm-px: 14px;     /* Good for secondary text */
--text-base-px: 16px;   /* Base size - Good for body text */
--text-md-px: 18px;     /* Enhanced readability */
--text-lg-px: 20px;     /* Good for important text */
--text-xl-px: 24px;     /* Sub-headings */
--text-2xl-px: 28px;    /* Small headings */
--text-3xl-px: 30px;    /* Medium headings */
--text-4xl-px: 32px;    /* Large headings */
--text-5xl-px: 40px;    /* Extra large headings */
--text-6xl-px: 48px;    /* Major headings */
--text-7xl-px: 60px;    /* Hero text */
--text-8xl-px: 80px;    /* Large hero text */
--text-9xl-px: 120px;   /* Extra large display text */
--text-10xl-px: 140px;  /* Maximum display text */

/* Rem-based equivalents - These stay the same as they're relative to root 16px */
--text-2xs-rem: 0.625rem;  /* 10px */
--text-xs-rem: 0.75rem;    /* 12px */
--text-sm-rem: 0.875rem;   /* 14px */
--text-base-rem: 1rem;     /* 16px */
--text-md-rem: 1.125rem;   /* 18px */
--text-lg-rem: 1.25rem;    /* 20px */
--text-xl-rem: 1.5rem;     /* 24px */
--text-2xl-rem: 1.75rem;   /* 28px */
--text-3xl-rem: 1.875rem;  /* 30px */
--text-4xl-rem: 2rem;      /* 32px */
--text-5xl-rem: 2.5rem;    /* 40px */
--text-6xl-rem: 3rem;      /* 48px */
--text-7xl-rem: 3.75rem;   /* 60px */

/* Legacy names - Updated with proper base size references */
--text-regular: var(--text-base);      /* 16px */
--text-small: var(--text-sm);          /* 14px */
--text-medium: var(--text-xl);         /* 24px */
--text-larger: var(--text-3xl);        /* 30px */
--text-medium-large: var(--text-md);   /* 18px */

  /* Border width scale */
  --border-0: 0;              /* 76 uses - Add to system for explicit 0 */
  --border-1: 1px;           /* 104 uses - Default border */
  --border-2: 2px;           /* 108 uses - Medium border */
  --border-3: 3px;           /* 36 uses - Add to system since frequently used */
  --border-4: 4px;           /* 16 uses - Thick border */
  --border-8: 8px;

    /* Edge cases to standardize - 16% of usage (61 declarations) */
    --border-5: 5px;           /* 9 uses - consider standardizing to --border-4 */
    --border-6: 6px;           /* 4 uses - consider standardizing to --border-4 */
    --border-10: 10px;         /* 2 uses - consider standardizing to --border-8 */
    
    /* Decimal values to standardize - < 1% of usage */
    --border-1-5: 1.5px;       /* 3 uses - consider rounding to --border-2 */
    --border-2-5: 2.5px;       /* 1 use - consider rounding to --border-2 */

  /* Semantic aliases */
  --border-thin: var(--border-1);
  --border-default: var(--border-2);
  --border-medium: var(--border-3);
  --border-thick: var(--border-4);
    
   }
   
  
  
  
  /* Base Styles */

html {
    user-select: none;
    text-rendering: optimizeLegibility !important;
    -webkit-font-smoothing: antialiased !important;
    -moz-osx-font-smoothing: grayscale !important;  
    -webkit-touch-callout: none;
}
html, 
body {
    height: 100%;

}

input, select, textarea,
input:focus, select:focus, textarea:focus { 
    appearance: none; 
    -webkit-border-radius:0; 
    border-radius: 0; 
    outline:none;
}
body.ui-mobile-viewport,
div.ui-mobile-viewport {
   overflow: hidden;
}

.ui-btn, body, button, input, select, textarea{
    transition: all .3s;
}

body  > object{
  display:none !important;
  
}
a,
.text_link{
    color: var(--trebble-primary);
    cursor: pointer;
    user-select: none;
    -webkit-touch-callout: none;
}
/* THIS IS DONE TO CANCEL STYLE FROM MATERIALIAZECSS --START-- */
*, *:before, *:after {
    box-sizing: unset;
}

p {
    display: block;
    -webkit-margin-before: 1em;
    -webkit-margin-after: 1em;
    -webkit-margin-start: 0px;
    -webkit-margin-end: 0px;
    line-height: 1.3;
}

/* THIS IS DONE TO CANCEL STYLE FROM MATERIALIAZECSS --END-- */



body.browser.web ,
body.browser.web a{
    user-select: text;
}
@media only screen and (-webkit-min-device-pixel-ratio: 1.25),
only screen and ( min-device-pixel-ratio: 1.25),
only screen and ( -webkit-min-device-pixel-ratio: 2.0833333333333335),
only screen and ( min-resolution: 200dpi),
only screen and ( -webkit-min-device-pixel-ratio: 1.25),
only screen and ( min-resolution: 1.25dppx) {
    html {
        -webkit-font-smoothing: subpixel-antialiased;
    }
}

@media (min-width: 55em) {
    .ui-panel-dismiss-display-push.ui-panel-dismiss-open {
        display: none;
    }
}

input, select, textarea,
input:focus, select:focus, textarea:focus { 
    appearance: none; 
    -webkit-border-radius:0; 
    border-radius: 0; 
    outline:none;
}


.trebbleCustomPageTransition.in {
    animation-name: slideInRight;
    -moz-animation-name: slideInRight;
}
.trebbleCustomPageTransition.out {
    animation-name: slideOutLeft;
    -moz-animation-name: slideOutLeft;
}
.trebbleCustomPageTransition.in.reverse {
    animation-name: slideInLeft;
    -moz-animation-name: slideInLeft;
}
.trebbleCustomPageTransition.out.reverse {
    animation-name: slideOutRight;
    -moz-animation-name: slideOutRight;
}

.in {
    animation-timing-function: ease-out;
    animation-duration: 5000ms;
    -moz-animation-timing-function: ease-out;
    -moz-animation-duration: 500ms;
}

.out {
    animation-timing-function: ease-in;
    animation-duration: 3000ms;
    -moz-animation-timing-function: ease-in;
    -moz-animation-duration: 3000ms;
}


/**
* Styles to fix the new iOS 7 transparent status bar
*/

#ios7statusbar {
    width: 100%;
    height: 20px;
    background-color: white;
    position: fixed;
    z-index: 10000;
}
.ios7 .ui-page,
.ios7 .ui-header,
.ios7 .ui-pane {
    margin-top: 20px;
}
/* Bootstrape typeahead  import**/

.trebblePageSlide.in {
    animation-name: bounceInLeft;
    animation-name: bounceInLeft;
    animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    animation-duration: 600ms;
    animation-duration: 600ms;
}
.trebblePageSlide.out {
    animation-name: bounceOutLeft;
    animation-name: bounceOutLeft;
    animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    animation-duration: 500ms;
    animation-duration: 500ms;
}
.trebblePageSlide.in.reverse {
    animation-name: bounceInLeft;
    animation-name: bounceInLeft;
}
.trebblePageSlide.out.reverse {
    animation-name: bounceOutRight;
    animation-name: bounceOutRight;
}
.trebbleDialogPop.in {
    animation-name: bounceInUp;
    animation-name: bounceInUp;
    animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    animation-duration: 600ms;
    animation-duration: 600ms;
}
.trebbleDialogPop.out {
    animation-name: bounceOutUp;
    animation-name: bounceOutUp;
    animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    animation-duration: 500ms;
    animation-duration: 500ms;
}
.trebbleDialogPop.in.reverse {
    animation-name: bounceInDown;
    animation-name: bounceInDown;
}
.trebbleDialogPop.out.reverse {
    animation-name: bounceOutDown;
    animation-name: bounceOutDown;
}
body.browser.mobileweb .songLibraryWrapper > .infiniteListItem:hover,
body.browser.mobileweb .songLibraryWrapper > .infiniteListItem:hover ~ .explicitIconWrapper,
body.browser.mobileweb #songLibraryOptionButtuon:hover,
body.browser.mobileweb #songCapsuleOptionButtuon:hover,
body.browser.mobileweb #gradeOptionButtuon:hover,
body.browser.mobileweb .showCommentBtnWrapper #showCommentOptionButton:hover,
body.browser.mobileweb .showInfoBtnWrapper #showInfoOptionButton:hover,
body.browser.mobileweb #addToTrebbleOptionButtuon:hover,
body.browser.mobileweb #addToTrebbleOptionButtuon.ion-ios7-checkmark-outline:hover,
body.browser.mobileweb #gradeOptionButtuon.ion-ios7-pricetags:hover,
body.browser.mobileweb #songCapsuleOptionButtuon:hover,
body.browser.mobileweb div.songLibraryWrapper.songlistItem>.capsuleBtnWrapper>#songCapsuleOptionButton.enabled:hover,
body.browser.mobileweb div.songLibraryWrapper.songlistItem > .capsuleBtnWrapper > #songCapsuleOptionButton.enabled:hover,
body.browser.mobileweb div.songLibraryWrapper.albumlistItem > .capsuleBtnWrapper > #songCapsuleOptionButton.enabled:hover,
body.browser.mobileweb div.songLibraryWrapper.artistlistItem > .capsuleBtnWrapper > #songCapsuleOptionButton.enabled:hover,
body.browser.mobileweb div.songLibraryWrapper.songlistItem > .songOptionBtnWrapper > #actionButton.enabled:hover,
body.browser.mobileweb div.songLibraryWrapper.albumlistItem > .actionButtonWrapper > #actionButton.enabled:hover,
body.browser.mobileweb div.songLibraryWrapper.artistlistItem > .actionButtonWrapper> #actionButton.enabled:hover,
body.browser.mobileweb .userListItem:hover  {
    background-color: var(--bg-overlay-dark);
    border-color: var(--bg-overlay-dark);
    transform: translateZ(0);
    transform: translateZ(0);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -moz-osx-font-smoothing: grayscale;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-property: color, background-color;
    transition-property: color, background-color;
}
body.browser.mobileweb .lighttext * .songLibraryWrapper > .infiniteListItem:hover,
body.browser.mobileweb .lighttext * .songLibraryWrapper > .infiniteListItem:hover ~  .explicitIconWrapper,
body.browser.mobileweb .lighttext * #songLibraryOptionButtuon:hover,
body.browser.mobileweb .lighttext * #songCapsuleOptionButtuon:hover,
body.browser.mobileweb .lighttext * #gradeOptionButtuon:hover,
body.browser.mobileweb .lighttext * .showCommentBtnWrapper #showCommentOptionButton:hover,
body.browser.mobileweb .lighttext * .showInfoBtnWrapper #showInfoOptionButton:hover,
body.browser.mobileweb .lighttext * #addToTrebbleOptionButtuon:hover,
body.browser.mobileweb .lighttext * #addToTrebbleOptionButtuon.ion-ios7-checkmark-outline:hover,
body.browser.mobileweb .lighttext * #gradeOptionButtuon.ion-ios7-pricetags:hover,
body.browser.mobileweb .lighttext * #songCapsuleOptionButtuon:hover,
body.browser.mobileweb .lighttext * div.songLibraryWrapper.songlistItem>.capsuleBtnWrapper>#songCapsuleOptionButton.enabled:hover,
body.browser.mobileweb [data-role=popup] * .songLibraryWrapper:hover ,
body.browser.mobileweb .lighttext * div.songLibraryWrapper.songlistItem>.capsuleBtnWrapper>#songCapsuleOptionButton.enabled:hover,
body.browser.mobileweb .lighttext * div.songLibraryWrapper.songlistItem > .capsuleBtnWrapper > #songCapsuleOptionButton.enabled:hover,
body.browser.mobileweb .lighttext * div.songLibraryWrapper.albumlistItem > .capsuleBtnWrapper > #songCapsuleOptionButton.enabled:hover,
body.browser.mobileweb .lighttext * div.songLibraryWrapper.artistlistItem > .capsuleBtnWrapper > #songCapsuleOptionButton.enabled:hover,
body.browser.mobileweb .lighttext * div.songLibraryWrapper.songlistItem > .songOptionBtnWrapper > #actionButton.enabled:hover,
body.browser.mobileweb .lighttext * div.songLibraryWrapper.albumlistItem > .actionButtonWrapper > #actionButton.enabled:hover,
body.browser.mobileweb .lighttext * div.songLibraryWrapper.artistlistItem > .actionButtonWrapper> #actionButton.enabled:hover,
body.browser.mobileweb .selectionListItem:hover {
    background-color: var(--bg-overlay-light);
    border-color: var(--color-white-5);
    transform: translateZ(0);
    transform: translateZ(0);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -moz-osx-font-smoothing: grayscale;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-property: color, background-color;
    transition-property: color, background-color;
}


.dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 160px;
    padding: 0px;
    margin: 2px 0 0;
    list-style: none;
    /* background-color: var(--color-white-30);*/
    
    background-color: white;
    color: var(--text-dark);
    border: var(--border-1) solid var(--color-gray-300);
    border: var(--border-1) solid var(--color-black-20);
    border-right-width: var(--border-2);
    border-bottom-width: var(--border-2);
    border-radius: 6px;
    box-shadow: 0 5px 10px var(--color-black-20);
    background-clip: padding-box;
}
.dropdown-menu.pull-right {
    right: 0;
    left: auto;
}
.dropdown-menu .divider {
    width: 100%;
    height: 1px;
    margin: 9px 1px;
    margin: -5px 0 5px;
    overflow: hidden;
    background-color: var(--color-gray-200);
    border-bottom: var(--border-1) solid var(--text-light);
}
.dropdown-menu>li>a {
    text-decoration: none;
    display: block;
    padding: 3px 20px;
    clear: both;
    font-weight: normal;
    line-height: 30px;
    color: white;
    white-space: nowrap;
}
.dropdown-menu > li > a > .mention_username {
    color: var(--text-dark) !important;
    font-weight: 400;
}
.dropdown-menu > li.active > a > .mention_username {
    color: white !important;
    font-weight: 400;
}
[data-role=page] * .dropdown-menu > li > a {
    /* border-bottom: var(--border-1) solid var(--color-gray-150); */
}
[data-role=page] * .dropdown-menu > li > a:active {
    border-bottom: var(--border-1) solid white;
    color: white !important;
    background-color: var(--trebble-primary);
}
[data-role=page] * .dropdown-menu > li > a:active > .mention_username {
    color: white !important;
    font-weight: 400;
}
body.browser.mobileweb .dropdown-menu>li>a:hover,
body.browser.mobileweb .dropdown-menu>li>a:focus,
body.browser.mobileweb .dropdown-submenu:hover>a,
body.browser.mobileweb .dropdown-submenu:focus>a {
    text-decoration: none;
    color: var(--text-light);
    background-color: var(--trebble-primary);
}
.dropdown-menu>.active>a,
body.browser.mobileweb .dropdown-menu>.active>a:hover,
body.browser.mobileweb .dropdown-menu>.active>a:focus {
    color: var(--text-light);
    text-decoration: none;
    outline: 0;
    background-color: var(--trebble-primary);
}
[data-role=page] *.dropdown-menu>.active>a,
body.browser.mobileweb [data-role=page] * .dropdown-menu>.active>a:hover > span.mention_username,
body.browser.mobileweb [data-role=page] * .dropdown-menu>.active>a:focus >.mention_username {
    color: white !important;
}
.dropdown-menu>.disabled>a,
body.browser.mobileweb .dropdown-menu>.disabled>a:hover,
body.browser.mobileweb .dropdown-menu>.disabled>a:focus {
    color: var(--color-gray-550);
}
body.browser.mobileweb .dropdown-menu>.disabled>a:hover,
body.browser.mobileweb .dropdown-menu>.disabled>a:focus {
    text-decoration: none;
    background-color: transparent;
    background-image: none;
    cursor: default;
}
.typeahead {
    z-index: 1051;
    margin-top: 2px;
    border-radius: 4px;
    overflow: auto;
}
a[class*="ion"] , a.ion-button {
    padding: 10px;
    overflow: visible;
}

a.largeMenuButton[class*="ion"]:before {
    font-size: var(--text-xl);
    margin-right: 10px;
}
.details > a {
    margin: 0px;
    width: 30px;
}
/*
.shrinkAnimation {
    transition-duration: 0.1s;
    transition-property: transform;
    transition-property: transform;
    transform: translateZ(0);
    transform: translateZ(0);
}
.shrinkAnimation:active {
    transform: scale(0.95);
    transform: scale(0.95);
}
body.ios * .shrinkAnimation

{
    transition-duration: 0s;
    transition-property: transform;
    transition-property: transform;
    transform: translateZ(0);
    transform: translateZ(0);
}
body.ios *.shrinkAnimation:active
{
    transform: scale(1);
    transform: scale(1);
}
*/
.topsticky {
    top: 0;
}
/*notify js css*/

body > .notifyjs-corner {
    width: 100%;
    z-index: 2147483638 !important;
    /*margin: 0px;*/
    width: fit-content;
    margin: auto;
    position: relative;
}
.notifyjs-corner{
    /*margin: 0px;*/
    
}
body * .notifyjs-container,
body * .notifyjs-wrapper {
    width: 100%;
    margin: 0px;
    padding: 0px;
    max-width: 400px;
    padding: 5px 10px;
    box-sizing:border-box;
}
.notifyjs-corner .notifyjs-wrapper, .notifyjs-corner .notifyjs-container{
    /*margin: 0px !important;*/
    margin: auto !important;
    /*margin-top: 5px !important;*/
}


body.browser * .notifyjs-trebbleNotification-base {
    /*max-width: 400px;*/
}
.notifyjs-trebbleNotification-base {
    background-color: var(--trebble-secondary);
    color: white;
    min-height: 32px;
    max-height: 100px;
    /* width : 100%; */
    
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-align-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-justify-content: center;
    padding: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-bottom: 10px;
}
.notifyjs-trebbleNotification-base .notificationTextContent {
    display: inline-block;
    min-height: 1em;
    text-align: center;
    font-weight: bold;
    /*font-weight: 300;*/
    
    font-size: var(--text-sm);
    max-width: 400px;
    overflow: hidden;
}
.notifyjs-pushNotificationPreview-info{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction:column;

}
.notifyjs-trebbleNotification-info {
    background-color: var(--trebble-secondary-90);
    box-shadow: 0 10px 30px 0 var(--color-black-30);
}
.notifyjs-trebbleNotification-error {
    background-color: var(--error-90);
    box-shadow: 0 10px 30px 0 var(--color-black-30);
}
.notifyjs-trebbleNotification-warning {
    background-color: var(--warning-90);
    color: var(--color-black);
    box-shadow: 0 10px 30px 0 var(--color-black-30);
}
.notifyjs-trebbleNotification-trebbleready{
    background-color: var(--color-purple-500);
    box-shadow: 0 10px 30px 0 var(--color-black-30);
} 
.notifyjs-trebbleNotification-base.notifyjs-trebbleNotification-toast {
    border-radius: 0px;
    border-radius:2px;
    background: -webkit-linear-gradient(to top left, var(--color-pink-alt), var(--color-orange-200));
    background: -webkit-linear-gradient(to top left, var(--color-pink-alt), var(--color-orange-200));
    background: linear-gradient(to top left, var(--color-pink-alt), var(--color-orange-200));
    box-shadow: 0 10px 30px 0 var(--color-black-30);
}
.notifyjs-trebbleNotification-base.notifyjs-trebbleNotification-toast-light {
  border-radius: 0px;

  background-color: var(--info);
  border-radius:2px;
  display:flex;
  justify-content:center;
  align-items:center;
  box-shadow: 0 10px 30px 0 var(--color-black-30);
}
.notifyjs-trebbleNotification-base.notifyjs-trebbleNotification-toast > .notificationTextContent {
    padding-left: 20px;
    padding-right: 20px;
}
/*Start loader Css*/
/* change font size to change size */

.loaderWrapper {
    width: 80px;
    height: 50px;
}
.loader {
    font-size: var(--text-3xl);
    position: relative;
    width: 2em;
    height: 0.5em;
    margin: 100px auto;
}
.dot {
    width: 0.5em;
    height: 0.5em;
    border-radius: 0.5em;
    background: var(--text-dark);
    position: absolute;
    animation-duration: 0.5s;
    animation-timing-function: ease;
    animation-iteration-count: infinite;
}
.dot1,
.dot2 {
    left: 0;
}
.dot3 {
    left: 0.75em;
}
.dot4 {
    left: 1.5em;
}
@-webkit-keyframes reveal {
    from {
        transform: scale(0.001);
        opacity: 0.1;
    }
    to {
        transform: scale(1);
        opacity: 0.8;
    }
}
@keyframes reveal {
    from {
        transform: scale(0.001);
        opacity: 0.1;
    }
    to {
        transform: scale(1);
        opacity: 0.8;
    }
}
@-webkit-keyframes slide {
    to {
        transform: translateX(0.75em);
    }
}
@keyframes slide {
    to {
        transform: translateX(0.75em);
    }
}



@-webkit-keyframes dashoffset {
    0% {
        stroke-dashoffset: 0
    }

    100% {
        stroke-dashoffset: -30px
    }
}

@-moz-keyframes dashoffset {
    0% {
        stroke-dashoffset: 0
    }

    100% {
        stroke-dashoffset: -30px
    }
}


@keyframes dashoffset {
    0% {
        stroke-dashoffset: 0
    }

    100% {
        stroke-dashoffset: -30px
    }
}
.dot1 {
    animation-name: reveal;
}
.dot2,
.dot3 {
    animation-name: slide;
}
.dot4 {
    animation-name: reveal;
    animation-direction: reverse;
    /* thx @HugoGiraudel */
}
/*End loader Css*/

::-webkit-search-cancel-button {
    display: none;
}
.left-right-content-padding {
    box-sizing: border-box;
    /*padding-left: 0.5em;
    padding-right: 0.5em;*/
    padding-left: 10px;
    padding-right: 10px;
}

.infoBoxWrapper,
.flexContentCenteringBox {
    width: 100%;
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
    -ms-flex-align: center;
    align-items: center;
    -webkit-align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-justify-content: center;
    /* max-width: 700px; */
}
.infoBoxWrapper:not(.feature) {

    height: 100%;
    max-width: 500px;
    margin: auto;
    padding:20px;
    min-height: 300px;
    box-sizing:border-box;
}

@media (max-width: 500px), (max-height: 500px) and (orientation: landscape){
    .infoBoxWrapper:not(.feature) {
        max-width: 250px;
        padding:10px;
        box-sizing:border-box
    }

}
#recordingBlock > .flexContentCenteringBox {
  margin: auto;
}

#recordingBlock > #recordingInstruction > .instructionText{
    font-weight: bold;
    padding: 0px 40px;
    box-sizing: border-box;
}
#recordingBlock > #recordingInstruction > .arrowDownIconBox{
    animation-duration: 1.4s;
    animation-duration: 1.4s;
}

#recordingBlock > #recordingInstruction > .arrowDownIconBox > i{
    font-size: var(--text-4xl-px);
}
.infoBoxWrapper > .content {
    -webkit-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
}

#recordingBlock #peak_meter_container{
    width: 100%;
    max-width: 200px;
    margin: auto;
    padding: 7px 0px;
    height: 25px;
    box-sizing: border-box;
    /*padding: 10px 0px;*/
}

.pids-wrapper{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.pid{
  height: 4px;
  flex:  1 1 auto;
  display: inline-block;
  margin: 1px;
  background-color: var(--color-black-10);
}

.ui-page.lighttext .pid,
body.darkTheme .pid{
    background-color: grey;
  }

.pid.activeGreen,
body.darkTheme .pid.activeGreen,
[data-role=page].lighttext .pid.activeGreen{
    background-color:  var(--success);
}

.pid.activeYellow,
body.darkTheme .pid.activeYellow,
[data-role=page].lighttext .pid.activeYellow{
    background-color:  var(--warning);
}

.pid.activeRed, 
body.darkTheme .pid.activeRed,
[data-role=page].lighttext .pid.activeRed{
    background-color:  var(--error);
}

.createCapsulePage:not(.showCountTimer) #recordingBlock #counterAnimationBox,
.createCapsulePage.showCountTimer #recordingBlock #recordProgressContainer,
.createCapsulePage.showCountTimer #recordingBlock #showNotesToggleBox,
.createCapsulePage.showCountTimer #recordingBlock #noteSection,
.createCapsulePage.showCountTimer #recordingInstruction,
.createCapsulePage.showCountTimer #peak_meter_container,
.createCapsulePage.showCountTimer #recordControlsWrapper,
.createCapsulePage.showCountTimer #creationModeSelectorWrapper,
.createCapsulePage.showCountTimer #recordingBlock .trackSelectorBox,
.createCapsulePage.showCountTimer [data-role=header]{
    display: none !important;
}

.createCapsulePage.showCountTimer #counterAnimationBox{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

#recordingBlock #counterAnimationBox #counterNumber{
 font-weight: bold;
 font-size: var(--text-9xl-px);
 opacity: 0;
 animation-duration: 1s;
}






#featurePage * .owl-controls,
#GetStartedForCuratorsPage * .owl-controls {
    position: relative;
    top: -50px;
}
#featuresCarousel.owl-theme .owl-controls .owl-page span {
    width: 8px;
    height: 8px;
    margin: 4px 6px;
    background: white;
    border: var(--border-2) solid white;
    opacity: 1;
    background-color: transparent;
}
#GetStartedForCuratorsPage #featuresCarousel.owl-theme .owl-controls .owl-page span{
  background: white;
  border: var(--border-2) solid white;
  opacity: 1;
  background-color: transparent;
}
#featurePage * .infoBoxWrapper > .content ,
#GetStartedForCuratorsPage * .infoBoxWrapper > .content {
    padding: 5px;
    max-width: 700px;
}

#GetStartedForCuratorsPage .helpContent > .helpDetail > .actionButtonWrapper > .actionButton {

    color: var(--pink);
    background-color: white;
    border: var(--border-3) solid white;
    width: inherit;
    padding-left: 2em;
    padding-right: 2em;
    padding-top: 1em;
    padding-bottom: 1em;

}
body.browser.mobileweb  #GetStartedForCuratorsPage .helpContent > .helpDetail > .actionButtonWrapper > .actionButton:hover,
#GetStartedForCuratorsPage .helpContent > .helpDetail > .actionButtonWrapper > .actionButton:active {

    color: white;
    background-color: transparent;
    border: var(--border-3) solid white;

}

.infoBoxWrapper > .content > p ,
.infoBoxWrapper > .content > div{
    text-align: center;
    margin: 0px;
}
.infoBoxWrapper > .content > .actionButtonWrapper {
    max-width: 200px;
    margin: 20px auto;
}
.tag {
    flex: 0 0 45px;
    -webkit-flex: 0 0 45px;
    -ms-flex: 0 0 45px;
    float: left;
    width: 100%;
    max-width: 400px;
    height: 45px;
    border: var(--border-2) solid white;
    border-radius: 2px;
    color: white;
    margin: 4px;
    text-align: center;
    vertical-align: middle;
    line-height: 45px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 4px;
    padding-bottom: 4px;
    cursor: pointer;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.tag.withSublabel{
    line-height: 22.5px;
}
.tag >  .sublabel{
    opacity: 0.5;
    font-size: 90%;
}
.alltag {
    height: 45px;
    border: var(--border-2) solid white;
    border-radius: 2px;
    color: white;
    margin: 4px;
    text-align: center;
    vertical-align: middle;
    line-height: 45px;
    padding-left: 6px;
    padding-right: 6px;
    padding-top: 4px;
    padding-bottom: 4px;
    cursor: pointer;
}
.tagPlaceholder {
    display: inline-block;
}
#songGradingView > .tagPlaceholder,
#lifespanSelectionView > .tagPlaceholder {
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
    padding: 20px;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex-direction: column;
    justify-content: center;
    align-items: center;
    -webkit-justify-content: center;
    -webkit-align-items: center;
    -ms-justify-content: center;
    -ms-align-items: center;
}
.tag.selected {
    background-color: var(--trebble-primary-70);
    transition: background-color 100ms ease-out;
}
.alltag.selected {
    background-color: var(--color-success-70);
    transition: background-color 100ms ease-out;
}
.feature.infoBoxWrapper {
    height: 100%;
    opacity: 1;
}
.feature.infoBoxWrapper .illustrationWrapper{
    /*padding: 10px;
    background-color: var(--text-light);
    border-radius: 10px;
    width: calc(100% - 50px);
    margin: auto auto 10px;
    width: fit-content;
    display: block;
    box-sizing: border-box;
    height: calc(100vh - 250px);*/
}
.feature.infoBoxWrapper .illustration{
  width: calc(100% - 50px);
  height: calc(100vh - 300px);
  -o-object-fit: contain;
  object-fit: contain;
  position: relative;
  padding: 10px;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 20px 30px var(--color-black-20);
}

body[appname="trebble"] .sweet-alert h2{
    padding: 0 20px;
}

.feature.infoBoxWrapper .illustrationWrapper{
    margin-bottom: 20px;
}

#featurePage * .tag.actionButton,
#GetStartedForCuratorsPage * .tag.actionButton,
#createCapsuleInfoHeader * .tag.actionButton {
    font-size: var(--text-lg);
    border: var(--border-0) solid white;
    color: white;
    /* height: 30px; */
    /* padding: 10px; */
    /* margin: 3px; */
    
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 0px;
    padding-bottom: 0px;
    width: auto;
}


body:not(.darkTheme) .ui-page:not(.lighttext) #createCapsuleInfoHeader * .tag.actionButton,
body:not(.darkTheme) .ui-page:not(.lighttext) #continue_with_no_edit_btn.hightlightBtn,
body:not(.darkTheme) .ui-page:not(.lighttext) #doneRecordingButtonIcon {
    border: var(--border-2) solid var(--text-dark);
    color: var(--text-dark) !important;
    border: var(--border-2) solid var(--color-gray-100);
    color: var(--text-dark) !important;
    background-color: var(--color-gray-100);
    padding: 10px;
    margin: 3px;
    
}

body:not(.darkTheme) .ui-page:not(.lighttext) #createCapsuleInfoHeader * .tag.actionButton{
    height: 40px;
}
.ui-page:not(.lighttext) #continue_with_no_edit_btn.hightlightBtn {
    height: 45px;
}

body:not(.darkTheme) .ui-page:not(.lighttext) #createCapsuleInfoHeader * .tag.actionButton#nextButton,
body.white-theme div[data-role=page]:not(.lighttext) [data-role=header]#createCapsuleInfoHeader * #saveButtonIcon,
body.white-theme div[data-role=page]:not(.lighttext) [data-role=header]#createCapsuleInfoHeader * #doneRecordingButtonIcon{
    border: var(--border-2) solid var(--trebble-primary);
    color: white !important;
    background-color:var(--trebble-primary); 
    height: 40px;
    margin-top: 4px;
    margin-bottom: 4px;
}

#featurePage * .tag.actionButton > i,
#GetStartedForCuratorsPage * .tag.actionButton > i ,
#createCapsuleInfoHeader  * .tag.actionButton > i.ion-ios7-arrow-thin-left {
  font-size: var(--text-4xl);
}
#createCapsuleInfoHeader * .tag.actionButton > i {
    font-size: var(--text-base);
}
#createCapsuleInfoHeader * .tag.actionButton#showRecordingSettingsButton > i {
    font-size: var(--text-xl);
}
#createCapsuleInfoHeader #previousButton{
  padding:0px;
  border: var(--border-0);
background-color: transparent;
}
.trebble_disabled {
    opacity: 0.2;
    cursor: default !important;
}
.feature.infoBoxWrapper > .content > p > .iconWrapper {
    color: white;
}
.infoBoxWrapper > .content > p > .iconWrapper {
    color: var(--text-dark);
    font-size: var(--text-9xl-px);
    padding:20px 0;
    box-sizing:border-box;
}

#player #commentOverviewBox .infoBoxWrapper:not(.feature){
    display: block;
    min-height: unset;
    padding: 0px;
}

#player #commentOverviewBox * .infoBoxWrapper > .content > p > .iconWrapper{
    font-size: var(--text-5xl);
}

.infoBoxWrapper:not(.feature) > .content > p > .iconWrapper {
    opacity: 0.7;
}
.lighttext * .infoBoxWrapper > .content > p > .iconWrapper {
    color: var(--text-muted);
    opacity: 1;
}
/*#player #commentOverviewBox > div > div > div{
    height: calc(100% - 61px);
    }*/
    #player #commentOverviewBox * .commentBoxWrapper{
        background-color: white;
    }
    #player #commentOverviewBox * .commentBoxWrapper * #comment{
        color: var(--text-dark);
    }

    #commentOverviewBox * .infoBoxWrapper > .content > p > .iconWrapper {
        /* display: none; */

        font-size: var(--text-7xl);
    }
    [data-role=popup] * .infoBoxWrapper > .content > p > .iconWrapper,
    #ExplorePage:not([showListView=true]) * .infoBoxWrapper > .content > p > .iconWrapper,
    #Onboarding:not([showListView=true]) * .infoBoxWrapper > .content > p > .iconWrapper {
        color: white;
    }
    .feature.infoBoxWrapper > .content > .message {
        color: white;
        max-width: 400px;
        margin: auto;
    }
    .infoBoxWrapper > .content > .message {
        color: var(--text-dark);
        font-size: var(--text-xl);
        font-weight: 700;
    }
    .infoBoxWrapper:not(.feature) > .content > .message {
        opacity: 0.7;
    }
    #commentOverviewBox * .infoBoxWrapper > .content > .message {
        /*font-size: var(--text-md);*/
    }
    .lighttext .infoBoxWrapper > .content > .message {
        /*font-size: var(--text-md);*/
        color: var(--text-muted);
        opacity: 1;
    }
    [data-role=popup] * .infoBoxWrapper > .content > .message,
    #ExplorePage:not([showListView=true]) * .infoBoxWrapper > .content > .message,
    #Onboarding:not([showListView=true]) * .infoBoxWrapper > .content > .message {
        color: white;
    }
    .feature.infoBoxWrapper > .content > .helpMessage {
        color: white;
        font-size: var(--text-md);
        max-width: 300px;
        margin: 10px auto auto auto;
    }
    [data-role=popup] * .infoBoxWrapper > .content > .helpMessage,
    #ExplorePage:not([showListView=true]) * .infoBoxWrapper > .content > .helpMessage,
    #Onboarding:not([showListView=true]) * .infoBoxWrapper > .content > .helpMessage {
        color: white;
    }

    #ExplorePage.hideAllContent .ui-content{
        display: none;
    }
    .infoBoxWrapper > .content > .helpMessage {
        color: var(--text-dark);
        font-size: var(--text-base);
        margin: 10px;
    }
    .infoBoxWrapper:not(.feature) > .content > .helpMessage {
        opacity: 0.7;
    }
    .lighttext * .infoBoxWrapper > .content > .helpMessage {
        color: white;
    }
    .lighttext * .sentinel_block_wrapper .sentinel,
    .lighttext * .infoBoxWrapper > .content > .helpMessage {
        color: var(--color-white-50);
        opacity: 1;
    }

    .infoBoxWrapper > .content > .actionButtonWrapper > a.actionButton {
        height: 25px;
        margin: 0px;
        margin-right: 10px;
        margin-left: 10px;
        font-weight: 300;
        background-color: var(--color-gray-150);
        border-color: var(--color-gray-150);
        border: var(--border-2) solid var(--color-gray-150);
        color: var(--text-dark);
        line-height: 30px;
        font-size: var(--text-md-px);
        font-weight:bold;
        display: 
        -ms-flexbox;
        display: flex;
        display: 
        -webkit-flex;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-align-items: center;
        -webkit-justify-content: center;
        width: fit-content;
        cursor: pointer;
        min-width: 200px;
    }

    body.browser.mobileweb [data-role=page] .infoBoxWrapper > .content > .actionButtonWrapper > a.actionButton:hover{
      cursor: pointer;
      color: white;
      background-color: var(--trebble-primary);
      border: var(--border-2) solid var(--trebble-primary);
      transform: translateZ(0);
      transform: translateZ(0);
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      -moz-osx-font-smoothing: grayscale;
      -webkit-transition-duration: 0.3s;
      transition-duration: 0.3s;
      -webkit-transition-property: all;
      transition-property: all;
  }

  body.browser.mobileweb .infoBoxWrapper > .content > .actionButtonWrapper > a.actionButton:hover,
  .infoBoxWrapper > .content > .actionButtonWrapper > a.actionButton:active{
    cursor: pointer;
    color: white;
    background-color: var(--trebble-primary);
    border: var(--border-2) solid var(--trebble-primary);
    transform: translateZ(0);
    transform: translateZ(0);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -moz-osx-font-smoothing: grayscale;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-property: all;
    transition-property: all;
}

[data-role=popup] * .infoBoxWrapper > .content > .actionButtonWrapper > a.actionButton {
    background-color: transparent;
    border: var(--border-2) solid var(--trebble-primary);
    color: white;
}
body.browser.mobileweb  > [data-role=popup] * .infoBoxWrapper > .content > .actionButtonWrapper > a.actionButton:hover,
body.browser.mobileweb .selectionListItem:hover {
    background-color: var(--color-white-5);
    transform: translateZ(0);
    transform: translateZ(0);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -moz-osx-font-smoothing: grayscale;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-property: color, background-color;
    transition-property: color, background-color;

}

#schedulingSettingFld,
.schedulingTimeWrapper #schedulingTimeFld{
    border: var(--border-2) solid var(--trebble-primary) !important;
}
body.browser.mobileweb #schedulingSettingFld:hover,
#schedulingSettingFld:active,
body.browser.mobileweb .schedulingTimeWrapper #schedulingTimeFld:hover,
.schedulingTimeWrapper #schedulingTimeFld:active{
    border: var(--border-2) solid white !important;
}


[data-role=popup] * .infoBoxWrapper > .content > .actionButtonWrapper > a.actionButton:active,
.selectionListItem:active {
    background-color: var(--color-white-10);
    transform: translateZ(0);
    transform: translateZ(0);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -moz-osx-font-smoothing: grayscale;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-property: color, background-color;
    transition-property: color, background-color;

}
.infoBoxWrapper > .content > .actionButtonWrapper > a.actionButton:active {
    background-color: var(--trebble-primary-dark);
    border-color: var(--trebble-primary-dark);
    color: white;
}
[data-role=popup] * .infoBoxWrapper > .content > .actionButtonWrapper > a.actionButton:active {
    background-color: var(--trebble-primary);
    border: var(--border-2) solid var(--trebble-primary);
    color: white;
}

.listHeader {
    line-height: 40px;
    vertical-align: middle;
    /*font-size: var(--text-md);*/
    font-size: var(--text-2xl);
    margin-top: 4px;
    margin-bottom: 4px;
    border-bottom: var(--border-1) solid var(--color-black-5);
    color: var(--text-muted-dark);
    padding: 10px;
    /*justify-content: center;
    -webkit-justify-content: center;*/
    
    -ms-flex-line-pack: center;
    -moz-flex-line-pack: center;
    align-content: center;
    -webkit-align-content: center;
    -ms-flex-direction: row;
    -moz-flex-direction: row;
    flex-direction: row;
    -webkit-flex-direction: row;
    display: -ms-flexbox;
    display: -moz-flexbox;
    display: flex;
    display: -webkit-flex;
    display: -moz-flex;
    height: 40px;
    background-color: transparent;
    font-weight: 900;
}
.popupContent * .listHeader {
    color: var(--color-white-60);
    border-bottom: var(--border-1) solid var(--color-white-20);
    /*font-size: var(--text-md);*/
    font-size: var(--text-2xl);
}
.lighttext * .listHeader,
.lighttext * .tabheader {
    border-bottom: var(--border-1) solid var(--color-white-5);
    color: var(--color-white-50);
}
.listHeader >:first-child {
    line-height: 40px;
    -ms-flex: 1 1 100%;
    flex: 1 1 100%;
    -webkit-flex: 1 1 100%;
}
.listHeader > [class*="ion"] {
    text-align: center;
    font-size: var(--text-3xl);
    -ms-flex: 0 0 50px;
    flex: 0 0 50px;
    -webkit-flex: 0 0 50px;
    color: var(--trebble-primary);
}
.listHeader > [class*="ion"]:active {
    color: white;
    background-color: var(--trebble-primary);
}
.listFooter {
    height: 60px;
    border-bottom: var(--border-1) solid var(--color-gray-100);
    color: var(--color-gray-400);
    text-align: left;
}
.listFooter.transparent {
    border-bottom: var(--border-1) solid var(--color-white-5);
}
.listFooter.transparent > a.ui-btn.ui-btn-c {
    background-color: transparent;
}
.lighttext * .listFooter.transparent > a.ui-btn.ui-btn-c {
    /*background-color: transparent;*/
    background-color: var(--color-white-5);
    color: var(--color-white-50);
}
.listFooter > a.ui-btn.ui-btn-c {
    user-select: none;
    -webkit-user-drag: none;
    -webkit-tap-highlight-color: var(--color-black-0);
    text-align: left;
    color: var(--text-dark);
    font-weight: 400;
    padding: 8px;
    padding-top: 20px;
    /* height: 100%; */
    
    margin: 0px;
    line-height: 20px;
    padding-bottom: 20px;
    border: var(--border-0);
}
body.browser.mobileweb .listFooter > a.ui-btn.ui-btn-c{
    user-select: text;
}
body.browser.mobileweb .listFooter > a.ui-btn.ui-btn-c:hover {
    color: var(--text-dark) !important;
    background-color: var(--color-gray-100);
    border-color: var(--color-gray-100);
    transform: translateZ(0);
    transform: translateZ(0);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -moz-osx-font-smoothing: grayscale;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-property: color, background-color;
    transition-property: color, background-color;
}

.spotify_gradient{
    background: linear-gradient(90deg,var(--color-purple-dark),var(--color-blue-450));
}

body.browser.mobileweb  .listFooter > a.ui-btn.ui-btn-c:hover{
  color: white !important;
  background-color: var(--trebble-primary);
  border-color: var(--trebble-primary);
  transform: translateZ(0);
  transform: translateZ(0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: color, background-color;
  transition-property: color, background-color;
}
[data-role=popup].songIdentifiedDetailPage {
    border: var(--border-0);
}
.animate-spin {
    animation: spin 1s infinite linear;
    animation: spin 1s infinite linear;
    display: inline-block;
}

body.ios * .blurPage,
body.browser * .blurPage {
    -webkit-filter: blur(5px);
    -moz-filter: blur(5px);
    -o-filter: blur(5px);
    -ms-filter: blur(5px);
    filter: blur(5px);
    /*filter : url(#blur-effect-5);*/

}
.fontello-icon-spin4 {
    font-size: var(--text-3xl);
}
#playlistInfoHeader {
    opacity: 1;
    font-weight: 400;
    font-size: var(--text-sm);
    color: white;
    text-align: left;
}
#playlistNameWrapper {
    font-size: var(--text-sm);
    color: white;
    font-weight: 600;
    /*font-weight: 300;*/

    text-align: left;
}

#player #playlistNameWrapper{
   opacity: 0.9;
   width: 100%;
   text-overflow: ellipsis;
   overflow: hidden;
   text-align: center;


 /*white-space: normal;
 display: -webkit-box;
 -webkit-line-clamp: 2;
 -webkit-box-orient: vertical;*/

 /*white-space: normal;
 display: block;
 box-sizing: border-box;
 margin: 1em 0px;
 text-overflow: ellipsis;
 height: calc(64px - 2.2em);
 overflow: hidden;*/

 white-space: nowrap;
 display: block;
 box-sizing: border-box;
 margin: 0.5em 0;
 text-overflow: ellipsis;
 height: 1em;
 overflow: hidden !important;
}
body.embedded #playlistNameWrapper{
  display: none;
}

.browser.mobilebrowser  #playlistNameWrapper {

    font-weight: 600;
    /*font-weight: 300;*/
    
}
#songInfoWrapper {
    padding-bottom: 5px;
    padding-top: 5px;
}
body.embedded * #songInfoWrapper {
    padding-bottom: 0px;
    padding-top: 0px;
}
@-webkit-keyframes spin {
    0% {
        transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(359deg);
        transform: rotate(359deg);
    }
}
@keyframes spin {
    0% {
        transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(359deg);
        transform: rotate(359deg);
    }
}
#home {
    padding-top: 58px;
    min-height: 509px;
    padding-bottom: 66px;
    /* background-image: url(file:///android_asset/www/img/backgrounds/BW1.jpg); */

    background-size: cover;
    background-color: var(--bg-light);
    color: var(--text-dark);
}
.popupContent * #analyseAudioBtn {
    color: white;
}
.popupContent * #searchGlobalBtn {
    color: white;
}
div[data-role=header] * .ion-ionic {
    color: var(--trebble-primary-dark);
    line-height: 51px;
    padding: 0px;
    width: 51px;
    display: block;
    border: var(--border-0);
    font-size: var(--text-4xl);
}
#mylibrary > div[data-role=header] * .ui-btn,
#mySubscriptions:not(.no_subscription) > div[data-role=header] * .ui-btn,
#myJourneys:not(.no_subscription) > div[data-role=header] * .ui-btn,
#home > div[data-role=header] * .ui-btn,
html .ui-page-theme-c div[data-role=header] * .ui-btn,
#globalSearch > div[data-role=header] * .ui-btn,
#PlaylistPodcastsPage > div[data-role=header] * .ui-btn {
    background-color: transparent;
    font-size: var(--text-2xl);
    color: white;
    overflow: visible;
    border-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;

}
#mylibrary > div[data-role=header] * .ui-btn:active,
#mySubscriptions > div[data-role=header] * .ui-btn:active,
#myJourneys > div[data-role=header] * .ui-btn:active,
#home > div[data-role=header] * .ui-btn:active,
html .ui-page-theme-c div[data-role=header] * .ui-btn:active,
#globalSearch > div[data-role=header] * .ui-btn:active,
#PlaylistPodcastsPage > div[data-role=header] * .ui-btn:active,
#homeHeaderButton:active,
#helpHeaderButton:active,
.defaultPage#queue * #queueMenuOptionBtn:active,
.defaultPage * #header-back-button:active,
.defaultPage * #header-queue-button:active {
    background-color: var(--bg-overlay-dark);
    border-color: transparent;
    transform: translateZ(0);
    transform: translateZ(0);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -moz-osx-font-smoothing: grayscale;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-property: color, background-color;
    transition-property: color, background-color;
}
body.browser.mobileweb  #mylibrary > div[data-role=header] * .ui-btn:hover,
body.browser.mobileweb  #mySubscriptions > div[data-role=header] * .ui-btn:hover,
body.browser.mobileweb  #myJourneys > div[data-role=header] * .ui-btn:hover,
body.browser.mobileweb   #home > div[data-role=header] * .ui-btn:hover,
html  body.browser.mobileweb  .ui-page-theme-c div[data-role=header] * .ui-btn:hover,
body.browser.mobileweb  #globalSearch > div[data-role=header] * .ui-btn:hover,
body.browser.mobileweb #PlaylistPodcastsPage > div[data-role=header] * .ui-btn:hover,
body.browser.mobileweb #homeHeaderButton:hover,
body.browser.mobileweb #helpHeaderButton:hover,
body.browser.mobileweb .defaultPage#queue * #queueMenuOptionBtn:hover,
body.browser.mobileweb .defaultPage * #header-back-button:hover,
body.browser.mobileweb .defaultPage * #header-queue-button:hover {
    background-color: var(--bg-overlay-dark);
    border-color: transparent;
    transform: translateZ(0);
    transform: translateZ(0);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -moz-osx-font-smoothing: grayscale;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-property: color, background-color;
    transition-property: color, background-color;
}
#mylibrary > div[data-role=header] * .ui-btn#filterButton,
#mySubscriptions > div[data-role=header] * .ui-btn#filterButton,
#myJourneys > div[data-role=header] * .ui-btn#filterButton,
#home > div[data-role=header] * .ui-btn#filterButton,
html .ui-page-theme-c div[data-role=header] * .ui-btn#filterButton,
#globalSearch > div[data-role=header] * .ui-btn#filterButton {
    font-size: var(--text-lg);
}
#globalSearch > div[data-role=header] * .ui-btn#searchLibraryBtn.ion-ios7-close-empty,
#mylibrary > div[data-role=header] * .ui-btn#searchLibraryBtn.ion-ios7-close-empty,
#mySubscriptions > div[data-role=header] * .ui-btn#searchLibraryBtn.ion-ios7-close-empty,
#myJourneys > div[data-role=header] * .ui-btn#searchLibraryBtn.ion-ios7-close-empty,
#PlaylistPodcastsPage > div[data-role=header] * .ui-btn#searchLibraryBtn.ion-ios7-close-empty,
#globalSearch > div[data-role=header] * .ui-btn#searchLibraryBtn > .ion-ios7-close-empty,
#mylibrary > div[data-role=header] * .ui-btn#searchLibraryBtn > .ion-ios7-close-empty,
#mySubscriptions > div[data-role=header] * .ui-btn#searchLibraryBtn > .ion-ios7-close-empty,
#myJourneys > div[data-role=header] * .ui-btn#searchLibraryBtn > .ion-ios7-close-empty,
#PlaylistPodcastsPage > div[data-role=header] * .ui-btn#searchLibraryBtn > .ion-ios7-close-empty  {
    font-size: var(--text-3xl);
}
#searchLibraryBtn,
#searchGlobalBtn {
    color: var(--trebble-primary-dark);
    font-size: var(--text-3xl);
    line-height: 51px;
    padding-right: 3px;
    padding-left: 3px;
    padding: 0px;
    height: 100%;
    display: block;
    width: 51px;
    border: var(--border-0);
    margin: 0px;
}

#mylibrary #collection_repeat_container{
    padding: 0px;
    box-sizing: border-box;
    height: calc(100vh - 50px);
}

#mylibrary .capsule_list_content_wrapper #collection_repeat_container{
    height: calc(100vh - 140px);
}

#mylibrary .capsule_list_content_wrapper.hide_category_list #collection_repeat_container{
    height: calc(100vh - 75px);
}

[data-role=page] * #songSelectorWrapper *#searchLibraryBtn {
    color: var(--text-muted-dark);
}
[data-role=popup] * #searchLibraryBtn {
    color: white;
}
#searchLibraryBtn.ion-ios7-close-empty,
#searchGlobalBtn.ion-ios7-close-empty,
#searchLibraryBtn > .ion-ios7-close-empty,
#searchGlobalBtn > .ion-ios7-close-empty   {
    font-size: var(--text-5xl);
}
input.customSearchField[type=search],
input.shareableLink {
    border-radius: 2px;
    font-weight: 400;
    height: 36px;
    width: 100px;
    color: white;
    /*color: var(--text-muted);*/
    border: var(--border-0);
    padding: 0px;
    /* background-color: transparent; */

    background-color: var(--color-red-600);
    font-size: var(--text-base);
}
[data-role=popup] * input.customSearchField[type=search],
[data-role=popup] * input.shareableLink {
    background-color: var(--color-white-20);
    color: var(--color-white-60);
}
[data-role=popup] * input.customSearchField[type=search]:focus,
[data-role=popup] * input.shareableLink:focus {
    width: 100%;
    background-color: var(--color-white-20);
}


input.customSearchField[type=search]:focus,
input.shareableLink:focus {
    font-weight: 300;
    outline: none !important;
    width: 130px;
    color: white;
    border: var(--border-0);
    background-color: transparent;
    border-bottom: var(--border-1) solid var(--color-red-600);
    border-radius: 2px;
    font-weight: 400;
    height: 36px;
    width: 100px;
    color: white;
    /*color: var(--text-muted);*/
    border: var(--border-0);
    padding: 0px;
    /* background-color: transparent; */

    background-color: var(--color-red-600);
}
input.customSearchField[type=search],
input.customSearchField[type=search]:focus,
input.customSearchField[type=search]:not(.browser-default),
input.customSearchField[type=search]:not(.browser-default):focus,
input.customSearchField[type=search]:not(.browser-default):not([readonly]),
input.customSearchField[type=search]:not(.browser-default):focus:not([readonly]){
  font-weight: 900;
  height: 36px;
  margin-top: 0px;
  margin: 0px;
  border-bottom: var(--border-0);
  box-shadow:none;
}

.headerSearchBtn {} #loginPage {
    background: -webkit-linear-gradient(to top, var(--color-pink-500) 0%, var(--color-orange-500) 100%);
    background: -moz-linear-gradient(to top, var(--color-pink-500) 0%, var(--color-orange-500) 100%);
    background: -ms-linear-gradient(to top, var(--color-pink-500) 0%, var(--color-orange-500) 100%);
    background: -o-linear-gradient(to top, var(--color-pink-500) 0%, var(--color-orange-500) 100%);
    background: linear-gradient(to top, var(--color-pink-500) 0%, var(--color-orange-500) 100%);
}
#loginPage * .ui-content {
    width: inherit;
}
[data-role=page] > .ui-content {
    position: fixed;
    width: 100%;
    padding: 0px;
    -webkit-overflow-scrolling: touch;
    overflow: auto;
}
@keyframes songGettingAddedPlaylistAnimation1

{
    0% {
        transform: translateX(0px);
    }
    40% {
        transform: translateX(30%);
    }
    100% {
        transform: translateX(30%);
    }
}
@keyframes songGettingAddedPlaylistAnimation2
{
    0% {
        transform: translateX(30%);
    }
    90% {
        transform: translateX(30%);
    }
    100% {
        transform: translateX(0px);
    }
}
.songLibraryWrapper > .infiniteListItem {
    animation-fill-mode: forwards;
}


a.ui-link-inherit {
    text-decoration: none;
    color: var(--color-black);
}
li[data-role=list-divider] {
    border: var(--border-0);
    background-color: transparent;
    color: var(--color-black);
    font-size: var(--text-md);
}
#expandableSongOptions {
    transition: height 4s;
    -webkit-transition: height 4s;
}
.songIdentifiedDetailPage * #albumDetailButtonOptionBar {
    font-size: var(--text-sm);
}
#albumDetailButtonOptionBar {
    /*position: sticky;
    top: 20px;*/
    
    margin: 0px;
    /*background-color: var(--bg-overlay-dark);*/
}
.songIdentifiedDetailPage * #albumDetailButtonOptionBar > div > a.ui-btn {
    font-size: var(--text-sm);
}
#albumDetailButtonOptionBar > div > a.ui-btn {
    height: 30px;
    margin: 0px;
    padding-right: 0px;
    padding-left: 0px;
    font-weight: 400;
    /*font-weight: 300;*/
    
    background-color: var(--color-gray-100);
    border-color: var(--color-gray-100);
    color: var(--text-dark);
    line-height: 30px;
    font-size: var(--text-base);
}
#albumDetailButtonOptionBar > div > a.ui-btn:active {
    background-color: var(--color-gray-450);
    border-color: var(--color-gray-450);
    color: var(--color-gray-600);
}
.artistDetailCoverArtWrapper,
.userDetailCoverArtWrapper {
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex-direction: column;
    /* -webkit-transition: height 0.8s ease-in-out; */
    
    transition: background-image .2s cubic-bezier(.13, 1.07, .5, 1.01), height 0.2s cubic-bezier(.13, 1.07, .5, 1.01);
    background-color: var(--color-black);
    position: relative;
    background-repeat: no-repeat;
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
    background-position: top;
    background-size: cover;
    padding-top: 52px;
    height: 400px;
    width: 100%;
}
.albumDetailCoverArtWrapper {
    transition: background-image .2s cubic-bezier(.13, 1.07, .5, 1.01), height 0.2s cubic-bezier(.13, 1.07, .5, 1.01);
    background-color: var(--color-black);
    position: relative;
    background-repeat: no-repeat;
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
    background-position: center;
    background-size: cover;
    padding-top: 52px;
    height: 400px;
}
.songIdentifiedDetailPage * .basicAlbumInfo {
    height: 100px;
}
.basicAlbumInfo {
    box-sizing: border-box;
    font-weight: 400;
    text-overflow: ellipsis;
    overflow: hidden;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex-direction: column;
    vertical-align: middle;
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
    color: white;
    width: 100%;
    background: var(--gradient-fade-dark);
    bottom: 0px;
    height: 40%;
    position: absolute;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-justify-content: flex-end;
    padding: 10px;
}
body.browser * .basicAlbumInfo {
    height: 100%;
}
.homeOptionHeader {
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-direction: row;
    height: 40px;
}
.homeOptionHeader > .title {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    -webkit-flex: 1 1 auto;
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
    -ms-flex-align: center;
    align-items: center;
    -webkit-align-items: center;
}
.popupContent * #homeOptionsWrapper > div > p {
    font-size: var(--text-xs);
}
.homeOptionHeader > .option {
    width: 40px;
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-align-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-justify-content: center;
}
.homeOptionHeader > .option > a {
    line-height: 40px;
    padding: 0px;
    font-size: var(--text-xl);
}
.basicArtistInfo {
    box-sizing: border-box;
    -ms-flex: 0 0 150px;
    flex: 0 0 150px;
    -webkit-flex: 0 0 150px;
    font-weight: 400;
    text-overflow: ellipsis;
    overflow: hidden;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex-direction: column;
    vertical-align: middle;
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
    color: white;
    width: 100%;
    background: var(--gradient-fade-dark);
    bottom: 0px;
    position: absolute;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-justify-content: flex-end;
    min-height: 100px;
}
body.browser * .basicArtistInfo {
    -ms-flex: 0 0 450px;
    flex: 0 0 400px;
    -webkit-flex: 0 0 450px;
    height: 450px;
}


@media (max-width: 480px), (max-height: 480px) and   (orientation: landscape) {
  body.browser > .playlistDetailsPage * .basicArtistInfo {
      -ms-flex: 0 0 200px;
      -webkit-box-flex: 0;
      flex: 0 0 200px;
      -webkit-flex: 0 0 200px;
      height: 200px;
  }
}
.userProfileDetailsPage * .basicArtistInfo {
    height: 100%;
    padding-top: 56px;
    background-image: none;
}
.basicAlbumInfo .artistName {
    color: white;
    font-weight: 600;
    font-size: var(--text-md);
    white-space: pre;
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-right: 14px;
    margin-left: 14px;
    opacity: 0.8;
}
.darktext * .basicAlbumInfo .artistName {
    color: var(--text-dark);
}
.basicAlbumInfo .yearLabel {
    white-space: pre;
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-right: 14px;
    margin-left: 14px;
    font-weight: 400;
    font-size: var(--text-sm);
    color: var(--color-white-40);
}
.darktext * .basicAlbumInfo .yearLabel {
    color: var(--color-black-30);
}
.basicArtistInfo .artistName {
    font-size: var(--text-md);
    white-space: pre;
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-right: 14px;
    margin-left: 14px;
}
.playlistDetailsPage * .basicArtistInfo .bioWrapper {
    max-height: 100px;
}
.basicArtistInfo .bioWrapper {
    box-sizing: border-box;
    max-height: 100px;
    padding: 8px;
    display: block;
    text-overflow: ellipsis;
    overflow: auto;
    font-weight: 400;
    /*font-weight: 300;*/
    /* opacity: 0.8; */
    
    /*font-size: var(--text-sm);*/
    z-index: 2;
}
.darktext *.basicArtistInfo .bioWrapper {
    color: var(--text-dark);
}
.lighttext *.basicArtistInfo .bioWrapper {
    color: white;
}
.userProfileDetailsPage #pageHeaderTitleWrapper{
    display: none;
}
.userProfileDetailsPage * #usernameBox,
.userProfileDetailsPage * #fullnameBox {
    padding-left: 10px;
    padding-right: 10px;
    text-align: center;
    box-sizing: border-box;
    width: 100%;
    /* display: flex; */
    /* display: -webkit-flex; */
    /* justify-content: center; */
    /* -webkit-justify-content: center; */
    /* align-items: center; */
    /* -webkit-align-items: center; */
    /* 
    -ms-flex: 0 0 40px;
    flex: 0 0 40px;
    -webkit-flex: 0 0 40px;*/

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    /* margin-top: 10px; */
    /* margin-bottom: 10px; */
}
.userProfileDetailsPage * #fullnameBox{
 font-size: var(--text-lg);
 font-weight: bold;
}
.userProfileDetailsPage * #usernameBox{
    margin-bottom: 10px;
    color:var(--color-gray-300);
}
.userProfileDetailsPage * #bioBox{
    max-width: 400px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    /* autoprefixer: ignore next */
    -webkit-box-orient: vertical;
    white-space: normal;
    margin-bottom: 5px;
    /*padding: 10px 20px;*/
    padding: 0px;
    box-sizing: border-box;
    overflow: hidden;
    color: var(--color-gray-300);

}

.userProfileDetailsPage * #websiteBox{
 white-space: nowrap;
 overflow: hidden;
 text-overflow: ellipsis;
 max-width: 400px;
 margin-bottom: 10px;
 font-weight: bold;
 padding: 0px 20px;
 box-sizing: border-box;
 cursor: pointer;

}

.userProfileDetailsPage * #websiteBox i{
    margin-right: 5px;
    -webkit-text-stroke: thin;
}

body.mobileweb .userProfileDetailsPage * #websiteBox:hover,
.userProfileDetailsPage * #websiteBox:active{
    opacity: 0.7;
}

body.browser.mobileweb > .userProfileDetailsPage .basicArtistInfo .bioWrapper > .carouselWrapper > .owl-wrapper-outer, 
body.browser.mobileweb > .userProfileDetailsPage .basicArtistInfo .bioWrapper > .carouselWrapper > .owl-wrapper-outer > .owl-wrapper,
body.browser.mobileweb > .userProfileDetailsPage .basicArtistInfo .bioWrapper > .carouselWrapper > .owl-wrapper-outer > .owl-wrapper > .owl-item .carouselItem,
body.browser.mobileweb > .userProfileDetailsPage * .userInfo {
  height: 360px;
  max-height: 360px;
}
.userProfileDetailsPage * .userInfo {
    height: 360px;
    width: 100%;
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-flex-direction: column;
    -webkit-align-items: center;
}
body.browser.mobileweb > .userProfileDetailsPage * #userProfileImage,
body.browser.mobileweb > .userProfileDetailsPage * #userProfileImage:hover,
body.browser.mobileweb > .userProfileDetailsPage * #userProfileImage:active{
    height: 100px;
    -ms-flex: 0 0 100px;
    -webkit-box-flex: 0;
    flex: 0 0 100px;
    -webkit-flex: 0 0 100px;
    width:100px;
    overflow: hidden !important;    
    margin-bottom: 20px;
    /* position: absolute; */
}
.playlistDetailsPage #editProfilePicBtn,
.playlistDetailsPage  #editTrebbleCoverPicBtn,
.playlistDetailsPage   #fullCoverArtImage > .overlay {
  display: none;
}
#editProfilePicBtn > i, #editTrebbleCoverPicBtn > i{
  font-size: var(--text-4xl);
  margin: 0px 10px;
}
#editProfilePicBtn > span, #editTrebbleCoverPicBtn > span{
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex-direction: column;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-align-content: center;
    margin-right: 10px;
}
.userProfileDetailsPage * #userProfileImage > #editProfilePicBtn{
  position: relative;
  top: 85px;
  font-size: 85%;
  width: 108px;
  display: none;
  margin: auto;
  height: 20px;
  font-weight: 300;
}
/*body.browser.mobilebrowser > .userProfileDetailsPage * #userProfileImage.editable:hover > #editProfilePicBtn,
body.browser.mobileweb > .userProfileDetailsPage * #userProfileImage.editable:hover > #editProfilePicBtn,
.userProfileDetailsPage * #userProfileImage.editable:active > #editProfilePicBtn{
 display: -ms-flexbox;
 display: flex;
 display: -webkit-flex;
 -ms-flex-pack: center;
 justify-content: center;
 -webkit-justify-content: center;
 -ms-flex-direction: row;
 flex-direction: row;
 -webkit-flex-direction: row;
 animation: fadeIn 0.5s;
 animation: fadeIn 0.5s;
 }*/
 .userProfileDetailsPage * #userProfileImage {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: var(--color-white-20);
    height: 100px;
    -ms-flex: 0 0 100px;
    flex: 0 0 100px;
    -webkit-flex: 0 0 100px;
    width: 100px;
    border-radius: 50%;
    margin: 0px;
    margin-bottom: 20px;
}

body.browser.mobileweb > .userProfileDetailsPage .basicArtistInfo .bioWrapper,
body.browser.mobileweb > .userProfileDetailsPage .basicArtistInfo .bioWrapper > .carouselWrapper {
  max-height: 380px;
  padding: 0px;
}
.userProfileDetailsPage .basicArtistInfo .bioWrapper,
.userProfileDetailsPage .basicArtistInfo .bioWrapper > .carouselWrapper {
    max-height: 380px;
    padding: 0px;
}
.userProfileDetailsPage .basicArtistInfo .bioWrapper > .carouselWrapper > .owl-wrapper-outer,
.userProfileDetailsPage .basicArtistInfo .bioWrapper > .carouselWrapper > .owl-wrapper-outer > .owl-wrapper,
.userProfileDetailsPage .basicArtistInfo .bioWrapper > .carouselWrapper > .owl-wrapper-outer > .owl-wrapper > .owl-item .carouselItem {
    max-height: 380px;
    padding: 0px;
}
#artistDetailsPage * .basicArtistInfo .bioWrapper {
    max-height: 100px;
}
.basicArtistInfo .bioWrapper > a {
    text-decoration: none;
    color: var(--trebble-primary);
    font-weight: 400;
}
#playerFooter.browser * div#songSocialInfoWrapper {
    color: var(--text-dark);
}
#playerFooter.browser * div#songSocialInfoWrapper > .highlight {
    color: var(--text-dark);
    opacity: 0.5;
}
#songSocialInfoWrapper > .highlight {
    color: white;
}
a.ui-link.highlight,
.highlight {
    color: var(--trebble-primary);
    font-weight: 400;
}
body.browser.mobileweb a.ui-link.highlight:hover {
    background-color: var(--trebble-primary);
    color: white;
}
#expandableSongOptions > div > div > a > span.ui-btn-inner {
    padding-right: 0px;
    padding-left: 0px;
    margin-right: 0px;
    margin-left: 0px;
}
#homeOptionsWrapper {
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-justify-content: center;
    height: 500px;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex-direction: column;
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
    margin-top: 45px;
}
.popupContent * #homeOptionsWrapper > div {
    color: white;
}
.popupContent * #homeOptionsWrapper > div:active {
    background-color: var(--color-white-5);
}
#homeOptionsWrapper > div {
    box-shadow: inset 0px 0px 85px var(--color-black-50);
    padding: 10px;
    /* opacity: 0.8; */
    
    border-radius: 1px;
    margin-bottom: 2px;
    margin-right: 20px;
    margin-top: 2px;
    margin-left: 20px;
    color: var(--text-dark);
    
    display: block;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    -webkit-flex: 1 1 auto;
}
#homeOptionsWrapper > div {

}
#homeOptionsWrapper > div {
    border-bottom: var(--border-1) solid var(--color-white-5);
}
#homeOptionsWrapper > div > a > p {
    font-weight: 400;
    color: var(--text-dark);
    font-size: var(--text-sm);
}
.popupContent * #homeOptionsWrapper > div > a > p {
    color: white;
}
/*


#homeOptionsWrapper > div#MyLibraryOption  {

}

#homeOptionsWrapper > div#PlaylistPodcastsOption {
 background-color: var(--color-blue-400);

}

#homeOptionsWrapper > div#FeedActivityOption {
 background-color: var(--color-orange-500);

}





#homeOptionsWrapper > div#SettingOption  {
 background-color: var(--color-gray-600);

}
*/

.popupContent * #homeOptionsWrapper > div > a {
    font-size: var(--text-xl);
    color: white;
    font-weight: 300;
}
#homeOptionsWrapper > div > a {
    /* margin-top: 20px; */
    /* margin-left: 20px; */
    
    width: 100%;
    height: 100%;
    display: inline-block;
    color: var(--text-dark);
    text-decoration: none;
    font-size: var(--text-base);
    font-weight: 400;
    /*font-weight: 300;*/
    
    -ms-flex-align: inherit;
    align-items: inherit;
    -webkit-align-items: inherit;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    -webkit-flex: 1 1 auto;
    /* margin: auto; */
}
#FeedActivityPage > div[data-role=header] {
    border: var(--border-1) solid var(--color-orange-400);
    background-color: var(--color-orange-300);
}
#FeedActivityPage > div[data-role=header] > #homeHeaderButton,
#FeedActivityPage > div[data-role=header] > #helpHeaderButton {
    background-color: var(--color-orange-300);
    color: var(--color-orange-400);
}
#PlaylistPodcastsPage > div[data-role=header]
/*, .trebbleEditInfoPage > div[data-role=header]*/

{
  
}
#PlaylistPodcastsPage > div[data-role=header] > #homeHeaderButton,
#PlaylistPodcastsPage > div[data-role=header] > #helpHeaderButton {

    
    color: var(--trebble-primary-dark);
}
#OnAirPage > div[data-role=header] {

}
#OnAirPage > div[data-role=header] > #homeHeaderButton,
#OnAirPage > div[data-role=header] > #helpHeaderButton {

}
#SettingPage > div[data-role=header] {
    border: var(--border-1) solid var(--color-orange-500);
    background-color: var(--color-orange-300);
}
#SettingPage > div[data-role=header] > #homeHeaderButton,
#SettingPage > div[data-role=header] > #helpHeaderButton {
    background-color: var(--color-orange-300);
    color: var(--color-orange-500);
}
/*Library sub Menu*/

#songPoppupMenu > div > ul > li {
    
    background-color: transparent;
    /* border-bottom: var(--border-0); */
    
    color: white;
    font-weight: 400;
}
#songPoppupMenu > div > ul > li {
    padding-left: 0.5em;
    box-sizing: border-box;
}
#songPoppupMenu,
#loginPoppup,
#paidAccountSubsPopup {
    color: white;
    opacity: 0.94;
    border: var(--border-0);
    height: 507px;
    width: 340px;
    background-color: transparent;
    animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
}
.embedded * #loginPoppup {
    max-width: 400px;
}
#loginPoppup * .ui-content > div:first-child{
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex-direction: column;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-align-content: center;
}

#loginPoppup #loginForm .formField{
    margin: 0px;
    width: 100% !important;
}

#loginPoppup * .ui-content > div:first-child,
#loginPoppup * .ui-content > div:first-child > div{
    max-width: 400px;
    width: 100%;
    
}
#loginPoppup .twoButtonsSet{
    box-sizing:border-box;
    padding: 1.25em;
}

[data-role=popup] ::shadow input,
[data-role=popup] input::placeholder{
  color: white !important;
}

#songPoppupMenu.whatsnew > .popupContent {
    display: flex;
}
#songPoppupMenu > div > ul > li.ui-li-divider {
    font-weight: 300;
    color: white;
    padding: 0px;
    border: var(--border-0);
    /*font-size: var(--text-xl);*/
    /*border-bottom: var(--border-1) solid var(--color-white-20);*/
}
.commentTitle {
    color: white;
    font-weight: 300;
    font-size: var(--text-lg);
}
.commentBox {
    font-size: var(--text-lg);
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-direction: row;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-align-content: center;
    width: 100%;
    margin: 0px !important;
    height: 39px;
}
.commentBox > .commentForm {
    width: 100%;
}
.commentBox > .commentForm > #comment {
    height: 39px;
    border: var(--border-1) solid white !important;
    background-color: transparent;
    color: white;
    width: 100%;
    font-weight: 400;
    /*font-weight: 300;*/
}
.commentBox > #commentBtn {
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-align-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-justify-content: center;
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    -webkit-flex: 0 1 auto;
    width: 50px;
    /* background-color: var(--color-gray-100); */
    /* border-color: var(--color-gray-100); */
    
    color: white;
    /* line-height: 30px; */
    
    font-size: var(--text-3xl);
    font-weight: 300;
}
.commentBox > #commentBtn:active {
    background-color: var(--trebble-primary);
    border-color: var(--trebble-primary);
    color: white;
}

input:not([type]),
input[type=date]:not(.browser-default),
input[type=datetime-local]:not(.browser-default),
input[type=datetime]:not(.browser-default),
input[type=email]:not(.browser-default), 
input[type=number]:not(.browser-default), 
input[type=password]:not(.browser-default), 
input[type=search]:not(.browser-default),
input[type=tel]:not(.browser-default), 
input[type=text]:not(.browser-default),
input[type=time]:not(.browser-default), 
input[type=url]:not(.browser-default),
textarea.materialize-textarea{
    padding: 4px;
}

input:not([type]):focus-visible,
input[type=date]:not(.browser-default):focus-visible,
input[type=datetime-local]:not(.browser-default):focus-visible,
input[type=datetime]:not(.browser-default):focus-visible,
input[type=email]:not(.browser-default):focus-visible,
input[type=number]:not(.browser-default):focus-visible,
input[type=password]:not(.browser-default):focus-visible,
input[type=search]:not(.browser-default):focus-visible,
input[type=tel]:not(.browser-default):focus-visible,
input[type=text]:not(.browser-default):focus-visible,
input[type=time]:not(.browser-default):focus-visible,
input[type=url]:not(.browser-default):focus-visible,
textarea.materialize-textarea:focus-visible,
textarea.materialize-textarea:focus-within
{
    /* outline: var(--color-gray-150) solid 3px;
    outline-offset: 4px;*/
}

a.ui-link:focus-visible,
.forgotPasswordLink
.ui-btn:focus-visible,
select:focus-visible,
select:focus-within,
button:focus-visible{
    outline: var(--trebble-primary-30) solid 3px;
    /*outline: var(--color-gray-150) solid 3px;*/
    outline-offset: 4px;
}


.div-table {
  display: table;         
  width: auto;         
  border-spacing: 5px; /* cellspacing:poor IE support for  this */
}
.div-table-row {
  display: table-row;
  width: auto;
  clear: both;
}
.div-table-col {
  float: left; /* fix for  buggy browsers */
  display: table-column;         
}

#songPoppupMenu > div,
.popup-menu-content > div {
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex-direction: column;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-align-items: center;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
}
#songPoppupMenu > div.popupContent.top,
.popup-menu-content > div.popupContent.top {
    padding-right: 10px;
    padding-left: 10px;
    overflow: auto;
    box-sizing: border-box;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-justify-content: flex-start;
}
#songPoppupMenu > div.popupContent.bottom,
.popup-menu-content > div.popupContent.bottom {
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-justify-content: flex-end;
}
#songPoppupMenu > div > ul,
[data-role=popup].popup-menu-content > div > ul {
    overflow: auto;
    /* height: 100%;*/
    
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
    box-sizing: border-box;
    max-width: 700px;
}
.c-bottom-sheet ul.trebbleOptionList > li > a,
.trebble-context-menu li.context-menu-item > a,
#songPoppupMenu > div > ul > li > a,
[data-role=popup].popup-menu-content > div > ul > li > a,
[data-role=popup].popup-menu-content > div > ul > li > .toggle_switch_menu,
.c-bottom-sheet ul.trebbleOptionList > li > .toggle_switch_menu  {
    box-sizing: border-box;
    font-size: var(--text-base);
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
    padding-left: 5px;
    /*border-bottom: var(--border-1) solid var(--color-white-5) !important;*/
    font-weight: 400;
    /*font-weight: 300;*/
    
    height: 54px;
    border: var(--border-0);
    color: white;
    background-color: transparent;
    text-decoration: none;
    -ms-flex-align: center;
    align-items: center;
    -webkit-align-items: center;
}

.c-bottom-sheet ul.trebbleOptionList > li.divider ,
.trebble-context-menu li.divider ,
#songPoppupMenu > div > ul > li.divider,
[data-role=popup].popup-menu-content > div > ul > li.divider,
.c-bottom-sheet ul.trebbleOptionList > li.divider {
    border: var(--border-0);
    border-bottom: var(--border-1) solid var(--color-black-10) !important;
    padding: 0px;
    background: transparent;
}

body.darkTheme .c-bottom-sheet ul.trebbleOptionList > li.divider ,
body.darkTheme .trebble-context-menu li.divider ,
body.darkTheme #songPoppupMenu > div > ul > li.divider,
body.darkTheme [data-role=popup].popup-menu-content > div > ul > li.divider,
body.darkTheme .c-bottom-sheet ul.trebbleOptionList > li.divider {
    border: var(--border-0);
    border-bottom: var(--border-1) solid var(--color-white-10) !important;
    padding: 0px;
    background: transparent;
}

.trebble-context-menu li.context-menu-item > a > i,
.c-bottom-sheet ul.trebbleOptionList > li > a > i,
#songPoppupMenu > div > ul > li > a > i,
[data-role=popup].popup-menu-content > div > ul > li > a > i,
[data-role=popup].popup-menu-content > div > ul > li > a > .menu_icon {
    font-size: var(--text-xl);
    margin-right: 12px;
    opacity: .8;
    
    min-width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.trebble-context-menu li.context-menu-item > a:active,
#songPoppupMenu > div > ul > li > a:active,
.c-bottom-sheet ul.trebbleOptionList > li > a:active,
[data-role=popup].popup-menu-content > div > ul > li > a:active,
[data-role=popup].popup-menu-content > div > ul > li > .toggle_switch_menu:active,
.c-bottom-sheet ul.trebbleOptionList > li > .toggle_switch_menu:active {
    background-color: var(--color-white-20);
    color: white;
}
#songPoppupMenu-popup,
#songIdifiedInfo-popup,
#songAddedFromSCInfo-popup {
    /*left: 10px;
    top: 10px;*/
    
    left: 0px;
    top: 0px;
    opacity: 0;
    animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    z-index: 10000;
}

#songPoppupMenu-screen,
#songIdifiedInfo-screen,
#songAddedFromSCInfo-screen ,
#loginPoppup-screen/*,
#paidAccountSubsPopup-screen*/{
    opacity: 0;
    position: fixed;
    animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    border-color: var(--color-black-100);
    background-color: var(--color-black-100);
}
body.ios > #songPoppupMenu-screen.in ~ .c-bottom-sheet.active,
body.browser > #songPoppupMenu-screen.in ~ .c-bottom-sheet.active,
body.ios > #songPoppupMenu-screen.in ~ .ui-page-active,
body.browser > #songPoppupMenu-screen.in ~ .ui-page-active,
body.browser > #loginPoppup-screen.in ~ .ui-page-active,
body.ios > #loginPoppup-screen.in ~ .ui-page-active/*,
body.browser > #paidAccountSubsPopup-screen.in ~ .ui-page-active,
body.ios > #paidAccountSubsPopup-screen.in ~ .ui-page-active*/ {
    -webkit-filter: blur(20px);
    -moz-filter: blur(20px);
    -o-filter: blur(20px);
    -ms-filter: blur(20px);
    filter: blur(20px);


    background-color: transparent;
    border: var(--border-0);
}
body.ios > #songIdifiedInfo-screen.in ~ .ui-page-active,
body.browser > #songIdifiedInfo-screen.in ~ .ui-page-active,
body.browser > #loginPoppup-screen.in ~ .ui-page-active,
body.ios > #loginPoppup-screen.in ~ .ui-page-active  {
    -webkit-filter: blur(20px);
    -moz-filter: blur(20px);
    -o-filter: blur(20px);
    -ms-filter: blur(20px);
    filter: blur(20px);

    background-color: transparent;
}

.ui-popup-screen:not(.in) ~ #songAddedFromSCInfo-popup,
.ui-popup-screen:not(.in) ~ #songIdifiedInfo-popup,
.ui-popup-screen:not(.in) ~ #songPoppupMenu-popup,
.ui-popup-screen:not(.in) ~ #songPoppupMenu-popup{
    display:none;
}
body.ios > #songAddedFromSCInfo-screen.in ~ .ui-page-active,
body.browser > #songAddedFromSCInfo-screen.in ~ .ui-page-active,
body.browser > #loginPoppup-screen.in ~ .ui-page-active,
body.ios > #loginPoppup-screen.in ~ .ui-page-active  {
    -webkit-filter: blur(20px);
    -moz-filter: blur(20px);
    -o-filter: blur(20px);
    -ms-filter: blur(20px);
    filter: blur(20px);
    background-color: transparent;
}
body.ios > #songPoppupMenu-screen.in ~ #playerFloating,
body.browser > #songPoppupMenu-screen.in ~ #playerFloating,
body.ios > #songPoppupMenu-screen.in ~ [data-role=footer],
body.browser > #songPoppupMenu-screen.in ~ [data-role=footer],
body.browser > #loginPoppup-screen.in ~ [data-role=footer],
body.ios > #loginPoppup-screen.in ~ [data-role=footer]/*,
body.browser > #paidAccountSubsPopup-screen.in ~ [data-role=footer],
body.ios > #paidAccountSubsPopup-screen.in ~ [data-role=footer] */ {
    -webkit-filter: blur(20px);
    -moz-filter: blur(20px);
    -o-filter: blur(20px);
    -ms-filter: blur(20px);
    filter: blur(20px);
    background-color: transparent;
    border: var(--border-0);
}
body.ios > #songIdifiedInfo-screen.in ~ [data-role=footer],
body.browser > #songIdifiedInfo-screen.in ~ [data-role=footer],
body.browser > #loginPoppup-screen.in ~ [data-role=footer],
body.ios > #loginPoppup-screen.in ~ [data-role=footer]  {
    -webkit-filter: blur(20px);
    -moz-filter: blur(20px);
    -o-filter: blur(20px);
    -ms-filter: blur(20px);
    filter: blur(20px);
    background-color: transparent;
}
body.ios > #songAddedFromSCInfo-screen.in ~ [data-role=footer],
body.browser > #songAddedFromSCInfo-screen.in ~ [data-role=footer],
body.browser > #loginPoppup-screen.in ~ [data-role=footer],
body.ios > #loginPoppup-screen.in ~ [data-role=footer] {
    -webkit-filter: blur(20px);
    -moz-filter: blur(20px);
    -o-filter: blur(20px);
    -ms-filter: blur(20px);
    filter: blur(20px);
    background-color: transparent;
}
body.ios > #songPoppupMenu-screen.out ~ .ui-page-active,
body.browser > #songPoppupMenu-screen.out ~ .ui-page-active,
body.browser > #loginPoppup-screen.out ~ .ui-page-active,
body.ios > #loginPoppup-screen.out ~ .ui-page-active/*,
body.browser > #paidAccountSubsPopup-screen.out ~ .ui-page-active,
body.ios > #paidAccountSubsPopup-screen.out ~ .ui-page-active */{
    -webkit-filter: blur(0px);
    -moz-filter: blur(0px);
    -o-filter: blur(0px);
    -ms-filter: blur(0px);
    filter: blur(0px);
}
body.ios > #songIdifiedInfo-screen.out ~ .ui-page-active,
body.browser > #songIdifiedInfo-screen.out ~ .ui-page-active,
body.browser > #loginPoppup-screen.out ~ .ui-page-active,
body.ios > #loginPoppup-screen.out ~ .ui-page-active  {
    -webkit-filter: blur(0px);
    -moz-filter: blur(0px);
    -o-filter: blur(0px);
    -ms-filter: blur(0px);
    filter: blur(0px);
}
body.ios > #songAddedFromSCInfo-screen.out ~ .ui-page-active,
body.browser > #songAddedFromSCInfo-screen.out ~ .ui-page-active,
body.browser > #loginPoppup-screen.out ~ .ui-page-active,
body.ios > #loginPoppup-screen.out ~ .ui-page-active  {
    -webkit-filter: blur(0px);
    -moz-filter: blur(0px);
    -o-filter: blur(0px);
    -ms-filter: blur(0px);
    filter: blur(0px);
}
body.ios > #songPoppupMenu-screen.out ~ [data-role=footer],
body.browser > #songPoppupMenu-screen.out ~ [data-role=footer],
body.browser > #loginPoppup-screen.out ~ [data-role=footer],
body.ios > #loginPoppup-screen.out ~ [data-role=footer] /*,
body.browser > #paidAccountSubsPopup-screen.out ~ [data-role=footer],
body.ios > #paidAccountSubsPopup-screen.out ~ [data-role=footer] */ {
    -webkit-filter: blur(0px);
    -moz-filter: blur(0px);
    -o-filter: blur(0px);
    -ms-filter: blur(0px);
    filter: blur(0px);
}
body.ios > #songIdifiedInfo-screen.out ~ [data-role=footer],
body.browser > #songIdifiedInfo-screen.out ~ [data-role=footer],
body.browser > #loginPoppup-screen.out ~ [data-role=footer],
body.ios > #loginPoppup-screen.out ~ [data-role=footer]  {
    -webkit-filter: blur(0px);
    -moz-filter: blur(0px);
    -o-filter: blur(0px);
    -ms-filter: blur(0px);
    filter: blur(0px);
}
body.ios > #songAddedFromSCInfo-screen.out ~ [data-role=footer],
body.browser > #songAddedFromSCInfo-screen.out ~ [data-role=footer],
body.browser > #loginPoppup-screen.out ~ [data-role=footer],
body.ios > #loginPoppup-screen.out ~ [data-role=footer]  {
    -webkit-filter: blur(0px);
    -moz-filter: blur(0px);
    -o-filter: blur(0px);
    -ms-filter: blur(0px);
    filter: blur(0px);
}
body.ios > #songPoppupMenu-screen ~ .ui-page-active,
body.browser > #songPoppupMenu-screen ~ .ui-page-active ,
body.browser > #loginPoppup-screen.out ~ .ui-page-active,
body.ios > #loginPoppup-screen.out ~ .ui-page-active/*,
body.browser > #paidAccountSubsPopup-screen.out ~ .ui-page-active,
body.ios > #paidAccountSubsPopup-screen.out ~ .ui-page-active */{
    -webkit-filter: blur(0px);
    -moz-filter: blur(0px);
    -o-filter: blur(0px);
    -ms-filter: blur(0px);
    filter: blur(0px);
}
body.ios > #songIdifiedInfo-screen ~ .ui-page-active,
body.browser > #songIdifiedInfo-screen ~ .ui-page-active,
body.browser > #loginPoppup-screen.out ~ .ui-page-active,
body.ios > #loginPoppup-screen.out ~ .ui-page-active {
    -webkit-filter: blur(0px);
    -moz-filter: blur(0px);
    -o-filter: blur(0px);
    -ms-filter: blur(0px);
    filter: blur(0px);
}
body.ios > #songAddedFromSCInfo-screen ~ .ui-page-active,
body.browser > #songAddedFromSCInfo-screen ~ .ui-page-active,
body.browser > #loginPoppup-screen ~ .ui-page-active,
body.ios > #loginPoppup-screen ~ .ui-page-active {
    -webkit-filter: blur(0px);
    -moz-filter: blur(0px);
    -o-filter: blur(0px);
    -ms-filter: blur(0px);
    filter: blur(0px);
}
body.ios > #songPoppupMenu-screen,
/*body.ios > #paidAccountSubsPopup-screen,*/
body.ios > #songIdifiedInfo-screen,
body.ios > #songAddedFromSCInfo-screen,
body.browser > #songPoppupMenu-screen,
/*body.browser > #paidAccountSubsPopup-screen,*/
body.browser > #songIdifiedInfo-screen,
body.browser > #songAddedFromSCInfo-screen,
body.browser > #loginPoppup-screen,
body.ios > #loginPoppup-screen  {
    opacity: 0;
    position: fixed;
    animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    border-color: var(--color-black-80);
    background-color: var(--color-black-80);
    z-index: 10000;
}

body.ios > #paidAccountSubsPopup-screen,
body.browser > #paidAccountSubsPopup-screen{
    opacity: 1;
    border-color: var(--color-gray-100);
    background-color: var(--color-gray-100);
}

#paidAccountSubsPopup-popup{
    background-color: transparent;
}

#songPoppupMenu-screen.in,
/*#paidAccountSubsPopup-screen.in,*/
#songIdifiedInfo-screen.in,
#songAddedFromSCInfo-screen.in,
#loginPoppup-screen.in {
    opacity: 0.94;
    animation-duration: 325ms;
    animation-name: popupfadein;
    -moz-animation-duration: 325ms;
    -moz-animation-name: popupfadein;
    animation-duration: 325ms;
    animation-duration: 325ms;
    animation-name: popupfadein;
    animation-name: popupfadein;
}
#loginPoppup-screen.in{
    display: none;
}
#songPoppupMenu-screen.out,
/*#paidAccountSubsPopup-screen.out,*/
#songIdifiedInfo-screen.out,
#songAddedFromSCInfo-screen.out,
#loginPoppup-screen.out {
    opacity: 0;
    animation-duration: 325ms;
    animation-name: popupfadeout;
    -moz-animation-duration: 325ms;
    -moz-animation-name: popupfadeout;
    animation-duration: 325ms;
    animation-duration: 325ms;
    animation-name: popupfadeout;
    animation-name: popupfadeout;
}
#songPoppupMenu-screen.whatsnew,
body.ios > #songPoppupMenu-screen.whatsnew,
body.browser > #songPoppupMenu-screen.whatsnew,
body.browser > #loginPoppup-screen.whatsnew,
body.ios > #loginPoppup-screen.whatsnew {
    /*background-color: var(--error);*/
    /*background: linear-gradient(-45deg, var(--color-pink-alt), var(--color-orange-200));
    background: -webkit-linear-gradient(-45deg, var(--color-pink-alt), var(--color-orange-200));*/
}
@-webkit-keyframes popupfadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 0.96;
    }
}
@keyframes popupfadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 0.96;
    }
}
@-webkit-keyframes popupfadeout {
    from {
        opacity: 0.96;
    }
    to {
        opacity: 0;
    }
}
@keyframes popupfadeout {
    from {
        opacity: 0.96;
    }
    to {
        opacity: 0;
    }
}

/*
@media (max-width: 380px), (max-height: 380px) and   (orientation: landscape) {
  body.mobilebrowser.ios > #songPoppupMenu-screen.in ~ .ui-page-active,
  body.browser.mobilebrowser > #songPoppupMenu-screen.in ~ .ui-page-active,
  body.mobilebrowser.mobilebrowser.browser > #loginPoppup-screen.in ~ .ui-page-active,
  body.mobilebrowser.ios > #loginPoppup-screen.in ~ .ui-page-active,
  body.mobilebrowser.browser > #songPoppupMenu-placeholder ~ .ui-page-active,
  body.mobilebrowser.browser > #loginPoppup-placeholder ~ .ui-page-active,
  body.mobilebrowser.ios > #songPoppupMenu-placeholder ~ .ui-page-active,
  body.mobilebrowser.ios > #loginPoppup-placeholder ~ .ui-page-active{
      -webkit-filter:none;
      -moz-filter: none;
      -o-filter: none;
      -ms-filter: none;
      filter: none;
  }
  body.mobilebrowser.ios > #songPoppupMenu-screen,
  body.mobilebrowser.ios > #songIdifiedInfo-screen,
  body.mobilebrowser.ios > #songAddedFromSCInfo-screen,
  body.mobilebrowser.browser > #songPoppupMenu-screen,
  body.mobilebrowser.browser > #songIdifiedInfo-screen,
  body.mobilebrowser.browser > #songAddedFromSCInfo-screen,
  body.mobilebrowser.browser > #loginPoppup-screen,
  body.mobilebrowser.ios > #loginPoppup-screen {

    border-color: var(--color-black-100);
    background-color: var(--color-black-100);
}

}
*/

#librarySubMenuWrapper > div.ui-controlgroup-controls {
    width: 100%;
    height: 50px;
}
#librarySubMenuWrapper > div > a.ui-btn-down-a > span.ui-btn-inner {
    color: white;
}
#librarySubMenuWrapper > div > a > span.ui-btn-inner {
    padding-right: 0px;
    font-size: var(--text-xs);
    padding-left: 0px;
    margin-right: 0px;
    margin-left: 0px;
    font-weight: 400;
    line-height: 30px;
    color: var(--color-gray-700);
}
#librarySubMenuWrapper > div > a {
    height: 90%;
}
#librarySubMenuWrapper > div > a.ui-btn-down-a {
    color: white;
}
div.songLibraryWrapper > .infiniteListItem {
    background-color: inherit;
    border-color: inherit;
    position: relative;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
    overflow: hidden;
}

div.songLibraryWrapper > .infiniteListItem.textonly{
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-flex-direction: row;
    -webkit-justify-content: center;
}
div.songLibraryWrapper > .infiniteListItem.textonly > .textwrapper{
    /*flex: 1 1 auto;
    -webkit-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    -o-flex: 1 1 auto;*/
    width: 100%;

}

div.songLibraryWrapper.showCoverArt > .infiniteListItem.textonly > .textwrapper{
    width: calc(100% - 63px);
    padding: 6px;

}


.songLibraryWrapper > .infiniteListItem.textonly > .playIconWrapper,
.songLibraryWrapper > .infiniteListItem.textAndImage > #imageContainer > .playIconWrapper {
    display: none;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex-direction: column;
    justify-content: center;
    align-items: center;
    -webkit-justify-content: center;
    -webkit-align-items: center;
    -ms-justify-content: center;
    -ms-align-items: center;
}

.songLibraryWrapper > .infiniteListItem.textonly > .playIconWrapper > .playicon,
.songLibraryWrapper > .infiniteListItem.textAndImage > #imageContainer > .playIconWrapper > .playicon {
   justify-content: center;
   align-items: center;
   -webkit-justify-content: center;
   -webkit-align-items: center;
   -ms-justify-content: center;
   -ms-align-items: center;
   display: -ms-flexbox;
   display: flex;
   display: -webkit-flex;
   width: 40px;
   height: 40px;
   padding-left: 4px;
   font-size: var(--text-3xl-px);
   color:var(--trebble-primary);
}

body.mobileweb .songLibraryWrapper > .infiniteListItem.textonly:hover > .playIconWrapper ,
body.mobileweb .songLibraryWrapper > .infiniteListItem.textonly:active > .playIconWrapper ,
body.mobileweb .songLibraryWrapper > .infiniteListItem.textAndImage:hover  > #imageContainer > .playIconWrapper ,
body.mobileweb .songLibraryWrapper > .infiniteListItem.textAndImage:active  > #imageContainer > .playIconWrapper {
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
}

div.songLibraryWrapper.currentlyPlaying > .infiniteListItem > .songTitleLibrary,
div.songLibraryWrapper.currentlyPlaying > .infiniteListItem > .songArtistLibrary {
    color: var(--trebble-primary) !important;
}
div.songLibraryWrapper.songlistItem > .infiniteListItem,
div.songLibraryWrapper.albumlistItem > .infiniteListItem,
div.songLibraryWrapper.artistlistItem > .infiniteListItem {
    padding: 0.2em;
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-flex-direction: row;
    -webkit-justify-content: center;
}
div.songLibraryWrapper.songlistItem > .infiniteListItem:active,
div.songLibraryWrapper.albumlistItem > .infiniteListItem:active,
div.songLibraryWrapper.artistlistItem > .infiniteListItem:active {
    color: white;
}

.infiniteListItem  .songArtistLibrary .radio_type_tag.journey_tag{
    padding-right: 4px;
}

.infiniteListItem  .songArtistLibrary .pe-7s-sun.journeyIcon,
.infiniteListItem  .songArtistLibrary .ion-ios7-time-outline.journeyOultlineListIcon{
    font-weight: bold;
    margin-right: 5px;
}
.infiniteListItem  .songArtistLibrary .fontello-icon-list.journeyOultlineListIcon{
    font-size: var(--text-base-px);
}

div.songLibraryWrapper > .infiniteListItem.songInfo > .songAndArtist {
    -ms-flex: 1 1 calc(100% - 52px);
    flex: 1 1 calc(100% - 52px);
    -webkit-flex: 1 1 calc(100% - 52px);
    -moz-flex: 1 1 calc(100% - 52px);
    -o-flex: 1 1 calc(100% - 52px);
    /*max-width: calc(100% - 52px - 1em);*/
    max-width: calc(100% - 52px);
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-justify-content: center;
    align-items: flex-start;
    -webkit-align-items: flex-start;
    -ms-align-items: flex-start;
    -moz-align-items: flex-start;
    -o-align-items: flex-start;

}
.songAndArtist {

} 

div.songLibraryWrapper > .infiniteListItem > .imageContainer {
    -ms-flex: 0 0 52px;
    flex: 0 0 52px;
    -webkit-flex: 0 0 52px;
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
    height: 52px;
    width: 52px;
    background-color: var(--color-gray-100);
    position: relative;
}
.lighttext * div.songLibraryWrapper > .infiniteListItem > .imageContainer {
    background-color: transparent;
}
.youtubeList > .infiniteListOuterContainer > #innerContainer * div.songLibraryWrapper > .infiniteListItem > .imageContainer {
    width: 100px;
    flex: 0 0 100px;
    -webkit-flex: 0 0 100px;
    -moz-flex: 0 0 100px;
    -ms-flex: 0 0 100px;
    height: 57px;
}
.youtubeList > .infiniteListOuterContainer > #innerContainer * div.songLibraryWrapper > .infiniteListItem > .songAndArtist {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    display: -webkit-flex;
    -webkit-flex-direction: column;
    -webkit-justify-content: center;
    display: -moz-flex;
    -moz-flex-direction: column;
    -moz-justify-content: center;
    display: -ms-flex;
    -ms-flex-direction: column;
    -ms-justify-content: center;
    overflow: hidden;
}
div.songLibraryWrapper.artistlistItem > .infiniteListItem > .imageContainer {
    border-radius: 50%;
}
div.songLibraryWrapper > .infiniteListItem > .imageContainer > img {
    object-fit: cover;
    width: 100%;
    height: auto;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: var(--color-gray-100);
}
.lighttext * div.songLibraryWrapper > .infiniteListItem > .imageContainer > img {
    background-color: var(--color-white-20);
}
div.songLibraryWrapper.artistlistItem > .infiniteListItem > .imageContainer > img {
    border-radius: 50%;
}
div.songOptionBtnWrapper > .infiniteListItem.ui-btn.ui-btn-up-a.ui-shadow.ui-btn-corner-all.ui-btn-inline {
    object-fit: cover;
    width: 100%;
    height: auto;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    background-color: var(--color-gray-100);
}
[trebbleclass=playerSlider] >:first-child >:nth-child(2) {
    height: 2px;
    border: var(--border-0);
    margin-left: 0px;
}
.ui-slider-track > a:first-child {
    border-radius: 50%;
    -webkit-border-radius: 50%;
}
.side-main-menu > li > div > div > a > span[class*=fontello-icon] {
    font-size: var(--text-xl);
    margin-right: 5px;
    font-weight: 400;
    /*text-shadow: 2px 2px 2px var(--trebble-primary-dark);*/
}
.side-main-menu > li > div > div > a > span[class*=fontello-icon] {
    color: var(--color-gray-400);
    font-weight: 400;
}
.side-main-menu > li {
    height: 40px;
}
#songList,
#albumList,
#artistList,
#moreList,
/*#capsuleList,*/
#myFeedList,
#myNotificationsList {
    box-sizing: border-box;
    padding-bottom: 0px;
    padding-top: 0px;
    border: var(--border-0);
    /* background-color: var(--bg-light);*/

    padding-top: 6px;
    box-shadow: inset 0px 3px 6px 0px var(--color-black-20);
}
#myNotificationsList {
    background-color: var(--bg-light);
}
#userProfileWrapper {} 
#moreList,
#capsuleList {
    padding-left: 0px;
    padding-right: 0px;
}
#albumSongListWrapper {
    padding: 12px;
}
.songOptionBtnWrapper,
.capsuleBtnWrapper,
.gradesBtnWrapper,
.showCommentBtnWrapper,
.showInfoBtnWrapper,
.addToTrebbleBtnWrapper {
    margin-right: 0em;
    width: 50px;
    /* background-color: white; */
    /* border-bottom: var(--border-1) solid var(--color-gray-100);*/
    /* border-bottom: var(--border-1) solid var(--bg-light); */
}
div.songLibraryWrapper.songlistItem > .capsuleBtnWrapper,
div.songLibraryWrapper.albumlistItem > .capsuleBtnWrapper,
div.songLibraryWrapper.artistlistItem > .capsuleBtnWrapper,
div.songLibraryWrapper.songlistItem > .songOptionBtnWrapper,
div.songLibraryWrapper.albumlistItem > .actionButtonWrapper,
div.songLibraryWrapper.artistlistItem > .actionButtonWrapper {
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: row;
    display: -webkit-flex;
    width: auto;
    align-items: center;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    margin: 0px 4px;
}
div.songLibraryWrapper.songlistItem > .capsuleBtnWrapper > #songCapsuleOptionButton.enabled,
div.songLibraryWrapper.albumlistItem > .capsuleBtnWrapper > #songCapsuleOptionButton.enabled,
div.songLibraryWrapper.artistlistItem > .capsuleBtnWrapper > #songCapsuleOptionButton.enabled,
div.songLibraryWrapper.songlistItem > .songOptionBtnWrapper > #actionButton.enabled,
div.songLibraryWrapper.albumlistItem > .actionButtonWrapper > #actionButton.enabled,
div.songLibraryWrapper.artistlistItem > .actionButtonWrapper> #actionButton.enabled {
    width: 50px;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    display: -webkit-flex;
    -webkit-align-items: center;
    -webkit-justify-content: center;
    color: var(--text-muted-dark);
    font-size: var(--text-xl);
    background-color: transparent;
    position: relative;
    cursor: pointer;
}
.lighttext * div.songLibraryWrapper.songlistItem > .capsuleBtnWrapper > #songCapsuleOptionButton.enabled,
.lighttext * div.songLibraryWrapper.albumlistItem > .capsuleBtnWrapper > #songCapsuleOptionButton.enabled,
.lighttext * div.songLibraryWrapper.artistlistItem > .capsuleBtnWrapper > #songCapsuleOptionButton.enabled,
.lighttext * div.songLibraryWrapper.songlistItem > .songOptionBtnWrapper > #actionButton.enabled,
.lighttext * div.songLibraryWrapper.albumlistItem > .actionButtonWrapper > #actionButton.enabled,
.lighttext * div.songLibraryWrapper.artistlistItem > .actionButtonWrapper> #actionButton.enabled {
    color: var(--color-white-50)
}
[data-role=popup] * div.songLibraryWrapper.songlistItem > .capsuleBtnWrapper > #songCapsuleOptionButton.enabled,
[data-role=popup] * div.songLibraryWrapper.albumlistItem > .capsuleBtnWrapper > #songCapsuleOptionButton.enabled,
[data-role=popup] * div.songLibraryWrapper.artistlistItem > .capsuleBtnWrapper > #songCapsuleOptionButton.enabled,
[data-role=popup] * div.songLibraryWrapper.songlistItem > .songOptionBtnWrapper > #actionButton.enabled,
[data-role=popup] * div.songLibraryWrapper.albumlistItem > .actionButtonWrapper > #actionButton.enabled,
[data-role=popup] * div.songLibraryWrapper.artistlistItem > .actionButtonWrapper> #actionButton.enabled {
    color: var(--color-white-80);
}
div.songLibraryWrapper.songlistItem > .capsuleBtnWrapper > #songCapsuleOptionButton.enabled:active,
div.songLibraryWrapper.albumlistItem > .capsuleBtnWrapper > #songCapsuleOptionButton.enabled:active,
div.songLibraryWrapper.artistlistItem > .capsuleBtnWrapper > #songCapsuleOptionButton.enabled:active,
div.songLibraryWrapper.songlistItem > .songOptionBtnWrapper > #actionButton.enabled:active,
div.songLibraryWrapper.albumlistItem > .actionButtonWrapper > #actionButton.enabled:active,
div.songLibraryWrapper.artistlistItem > .actionButtonWrapper> #actionButton.enabled:active {
    background: var(--trebble-primary);
    border-color: var(--trebble-primary);
    color: var(--text-light);
}
.songOptionBtnWrapper > a#songLibraryOptionButtuon:active {
    color: white !important;
    background-color: var(--trebble-primary);
    border-color: var(--trebble-primary);
}
#songLibraryOptionButtuon,
#songCapsuleOptionButtuon,
#gradeOptionButtuon,
.showCommentBtnWrapper #showCommentOptionButton,
.showInfoBtnWrapper #showInfoOptionButton,
#addToTrebbleOptionButtuon {
    padding: 0px;
    margin-bottom: 0px;
    margin-top: 0px;
    margin-right: 0px;
    margin-left: 0px;
    /*background-color: var(--bg-light);*/

    background-color: transparent;
    border: var(--border-0);
    width: 100%;
    height: 100%;
    color: var(--text-muted-dark) !important;
    font-size: var(--text-xl);
    line-height: 59px;
    flex: 0 0 40px;
    -webkit-flex: 0 0 40px;
    -ms-flex: 0 0 40px;
    -o-flex: 0 0 40px;
}
.lighttext * #songLibraryOptionButtuon,

.lighttext * #gradeOptionButtuon,
.lighttext * .showCommentBtnWrapper #showCommentOptionButton,
.lighttext * .showInfoBtnWrapper #showInfoOptionButton,
.lighttext * #addToTrebbleOptionButtuon {
    color: var(--color-white-50) !important;
}
#songCapsuleOptionButtuon,
div.songLibraryWrapper.songlistItem > .capsuleBtnWrapper > #songCapsuleOptionButton.enabled,
#addToTrebbleOptionButtuon.ion-ios7-checkmark-outline,
#gradeOptionButtuon.ion-ios7-pricetags,
.showCommentBtnWrapper #showCommentOptionButton,
.showInfoBtnWrapper #showInfoOptionButton {
    color: var(--trebble-primary) !important;
    font-size: var(--text-xl);
    background-color: transparent;
}
body.browser.mobileweb #songCapsuleOptionButtuon:hover,
body.browser.mobileweb div.songLibraryWrapper.songlistItem > .capsuleBtnWrapper > #songCapsuleOptionButton.enabled:hover,
body.browser.mobileweb #addToTrebbleOptionButtuon.ion-ios7-checkmark-outline:hover,
body.browser.mobileweb #gradeOptionButtuon.ion-ios7-pricetags:hover,
body.browser.mobileweb .showCommentBtnWrapper #showCommentOptionButton:hover,
body.browser.mobileweb .showInfoBtnWrapper #showInfoOptionButton:hover {
    color: var(--trebble-primary) !important;
    font-size: var(--text-xl);
    background-color: var(--bg-overlay-dark);
}
body.browser.mobileweb .lighttext * #songCapsuleOptionButtuon:hover,
body.browser.mobileweb div.songLibraryWrapper.songlistItem > .capsuleBtnWrapper > #songCapsuleOptionButton.enabled:hover,
body.browser.mobileweb .lighttext * #addToTrebbleOptionButtuon.ion-ios7-checkmark-outline:hover,
body.browser.mobileweb .lighttext *#gradeOptionButtuon.ion-ios7-pricetags:hover,
body.browser.mobileweb .lighttext * .showCommentBtnWrapper #showCommentOptionButton:hover,
body.browser.mobileweb .lighttext * .showInfoBtnWrapper #showInfoOptionButton:hover {
    color: var(--trebble-primary) !important;
}
#songLibraryOptionButtuon:active {
    background: var(--trebble-primary);
    border-color: var(--trebble-primary);
    color: var(--text-light);
}
#songAddToQueueTooltip {
    font-size: var(--text-xs);
    color: white;
    top: 1px;
    background-color: transparent;
    height: 58px;
    position: absolute;
    width: 100%;
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
    -ms-flex-align: center;
    align-items: center;
    -webkit-align-items: center;
}
.lighttext * #songAddToQueueTooltip {
    color: white;
}
#songAddToQueueTooltip.optionOnPanRight {
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-justify-content: flex-start;
}
#songAddToQueueTooltip.optionOnPanLeft {
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-justify-content: flex-end;
    float: right;
}
/*#songAddToQueueTooltip.optionSelected {
 background-color: var(--trebble-primary-light);
 -webkit-transition: background-color 500ms ease-in;
 -moz-transition: background-color 500ms ease-in;
 -o-transition: background-color 500ms ease-in;
 transition: background-color 500ms ease-in;
 }*/

 #songAddToQueueTooltip.optionOnPanRight.optionLevel_1_valid {
    background-color: var(--pulse-green);
}
#songAddToQueueTooltip.optionOnPanRight.optionLevel_1_invalid {
    background-color: var(--color-gray-400);
}
#songAddToQueueTooltip.optionOnPanLeft.optionLevel_1_valid {
    background-color: var(--color-orange-500);
}
#songAddToQueueTooltip.optionOnPanLeft.optionLevel_1_invalid {
    background-color: var(--color-gray-400);
}
.commentForm >div > input#comment {
    background-color: transparent;
    border: var(--border-1) solid white;
    color: white;
    font-size: var(--text-base);
    height: 100px
}
#songAddToQueueTooltip.optionOnPanRight.optionLevel_1_valid.optionSelected,
#songAddToQueueTooltip.optionOnPanLeft.optionLevel_1_valid.optionSelected,
#songAddToQueueTooltip.optionOnPanRight.optionLevel_2_valid.optionSelected,
#songAddToQueueTooltip.optionOnPanLeft.optionLevel_2_valid.optionSelected {
    background-color: var(--trebble-primary-light);
    transition: background-color 500ms linear;
}
#songAddToQueueTooltip.optionOnPanRight.optionLevel_1_invalid.optionSelected,
#songAddToQueueTooltip.optionOnPanLeft.optionLevel_1_invalid.optionSelected,
#songAddToQueueTooltip.optionOnPanRight.optionLevel_2_invalid.optionSelected,
#songAddToQueueTooltip.optionOnPanLeft.optionLevel_2_invalid.optionSelected {
    background-color: var(--color-gray-400);
    transition: background-color 500ms linear;
}
.songLibraryWrapper.animateToZeroXPosition,
#songAddToQueueTooltip > .animateToZeroXPosition {
    transform: translateX(0px);
    transition: all 200ms cubic-bezier(.52, .17, .36, .86);
}
/*
.songLibraryWrapper.animateToZeroXPosition,
#songAddToQueueTooltip > .animateToZeroXPosition
{
 transform: translateX(0px);
 -webkit-transition: all 300ms cubic-bezier(.21,-0.7,.01,.93);
 -moz-transition: all 300ms cubic-bezier(.21,-0.7,.01,.93);
 -o-transition: all 300ms cubic-bezier(.21,-0.7,.01,.93);
 transition: all 300ms cubic-bezier(.21,-0.7,.01,.93);
 }*/
 /*
 .songLibraryWrapper.animateToZeroXPosition,
 #songAddToQueueTooltip.optionOnPanRight.optionLevel_1_valid.optionSelected > .animateToZeroXPosition,
 #songAddToQueueTooltip.optionOnPanLeft.optionLevel_1_valid.optionSelected > .animateToZeroXPosition,
 #songAddToQueueTooltip.optionOnPanRight.optionLevel_2_valid.optionSelected > .animateToZeroXPosition,
 #songAddToQueueTooltip.optionOnPanLeft.optionLevel_2_valid.optionSelected > .animateToZeroXPosition,
 #songAddToQueueTooltip.optionOnPanRight.optionLevel_1_invalid.optionSelected > .animateToZeroXPosition,
 #songAddToQueueTooltip.optionOnPanLeft.optionLevel_1_invalid.optionSelected > .animateToZeroXPosition,
 #songAddToQueueTooltip.optionOnPanRight.optionLevel_2_invalid.optionSelected > .animateToZeroXPosition,
 #songAddToQueueTooltip.optionOnPanLeft.optionLevel_2_invalid.optionSelected  > .animateToZeroXPosition{
     transform: translateX(0px);
     -webkit-transition: all 300ms cubic-bezier(.21,-0.7,.01,.93);
     -moz-transition: all 300ms cubic-bezier(.21,-0.7,.01,.93);
     -o-transition: all 300ms cubic-bezier(.21,-0.7,.01,.93);
     transition: all 300ms cubic-bezier(.21,-0.7,.01,.93);
 }
 */

 #songAddToQueueTooltip.optionOnPanRight.optionLevel_2_valid {
    background-color: var(--color-blue-400);
}
#songAddToQueueTooltip.optionOnPanLeft.optionLevel_2_valid {
    background-color: var(--color-orange-500);
}
#songAddToQueueTooltip.optionOnPanRight.optionLevel_2_invalid {
    background-color: var(--color-gray-400);
}
#songAddToQueueTooltip.optionOnPanLeft.optionLevel_2_invalid {
    background-color: var(--color-gray-400);
}
#songAddToQueueTooltip > div:nth-child(1) {
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
    padding: 8px;
    padding-left: 10px;
    font-size: var(--text-sm-px);
    font-weight: 400;
    /*font-weight: 300;*/
    
    -ms-flex-align: center;
    align-items: center;
    -webkit-align-items: center;
}
#songAddToQueueTooltip > div:nth-child(1) > i {
  padding: 8px;
  padding-left: 8px;
  margin-right: 2px;
  font-size: var(--text-xl-px);
  font-weight: 400;
  /*font-weight: 300;*/
}

/*
#placeHolderSongList > ul > li >div >div > .ui-link-inherit {
 margin: 0px;
 padding: 6px;
 height: 50px;
 text-decoration: none;
 border-bottom-width: var(--border-1);
 border-bottom-color: var(--color-gray-400);
 border-bottom-style: solid;
 color:var(--color-black);
 }*/
 /* Song Library */

 .songLibraryWrapper {
    background-color: inherit;
    display: -webkit-flex;
    display: flex;
    line-height: 20px;
    margin-right: 0px;
    margin-left: 0em;
    padding: 0em;
    border-bottom: var(--border-1) solid var(--color-gray-100);
    border-bottom: var(--border-1) solid var(--color-black-5);
    color: var(--text-dark);
}
/*
.songLibraryWrapper.isLoadedInPlayer .songTitleLibrary{
    color:var(--trebble-primary);   
}
*/

.songLibraryWrapper.artistlistItem {
    height: 59px;
}
.lighttext * .songLibraryWrapper {
    border-bottom: var(--border-1) solid var(--color-white-5);
    color: var(--color-white-100);
}
.songLibraryWrapper > .infiniteListItem {
    padding: 0.2em;
    margin: 0px;
    text-align: left;
    display: block;
    flex: 1 1 100%;
    -webkit-flex: 1 1 100%;
}
.songLibraryWrapper > .infiniteListItem:active,
.songLibraryWrapper > .infiniteListItem:active ~  .explicitIconWrapper{
    color: white;
    background-color: var(--trebble-primary);
    border-color: var(--trebble-primary);
}
.songLibraryWrapper > .infiniteListItem:active > div {
    color: white;
    /*background-color: var(--trebble-primary);
    border-color: var(--trebble-primary);*/
}
.songLibraryWrapper > .infiniteListItem:active > div > div {
    color: white;
}
#albumArtImage {
    /* padding: 15px; */
    
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
    /* box-shadow: var(--color-black-10) 0px 0px 0px 2px; */
}
/*
.songLibraryWrapper > a.ui-btn-down-undefined {
    background-color: var(--trebble-primary);
    border: var(--border-1) solid var(--trebble-primary);
    color: var(--text-light)
}
*/

#songLibraryOptionButtuon.ui-btn-down-a {
    background-color: var(--trebble-primary);
    border: var(--border-1) solid var(--trebble-primary);
    color: var(--text-light)
}
/*
.songLibraryWrapper > a.ui-btn-down-undefined > .songTitleLibrary {
    margin: auto;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: 400;
    color: var(--text-light);
}

.songLibraryWrapper > a.ui-btn-down-undefined > .songArtistLibrary {
    margin: auto;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: var(--text-xs);
    font-weight: 400;
    color: var(--text-light)
}
*/

.songTitleLibrary {
    margin: auto;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: 400;
    width: 100%;
    color: var(--text-dark);
}
.lighttext * .songTitleLibrary {
    color: var(--color-white-80);
}
.songArtistLibrary {
    margin: auto;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: var(--text-xs);
    /*color: var(--color-black-20);*/
    color: var(--text-dark);
    opacity: 0.5;
    font-weight: 400;
    width: 100%;
}
[data-role=popup] * .songArtistLibrary {
    color: var(--color-white-50);
}
.lighttext * .songArtistLibrary {
    color: var(--color-white-30);
}
#myLibraryHeader,
#globalSearchPageHeader {
    /* border-top-width: var(--border-2); */
    /* border-bottom-width: var(--border-1); */
    /* border-bottom-color: var(--trebble-primary); */
    /* border-top-color: var(--trebble-primary); */
}
#globalSearch * .ui-content{
  display: block;
  text-align: center;
}
#globalSearch * .ui-content > div{
  display: inline-block;
  width: 100%;
  vertical-align: top;
  margin: auto;
}

.ui-content{
    box-sizing: border-box;
}



/*
@media(max-width: 1200px){
    #globalSearch * .ui-content > div{
      width: 50%;

  }
}
@media(max-width: 600px){
    #globalSearch * .ui-content > div{
      width: 100%;

  }
}
*/

#mylibrary * .ui-content > [data-role=tabs],
#mySubscriptions * .ui-content > [data-role=tabs],
#myJourneys * .ui-content > [data-role=tabs],
#PlaylistPodcastsPage * .ui-content > [data-role=tabs],
#FeedAndActivityPage * .ui-content > [data-role=tabs],
#CapsulesPage * .ui-content > [data-role=tabs]
{
    position: static;
}
#FeedAndActivityPage > .ui-panel-wrapper {
    /* background-color: white; */
}
#trebbleEditInfoHeader,
#shortcastEditInfoHeader {
    height: 52px;
}
#homeHeaderWrapper {
    font-weight: 400;
    font-size: var(--text-lg);
    line-height: 2.5em;
    vertical-align: middle;
    text-align: left;
    /*height: 100%;*/
    
    display: inline-table;
    position: relative;
    left: 1em;
}
#queueMenuOptionBtn {
    line-height: 2em;
    padding: 0px;
    right: 0em;
    border: var(--border-0);
    width: 50px;
    /* border-bottom: var(--border-0); */
    
    top: 0em;
    /* height: 100%; */
    
    font-size: var(--text-xl);
    display: block;
    text-align: center;
    color: white;
    display: none!important;
}
#pageHeaderTitleWrapper {
    text-overflow: ellipsis;
    overflow: hidden;
    font-weight: 900;
    font-size: var(--text-3xl);
    line-height: 50px;
    vertical-align: middle;
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-align-content: center;
    -ms-flex-pack: center;
    white-space: nowrap; 

}
/*
.ui-popup-container #pageHeaderTitleWrapper {
   background: linear-gradient(var(--color-black-50), var(--color-black-0) 100%)
}
*/
.browser.mobilebrowser  #pageHeaderTitleWrapper {
 /*font-weight: 300; */

}
@media ( max-width: 470px) {
 body:not(.browser) #pageHeaderTitleWrapper {
    font-weight: 600;
    /*font-weight: 300;*/

}

.listHeader{
    font-size: var(--text-lg);
}

body #pageHeaderTitleWrapper {
    font-size: var(--text-lg);
}

body:not(.browser) div.ui-header[data-role=header] ~ .ui-panel-wrapper > .ui-content > [data-role=tabs] > [data-role=navbar] > ul > li > a,
body:not(.browser) div.ui-header[data-role=header] ~ .ui-panel-wrapper > .ui-content > [data-role=tabs] * [data-role=tabs] > [data-role=navbar] > ul > li > a {
    font-weight: 400;
}

body:not(.browser) .homePanel * .menuBox > .panelMenuItem > .menuLabel,
body:not(.browser) .homePanel * .settingMenuBox {
  font-size: var(--text-sm);
  font-weight: 400;
  letter-spacing: 2px;
}
.homePanel * .menuBox > .panelMenuItem> i.ion-contrast:before{
  opacity:0.8;
}
body:not(.browser) .homePanel * .loggedInUserInfoBox > .userCoverArtWrapper > .userInfo > .username.itemTitle{
    font-weight: 400;
}

#playlistPodcastTabs .ui-grid-b > .ui-block-b,
#playlistPodcastTabs .ui-grid-b > .ui-block-a{
  width: 50%;
}
#capsuleTabs .ui-grid-b > .ui-block-b,
#capsuleTabs .ui-grid-b > .ui-block-a,
#capsuleTabs .ui-grid-b > .ui-block-c {
    width: 50%;
}
}
#artistDetailHeaderWrapper {
    padding-right: 1em;
    padding-left: 3em;
    position: relative;
    /* margin: 0px; */
    
    white-space: pre;
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
    font-weight: 400;
    font-size: var(--text-lg);
    line-height: 2.5em;
    text-align: left;
}
div.ui-header[data-role=header] {
    -ms-flex: 0 0 52px;
    flex: 0 0 52px;
    -webkit-flex: 0 0 52px;
    height: 52px !important;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-direction: row;
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
    background-color: var(--trebble-primary);
    border-color: var(--trebble-primary);
    overflow: visible;
}
[data-role=popup] * div.ui-header[data-role=header],
#featurePage > div.ui-header[data-role=header],
#ExplorePage > div.ui-header[data-role=header],
#Onboarding > div.ui-header[data-role=header],
#GetStartedForCuratorsPage > div.ui-header[data-role=header],
.TrebbleStatisticPage.lighttext > div.ui-header[data-role=header],
.createCapsulePage > div.ui-header[data-role=header]{
    background-color: transparent;
    border-color: transparent;
    width: 100%;
}

[data-role=page].createCapsulePage.lighttext.transparent > .ui-content,
[data-role=page].createCapsulePage.lighttext.transparent > .ui-content .sequencer_settings .itemTitle,
[data-role=page].createCapsulePage.lighttext.transparent > .ui-content .sequencer_settings .settingSection .sectionTitle,
[data-role=page].createCapsulePage.lighttext.transparent > .ui-content .pause_audio_segment_node {
    color: white;
}

[data-role=page].createCapsulePage   div.ui-input-text{
   background-color: transparent;
   border-width: var(--border-0) !important;
}
[data-role=page].createCapsulePage #textToConvertField,
[data-role=page].createCapsulePage #instructionsForVoiceActorField,
[data-role=page].createCapsulePage #textToConvertByVoiceActorField{
    height: 100%;
    box-sizing: border-box;
    padding: 20px;
}

[data-role=page].createCapsulePage #textToConvertField,
[data-role=page].createCapsulePage #instructionsForVoiceActorField,
[data-role=page].createCapsulePage #textToConvertByVoiceActorField,
[data-role=page].createCapsulePage  #comment,
[data-role=page].createCapsulePage  .commentBox  {
  max-width: 700px;
  margin-left: auto !important;
  margin-right: auto !important;
}
[data-role=page].createCapsulePage #textToConvertField,
[data-role=page].createCapsulePage #instructionsForVoiceActorField,
[data-role=page].createCapsulePage #textToConvertByVoiceActorField{
    height: 90px !important;
}
.word_count_wrapper{
    text-align: right;
    opacity: 0.5;
    font-size: 90%;
}
[data-role=page].createCapsulePage #textToConvertField,
[data-role=page].createCapsulePage #instructionsForVoiceActorField,
[data-role=page].createCapsulePage #textToConvertByVoiceActorField,
[data-role=page].createCapsulePage  #comment  {
  color: white;
  background-color: var(--color-white-10);
  font-size: var(--text-sm-px);
}

[data-role=page].createCapsulePage label,
[data-role=page].createCapsulePage button label{
  margin-left: auto;
  margin-right: auto;
  padding-bottom:10px;
  padding-top:10px;
}
[data-role=page].createCapsulePage.ui-page:not(.lighttext) label{
    color: var(--trebble-primary);
}
[data-role=page].transparent label,
#addOrScheduleCapsuleToTrebbleFormWrapper label,
[data-role=page].createCapsulePage .ui-select,
[data-role=page].createCapsulePage .ui-input-text,
[data-role=page].createCapsulePage .schedulingSettingWrapper .ui-input-text,
[data-role=page].createCapsulePage #songToPlayAfterCapsuleInputPlaceholder,
[data-role=page].createCapsulePage .doNotAddOnAirCheckboxWrapper,
[data-role=popup] label,
[data-role=popup] #addOrScheduleCapsuleToTrebbleFormWrapper .ui-select,
[data-role=popup] #addOrScheduleCapsuleToTrebbleFormWrapper .ui-input-text,
[data-role=popup]  #addOrScheduleCapsuleToTrebbleFormWrapper .schedulingSettingWrapper .ui-input-text,
[data-role=popup]  #addOrScheduleCapsuleToTrebbleFormWrapper #songToPlayAfterCapsuleInputPlaceholder,
[data-role=popup] #addOrScheduleCapsuleToTrebbleFormWrapper .doNotAddOnAirCheckboxWrapper {
  color: white;
  padding: 0px 10px;
}

[data-role=popup]  #addOrScheduleCapsuleToTrebbleFormWrapper .ui-input-text{

    border: var(--border-0) !important;
}

[type="checkbox"].filled-in+label[for=doNotAddOnAirCheckbox]:after{
  top:10px;
  left:2px;
  background-color: white;
  border-color:white;
}


[data-role=page] [type="checkbox"]+label {
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  display: inline-block;
  height: 25px;
  line-height: 25px;
  font-size: var(--text-base-rem);
  user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

[data-role=page].transparent [type="checkbox"]:not(.filled-in)+label:before, 
[data-role=page].transparent [type="checkbox"]:not(.filled-in)+label:after{
  border-color:white;
}
[type="checkbox"]:checked+label:before ,
[data-role=page].transparent [type="checkbox"]:checked+label:before {
  border-color:transparent;
  border-right: var(--border-2) solid var(--trebble-primary);
  border-bottom: var(--border-2) solid var(--trebble-primary);
}


[type=checkbox].filled-in:checked+label:after,
[data-role=page]:not(.transparent) [type=checkbox].filled-in:checked+label:after {
  border: var(--border-2) solid var(--trebble-primary);
  background-color: var(--trebble-primary);
}

[data-role=page].transparent [type=checkbox].filled-in:checked+label:after {
  border: var(--border-2) solid white;
  background-color: white;
}


[data-role=page].createCapsulePage paper-input-decorator{
  padding:0px;
  width: 100%;
}

[data-role=page].createCapsulePage  paper-input::shadow > paper-input-decorator::shadow > .underline > .focused-underline,
[data-role=page].createCapsulePage  paper-input-decorator::shadow > .underline > .focused-underline{
  background-color: var(--trebble-primary) ;
}
[data-role=popup] input{
  color: white;
}

#GetStartedForCuratorsPage > div[data-role=header] > div > #homeHeaderButton,
#GetStartedForCuratorsPage > div[data-role=header] >  #pageHeaderTitleWrapper {
  color: white;
}

.TrebbleStatisticPage.lighttext.ui-page-theme-a  > .ui-panel-wrapper{
    background-color: transparent;
}
#player > div.ui-header[data-role=header] {
    border: var(--border-0);
}
div.ui-header[data-role=header] ~ .ui-panel-wrapper > .ui-content > [data-role=tabs] > [data-role=navbar] > ul > li > a {
    font-size: var(--text-2xs);
    padding-bottom: 11px;
    box-sizing: border-box;
    background-color: var(--trebble-primary);
    border-width: var(--border-0) 0px 3px 0px;
    border-color: var(--trebble-primary);
    font-weight: 600;
    letter-spacing: 3px;
    color: var(--trebble-primary-200);
}
.browser.mobilebrowser div.ui-header[data-role=header] ~ .ui-panel-wrapper > .ui-content > [data-role=tabs] > [data-role=navbar] > ul > li > a{
 font-weight: 300;
}

body.browser.mobileweb div.ui-header[data-role=header] ~ .ui-panel-wrapper > .ui-content > [data-role=tabs] > [data-role=navbar] > ul > li > a:hover{
 color: white;
 transform: translateZ(0);
 transform: translateZ(0);
 -webkit-backface-visibility: hidden;
 backface-visibility: hidden;
 -moz-osx-font-smoothing: grayscale;
 -webkit-transition-duration: 0.3s;
 transition-duration: 0.3s;
 -webkit-transition-property: color, background-color;
 transition-property: color, background-color;
}
.browser.mobileweb * div.ui-header[data-role=header] ~ .ui-panel-wrapper > .ui-content > [data-role=tabs] > [data-role=navbar] > ul > li,
.browser.mobileweb * div.ui-header[data-role=header] ~ .ui-panel-wrapper > .ui-content > [data-role=tabs] * [data-role=tabs] > [data-role=navbar] > ul > li {
    width: auto;
}

.browser.mobileweb * div.ui-header[data-role=header] ~ .ui-panel-wrapper > .ui-content > [data-role=tabs] > [data-role=navbar] > ul ,
.browser.mobileweb * div.ui-header[data-role=header] ~ .ui-panel-wrapper > .ui-content > [data-role=tabs] * [data-role=tabs] > [data-role=navbar] > ul  {
   display:flex;
   display: -webkit-flex;
   display: -moz-flex;
   display: -ms-flex;
   justify-content:center;
   -webkit-justify-content:center;
   -moz-justify-content:center;
   -ms-justify-content:center;

}
/*
.browser.mobileweb * div.ui-header[data-role=header] ~ .ui-panel-wrapper > .ui-content > [data-role=tabs] > [data-role=navbar] > ul > li > a {
    padding-right: 70px;
    padding-left: 70px;
}
.browser.mobileweb * div.ui-header[data-role=header] ~ .ui-panel-wrapper > .ui-content > [data-role=tabs] * [data-role=tabs] > [data-role=navbar] > ul > li > a {
    padding-right: 100px;
    padding-left: 100px;
    }*/
    div.ui-header[data-role=header] ~ .ui-panel-wrapper > .ui-content > [data-role=tabs] > [data-role=navbar] {
        background-color: var(--trebble-primary);
    }
    div.ui-header[data-role=header] ~ .ui-panel-wrapper > .ui-content > [data-role=tabs] > [data-role=navbar] > ul {
        border: var(--border-0);
        /* box-shadow: 0px 3px 5px 0px var(--color-black-20); */
    }
    div.ui-header[data-role=header] ~ .ui-panel-wrapper > .ui-content > [data-role=tabs] * [data-role=tabs] > [data-role=navbar] > ul > li > a {
        font-size: var(--text-2xs);
        padding-top: 9px;
        padding-bottom: 8px;
        box-sizing: border-box;
        background-color: white;
        border-width: var(--border-0) 0px 3px 0px;
        border-color: white;
        font-weight: 600;
        letter-spacing: 3px;
        color: var(--color-gray-600);
    }
    .browser.mobilebrowser  div.ui-header[data-role=header] ~ .ui-panel-wrapper > .ui-content > [data-role=tabs] * [data-role=tabs] > [data-role=navbar] > ul > li > a {
      font-weight: 300;
  }
  body.browser.mobileweb div.ui-header[data-role=header] ~ .ui-panel-wrapper > .ui-content > [data-role=tabs] * [data-role=tabs] > [data-role=navbar] > ul > li > a:hover{
    color: var(--text-dark) !important;
}
div.ui-header[data-role=header] ~ .ui-panel-wrapper > .ui-content > [data-role=tabs] * [data-role=tabs] > [data-role=navbar] {
    background-color: var(--bg-light);
}
div.ui-header[data-role=header] ~ .ui-panel-wrapper > .ui-content > [data-role=tabs] * [data-role=tabs] > [data-role=navbar] > ul {
    border: var(--border-0);
}
div.ui-header[data-role=header] ~ .ui-panel-wrapper > .ui-content > [data-role=tabs] * [data-role=tabs] > [data-role=navbar] > ul > li > a.ui-btn-active {
    border-color: var(--trebble-primary-300);
    color: var(--trebble-primary-300);
}
div.ui-header[data-role=header] ~ .ui-panel-wrapper > .ui-content > [data-role=tabs] > [data-role=navbar] > ul > li > a.ui-btn-active {
    border-color: white;
    color: white;
}

#mylibrary div.ui-header[data-role=header] ~ .ui-panel-wrapper > .ui-content > [data-role=tabs] > [data-role=navbar] > ul  {
    max-width: 500px;
    margin: auto;
}
div.ui-header[data-role=header] > #myLibraryHeaderWrapper,
div.ui-header[data-role=header] > #pageHeaderTitleWrapper {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    -webkit-flex: 1 1 auto;
}
div.ui-header[data-role=header]>:first-child {
    position: relative;
    -ms-flex: 0 0 51px;
    flex: 0 0 51px;
    -webkit-flex: 0 0 51px;
    display: block;
}
div.ui-header[data-role=header]> #header-back-button:first-child {
    text-align: start;
    display: block;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    -webkit-flex: 1 1 auto;
    -webkit-align-content: flex-start;
    -ms-flex-line-pack: start;
    align-content: flex-start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-align-content: flex-start;
    -webkit-justify-content: flex-start;
}
div.ui-header[data-role=header]> #header-back-button.noText:first-child {
    text-align: start;
    display: block;
    -webkit-flex: 0 0 51px;
    -ms-flex: 0 0 51px;
    flex: 0 0 51px;
    -webkit-align-content: flex-start;
    -ms-flex-line-pack: start;
    align-content: flex-start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-flex: 0 0 51px;
    -webkit-align-content: flex-start;
    -webkit-justify-content: flex-start;
}
body.ios * div.ui-header[data-role=header] > #pageHeaderTitleWrapper
/*,
body.browser * div.ui-header[data-role=header] > #pageHeaderTitleWrapper */

{
    text-align: center;
    display: block;
    -webkit-flex: 1 1 100%;
    -ms-flex: 1 1 100%;
    flex: 1 1 100%;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-flex: 1 1 100%;
    -webkit-justify-content: center;
}
div.ui-header[data-role=header]> #playlistInfoWrapper {
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    display: -webkit-flex;
    -webkit-flex-direction: column;
    -webkit-align-items: flex-start;
    -webkit-justify-content: center;
}
div#player > div.ui-header[data-role=header]> #playlistInfoWrapper {
    padding: 0px;
    width: 100%;
    height: 51px;
}
div.ui-header[data-role=header]> .ui-title {
    margin: 0px;
}
div.ui-header[data-role=header]>:first-child {
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
    position: relative;
    -ms-flex: 0 0 51px;
    flex: 0 0 51px;
    -webkit-flex: 0 0 51px;
    display: flex;
}
div.ui-header[data-role=header]>:last-child {
    position: relative;
    -ms-flex: 0 0 51px;
    flex: 0 0 51px;
    -webkit-flex: 0 0 51px;
    display: flex;
}
div.ui-header[data-role=header]>:last-child > #createNewCapsuleBtn {
    font-size: var(--text-3xl);
    margin: 0px;
    padding: 0px;
}
div.ui-header[data-role=header]> #pageHeaderTitleWrapper {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    -webkit-flex: 1 1 auto;
}

[data-role=popup] div.ui-header[data-role=header]> #pageHeaderTitleWrapper{
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
}
[data-role=page]:not(#globalSearch) div.ui-header[data-role=header] > #pageHeaderTitleWrapper {

  padding-left: 14px;
}

.search_field_displayed.searchFieldWrapper{
    overflow:hidden;
    width:100%;
    height:100%;
}
.search_field_displayed.searchFieldWrapper .listContainer{
    overflow:scroll;
    height:calc(100% - 40px);
}
.ui-content > .left-right-content-padding > #collection_repeat_container,
.search_field_displayed.searchFieldWrapper .listContainer > div,
.search_field_displayed.searchFieldWrapper #collection_repeat_container{
 height: 100%;
}


@media(max-width: 380px), (max-height:380px){
    [data-role=page]:not(#globalSearch) div.ui-header[data-role=header] > div:not(.homeButtonWrapper) ~ #pageHeaderTitleWrapper {

        padding-left: 10px;
    }

    body.footerMenuBardisplayed:not([userprofiletype=creator]):not(#globalSearch):not(.alwaysDisplayHelp):not([userprofiletype=journey_creator]):not([userprofiletype=shortcast_creator]) [data-role=header] .homeButtonWrapper ~ #pageHeaderTitleWrapper{
        padding-left: 30px;
    }
}
body.ios * div.ui-header[data-role=header].twoButtons >:first-child
/*,
body.browser * div.ui-header[data-role=header].twoButtons >:first-child */

{
    position: relative;
    -ms-flex: 0 0 102px;
    flex: 0 0 102px;
    -webkit-flex: 0 0 102px;
    display: block;
}
body.ios * div.ui-header[data-role=header].bigButton >:first-child
/*,
body.browser * div.ui-header[data-role=header].bigButton >:first-child*/

{
    position: relative;
    -ms-flex: 0 0 122px;
    flex: 0 0 122px;
    -webkit-flex: 0 0 122px;
    display: block;
}
body.ios * div.ui-header[data-role=header].twoButtons.searchFieldWrapper >:first-child
/*, 
body.browser * div.ui-header[data-role=header].twoButtons.searchFieldWrapper >:first-child*/

{
    position: relative;
    -ms-flex: 0 0 51px;
    flex: 0 0 51px;
    -webkit-flex: 0 0 51px;
    display: block;
}
body.ios > div#player > div.ui-header[data-role=header].twoButtons >:first-child
/*,
body.browser > div#player > div.ui-header[data-role=header].twoButtons >:first-child */

{
    position: relative;
    -ms-flex: 0 0 51px;
    flex: 0 0 51px;
    -webkit-flex: 0 0 51px;
    display: block;
}
div.ui-header[data-role=header].twoButtons >:last-child {
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
    position: relative;
    -ms-flex: 0 0 102px;
    flex: 0 0 102px;
    -webkit-flex: 0 0 102px;
    display: block;
}
div.ui-header[data-role=header].bigButton >:last-child {
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-justify-content: center;
    -webkit-align-content: center;
    position: relative;
    -ms-flex: 0 0 122px;
    flex: 0 0 122px;
    -webkit-flex: 0 0 122px;
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex-direction: column;
}
div.ui-header[data-role=header].twoButtons >:last-child > .ui-controlgroup-controls {
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    display: -ms-flexbox;
    display: flex;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-flex-direction: row;
    -webkit-display: -webkit-flex;
    -webkit-justify-content: flex-end;
    height: 100%;
}
div.ui-header[data-role=header]> .textButton:last-child {
    position: relative;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    -webkit-flex: 0 0 auto;
}
div.ui-header[data-role=header]> .textButton:last-child * a {
    font-weight: 300;
    font-size: var(--text-lg);
    line-height: 1em;
    overflow: hidden;
    color: white;
    text-overflow: ellipsis;
}
[data-role=controlgroup].rightHeaderButtonGroup {
    margin: 0px;
    font-size: var(--text-base);
    float: right;
}
#homeHeaderButton,
#helpHeaderButton {
    box-sizing: border-box;
    background-color: transparent;
    left: 0em;
    top: 0em;
    text-align: center;
    font-size: var(--text-2xl);
    line-height: 40px;
    padding-right: 3px;
    padding-left: 3px;
    padding: 0px;
    height: 100%;
    display: block;
    width: 54px;
    border: var(--border-0);
    margin: 0px;
    padding-top: 0px;
    align-items: center;
    display: flex;
    justify-content: center;

}


body[userprofiletype=creator] div[data-role=header] * #helpHeaderButton.ui-btn,
body[userprofiletype=journey_creator] div[data-role=header] * #helpHeaderButton.ui-btn,
body[userprofiletype=shortcast_creator] div[data-role=header] * #helpHeaderButton.ui-btn,
body:not(.alwaysDisplayHelp) div[data-role=header] * #helpHeaderButton.ui-btn,
body[userprofiletype=creator] #home div[data-role=header] * #helpHeaderButton.ui-btn,
body[userprofiletype=journey_creator] #home div[data-role=header] * #helpHeaderButton.ui-btn,
body[userprofiletype=shortcast_creator] #home div[data-role=header] * #helpHeaderButton.ui-btn,
body:not(.alwaysDisplayHelp)  #home div[data-role=header] * #helpHeaderButton.ui-btn{
    display: none;
}

body.alwaysDisplayHelp:not([userprofiletype=creator]):not([userprofiletype=journey_creator]):not([userprofiletype=shortcast_creator]) #helpHeaderButton{
    display: flex;
}

body.iosApp [class*=" pe-7s-"], body.iosApp [class^=pe-7s-] {
    -webkit-text-stroke-width:0.5px
}

body [data-role=page] [class*=" pe-7s-"], body [data-role=page] [class^=pe-7s-],
body [data-role=popup] [class*=" pe-7s-"], body [data-role=popup] [class^=pe-7s-],
body [data-role=panel] [class*=" pe-7s-"], body [data-role=panel] [class^=pe-7s-],

body [data-role=page] [class*=" ion-"], body [data-role=page] [class^=ion-],
body [data-role=popup] [class*=" ion-"], body [data-role=popup] [class^=ion-],
body [data-role=panel] [class*=" ion-"], body [data-role=panel] [class^=ion-]  {
    -webkit-text-stroke-width:0.5px
}

#PlaylistPodcastsPage * #homeHeaderButton {
    /**/
}
#OnAirPage * #homeHeaderButton {
    /**/
}
#PlaylistPodcastsPage * #homeHeaderButton {
    /* */
}
#globalSearch * #homeHeaderButton {
    /* */
}
#placeHolderSongList > ul > li.ui-btn.ui-btn-icon-right.ui-li.ui-li-has-alt.ui-btn-up-c {
    height: 3.5em;
    border-bottom-width: var(--border-1);
    border-bottom-color: var(--color-gray-400);
    border-bottom-style: solid;
    color: var(--color-black);
}
#placeHolderSongList > ul > li > div {
    margin: 0px;
    padding: 0px;
    height: 100%;
    width: 100%;
}
#placeHolderSongList > ul > li > div > div {
    margin: 0px;
    padding: 0px;
    height: 100%;
    width: 100%;
}
#placeHolderSongList > ul > li > div > div > a > h2.ui-li-heading {
    margin: 0px;
    padding-top: 0.5em;
    padding-bottom: 0.2em;
    padding-left: 0.5em;
    padding-right: 0.5em;
}
#placeHolderSongList > ul > li > div > div > a > p.ui-li-desc {
    margin: 0px;
    padding-top: 0.2em;
    padding-bottom: 0.5em;
    padding-left: 0.5em;
    padding-right: 0.5em;
}
#moreSubTabs > div,
#myRadioSubTabs > div,
#mylibrary #capsuleTabs > div {
    padding: 0px;
    border: var(--border-0);
}


html body:not(.darkTheme) #moreSubTabs > div,
html body:not(.darkTheme) #myRadioSubTabs > div,
html body:not(.darkTheme) #mylibrary #capsuleTabs > div {
    background-color: var(--bg-light);
}

#moreSubTabs > div[role=tabpanel],
#mylibrary #capsuleTabs >  div[role=tabpanel] {
    background-color: white;
}
#myRadioSubTabs > div[role=tabpanel] {
    padding-top: 6px;
    background-color: var(--bg-light);
    box-shadow: inset 0px 3px 6px 0px var(--color-black-10);
}
#moreSubTabs > div[role=tabpanel],
#mylibrary #capsuleTabs >  div[role=tabpanel] {
    box-sizing: border-box;
    padding-top: 10px;
    padding-left: 1em;
    padding-right: 1em;
    box-shadow: inset 0px 3px 6px 0px var(--color-black-10);
}
/*
#moreSubTabs > div > ul > li > a.ui-btn-active {
 background-color: var(--color-gray-150);
 }*/

 #playlistPodcastTabs > div[role="tabpanel"]#playlistWrapperTest {
    box-shadow: inset 0px 3px 6px 0px var(--color-black-20);
    background-color: white;
    padding-top: 6px;
}
#playlistPodcastTabs > div[role="tabpanel"],
#capsuleTabs > div[role="tabpanel"],
#myNotificationsTabs > div[role="tabpanel"]{
    border: var(--border-0);
    padding-top: 6px;
    overflow-y: auto;
    background-color: var(--bg-light);
    -webkit-overflow-scrolling: touch;
    box-shadow: inset 0px 3px 6px 0px var(--color-black-20);
}
#placeHolderSongList > ul > li > div > div > a {
    margin: 0px;
    padding: 0em;
    height: 100%;
    text-decoration: none;
}
#songMoreOptionButton,
.ui-li-link-alt {
    text-align: center;
    line-height: 2em;
    vertical-align: middle;
    height: 100%;
    padding: 0px;
    margin: 0px;
    font-size: var(--text-xl);
    color: var(--color-gray-400);
}
/* Player Footer */

#playerFooter {
    background-color: var(--color-black);
    background-color: white;/*var(--bg-light);*/
    height: 4em;
    box-sizing: border-box;
    /*backdrop-filter: blur(20px);*/
    border-top: var(--border-1) solid var(--color-gray-100);
    box-shadow: 0px -3px 8px 0px var(--color-black-20);
    -moz-box-shadow: 0px -3px 8px 0px var(--color-black-20);
    -webkit-box-shadow: 0px -3px 8px 0px var(--color-black-20);
}
#playerFooter.transparent {
    background: var(--gradient-fade-dark-simple);
    background-color: transparent;
    border-color: var(--color-white-100);
    backdrop-filter: blur(20px);
    border: var(--border-0);
    box-shadow: none;
    /*background-color: var(--color-black-20);*/
    height: 4em;
    box-sizing: border-box;
    color: white;
    border-top: var(--border-1) solid var(--color-white-20);
}

body.darkTheme #playerFooter:not(.transparent),
[data-role=page].ui-page-active.darkTheme ~ #playerFooter:not(.transparent) {
    background-color:  var(--color-black);
    border-color: var(--color-black);
    backdrop-filter: blur(20px);
    border: var(--border-0);
    /*background-color: var(--color-black-20);*/
    height: 4em;
    box-sizing: border-box;
    color: white;
    border-top: var(--border-1) solid var(--color-black);
}

/*
#playerFooter.transparent > div > div#footerControlsWrapper > #playerControlsWrapper > div > div > a#loopButton, 
#playerFooter.transparent > div > div#footerControlsWrapper > #playerControlsWrapper > div > div > a#shuffleButton,
#playerFooter.transparent > div > div#footerControlsWrapper > #playerControlsWrapper > div > a#loopButton, 
#playerFooter.transparent > div > div#footerControlsWrapper > #playerControlsWrapper > div > a#shuffleButton{
  color: var(--color-white-60);
  }*/
  #playerPositionSliderWrapper * div.ui-slider {
    margin: 0px;
}
#playerFooter > div {
    width: 100%;
    height: 100%;
}

#audioInfoAndSliderWrapper{
    display: flex;
    flex-direction: column;
}

#playerFooter > div > div > div#footerCurrentSongImg,
#playerFooter > div > #footerCurrentSongImg {
    margin-right: 1px;
    min-width: 58px;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    -webkit-flex: 1 1 auto;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    height: 100%;
    background-color: var(--color-gray-150);
    cursor: pointer;
}

#playerFooter.transparent > div > div > div#footerCurrentSongImg,
#playerFooter.transparent > div > #footerCurrentSongImg,
body.darkTheme #playerFooter:not(.transparent) > div > div > div#footerCurrentSongImg,
[data-role=page].ui-page-active.darkTheme ~ #playerFooter:not(.transparent) > div > #footerCurrentSongImg,
body.darkTheme #playerFooter:not(.transparent) > div > div > div#footerCurrentSongImg,
[data-role=page].ui-page-active.darkTheme ~ #playerFooter:not(.transparent) > div > #footerCurrentSongImg {
    background-color: black;
}

#playerFooter.browser > div > div {
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
    flex: 0 0 auto;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
}
#playerFooter.browser > div > div > div#footerCurrentSongImg {
    height: 58px;
}
#playerFooter.browser > div > div#playerFooterSongInfoWrapper {
    -ms-flex: 0 0 400px;
    flex: 0 0 400px;
    -webkit-flex: 0 0 400px;
    color: var(--text-dark) !important;
}
#playerFooter.browser > div > div#playerFooterSliderWrapper {
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
    -ms-flex: 1 1 100%;
    flex: 1 1 100%;
    -webkit-flex: 1 1 100%;
    -ms-flex-align: center;
    align-items: center;
    -webkit-align-items: center;
}
#playerFooter.browser > div > div#footerControlsWrapper {
    -ms-flex: 0 0 260px;
    flex: 0 0 260px;
    -webkit-flex: 0 0 260px;
}
#playerFooter.browser:not(.transparent) > div > div#footerControlsWrapper > #playerControlsWrapper > div > div > a,
#playerFooter.browser:not(.transparent) > div > div#footerControlsWrapper > #playerControlsWrapper > div > a {
    color: var(--text-dark);
    overflow: visible;
}
#playerFooter.browser:not(.transparent) > div > div#footerControlsWrapper > #playerControlsWrapper > div > div > a#loopButton > t,
#playerFooter.browser:not(.transparent) > div > div#footerControlsWrapper > #playerControlsWrapper > div > div > a#shuffleButton > t,
#playerFooter.browser:not(.transparent) > div > div#footerControlsWrapper > #playerControlsWrapper > div > a#loopButton > t,
#playerFooter.browser:not(.transparent) > div > div#footerControlsWrapper > #playerControlsWrapper > div > a#shuffleButton > t {
    color: var(--gray-overlay-40);
    font-size: var(--text-lg);
}
#playerFooter.browser.transparent > div > div#footerControlsWrapper > #playerControlsWrapper > div > div > a,
#playerFooter.browser.transparent > div > div#footerControlsWrapper > #playerControlsWrapper > div > a,

body.darkTheme #playerFooter.browser:not(.transparent) > div > div#footerControlsWrapper > #playerControlsWrapper > div > div > a,
body.darkTheme #playerFooter.browser:not(.transparent) > div > div#footerControlsWrapper > #playerControlsWrapper > div > a ,

[data-role=page].ui-page-active.darkTheme ~ #playerFooter.browser:not(.transparent) > div > div#footerControlsWrapper > #playerControlsWrapper > div > div > a,
[data-role=page].ui-page-active.darkTheme ~ #playerFooter.browser:not(.transparent) > div > div#footerControlsWrapper > #playerControlsWrapper > div > a  {
  color: var(--text-muted);
  overflow: visible;
}

#playerFooter.browser.transparent > div > div#footerControlsWrapper > #playerControlsWrapper > div > div > a#loopButton, 
#playerFooter.browser.transparent > div > div#footerControlsWrapper > #playerControlsWrapper > div > div > a#shuffleButton,
#playerFooter.browser.transparent  > div > div#footerControlsWrapper > #playerControlsWrapper > div > a#loopButton, 
#playerFooter.browser.transparent  > div > div#footerControlsWrapper > #playerControlsWrapper > div > a#shuffleButton,

body.darkTheme #playerFooter.browser:not(.transparent) > div > div#footerControlsWrapper > #playerControlsWrapper > div > div > a#loopButton, 
body.darkTheme #playerFooter.browser:not(.transparent) > div > div#footerControlsWrapper > #playerControlsWrapper > div > div > a#shuffleButton,
body.darkTheme #playerFooter.browser:not(.transparent) > div > div#footerControlsWrapper > #playerControlsWrapper > div > a#loopButton, 
body.darkTheme #playerFooter.browser:not(.transparent) > div > div#footerControlsWrapper > #playerControlsWrapper > div > a#shuffleButton,

[data-role=page].ui-page-active.darkTheme ~ #playerFooter.browser:not(.transparent) > div > div#footerControlsWrapper > #playerControlsWrapper > div > div > a#loopButton, 
[data-role=page].ui-page-active.darkTheme ~ #playerFooter.browser:not(.transparent) > div > div#footerControlsWrapper > #playerControlsWrapper > div > div > a#shuffleButton,
[data-role=page].ui-page-active.darkTheme ~ #playerFooter.browser:not(.transparent) > div > div#footerControlsWrapper > #playerControlsWrapper > div > a#loopButton, 
[data-role=page].ui-page-active.darkTheme ~ #playerFooter.browser:not(.transparent) > div > div#footerControlsWrapper > #playerControlsWrapper > div > a#shuffleButton{
  color: var(--color-white-40);
}

#playerFooter.browser.transparent > div > div#footerControlsWrapper > #playerControlsWrapper > div > div > a:active,
#playerFooter.browser.transparent > div > div#footerControlsWrapper > #playerControlsWrapper > div > a:active,

body.darkTheme #playerFooter.browser:not(.transparent) > div > div#footerControlsWrapper > #playerControlsWrapper > div > div > a:active,
body.darkTheme #playerFooter.browser:not(.transparent) > div > div#footerControlsWrapper > #playerControlsWrapper > div > a:active,

[data-role=page].ui-page-active.darkTheme ~ #playerFooter.browser:not(.transparent) > div > div#footerControlsWrapper > #playerControlsWrapper > div > div > a:active,
[data-role=page].ui-page-active.darkTheme ~ #playerFooter.browser:not(.transparent) > div > div#footerControlsWrapper > #playerControlsWrapper > div > a:active {
    color: white;
}
body.browser.mobileweb #playerFooter.browser:not(.transparent) > div > div#footerControlsWrapper > #playerControlsWrapper > div > div > a:hover,
body.browser.mobileweb #playerFooter.browser:not(.transparent) > div > div#footerControlsWrapper > #playerControlsWrapper > div > a:hover,
body.browser.mobileweb #playerFooter.browser:not(.transparent) > div #playerSocialInfoWrapper > div > a:hover {
    background-color: var(--bg-overlay-dark);
    border-color: var(--bg-overlay-dark);
    transform: translateZ(0);
    transform: translateZ(0);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -moz-osx-font-smoothing: grayscale;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-property: color, background-color;
    transition-property: color, background-color;
}

body.browser.mobileweb #playerFooter.browser.transparent > div > div#footerControlsWrapper > #playerControlsWrapper > div > div > a:hover,
body.browser.mobileweb #playerFooter.browser.transparent > div > div#footerControlsWrapper > #playerControlsWrapper > div > a:hover,

body.browser.mobileweb.darkTheme #playerFooter.browser:not(.transparent) > div > div#footerControlsWrapper > #playerControlsWrapper > div > div > a:hover,
body.browser.mobileweb.darkTheme #playerFooter.browser:not(.transparent) > div > div#footerControlsWrapper > #playerControlsWrapper > div > a:hover,

body.browser.mobileweb [data-role=page].ui-page-active.darkTheme ~ #playerFooter.browser:not(.transparent) > div > div#footerControlsWrapper > #playerControlsWrapper > div > div > a:hover,
body.browser.mobileweb [data-role=page].ui-page-active.darkTheme ~ #playerFooter.browser:not(.transparent) > div > div#footerControlsWrapper > #playerControlsWrapper > div > a:hover,


body.browser.mobileweb #playerFooter.transparent * #playerInformationButton:hover,
body.browser.mobileweb #playerFooter.transparent * #playerSongMoreButton:hover,
body.browser.mobileweb #playerFooter.transparent * #playerMoreInformationButton:hover,
body.browser.mobileweb #playerFooter.transparent * #expandPlayerButton:hover,
body.browser.mobileweb #playerFooter.transparent * #queueRefreshButton:hover, 
body.browser.mobileweb #playerFooter.transparent * #songIsFavoriteBtn:hover,
body.browser.mobileweb #playerFooter.transparent * #tagsBtn:hover,
body.browser.mobileweb #playerFooter.transparent * #shareSongBtn:hover,


body.darkTheme #settings .userInfoSection:active,
body.darkTheme  .settingSection .settingItemTitle:not(.unclickable):active, 
body.browser.mobileweb.darkTheme #settings .userInfoSection:hover, 
body.browser.mobileweb.darkTheme .settingSection .settingItemTitle:not(.unclickable):hover,
body.browser.mobileweb.darkTheme #playerFooter:not(.transparent) * #playerInformationButton:hover,
body.browser.mobileweb.darkTheme #playerFooter:not(.transparent) * #playerSongMoreButton:hover,
body.browser.mobileweb.darkTheme #playerFooter:not(.transparent) * #playerMoreInformationButton:hover,
body.browser.mobileweb.darkTheme #playerFooter:not(.transparent) * #expandPlayerButton:hover,
body.browser.mobileweb.darkTheme #playerFooter:not(.transparent) * #queueRefreshButton:hover, 
body.browser.mobileweb.darkTheme #playerFooter:not(.transparent) * #songIsFavoriteBtn:hover,
body.browser.mobileweb.darkTheme #playerFooter:not(.transparent) * #tagsBtn:hover,
body.browser.mobileweb.darkTheme #playerFooter:not(.transparent) * #shareSongBtn:hover,


body.browser.mobileweb [data-role=page].ui-page-active.darkTheme ~ #playerFooter:not(.transparent) * #playerInformationButton:hover,
body.browser.mobileweb [data-role=page].ui-page-active.darkTheme ~ #playerFooter:not(.transparent) * #playerSongMoreButton:hover,
body.browser.mobileweb [data-role=page].ui-page-active.darkTheme ~ #playerFooter:not(.transparent) * #playerMoreInformationButton:hover,
body.browser.mobileweb [data-role=page].ui-page-active.darkTheme ~ #playerFooter:not(.transparent) * #expandPlayerButton:hover,
body.browser.mobileweb [data-role=page].ui-page-active.darkTheme ~ #playerFooter:not(.transparent) * #queueRefreshButton:hover, 
body.browser.mobileweb [data-role=page].ui-page-active.darkTheme ~ #playerFooter:not(.transparent) * #songIsFavoriteBtn:hover,
body.browser.mobileweb [data-role=page].ui-page-active.darkTheme ~ #playerFooter:not(.transparent) * #tagsBtn:hover,
body.browser.mobileweb [data-role=page].ui-page-active.darkTheme ~ #playerFooter:not(.transparent) * #shareSongBtn:hover
{
   background-color: var(--color-white-5);
   border-color: var(--color-white-5);
}

body.browser.mobileweb #playerFooter.browser.transparent > div > div#footerControlsWrapper > #playerControlsWrapper > div > div > a#playButton:hover,
body.browser.mobileweb #playerFooter.browser.transparent > div > div#footerControlsWrapper > #playerControlsWrapper > div > a#playButton:hover,

body.browser.mobileweb.darkTheme #playerFooter.browser:not(.transparent) > div > div#footerControlsWrapper > #playerControlsWrapper > div > div > a#playButton:hover,
body.browser.mobileweb.darkTheme #playerFooter.browser:not(.transparent) > div > div#footerControlsWrapper > #playerControlsWrapper > div > a#playButton:hover,

body.browser.mobileweb [data-role=page].ui-page-active.darkTheme ~ #playerFooter.browser:not(.transparent) > div > div#footerControlsWrapper > #playerControlsWrapper > div > div > a#playButton:hover,
body.browser.mobileweb [data-role=page].ui-page-active.darkTheme ~ #playerFooter.browser:not(.transparent) > div > div#footerControlsWrapper > #playerControlsWrapper > div > a#playButton:hover{
   background-color: var(--color-white-70);
   border-color: var(--color-white-70);
}

body.browser.mobileweb #playerFooter.browser:not(.transparent) > div > div#footerControlsWrapper > #playerControlsWrapper > div > div > a#playButton:hover,
body.browser.mobileweb #playerFooter.browser:not(.transparent) > div > div#footerControlsWrapper > #playerControlsWrapper > div > a#playButton:hover{
   background-color: var(--color-black-70);
   border-color: var(--color-black-70);
}

div#progressContainer {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    -webkit-flex: 1 1 auto;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}
#playerFooter > div > a#playPauseBtn.ion-loading-c {
    font-size: var(--text-5xl);
    line-height: 44px;
    margin: 10px;
    height: 44px;
    min-width: 44px;
    width: 44px;
}
#playerFooter > div > a#playPauseBtn {
 padding: 0;
 vertical-align: middle;
 border: var(--border-0);
 width: 45px;
 height: 45px;
 background-color:white;
 border-radius:50%;
 font-size: var(--text-2xl);
 line-height: 45px;
 background-color: var(--text-dark);
 color: white;
 min-width: 45px;
 margin: auto 10px;
 display: flex;
 justify-content: center;
 align-items: center;
}

#playerFooter > div > a#playPauseBtn t{
 display: flex;
 justify-content: center;
 align-items: center;
}

#playerFooter > div > a#playPauseBtn t.ion-ios7-play{
    padding-left: 5px;
}
#playerFooter.transparent > div > a#playPauseBtn,
body.darkTheme #playerFooter:not(.transparent) > div > a#playPauseBtn,
[data-role=page].ui-page-active.darkTheme ~ #playerFooter:not(.transparent) > div > a#playPauseBtn {
    color: var(--text-dark);
    background-color: var(--text-light);
}
#playerFooter > div > a#playPauseBtn:active {
    color: var(--text-dark);
    background-color: var(--text-light);
}
#playerFooter.transparent > div > a#playPauseBtn:active ,
body.darkTheme #playerFooter:not(.transparent) > div > a#playPauseBtn:active ,
[data-role=page].ui-page-active.darkTheme ~ #playerFooter:not(.transparent) > div > a#playPauseBtn:active {
    color: var(--text-light);
    background-color: var(--text-dark);
}
#playerSongPositionSlider {
    width: 100%;
    top:inherit;
    background-color: transparent;
    box-shadow: none;
}
paper-slider#playerSongPositionSlider::shadow #sliderBar::shadow #secondaryProgress {
    background-color: var(--trebble-primary-dark-35);
}
paper-slider#playerSongPositionSlider::shadow #sliderKnobInner,
paper-slider#playerSongPositionSlider::shadow #sliderBar::shadow #activeProgress {
    background-color: var(--trebble-primary-dark);
    border: var(--border-0);
}
paper-slider#playerSongPositionSlider #sliderBar #secondaryProgress {
    background-color: var(--trebble-primary-dark-35);
}
paper-slider#playerSongPositionSlider #sliderKnobInner,
paper-slider#playerSongPositionSlider #sliderBar #activeProgress {
    background-color: var(--trebble-primary-dark);
    border: var(--border-0);
}
/*
paper-slider#playerSongPositionSlider::shadow #sliderContainer{
    width: calc(100% - 16px);
}
paper-slider#playerSongPositionSlider::shadow #sliderBar{
    left: 8px;
}
paper-slider#playerSongPositionSlider::shadow #sliderKnob {
    width:16px;
}
*/

paper-slider#playerSongPositionSlider::shadow #sliderBar::shadow #progressContainer {
    background-color: var(--color-white-20);
}
paper-slider#playerSongPositionSlider #sliderBar #progressContainer {
    background-color: var(--color-white-20);
}
#playerFooter.browser * paper-slider#playerSongPositionSlider #sliderBar #progressContainer {
    background-color: var(--gray-overlay-20);
}
#playerFooter.browser * paper-slider#playerSongPositionSlider::shadow #sliderBar::shadow #progressContainer,
#playerFooter.browser * paper-slider#playerSongPositionSlider #sliderBar #progressContainer {
    background-color: var(--gray-overlay-20);
}
[trebbleclass=playrButtonWrapper] > div > div {
    width: 100%;
}
#playerFooter > div > div#footerPlayerCurrentSongWrapper {
    height: 100%;
}
#playerFooter > div > div#footerPlayerCurrentSongWrapper > a.ui-btn-down-undefined {
    background-color: var(--trebble-primary);
    border: var(--border-1) solid var(--trebble-primary);
    color: var(--text-light)
}
#songSocialInfoWrapper {
    font-size: var(--text-sm);
    padding: 5px;
    font-weight: 400;
    /*font-weight: 300;*/
    
    color: var(--color-white-50);
}
body.embedded * #songSocialInfoWrapper {
    font-size: var(--text-sm);
    line-height: 18px;
    padding: 0px;
}
#playerFooter > div > div#footerPlayerCurrentSongWrapper > a {
    margin: 0px;
    height: 100%;
    text-decoration: none;
    overflow: hidden;
    width: 100%;
}
#footerCurrentSongInfo {
    padding-right: 5px;
    padding-left: 5px;
    margin: 0px;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    -webkit-flex: 1 1 auto;
}
#footerCurrentSongInfo:active > #footerCurrentSongTitle,
#footerCurrentSongInfo:active > #footerCurrentSongArtist,
#footerCurrentSongInfo:active > #mediaSourceBtn  {
    color: white !important;
}
#footerCurrentSongTitle,
.songTitle,
.itemTitle,
.NotficationCard * .songTitle,
.NotficationCard * .itemTitle,
.userListItem > .infoWrapper >.userUsername {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 400;
    /*font-weight: 300;*/
    
    font-size: var(--text-md);
    text-align: left;
    color: white;
    color: var(--text-dark);
    max-width: 100%;
}
#footerCurrentSongTitle{
 font-weight: 900 !important;
 font-size: var(--text-md);
}


@keyframes marquee {
    0%   { transform: translate(0, 0); }
    100% { transform: translate(-100%, 0); }
}

.marquee {
    color: white;
    white-space: nowrap;
    overflow: visible !important;
    box-sizing: border-box;
}
.marquee span {
    display: inline-block;
    padding-left: 100%;
    animation: marquee 30s linear infinite;
}

#footerCurrentSongInfo, #playerPositionSliderWrapper{
    position: relative;
}
#mediaSourceBtn{
    font-size: var(--text-sm);
    padding: 0px 5px 0px 5px;
    color: white;
    cursor: pointer;
    
}
body.browser.mobileweb #mediaSourceBtn:hover,
#mediaSourceBtn:active{
    color: var(--trebble-primary) !important;
    text-shadow: none;
}

#player #playerPositionSliderWrapper > #mediaSourceBtn {
  color: white;

}

#playerPositionSliderWrapper > #mediaSourceBtn{
    bottom: -16px;
    position: absolute;
    right: 0px;
    color: var(--text-dark);
}

#youtubeVideoLinkWrapper ~ #mediaSourceBtn{
    bottom: 0px;
    position: absolute;
    right: 0px;
    padding-bottom: 10px;
    text-shadow: 2px 2px var(--color-black-10);
}
#footerCurrentSongInfo > #mediaSourceBtn{
    position: absolute;
    bottom: 0px;
    right: 0px;
    color: var(--text-dark);
    font-size: var(--text-xs);
}

[data-role=popup] .userListItem > .infoWrapper >.userUsername,
[data-role=popup] .userListItem > .infoWrapper >.userFullname,
[data-role=popup] .songTitle {
  color: white;
  font-weight: 600;
  font-size: var(--text-md);
}

[data-role=popup] .artistAndAlbum{
  font-size: var(--text-md);
} 

#FeedAndActivityPage  .NotficationCard.unread {
  background-color: var(--color-pink-light);
}

.userListItem:active > .infoWrapper >.userUsername,
.userListItem:active > .infoWrapper >.userFullname {
    color: white;
}
.songTitle,
.itemTitle {
    color: white;
}
.notificationTitle.itemTitle {
    color: var(--text-dark);
    /* font-size: var(--text-md); */
}
[data-role=page] * .songInfo * .itemTitle {
    color: var(--text-dark);
}
.lighttext * .songInfo * .itemTitle {
    color: var(--color-white-80);
}
[data-role=page] * .lighttext * .songInfo * .itemSubtitle {
    color: var(--color-white-30);
}
[data-role=page] * .songInfo * .itemSubtitle {
    color: var(--text-dark);
}
[data-role=page].lighttext * .songInfo * .itemSubtitle {
    color: var(--color-white-30);
    opacity: 1;
}
#footerCurrentSongArtist,
.artistAndAlbum,
.itemSubtitle,
.NotficationCard * .artistAndAlbum,
.NotficationCard * .itemSubtitle,
.userListItem > .infoWrapper >.userFullname {
    white-space: nowrap;
    opacity: 0.5;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: var(--text-sm);
    color: white;
    font-weight: 400;
    text-align: left;
    color: var(--text-dark);
    max-width: 100%;
}
#playerFooter.transparent * #footerCurrentSongTitle,
#playerFooter.transparent * #footerCurrentSongArtist,
body.darkTheme #playerFooter:not(.transparent) * #footerCurrentSongTitle,
[data-role=page].ui-page-active.darkTheme * #footerCurrentSongTitle,
body.darkTheme  * div#playerFooter #footerCurrentSongInfo #footerCurrentSongTitle ,
[data-role=page].ui-page-active.darkTheme ~ div#playerFooter #footerCurrentSongInfo #footerCurrentSongTitle,
body.darkTheme #playerFooter:not(.transparent) * #footerCurrentSongTitle,
[data-role=page].ui-page-active.darkTheme ~ #playerFooter:not(.transparent) * #footerCurrentSongArtist {
    color: white;
}
.artistAndAlbum,
.itemSubtitle {
    color: white;
}
/* Player Queue*/

#queueTrackWrapper > li {
    border-bottom-width: var(--border-1);
    border-bottom-color: var(--color-gray-400);
    border-bottom-style: solid;
    color: var(--color-black);
}
#queueTrackWrapper > li > div > div > a {
    padding: 5px;
    height: 50px;
    text-decoration: none;
}
#QueueHeaderWrapper {
    font-size: var(--text-md);
    font-weight: 200;
}
ui-btn-inner {
    padding-right: 0px;
    padding-left: 0px;
    margin: 0px;
}
[id=toolbarWrapper] > div > a {
    color: var(--color-gray-400);
    width: 20%;
    font-weight: 400;
    border: var(--border-0);
    border-top-width: var(--border-0);
    border-left-width: var(--border-0);
    border-right-width: var(--border-0);
    border-bottom-width: var(--border-4);
    border-bottom-color: var(--color-gray-300);
    border-bottom-style: solid;
    border-bottom-color: var(--color-black);
    border-bottom-width: var(--border-4);
}
[id=toolbarWrapper] > div > a > .ui-btn-inner {
    padding-right: 0px;
    padding-left: 0px;
    margin: 0px;
}
/*CSS for player page */

#player > div.ui-content {
    width: 100%;
    position: fixed;
    background-image: linear-gradient( var(--color-black-0), var(--color-black-10) 60%, var(--color-black-80) 100%);

}

body.browser.mobileweb.embedded  #player > div.ui-content{
    background-image: linear-gradient(var(--color-black-10),var(--color-black-30) 100%);
}

body.browser.mobileweb.embedded  #player .playerCoverArtImage-landscape, body.browser.mobileweb.embedded #player .playerCoverArtImage-portrait{
    box-shadow: none;
}

div[data-role=popup] > a#closeBtn {
  box-sizing: border-box;
  line-height: 50px;
  top: 0px;
  position: absolute;
  width: 50px;
  font-weight: 400;
  /*font-weight: 300;*/
  font-size: var(--text-6xl);
  margin: 0px;
  padding: 0px;
  padding-left: 7px;
  padding-right: 7px;
  /*top: -25px;
  left: -10px;*/
  right: 10px;
  left: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  border: var(--border-0);
  box-sizing: border-box;
  -webkit-transition: all .3s cubic-bezier(.215,.61,.355,1);
  transition: all .3s cubic-bezier(.215,.61,.355,1);
  transform-origin: 50% 50%;
  -ms-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
}


@media (min-width: 380px), (min-height: 380px) and   (orientation: landscape) {
    div[data-role=popup] > a#closeBtn {
        font-size: var(--text-7xl);
        right: 10px;
        left: auto;
    }
}
body.browser.mobileweb div[data-role=popup] > a#closeBtn:hover,
div[data-role=popup] > a#closeBtn:active{
    /*transform: rotateZ(90deg);
    -ms-transform: rotate(90deg);
    transform: rotateZ(90deg)*/
    transform: scale(1.2);
    -ms-transform: scale(1.2);
    transform: scale(1.2);
    /*ackground-color: var(--color-white-10);*/
}
div[data-role=popup].whatsnew * .owl-theme .owl-controls .owl-page span {
    width: 6px;
    height: 6px;
    margin: 4px;
    background-color: white;
}
div[data-role=popup].whatsnew * .header {


    box-sizing: border-box;
    
    color: white;
    font-size: var(--text-3xl);
    font-weight: 300;
    margin: 0px 30px;
    font-size: var(--text-6xl);
    font-weight: 700;
}
div[data-role=popup].whatsnew * .title {
    margin-top: 10px;
    font-size: var(--text-xl);
    margin-bottom: 10px;
    margin: 20px;
    font-weight: 300;
    color: white;
}
div[data-role=popup].whatsnew * .details {
    padding: 20px;
    font-size: var(--text-md);
    font-weight: 400;
    color: white;
    box-sizing: border-box;
    /* max-width: 700px;*/
}
@media ( max-height: 470px) {
    div[data-role=popup].whatsnew * .details {
        font-size: var(--text-sm);
    }
}
div[data-role=popup].whatsnew * .previewImage {
    background-position: center;
    background-size: contain;
    height: 200px;
    width: 100%;
    background-repeat: no-repeat;
}
#library:first-child {
    content: "";
    position: absolute;
    height: 20%;
    width: 20%;
    background-image: none;
    background-size: cover;
    z-index: -1;
    /* Keep the background behind the content */
    /* don't forget to use the prefixes you need */
    
    transform: scale(5);
    transform-origin: top left;
    filter: blur(2px);
    -webkit-filter: blur(2px);
    -moz-filter: blur(2px);
    -o-filter: blur(2px);
    -ms-filter: blur(2px);
    /*filter : url(#blur-effect-2);*/
    
}

.userListPage .ui-content > .left-right-content-padding{
    height: 100%;
}

#header-back-button{
    height: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
}
#header-back-button.ui-btn-left {
    left: 0px;
}
#queue > div[data-role=header] > #header-queue-button.ui-btn-right {
    right: 0px;
    line-height: 53px;
}
div[data-role=header] > #header-queue-button.ui-btn-right {
    right: 0px;
}
#header-back-button.ui-btn-up-a,
#header-queue-button.ui-btn-up-a {
    color: var(--color-gray-700);
}
#mini-header-back-button {
    padding-left: 2px;
    padding-right: 2px;
    padding-bottom: 7px;
    padding-top: 7px;
    width: 1em;
    border-bottom: var(--border-0);
    top: 0em;
    height: 100%;
    line-height: 2em;
    font-size: var(--text-base);
    display: block;
    text-align: center;
}
.defaultPage * #header-back-button,
.defaultPage * #header-queue-button {
    /*color: var(--color-gray-500);*/
    
    font-size: var(--text-2xl);
    line-height: 1.7em;
}
a#queueMenuOptionBtn {
    /*color: var(--color-gray-500);*/
    
    font-size: var(--text-4xl);
    line-height: 50px;
}
/*
.defaultPage > div.ui-header[data-role=header] {
    border-bottom: var(--border-1) solid var(--color-gray-150) !important;
    height: 52px;
    background-color: white;
    border-color: var(--color-gray-150);
    color: var(--text-dark);
    }*/

    #header-back-button,
    #header-queue-button,
    #tuninButton {
        border: var(--border-0);
        color: white;
        margin: 0px;
        padding: 0px;
        border-bottom: var(--border-0);
        top: 0em;
        line-height: 51px;
        font-size: var(--text-4xl);
        display: block;
        text-align: center;
        width: 51px;
        font-weight: 300;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    html  body.browser.mobileweb  .ui-page-theme-a .ui-btn:hover,
    html  body.browser.mobileweb .ui-bar-a .ui-btn:hover,
    html  body.browser.mobileweb .ui-body-a .ui-btn:hover,
    html  body.browser.mobileweb  .ui-group-theme-a .ui-btn:hover,
    html  body.browser.mobileweb  .ui-btn.ui-btn-a:hover,
    body.browser.mobileweb #header-back-button:hover,
    body.browser.mobileweb #header-queue-button:hover,
    body.browser.mobileweb #tuninButton:hover {
        background-color: var(--trebble-primary);
        border-color: var(--trebble-primary);
        color: white !important;
        transform: translateZ(0);
        transform: translateZ(0);
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        -moz-osx-font-smoothing: grayscale;
        -webkit-transition-duration: 0.3s;
        transition-duration: 0.3s;
        -webkit-transition-property: color, background-color;
        transition-property: color, background-color;
    }
    html  body.browser.mobileweb .ui-page-theme-a.lighttext .ui-btn:not(.disabled):hover,
    html  body.browser.mobileweb .lighttext .ui-bar-a .ui-btn:not(.disabled):hover,
    html  body.browser.mobileweb .ui-body-a .lighttext .ui-btn:not(.disabled):hover,
    html  body.browser.mobileweb  .lighttext .ui-group-theme-a .ui-btn:not(.disabled):hover,
    html  body.browser.mobileweb  .lighttext .ui-btn.ui-btn-a:not(.disabled):hover,
    body.browser.mobileweb .lighttext #header-back-button:not(.disabled):hover,
    body.browser.mobileweb .lighttext #header-queue-button:not(.disabled):hover,
    body.browser.mobileweb .lighttext #tuninButton:not(.disabled):hover,
    body.browser.mobileweb .ui-page-theme-a#player .ui-btn:not(.disabled):hover,
    body.browser.mobileweb:not(.embedded) #player .ui-bar-a .ui-btn:not(.disabled):hover,
    body.browser.mobileweb:not(.embedded) #player .ui-btn:not(.disabled):hover,
    body.browser.mobileweb:not(.embedded) #player .ui-group-theme-a .ui-btn:not(.disabled):hover,
    body.browser.mobileweb:not(.embedded) #player .ui-btn.ui-btn-a:not(.disabled):hover,
    body.browser.mobileweb:not(.embedded) #player #header-back-button:not(.disabled):hover,
    body.browser.mobileweb:not(.embedded) #player #header-queue-button:not(.disabled):hover,
    body.browser.mobileweb:not(.embedded) #player #tuninButton:not(.disabled):hover,
    body.browser.mobileweb div#playerSocialInfoWrapperInQueue > div > a:not(.disabled):hover,
    body.browser.mobileweb #songPoppupMenu > div > ul > li > a:not(.disabled):hover,
    body.browser.mobileweb .popup-menu-content > div > ul > li > a:not(.disabled):hover,
    body.browser.mobileweb .ui-page.lighttext .trebble-context-menu li.context-menu-item > a.menu_with_keyboard_shortcut:not(.disabled):hover,
    body.browser.mobileweb.darkTheme .trebble-context-menu li.context-menu-item > a.menu_with_keyboard_shortcut:not(.disabled):hover,
    body.browser.mobileweb .sequencer_node_default_context_menu.horizontal_menu .menu_item:not(.disabled):hover,
    body.browser.mobileweb .playlistShortcutButtonsWrapper > #showStatistics:not(.disabled):hover, 
    body.browser.mobileweb .playlistShortcutButtonsWrapper > #shareTrebbleBtn:not(.disabled):hover,
    body.browser.mobileweb .transparent_button:not(.disabled):hover,
    body.browser.mobileweb .ui-page.lighttext .trebble-context-menu li.context-menu-item > a.menu_with_keyboard_shortcut:not(.disabled):hover,
    body.browser.mobileweb .ui-page.lighttext ~ .trebble-context-menu li.context-menu-item > a.menu_with_keyboard_shortcut:not(.disabled):hover,
    body.browser.mobileweb .c-bottom-sheet ul.trebbleOptionList > li > a.menu_with_keyboard_shortcut:not(.disabled):hover
    {
        background-color: var(--color-white-10);
        border-color: var(--color-white-10);
        color: white !important;
        transform: translateZ(0);
        transform: translateZ(0);
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        -moz-osx-font-smoothing: grayscale;
        -webkit-transition-duration: 0.3s;
        transition-duration: 0.3s;
        -webkit-transition-property: color, background-color;
        transition-property: color, background-color;
    }

    
    body.browser.mobileweb:not(.darkTheme) .ui-page-active:not(.lighttext) ~ .drop-element .popup-menu-content > div > ul > li > a:not(.disabled):hover,
    body.browser.mobileweb:not(.darkTheme) .ui-page-active:not(.lighttext)  .sequencer_node_default_context_menu.horizontal_menu .menu_item:not(.disabled):hover,
    body.browser.mobileweb .trebble-context-menu li.context-menu-item > a.menu_with_keyboard_shortcut:not(.disabled):hover,
    body.browser.mobileweb .c-bottom-sheet ul.trebbleOptionList > li > a.menu_with_keyboard_shortcut:not(.disabled):hover
    {
        background-color: var(--color-black-5);
        border-color: var(--color-black-5);
        color: var(--text-dark) !important;

    }


    body.browser.mobileweb:not(.embedded) #player .ui-btn#playButton:not(.disabled):hover
    {
        background-color: transparent;
        border-color: transparent;
    }




    @supports not (backdrop-filter: blur(20px)) {
       html  body.browser.mobileweb .ui-page-theme-a.lighttext .ui-btn:not(.disabled):hover,
       html  body.browser.mobileweb .lighttext .ui-bar-a .ui-btn:not(.disabled):hover,
       html  body.browser.mobileweb .ui-body-a .lighttext .ui-btn:not(.disabled):hover,
       html  body.browser.mobileweb  .lighttext .ui-group-theme-a .ui-btn:not(.disabled):hover,
       html  body.browser.mobileweb  .lighttext .ui-btn.ui-btn-a:not(.disabled):hover,
       body.browser.mobileweb .lighttext #header-back-button:not(.disabled):hover,
       body.browser.mobileweb .lighttext #header-queue-button:not(.disabled):hover,
       body.browser.mobileweb .lighttext #tuninButton:not(.disabled):hover,
       body.browser.mobileweb .ui-page-theme-a#player .ui-btn:not(.disabled):hover,
       body.browser.mobileweb:not(.embedded) #player .ui-bar-a .ui-btn:not(.disabled):hover,
       body.browser.mobileweb:not(.embedded) #player .ui-btn:not(.disabled):hover,
       body.browser.mobileweb:not(.embedded) #player .ui-group-theme-a .ui-btn:not(.disabled):hover,
       body.browser.mobileweb:not(.embedded) #player .ui-btn.ui-btn-a:not(.disabled):hover,
       body.browser.mobileweb:not(.embedded) #player #header-back-button:not(.disabled):hover,
       body.browser.mobileweb:not(.embedded) #player #header-queue-button:not(.disabled):hover,
       body.browser.mobileweb:not(.embedded) #player #tuninButton:not(.disabled):hover,
       body.browser.mobileweb div#playerSocialInfoWrapperInQueue > div > a:not(.disabled):hover,
       body.browser.mobileweb #songPoppupMenu > div > ul > li > a:not(.disabled):hover,
       body.browser.mobileweb .popup-menu-content > div > ul > li > a:not(.disabled):hover,
       body.browser.mobileweb .sequencer_node_default_context_menu.horizontal_menu .menu_item:not(.disabled):hover,
       body.browser.mobileweb .playlistShortcutButtonsWrapper > #showStatistics:not(.disabled):hover, 
       body.browser.mobileweb .playlistShortcutButtonsWrapper > #shareTrebbleBtn:not(.disabled):hover,
       body.browser.mobileweb .transparent_button:not(.disabled):hover
       {
        background-color: var(--color-black-5);
        border-color: var(--color-black-5);
        color: var(--text-dark) !important;

    }
}
body.browser.mobileweb [data-role=page]:not(.lighttext) .transparent_button:hover,
.transparent_button:active,
body.browser.mobileweb  .userProfileDetailsPage * #userProfileImage > #editProfilePicBtn:hover,
.userProfileDetailsPage * #userProfileImage > #editProfilePicBtn:active  {
  border-color: var(--color-white-100);
  background-color: var(--color-white-100);
  color: var(--text-dark) !important;
}
body.browser.mobileweb [data-role=page]:not(.lighttext) .transparent_button.toggled:hover,
[data-role=page]:not(.lighttext) .transparent_button.toggled:active{
    color: white !important;  
    font-weight: bold;
    background-color: var(--trebble-primary);   
    border: var(--border-2) solid var(--trebble-primary);
}

[class*="ion"].transparent_button:before{
  margin-right: 10px;
  font-size: var(--text-5xl);
  height: 32px;
  vertical-align: middle;
  
}

html .ui-page-theme-a .ui-btn:active,
html .ui-bar-a .ui-btn:active,
html .ui-body-a .ui-btn:active,
html body .ui-group-theme-a .ui-btn:active,
html body .ui-btn.ui-btn-a:active,
#header-back-button:active,
#header-queue-button:active,
#tuninButton:active {
    background-color: var(--trebble-primary-dark);
    border-color: var(--trebble-primary-dark);
    color: white !important;
    transform: translateZ(0);
    transform: translateZ(0);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -moz-osx-font-smoothing: grayscale;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-property: color, background-color;
    transition-property: color, background-color;
}
html .ui-page-theme-a.lighttext .ui-btn:active,
html .lighttext .ui-bar-a .ui-btn:active,
html .lighttext .ui-body-a .ui-btn:active,
html body .lighttext .ui-group-theme-a .ui-btn:active,
html body .lighttext .ui-btn.ui-btn-a:active,
.lighttext #header-back-button:active,
.lighttext #header-queue-button:active,
.lighttext #tuninButton:active,
.ui-page-theme-a#player .ui-btn:active,
#player .ui-bar-a .ui-btn:active,
#player .ui-btn:active,
#player .ui-group-theme-a .ui-btn:active,
#player .ui-btn.ui-btn-a:active,
#player #header-back-button:active,
#player #header-queue-button:active,
#player #tuninButton:active,
div#playerSocialInfoWrapperInQueue > div > a:active,
#songPoppupMenu > div > ul > li > a:active,
.popup-menu-content > div > ul > li > a:active {
    background-color: var(--color-white-10);
    border-color: var(--color-white-10);
    color: white !important;
    transform: translateZ(0);
    transform: translateZ(0);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -moz-osx-font-smoothing: grayscale;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-property: color, background-color;
    transition-property: color, background-color;
}
#tuninButton,
body.browser.mobileweb #tuninButton:hover,
#tuninButton:active,
#player #tuninButton,
body.browser.mobileweb #player #tuninButton:hover,
#player #tuninButton:active,
body.browser.mobileweb div#playerSocialInfoWrapperInQueue > div > a:hover,
div#playerSocialInfoWrapperInQueue > div > a:active {
    transform: rotate(90deg);
    transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
}
.darktext * #header-back-button,
.darktext * #header-queue-button {
    color: var(--text-dark) !important;
}
.no_border {
    border-width: var(--border-0) !important;
}
#albumDetailsPage > div[data-role=header] {
    overflow: visible;
    height: 54px;
    border: var(--border-0);
    background-color: transparent;
    
    background-image: var(--gradient-fade-dark-top);
}
.userProfileDetailsPage > div.ui-header[data-role=header] {
    overflow: visible;
    height: 54px;
    border: var(--border-0);
    background-color: transparent;
}
#albumDetailsPage > div[data-role=header] > a.ion-ios7-more,
#albumDetailsPage > div[data-role=header] > a.pe-7s-more {
    padding: 0px;
    top: 0em;
    vertical-align: middle;
    text-align: center;
    color: white;
    line-height: 54px;
    font-size: var(--text-4xl);
    right: 0px;
}
.songInfoCarousel {
    display: block;
    /* overflow-x: scroll; */
}
.songInfoCarousel * .owl-item {
    margin-left: 5px;
    margin-right: 5px;
}
.songOverviewCard {
    height: 100px;
    float: left;
    margin-left: 5px;
    margin-right: 5px;
}
.songOverviewCard > div {
    height: 100%;
    width: 100%;
}
.songOverviewCard * .songInfo {
    position: absolute;
    bottom: 0px;
    height: 35%;
    width: 100%;
    display: block;


    font-size: var(--text-2xs);
    color: white;
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex-direction: column;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-justify-content: flex-end;
    padding-bottom: 3px;
    padding-top: 3px;
}
.popupContent * .songOverviewCard * .songInfo > div {
    color: white;
}


.songInfo.doNotShowArtistName .artistName,
.songInfo.doNotShowCoverArt .songCoverArt{
    display: none;
}

.songInfo.doNotShowCoverArt .songAndArtist{
    width: 100%;
}
.songInfo.doNotShowCoverArt .songName,
.songInfo.doNotShowCoverArt .artistName{
    text-align: center;
}
.songOverviewCard * .songInfo > div {
    overflow-x: hidden;
    text-overflow: ellipsis;
    margin-left: 5px;
    font-weight: 400;
    /*font-weight: 300;*/

    color: var(--text-dark);
    white-space: nowrap;
}
.songOverviewCard * .songInfo > div:first-child {
    overflow-x: hidden;
    text-overflow: ellipsis;
    margin-left: 5px;
    opacity: 0.5;
    font-weight: 400;
}
.songOverviewCard * .songAlbumCoverArt {
    height: 100%;
    width: 100%;
    /*background-image: url('img/videoLoading.png');*/

    background-size: cover;
    top: 0px;
    left: 0px;
    /*background-color: var(--color-gray-100);*/
}
.songOverviewCard * .songAlbumCoverArt > div {
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
}
.popupContent * .songOverviewCard * .songAlbumCoverArt > div {
    background-color: var(--color-white-10);
    height: 100%;
    width: 100%;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-justify-content: center;
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-align-content: center;
    /*background-color: var(--color-white-20);*/
}
.popupContent * .songOverviewCard * .songAlbumCoverArt > div:active {
    background-color: var(--color-white-50);
}
#artistDetailsPage > div[data-role=header] > a.ion-ios7-more,
.playlistDetailsPage > div[data-role=header] > a.ion-ios7-more,
#artistDetailsPage > div[data-role=header] > a.pe-7s-more,
.playlistDetailsPage > div[data-role=header] > a.pe-7s-more {
    padding: 0px;
    top: 0em;
    vertical-align: middle;
    text-align: center;
    color: white;
    line-height: 54px;
    font-size: var(--text-5xl);
    right: 0px;
}
#artistDetailsPage > div[data-role=header],
.playlistDetailsPage > div[data-role=header],
.artistDetailsPage > div[data-role=header],
.albumDetailsPage > div[data-role=header] {
    background-color: transparent;
    overflow: visible;
    border: var(--border-0);
    background-image: var(--gradient-fade-dark-to);
}
#artistDetailsPage.darktext > div[data-role=header],
.playlistDetailsPage.darktext > div[data-role=header],
.artistDetailsPage.darktext > div[data-role=header],
.albumDetailsPage.darktext > div[data-role=header] {
    color: var(--text-dark) !important;
}
#albumDetailsPage > div[data-role=header] > #header-back-button {
    color: white;
    text-overflow: ellipsis;
    font-weight: 300;
    line-height: 2em;
    font-size: var(--text-xl);
    overflow: hidden;
}
#artistDetailsPage > div[data-role=header] > #header-back-button,
.playlistDetailsPage > div[data-role=header] > #header-back-button,
.trebbleEditInfoPage > div[data-role=header] > #header-back-button {
    color: white;
    text-overflow: ellipsis;
    font-weight: 300;
    line-height: 2.5em;
    font-size: var(--text-lg);
    overflow: hidden;
}

.trebbleEditInfoPage #trebbleCategoryField-button,
.trebbleEditInfoPage #trebbleLanguageField-button,
.trebbleEditInfoPage #isJourneyFreeField-button,
.trebbleEditInfoPage #trebbleVisibiltiyField-button,
.trebbleEditInfoPage #journeyCostField-button,
.trebbleEditInfoPage #trebbleTimezoneField-button{
  background-color:transparent;
  color:var(--trebble-primary) !important;
  border-width: var(--border-2);
  font-weight:400;
  height: 46px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  font-size: var(--text-sm-px);
  padding: 0px;
  margin-right: 10px;
  margin-left: 10px;
}
#playerCurrentSongTitleWrapper {
    font-weight: 600 !important;
    /*font-weight: 400;
    font-weight: 300;*/

    padding-left: 4em;
    padding-right: 4em;
    margin-left: 0em;
    margin-right: 0em;
}
.playerCoverArtImage-landscape {
    height: 98%;
    /*position: absolute;*/
    margin: 0px auto;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    visibility: visible;
    background-color: black;
    box-shadow: 9px 9px 40px var(--color-black-50);
    /*box-shadow: 0 0 50px var(--color-black-30);
    -moz-box-shadow: 0 0 50px var(--color-black-30);
    background-color: black;*/
}
#youtubeVideoLinkWrapper {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.playerCoverArtImage-portrait {
    margin: 5px;
    background-size: contain;
    /* height: 100%; */

    position: absolute;
    margin: 0px auto;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background-color: black;
    box-shadow: 9px 9px 40px var(--color-black-50);
    /*box-sizing: border-box;
    box-shadow: 0 0 50px var(--color-black-30);
    -moz-box-shadow: 0 0 50px var(--color-black-30);
    background-color: black;*/
}
body.embedded * #playerCurrentSongTitle {
    font-size: var(--text-sm);
    line-height: 18px;
}
#playerCurrentSongTitle {
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 600 !important;
    /*font-weight: 400;
    font-weight: 300;*/

    font-size: var(--text-base);
    text-align: left;
    color: white;
    white-space: nowrap;
}
#playerFooter.browser * #playerCurrentSongTitle,
#playerFooter.browser * #playerCurrentArtist {
    color: var(--text-dark);
}
#playerCurrentSongTitleWrapper > div {
    overflow: hidden;
    text-overflow: ellipsis;
    /* padding-left: 4em; */
    /* padding-right: 4em; */

    margin-left: 0em;
    margin-right: 0em;
}
body.embedded * #playerCurrentArtist {
    font-size: var(--text-sm);
    line-height: 17px;
}
#playerCurrentArtist {
    opacity: 0.5;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: var(--text-sm);
    color: white;
    font-weight: 400;
    text-align: left;
    white-space: nowrap;
}
#resizeVideoFullBtn {
    padding: 0px;
    background-color: transparent;
    border: var(--border-0);
    margin: 0em;
    width: 1.7em;
    height: 1.7em;
    text-align: center;
    line-height: 1.5em;
    position: absolute;
    bottom: 0px;
    left: 0px;
    opacity: 0.8;
    font-size: var(--text-2xl);
    display: block;
}


body.browser:not(.mobilebrowser) #youtubeVideoLinkWrapper:hover ~ #resizeVideoFullBtn.showOnHover,
body.browser:not(.mobilebrowser) #youtubeVideoLinkWrapper:active ~ #resizeVideoFullBtn.showOnHover,
body.browser:not(.mobilebrowser)  #resizeVideoFullBtn.showOnHover:hover,
body.browser:not(.mobilebrowser)  #resizeVideoFullBtn.showOnHover:active {
  display: block ;
}

body.browser.mobilebrowser  #resizeVideoFullBtn.showOnHover {
  display: block ;
}

#playerSongPositionWrapper {
    padding-right: 1em;
    padding-left: 1em;
    height: 2em;
    width: 100%;
}
#playerSongPositionWrapper > div {
    display: flex;
    display: -webkit-flex;
    line-height: 2em;
    height: 2em;
    width: 100%;
    margin-top: 0em;
}
#playerSongPositionWrapper > div > div#songCurrentPosition {
    padding-right: 0.3em;
}
#playerSongPositionWrapper > div > div#trackDuration {
    margin-left: 0.3em;
}
#playerSongPositionWrapper > div > div {
    opacity: 1;
    margin-right: 0em;
    margin-left: 0em;
    font-size: var(--text-xs);
    color: white;
    height: 100%;
    width: 2em;
}

a#queueRefreshButton,div#playerSocialInfoWrapperInQueue>div>a{
    font-size: var(--text-4xl);
    display:block;
    margin:7px;
    padding:0;
    border:var(--border-0)
}
#playerFooter.browser * #playerSongPositionWrapper > div > div {
    flex: 0 0 auto;
    color: var(--text-dark);
}
#playerFooter.browser * #playerSongPositionWrapper > div > div#playerPositionSliderWrapper {
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;


}
div#playerSocialInfoWrapperInQueue {
    height: 4em;
}
div#playerSocialInfoWrapperInQueue > div {
    height: 4em;
}

#playerFooter.transparent * #playerCurrentSongTitle, 
#playerFooter.transparent * #playerCurrentArtist,
body.browser.mobileweb #playerFooter.transparent * #playerInformationButton:hover,
body.browser.mobileweb #playerFooter.transparent * #playerSongMoreButton:hover,
body.browser.mobileweb #playerFooter.transparent * #playerMoreInformationButton:hover,
body.browser.mobileweb #playerFooter.transparent * #expandPlayerButton:hover,
body.browser.mobileweb #playerFooter.transparent * #queueRefreshButton:hover, 
body.browser.mobileweb #playerFooter.transparent * #songIsFavoriteBtn:hover,
body.browser.mobileweb #playerFooter.transparent * #tagsBtn:hover,
body.browser.mobileweb #playerFooter.transparent * #shareSongBtn:hover,
#playerFooter.transparent * #playerSongPositionWrapper > div > div,
#playerFooter.transparent > div > div#footerControlsWrapper > #playerControlsWrapper > div > div > a,
#playerFooter.transparent > div > div#footerControlsWrapper > #playerControlsWrapper > div > a,

body.darkTheme #playerFooter:not(.transparent) * #playerCurrentSongTitle, 
body.darkTheme #playerFooter:not(.transparent) * #playerCurrentArtist,
body.browser.mobileweb.darkTheme #playerFooter:not(.transparent) * #playerInformationButton:hover,
body.browser.mobileweb.darkTheme #playerFooter:not(.transparent) * #playerSongMoreButton:hover,
body.browser.mobileweb.darkTheme #playerFooter:not(.transparent) * #playerMoreInformationButton:hover,
body.browser.mobileweb.darkTheme #playerFooter:not(.transparent) * #expandPlayerButton:hover,
body.browser.mobileweb.darkTheme #playerFooter:not(.transparent) * #queueRefreshButton:hover, 
body.browser.mobileweb.darkTheme #playerFooter:not(.transparent) * #songIsFavoriteBtn:hover,
body.browser.mobileweb.darkTheme #playerFooter:not(.transparent) * #tagsBtn:hover,
body.browser.mobileweb.darkTheme #playerFooter:not(.transparent) * #shareSongBtn:hover,
body.darkTheme #playerFooter:not(.transparent) * #playerSongPositionWrapper > div > div,
body.darkTheme #playerFooter:not(.transparent) > div > div#footerControlsWrapper > #playerControlsWrapper > div > div > a,
body.darkTheme #playerFooter:not(.transparent) > div > div#footerControlsWrapper > #playerControlsWrapper > div > a, 

[data-role=page].ui-page-active.darkTheme ~ #playerFooter:not(.transparent) * #playerCurrentSongTitle, 
[data-role=page].ui-page-active.darkTheme ~ #playerFooter:not(.transparent) * #playerCurrentArtist,
body.browser.mobileweb [data-role=page].ui-page-active.darkTheme ~ #playerFooter:not(.transparent) * #playerInformationButton:hover,
body.browser.mobileweb [data-role=page].ui-page-active.darkTheme ~ #playerFooter:not(.transparent) * #playerSongMoreButton:hover,
body.browser.mobileweb [data-role=page].ui-page-active.darkTheme ~ #playerFooter:not(.transparent) * #playerMoreInformationButton:hover,
body.browser.mobileweb [data-role=page].ui-page-active.darkTheme ~ #playerFooter:not(.transparent) * #expandPlayerButton:hover,
body.browser.mobileweb [data-role=page].ui-page-active.darkTheme ~ #playerFooter:not(.transparent) * #queueRefreshButton:hover, 
body.browser.mobileweb [data-role=page].ui-page-active.darkTheme ~ #playerFooter:not(.transparent) * #songIsFavoriteBtn:hover,
body.browser.mobileweb [data-role=page].ui-page-active.darkTheme ~ #playerFooter:not(.transparent) * #tagsBtn:hover,
body.browser.mobileweb [data-role=page].ui-page-active.darkTheme ~ #playerFooter:not(.transparent) * #shareSongBtn:hover,
[data-role=page].ui-page-active.darkTheme ~ #playerFooter:not(.transparent) * #playerSongPositionWrapper > div > div,
[data-role=page].ui-page-active.darkTheme ~ #playerFooter:not(.transparent) > div > div#footerControlsWrapper > #playerControlsWrapper > div > div > a,
[data-role=page].ui-page-active.darkTheme ~ #playerFooter:not(.transparent) > div > div#footerControlsWrapper > #playerControlsWrapper > div > a  {
  color: var(--color-white-70);
  font-weight: normal;
}


#playerFooter #songIsFavoriteBtn,
#player #songIsFavoriteBtn{
    display: none !important;
}

#playerFooter.transparent * #playerCurrentSongTitle,
#playerFooter.transparent * #playerCurrentArtist,
#playerFooter.transparent * #mediaSourceBtn,

body.darkTheme #playerFooter:not(.transparent) * #playerCurrentSongTitle,
body.darkTheme #playerFooter:not(.transparent) * #playerCurrentArtist,
body.darkTheme #playerFooter:not(.transparent) * #mediaSourceBtn,

[data-role=page].ui-page-active.darkTheme ~ #playerFooter:not(.transparent) * #playerCurrentSongTitle,
[data-role=page].ui-page-active.darkTheme ~ #playerFooter:not(.transparent) * #playerCurrentArtist,
[data-role=page].ui-page-active.darkTheme ~ #playerFooter:not(.transparent) * #mediaSourceBtn{
  color: white;
}

div#playerSocialInfoWrapperInQueue > div > a {
    background-color: transparent;
    border: var(--border-0);
    line-height: 50px;
    font-size: var(--text-4xl);
    display: block;
    width: 50px;
    height: 50px;
    padding: 0px;
    margin: 7px;
}
div#playerSocialInfoWrapperInQueue > div > a:active {
    background-color: var(--trebble-primary);
}
#playerSocialInfoWrapper,
#playerSocialInfoWrapperInQueue {
    line-height: 3.5em;
    height: 4.2em;
    width: 100%;
    margin-top: 0em;
    display: -webkit-flex;
    display: flex;
    background-size: cover;
}
#playerSocialInfoWrapper > div,
#playerSocialInfoWrapperInQueue > div {
    height: 3.5em;
    display: block;
    width: 3.5em;
    flex: 0 0 3.5em;
    -ms-flex: 0 0 3.5em;
    -webkit-flex: 0 0 3.5em;
}
body.embedded * #playerSocialInfoWrapper > div,
body.embedded * #playerSocialInfoWrapperInQueue > div {
    height: 40px;
    display: block;
    width: 3.5em;
}
#playerSocialInfoWrapper > div#playerSongMoreSocialWrapper,
#playerSocialInfoWrapperInQueue > div#playerSongMoreSocialWrapper {
    flex: 1 1 auto;
    -webkit-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
}
a#queueRefreshButton {
    border: var(--border-0);
    line-height: 50px;
    font-size: var(--text-4xl);
    display: block;
    width: 50px;
    height: 50px;
    padding: 0px;
    margin: 7px;
}
#playerInformationButton,
#playerSongMoreButton,
#playerMoreInformationButton,
#expandPlayerButton,
#queueRefreshButton,
#songIsFavoriteBtn,
#tagsBtn,
#shareSongBtn,
#trebbleIconButton {
    padding: 0px;
    opacity: 1;
    margin: 0em;
    width: 100%;
    vertical-align: middle;
    line-height: 2.1em;
    color: white;
    font-weight: 400;
    height: 100%;
    font-size: var(--text-xl);
    bottom: 0px;
    border-width: var(--border-0);
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    display: -ms-flexbox;
    display: flex;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-items: center;
    display: -webkit-flex;
    -webkit-justify-content: center;
    overflow: visible;
}
#playerFooter.browser:not(.transparent) * #playerInformationButton,
#playerFooter.browser:not(.transparent) * #playerSongMoreButton,
#playerFooter.browser:not(.transparent) * #playerMoreInformationButton,
#playerFooter.browser:not(.transparent) * #expandPlayerButton,
#playerFooter.browser:not(.transparent) * #queueRefreshButton,
#playerFooter.browser:not(.transparent) * #songIsFavoriteBtn,
#playerFooter.browser:not(.transparent) * #tagsBtn,
#playerFooter.browser:not(.transparent) * #shareSongBtn{
    color: var(--text-dark);
    overflow: visible;
}
#playerFooter.browser.transparent * #playerInformationButton,
#playerFooter.browser.transparent * #playerSongMoreButton,
#playerFooter.browser.transparent * #playerMoreInformationButton,
#playerFooter.browser.transparent * #expandPlayerButton,
#playerFooter.browser.transparent * #queueRefreshButton,
#playerFooter.browser.transparent * #songIsFavoriteBtn,
#playerFooter.browser.transparent * #tagsBtn,
#playerFooter.browser.transparent * #shareSongBtn,

body.darkTheme #playerFooter.browser:not(.transparent) * #playerInformationButton,
body.darkTheme #playerFooter.browser:not(.transparent) * #playerSongMoreButton,
body.darkTheme #playerFooter.browser:not(.transparent) * #playerMoreInformationButton,
body.darkTheme #playerFooter.browser:not(.transparent) * #expandPlayerButton,
body.darkTheme #playerFooter.browser:not(.transparent) * #queueRefreshButton,
body.darkTheme #playerFooter.browser:not(.transparent) * #songIsFavoriteBtn,
body.darkTheme #playerFooter.browser:not(.transparent) * #tagsBtn,
body.darkTheme #playerFooter.browser:not(.transparent) * #shareSongBtn,

[data-role=page].ui-page-active.darkTheme ~ #playerFooter.browser:not(.transparent) * #playerInformationButton,
[data-role=page].ui-page-active.darkTheme ~ #playerFooter.browser:not(.transparent) * #playerSongMoreButton,
[data-role=page].ui-page-active.darkTheme ~ #playerFooter.browser:not(.transparent) * #playerMoreInformationButton,
[data-role=page].ui-page-active.darkTheme ~ #playerFooter.browser:not(.transparent) * #expandPlayerButton,
[data-role=page].ui-page-active.darkTheme ~ #playerFooter.browser:not(.transparent) * #queueRefreshButton,
[data-role=page].ui-page-active.darkTheme ~ #playerFooter.browser:not(.transparent) * #songIsFavoriteBtn,
[data-role=page].ui-page-active.darkTheme ~ #playerFooter.browser:not(.transparent) * #tagsBtn,
[data-role=page].ui-page-active.darkTheme ~ #playerFooter.browser:not(.transparent) * #shareSongBtn {
  color: var(--color-white-70);
  overflow: visible;
}

#playerFooter.browser * #playerInformationButton:active,
#playerFooter.browser * #playerSongMoreButton:active,
#playerFooter.browser * #playerMoreInformationButton:active,
#playerFooter.browser * #expandPlayerButton:active,
#playerFooter.browser * #queueRefreshButton:active,
#playerFooter.browser * #songIsFavoriteBtn:active,
#playerFooter.browser * #tagsBtn:active,
#playerFooter.browser * #shareSongBtn:active {
    color: white;
    overflow: visible;
}
body.browser.mobileweb #playerFooter.browser * #playerInformationButton:hover,
body.browser.mobileweb  #playerFooter.browser * #playerSongMoreButton:hover,
body.browser.mobileweb  #playerFooter.browser * #playerMoreInformationButton:hover,
body.browser.mobileweb  #playerFooter.browser * #expandPlayerButton:hover,
body.browser.mobileweb  #playerFooter.browser * #queueRefreshButton:hover,
body.browser.mobileweb  #playerFooter.browser * #songIsFavoriteBtn:hover,
body.browser.mobileweb  #playerFooter.browser * #tagsBtn:hover,
body.browser.mobileweb  #playerFooter.browser * #shareSongBtn:hover {
    background-color: var(--bg-overlay-dark);
    /* border-color: var(--color-black-5); */

    transform: translateZ(0);
    transform: translateZ(0);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -moz-osx-font-smoothing: grayscale;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-property: color, background-color;
    transition-property: color, background-color;
    overflow: visible;
}

body.browser.mobileweb #playerFooter.browser * #playerInformationButton:hover,
body.browser.mobileweb  #playerFooter.browser * #playerSongMoreButton:hover,
body.browser.mobileweb  #playerFooter.browser * #playerMoreInformationButton:hover,
body.browser.mobileweb  #playerFooter.browser * #expandPlayerButton:hover,
body.browser.mobileweb  #playerFooter.browser * #queueRefreshButton:hover,
body.browser.mobileweb  #playerFooter.browser * #songIsFavoriteBtn:hover,
body.browser.mobileweb  #playerFooter.browser * #tagsBtn:hover,
body.browser.mobileweb  #playerFooter.browser * #shareSongBtn:hover {
  background-color: var(--color-white-5);
  overflow: visible;

}
a#songIsFavoriteBtn {
    font-size: var(--text-4xl);
    line-height: 58px;
    height: 58px;
}
body.embedded * a#songIsFavoriteBtn {
    font-size: var(--text-lg);
    line-height: 40px;
    height: 40px;
    border: var(--border-0);
    display: block;
}
a#playerSongMoreButton,
a#playerMoreInformationButton{
    line-height: 1.5em;
    font-weight: 400;
    font-size: var(--text-2xl);
}

#playerPositionSliderWrapper > form > div > div[role=application] > div {
    background: var(--trebble-primary-dark);
}
#playerSongPositionWrapper > div > #playerPositionSliderWrapper {
    flex: 1 1 100%;
    -webkit-flex: 1 1 100%;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-justify-content: center;
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-align-content: center;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex-direction: column;
    padding: 0px 10px;

}
#playerSongPositionWrapper > div > #playerPositionSliderWrapper > form > div > input {
    display: none;
}
#playerSongPositionWrapper > div > #playerPositionSliderWrapper > form > div > div {
    opacity: 1;
    margin-left: 0em;
    margin-right: 0em;
    margin-bottom: 0em;
    border: var(--border-0);
    background-color: var(--color-white-20);
    margin-top: 0.5em;
    height: 0.1em;
}
#playerSongPositionWrapper > div > #playerPositionSliderWrapper > form > div > div > a {
    border: var(--border-0);
    border-color: var(--color-white-90);
    background-color: var(--color-white-90);
    margin: -7px 0 0 -2px;
    border-radius: 0%;
    width: 0.2em;
}
html .ui-page-theme-c .playlistItem,
html  body.browser.mobileweb .ui-page-theme-c .playlistItem:hover,
html .ui-page-theme-a .playlistItem,
html  body.browser.mobileweb .ui-page-theme-a .playlistItem:hover {
    /*height: 180px;
    padding: 6px;*/
    color: var(--text-dark);
    /*display: block;*/
    font-weight: 400;
    /*border: var(--border-1) solid var(--color-gray-100);*/
    position: relative;
}
html .ui-page-theme-c .playlistItem.withLargeAlbumArt,
html .ui-page-theme-a .playlistItem.withLargeAlbumArt{
    padding-left: 6px;
    padding-right: 6px;
    padding-top: 10px;
    background-color: white;
    height: 360px;
    box-sizing: border-box;
    padding-bottom: 10px;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    display: -ms-flexbox;
    display: flex;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-items: center;
    display: -webkit-flex;
    -webkit-justify-content: center;
    margin: 15px;
    margin-left: 15px;
    margin-right: 15px;
    -webkit-flex: 0 0 100%;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    -moz-flex: 0 0 100%;
    -o-flex: 0 0 100%;
    min-width: 240px;
    max-width: 400px;
    width: calc(100% - 30px);
}

html .ui-page-theme-c .playlistItem.withLargeAlbumArt.journeyCard,
html .ui-page-theme-a .playlistItem.withLargeAlbumArt.journeyCard{
    padding: 10px;
    width: calc(100% - 20px);
}


.playlistItem.withLargeAlbumArt.journeyCard .info{
    text-align: start;
}

.playlistItem.withLargeAlbumArt.journeyCard > div > div.info > .title{
    display: -webkit-box;
    -webkit-line-clamp: 2;
    /* autoprefixer: ignore next */
    -webkit-box-orient: vertical;
    white-space: normal;
}

.journeyPage[logged-in-used-subscribed="false"] .create_update_review_sectiom{
    display: none;
}

/* Rating Journey Section CSSS */
.rating_summary_section{
    max-width: 900px;
    margin: auto;
}


/* Rating Summary Card CSSS */
.rating_summary_card{
    max-width: 900px;
    /*border: var(--border-2) solid var(--color-white-10);*/
    /*padding: 20px;*/
    box-sizing: border-box;
    margin: 20px;
}

.rating_summary_card:not([total-ratings="0"]) .no_rating_available,
.rating_summary_card[total-ratings="0"] .rating_summary_card_content{
    display: none;
}

.rating_summary_card_content{
    display: flex;
    flex-direction: row;
}


.rating_summary_card_content .rating_average_section{
    display: flex;
    flex-direction: column;
    padding: 20px;
    box-sizing: border-box;
    justify-content: center;
}

.rating_summary_card_content .rating_average_section .total_ratings{
 flex-direction: row;
 display: flex;
 align-items: center;
}

.rating_summary_card_content .rating_details_section{
    flex: 1 1 100%;
}


.rating_summary_card[total-ratings="0"] .no_rating_available{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: var(--text-base-px);
}

.rating_summary_card .rating_average_section{
    font-size: var(--text-xl-px);
    /* margin-bottom: 20px;*/
}

.rating_summary_card .rating_average_section .total_ratings_label{
    padding-left: 3px;
}

.rating_summary_card .rating_average_section .rating_average{
    font-weight: bold;
    font-size: var(--text-5xl-px);
}

.rating_summary_card .rating_details{
    display: flex;
    flex-direction: row;
    margin: 5px 0px;
    justify-content: center;
    align-items: center;
    
}

.rating_summary_card .rating_average_section .separator{
    display: none;
}


.rating_summary_card .rating_details .rating_visual_bar{
    flex: 1 1 auto;
    height: 8px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    background-color: var(--color-white-20);
    overflow: hidden;
}

.rating_summary_card .rating_details .rating_visual_bar .rating_progress_bar{
    width: 0%;
    background-color: var(--color-gray-100);
    height: 100%;
}

.rating_summary_card .rating_details .rating_stars_list{
    flex: 0 0 auto;
    margin: 0px 6px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.rating_summary_card .rating_details .rating_stars_list .star_icon.ion-ios7-star-outline{
    /*visibility: hidden;*/
}

.rating_summary_card .rating_details .rating_stars_list .star_icon{
   margin: 0px 2px;
   font-size: var(--text-base-px);
}

.rating_summary_card .rating_details .rating_percentage{
    flex: 0 0 45px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    opacity: 0.7;
}



/*  Create  a user review page CSS */
.review_form_wrapper{
    max-width: 900px;
    margin: auto;
}

.review_form_content{

    padding: 20px 0px;
    box-sizing: border-box;

}

.review_form_content .rating_section,
.review_form_content .text_review_section{
    margin: 20px;
}

.review_form_content .rating_section .rating_section_title,
.review_form_content .text_review_section .text_review_section_title{

    font-weight: bold;
    font-size: var(--text-md-px);
    margin: 10px 0px;

}

.review_form_content .rating_section  .rating_stars .star_rating_btn{
    font-size: var(--text-5xl-px);
    margin: 2px;
    cursor: pointer;
}

[creating-new-review=false] .review_form_content #createReviewBtn,
[creating-new-review=true] .review_form_content #updateReviewBtn{
    display: none;
}

.transparent_outline_button{
    background-color: transparent;
    border: var(--border-2) solid var(--text-dark);
    color: var(--text-dark)!important;
    margin-top: 5px;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-width: var(--border-2);
    max-width: 300px;
    margin: auto;
    box-sizing: border-box;
    height: 44px;
    cursor: pointer;
    
}

[data-role=page].lighttext .transparent_outline_button,
html body.darkTheme .transparent_outline_button{
  color: var(--text-light)!important;
  border: var(--border-2) solid var(--text-light);
}

.transparent_outline_button.disabled{
    opacity: 0.5;
    cursor: default;
}


.playlistItem.journeyCard > div > div.info > div > div.lastUpdateTime,
.journeyPage .journey_other_info{
 max-width: 100%;
 width: fit-content;
 padding: 0px 10px;
 box-sizing: border-box;
 flex-direction: row;
 display: flex;
 align-items: center;
 color: var(--color-purple-400);
 font-weight: bold;
 font-size: 90%;
}

.journeyPage .setup_trailer_box_wrapper{
    max-width: 900px;
    margin: auto;
    padding: 20px;
    box-sizing: border-box;
}

.journeyPage .setup_trailer_box_wrapper .selectedSongViewPlaceHolder{
    border-color: transparent;
    background: var(--color-white-10);
}

.playlistDetailsPage.journeyPage.publicPage .bioWrapper .playlistDescription,
.playlistDetailsPage.journeyPage:not(.publicPage) .ratings_section,
.playlistDetailsPage.journeyPage:not(.publicPage) .description_box_wrapper,
.playlistDetailsPage.journeyPage .preview_mode_warning,
.playlistDetailsPage.journeyPage.publicPage .trailer_info_management_box_wrapper{
    display: none;
}

.playlistDetailsPage.journeyPage.publicPage[in-preview-mode="true"] .preview_mode_warning{
    background: var(--color-black-50);
    z-index: 2222222;
    position: absolute;
    top: 0;
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    text-align: center;
    backdrop-filter: blur(20px);
    font-weight: 700;
    border-bottom: var(--border-1) solid var(--color-gray-700);

}
.playlistDetailsPage.journeyPage.publicPage[in-preview-mode=true] [data-role=header]{
    top: 50px;
}

.journey_progress_info_wrapper{
    max-width: 600px;
    padding: 20px;
    margin: auto;
    box-sizing: border-box;
}

.journey_progress_info_wrapper .text_progress_summary_info{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.journey_progress_info_wrapper .text_progress_summary_info .text_progress_summary_in_days {
    text-align: center;
    font-weight: bold;
    text-transform: uppercase;
}

.journey_progress_info_wrapper .text_progress_summary_info .text_progress_summary_in_percent  {
    text-align: center;
    font-weight: bold;
    text-transform: uppercase;
}

.journey_progress_info_wrapper  .total_progress_bar{
    background-color: var(--color-white-20);
    width: 100%;
    height: 5px;
    margin: 20px auto;
}

.journey_progress_info_wrapper  .total_progress_bar .completed_progress_bar{
    background-color: white;
    width: 0%;
    height: 100%;
    max-width: 100%;

}

.journey_progress_info_wrapper  .total_progress_bar .completed_progress_bar.coloured{
    background-color: var(--color-green-400);
}


/* JOurney and Playlist page is non colour theme css */
html body:not(.darkTheme) #myJourneys,
html body:not(.darkTheme) #journeyList,
html body:not(.darkTheme) div.ui-header[data-role=header] ~ .ui-panel-wrapper > .ui-content > [data-role=tabs] * [data-role=tabs] > [data-role=navbar] > ul > li > a,
html body:not(.darkTheme) .ui-bar-t .ui-body-inherit, 
html body:not(.darkTheme).ui-body-t, 
html body:not(.darkTheme).ui-body-t .ui-body-inherit, 
html body:not(.darkTheme) .ui-page-theme-t .ui-body-inherit, 
html body:not(.darkTheme) .ui-panel-page-container-t, 
html body:not(.darkTheme) .ui-group-theme-t .ui-body-inherit,
html body:not(.darkTheme) #mylibrary #collection_repeat_container,
html body:not(.darkTheme) .playlistDetailsPage[do-not-match-coverart-color-with-background="true"],
html body:not(.darkTheme) .playlistDetailsPage[do-not-match-coverart-color-with-background="true"] .artistDetailCoverArtWrapper{
    background: var(--bg-light) !important;
}
html body:not(.darkTheme) .playlistDetailsPage[do-not-match-coverart-color-with-background="true"] .basicArtistInfo{
    display: none;
}
html body:not(.darkTheme) #artistDetailsPage[do-not-match-coverart-color-with-background="true"] > div[data-role=header],
html body:not(.darkTheme) .albumDetailsPage[do-not-match-coverart-color-with-background="true"] > div[data-role=header], 
html body:not(.darkTheme) .artistDetailsPage[do-not-match-coverart-color-with-background="true"] > div[data-role=header], 
html body:not(.darkTheme) .playlistDetailsPage[do-not-match-coverart-color-with-background="true"] > div[data-role=header]{
    background-color: transparent !important;
    background-image: linear-gradient(var(--color-white-100),var(--color-white-40) 70%,var(--color-white-0) 100%)
}

html body:not(.darkTheme) [do-not-match-coverart-color-with-background="true"].journeyPage.editable .journey_summary_detail .jouney_summary_detail_item.goal_info:hover,
html body:not(.darkTheme) [do-not-match-coverart-color-with-background="true"].playlistDetailsPage #pageHeaderTitleWrapper > .editable:hover,
html body:not(.darkTheme) [do-not-match-coverart-color-with-background="true"].playlistDetailsPage .playlistDescription.editable:hover,
html body:not(.darkTheme) [do-not-match-coverart-color-with-background="true"].playlistDetailsPage #pageHeaderTitleWrapper > .editable:active,
html body:not(.darkTheme) [do-not-match-coverart-color-with-background="true"].playlistDetailsPage .playlistDescription.editable:active,
html body:not(.darkTheme) [do-not-match-coverart-color-with-background="true"].userProfileDetailsPage #pageHeaderTitleWrapper > .editable:hover,
html body:not(.darkTheme) [do-not-match-coverart-color-with-background="true"].userProfileDetailsPage #pageHeaderTitleWrapper > .editable:active,
html body:not(.darkTheme) [do-not-match-coverart-color-with-background="true"][data-role=page].lighttext * .capsuleCard * .description,
html body:not(.darkTheme) [do-not-match-coverart-color-with-background="true"][data-role=page].lighttext * .capsuleCard * .itemSubtitle{
    color: var(--color-black-70)!important;
}

html body:not(.darkTheme) .playlistDetailsPage[do-not-match-coverart-color-with-background="true"] * .capsuleCard, [data-role=page].userProfileDetailsPage * .capsuleCard{
    box-shadow: 2px 0 12px 0 var(--gray-500-5);
}

html body:not(.darkTheme) [data-role=page]:not(.lighttext) #helpHeaderButton,
html body:not(.darkTheme) [data-role=page]:not(.lighttext) #homeHeaderButton,
html body:not(.darkTheme) [data-role=page]:not(.lighttext) .ui-bar-t,
html body:not(.darkTheme) [data-role=page]:not(.lighttext) .ui-bar-t .ui-bar-inherit,
html body:not(.darkTheme).ui-body-t [data-role=page]:not(.lighttext) .ui-bar-inherit,
html body:not(.darkTheme) .ui-page-theme-t[data-role=page]:not(.lighttext)  .ui-bar-inherit,
html body:not(.darkTheme) [data-role=page]:not(.lighttext)  .ui-group-theme-t .ui-bar-inherit,
html body:not(.darkTheme) [data-role=page]:not(.lighttext) [data-role=header] * #deleteButton,
html body:not(.darkTheme) [data-role=page]:not(.lighttext) [data-role=header] * #editButton,
html body:not(.darkTheme) [data-role=page]:not(.lighttext) [data-role=header] * #filterButton,
html body:not(.darkTheme) [data-role=page]:not(.lighttext) [data-role=header] * #menuOptionButton,
html body:not(.darkTheme) [data-role=page]:not(.lighttext) [data-role=header] * #nextButton.tag.actionButton,
html body:not(.darkTheme) [data-role=page]:not(.lighttext) [data-role=header] * #saveButtonIcon,
html body:not(.darkTheme) [data-role=page]:not(.lighttext) [data-role=header] * #doneRecordingButtonIcon,
html body:not(.darkTheme) [data-role=page]:not(.lighttext) [data-role=header] * #shareTrebbleBtn,
html body:not(.darkTheme) [data-role=page]:not(.lighttext) [data-role=header] * #showRecordSectionButton.tag.actionButton,
html body:not(.darkTheme) [data-role=page]:not(.lighttext) [data-role=header] * #showUploadSectionButton.tag.actionButton,
html body:not(.darkTheme) .playlistDetailsPage[do-not-match-coverart-color-with-background="true"][data-role=page] * .capsuleCard,
html body:not(.darkTheme) .playlistDetailsPage[do-not-match-coverart-color-with-background="true"][data-role=page] * .capsuleCard > .note > span,
html body:not(.darkTheme) .playlistDetailsPage[do-not-match-coverart-color-with-background="true"][data-role=page] * .capsuleCard > .title > span,
html body:not(.darkTheme) .playlistDetailsPage[do-not-match-coverart-color-with-background="true"][data-role=page] .capsuleCard a,
html body:not(.darkTheme) .userProfileDetailsPage[do-not-match-coverart-color-with-background="true"][data-role=page] * .capsuleCard,
html body:not(.darkTheme) .userProfileDetailsPage[do-not-match-coverart-color-with-background="true"][data-role=page] * .capsuleCard > .note > span,
html body:not(.darkTheme) .userProfileDetailsPage[do-not-match-coverart-color-with-background="true"][data-role=page] * .capsuleCard > .title > span,
html body:not(.darkTheme) .userProfileDetailsPage[do-not-match-coverart-color-with-background="true"][data-role=page] .capsuleCard a{
    color: var(--text-dark) !important;
}


html body:not(.darkTheme) [do-not-match-coverart-color-with-background="true"][data-role=page].lighttext * .capsuleCard, 
html body:not(.darkTheme) [do-not-match-coverart-color-with-background="true"][data-role=page].lighttext * .capsuleCard * .itemTitle,
html body:not(.darkTheme) .playlistDetailsPage[do-not-match-coverart-color-with-background="true"] .listeningInstructionsBox,
html body:not(.darkTheme) .playlistDetailsPage[do-not-match-coverart-color-with-background="true"] #contentWrapper,
html body:not(.darkTheme) .playlistDetailsPage[do-not-match-coverart-color-with-background="true"][data-role=page].lighttext .flexUpperContentWrapper > div.trebbleInfo > .bioWrapper,
html body:not(.darkTheme) .playlistDetailsPage[do-not-match-coverart-color-with-background="true"][data-role=page].lighttext.playlistDetailsPage #contentWrapper,
html body:not(.darkTheme) .playlistDetailsPage[do-not-match-coverart-color-with-background="true"].journeyPage .journey_other_info .journey_stats > span,
html body:not(.darkTheme) .playlistDetailsPage[do-not-match-coverart-color-with-background="true"].journeyPage .journey_other_info .radio_type_tag.journey_tag,
html body:not(.darkTheme) .playlistDetailsPage[do-not-match-coverart-color-with-background="true"] #header-back-button.ui-btn-left,
html body:not(.darkTheme) .playlistDetailsPage[do-not-match-coverart-color-with-background="true"] .flexUpperContentWrapper >div.trebbleInfo > .bioWrapper,
html body:not(.darkTheme) .playlistDetailsPage[do-not-match-coverart-color-with-background="true"].journeyPage .journey_other_info .journey_stats,
html body:not(.darkTheme) .playlistDetailsPage[do-not-match-coverart-color-with-background="true"].journeyPage .journey_other_info .radio_type_tag.journey_tag,
html body:not(.darkTheme) .playlistDetailsPage[do-not-match-coverart-color-with-background="true"] .itemTitle,
html body:not(.darkTheme) .playlistDetailsPage[do-not-match-coverart-color-with-background="true"] .songTitle,
html body:not(.darkTheme) .playlistDetailsPage[do-not-match-coverart-color-with-background="true"] .userListItem:active > .infoWrapper > .userFullname,
html body:not(.darkTheme) .playlistDetailsPage[do-not-match-coverart-color-with-background="true"] .userListItem:active > .infoWrapper >.userUsername,
html body:not(.darkTheme) .playlistDetailsPage[do-not-match-coverart-color-with-background="true"].lighttext .outline_item {
    color: var(--text-dark) !important;
}

html body:not(.darkTheme) .playlistDetailsPage[do-not-match-coverart-color-with-background="true"] .rating_summary_card .rating_details .rating_visual_bar{
    background-color: var(--color-black-20);
}

html body:not(.darkTheme) .playlistDetailsPage[do-not-match-coverart-color-with-background="true"]  .rating_summary_card .rating_details .rating_visual_bar .rating_progress_bar{
 background-color: var(--text-dark);
}

html body:not(.darkTheme) .playlistDetailsPage[do-not-match-coverart-color-with-background="true"].lighttext * .listHeader,
html body:not(.darkTheme) .playlistDetailsPage[do-not-match-coverart-color-with-background="true"].lighttext * .tabheader {
    border-bottom: var(--border-1) solid var(--color-black-5);
    color: var(--color-black-50);
}

#redeemButton{
    height: 45px;
    max-width: 200px;
}

#redeemButton.disabled{
    border-color: var(--color-gray-100);
    background-color: var(--color-gray-100);
    color: var(--color-gray-300) !important;
}

html #redeemButton,
html body:not(.darkTheme) .playlistDetailsPage[do-not-match-coverart-color-with-background="true"] #followBtn,
html body:not(.darkTheme) .playlistDetailsPage[do-not-match-coverart-color-with-background="true"] #buyNowBtn,
html body:not(.darkTheme) .playlistDetailsPage[do-not-match-coverart-color-with-background="true"] #followingBtn, 
html body:not(.darkTheme) .playlistDetailsPage[do-not-match-coverart-color-with-background="true"] #purchasedBtn, 
html body:not(.darkTheme) .playlistDetailsPage[do-not-match-coverart-color-with-background="true"] #playTrailerBtn{
    background-color: var(--trebble-primary);
    border-color: var(--trebble-primary);
    color: var(--text-light)!important;
    cursor: pointer;
}

html body:not(.darkTheme) .playlistDetailsPage[do-not-match-coverart-color-with-background="true"][data-role=page].lighttext .transparent_outline_button, 
html body:not(.darkTheme) .playlistDetailsPage[do-not-match-coverart-color-with-background="true"] .transparent_outline_button,
html body:not(.darkTheme) .playlistDetailsPage[do-not-match-coverart-color-with-background="true"] #playTrailerBtn{
    background-color: transparent;
    border-color: var(--trebble-primary);
    color: var(--trebble-primary) !important;
    cursor: pointer;
}

html body:not(.darkTheme) [data-role=page]:not(.lighttext) .playlistItem.withLargeAlbumArt,
html body:not(.darkTheme) [data-role=page].playlistDetailsPage:not(.lighttext) * .capsuleCard, 
html body:not(.darkTheme) [data-role=page].userProfileDetailsPage:not(.lighttext) * .capsuleCard{
    background-color: white;
}

html body:not(.darkTheme) .playlistDetailsPage[do-not-match-coverart-color-with-background="true"].lighttext  .outline_item .buttonGroup .play_btn #playCapsuleBtn i,
html body:not(.darkTheme) .playlistDetailsPage[do-not-match-coverart-color-with-background="true"].lighttext  .outline_item .buttonGroup  i{
    color:white !important;
}
html body:not(.darkTheme) .playlistDetailsPage[do-not-match-coverart-color-with-background="true"].lighttext .outline_item{
    border-bottom: var(--border-1) solid var(--color-black-10);
}

html body:not(.darkTheme) .playlistDetailsPage[do-not-match-coverart-color-with-background="true"] .journey_summary_detail,
html body:not(.darkTheme) .playlistDetailsPage[do-not-match-coverart-color-with-background="true"].listeningInstructionWrapper .listeningInstructionsBox,
html body:not(.darkTheme) .playlistDetailsPage[do-not-match-coverart-color-with-background="true"].playlistDetailsPage .listeningInstructionsBox{
    background-color: var(--color-black-5);
}

html body:not(.darkTheme) .playlistDetailsPage[do-not-match-coverart-color-with-background="true"] .songSelectorPlaceholder>.content>.noAudioAvaliablePlaceholder{
   border: var(--border-2) solid var(--color-black-20);
   opacity: .5;
   color: var(--color-black-50);
}

html body:not(.darkTheme) .playlistDetailsPage[do-not-match-coverart-color-with-background="true"] .songSelectorPlaceholder>.content>.selectedSongViewPlaceHolder {
    border: var(--border-2) solid var(--color-black-40)
}

html body:not(.darkTheme) [data-role=page]:not(.lighttext) .infoBoxWrapper > .content > .message,
html body:not(.darkTheme) [data-role=page]:not(.lighttext) * .infoBoxWrapper > .content > p > .iconWrapper{
    color: var(--color-black-70);
}



.journeyPage .setup_trailer_box_wrapper .selectedSongViewPlaceHolder .outline_item{
    border-color: transparent;
    padding-right: 20px;
    padding-left: 20px;
    height: 100%;
}

.journeyStatsInfoWrapper{

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.specific_journey_stats_summary{

    display: inline-flex;
    width: 140px;
    align-items: center;
    justify-content: center;
    height: 140px;
    flex-direction: column;
    margin: 20px 10px;
    box-shadow: 2px 0 12px 0 var(--color-black-5);

}

.journey_context_info{

    max-width: 700px;
    display: flex;
    flex-direction: row;
    margin: auto;
    margin-top: 20px;
    width: calc(100% - 40px);
    background-color: var(--bg-overlay-dark);
}




.journey_context_info .cover_art_wrapper{

    height: 60px;
    width: 60px;
    margin: 20px;
    box-sizing: border-box;

}

.journey_context_info .cover_art_wrapper img{


    height: 60px;
    width: 60px;
    object-fit: cover;


}

.journey_stats .listHeader{
    max-width: 700px;
    margin: auto;
    box-sizing: border-box;
    padding: 10px 0px;
    height: 60px;
    width: calc(100% - 40px);
}
.journey_context_info .journey_title{

    box-sizing: border-box;
    padding: 20px;
    font-size: var(--text-xl-px);
    font-weight: 900;

}
.outline_stats_table .outline_stats_header_row{
    background-color: var(--color-black-5);
    font-weight: 700;
    text-transform: uppercase;
    color: var(--color-gray-750);
    font-size: 80%;
}

.outline_stats_table .outline_stats_header_row,
.outline_stats_table .outline_stats{
    display: flex;
    flex-direction: row;
    width: 100%;
    border-bottom: var(--border-1) solid var(--color-black-5);
    

}
.outline_stats_table{

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: calc(100% - 40px);
    max-width: 700px;
    margin: 40px auto;
    font-size: 100%;
    margin: 10px auto;
    border: var(--border-1) solid var(--color-black-5);


}
.generic_journey_stats{
    text-align: center;
    max-width: 600px;
    margin: auto;
}

.outline_stats_table .outline_stats .day_number,.outline_stats_table .outline_stats_header_row .day_number{
    flex: 0 0 40px;
    justify-content: center;
    align-items: center;
    display: flex;
    padding: 24px 10px;
    border-right: var(--border-1) solid var(--color-black-5);
}
.outline_stats_table .outline_stats .number_of_plays,.outline_stats_table .outline_stats_header_row .number_of_plays{
    flex: 0 0 60px;
    justify-content: center;
    align-items: center;
    display: flex;
    padding: 24px 10px;
}
.outline_stats_table .outline_stats .shortcast_title,.outline_stats_table .outline_stats_header_row .shortcast_title{
    flex: 1 1 calc(100% - 120px);
    box-sizing: border-box;
    padding-left: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 24px 10px;
    border-right: var(--border-1) solid var(--color-black-5);
    display: block;
    align-items: center;
}
.outline_stats_table .outline_stats_header_row .shortcast_title{
   white-space: normal;
}
.specific_journey_stats_summary .stats_number{

    font-weight: 900;
    font-size: var(--text-2xl-px);

}

.journeyPage .schedule_date_message_wrapper{
    color: var(--color-purple-400);
    margin: auto;
    display: block;
    justify-content: center;
    align-items: center;
    padding: 20px;
    background-color: white;
    max-width: 250px;
    text-align: center;
    font-weight: bold;

    display: flex;
    flex-direction: row;
    margin: 20px auto;

}

#buyNowBtn:not(.loading) > #loadingImg,
#buyNowBtn.loading > .button_content{
    display: none;
}

#buyNowBtn.loading > #loadingImg{
  border: var(--border-2) solid var(--color-black-20);
  border-top-color: var(--color-black-80);
}

#buyNowBtn.loading > #loadingImg,
#buyNowBtn:not(.loading) > .button_content{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

#buyNowBtn #loadingImg{
    width: 20px;
    height: 20px;
    flex: 0 0 20px;
    margin: 0px;
}
#buyNowBtn .button_content .buy_now_label{
    margin-left: 0.6em;
}

#buyNowBtn .button_content .buy_now_cost{
    margin-left: 0.3em;
}


.journeyPage .schedule_date_message_wrapper .ion-ios7-calendar-outline{
    font-size: var(--text-3xl-px);
    margin-right: 20px;
}

.playlistItem.journeyCard.doNotShowRadioTag > div > div.info > div > div.lastUpdateTime,
.playlistItem.journeyCard.doNotShowRadioTag > div > div.info > div > div.lastUpdateTime .radio_type_tag.journey_tag{
    color: var(--trebble-primary-200);
    color: var(--color-purple-400);
    /*font-size: 120%;*/
}

.playlistItem.journeyCard.doNotShowRadioTag > div > div.info > div > div.lastUpdateTime .radio_type_tag.journey_tag{
    background-color: transparent;
    color: var(--trebble-primary-200);
    color: var(--color-purple-400);
    padding: 5px 5px 5px 0px;
}

.journeyPage .journey_other_info .radio_type_tag.journey_tag{
    padding: 5px 5px 5px 0px;
}

.journeyPage .ownerInfo{

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: var(--text-xs-px);
    margin: 20px auto;
    width: fit-content;
    max-width: 100%;


}

body.mobileweb .journeyPage .ownerInfo:hover,
.journeyPage .ownerInfo:active{
    opacity: 0.7;
}


.journeyPage .ownerInfo .usernameAndFullname{
    margin: 0px 10px;
}
.journeyPage .ownerInfo .usernameAndFullname .username{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 200px;
}

.journeyPage .ownerInfo .usernameAndFullname ,
.journeyPage .ownerInfo .userAvatar{
    cursor: pointer;
} 

.journeyPage .ownerInfo .userAvatar{
    height: 24px;
    width: 24px;
    flex: 0 0 24px;
}
.journeyPage .journey_duration_label .ion-ios7-time-outline.journeyOultlineListIcon,
.journeyPage .journey_rating_label .ion-ios7-star.journeyOultlineListIcon{
    margin: 0px 5px;
}

.journeyPage .journey_stats i.journeyOultlineListIcon{
    margin-left: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}


.journeyPage .journey_stats .journey_duration_label .ion-ios7-time-outline.journeyOultlineListIcon,
.journeyPage .journey_stats .journey_rating_label .ion-ios7-star.journeyOultlineListIcon{
    margin-left: 10px;
}
.journeyPage .journey_stats i.journeyOultlineListIcon.fontello-icon-list{
    font-size: var(--text-md-px);
}

.playlistItem.journeyCard > div > div.info > div > div.lastUpdateTime > span,
.journeyPage .journey_other_info > span {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 5px 5px 0;
}

.playlistItem.journeyCard > div > div.info > div > div.lastUpdateTime > span .journeyOultlineListIcon,
.journeyPage .journey_other_info  > span .journeyOultlineListIcon{

    font-size: var(--text-md-px);
    font-weight: bold;
    align-items: center;
    justify-content: center;
    display: flex;
    width: 16px;
    margin: 0px 4px;

}
.ion-ios7-time-outline.journeyOultlineListIcon{
    -webkit-text-stroke: thin;
}
.playlistItem.withLargeAlbumArt.journeyCard > div > div.info > div > div.lastUpdateTime > span.journey_duration_label{
    display: none;
}

.playlistItem.withLargeAlbumArt.journeyCard > div > div.info > div > div.lastUpdateTime > span.journey_rating_label{
    font-size: var(--text-xs-px);
}

.playlistItem.journeyCard > div > div.info > div > div.lastUpdateTime .radio_type_tag.journey_tag,
.journeyPage .journey_other_info .radio_type_tag.journey_tag,
.journeyPage .journey_other_info .journey_stats > span{
    display: flex;
    padding: 0px 5px 10px 5px;
    background-color: transparent;
    border-radius: 4px;
    font-weight: bold;
    color: white;
    flex-direction: row;
    display: flex;
    width: fit-content;
    flex: 0 0 auto;
    align-items: center;
    justify-content: center;
} 

.infiniteListItem .songArtistLibrary .journey_rating_label .ion-ios7-star.journeyOultlineListIcon{
    margin: 0px 4px;
}


.journeyPage .journey_other_info .radio_type_tag.journey_tag{
 background-color: transparent;
 color: var(--color-purple-400);
}

.journeyPage .journey_other_info .radio_type_tag.journey_tag,
.journeyPage .journey_other_info .journey_stats{
    color: white;
}

.journeyPage .journey_other_info{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
}

.playlistItem.journeyCard > div > div.info > div > div.lastUpdateTime .radio_type_tag.journey_tag .journeyIcon,
.journeyPage .journey_other_info  .radio_type_tag.journey_tag .journeyIcon{
    margin-right: 6px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 0 0 auto;
    font-size: var(--text-base-px);
}

.journeyPage .journey_other_info .journey_stats{
    flex-direction: row;
    display: flex;
}

.playlistItem.journeyCard > div > div.info  .description .goal_icon,
html   .playlistItem.withLargeAlbumArt.smallcardsize.journeyCard  .journey_goal_wrapper .goal_icon{
    margin-right: 10px;
    text-align: start;
}


#collection_repeat_container .playlistItem.withLargeAlbumArt{
    display: inline-table;
}

.sentinel_block_wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
}
.sentinel_block_wrapper .sentinel{
    font-size: var(--text-xl-px);
    opacity: 0.7;
}

@media(max-width: 500px), (max-height: 500px) and (orientation: landscape){
    #collection_repeat_container .playlistItem.withLargeAlbumArt{
        display: inline-flex;
        flex-direction: column;
    }
}

[data-role=page]:not(.lighttext) .playlistItem.withLargeAlbumArt{
    box-shadow: 2px 0 12px 0 var(--shadow-color-light);
    border: transparent;
}
html  body.browser.mobileweb .ui-page-theme-c .playlistItem.withLargeAlbumArt:hover,
html  body.browser.mobileweb .ui-page-theme-a .playlistItem.withLargeAlbumArt:hover,
html body.browser.mobileweb .ui-page-theme-c .playlistItem.withLargeAlbumArt:active,
html body.browser.mobileweb .ui-page-theme-a .playlistItem.withLargeAlbumArt:active{
  box-shadow: 0 5px 20px var(--color-black-20);
}
/*html  body.browser.mobileweb .capsuleCard .buttonGroup{
    display: none;
}
html  body.browser.mobileweb .capsuleCard:hover .buttonGroup,
body.browser.mobileweb .capsuleCard:active .buttonGroup{
    display: flex;
}*/
html  body.browser.mobileweb .capsuleCard:hover {
  box-shadow: 0 5px 10px var(--color-black-20);
}
html  body.browser.mobileweb .ui-page-theme-c .playlistItem.withLargeAlbumArt:hover,
html  body.browser.mobileweb .ui-page-theme-a .playlistItem.withLargeAlbumArt:hover {
  cursor: pointer;
}

#collection_repeat_container.sortable{
    counter-reset: trebbleOrderInFeed;
}

html   .playlistItem.withLargeAlbumArt.smallcardsize {
    height: 120px;
    counter-increment: trebbleOrderInFeed;
}

#mySubscriptions .playlistItem.withLargeAlbumArt.smallcardsize:before
/*,#myJourneys .playlistItem.withLargeAlbumArt.smallcardsize:before*/{
    content: counter(trebbleOrderInFeed)  ;
    position:absolute;
    background-color: white;
    color:var(--trebble-primary);
    top: 8px;
    left:6px;
    font-weight: bold;
    padding:3px 6px;
    z-index: 1;
}


html   .playlistItem.withLargeAlbumArt.smallcardsize  .itemContent.withLargeAlbumArt{
    height:100%;
    display:flex;
    flex-direction:row;
}
html    .playlistItem.withLargeAlbumArt.smallcardsize  .itemContent.withLargeAlbumArt .coverArt{
    width:100px;
    height:100px;
    flex: 0 0 100px;
}
.playlistItem.journeyCard.withLargeAlbumArt.rectangularImage.smallcardsize > div > div.info > .title{
    height: auto;
    max-height: 44px;
}
html    .playlistItem.withLargeAlbumArt.smallcardsize.journeyCard  .itemContent.withLargeAlbumArt .coverArt,
html  body.darkTheme   .playlistItem.withLargeAlbumArt.smallcardsize.journeyCard  .itemContent.withLargeAlbumArt .coverArt{
    width:70px;
    height:70px;
    flex: 0 0 70px;
}



/*html  .playlistItem.withLargeAlbumArt.smallcardsize  .itemContent.withLargeAlbumArt .coverArt .imageWrapper *:not(img),
html  .playlistItem.withLargeAlbumArt.smallcardsize  .itemContent.withLargeAlbumArt .coverArt .imageWrapper:hover *:not(img),
html  .playlistItem.withLargeAlbumArt.smallcardsize  .itemContent.withLargeAlbumArt .coverArt .imageWrapper:active *:not(img),
html  .playlistItem.withLargeAlbumArt.smallcardsize:hover  .itemContent.withLargeAlbumArt .coverArt .imageWrapper *:not(img),
html  .playlistItem.withLargeAlbumArt.smallcardsize:active  .itemContent.withLargeAlbumArt .coverArt .imageWrapper *:not(img){
    display:none;
}
*/


html  .playlistItem.withLargeAlbumArt.smallcardsize  .itemContent.withLargeAlbumArt .coverArt .imageWrapper i{
    font-size: var(--text-4xl-px);
    width: 50px;
    height: 50px;
    margin: auto;
    top: calc(50% - 25px);
    left: calc(50% - 25px);
}



html  .playlistItem.withLargeAlbumArt.smallcardsize  .itemContent.withLargeAlbumArt .coverArt .imageWrapper .buttonBar,
html  .playlistItem.withLargeAlbumArt.smallcardsize  .itemContent.withLargeAlbumArt .coverArt .imageWrapper .previewBtn,
html  .playlistItem.withLargeAlbumArt.smallcardsize  .itemContent.withLargeAlbumArt .coverArt .imageWrapper:hover .buttonBar,
html  .playlistItem.withLargeAlbumArt.smallcardsize  .itemContent.withLargeAlbumArt .coverArt .imageWrapper:active .buttonBar,
html  .playlistItem.withLargeAlbumArt.smallcardsize  .itemContent.withLargeAlbumArt .coverArt .imageWrapper:hover .previewBtn,
html  .playlistItem.withLargeAlbumArt.smallcardsize  .itemContent.withLargeAlbumArt .coverArt .imageWrapper:active .previewBtn,
html  .playlistItem.withLargeAlbumArt.smallcardsize:hover  .itemContent.withLargeAlbumArt .coverArt .imageWrapper .buttonBar,
html  .playlistItem.withLargeAlbumArt.smallcardsize:active  .itemContent.withLargeAlbumArt .coverArt .imageWrapper .buttonBar,
html  .playlistItem.withLargeAlbumArt.smallcardsize:hover  .itemContent.withLargeAlbumArt .coverArt .imageWrapper .previewBtn,
html  body.browser.mobileweb:not(.mobilebrowser) .playlistItem.withLargeAlbumArt.smallcardsize:active  .itemContent.withLargeAlbumArt .coverArt .imageWrapper .previewBtn{
    display:none;
}
html   .playlistItem.withLargeAlbumArt.smallcardsize  .itemContent.withLargeAlbumArt .info{
    /* flex: 0 0 calc(100% - 100px); */
    width: calc(100% - 100px);
    padding: 0px 10px;
    box-sizing: border-box;
}

html .sortable > .collection_content_wrapper > .playlistItem.withLargeAlbumArt.smallcardsize .itemContent.withLargeAlbumArt .info{
    width: calc(100% - 150px);
}
html   .playlistItem.withLargeAlbumArt.smallcardsize  .itemContent.withLargeAlbumArt .info .description,
html   .playlistItem.withLargeAlbumArt.smallcardsize.journeyCard  .journey_goal_wrapper {
    /*white-space:nowrap;
    text-overflow: ellipsis;*/
    margin: 2px 0px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    /* autoprefixer: ignore next */
    -webkit-box-orient: vertical;  
    text-overflow: ellipsis;
    font-size: var(--text-sm-px);
    overflow: hidden;
}
html   .playlistItem.withLargeAlbumArt.smallcardsize.journeyCard  .journey_goal_wrapper {
    padding-bottom: 2px;
    margin-bottom: 8px;
    height: 32px;
    /*text-align: start;*/
}
html .sortable > .playlistItem.withLargeAlbumArt.smallcardsize .itemContent.withLargeAlbumArt .info{
    width: calc(100% - 100px - 50px - 12px);
    max-width: calc(400px - 100px - 50px - 12px);
}

.playlistItem.withLargeAlbumArt.smallcardsize.journeyCard .itemContent.withLargeAlbumArt .buttonBar,
html   .playlistItem.withLargeAlbumArt.smallcardsize.journeyCard .itemContent.withLargeAlbumArt .info .description,
html   .playlistItem.withLargeAlbumArt.smallcardsize  .itemContent.withLargeAlbumArt .info .summary,
html   .playlistItem.withLargeAlbumArt.smallcardsize  .itemContent.withLargeAlbumArt .info .socialInfos,
html   .playlistItem.withLargeAlbumArt.smallcardsize:not(.journeyCard)  .itemContent.withLargeAlbumArt .info .footer{
    display:none;
}

.playlistItem.withLargeAlbumArt.smallcardsize.journeyCard .itemContent.withLargeAlbumArt{
    align-items: flex-start;
    height: fit-content;
    margin-bottom: 10px;
}

*:not(.sortable) .swiper-container-horizontal  .playlistItem.withLargeAlbumArt .dragBtn,
*:not(.sortable) > .collection_content_wrapper > .playlistItem.withLargeAlbumArt .dragBtn,
.outline_item .drag_btn_wrapper{
    display: none ;
}

[data-role=page][journey_status=DRAFT]:not(.publicPage) .sortable > .collection_content_wrapper > .outline_item .drag_btn_wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    flex: 0 0 40px;
}

.sortable > .playlistItem.withLargeAlbumArt .dragBtn{
    color: var(--trebble-primary);
}

.sortable > .collection_content_wrapper > .playlistItem.withLargeAlbumArt .dragBtn,
.sortable > .outline_item .dragBtn{
    width: 50px;
    height: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    font-size: var(--text-xl-px);
}



.playlistItem.withLargeAlbumArt .info .buttonBar{
    display:flex;
    flex-direction:row;
    justify-content: center;
    align-items: center;
}

.journeyCard.playlistItem.withLargeAlbumArt.doNotShowSubscribeButton .info .buttonBar,
.journeyCard.playlistItem.withLargeAlbumArt.doNotShowSubscribeButton  .buttonBar{
    display: none;
}



@media (max-width: 500px), (max-height: 500px) and (orientation: landscape){

    html .playlistItem.withLargeAlbumArt.smallcardsize .itemContent.withLargeAlbumArt .info .title{
        font-size: var(--text-md-px);
    }

    html .playlistItem.withLargeAlbumArt.smallcardsize .itemContent.withLargeAlbumArt .info .description,
    html .playlistItem.withLargeAlbumArt.smallcardsize.journeyCard .journey_goal_wrapper{
        font-size: var(--text-xs-px);
    }

    html   .playlistItem.withLargeAlbumArt.smallcardsize {
        height: 100px;
        margin: 6px;
    }

    html   .playlistItem.withLargeAlbumArt.smallcardsize  .itemContent.withLargeAlbumArt .coverArt{
        width:70px;
        height:70px;
        flex: 0 0 70px;
    }

    html   .playlistItem.withLargeAlbumArt.smallcardsize  .itemContent.withLargeAlbumArt .info{
        /* flex: 0 0 calc(100% - 100px); */
        width: calc(100% - 70px);
        padding: 0px 5px;
        box-sizing: border-box;
    }

    html .sortable > .collection_content_wrapper > .playlistItem.withLargeAlbumArt.smallcardsize .itemContent.withLargeAlbumArt .info{
        width: calc(100% - 120px);
    }

    html .sortable > .playlistItem.withLargeAlbumArt.smallcardsize .itemContent.withLargeAlbumArt .info{
        width: calc(100% - 100px - 50px - 12px);
    }

    .playlistItem.withLargeAlbumArt.smallcardsize .buttonBar div.transparent_button{
        min-width: 70px;
    }

    .playlistItem.withLargeAlbumArt.smallcardsize:before{

        top: 2px;
        left: 4px;
        padding:1px 4px;


    }

    .playlistDetailsPage.publicPage.journeyPage .bioWrapper .trebbleName,
    .playlistDetailsPage.journeyPage .bioWrapper .trebbleName,
    body .playlistDetailsPage.journeyPage  #pageHeaderTitleWrapper{
        font-size: var(--text-2xl);
    }



}



html .ui-page-theme-c .playlistItem.transparent.withLargeAlbumArt,
html  body.browser.mobileweb .ui-page-theme-c .playlistItem.transparent.withLargeAlbumArt:hover,
html .ui-page-theme-a .playlistItem.transparent.withLargeAlbumArt,
html  body.browser.mobileweb .ui-page-theme-a .playlistItem.transparent.withLargeAlbumArt:hover,

html body.darkTheme .ui-page-theme-c .playlistItem.withLargeAlbumArt,
html  body.darkTheme.browser.mobileweb .ui-page-theme-c .playlistItem.withLargeAlbumArt:hover,
html body.darkTheme .ui-page-theme-a .playlistItem.withLargeAlbumArt,
html  body.darkTheme.browser.mobileweb .ui-page-theme-a .playlistItem.withLargeAlbumArt:hover,

html [data-role=page].darkTheme .playlistItem.withLargeAlbumArt,
html  body.browser.mobileweb [data-role=page].darkTheme .playlistItem.withLargeAlbumArt:hover,
html [data-role=page].darkTheme .playlistItem.withLargeAlbumArt,
html  body.browser.mobileweb [data-role=page].darkTheme .playlistItem.withLargeAlbumArt:hover {
    border-color: transparent;
    /*background-color: var(--color-white-10);*/
    background-color: transparent;
    color: white;
}


html body.darkTheme  .journey_context_info,
html .ui-page-theme-c.lighttext .playlistItem.transparent.withLargeAlbumArt,
html  body.browser.mobileweb .ui-page-theme-c.lighttext .playlistItem.transparent.withLargeAlbumArt:hover,
html .ui-page-theme-a.lighttext .playlistItem.transparent.withLargeAlbumArt,
html  body.browser.mobileweb .ui-page-theme-a.lighttext .playlistItem.transparent.withLargeAlbumArt:hover,

html .ui-page-theme-c .playlistItem.transparent.withLargeAlbumArt.smallcardsize,
html  body.browser.mobileweb .ui-page-theme-c .playlistItem.transparent.withLargeAlbumArt.smallcardsize:hover,
html .ui-page-theme-a .playlistItem.transparent.withLargeAlbumArt.smallcardsize,
html  body.browser.mobileweb .ui-page-theme-a .playlistItem.transparent.withLargeAlbumArt.smallcardsize:hover,

html body.darkTheme .ui-page-theme-c .playlistItem.withLargeAlbumArt.smallcardsize,
html  body.darkTheme.browser.mobileweb .ui-page-theme-c .playlistItem.withLargeAlbumArt.smallcardsize:hover,
html body.darkTheme .ui-page-theme-a .playlistItem.withLargeAlbumArt.smallcardsize,
html  body.darkTheme.browser.mobileweb .ui-page-theme-a .playlistItem.withLargeAlbumArt.smallcardsize:hover,

html body.darkTheme .ui-page-theme-c .playlistItem.withLargeAlbumArt.journeyCard,
html  body.darkTheme.browser.mobileweb .ui-page-theme-c .playlistItem.withLargeAlbumArt.journeyCard:hover,
html body.darkTheme .ui-page-theme-a .playlistItem.withLargeAlbumArt.journeyCard,
html  body.darkTheme.browser.mobileweb .ui-page-theme-a .playlistItem.withLargeAlbumArt.journeyCard:hover,

html [data-role=page].darkTheme .playlistItem.withLargeAlbumArt.smallcardsize,
html  body.browser.mobileweb [data-role=page].darkTheme .playlistItem.withLargeAlbumArt.smallcardsize:hover,
html [data-role=page].darkTheme .playlistItem.withLargeAlbumArt.smallcardsize,
html  body.browser.mobileweb [data-role=page].darkTheme .playlistItem.withLargeAlbumArt.smallcardsize:hover {
    background: var(--color-white-5) !important;
}


html body.browser.mobileweb  .ui-page-theme-c .playlistItem:active,
html body.browser.mobileweb  .ui-page-theme-a .playlistItem:active {
    background-color: var(--trebble-primary) !important;
    border-color: var(--trebble-primary);
    color: white !important;
}
html  body.browser.mobileweb .ui-page-theme-c .playlistItem.transparent:active,
html  body.browser.mobileweb .ui-page-theme-a .playlistItem.transparent:active,
html  body.browser.mobileweb .ui-page-theme-c .playlistItem.transparent:hover,
html  body.browser.mobileweb .ui-page-theme-a .playlistItem.transparent:hover,

html  body.darkTheme.browser.mobileweb .ui-page-theme-c .playlistItem:active,
html  body.darkTheme.browser.mobileweb .ui-page-theme-a .playlistItem:active,
html  body.darkTheme.browser.mobileweb .ui-page-theme-c .playlistItem:hover,
html  body.darkTheme.browser.mobileweb .ui-page-theme-a .playlistItem:hover,

html  body.browser.mobileweb [data-role=page].darkTheme.ui-page-theme-c .playlistItem:active,
html  body.browser.mobileweb [data-role=page].darkTheme.ui-page-theme-a .playlistItem:active,
html  body.browser.mobileweb [data-role=page].darkTheme.ui-page-theme-c .playlistItem:hover,
html  body.browser.mobileweb [data-role=page].darkTheme.ui-page-theme-a .playlistItem:hover  {
    background-color: var(--bg-overlay-light) !important;
    border: var(--border-0);
    /*var(--color-white-10) !important;*/
    
    color: white !important;
}
.playlistItem > .itemContent {
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-direction: row;
    height: 180px;
    align-items: center;
}
.playlistItem.withLargeAlbumArt > .itemContent {
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex-direction: column;
    height: 330px;
    width: 100%;
    /* max-width: 500px;*/
}
.playlistItem > .itemContent > .coverArt {
    width: 50px;
    background-color: var(--color-gray-100);
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 4px;
    margin-bottom: 4px;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    -webkit-flex: 0 0 auto;
}
.playlistItem.withLargeAlbumArt > .itemContent > .coverArt {
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
    width: auto;
    height: 140px;
    background-color: var(--color-gray-100);
    /* margin-left: 10px; */
    /* margin-right: 10px; */
    
    margin-top: 4px;
    margin-bottom: 4px;
    margin: 0px;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    -webkit-flex: 0 0 auto;
    display: block;
}
.playlistItem.transparent.withLargeAlbumArt > .itemContent > .coverArt,
body.darkTheme .playlistItem.withLargeAlbumArt > .itemContent > .coverArt,
[data-role=page].darkTheme .playlistItem.withLargeAlbumArt > .itemContent > .coverArt {
    height: 140px;
    background-color: transparent;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-justify-content: center;
    background-color: transparent;
    background-image: none;
}
.playlistItem.withLargeAlbumArt > .itemContent > .coverArt * .previewBtn {
    display: none;
    top: 2px;
    right: 2px;
    position: absolute;
    color: var(--text-dark);
    padding: 10px;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 1px;
    font-size: var(--text-xs);
    /*text-shadow: 1px 1px 1px var(--color-black-40);*/
    border: var(--border-2) solid white;
    background-color: white;
}

body.browser.mobileweb  .playlistItem.withLargeAlbumArt > .itemContent > .coverArt * .previewBtn:hover, .playlistItem.withLargeAlbumArt > .itemContent > .coverArt * .previewBtn:active {
    background-color: transparent;
    border: var(--border-2) solid white;
    color: white;
    text-shadow: none;
}

.playlistItem.withLargeAlbumArt > .itemContent > .coverArt * .previewBtn.notAvailable,
body.browser.mobileweb  .playlistItem.withLargeAlbumArt > .itemContent > .coverArt * .previewBtn.notAvailable:hover,
.playlistItem.withLargeAlbumArt > .itemContent > .coverArt * .previewBtn.notAvailable:active{
    display: none !important;
}

.playlistItem.withLargeAlbumArt > .itemContent > .coverArt * .overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.5;
  top: 0px;
  right: 0px;
  display: none;
  transition: transform 0.4s cubic-bezier(.33,0,.2,1), transform 0.4s cubic-bezier(.33,0,.2,1);
}

body.browser.mobileweb .playlistItem.withLargeAlbumArt:hover > .itemContent > .coverArt * .previewBtn,
body.browser.mobileweb:not(.mobilebrowser) .playlistItem.withLargeAlbumArt:active > .itemContent > .coverArt * .previewBtn,
body.browser.mobileweb .playlistItem.withLargeAlbumArt:not(.notDetailable):hover > .itemContent > .coverArt * .overlay,
body.browser.mobileweb .playlistItem.withLargeAlbumArt:not(.notDetailable):active > .itemContent > .coverArt * .overlay {
  display: block;
}

.playlistItem.withLargeAlbumArt > .itemContent > .coverArt * .buttonBar {
  display: none;
  top: 42px;
  right: 2px;
  position: absolute;
}

.playlistItem.withLargeAlbumArt.journeyCard > .itemContent > .coverArt * .buttonBar{
  top: 2px;
}

body.browser.mobilebrowser .playlistItem.withLargeAlbumArt > .itemContent > .coverArt * .buttonBar {
   top: 0px;
}

body.browser.mobileweb .playlistItem.withLargeAlbumArt > .itemContent > .coverArt * .buttonBar > a:hover,
.playlistItem.withLargeAlbumArt > .itemContent > .coverArt * .buttonBar > a:active{
  color: white;
  background-color: var(--trebble-primary);
  border: var(--border-2) solid var(--trebble-primary);

}

.playlistItem.withLargeAlbumArt > .itemContent > .coverArt * .buttonBar > a{
  margin: 0px 0px 0px 2px;
  width: 30px;
  height: 30px;
  padding: 0px;
  display: flex;
  justify-content: center;
  font-size: var(--text-4xl);
  align-items: center;
  border-radius: 1px;
  color: var(--trebble-primary);
  color: white;
  background-color: transparent;
  border: var(--border-2) solid transparent;
  /* border: var(--border-0); */
}

body.browser.mobileweb .playlistItem.withLargeAlbumArt:hover > .itemContent > .coverArt * .buttonBar,
body.browser:not(.mobilebrowser) .playlistItem.withLargeAlbumArt:active > .itemContent > .coverArt * .buttonBar {
    -ms-flex-align: center;
    -webkit-box-align: center;
    align-items: center;
    -webkit-align-items: center;
    display: -ms-flexbox;
    display: -webkit-box;
    display: flex;
    display: -webkit-flex;

}

.playlistItem.withLargeAlbumArt > .itemContent > .coverArt > .playButton,
.playlistItem.withLargeAlbumArt > .itemContent > .coverArt > div > .playButton,
.playlistItem.withLargeAlbumArt:not(.isCurrentlyPlaying):not(.isCurrentlyLoading) > .itemContent > .coverArt > div > .playButton,
.playlistItem.withLargeAlbumArt.isCurrentlyPlaying > .itemContent > .coverArt > div > .stopButton,
.playlistItem.withLargeAlbumArt.isCurrentlyLoading:not(.isCurrentlyPlaying) > .itemContent > .coverArt > div > .loadingIndicator{
  box-sizing: border-box;
  -ms-flex-align: center;
  -webkit-box-align: center;
  align-items: center;
  -webkit-align-items: center;
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  display: -webkit-flex;
  height: 140px;
  width: 100%;
  position: absolute;
  color: white;
  font-size: var(--text-7xl);
  box-sizing: border-box;
  left: 0px;
  top: 0px;
  height: 70px;
  top: calc(50% - 35px);
  left: calc(50% - 35px);
  border-radius: 50%;
  border: var(--border-3) solid transparent;
  width: 70px;
  /* padding: 70px; */
  -ms-flex-pack: center;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-justify-content: center;
  cursor: pointer;
  border: var(--border-3) solid var(--color-white-90);
  background-color: var(--color-black-20);
}

.playlistItem.withLargeAlbumArt.transparent .playButton.ion-ios7-play,
body.darkTheme .playlistItem.withLargeAlbumArt .playButton.ion-ios7-play,
[data-role=page].darkTheme .playlistItem.withLargeAlbumArt .playButton.ion-ios7-play,
.playlistItem.withLargeAlbumArt > .itemContent > .coverArt > div > .playButton:before{
    padding-left:3px; /** THIS IS DONE TO ACCOUNT FOR THE BORDER WIDTH, NOT REALLY SURE WHY **/
}

.playlistItem.withLargeAlbumArt.isCurrentlyLoading:not(.isCurrentlyPlaying) > .itemContent > .coverArt > div > .loadingIndicator{
    border: var(--border-0);
}

.playlistItem.withLargeAlbumArt > .itemContent > .coverArt > .playButton.ion-ios7-play-outline:before,
.playlistItem.withLargeAlbumArt > .itemContent > .coverArt > div > .playButton.ion-ios7-play-outline:before {
  margin-left: 8px;
  opacity: 0.8;
  font-weight: 600;
}

.playlistItem.withLargeAlbumArt > .itemContent > .coverArt > .playButton.ion-ios7-pause-outline:before,
.playlistItem.withLargeAlbumArt > .itemContent > .coverArt > div > .playButton.ion-ios7-pause-outline:before {
  margin-left: 2px;
  opacity: 0.8;
  font-weight: 600;
}



body.browser.mobileweb .playlistItem.withLargeAlbumArt > .itemContent > .coverArt > .playButton:hover,
body.browser.mobileweb .playlistItem.withLargeAlbumArt > .itemContent > .coverArt > div > .playButton:hover,
.playlistItem.withLargeAlbumArt > .itemContent > .coverArt > .playButton:active,
.playlistItem.withLargeAlbumArt > .itemContent > .coverArt > div > .playButton:active,
body.browser.mobileweb .playlistItem.withLargeAlbumArt > .itemContent > .coverArt > .stopButton:hover,
body.browser.mobileweb .playlistItem.withLargeAlbumArt > .itemContent > .coverArt > div > .stopButton:hover,
.playlistItem.withLargeAlbumArt > .itemContent > .coverArt > .stopButton:active,
.playlistItem.withLargeAlbumArt > .itemContent > .coverArt > div > .stopButton:active,
body.browser.mobileweb .playlistItem.withLargeAlbumArt > .itemContent > .coverArt > .loadingIndicator:hover,
body.browser.mobileweb .playlistItem.withLargeAlbumArt > .itemContent > .coverArt > div > .loadingIndicator:hover,
.playlistItem.withLargeAlbumArt > .itemContent > .coverArt > .loadingIndicator:active,
.playlistItem.withLargeAlbumArt > .itemContent > .coverArt > div > .loadingIndicator:active {
  /* color: var(--trebble-primary); */
  border: var(--border-3) solid var(--trebble-primary);
  background-color: var(--trebble-primary);
  color: white;
  font-weight: 700;
  text-shadow: none;
  /* transform: scale(1.09); */
}
.playlistItem.withLargeAlbumArt > .itemContent > .coverArt > img[src*=http] ~ .playButton,
.playlistItem.withLargeAlbumArt > .itemContent > .coverArt > div > img[src*=http] ~ .playButton {

}
.playlistItem.transparent.withLargeAlbumArt > .itemContent > .coverArt > img[src*=http] ~ .playButton,
.playlistItem.transparent.withLargeAlbumArt > .itemContent > .coverArt > img[src*=http] > div ~ .playButton,
body.darkTheme .playlistItem.withLargeAlbumArt > .itemContent > .coverArt > img[src*=http] ~ .playButton,
body.darkTheme .playlistItem.withLargeAlbumArt > .itemContent > .coverArt > img[src*=http] > div ~ .playButton,
[data-role=page].darkTheme .playlistItem.withLargeAlbumArt > .itemContent > .coverArt > img[src*=http] ~ .playButton,
[data-role=page].darkTheme .playlistItem.withLargeAlbumArt > .itemContent > .coverArt > img[src*=http] > div ~ .playButton {
    /*background: none;*/
}
.playlistItem.withLargeAlbumArt > .itemContent > .coverArt > img,
.playlistItem.withLargeAlbumArt > .itemContent > .coverArt > div > img {
  background-color: var(--color-gray-100);
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  height: 100%;
  transition: transform 0.4s cubic-bezier(.33,0,.2,1);
}
.playlistItem.transparent.withLargeAlbumArt > .itemContent > .coverArt > img,
.playlistItem.transparent.withLargeAlbumArt > .itemContent > .coverArt > div > img,
body.darkTheme .playlistItem.withLargeAlbumArt > .itemContent > .coverArt > img,
body.darkTheme .playlistItem.withLargeAlbumArt > .itemContent > .coverArt > div > img,
[data-role=page].darkTheme .playlistItem.withLargeAlbumArt > .itemContent > .coverArt > img,
[data-role=page].darkTheme .playlistItem.withLargeAlbumArt > .itemContent > .coverArt > div > img {
  background-color: transparent;
}
/*.playlistItem.transparent.withLargeAlbumArt > .itemContent > .coverArt > img,
.playlistItem.transparent.withLargeAlbumArt > .itemContent > .coverArt > div >img {


    box-shadow: 5px 5px 30px var(--text-muted-dark);
    }*/
    .playlistItem.transparent.withLargeAlbumArt > .itemContent > .coverArt ,
    body.darkTheme .playlistItem.withLargeAlbumArt > .itemContent > .coverArt ,
    [data-role=page].darkTheme .playlistItem.withLargeAlbumArt > .itemContent > .coverArt {
      /* width: 154px; */
      box-shadow: 5px 5px 30px var(--text-muted-dark);
  }
  body.browser.mobileweb .playlistItem.withLargeAlbumArt:not(.notDetailable):hover > .itemContent > .coverArt > img,
  body.browser.mobileweb .playlistItem.withLargeAlbumArt:not(.notDetailable):hover > .itemContent > .coverArt > div > img,
  body.browser.mobileweb .playlistItem.withLargeAlbumArt:not(.notDetailable):active > .itemContent > .coverArt > img,
  body.browser.mobileweb .playlistItem.withLargeAlbumArt:not(.notDetailable):active > .itemContent > .coverArt > div > img {
      transform: scale(1.09);
  }
  .playlistItem.withLargeAlbumArt > .itemContent > .coverArt > div.imageWrapper {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    box-shadow: 2px 0px 12px 0 var(--shadow-color-light);
}
.playlistItem > div > div.info {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    -webkit-flex: 1 1 auto;
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex-direction: column;
    width: calc(100% - 40px);
    padding: 20px;
    max-width: calc(400px - 12px - 100px);
}

.sortable > .collection_content_wrapper > .playlistItem:not(.journeyCard) > div > div.info {
    max-width: calc(400px - 12px - 150px);
}

.sortable > .collection_content_wrapper > .playlistItem.journeyCard > div > div.info {
    max-width: calc(400px - 150px);
}

.playlistItem.journeyCard.withLargeAlbumArt.rectangularImage > div > div.info {
    width: calc(100% - 20px);
    padding: 10px;
}

.playlistItem.journeyCard.withLargeAlbumArt.rectangularImage > div > div.info > .description{
    height: 70px;
    text-align: start;
}

.playlistItem.journeyCard.withLargeAlbumArt.rectangularImage > div > div.info > .title{
    height: 60px;
    text-align: start;
}

.playlistItem > div > div.info > .title {
    padding: 0px 10px;
    font-weight: 900;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    -webkit-flex: 0 0 auto;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: var(--text-md);
}
/*
.playlistItem.journeyCard > div > div.info > .title{
    font-size: var(--text-md);
    }*/
    body.browser.mobileweb .playlistItem:not(.notDetailable) > div > div.info > .title:hover,
    .playlistItem:not(.notDetailable) > div > div.info > .title:active {
     color:var(--trebble-primary); 
 }
 .browser.mobilebrowser  .playlistItem > div > div.info > .title{
  /*font-weight: 500;*/
}
.playlistItem.transparent > div > div.info > .title,
body.darkTheme .playlistItem > div > div.info > .title,
[data-role=page].darkTheme .playlistItem > div > div.info > .title {
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
}
.playlistItem > div > div.info > .description {
    font-size: var(--text-sm-px);
    padding: 0px 8px;
    /*color: var(--color-gray-500);*/
    color: var(--text-dark);
    padding-top: 0px;
    padding-bottom: 0px;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    -webkit-flex: 1 1 auto;
    font-weight: 400;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 10px 0px;
    max-height: 70px;
    /*font-weight: 300;*/
}

.playlistItem:not(.smallcardsize) > div > div.info > .description {
    height: 70px;
    overflow: hidden;
    text-overflow: ellipsis;
    /* autoprefixer: ignore next */
    -webkit-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: 4;
}

.playlistItem.transparent > div > div.info > .description,
body.darkTheme .playlistItem > div > div.info > .description,
[data-role=page].darkTheme .playlistItem > div > div.info > .description,
.playlistItem.transparent.withLargeAlbumArt.smallcardsize > div > div.info > .description,
body.darkTheme .playlistItem.withLargeAlbumArt.smallcardsize > div > div.info > .description,
[data-role=page].darkTheme .playlistItem.withLargeAlbumArt.smallcardsize > div > div.info > .description {
    color: white;
    text-align: center;
}
html body.browser.mobileweb .playlistItem:active > div > div.info > .description {
    color: white;
}
.playlistItem > div > div.info > p.summary,
.playlistItem > div > div.info > .socialInfos {
    margin-top: 0px;
    margin-bottom: 0px;
    white-space: nowrap;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    -webkit-flex: 0 0 auto;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: var(--text-sm);
}
.playlistItem > div > div.info > p.summary {
    font-weight: 600;
    color: var(--trebble-primary-200);
}
.playlistItem.transparent > div > div.info > p.summary,
body.darkTheme .playlistItem > div > div.info > p.summary,
[data-role=page].darkTheme .playlistItem > div > div.info > p.summary {
    color: var(--trebble-primary-200);
    text-align: center;
}
.playlistItem > div > div.info > .socialInfos {
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    color: var(--color-gray-500);
    -webkit-flex-direction: row;
}
.playlistItem  .socialInfos{
    display: none !important;
}
.playlistItem.transparent > div > div.info > .socialInfos,
body.darkTheme .playlistItem > div > div.info > .socialInfos,
[data-role=page].darkTheme .playlistItem > div > div.info > .socialInfos {
    color: white;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-justify-content: center;
}
html body.browser.mobileweb .playlistItem:active > div > div.info > .socialInfos {
    color: white;
}
.playlistItem * .socialInfos > i {
    margin-left: 4px;
}
.socialInfos > i {
    margin-right: 6px;
}
.playlistItem > div > div.info > .footer {
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
    padding-top: 4px;
    -ms-flex: 0 0 25px;
    flex: 0 0 25px;
    -webkit-flex: 0 0 25px;
    white-space: nowrap;
    font-size: var(--text-sm);
    padding-bottom: 4px;
    color: var(--color-gray-400);
}
.playlistItem.transparent > div > div.info > .footer,
body.darkTheme .playlistItem > div > div.info > .footer,
[data-role=page].darkTheme .playlistItem > div > div.info > .footer{
    color: var(--color-white-50);
}
html body.browser.mobileweb .playlistItem:active > div > div.info > .footer {
    color: white;
}
.playlistItem > div > div.info > div > div.lastUpdateTime,
.playlistItem > div > div.info > div > div.author {
    -ms-flex: 1 1 50%;
    flex: 1 1 50%;
    -webkit-flex: 1 1 50%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.playlistItem > div > div.info > div > div.lastUpdateTime {
    text-align: right;
}
#playerControlsWrapper > [data-role=controlgroup] >:first-child > a,
#recordControlsWrapper > [data-role=controlgroup] >:first-child > a,
#recordControlsWrapper > [data-role=controlgroup] >:first-child > o {
    padding: 0px;
    border: var(--border-0);
    line-height: 60px;
    vertical-align: middle;
    text-align: center;
    width: 20%;
    height: 60px;
    overflow: visible;
    max-width: 20%;
    margin: auto;
}
ui-#recordControlsWrapper > [data-role=controlgroup] >:first-child > a,
#recordControlsWrapper > [data-role=controlgroup] >:first-child > o {

    /*padding-top: 20px;*/

}
.createCapsulePage #recordControlsWrapper > [data-role=controlgroup] >:first-child{
    max-width: 400px;
    margin: auto;
}

#playerControlsWrapper > [data-role=controlgroup] >:first-child > a > t.pe-7s-chat {
    font-size: var(--text-lg);
}
#playerControlsWrapper > [data-role=controlgroup] >:first-child > a {
    line-height: 40px;
    height: 50px;
    font-weight: 300;
    display: flex;
    display: -ms-flex;
    display: -webkit-flex;
    display: -moz-flex;
    flex: 1 1 100%;
    justify-content: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    -ms-justify-content: center;
    -webkit-align-items: center;
    align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
}


#playerControlsWrapper > [data-role=controlgroup] >:first-child > a > .ion-ios7-mic, 
#playerControlsWrapper > [data-role=controlgroup] >:first-child > a > .pe-7s-micro,
#playerControlsWrapper > [data-role=controlgroup] >:first-child > a > .ion-ios7-paperplane, 
#playerControlsWrapper > [data-role=controlgroup] >:first-child > a > .ion-ios7-paperplane-outline{
  height: 50px;
  width:50px;
  font-weight: 300;
  position:relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  display: -ms-flex;
  display: -webkit-flex;
  display: -moz-flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
}

#playerControlsWrapper > [data-role=controlgroup] >:first-child > a > .ion-ios7-mic > .ion-contrast, 
#playerControlsWrapper > [data-role=controlgroup] >:first-child > a > .pe-7s-micro > .ion-contrast,
#playerControlsWrapper > [data-role=controlgroup] >:first-child > a > .ion-ios7-paperplane > .ion-contrast, 
#playerControlsWrapper > [data-role=controlgroup] >:first-child > a > .ion-ios7-paperplane-outline > .ion-contrast{

  font-size: var(--text-sm-px);
  position: absolute;
  bottom: calc(20px + 5px);
  height: 14px;
  right: calc(25px - 18px);
}



body.embedded #playerControlsWrapper > [data-role=controlgroup] >:first-child > a{
    height: 40px;
}


#playerControlsWrapper.doNotShowComment > [data-role=controlgroup] >:first-child > a,
#recordControlsWrapper.doNotShowComment > [data-role=controlgroup] >:first-child > a,
#recordControlsWrapper.doNotShowComment > [data-role=controlgroup] >:first-child > o {
    width: 24%;
    max-width: 24%;
}
[data-role=page] * #recordControlsWrapper > [data-role=controlgroup] >:first-child > a,
[data-role=page] * #recordControlsWrapper > [data-role=controlgroup] >:first-child > o {
    color: var(--text-dark);
    background-color: var(--bg-light);
}

[data-role=page].ui-page:not(.lighttext) * #recordControlsWrapper > [data-role=controlgroup] >:first-child > a,
[data-role=page].ui-page:not(.lighttext) * #recordControlsWrapper > [data-role=controlgroup] >:first-child > o {
    color: var(--color-purple-400);
}

[data-role=page].ui-page:not(.lighttext) * #recordControlsWrapper > [data-role=controlgroup] >:first-child > o > .pe-7s-micro,
[data-role=page].ui-page:not(.lighttext) * #recordControlsWrapper > [data-role=controlgroup] >:first-child > o > .ion-stop{
    color: var(--color-gray-100);
    font-size: var(--text-4xl);
    background: var(--gradient-primary-alt);
    border: var(--border-0);
}

body.darkTheme [data-role=page].createCapsulePage * #recordControlsWrapper > [data-role=controlgroup] >:first-child > a,
body.darkTheme [data-role=page].createCapsulePage * #recordControlsWrapper > [data-role=controlgroup] >:first-child > o,
[data-role=page].createCapsulePage.lighttext.transparent * #recordControlsWrapper > [data-role=controlgroup] >:first-child > a,
[data-role=page].createCapsulePage.lighttext.transparent * #recordControlsWrapper > [data-role=controlgroup] >:first-child > o{
  color: white;
}

[data-role=page].createCapsulePage * #recordControlsWrapper > [data-role=controlgroup] >:first-child > a,
[data-role=page].createCapsulePage * #recordControlsWrapper > [data-role=controlgroup] >:first-child > o {
  background-color: transparent;
}
#recordControlsWrapper > [data-role=controlgroup] >:first-child > a,
#recordControlsWrapper > [data-role=controlgroup] >:first-child > o {
    width: 33%;
}
#recordControlsWrapper > [data-role=controlgroup] >:first-child > a#showUploadSectionButton,
#recordControlsWrapper > [data-role=controlgroup] >:first-child > o#showUploadSectionButton {
    display: none;
}
#recordControlsWrapper.uploadAvailable > [data-role=controlgroup] >:first-child > a,
#recordControlsWrapper.uploadAvailable > [data-role=controlgroup] >:first-child > o {
    width: 100%;
    display: flex;
    display: -ms-flex;
    display: -webkit-flex;
    display: -moz-flex;
    justify-content: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    -ms-justify-content: center;
    -webkit-align-items: center;
    align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    flex-direction: row;
    -webkit-flex-direction: row;
    -moz-flex-direction: row;
    -ms-flex-direction: row;
}
#recordProgressContainer #playButton:hover{
  background-color:transparent;
  color: white;
  border-color:transparent;
}

#recordProgressContainer #playButton{
    overflow: visible;
}

#recordControlsWrapper > [data-role=controlgroup] >:first-child > o > .ion-ios7-close-empty {
    font-size: var(--text-5xl);
}
.popupContent> [data-role=header]+div.carouselWrapper.capsuleCreationWrapper > .owl-wrapper-outer > .owl-wrapper > .owl-item > .carouselItem > #uploadBlock,
.createCapsulePage div.carouselWrapper.capsuleCreationWrapper > .owl-wrapper-outer > .owl-wrapper > .owl-item > .carouselItem > #uploadBlock {
    height: 100%;
    display: flex;
    display: -ms-flex;
    display: -webkit-flex;
    display: -moz-flex;
    justify-content: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    -ms-justify-content: center;
    -webkit-align-items: center;
    align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    flex-direction: column;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;

}
.popupContent> [data-role=header]+div.carouselWrapper.capsuleCreationWrapper > .owl-wrapper-outer > .owl-wrapper > .owl-item > .carouselItem > #uploadBlock > .uploadZonePlaceHolder ,
.createCapsulePage div.carouselWrapper.capsuleCreationWrapper > .owl-wrapper-outer > .owl-wrapper > .owl-item > .carouselItem > #uploadBlock > .uploadZonePlaceHolder,
body:not(.darkTheme) .createCapsulePage div.carouselWrapper.capsuleCreationWrapper > .owl-wrapper-outer > .owl-wrapper > .owl-item > .carouselItem > #uploadBlock > .uploadZonePlaceHolder{
    min-height: 200px;
    display: flex;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    background-color: var(--color-white-10);
    cursor: pointer;
    width: 100%;
    border: var(--border-2) dashed var(--color-white-10);
}

body:not(.darkTheme) .createCapsulePage.ui-page:not(.lighttext) div.carouselWrapper.capsuleCreationWrapper > .owl-wrapper-outer > .owl-wrapper > .owl-item > .carouselItem > #uploadBlock > .uploadZonePlaceHolder{
    background-color: white;
    border: var(--border-2) dashed var(--color-black-10);
}

.popupContent > [data-role=header]+div.carouselWrapper.capsuleCreationWrapper > .owl-wrapper-outer > .owl-wrapper > .owl-item > .carouselItem > #uploadBlock > .uploadZonePlaceHolder:hover,
.createCapsulePage div.carouselWrapper.capsuleCreationWrapper > .owl-wrapper-outer > .owl-wrapper > .owl-item > .carouselItem > #uploadBlock > .uploadZonePlaceHolder:hover,
.createCapsulePage.ui-page:not(.lighttext) div.carouselWrapper.capsuleCreationWrapper > .owl-wrapper-outer > .owl-wrapper > .owl-item > .carouselItem > #uploadBlock > .uploadZonePlaceHolder:hover,
body.darkTheme .createCapsulePage div.carouselWrapper.capsuleCreationWrapper > .owl-wrapper-outer > .owl-wrapper > .owl-item > .carouselItem > #uploadBlock > .uploadZonePlaceHolder:hover,
.createCapsulePage.ui-page.lighttext div.carouselWrapper.capsuleCreationWrapper > .owl-wrapper-outer > .owl-wrapper > .owl-item > .carouselItem > #uploadBlock > .uploadZonePlaceHolder:hover{
    background-color: var(--bg-warm);
    color: var(--color-black) !important;
    border: var(--border-2) dashed var(--trebble-primary-200);
}




.orrecordsection{
    margin: 20px 0px;
}

.orrecordsection .orlabel{
    font-weight: bold;
    font-size: var(--text-lg-px);
    margin: 20px 0px;
}

.orrecordsection   .actionButton{
    color: var(--color-purple-400);
    background-color: white;
    width: 100%;
    box-sizing: border-box;
    margin: 0px;
}
.orrecordsection   .actionButton:hover,
.orrecordsection   .actionButton:active {
  color: white;
  background-color: transparent;
}
#recordControlsWrapper > [data-role=controlgroup] >:first-child > o > .fontello-icon-play-1,
#recordControlsWrapper > [data-role=controlgroup] >:first-child > o > .fontello-icon-pause-1,
#recordControlsWrapper > [data-role=controlgroup] >:first-child > o >.ion-ios7-play,
#recordControlsWrapper > [data-role=controlgroup] >:first-child > o > .ion-ios7-pause {
    font-size: var(--text-4xl);
}
#recordControlsWrapper > [data-role=controlgroup] >:first-child > o > .ion-ios7-trash  {
    font-size: var(--text-2xl);
}
#recordControlsWrapper > [data-role=controlgroup] >:first-child > o >.ion-ios7-circle-filled,
#recordControlsWrapper > [data-role=controlgroup] >:first-child > o > .pe-7s-micro,
[data-role=page] * #recordControlsWrapper > [data-role=controlgroup] >:first-child > o > .ion-ios7-circle-filled,
[data-role=page] * #recordControlsWrapper > [data-role=controlgroup] >:first-child > o > .pe-7s-micro,
#recordControlsWrapper > [data-role=controlgroup] >:first-child > o >.ion-stop,
[data-role=page] * #recordControlsWrapper > [data-role=controlgroup] >:first-child > o > .ion-stop  {
 color: white;
 font-size: var(--text-6xl);
 border: var(--border-2) solid white;
 border-radius: 50%;
 padding: 4px;
 width: 50px;
 display: flex;
 display: -ms-flex;
 display: -webkit-flex;
 display: -moz-flex;
 justify-content: center;
 -webkit-justify-content: center;
 -moz-justify-content: center;
 -ms-justify-content: center;
 -webkit-align-items: center;
 align-items: center;
 -moz-align-items: center;
 -ms-align-items: center;
 flex-direction: row;
 -webkit-flex-direction: row;
 -moz-flex-direction: row;
 -ms-flex-direction: row;
 height: 50px;
 flex: 0 0 50px;
 margin: 0px;
 background-color: white;
 color: var(--color-purple-400);
}
#recordControlsWrapper > [data-role=controlgroup] >:first-child > o > .ion-ios7-circle-filled:active, 
#recordControlsWrapper > [data-role=controlgroup] >:first-child > o > .ion-stop:active{

    color: white;
}
#recordControlsWrapper > [data-role=controlgroup] >:first-child > o.hidden{
  display: none;
}
#playerControlsWrapper > [data-role=controlgroup] >:first-child > a > t[class*="ion"] {
    line-height: 50px;
    font-size: var(--text-3xl);
    /*height: 100%;*/
}
body.embedded * #playerControlsWrapper > [data-role=controlgroup] >:first-child > a  t.fontello-icon-play-1,
body.embedded * #playerControlsWrapper > [data-role=controlgroup] >:first-child > a  t.fontello-icon-pause-1,
body.embedded * #playerControlsWrapper > [data-role=controlgroup] >:first-child > a#playButton  t.ion-ios7-play,
body.embedded * #playerControlsWrapper > [data-role=controlgroup] >:first-child > a  t.ion-loading-c,
body.embedded * #playerControlsWrapper > [data-role=controlgroup] >:first-child > a#playButton  t.ion-ios7-pause,
body.embedded * #playerControlsWrapper > [data-role=controlgroup] >:first-child > a#playButton  t.pe-7s-play,
body.embedded * #playerControlsWrapper > [data-role=controlgroup] >:first-child > a#playButton  t.ion-ios7-pause-outline {
    line-height: 40px;
    font-size: var(--text-4xl);
    /*height: 100%;*/
}
#playerControlsWrapper > [data-role=controlgroup] >:first-child > a t.fontello-icon-play-1,
#playerControlsWrapper > [data-role=controlgroup] >:first-child > a t.fontello-icon-pause-1,
#playerControlsWrapper > [data-role=controlgroup] >:first-child > a#playButton t.ion-ios7-play,
#playerControlsWrapper > [data-role=controlgroup] >:first-child > a#playButton t.ion-ios7-pause,
#playerControlsWrapper > [data-role=controlgroup] >:first-child > a > t.ion-loading-c,
#playerControlsWrapper > [data-role=controlgroup] >:first-child > a#playButton t.pe-7s-play,
#playerControlsWrapper > [data-role=controlgroup] >:first-child > a#playButton t.ion-ios7-pause-outline {
    line-height: 47px;
    font-size: var(--text-5xl);
    /*height: 100%;*/
}
#playerFooter.browser * #playerControlsWrapper > [data-role=controlgroup] >:first-child > a t.fontello-icon-play-1,
#playerFooter.browser * #playerControlsWrapper > [data-role=controlgroup] >:first-child > a t.fontello-icon-pause-1,
#playerFooter.browser * #playerControlsWrapper > [data-role=controlgroup] >:first-child > a#playButton t.ion-ios7-play,
#playerFooter.browser * #playerControlsWrapper > [data-role=controlgroup] >:first-child > a#playButton t.ion-ios7-pause,
#playerFooter.browser * #playerControlsWrapper > [data-role=controlgroup] > a t.fontello-icon-play-1,
#playerFooter.browser * #playerControlsWrapper > [data-role=controlgroup] > a t.fontello-icon-pause-1,
#playerFooter.browser * #playerControlsWrapper > [data-role=controlgroup] > a t.ion-loading-c,
#playerFooter.browser * #playerControlsWrapper > [data-role=controlgroup] > a#playButton t.ion-ios7-play,
#playerFooter.browser * #playerControlsWrapper > [data-role=controlgroup] > a#playButton t.ion-ios7-pause,
#playerFooter.browser * #playerControlsWrapper > [data-role=controlgroup] >:first-child > a#playButton t.pe-7s-play,
#playerFooter.browser * #playerControlsWrapper > [data-role=controlgroup] >:first-child > a#playButton t.ion-ios7-pause-outline,
#playerFooter.browser * #playerControlsWrapper > [data-role=controlgroup] > a#playButton t.pe-7s-play,
#playerFooter.browser * #playerControlsWrapper > [data-role=controlgroup] > a#playButton t.ion-ios7-pause-outline {
    line-height: 47px;
    font-size: var(--text-2xl);
    /*height: 100%;*/
}
#playerControlsWrapper > [data-role=controlgroup] >:first-child > a > t.fontello-icon-to-end-alt {
    line-height: 50px;
    font-size: var(--text-xl);
}
#playerControlsWrapper > [data-role=controlgroup] >:first-child > a > t.fontello-icon-to-start-alt {
    line-height: 50px;
    font-size: var(--text-xl);
}
body.embedded * #playerControlsWrapper > [data-role=controlgroup] >:first-child > a > t.fontello-icon-arrows-cw,
body.embedded * #playerControlsWrapper > [data-role=controlgroup] >:first-child > a > t.pe-7s-repeat {
    line-height: 40px;
    font-size: var(--text-lg);
    color: var(--color-white-40);
    /*height: 100%;*/
}
#playerControlsWrapper > [data-role=controlgroup] >:first-child > a > t.fontello-icon-arrows-cw,
#playerControlsWrapper > [data-role=controlgroup] >:first-child > a > t.pe-7s-repeat {
    line-height: 48px;
    font-size: var(--text-lg);
    color: var(--color-white-40);
    /*height: 100%;*/
}
#playerControlsWrapper > [data-role=controlgroup] >:first-child > a > t.fontello-icon-arrows-cw:active,
#playerControlsWrapper > [data-role=controlgroup] >:first-child > a > t.pe-7s-repeat:active {
    /* line-height: 2.35em; */
    
    height: 100%;
    opacity: 1;
}
#playerControlsWrapper > [data-role=controlgroup] >:first-child > a > t.fontello-icon-shuffle,
#playerControlsWrapper > [data-role=controlgroup] >:first-child > a > t.pe-7s-shuffle {
    line-height: 48px;
    font-size: var(--text-lg);
    /*height: 100%;*/
    color: var(--color-white-40);
}
#playerControlsWrapper > [data-role=controlgroup] >:first-child > a > t.fontello-icon-shuffle:active,
#playerControlsWrapper > [data-role=controlgroup] >:first-child > a > t.pe-7s-shuffle:active {
    opacity: 1;
    /*height: 100%;*/
}
a.largeMenuButton.ui-link,
a.largeMenuButton.ui-link:hover {
    height: 30px;
    margin: 0px;
    margin-right: 10px;
    margin-left: 10px;
    font-weight: 300;
    background-color: var(--trebble-primary);
    border-color: var(--trebble-primary);
    border: var(--border-2) solid var(--trebble-primary);
    color: white;
    line-height: 30px;
    font-size: var(--text-sm-px);
    text-align: center;
    display: block;
    font-weight: bold;
    display: -ms-flexbox;
    display: -webkit-box;
    display: flex;
    display: -webkit-flex;
    -ms-flex-direction: row;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-direction: row;
    -webkit-flex-direction: row;
    -ms-flex-pack: center;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-align: center;
    -webkit-box-align: center;
    align-items: center;
    -webkit-align-items: center;
    display: -webkit-flex;
    -webkit-box-flex: 1;
    flex: 1 1 100%;
    -webkit-flex: 1 1 100%;
    -ms-flex: 1 1 100%;
    cursor: pointer;
}
#LoginWithFacebookBtn,
#SignUpWithFacebookBtn {
    background-color: var(--color-blue-600);
    border-color: var(--color-blue-600);
}
body.browser.mobileweb  #LoginWithFacebookBtn:hover,
#LoginWithFacebookBtn:active,
body.browser.mobileweb  #SignUpWithFacebookBtn:hover, 
#SignUpWithFacebookBtn:active{
    background-color: var(--trebble-primary);
    /*color:var(--text-dark);
    border-color: var(--text-dark);*/
    color:white;
    border-color: var(--trebble-primary);
}
#LoginWithGoogleBtn,
#SignUpWithGoogleBtn{
    color: var(--text-dark);
    background-color: white;
    border-color: var(--text-dark);

}



#LoginWithAppleBtn,
#SignUpWithAppleBtn{
    color: var(--text-light);
    background-color: var(--color-black);
    border-color: var(--color-black);

}

[data-role=popup] #LoginWithGoogleBtn,
[data-role=popup] #SignUpWithGoogleBtn{
    border-color: transparent;
}


[data-role=popup] #LoginWithAppleBtn,
[data-role=popup] #SignUpWithAppleBtn{
    border-color: transparent;
}


body.browser.mobileweb  #LoginWithGoogleBtn:hover,
#LoginWithGoogleBtn:active,
body.browser.mobileweb  #SignUpWithGoogleBtn:hover, 
#SignUpWithGoogleBtn:active{
    color: white;
    background-color: var(--trebble-primary);
    border-color: var(--trebble-primary);
}
#LoginWithGoogleBtn .googleIcon,
#SignUpWithGoogleBtn .googleIcon{
    width: 20px;
    height: 20px;
    background: url("./images/512px-Google__G__Logo.svg.png") no-repeat;
    background-size: contain;
    padding: 0px 6px;
}



body.browser.mobileweb  #LoginWithAppleBtn:hover,
#LoginWithAppleBtn:active,
body.browser.mobileweb  #SignUpWithAppleBtn:hover, 
#SignUpWithAppleBtn:active{
    color: white;
    background-color: var(--trebble-primary);
    border-color: var(--trebble-primary);
}
#LoginWithAppleBtn .appleIcon,
#SignUpWithAppleBtn .appleIcon{
    width: 20px;
    height: 20px;
    background: url("./images/apple-512.png") no-repeat;
    background-size: contain;
    padding: 0px 6px;
}


#onboardingSignupPage .chooseProfileTypeWrapper a.actionButton,
#onboardingSignupPage .welcomeMessageWrapper a.actionButton,
#trebbleSetup .chooseProfileTypeWrapper a.actionButton,
#trebbleSetup .welcomeMessageWrapper a.actionButton,
#journeyCreation .chooseProfileTypeWrapper a.actionButton,
#journeyCreation .welcomeMessageWrapper a.actionButton,
#loginForm > a.actionButton,
#SignUpForm > a.actionButton {
    margin-top: 0.5em;
    font-size: var(--text-base);
    margin-bottom: 0.5em;
    flex: 0 0 54px;
    -webkit-flex: 0 0 54px;
    -ms-flex: 0 0 54px;
    box-sizing: border-box;
    width: calc(100% - 40px);
}
.google_button_container,
#loginForm .formField,
#SignUpForm .formField{
    width: calc(100% - 40px) !important;
}

#SignUpForm .google_button_container{
    padding: 0px 10px;
}

#SignUpForm .google_button_container > div{
    width: 100%;
}

.google_button_container [role=button]{
    border-radius: 0px;
    height: 50px;
    border-width: var(--border-2);
}

.google_button_container [role=button] div span{
    font-family: Montserrat,sans-serif;
    font-weight: bold;
    /*color: var(--color-black);*/
    font-size: var(--text-base-px);
}

#loginForm > a.actionButton.disabled,
#SignUpForm > a.actionButton.disabled,
.feedbackFormWrapper a.actionButton.disabled,
body.browser.mobileweb  .feedbackFormWrapper a.actionButton:hover.disabled,
.feedbackFormWrapper a.actionButton:active.disabled{
    cursor: default;
    color: var(--color-gray-550);
    background-color: var(--color-white);
    border: var(--border-2) solid var(--color-gray-100);
}
#loginSigninSpacerText {
    color: var(--text-dark);
    display: -ms-flexbox;
    display: flex;
    font-size: var(--text-sm);
    padding: 8px;
    display: -webkit-flex;
    box-sizing: border-box;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-justify-content: center;
}

#loginSigninSpacerText.forgotPasswordLink,
#showExternalSignupOptionsBtn{
  cursor:pointer;
  font-weight:bold;
  color: var(--trebble-primary);
  justify-content: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  width: fit-content;
  margin: auto;
  text-decoration: underline;
}
.largeMenuButton.topsticky,
.actionButton.topsticky {
    top: 60px;
    padding: 8px;
    letter-spacing: 0.13em;
}
a.largeMenuButton.ui-link:active {
  background-color: transparent;
  /* border-color: var(--trebble-primary-dark); */
  color: var(--trebble-primary);
  border: var(--border-2) solid var(--trebble-primary);
}
body.browser.mobileweb a.largeMenuButton.ui-link:hover {
    cursor: pointer;
    color: var(--trebble-primary);
    background-color: transparent;
    border: var(--border-2) solid var(--trebble-primary);
    transform: translateZ(0);
    transform: translateZ(0);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -moz-osx-font-smoothing: grayscale;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-property: all;
    transition-property: all;
}
#playerControlsWrapper > [data-role=controlgroup] >:first-child,
#recordControlsWrapper > [data-role=controlgroup] >:first-child {
    width: 100%;
}
#playerControlsWrapper > [data-role=controlgroup] >:first-child,
#recordControlsWrapper > [data-role=controlgroup] >:first-child{
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    height: 100%;
}

#playerControlsWrapper{
    height: 80px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
#playerSocialInfoWrapper > div#playerSongMoreSocialWrapper * .owl-theme .owl-controls {
    margin-top: 0px;
}
.basicArtistInfo * .owl-theme .owl-controls {
    margin-top: 10px;
}
.userProfileDetailsPage * .basicArtistInfo * .owl-theme .owl-controls {
    margin-top: 0px;
}
.userProfileDetailsPage #followedFollowedInfoBox,
.userProfileDetailsPage #followedFollowedInfoBox ~ .separator,
.userProfileDetailsPage #followingsFollowedInfoBox,
.userProfileDetailsPage #followingsFollowedInfoBox ~ .separator,
.userProfileDetailsPage #trebblesFollowedInfoBox ~ .separator,
.userProfileDetailsPage #songsInLibraryInfoBox,
.userProfileDetailsPage #songsInLibraryInfoBox ~ .separator,
/*.userProfileDetailsPage .userInfo #followingBtn,
.userProfileDetailsPage .userInfo #followBtn,
.userProfileDetailsPage .userInfo #buyNowBtn*/

.userProfileDetailsPage[profile_type=journey_creator]  #songsAndTrebbleSummaryWrapper,
.userProfileDetailsPage[profile_type=shortcast_creator]  #songsAndTrebbleSummaryWrapper,
body.browser > .userProfileDetailsPage[is_creator=false] * #userOverviewInfoWrapper > #capsuleHistoryWrapper,
body.browser > .userProfileDetailsPage #sendCmailToUserBtn,
body.browser > .userProfileDetailsPage #shareUserCmailUrlBtn,
body.browser > .userProfileDetailsPage[is_creator=false] * #userOverviewInfoWrapper > #songsAndTrebbleSummaryWrapper, 
body.browser > .userProfileDetailsPage[is_creator=false] * #userOverviewInfoWrapper > #songsAndTrebbleSummaryWrapper > .recently_added_songs_list_wrapper,
body.browser > .userProfileDetailsPage[is_creator=false] * #userOverviewInfoWrapper > #songsAndTrebbleSummaryWrapper > .top_song_list_wrapper{
    display:none !important;
}

.userProfileDetailsPage .listHeader{
    justify-content: center;
}

#playerSocialInfoWrapper > div#playerSongMoreSocialWrapper * .owl-theme .owl-controls .owl-page span {
    width: 4px;
    height: 4px;
    margin: 2px 3px;
    border-radius: 8px;
    border: var(--border-2) solid white;
    opacity: 1;
    background-color: transparent;
}
.basicArtistInfo * .owl-theme .owl-controls .owl-page span {
    width: 6px;
    height: 6px;
    margin: 2px 3px;
    border-radius: 6px;
    background-color: white;
    border: var(--border-2) solid white;
    opacity: 1;
    background-color: transparent;
}
#recordProgressContainer {
    position: relative;
}
#recordProgressContainer > svg {
    width: 100%;
    display: block;
    position: absolute;
}
.progressbar-text {
    font-size: var(--text-5xl);
    font-weight: 300;
    padding: 0px;
    margin: 0px;
    transform: translate(-50%, -50%);
    color: var(--color-orange-300);
    text-align: center;
    position: absolute;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    display: flex;
}

.playlistShortcutButtonsWrapper {
    -ms-flex: 0 0 45px;
    flex: 0 0 45px;
    -webkit-flex: 0 0 45px;
    width: 100%;
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-flex-direction: row;
    -webkit-justify-content: space-between;
    z-index: 10;
}
.playlistShortcutButtonsWrapper > #showStatistics,
.playlistShortcutButtonsWrapper > #shareTrebbleBtn {
    /*border-radius: 50%;*/
    color: white;
    font-size: var(--text-3xl);
    line-height: 30px;
    padding: 2px;
    padding-left: 20px;
    padding-right: 20px;
    width: 32px;
    padding: 0px;
    font-weight: 300;
    height: 32px;
    border-color: transparent;
}
.darktext .playlistShortcutButtonsWrapper > #showStatistics,
.darktext  .playlistShortcutButtonsWrapper > #shareTrebbleBtn {

  color: var(--text-dark);

}

.darktext .playlistShortcutButtonsWrapper > #showStatistics:active,
.darktext  .playlistShortcutButtonsWrapper > #shareTrebbleBtn:active,
body.browser.mobileweb .darktext .playlistShortcutButtonsWrapper > #showStatistics:hover,
body.browser.mobileweb .darktext  .playlistShortcutButtonsWrapper > #shareTrebbleBtn:hover {
  color: var(--trebble-primary) !important;
}

.playlistShortcutButtonsWrapper.itemsAlignedRight {
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-justify-content: flex-end;
}
.transparent_button,
.button {
    border: var(--border-2) solid white;
    border-radius: 1px;
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 2px;
    padding-bottom: 2px;
    height: 32px;
    margin: 0px;
    margin-left: 10px;
    font-weight: bold;
    color: white;
    line-height: 32px;
    font-size: var(--text-sm-px);
    text-align: center;
    display: block;
    cursor: pointer;
}

body.browser > [data-role=page] * .transparent_button #loadingImg,
body.browser > [data-role=page] * .button #loadingImg{
    width: 20px;
    height: 20px;
    margin: 0px;
}


.darktext .transparent_button{
  color: var(--text-dark);
  border-color: var(--text-dark);
}
body.browser.mobileweb .darktext .transparent_button:hover,
.darktext .transparent_button:active{
  color: var(--trebble-primary) !important;
  border-color: white !important;
  background-color: white;
}

.transparent_button.toggled {
    color: var(--trebble-primary); 
    
    font-weight: bold;
    /* background-color: var(--trebble-primary); */
    
    border: var(--border-2) solid var(--trebble-primary);
}
.userProfileDetailsPage .userInfo * #followingBtn,
.userProfileDetailsPage .userInfo * #purchasedBtn,
.userProfileDetailsPage .userInfo * #followBtn,
.trebbleSubscribeSuggestionContent #followBtn,
.trebbleSubscribeSuggestionContent #buyNowBtn {
    min-width: 100px;
    margin: 0px;
    -ms-flex: 0 0 30px;
    flex: 0 0 30px;
    -webkit-flex: 0 0 30px;
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
    -ms-flex-align: center;
    align-items: center;
    -webkit-align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-justify-content: center;
    box-sizing: border-box;
    margin: 10px;
    color: white;
    border-color: var(--trebble-primary);
    background-color:var(--trebble-primary);
}

body.browser.mobileweb  .userProfileDetailsPage * #followingBtn:hover,
body.browser.mobileweb  .userProfileDetailsPage * #purchasedBtn:hover,
body.browser.mobileweb  .userProfileDetailsPage * #followBtn:hover ,
body.browser.mobileweb  .userProfileDetailsPage * #buyNowBtn:hover ,
.userProfileDetailsPage * #followingBtn:active,
.userProfileDetailsPage * #purchasedBtn:active,
.userProfileDetailsPage * #followBtn:active ,
.userProfileDetailsPage * #buyNowBtn:active ,
body.browser.mobileweb .trebbleSubscribeSuggestionContent #followBtn:hover,
body.browser.mobileweb .trebbleSubscribeSuggestionContent #buyNowBtn:hover,
.trebbleSubscribeSuggestionContent #followBtn:active,
.trebbleSubscribeSuggestionContent #buyNowBtn:active{
    color:var(--trebble-primary) !important;
    border-color: var(--trebble-primary) ;
    background-color: transparent;
}

body.browser.mobileweb #followingBtn:hover:before,
body.browser.mobileweb #purchasedBtn:hover:before{
  /*content: '\f097';*/
}

.playlistItem.withLargeAlbumArt #followingBtn:hover:after,
.playlistItem.withLargeAlbumArt #purchasedBtn:hover:after{
    padding: 0 40px;
}

body.browser.mobileweb  #followingBtn:hover > span{
  display: none;
}

body.browser.mobileweb .userProfileDetailsPage .userInfo #followingBtn:hover:after{
   content: "Unfollow";
}
body.browser.mobileweb  #followingBtn:hover:after{
   content: "Unsubscribe";
}

body.browser.mobileweb[language^=fr] .userProfileDetailsPage #followingBtn:hover:after{
    content: "Cesser de suivre";
}

body.browser.mobileweb[language^=fr] #followingBtn:hover:after{
    content: "Se désabonner";
}

body.browser.mobileweb .journeyCard #followingBtn:hover:after,
body.browser.mobileweb .journeyPage #followingBtn:hover:after{
   content: "Unsubscribe";
}

body.browser.mobileweb[language^=fr] .journeyCard #followingBtn:hover:after,
body.browser.mobileweb[language^=fr] .journeyPage #followingBtn:hover:after{
    content: "Se désabonner";
}

#transcribe_and_edit_audio_btn.hightlightBtn,
#transcribe_btn.hightlightBtn{
    max-width: 400px;
    padding: 0px 30px;
    color: var(--color-purple-400) !important;
    display:flex;
    justify-content:center;
    align-items:center;
    font-weight: bold;
}

.ui-page[data-role=page]:not(.lighttext) #transcribe_and_edit_audio_btn.hightlightBtn,
.ui-page[data-role=page]:not(.lighttext) #transcribe_btn.hightlightBtn{
    background: var(--gradient-primary-alt);
    color: white !important;
    border: var(--border-0);
    font-weight: 400;
}
.ui-page[data-role=page]:not(.lighttext) #transcribe_and_edit_audio_btn.hightlightBtn .label,
.ui-page[data-role=page]:not(.lighttext) #transcribe_btn.hightlightBtn .label{
    font-weight: bold;
}

#continue_with_no_edit_btn.hightlightBtn{
    background-color: transparent;
    border: var(--border-2) solid white;
    max-width: fit-content;
}

#continue_with_no_edit_btn.hightlightBtn .label,
body.darkTheme .ui-page:not(.lighttext) #continue_with_no_edit_btn.hightlightBtn .label{
    color: white;
}

.ui-page:not(.lighttext) #continue_with_no_edit_btn.hightlightBtn .label{
    color:black
}

.hightlightBtn,
.playlistDetailsPage #playTrailerBtn,
.playlistDetailsPage #followBtn,
#redeemButton,
.playlistDetailsPage #buyNowBtn,
.playlistDetailsPage #followingBtn,
.playlistDetailsPage #purchasedBtn,
.trebbleSubscribeSuggestionContent #followBtn,
.trebbleSubscribeSuggestionContent #buyNowBtn{
  /*font-size: var(--text-xs);*/
  font-family: "Montserrat",sans-serif;
  display:flex;
  justify-content:center;
  align-items:center;
  background-color: transparent;
  font-weight: bold;
  text-transform: uppercase;
  box-sizing: border-box;
  min-width: 180px;
}

.hightlightBtn span.label{
    font-family: Montserrat,sans-serif;
    text-transform: uppercase;
}

.journey_summary_detail,
.playlistDetailsPage  .listeningInstructionsBox,
.listeningInstructionWrapper  .listeningInstructionsBox{
    z-index: 0;
    text-align: center;
    font-size: 90%;
    max-width:500px;
    background-color: var(--color-white-10);
    padding: 20px;
}

.journey_summary_detail{
   font-size: 100%;
   margin: 20px auto;
   max-width: 400px;
}

.listeningInstructionWrapper  .listeningInstructionsBox{
    margin:0px;
    background-color: transparent;
    padding: 20px;
}
.playlistDetailsPage  .listeningInstructionsBox{
    margin: 40px 20px 10px;
    color: var(--text-light);
    box-sizing: border-box;
}

.operationProgressPopupContent  .listeningInstructionsBox{
    background-color: var(--color-white-10);
    padding: 20px;
    
}



.playlistDetailsPage  .listeningInstructionsBox .utterance{
    font-weight: bold;
}
#redeemButton,
.playlistDetailsPage #followBtn,
.playlistDetailsPage #buyNowBtn{
   color: white;
   border-color: white;
}

#redeemButton
.playlistDetailsPage #followBtn,
.playlistDetailsPage #buyNowBtn{
  color: var(--trebble-primary);
  border-color: var(--trebble-primary);
}

/*body.browser.mobileweb .playlistDetailsPage #followBtn:hover,
body.browser.mobileweb .playlistDetailsPage #followingBtn:hover,
.playlistDetailsPage #followBtn:active,
.playlistDetailsPage #followingBtn:active,*/
body.browser.mobileweb #redeemButton:hover,
body.browser.mobileweb .trebbleSubscribeSuggestionContent #followBtn:hover,
body.browser.mobileweb .trebbleSubscribeSuggestionContent #buyNowBtn:hover,
#redeemButton:active,
.trebbleSubscribeSuggestionContent #followBtn:active,
.trebbleSubscribeSuggestionContent #buyNowBtn:active {
    color: white!important;
    border-color: var(--trebble-primary) ;
    background-color: var(--trebble-primary);
}

.hightlightBtn:before,
#redeemButton:before,
.playlistDetailsPage #followBtn:before,
.playlistDetailsPage #buyNowBtn:before,
.playlistDetailsPage #followingBtn:before,
.playlistDetailsPage #purchasedBtn:before,
.trebbleSubscribeSuggestionContent #followBtn:before,
.trebbleSubscribeSuggestionContent #buyNowBtn:before{
  font-size: var(--text-2xl);
  vertical-align: middle;
  line-height: 32px;
}
.playlistShortcutButtonsWrapper > .left {
    margin-left: 10px;
}
.playlistShortcutButtonsWrapper > .right {
    margin-right: 10px;
}
@media (min-width: 500px), (min-height: 500px) and (orientation: landscape){
    [data-role=page].createCapsulePage.lighttext.transparent [data-role=header] * #nextButton,
    [data-role=page].createCapsulePage.lighttext.transparent [data-role=header] * #saveButtonIcon,
    body.darkTheme [data-role=page].createCapsulePage [data-role=header] * #nextButton,
    body.darkTheme [data-role=page].createCapsulePage [data-role=header] * #saveButtonIcon{
        color: var(--color-purple-400) !important;
        background-color: white;
    }
}

.createCapsulePage.lighttext.transparent [data-role=header] * #nextButton:not(.trebble_disabled):hover,
.createCapsulePage.lighttext.transparent [data-role=header] * #nextButton:not(.trebble_disabled):active,
.createCapsulePage.lighttext.transparent [data-role=header] * #saveButtonIcon:not(.trebble_disabled):hover,
.createCapsulePage.lighttext.transparent [data-role=header] * #saveButtonIcon:not(.trebble_disabled):active,
.createCapsulePage.lighttext.transparent [data-role=header] * #doneRecordingButtonIcon:not(.trebble_disabled):hover,
.createCapsulePage.lighttext.transparent [data-role=header] * #doneRecordingButtonIcon:not(.trebble_disabled):active{
    color: white!important;
    background-color: transparent;
}
[data-role=header] * #createDraftButton,
[data-role=header] * #publishButton,
[data-role=header] * #previewJourneyButton,
[data-role=header] * #revertToDraftButton,
[data-role=header] * #archiveBtn,
[data-role=header] * #removeFromArchiveButton{
 font-size: var(--text-2xl);
}
[data-role=header] * #editButton,

[data-role=header] * #menuOptionButton,
[data-role=header] * #filterButton,
[data-role=header] * #deleteButton,
[data-role=header] * #saveButtonIcon,
[data-role=header] * #doneRecordingButtonIcon,
[data-role=header] * #showRecordSectionButton.tag.actionButton,
[data-role=header] * #nextButton.tag.actionButton,
[data-role=header] * #showUploadSectionButton.tag.actionButton,
[data-role=header] * #shareTrebbleBtn {
    color: white;
    font-size: var(--text-4xl);
    line-height: 51px;
    padding-right: 3px;
    padding-left: 3px;
    padding: 0px;
    /*height: 100%;*/
    height: calc(52px - 8px);
    display: block;
    width: 51px;
    border: var(--border-0);
    margin: 4px 0px;
}


[data-role=header] * #menuOptionButton{
  font-size: var(--text-3xl);
}
[data-role=header] * #nextButton.tag.actionButton{
    font-size: var(--text-6xl);
}

[data-role=header] * a.actionButton.tag,
[data-role=header] * #showRecordSectionButton.tag.actionButton,
[data-role=header] * #nextButton.tag.actionButton,
[data-role=header] * #showUploadSectionButton.tag.actionButton{
   display: -ms-flexbox;
   display: -webkit-box;
   display: flex;
   display: -webkit-flex;
   -ms-flex-align: center;
   -webkit-box-align: center;
   align-items: center;
   -webkit-align-items: center;
   -ms-flex-pack: center;
   -webkit-box-pack: center;
   justify-content: center;
   -webkit-justify-content: center;

}

[data-role=header] * #loadingIndicatorBtn,
[data-role=header] * #editButton.disabled,
[data-role=header] * #menuOptionButton.disabled,
[data-role=header] * #filterButton.disabled,
[data-role=header] * #deleteButton.disabled,
[data-role=header] * #saveButtonIcon.disabled,
[data-role=header] * #doneRecordingButtonIcon.disabled,
[data-role=header] * .ui-btn.disabled{
  opacity: 0.5;
  cursor: default;
  background-color: transparent;
}

.darktext > [data-role=header] * #loadingIndicatorBtn,
.darktext > [data-role=header] * #editButton,
.darktext > [data-role=header] * #menuOptionButton,
.darktext > [data-role=header] * #filterButton,
.darktext > [data-role=header] * #deleteButton,
.darktext > [data-role=header] * #saveButtonIcon,
.darktext > [data-role=header] * #doneRecordingButtonIcon {
    color: var(--text-dark) !important;
}
.darktext > [data-role=header] * #editButton:active,
.darktext > [data-role=header] * #menuOptionButton:active,
.darktext > [data-role=header] * #filterButton:active,
.darktext > [data-role=header] * #deleteButton:active,
.darktext > [data-role=header] * #saveButtonIcon:active,
.darktext > [data-role=header] * #doneRecordingButtonIcon:active,
body.browser.mobileweb .darktext > [data-role=header] * #editButton:hover,
body.browser.mobileweb .darktext > [data-role=header] * #menuOptionButton:hover,
body.browser.mobileweb .darktext > [data-role=header] * #filterButton:hover,
body.browser.mobileweb .darktext > [data-role=header] * #deleteButton:hover,
body.browser.mobileweb .darktext > [data-role=header] * #saveButtonIcon:hover,
body.browser.mobileweb .darktext > [data-role=header] * #doneRecordingButtonIcon:hover {
  color: white !important;
}

.trebbleEditInfoPage.validating #loadingIndicatorBtn{
    display: block;
    font-size: var(--text-3xl-px);
}
.trebbleEditInfoPage.validating #loadingIndicatorBtn:hover,
.trebbleEditInfoPage.validating #loadingIndicatorBtn:active{
    background-color: transparent;
    border: var(--border-0);
    color: var(--text-dark) !important;
}

.trebbleEditInfoPage:not(.validating) #loadingIndicatorBtn,
.trebbleEditInfoPage.validating #saveButtonIcon{
    display: none !important;
}

[data-role=header] * #filterButton {
    font-size: var(--text-lg);
}
[data-role=header] * #deleteButton {
    font-size: var(--text-xl);
}
[data-role=header] * #saveButtonIcon {
    font-size: var(--text-6xl);
}
.bioWrapper * .carouselItem {

}

.carouselItem,
.capsuleCreationWrapper .capsuleInfo,
.capsuleCreationWrapper .languageUsedInAudioFldWrapper
.capsuleCreationWrapper .uploadProgressInfo,
.capsuleCreationWrapper .uploadProgressInfo .progressContentWrapper {
  -ms-flex-align: center;
  -webkit-box-align: center;
  align-items: center;
  -webkit-align-items: center;
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  display: -webkit-flex;
  -ms-flex-direction: column;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-flex-direction: column;
  text-align: center;
  display: flex;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  overflow: auto;
  
}

.capsuleCreationWrapper .capsuleInfo,
.capsuleCreationWrapper .languageUsedInAudioFldWrapper{
    display: block;
    
}


.capsuleCreationWrapper .capsuleInfo,
.capsuleCreationWrapper .languageUsedInAudioFldWrapper
.capsuleCreationWrapper  .uploadProgressInfo,
.capsuleCreationWrapper  .uploadProgressInfo .progressBar,
.capsuleCreationWrapper  .uploadProgressInfo .progressContentWrapper {

    width: calc(100% - 40px); 

}
.capsuleCreationWrapper  .uploadProgressInfo .progressContentWrapper{
    max-width: 700px;
    overflow: hidden;
}

.capsuleCreationWrapper  .uploadProgressInfo{
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.capsuleCreationWrapper .capsuleInfo,
.capsuleCreationWrapper .languageUsedInAudioFldWrapper{
    width: 100%;
    text-align: justify;
}

.capsuleCreationWrapper .dz-progress{
  display: none;
}

.progressContentWrapper #percentageProgress{
    font-weight: 900;
    font-size: var(--text-8xl-px);
}
.progressContentWrapper #progressMessage{
    font-weight: 600;
    font-size: var(--text-3xl-px);
}
.carouselItem > .transparent_button {
  width: 30%;
  max-width: 200px;
}

.bioWrapper * .carouselItem > .textContent {
    /* white-space: nowrap; */
}

.bioWrapper * .carouselItem > .textContent  i{
    margin-right: 10px;
}
.carouselItem > .textContent {
    text-align: center;
    width: 100%;
    -webkit-flex-direction: column;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
    margin: 6px;
    font-size: var(--text-sm);
    max-width: 700px;
}
.carouselWrapper.capsuleCreationWrapper,
.carouselWrapper.capsuleCreationWrapper > .owl-wrapper-outer,
.carouselWrapper.capsuleCreationWrapper > .owl-wrapper-outer > .owl-wrapper,
.carouselWrapper.capsuleCreationWrapper > .owl-wrapper-outer > .owl-wrapper > .owl-item {
    height: 100%;
}
.popupContent > [data-role=header] + div.carouselWrapper.capsuleCreationWrapper > .owl-wrapper-outer > .owl-wrapper > .owl-item > .carouselItem:first-child {
    height: 100%;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex-direction: column;
    overflow: auto; 
}
.popupContent > [data-role=header] + div.carouselWrapper.capsuleCreationWrapper > .owl-wrapper-outer > .owl-wrapper > .owl-item > .carouselItem:first-child >.flexContentCenteringBox {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    -webkit-flex: 1 1 auto;
}
.popupContent > [data-role=header] + div.carouselWrapper.capsuleCreationWrapper > .owl-wrapper-outer > .owl-wrapper > .owl-item > .carouselItem >:first-child {
    width: 100%;
    margin-top: 62px;
}
.popupContent > [data-role=header] + div.carouselWrapper.capsuleCreationWrapper > .owl-wrapper-outer > .owl-wrapper > .owl-item > * label,
.popupContent > [data-role=header] + div.carouselWrapper.capsuleCreationWrapper > .owl-wrapper-outer > .owl-wrapper > .owl-item > * span {
    width: 100%;
    font-weight: 300;
    font-size: var(--text-md);
}
.popupContent > [data-role=header] + div.carouselWrapper.capsuleCreationWrapper > .owl-wrapper-outer > .owl-wrapper > .owl-item > .carouselItem * #comment,
.popupContent > [data-role=header] + div.carouselWrapper.capsuleCreationWrapper > .owl-wrapper-outer > .owl-wrapper > .owl-item > .carouselItem * #categoryFld {
    font-size: var(--text-lg-px) !important;
    border: var(--border-0) none var(--text-light)!important;
    overflow: hidden;
    outline: none;
    background-color: var(--color-white-10);
    color: white;
    font-weight: 300;
    max-width: 700px;
}
#recordingBlock{
    display: flex;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    align-items: center;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    justify-content: space-around;
    -webkit-justify-content: space-around;
    -moz-justify-content: space-around;
    -ms-justify-content: space-around;
    flex-direction: column;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    height: calc(100% - 62px);
    width: 100%;
}

.capsuleCreationWrapper #showNotesToggleBox{

    display: flex;
    max-width: 300px;
    justify-content: space-around;
    align-items: center;
    flex-direction: row;
    height: 40px;
    margin: 10px auto;

}

.capsuleCreationWrapper #showNotesToggleBox .input_block{
    margin: auto 0px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.capsuleCreationWrapper  #noteSection{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    flex-direction: column;
    max-width: 800px;

}

.capsuleCreationWrapper  #noteSection .note_field_wrapper{
    flex:1 1 auto;
}

.capsuleCreationWrapper  #noteSection .note_field_wrapper textarea{
    width: calc(100% - 0.75em);
    height: calc(100% - 40px) !important;
    font-size: var(--text-md-px);
    overflow: auto;
    background-color: var(--color-white-10);
}
.capsuleCreationWrapper  #noteSection .note_field_wrapper textarea::placeholder{
    color: white;
}

.capsuleCreationWrapper  #noteSection .red_recording_circle,
.capsuleCreationWrapper  .progressbar-text .red_recording_circle{
    width: 20px;
    height: 20px;
    background-color: var(--color-error-bright);
    border-radius: 50%;
    display: inline;
    margin: 0px 10px;
    flex: 0 0 20px;
}
/*
.capsuleCreationWrapper  .progressbar-text .red_recording_circle{
   margin: 0px;
   margin-right: 10px;
   }*/


   body #confettiCanvas{
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    z-index: 99999999;
    pointer-events: none;
}


[is-recording=true].createCapsulePage .capsuleCreationWrapper #noteSection .red_recording_circle,
[is-recording=true].createCapsulePage .capsuleCreationWrapper  .progressbar-text .red_recording_circle{
 animation:  blink-animation  0.5s linear infinite alternate;
 animation:  blink-animation  0.5s linear infinite alternate;
}

.capsuleCreationWrapper  #noteSection #time_left_to_record{
    color: var(--color-error-bright);
}
.capsuleCreationWrapper  #noteSection #time_left_to_record,
.capsuleCreationWrapper  #noteSection #time_played,
.capsuleCreationWrapper  #noteSection #total_recording_time{
    font-size: var(--text-xl-px);
}

.capsuleCreationWrapper  #noteSection #recording_playback_box,
.capsuleCreationWrapper  #noteSection #recording_time_left_box{
    flex: 0 0 50px;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
}
.createCapsulePage[is-recording=true] .capsuleCreationWrapper  #noteSection #recording_playback_box,
.createCapsulePage:not([is-recording=true]) .capsuleCreationWrapper  #noteSection #recording_time_left_box{
    display: none;
}
.createCapsulePage:not([is-recording=true]) #peak_meter_container,
body.iosApp .createCapsulePage #peak_meter_container,
body.androidApp .createCapsulePage #peak_meter_container{
    visibility: hidden;
}

#recordControlsWrapper {
    -webkit-flex: 0 0 64px;
    -ms-flex: 0 0 64px;
    flex: 0 0 64px;
    background-color: transparent;
    height: 4em;
    width: 100%;
    bottom: 0px;
    -webkit-flex: 0 0 64px;
}
#capsuleLifeSpanFld-button ,
#microphoneFld-button ,
#ttsLanguageFld-button,
#ttsLanguageForVoiceActorFld-button,
#voiceProfileFld-button,
#voiceToneFld-button,
#newsletterEmbedThemeSelector-button,
#publishingSettingFld-button,
#languageUsedInAudioFld-button,
#background_music_category_fld-button,
#schedulingTimezoneFld-button,
#privacySettingFld-button,
#categoryFld-button {
    padding: 0px;
    background-color: var(--color-white-10);
    border-width: var(--border-2);
}
[data-role=page] * #capsuleLifeSpanFld-button,
[data-role=page] * #privacySettingFld-button,
[data-role=page] *#ttsLanguageFld-button,
[data-role=page] *#ttsLanguageForVoiceActorFld-button,
[data-role=page] *#voiceProfileFld-button,
[data-role=page] *#voiceToneFld-button,
[data-role=page] *#newsletterEmbedThemeSelector-button,
[data-role=page] *#publishingSettingFld-button,
[data-role=page] *#languageUsedInAudioFld-button,
[data-role=page] *#background_music_category_fld-button,
[data-role=page] *#schedulingTimezoneFld-button,
[data-role=page] * #categoryFld-button {
    color: var(--text-dark);
}

[data-role=page].createCapsulePage.ui-page:not(.lighttext) *#languageUsedInAudioFld-button:hover{
    border-color: var(--trebble-primary);
}

#capsuleLifeSpanFld-button > span,
#microphoneFld-button > span,
#ttsLanguageFld-button > span,
#ttsLanguageForVoiceActorFld-button > span,
#voiceProfileFld-button > span,
#voiceToneFld-button > span,
#newsletterEmbedThemeSelector-button > span,
#publishingSettingFld-button > span,
#languageUsedInAudioFld-button > span,
#background_music_category_fld-button > span,
#schedulingTimezoneFld-button > span,
#privacySettingFld-button > span,
#categoryFld-button > span {
    font-weight: 400;
    padding: 5px 20px 0px 20px;
    font-size: var(--text-sm-px);
}
#recordingBlock .trackSelectorBox,
#recordingBlock .track_selector_block_spacer{
    height: 75px;
    flex: 0 0 75px;
    align-items: center;
    display: flex;
    justify-content: center;
}
#recordingBlock .trackSelectorBox .swiper-button-prev,
#recordingBlock .trackSelectorBox .swiper-button-next{
    bottom: auto;
    top: 10px;
    margin-top: 0px;
}
#recordingBlock .trackSelectorBox .songSelectorPlaceholder{
    width: 100%;
}
[data-role=page] * #capsuleLifeSpanFld-button > span,
[data-role=page] * #ttsLanguageFld-button > span,
[data-role=page] * #ttsLanguageForVoiceActorFld-button > span,
[data-role=page] * #voiceProfileFld-button > span,
[data-role=page] * #voiceToneFld-button > span,
[data-role=page] * #newsletterEmbedThemeSelector-button > span,
[data-role=page] * #publishingSettingFld-button > span,
[data-role=page] * #languageUsedInAudioFld-button > span,
[data-role=page] * #background_music_category_fld-button > span,
[data-role=popup] * #background_music_category_fld-button > span,
[data-role=page] * #schedulingTimezoneFld-button > span,
[data-role=page] * #privacySettingFld-button > span,
[data-role=page] * #categoryFld-button > span {
    font-weight: 400;
}
[data-role=page].createCapsulePage * #capsuleLifeSpanFld-button > span,
[data-role=page].createCapsulePage * #ttsLanguageFld-button > span,
[data-role=page].createCapsulePage * #ttsLanguageForVoiceActorFld-button > span,
[data-role=page].createCapsulePage * #voiceProfileFld-button > span,
[data-role=page].createCapsulePage * #voiceToneFld-button > span, 
[data-role=page].createCapsulePage * #newsletterEmbedThemeSelector-button > span,
[data-role=page].createCapsulePage * #publishingSettingFld-button > span,
[data-role=page].createCapsulePage * #languageUsedInAudioFld-button > span,
[data-role=page].createCapsulePage * #background_music_category_fld-button > span,
[data-role=page].createCapsulePage * #schedulingTimezoneFld-button > span,
[data-role=page].createCapsulePage * #privacySettingFld-button > span,
[data-role=page].createCapsulePage * #categoryFld-button > span ,
[data-role=page].createCapsulePage * i.ion-ios7-arrow-down,
[data-role=page].createCapsulePage * i.ion-chevron-down{
  color:white;
}
[data-role=page].createCapsulePage.ui-page:not(.lighttext) * #languageUsedInAudioFld-button > span,
[data-role=page].createCapsulePage.ui-page:not(.lighttext) * i.ion-chevron-down{
    color: var(--trebble-primary);
}

[data-role=page].createCapsulePage.ui-page.record_only #creationModeSelectorWrapper{
    display: none;
}
#playerControlsWrapper > [data-role=controlgroup] >:first-child > a > t[class*="ion"] {
    line-height: 50px;
    font-size: var(--text-xl);
}
body.embedded * #playerControlsWrapper > [data-role=controlgroup] >:first-child > a > t[class*="ion"] {
    line-height: 40px;
    /*font-size: var(--text-md);*/
    height: 100%;
}
a#liveLikeButton {
    line-height: 40px;
    font-size: var(--text-lg);
}
a#infoButton {
    font-size: var(--text-lg);
    line-height: 40px;
    height: 100%;
}
body.embedded * #playerControlsWrapper > [data-role=controlgroup] >:first-child > a#liveLikeButton  > t[class*="ion"]{
    font-size: var(--text-lg);
    line-height: 40px;
}
#playerControlsWrapper > [data-role=controlgroup] >:first-child > a#liveLikeButton > t[class*="ion"] {
    font-size: var(--text-3xl);
}
body:not(.embedded) #playerControlsWrapper > [data-role=controlgroup] >:first-child > a#liveLikeButton > t[class*="ion"]{
    font-size: var(--text-md);
}
#playerFooter.browser * #playerControlsWrapper > [data-role=controlgroup] >:first-child > a > t[class*="ion"],
#playerFooter.browser * #playerControlsWrapper > [data-role=controlgroup] > a > t[class*="ion"] {
    font-size: var(--text-lg);
}
#playerFooter.browser * #playerControlsWrapper > [data-role=controlgroup] >:first-child > a#liveLikeButton > t[class*="ion"],
#playerFooter.browser * #playerControlsWrapper > [data-role=controlgroup] > a#liveLikeButton > t[class*="ion"] {
    font-size: var(--text-xl);
}
body:not(.embedded) #playerFooter.browser * #playerControlsWrapper > [data-role=controlgroup] >:first-child > a#liveLikeButton > t[class*="ion"],
body:not(.embedded) #playerFooter.browser * #playerControlsWrapper > [data-role=controlgroup] > a#liveLikeButton > t[class*="ion"] {
  font-size: var(--text-md);
}
#playerControlsWrapper > [data-role=controlgroup] >:first-child > a > t.ion-ios7-rewind,
#playerControlsWrapper > [data-role=controlgroup] >:first-child > a > t.ion-ios7-fastforward,
#playerControlsWrapper > [data-role=controlgroup] >:first-child > a > t.ion-ios7-skipbackward,
#playerControlsWrapper > [data-role=controlgroup] >:first-child > a > t.ion-ios7-skipforward,
#playerControlsWrapper > [data-role=controlgroup] >:first-child > a > t.pe-7s-micro,
#playerControlsWrapper > [data-role=controlgroup] >:first-child > a > t.pe-7s-prev,
#playerControlsWrapper > [data-role=controlgroup] >:first-child > a > t.pe-7s-next {
    font-size: var(--text-4xl);
}

#playerControlsWrapper > [data-role=controlgroup] > :first-child > a > t.ion-ios7-mic{
    font-size: var(--text-2xl);
}
#playerControlsWrapper > [data-role=controlgroup] > :first-child > a > t.ion-ios7-paperplane,
#playerControlsWrapper > [data-role=controlgroup] > :first-child > a > t.ion-ios7-paperplane-outline{
    font-size: var(--text-4xl);
}
#playerControlsWrapper > [data-role=controlgroup] >:first-child > a#shareBtn > t{
    font-size: var(--text-2xl);
    height: 100%;
    display: flex;
    justify-content:center;
    align-items:center;
}
body.embedded * #playerControlsWrapper > [data-role=controlgroup] >:first-child > a > t.ion-ios7-rewind,
body.embedded * #playerControlsWrapper > [data-role=controlgroup] >:first-child > a > t.ion-ios7-fastforward,
body.embedded * #playerControlsWrapper > [data-role=controlgroup] >:first-child > a > t.ion-ios7-skipbackward,
body.embedded * #playerControlsWrapper > [data-role=controlgroup] >:first-child > a > t.ion-ios7-skipforward,
body.embedded * #playerControlsWrapper > [data-role=controlgroup] >:first-child > a > t.pe-7s-micro,
body.embedded * #playerControlsWrapper > [data-role=controlgroup] >:first-child > a > t.ion-ios7-mic,
body.embedded * #playerControlsWrapper > [data-role=controlgroup] >:first-child > a > t.ion-ios7-paperplane,
body.embedded * #playerControlsWrapper > [data-role=controlgroup] >:first-child > a > t.ion-ios7-paperplane-outline,
body.embedded * #playerControlsWrapper > [data-role=controlgroup] >:first-child > a > t.pe-7s-prev,
body.embedded * #playerControlsWrapper > [data-role=controlgroup] >:first-child > a > t.pe-7s-next {
    font-size: var(--text-xl);
}
body.embedded * #playerControlsWrapper > [data-role=controlgroup] >:first-child > a#shareBtn > t{
    font-size: var(--text-lg);
}
#playerFooter.browser * #playerControlsWrapper > [data-role=controlgroup] >:first-child > a > t.ion-ios7-rewind,
#playerFooter.browser * #playerControlsWrapper > [data-role=controlgroup] >:first-child > a > t.ion-ios7-fastforward,
#playerFooter.browser * #playerControlsWrapper > [data-role=controlgroup] >:first-child > a > t.ion-ios7-skipbackward,
#playerFooter.browser * #playerControlsWrapper > [data-role=controlgroup] >:first-child > a > t.ion-ios7-skipforward,
#playerFooter.browser * #playerControlsWrapper > [data-role=controlgroup] >:first-child > a > t.pe-7s-micro,
#playerFooter.browser * #playerControlsWrapper > [data-role=controlgroup] >:first-child > a > t.pe-7s-prev,
#playerFooter.browser * #playerControlsWrapper > [data-role=controlgroup] >:first-child > a > t.pe-7s-next,
#playerFooter.browser * #playerControlsWrapper > [data-role=controlgroup] > a > t.ion-ios7-rewind,
#playerFooter.browser * #playerControlsWrapper > [data-role=controlgroup] > a > t.ion-ios7-fastforward,
#playerFooter.browser * #playerControlsWrapper > [data-role=controlgroup] > a > t.ion-ios7-skipbackward,
#playerFooter.browser * #playerControlsWrapper > [data-role=controlgroup] > a > t.ion-ios7-skipforward,
#playerFooter.browser * #playerControlsWrapper > [data-role=controlgroup] > a > t.pe-7s-micro,
#playerFooter.browser * #playerControlsWrapper > [data-role=controlgroup] > a > t.pe-7s-prev,
#playerFooter.browser * #playerControlsWrapper > [data-role=controlgroup] > a > t.pe-7s-next {
    font-size: var(--text-3xl);
}
#playerFooter.browser * #playerControlsWrapper > [data-role=controlgroup] >:first-child > a#shareBtn > t,
#playerFooter.browser * #playerControlsWrapper > [data-role=controlgroup] > a#shareBtn > t,
#playerFooter.browser * #playerControlsWrapper > [data-role=controlgroup] >:first-child > a > t.ion-ios7-mic,
#playerFooter.browser * #playerControlsWrapper > [data-role=controlgroup] > a > t.ion-ios7-mic{
    font-size: var(--text-xl);
}
#playerFooter.browser * #playerControlsWrapper > [data-role=controlgroup] >:first-child > a > t.ion-ios7-paperplane,
#playerFooter.browser * #playerControlsWrapper > [data-role=controlgroup] > a > t.ion-ios7-paperplane,
#playerFooter.browser * #playerControlsWrapper > [data-role=controlgroup] >:first-child > a > t.ion-ios7-paperplane-outline,
#playerFooter.browser * #playerControlsWrapper > [data-role=controlgroup] > a > t.ion-ios7-paperplane-outline{
    font-size: var(--text-4xl);
}
#playerControlsWrapper > [data-role=controlgroup] >:first-child > a > t[class*="ion"].ion-loading-c {
    vertical-align: middle;
    text-align: center;
    line-height: 60px;
    font-size: var(--text-5xl);
    top: -5px;
    /*height: 100%;*/
    /*height: 60px;*/

}

#playerControlsWrapper > [data-role=controlgroup] >:first-child > a > t[class*="ion"].ion-loading-c{
    color: var(--text-dark);
}
.textContent .socialInfos {
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    align-self: center;
    width: 100%;
    text-overflow: ellipsis;
    text-align: center;
    overflow: hidden;
    white-space: nowrap;
}
.userProfileDetailsPage .textContent .socialInfos {
    width: 100%;
    height: 50px;
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
    max-width: 700px;
}
.userProfileDetailsPage .textContent .socialInfos > div {
  -ms-flex: 1 1 auto;
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 auto;
  flex: 1 1 auto;
  cursor: pointer;
  -webkit-flex: 1 1 auto;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 1px;
  height: 50px;
  margin: auto;
  flex-direction: column;
  /* border: var(--border-1) solid var(--color-white-20); */
  /* border-width: var(--border-0); */
  /* border-bottom-width: var(--border-1); */
  /* margin: 6px; */
}
.userProfileDetailsPage .textContent .socialInfos > div.separator {
  vertical-align: middle;
  top: calc(50% - 15px);
  font-size: var(--text-3xl);
  opacity: 0.2;
  min-width: 10px;
  max-width: 20px;
  cursor: default;
}
@media (min-width: 500px), (min-height: 500px) and (orientation: landscape){
   .userProfileDetailsPage .textContent .socialInfos > div {
    letter-spacing:3px;
}
}


@media (max-width: 500px), (max-height: 500px) and (orientation: landscape){

    .transparent_button,
    a.largeMenuButton.ui-link,
    a.largeMenuButton.ui-link:hover {
        font-size: var(--text-sm-px);
    }
    .userProfileDetailsPage .textContent .socialInfos > div {
        font-size: var(--text-xs);
    }

    .userProfileDetailsPage .textContent .socialInfos > div.separator {
        min-width: 2px;
    }
}

body.browser.mobileweb .userProfileDetailsPage .textContent .socialInfos > div:not(.separator):hover,
.userProfileDetailsPage .textContent .socialInfos > div:not(.separator):active {
  background-color: var(--color-white-10);
}

body.browser.mobileweb .userProfileDetailsPage.darktext .textContent .socialInfos > div:not(.separator):hover,
.userProfileDetailsPage .textContent .socialInfos > div:not(.separator):active {
  background-color: var(--color-black-10);
}

.userProfileDetailsPage .textContent .socialInfos > div > .value {
    font-size: var(--text-lg);
    font-weight: 600;
}
.userProfileDetailsPage .textContent {
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-flex: 1 1 60px;
    -ms-flex: 1 1 60px;
    flex: 1 1 60px;
    display: -webkit-flex;
    -webkit-flex-direction: column;
    -webkit-justify-content: flex-end;
    -webkit-flex: 1 1 60px;
}
div.popupContent.top > div.ui-header[data-role=header] + div,
div.popupContent.top >div > div.ui-header[data-role=header] + div {
    margin-top: 61px;
}
.ownerDataBubble {
    color: var(--pulse-gray);
    display: block;
    top: 10px;
    position: absolute;
    border-radius: 50%;
    width: 14px;
    height: 14px;
    background-color: var(--pulse-gray);
    right: calc(50% - 13px);
    animation: graypulse 1.3s infinite;
    animation: graypulse 1.3s infinite;
}

#startrecordingcapsuleBtn t{
    margin-right: 15px;
    font-size: var(--text-xl-px);
}
/*#playerFooter.browser * .ownerDataBubble {
    background-color: var(--bg-light);
    animation: graypulse 1.3s infinite;
    animation: graypulse 1.3s infinite;
}
#playerFooter.browser * .ownerDataBubble {
    background-color: var(--color-gray-300);
    animation: graypulse 1.3s infinite;
    animation: graypulse 1.3s infinite;
    }*/
    .unconsumed.ownerDataBubble {
        background-color: var(--pulse-green);
        animation: greenpulse 1.3s infinite;
        animation: greenpulse 1.3s infinite;
    }
    #player .unconsumed.ownerDataBubble{
        animation: graypulse 1.3s infinite;
        animation: graypulse 1.3s infinite;
    }
    [data-role=popup] * .songLibraryWrapper {
        background-color: transparent;
        display: -webkit-flex;
        display: flex;
        flex: 1 1 auto;
        -webkit-flex: 1 1 auto;
        line-height: 20px;
        margin-right: 0px;
        margin-left: 0em;
        padding: 0em;
        /*border-bottom: var(--border-1) solid var(--color-gray-100);*/
        
        border-bottom: var(--border-1) solid var(--color-white-10);
        color: var(--text-dark);
    }
    [data-role=popup] * .songLibraryWrapper > .infiniteListItem {
        background-color: transparent;
    }
    [data-role=popup] * .songLibraryWrapper > .infiniteListItem:active,
    [data-role=popup] * .songLibraryWrapper > .infiniteListItem:active ~  .explicitIconWrapper {
        color: white;
        background-color: var(--trebble-primary);
        border-color: var(--color-white-10);
    }
    [data-role=popup] * .songLibraryWrapper > .infiniteListItem:active > div {
        color: white;
        background-color: var(--trebble-primary);
        border-color: var(--color-white-10);
    }
    [data-role=popup] * .infiniteListInnerContainer > div {
        background-color: transparent;
    }
    [data-role=popup] * #songAddToQueueTooltip {
        background-color: transparent;
    }
    [data-role=popup] * #songLibraryOptionButtuon,
    [data-role=popup] * #songCapsuleOptionButtuon {
        background-color: transparent;
    }
    .songSelectorWidget * #songLibraryOptionButtuon,
    .songSelectorWidget * #songCapsuleOptionButtuon {
        color: white;
    }
    [data-role=popup] * .songTitleLibrary {
        color: white;
    }
    .songSummaryInfo {
        height: 65px;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
    }
    .songAlbumArt {
        background-repeat: no-repeat;
        -ms-flex: 0 0 60px;
        flex: 0 0 60px;
        -webkit-flex: 0 0 60px;
        background-size: contain;
        background-image: url('../img/videoLoading.png');
        height: 60px !important;
        width: 60px !important;
    }
    .trebbleOptionList * .songTextInfo {
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-justify-content: center;
    }
    .songTextInfo {
        overflow: hidden;
        padding: 5px;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-justify-content: center;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-flex-direction: column;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        -webkit-flex: 1 1 auto;
        display: -webkit-flex;
    }
    .capsuleCard {
        margin-bottom: 4px;
        background-color: var(--color-white-10);
        box-sizing: border-box;
        padding: 6px;
        /* border: var(--border-1) solid white; */
        
        display: -ms-flexbox;
        display: flex;
        display: -webkit-flex;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-flex-direction: column;
        /* height: 330px; */
        
        width: calc(100% - 20px);
        border-radius: 2px;
        max-width: 430px;
        margin: auto;
        margin-top: 10px;
        margin-bottom: 10px;
        text-align: justify;
    }
    [data-role=page] * .capsuleCard {
      color: black !important;
      /* height: 470px; */
      background-color: white;
      border: var(--border-1) solid var(--color-gray-100);
      margin-top: 20px;
      margin-bottom: 20px;
  }

  .capsuleCard #shareButton{
    /*display: none;*/
}


[data-url="mylibrary"] .capsuleCard #shareButton{
    display: flex;
}

body.browser.mobileweb [data-role=page] * .capsuleCard:hover,
html  body.browser.mobileweb .ui-page-theme-c .playlistItem.withLargeAlbumArt:hover,
html  body.browser.mobileweb .ui-page-theme-a .playlistItem.withLargeAlbumArt:hover{
  /*background-color: var(--bg-light);*/
  transform: translate(0px, -2px);
  transform: translate(0px, -2px);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: all;
  transition-property: all;
}

[data-role=page] * .capsuleCard,
[data-role=page] * .capsuleCard * .itemTitle,
[data-role=page] * .capsuleCard * .itemSubtitle,
[data-role=page] * .capsuleCard * .description {
  color: var(--color-gray-750) !important;
}
[data-role=page] * .capsuleCard * .itemTitle {
  font-size: var(--text-base);
  color: var(--text-dark) !important;
  /* font-weight: bold; */
}
[data-role=page].lighttext * .capsuleCard,
[data-role=page].lighttext * .capsuleCard * .itemTitle {
  color:white !important;
}

[data-role=page].lighttext * .capsuleCard * .itemSubtitle,
[data-role=page].lighttext * .capsuleCard * .description {
  color:var(--color-white-70) !important;
}

[data-role=page] * .capsuleCard > .ownerInfo > .userAvatar {
    background-color: var(--color-gray-150);
}
.capsuleCard > .ownerInfo,
.capsuleCard > .expirationInfo {
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-direction: row;
    -ms-flex: 0 0 40px;
    flex: 0 0 40px;
    -webkit-flex: 0 0 40px;
}


.capsuleCard #playCapsuleBtn{
  width: 100%;
  box-sizing: border-box;
  max-width: 100%;
}
.capsuleCard #playCapsuleBtn i{

    width: 20px;
    height: 20px;
    align-items: center;
    justify-content: center;
    display: flex;
    font-size: var(--text-lg-px);

}

.capsuleCard #playCapsuleBtn label{
    margin: 0px;
}

.capsuleCard > .expirationInfo {
    -ms-flex: 0 0 90px;
    flex: 0 0 90px;
    -webkit-flex: 0 0 90px;
}
.capsuleCard > .title {
  padding: 5px;
  font-size: var(--text-lg);
  color: var(--text-dark);
  font-weight: 600;
  box-sizing: border-box;
  flex-shrink: 0;
}

.capsuleCard > .title > span{
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.capsuleCard > .ownerInfo > .userAvatar {
    border-radius: 50%;
    -ms-flex: 0 0 30px;
    flex: 0 0 30px;
    -webkit-flex: 0 0 30px;
    height: 30px;
    width: 30px;
}
.capsuleCard > #commentsListWrapper * .commentBoxWrapper{
    position: relative;
    background-color: var(--color-white-10);
    color: white;
}
.capsuleCard > #commentsListWrapper{
    padding-top: 10px;
    padding-bottom: 10px;
    flex-shrink: 0;
}
.capsuleCard > .ownerInfo > .buttonGroup > #status{
    padding-right: 4px;
    padding-left: 4px;
    border: var(--border-3) solid var(--color-gray-300);
    border-radius: 3px;
    background-color: var(--color-gray-300);
    color: white;
    display:none;
}

.capsuleCard > .ownerInfo > .buttonGroup > #status[data-capsule-status=onair]{
    border: var(--border-3) solid var(--trebble-primary);
    background-color: var(--trebble-primary);
    display:block;
}

.capsuleCard > .ownerInfo > .buttonGroup > #status[data-capsule-status=scheduled] {
  border: var(--border-3) solid var(--color-purple-500);
  background-color: var(--color-purple-500);
  display: block;
}


.capsuleCard > .ownerInfo > .buttonGroup > #status[data-capsule-status=processing]{
    display:block;
} 

.capsuleCard > .ownerInfo > .buttonGroup > i#commentButton{
  display:none!important;
}
#player  * .capsuleCard,
#player  * .capsuleCard > .title > span,
#player * .capsuleCard * .itemTitle, 
#player * .capsuleCard * .itemSubtitle, 
#player * .capsuleCard * .description{
  color: white !important;
  background-color: transparent;
  border-color: transparent;
  box-shadow: none;
}
#player * .capsuleCard{
    width: 100%;
    max-width: calc(100% - 100px);
}
#player * .capsuleCard .ownerInfo .buttonGroup{

    display: none;
}

#player * .capsuleCard > .note {
    max-height: unset;
}

body.browser.mobileweb  #player  * .capsuleCard:hover{
  box-shadow: none;
}

#artistSocialInfoAndCapsuleOverviewBox * .swiper-pagination{
  height: 16px;
}
.capsuleCard > .ownerInfo > .usernameAndCreationDate {
    padding-right: 4px;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-justify-content: flex-start;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    -webkit-flex: 1 1 auto;
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex-direction: column;
    padding-left: 4px;
    overflow: hidden;
}
.capsuleCard > .ownerInfo > .buttonGroup {
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
    -ms-flex-align: center;
    align-items: center;
    -webkit-align-items: center;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    -webkit-flex: 0 0 auto;
    height: 40px;
}
.capsuleCard > .ownerInfo > .buttonGroup > i {
  color: var(--color-white-80);
  font-size: var(--text-3xl);
  width: 36px;
  height: 36px;
  text-align: center;
  padding: 2px;
  cursor: pointer;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-justify-content: center;
  display: -ms-flexbox;
  display: flex;
  display: -webkit-flex;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-direction: row;
}

.capsuleCard > .ownerInfo > .buttonGroup > i#addToTrebbleOptionButtonWrapper{
    width: 36px;
    height: 36px;
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
    color:var(--trebble-primary);
    font-size: var(--text-base);
}

.capsuleCard > .ownerInfo > .buttonGroup > i#addToTrebbleOptionButtonWrapper > #addToTrebbleOptionButtuon{
    width: auto;
    height: auto;
    color: var(--trebble-primary) !important;
    font-size: var(--text-2xl);
}
[data-role=popup] .capsuleCard > .ownerInfo > .buttonGroup > i#addToTrebbleOptionButtonWrapper > #addToTrebbleOptionButtuon {
  color:white !important;
}
.capsuleCard > .ownerInfo > .buttonGroup > i#addToTrebbleOptionButtonWrapper > #addToTrebbleOptionButtuon:hover,
.capsuleCard > .ownerInfo > .buttonGroup > i#addToTrebbleOptionButtonWrapper > #addToTrebbleOptionButtuon:active{
  background-color: transparent;
  border-color:transparent;
}
.capsuleCard > .ownerInfo > .buttonGroup > i > t{
  vertical-align: middle;
}

body.browser.mobileweb .capsuleCard > .ownerInfo > .buttonGroup > i:hover,
.capsuleCard > .ownerInfo > .buttonGroup > i:active{
  background-color: var(--color-white-5);
}

body.browser.mobileweb #player .capsuleCard > .ownerInfo > .buttonGroup > i:hover,
#player .capsuleCard > .ownerInfo > .buttonGroup > i:active{
  background-color: var(--color-white-10);
}

[data-role=page] .capsuleCard > .ownerInfo > .buttonGroup > i,
[data-role=page] .capsuleCard .clickable,
[data-role=page].lighttext .capsuleCard .clickable {
  color: var(--trebble-primary) !important;
  
}

body.browser.mobileweb [data-role=page] .capsuleCard > .ownerInfo > .buttonGroup > i:hover,
[data-role=page] .capsuleCard > .ownerInfo > .buttonGroup > i:active,
body.browser.mobileweb [data-role=page] .capsuleCard .clickable:hover, 
[data-role=page] .capsuleCard .clickable:active{

  background-color: var(--bg-overlay-dark);
  color: var(--trebble-primary);
  cursor: pointer;
  transform: translateZ(0);
  transform: translateZ(0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: color, background-color;
  transition-property: color, background-color;
}


.capsuleCard > .note {
    /*opacity: 1;*/
    font-size: var(--text-sm);
    box-sizing: border-box;
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-align-content: center;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    overflow: hidden;
    /*-ms-flex: 0 0 128px;
    flex: 0 0 128px;
    -webkit-flex: 0 0 128px;*/
    -ms-flex: 1 1 100%;
    flex: 1 1 100%;
    -webkit-flex: 1 1 100%;
    text-overflow: ellipsis;
    /*padding: 5px;*/
    margin: 5px;
    overflow: auto;
    min-height: 60px;
    white-space: pre-line;
    /*max-height: 175px;*/
    max-height: 60px;
    white-space: normal;
    flex-direction: column;
    text-align: start;
}

[data-role=popup] .capsuleCard > .note{
    max-height: 200px;
}

/*.capsuleCard > .note *,*/
[data-role=popup] * .capsuleCard > .note *,
[data-role=page].userProfileDetailsPage * .capsuleCard > .note *,
[data-role=page].playlistDetailsPage * .capsuleCard > .note *{
    color: var(--text-light)!important;
}

.capsuleCard > .note p {
    display: block !important;
    -webkit-margin-before: 0.6em !important;
    -webkit-margin-after: 0.6em !important;
    -webkit-margin-start: 0 !important;
    -webkit-margin-end: 0 !important;
    line-height: 1.3 !important;
}



.capsuleCard > .note img,
.capsuleCard > .note hr,
.capsuleCard > .note svg,
.capsuleCard > .note canvas,
.capsuleCard > .note audio,
.capsuleCard > .note video,
.capsuleCard > .note figure{
    display: none !important;
}

.capsuleCard > .note h3,
.capsuleCard > .note h2,
.capsuleCard > .note h1,
.capsuleCard > .note *{
    font-size: var(--text-base) !important;
    font-style: normal !important;
}

.capsuleCard > .note h3,
.capsuleCard > .note h2,
.capsuleCard > .note h1{
    margin:0px !important;
}

.capsuleCard > .note blockquote{
    margin: 0px;
    padding-left: 0px;
    border: var(--border-0);
}

.capsuleCard > .note ul {
  list-style-type: square !important;
  margin-block-start: 0em  !important;
  margin-block-end: 0em !important;
  margin-inline-start: 0px !important;
  margin-inline-start: 0px !important;
  margin-inline-end: 0px !important;
  padding-inline-start: 16px !important;
  -webkit-margin-before: 0.6em !important;
  -webkit-margin-after: 0.6em !important;
}
.capsuleCard > .note ul,
.capsuleCard > .note ul li {
  list-style-type: square !important;
}

.capsuleCard > .note > a {
    color: var(--trebble-primary);
    display: contents;
}

[data-role=page] * .capsuleCard > .note,
[data-role=page] * .capsuleCard > .expirationInfo {
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex-direction: column;
}
[data-role=page] * .capsuleCard > .note * #comment {
    border: var(--border-0) !important;
    font-size: var(--text-base);
    color: var(--text-dark) !important;
    /*border-bottom: var(--border-1) var(--color-gray-100) solid !important;*/
}
/*

[data-role=page] * .capsuleCard > .note {
    -ms-flex: 0 0 158px;
    flex: 0 0 158px;
    -webkit-flex: 0 0 158px;
}
[data-role=page] * .capsuleCard > .note.withoutUrlPreview,
[data-role=popup] * .capsuleCard > .note.withoutUrlPreview{
    -ms-flex: 0 0 234px;
    flex: 0 0 234px;
    -webkit-flex: 0 0 234px;
    -moz-flex: 0 0 234px;
    }*/
    @media(max-width: 800px){
        [data-role=page] * .capsuleCard > .note,
        [data-role=popup] * .capsuleCard > .note{
            -ms-flex: 1 1 100%;
            flex: 1 1 100%;
            -webkit-flex: 1 1 100%;
            -moz-flex: 1 1 100%;
            /*max-height: 158px;*/
            max-height: 50px;
        }

        [data-role=page] * .capsuleCard > .note.withoutUrlPreview,
        [data-role=popup] * .capsuleCard > .note.withoutUrlPreview{
            -ms-flex: 1 1 100%;
            flex: 1 1 100%;
            -webkit-flex: 1 1 100%;
            -moz-flex: 1 1 100%;
            /*max-height: 234px;*/
            max-height: 126px;
        }
    }
    textarea.ui-input-text,
    paper-autogrow-textarea > textarea.ui-input-text,
    paper-input-decorator > paper-autogrow-textarea > textarea.ui-input-text {
        border: var(--border-0) !important;
        font-size: var(--text-base);
        color: var(--text-dark);
        /*border-bottom: var(--border-1) var(--color-gray-100) solid !important;*/
    }
    .capsuleCard > .songInfo {
        -ms-flex: 0 0 70px;
        flex: 0 0 70px;
        -webkit-flex: 0 0 70px;
        display: -ms-flexbox;
        display: flex;
        display: -webkit-flex;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-justify-content: center;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-flex-direction: row;
    }
    .capsuleCard > .songInfo > .songCoverArt {
        padding: 5px;
        -ms-flex: 0 0 50px;
        flex: 0 0 50px;
        -webkit-flex: 0 0 60px;
    }

    [data-role=page] .capsuleCard > .songInfo > .songCoverArt[src='../img/videoLoading.png'] {
        background-color: var(--bg-light);
    }
    [data-role=page].userProfileDetailsPage   .capsuleCard > .songInfo > .songCoverArt[src='../img/videoLoading.png'] {
      background-color: transparent;
  }

  .capsuleCard > .songInfo > .songAndArtist {
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-justify-content: center;
    padding: 4px;
    box-sizing: border-box;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    -webkit-flex: 1 1 auto;
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex-direction: column;
    overflow: hidden;
}
.capsuleCard > .songInfo > .buttonGroup {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    -webkit-flex: 0 0 auto;
}
.capsuleCard > #expirationDate {
    -ms-flex: 0 0 30px;
    flex: 0 0 30px;
    -webkit-flex: 0 0 30px;
    color: var(--trebble-primary);
}
[data-role=page] * .capsuleCard * .itemSubtitle#expirationDate{
   color: var(--trebble-primary) !important;
}

[data-role=page] * .capsuleCard * .itemSubtitle#schedulingInfo{
  color:  var(--color-purple-500) !important;
}

.capsuleCard  #schedulingInfo{
    cursor: pointer;
}

.capsuleCard  .capsuleSocialInfo {
    font-size: var(--text-md);
    -ms-flex: 0 0 30px;
    flex: 0 0 30px;
    -webkit-flex: 0 0 30px;
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-direction: row;
    height: 30px;
    justify-content: flex-end;
}


.capsuleCard #playCapsuleWrapper .capsuleSocialInfo{
    flex: 1 1 auto;
}

.capsuleCard  .capsuleSocialInfo > div {
    font-size: var(--text-sm);
    -ms-flex-align: center;
    align-items: center;
    -webkit-align-items: center;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    -webkit-flex: 1 1 auto;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-justify-content: center;
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-direction: row;
    padding: 0px 10px;
}


.capsuleCard:not(.editable)  .capsuleSocialInfo > div#expirationDate,
.capsuleCard:not(.editable)  .capsuleSocialInfo > div#privacy{
  display:none;
}
.capsuleCard > .actionButtonWrapper {
    margin: 0px;
    box-sizing: border-box;
    -ms-flex: 0 0 86px;
    flex: 0 0 86px;
    -webkit-flex: 0 0 86px;
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex-direction: column;
}
body[showRoundCapsulePlayButton=true] .capsuleCard > .actionButtonWrapper{
   -ms-flex: 0 0 46px;
   flex: 0 0 46px;
   -webkit-flex: 0 0 46px;
   -ms-flex-direction: row;
   flex-direction: row;
   -webkit-flex-direction: row;
}
body[showRoundCapsulePlayButton=true] .capsuleCard > .actionButtonWrapper #playCapsuleBtn{
    width: 40px;
    height: 40px;
    padding: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin: 0px 10px 0px 0px;
}

body[showRoundCapsulePlayButton=true] .capsuleCard > .actionButtonWrapper #playCapsuleBtn i{
    font-size: var(--text-xl-px);
    margin-left: 2px;
    
}
body[showRoundCapsulePlayButton=true] .capsuleCard > .actionButtonWrapper #playCapsuleBtn i.animate-spin{
    margin-left: 0px;
}
body[showRoundCapsulePlayButton=true] .capsuleCard > .actionButtonWrapper #playCapsuleBtn i.ion-ios7-play{
    margin-left: 5px;
}

body[showRoundCapsulePlayButton=true] .capsuleCard > .actionButtonWrapper #playCapsuleBtn label{
    display: none;
}
body[showRoundCapsulePlayButton=true] .capsuleCard  .capsuleSocialInfo > div{
    flex: 0 0 auto;
}
.capsuleCard:not([data-capsule-status=onair]) #numberOfPlays,
/*.capsuleCard:not([data-capsule-status=onair]) #numberOfComments,*/
.capsuleCard[data-capsule-status=scheduled] #expirationDate{
    display: none;
}
.actionButtonWrapper {
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-align-items: center;
}

.actionButtonWrapper.responsive{
    flex-direction: row;
}

@media(max-width: 500px), (max-height: 500px) and (orientation: landscape){
    .actionButtonWrapper.responsive{
        flex-direction: column;
    }
}

body.browser * .actionButtonWrapper > .actionButton {
    width: fit-content;
    max-width: 200px;
    cursor: pointer;
    padding-left: 15px;
    padding-right: 15px;
    flex: 0 0 auto;
}

[data-role=page] .capsuleCard > .actionButtonWrapper > .actionButton,
.capsuleCard > .actionButtonWrapper > .actionButton label{
 color: var(--trebble-primary);
}
.capsuleCard > .actionButtonWrapper > .actionButton.disabled,
body.browser.mobileweb .capsuleCard > .actionButtonWrapper > .actionButton.disabled:hover,
.capsuleCard > .actionButtonWrapper > .actionButton.disabled:active,
#noSongSelectionBtn.disabled:active,
#noSongSelectionBtn.disabled:hover,
body.browser.mobileweb #noSongSelectionBtn.disabled:hover{
  color: var(--color-gray-300) !important;
  border-color: var(--color-gray-300)!important;
  background-color:transparent !important;
  cursor: default !important;
}
[data-role=popup] * .capsuleCard,
[data-role=popup] * .capsuleCard > .title > span,
[data-role=popup] * .capsuleCard > .note > span,
[data-role=page].userProfileDetailsPage * .capsuleCard,
[data-role=page].userProfileDetailsPage * .capsuleCard > .title > span,
[data-role=page].userProfileDetailsPage * .capsuleCard > .note > span,
[data-role=page].playlistDetailsPage * .capsuleCard,
[data-role=page].playlistDetailsPage * .capsuleCard > .title > span,
[data-role=page].playlistDetailsPage * .capsuleCard > .note > span,
[data-role=page].playlistDetailsPage  .capsuleCard   a ,
[data-role=page].userProfileDetailsPage  .capsuleCard  a {
  color: white;
}

[id*=capsuleInfoEditPage] .capsuleCard > .actionButtonWrapper > .actionButton {
  color: white;
}
[data-role=page].userProfileDetailsPage * .capsuleCard,
[data-role=page].playlistDetailsPage * .capsuleCard  {
  border-color: transparent;
  background-color: var(--color-white-10);
}

[data-role=page].lighttext .capsuleCard > .songInfo > .songCoverArt[src='../img/videoLoading.png'] {
  border-color: transparent;
  background-color:transparent;
}


.capsuleCard > .actionButtonWrapper > .actionButton,
#noSongSelectionBtn {
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-items: center;
    -webkit-justify-content: center;
    padding-bottom: 5px;
    padding-top: 5px;
    text-align: center;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    -webkit-flex: 1 1 auto;
    /* height: 30px; */
    
    margin: 0px;
    padding-right: 0px;
    padding-left: 0px;
    font-weight: 300;
    /* background-color: var(--trebble-primary); */
    
    border: var(--border-2) solid var(--trebble-primary);
    color: var(--trebble-primary);
    line-height: 25px;
    font-size: var(--text-sm);
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
    width: 100%;
}
body.browser.mobileweb .capsuleCard > .actionButtonWrapper > .actionButton:hover,
body.browser.mobileweb #noSongSelectionBtn:hover {
  cursor: pointer;
  transform: translateZ(0);
  transform: translateZ(0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: color, background-color;
  transition-property: color, background-color;
}
.capsuleCard > .actionButtonWrapper > .actionButton:active,
body.browser.mobileweb .capsuleCard > .actionButtonWrapper > .actionButton:hover,
.capsuleCard > .actionButtonWrapper > .actionButton:active label,
body.browser.mobileweb .capsuleCard > .actionButtonWrapper > .actionButton:hover label,
#noSongSelectionBtn:active,
body.browser.mobileweb #noSongSelectionBtn:hover {
    background-color: var(--trebble-primary);
    border: var(--border-2) solid var(--trebble-primary);
    color: white;
}
[id*=capsuleInfoEditPage] .capsuleCard > .actionButtonWrapper > .actionButton:active,
body.browser.mobileweb [id*=capsuleInfoEditPage] .capsuleCard > .actionButtonWrapper > .actionButton:hover {
  background-color: var(--trebble-primary-dark);
  border: var(--border-2) solid var(--trebble-primary-dark);
  color: white;
}

.disabledText {
    font-style: italic;
    color: var(--color-gray-750);
}
.capsuleCard * .itemSubtitle {
    font-size: var(--text-2xs);
    opacity: 1;
}
.capsuleCard * .itemTitle {
    font-size: var(--text-sm);
}
.formLabel,
label.formLabel {
  -ms-flex: 0 0 30px;
  -webkit-box-flex: 0;
  flex: 0 0 30px;
  -webkit-flex: 0 0 30px;
  font-size: var(--text-md);
  /* font-weight: 300; */
  padding-left: 10px;
  line-height: 30px;
  -ms-flex-align: center;
  -webkit-box-align: center;
  align-items: center;
  -webkit-align-items: center;
  height: 30px;
  /*background-color: var(--bg-light);
  color: var(--text-dark);*/
  background-color: transparent;
  color: var(--trebble-primary);
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  display: -webkit-flex;
  font-weight: 400;
  opacity: 0.5;
  margin-top: 0px;
  height: auto;
}
.capsuleCard * #capsuleLifeSpanFld-button > span,
.capsuleCard * #privacySettingFld-button > span,
.capsuleCard * #categoryFld-button > span {
    color: var(--text-dark);
}
paper-input-decorator::shadow > .underline > .unfocused-underline,
paper-input-decorator > .underline > .unfocused-underline,
[data-role=page] * .capsuleCard * paper-input-decorator::shadow > .underline > .focused-underline,
[data-role=page] * .capsuleCard * paper-input-decorator > .underline > .focused-underline,
paper-input-decorator::shadow > .underline > .focused-underline,
paper-input-decorator > .underline > .focused-underline,
[data-role=page] * .commentBoxWrapper * paper-input-decorator > .underline > .focused-underline {
  /* background-color: transparent; */
}
paper-input::shadow > paper-input-decorator::shadow > .underline > .unfocused-underline,
paper-input::shadow > paper-input-decorator > .underline > .unfocused-underline,
paper-input-decorator::shadow > .underline > .unfocused-underline{
 background-color: var(--color-gray-150);
}
paper-input > paper-input-decorator > .underline > .unfocused-underline,
paper-input > paper-input-decorator > .underline > .unfocused-underline,
paper-input-decorator > .underline > .unfocused-underline {
 background-color: var(--color-gray-150);
}

paper-input::shadow > paper-input-decorator::shadow > .underline > .focused-underline ,
[data-role=page] * .capsuleCard * paper-input-decorator::shadow > .underline > .focused-underline,
paper-input-decorator::shadow > .underline > .focused-underline,
[data-role=page] * .commentBoxWrapper * paper-input-decorator > .underline > .focused-underline {
 background-color:  var(--trebble-primary);
}
paper-input > paper-input-decorator > .underline > .focused-underline ,
[data-role=page] * .capsuleCard * paper-input-decorator > .underline > .focused-underline,
paper-input-decorator > .underline > .focused-underline,
[data-role=page] * .commentBoxWrapper * paper-input-decorator > .underline > .focused-underline {
 background-color:  var(--trebble-primary);
}
paper-input, paper-input-decorator,
#titleField::shadow  > paper-input-decorator,.input-field{
 width: 100%;
 box-sizing: border-box;
 /*padding: 0px;*/
 padding-left: 10px;
 padding-right: 10px;
 color: var(--text-dark);
}
.input-field{
 margin: 0;
 padding: 0 0.75em;
}
.formField.input-field{
    width: 100% !important;
    box-sizing: border-box;
}
.formLabel ~ .input-field{
 /* margin: 5px 0;*/
}

.ui-mobile .input-field label{
    margin: 0 0.75em;
}

input.validate,
textarea.validate,
.select-wrapper.validate{
 margin-bottom: 24px !important;
}


input:not([type]):focus:not([readonly]),
input[type=text]:not(.browser-default):focus:not([readonly]),
input[type=password]:not(.browser-default):focus:not([readonly]),
input[type=email]:not(.browser-default):focus:not([readonly]),
input[type=url]:not(.browser-default):focus:not([readonly]),
input[type=time]:not(.browser-default):focus:not([readonly]),
input[type=date]:not(.browser-default):focus:not([readonly]),
input[type=datetime]:not(.browser-default):focus:not([readonly]),
input[type=datetime-local]:not(.browser-default):focus:not([readonly]),
input[type=tel]:not(.browser-default):focus:not([readonly]),
input[type=number]:not(.browser-default):focus:not([readonly]),
input[type=search]:not(.browser-default):focus:not([readonly]),
textarea.materialize-textarea:focus:not([readonly]){
  border-bottom: var(--border-1) solid var(--trebble-primary);
  box-shadow: 0 1px 0 0 var(--trebble-primary);
}

input:not([type]),
input[type=text]:not(.browser-default),
input[type=password]:not(.browser-default),
input[type=email]:not(.browser-default),
input[type=url]:not(.browser-default),
input[type=time]:not(.browser-default),
input[type=date]:not(.browser-default),
input[type=datetime]:not(.browser-default),
input[type=datetime-local]:not(.browser-default),
input[type=tel]:not(.browser-default),
input[type=number]:not(.browser-default),
input[type=search]:not(.browser-default),
textarea.materialize-textarea {

  border-bottom: var(--border-1) solid var(--color-gray-150);
  height: 2em;
  margin: 0.75em 0;
  font-size: var(--text-base);
}

input.invalid:not([type]),
input.invalid:not([type]):focus,
input[type=text].invalid:not(.browser-default),
input[type=text].invalid:not(.browser-default):focus,
input[type=password].invalid:not(.browser-default),
input[type=password].invalid:not(.browser-default):focus,
input[type=email].invalid:not(.browser-default),
input[type=email].invalid:not(.browser-default):focus,
input[type=url].invalid:not(.browser-default),
input[type=url].invalid:not(.browser-default):focus,
input[type=time].invalid:not(.browser-default),
input[type=time].invalid:not(.browser-default):focus,
input[type=date].invalid:not(.browser-default),
input[type=date].invalid:not(.browser-default):focus,
input[type=datetime].invalid:not(.browser-default),
input[type=datetime].invalid:not(.browser-default):focus,
input[type=datetime-local].invalid:not(.browser-default),
input[type=datetime-local].invalid:not(.browser-default):focus,
input[type=tel].invalid:not(.browser-default),
input[type=tel].invalid:not(.browser-default):focus,
input[type=number].invalid:not(.browser-default),
input[type=number].invalid:not(.browser-default):focus,
input[type=search].invalid:not(.browser-default),
input[type=search].invalid:not(.browser-default):focus,
textarea.materialize-textarea.invalid,
textarea.materialize-textarea.invalid:focus,
.select-wrapper.invalid>input.select-dropdown {
  margin-bottom: 24px;
}

input.invalid:not([type]):focus,
input[type=text].invalid:not(.browser-default):focus,
input[type=password].invalid:not(.browser-default):focus,
input[type=email].invalid:not(.browser-default):focus,
input[type=url].invalid:not(.browser-default):focus,
input[type=time].invalid:not(.browser-default):focus,
input[type=date].invalid:not(.browser-default):focus,
input[type=datetime].invalid:not(.browser-default):focus,
input[type=datetime-local].invalid:not(.browser-default):focus,
input[type=tel].invalid:not(.browser-default):focus,
input[type=number].invalid:not(.browser-default):focus,
input[type=search].invalid:not(.browser-default):focus,
textarea.materialize-textarea.invalid:focus{
  border-bottom: var(--border-1) solid var(--trebble-primary);
  box-shadow: 0 1px 0 0 var(--trebble-primary);
}

input.invalid:not([type]),
input[type=text].invalid:not(.browser-default),
input[type=password].invalid:not(.browser-default),
input[type=email].invalid:not(.browser-default),
input[type=url].invalid:not(.browser-default),
input[type=time].invalid:not(.browser-default),
input[type=date].invalid:not(.browser-default),
input[type=datetime].invalid:not(.browser-default),
input[type=datetime-local].invalid:not(.browser-default),
input[type=tel].invalid:not(.browser-default),
input[type=number].invalid:not(.browser-default),
input[type=search].invalid:not(.browser-default),
textarea.materialize-textarea.invalid,
input.valid:not([type]),
input[type=text].valid:not(.browser-default),
input[type=password].valid:not(.browser-default),
input[type=email].valid:not(.browser-default),
input[type=url].valid:not(.browser-default),
input[type=time].valid:not(.browser-default),
input[type=date].valid:not(.browser-default),
input[type=datetime].valid:not(.browser-default),
input[type=datetime-local].valid:not(.browser-default),
input[type=tel].valid:not(.browser-default),
input[type=number].valid:not(.browser-default),
input[type=search].valid:not(.browser-default),
textarea.materialize-textarea.valid,
.select-wrapper.valid>input.select-dropdown {
  box-shadow:none;
  border-bottom: var(--border-1) solid var(--color-gray-150);
}


.noUi-horizontal .noUi-handle,
.noUi-target .range-label , .noUi-target.noUi-horizontal .noUi-tooltip{
    background-color: var(--trebble-primary);
}

.noUi-horizontal{
    height: 2px;
    background-color: transparent;
    border-color: transparent;
}

html:not([dir=rtl])  .noUi-horizontal .noUi-handle.noUi-handle-lower{
    border-radius: 50%;
    background: var(--trebble-primary);
    height: 12px;
    border: var(--border-0);
    box-shadow: none;
    width: 12px;
    right: -6px;
    top: -5px;
}


.noUi-handle:not(.noUi-active) .noUi-tooltip{
    display:none;
}
.noUi-handle .noUi-tooltip{
    color:white;
}
.noUi-handle:after, .noUi-handle:before{
    display: none;
}
.noUi-connect,
.noUi-base{
    height: 2px;
    box-shadow: none;
}

.noUi-touch-area{
    position: relative;
    width: 44px;
    height: 44px;
    left: -15px;
    top: -15px;
}
.noUi-base {
    cursor: pointer;
    background-color: var(--color-white-10);
}

.audio_sequencer .noUi-base{
    background-color: var(--color-black-10);
}

.tippy_tooltip_content .keyboard_shortcut{
    margin: auto;
    display:flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.tippy_tooltip_content .keyboard_shortcut{
    opacity: 0.5;
    font-size: 85%;
}
.tippy_tooltip_content .keyboard_shortcut .add_key_button{
    display: none;
}
.tippy_tooltip_content .keyboard_shortcut > .key_button{
    margin-right: 5px;
}
.tippy_tooltip_content .keyboard_shortcut .soundwave-command-apple-key{
    font-size: 80%;
}

.noUi-connect{
    background-color: var(--trebble-primary);
}

#playerFooter:not(.transparent) .noUi-base{
    background-color: var(--color-gray-150);
}

#playerFooter:not(.transparent) .noUi-base{
    background-color: var(--color-gray-150);
}
.noUi-tooltip span{
  font-size: var(--text-2xs-px);
  font-weight:bold;
  top: 0px;
}
input:not([type])+label:after, input[type=text]:not(.browser-default)+label:after, input[type=password]:not(.browser-default)+label:after, input[type=email]:not(.browser-default)+label:after, input[type=url]:not(.browser-default)+label:after, input[type=time]:not(.browser-default)+label:after, input[type=date]:not(.browser-default)+label:after, input[type=datetime]:not(.browser-default)+label:after, input[type=datetime-local]:not(.browser-default)+label:after, input[type=tel]:not(.browser-default)+label:after, input[type=number]:not(.browser-default)+label:after, input[type=search]:not(.browser-default)+label:after, textarea.materialize-textarea+label:after, .select-wrapper+label:after{
  font-size: var(--text-sm-px);
}


::-webkit-input-placeholder {
  color: var(--color-gray-600);
}

::-moz-placeholder {
  color: var(--color-gray-600);
}

:-ms-input-placeholder {
  color: var(--color-gray-600);
}

::placeholder {
  color: var(--color-gray-600);
}

[data-role=popup] ::placeholder {
    color: var(--text-light);
}

.sequencer_node_container[move_mode="true"] .sequencer_node{
    user-select: none;
}
/*
#signUpAndRecordBtn{
   background: var(--gradient-primary-alt);
   cursor: pointer;
   border: transparent;
   background-size: 200% 200%;
   animation: gradientanimation 10s ease infinite;
   animation: gradientanimation 10s ease infinite;
}


#signUpAndRecordBtn span.extensibleLabel{
    color:  white;
}

#signUpAndRecordBtn t{
    font-weight: 900;
}

#signUpAndRecordBtn t:before{
 color:  white;
 font-size: var(--text-2xl-px);
}

[data-role=header] #signUpAndRecordBtn{
    margin-right: 5px;
    margin-top: 3px;
}
*/

#signUpAndRecordBtn{
   background-color: transparent;
   cursor: pointer;
   border: var(--border-3) solid black;
   border-image-source: var(--gradient-primary-alt-reverse);
   border-image-slice: 1;
}


#signUpAndRecordBtn span.extensibleLabel{

    background: var(--gradient-primary-alt);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    background-size: 200% 200%;
    animation: gradientanimation 10s ease infinite;
    animation: gradientanimation 10s ease infinite;
    color: transparent;

}

#signUpAndRecordBtn t{
    font-weight: 900;
}

#signUpAndRecordBtn t:before{
    background: var(--gradient-primary-alt);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    background-size: 200% 200%;
    animation: gradientanimation 10s ease infinite;
    animation: gradientanimation 10s ease infinite;
    color: transparent;
    font-size: var(--text-2xl-px);

}


.sequencer_node{
    user-select:text;
    line-height: 30px;
    font-size: var(--text-base-px);
    position: relative;
    white-space: break-spaces;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    /*content-visibility: auto;
    contain-intrinsic-height: 30px;*/
}

.sequencer_paragraph{
    content-visibility: auto;
    padding-bottom: 12px;
}
.sequencer_paragraph.unlabelled_paragraph .speaker_label{
    display: none;
}

.beta_tag{
    color: black;
    background: white;
    padding: 0px 4px;
    border-radius: 4px;
    font-size: 75%;
}

.beta_tag::after{
    content:'BETA';
}

.sequencer_paragraph .speaker_label{
    font-weight: bold;
    opacity: 0.5;
    line-height: 30px;
    font-size: var(--text-base-px);
    padding-left: 4px;
    cursor: pointer;
}

.sequencer_paragraph:not(.show_summary) .paragraph_summary,
.sequencer_paragraph.show_summary .paragraph_content{
    display: none;
}

.sequencer_paragraph .paragraph_summary{
    letter-spacing: 1px;
    user-select: text;
    line-height: 30px;
    font-size: var(--text-base-px);
}

.sequencer_paragraph.show_summary .paragraph_summary,
.sequencer_paragraph:not(.show_summary) .paragraph_content{
    display: block;
}

.sequencer_node[not_continuous_on_left="true"] div.left_continuious_visual_cue{
  border-left: var(--border-1) dashed /*white*/;
  position: absolute;
  height: 20px;
  left: 1px;
  z-index: 2;
}
/*.sequencer_node.selected[not_continuous_on_left="true"] div.left_continuious_visual_cue{
    border-left-color: white;
}*/
.sequencer_node[not_continuous_on_left="true"] div.left_continuious_visual_cue .arrow-down{
    width: 0;
    height: 0;
    border-left: var(--border-3) solid transparent;
    border-right: var(--border-3) solid transparent;
    border-top: var(--border-4) solid /*white*/;
    top: 0px;
    left: -3.5px;
    position: absolute;
}

/*.sequencer_node.selected[not_continuous_on_left="true"] div.left_continuious_visual_cue .arrow-down{
    border-top: var(--border-4) solid white;
}*/

.sequencer_node[not_continuous_on_right="true"] div.right_continuious_visual_cue{
  border-right: var(--border-1) dashed var(--color-gray-750);
  position: absolute;
  height: 20px;
  right: 1px;
  display: none;
}

.sequencer_node[not_continuous_on_right="true"] div.right_continuious_visual_cue .arrow-down{
    width: 0;
    height: 0;
    border-left: var(--border-3) solid transparent;
    border-right: var(--border-3) solid transparent;
    border-top: var(--border-4) solid var(--color-gray-750);
    top: 0px;
    right: -3.5px;
    position: absolute;

}


.sequencer_node.sortable-chosen{
    cursor: grab;
}

.strikethrough-icon,
.deleted_audio_segment_node .deleted_label.strikethrough-icon.content .icon{
   text-decoration: line-through;
}

.trim_silences_context_menu_container{
    padding: 0em 1em;
    max-width: 300px;
    max-height: 500px;
}

.trim_silences_context_menu_container .container_title{
    padding: 14px;
    box-sizing: border-box;
    text-align: center;
    font-weight: 700;
}
.trim_silences_context_menu_container .container_content{
    display: flex;
    flex-direction: row;
}
.trim_silences_context_menu_container .container_content .input_description_container{
    flex: 1 1 200px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}
.trim_silences_context_menu_container .container_content .input_container{
    flex: 0 0 50px;
}

.trim_silences_context_menu_container .container_content .input_container input[type=number]::-webkit-inner-spin-button, 
.trim_silences_context_menu_container .container_content .input_container input[type=number]::-webkit-outer-spin-button {  
   opacity: 1;
}

.trim_silences_context_menu_container .container_content .input_container .ui-input-text{
    border: var(--border-0) !important;
}

.trim_silences_context_menu_container .button_bar_container button {
    margin: auto;
    background-color: var(--trebble-primary);
    color: var(--text-light);
    border: var(--border-2) solid var(--trebble-primary);
    max-width: 200px;
    box-sizing:initial;
    height:40px;
    padding: 0px;
    display:flex;
    align-items:center;
    justify-content:center;
    width: 100%;
}

.trim_silences_context_menu_container .button_bar_container button:active,
.trim_silences_context_menu_container .button_bar_container button:focus,
body.mobileweb .trim_silences_context_menu_container .button_bar_container button:hover {
    background-color: var(--trebble-primary-dark);
    border: var(--border-2) solid var(--trebble-primary-dark);
}

.audio_sequencer .bottom_toolbar > *,
.audio_sequencer .header_toolbar {
 display:flex;
 flex-direction:row;
 justify-content:center;
 align-items:center;
 padding: 4px;
}

.audio_sequencer .bottom_toolbar > .playback_progress_section{
 padding: 15px 4px;
}

.audio_sequencer:not([search_mode_on="true"]) .search_widget,
.audio_sequencer[search_mode_on="true"]:not([show_search_widget_on_left_side="true"]) .header_toolbar > button{
    display: none;
}

.audio_sequencer[search_mode_on="true"] .search_widget{

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 48px;
    width: 100%;
    max-width: 800px;

}

.audio_sequencer[hide_pauses_under_ten_milliseconds="true"] .pause_audio_segment_node.sequencer_node[duration_under_ten_milli="true"],
.audio_sequencer[hide_pauses_under_ten_milliseconds="true"] .pause_node[duration_under_ten_milli="true"]{
    display: none !important;
}

.shepherd-element  ul.browser-default{
    display: block;
    list-style-type: square;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
}
.audio_sequencer .right_section .video_canvas_container{
    align-items: center;
    justify-content: center;
    display: flex;
}
.audio_sequencer[search_mode_on="true"]:not([search_playback_on="true"]) #turnOnPlayOnSearchBtn,
.audio_sequencer[search_mode_on="true"][search_playback_on="true"] #turnOffPlayOnSearchBtn{
    display: none;
}
.audio_sequencer[do_not_show_ignored_content="true"] .deleted_audio_segment_node,
.audio_sequencer[do_not_show_gap_nodes="true"] .pause_audio_segment_node,
.audio_sequencer[do_not_show_edit_boundaries="true"] .left_continuious_visual_cue,
.audio_sequencer[do_not_show_edit_boundaries="true"] .right_continuious_visual_cue,
.audio_sequencer[do_not_display_video="true"] .right_section .video_canvas_container{
    display: none;
}
.audio_sequencer .left_continuious_visual_cue .arrow-down,
.audio_sequencer .right_continuious_visual_cue .arrow-down{
   display: none;
}

.audio_sequencer .search_widget .search_tool_bar button#playSelectionBtn,
.audio_sequencer .search_widget .search_tool_bar button#playWithoutSelection{
    width: 40px;
    height: 40px;
    align-items: center;
    display: flex;
    flex: 0 0 40px;
    justify-content: center;
}

.audio_sequencer .search_widget .search_tool_bar button#playSelectionBtn > span.pe-7s-play,
.audio_sequencer .search_widget .search_tool_bar button#playWithoutSelection > span.pe-7s-play{
    width: 100%;
    position: relative;
    font-weight: 400;
}

.audio_sequencer .search_widget .search_tool_bar button#playSelectionBtn > span.pe-7s-play > .icon,
.audio_sequencer .search_widget .search_tool_bar button#playWithoutSelection > span.pe-7s-play > .icon,
.editor-search-browse-panel button#playSelectionBtn > span.pe-7s-play > .icon,
.editor-search-browse-panel button#playWithoutSelection > span.pe-7s-play > .icon{
    position: absolute;
    font-size: 50%;
    bottom: 0px;
    right: 5px;
}

.audio_sequencer .search_widget .search_tool_bar button.button_with_label,
.editor-search-browse-panel button.button_with_label {
    width: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: white;
    color: black;
    padding: 0px 16px;
    cursor: pointer;
}

.ui-page:not(.lighttext) .audio_sequencer .search_widget .search_tool_bar button.button_with_label,
.ui-page:not(.lighttext) .editor-search-browse-panel button.button_with_label {
    background-color: var(--bg-warm);
}

body.mobileweb .ui-page.lighttext .editor-search-browse-panel button:not(.disabled):hover,
.ui-page.lighttext .editor-search-browse-panel button:not(.disabled):active{
    background-color: var(--bg-warm);
}
/*
.editor-search-browse-panel .search-result-item-card button.button_with_label{
    border: var(--border-2) solid black;
}

.ui-page:not(.lighttext)  .editor-search-browse-panel .search-result-item-card button.button_with_label{
    border: var(--border-2) solid black;
}
*/
.audio_sequencer .search_widget .search_tool_bar button.button_with_label   label,
.editor-search-browse-panel button.button_with_label   label{
    display: block;
    white-space: nowrap;
    margin: 0px;
    color: black;
    cursor: pointer;
    padding: 0px;
}

.ui-page:not(.lighttext) .audio_sequencer .search_widget .search_tool_bar button.button_with_label  label,
.ui-page:not(.lighttext) .editor-search-browse-panel button.button_with_label  label{
    color: var(--trebble-primary);
}

.audio_sequencer .search_widget .search_tool_bar button.button_with_label:disabled   label{
    cursor: pointer;
}

.audio_sequencer .search_widget .search_tool_bar  #deleteSelectionBtn > .icon{
    font-weight: 400;
    font-size: var(--text-2xl);
}
.audio_sequencer .search_widget .search_tool_bar button#playWithoutSelection > span.pe-7s-play > .ion-ios7-minus-empty{

    font-weight: 900;
    position: absolute;
    font-size: 80%;
    bottom: 0px;
    right: 5px;
    transform: rotate(45deg);

}
.audio_sequencer .search_widget .search_tool_bar  #turnOffPlayOnSearchBtn .icon{
    position: relative;
}

.audio_sequencer .search_widget .search_tool_bar #turnOffPlayOnSearchBtn .icon span{
  position: absolute;
  left: 2px;
  font-size: var(--text-7xl-px);
  top: -22px;
  transform: rotate(45deg);
  transform: rotate(40deg);

}
.audio_sequencer .search_widget .search_tool_bar .search_results span{
    padding:0px 4px;
}
.audio_sequencer .search_widget .search_tool_bar button{
    margin:0px;
}
/*.audio_sequencer[search_mode_on="true"] .search_widget #exit_search_btn{
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 auto;
    background-color: var(--color-gray-100);
}*/

.audio_sequencer[search_mode_on="true"] .search_widget #exit_search_btn{
    border: var(--border-0);
    display: flex;
    align-items: center;
    color: black;
    justify-content: center;
    height: 30px;
    width: 30px;
    flex: 0 0 auto;
    background-color: var(--color-gray-100);
}

.audio_sequencer[search_mode_on="true"] .search_widget .search_input{
    min-width: 200px;
}

.audio_sequencer[search_mode_on="true"]  .search_input{
    background-color: white;
}

.audio_sequencer[search_mode_on="true"] .search_widget #exit_search_btn label{
    display: flex;
    margin: 0px 20px;
    color: var(--color-black);
}

.audio_sequencer .search_widget .search_tool_bar,
.audio_sequencer .search_widget .search_tool_bar .search_results{

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

}

.audio_sequencer .main_section .search_widget{
    display: none;
}

@media(max-width: 500px), (max-height: 500px) and (orientation: landscape){
   .audio_sequencer .header_toolbar .search_widget .search_tool_bar{
    display: none;
}

.audio_sequencer[search_mode_on="true"] .main_section .search_widget.floating_menu{
    display: flex;
}
}

.audio_sequencer .search_widget .search_tool_bar{
    margin: 0px 10px;
    flex: 0 0 auto;
}

.audio_sequencer .search_widget button,
.editor-search-browse-panel button,
.audio_sequencer .bottom_toolbar button,
.audio_sequencer .header_toolbar button{
    border:var(--border-0);
    height: 40px;
    width: 40px;
    flex: 0 0 40px;
    display:inline-flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    margin: 4px;
    padding: 0px;
}

.audio_sequencer .bottom_toolbar button#insertBtn,
.audio_sequencer .bottom_toolbar button#wrapWithMusicBtn{
    font-size: 140%;
}

.audio_sequencer .bottom_toolbar button#saveAsClipBtn .icon.ion-paperclip{
    font-size: 120%;
}

button,
button:focus{
    background-color:transparent;
}

body.mobileweb button:not(.disabled):hover,
button:not(.disabled):active{

    background-color: var(--color-black-5);
}

.audio_editor_and_transcription_box{
    width: 100%;
}

.audio_editor_and_transcription_box .message_to_start_transcription_box_wrapper .actionButtonWrapper a{
    margin: 20px;
}

.audio_editor_and_transcription_box .message_to_start_transcription_box_wrapper .actionButtonWrapper{
    max-width: 700px;
    margin: auto;
}

.sequencer_node_container{
    background-color:white;
    padding: 10px;
    padding-bottom: 100px;
}



.createCapsulePage.lighttext.transparent .sequencer_node_container{
    background-color: var(--color-white-10);
}


.audio_sequencer .bottom_toolbar button.disabled,
.audio_sequencer .header_toolbar button.disabled,
.audio_sequencer .bottom_toolbar button.disabled:hover,
.audio_sequencer .header_toolbar button.disabled:hover,
.audio_sequencer .bottom_toolbar button.disabled:active,
.audio_sequencer .header_toolbar button.disabled:active{
    color: var(--pulse-gray);
    cursor: default;
    background-color: transparent;
}

.audio_sequencer .bottom_toolbar button.active,
.audio_sequencer .header_toolbar button.active,
.audio_sequencer .search_widget.floating_menu button.active{
    color: var(--trebble-primary);
}

.audio_sequencer .playback_progress_section #time_ellapsed,
.audio_sequencer .playback_progress_section #total_time{
    flex: 0 0 auto;
    
}
.audio_sequencer .playback_progress_section #time_ellapsed{
    margin-right: 10px;
}

.audio_sequencer .playback_progress_section #total_time{
 margin-left: 10px;
}

.audio_sequencer .playback_progress_section .playback_slider{
    flex: 1 1 auto;
}

.audio_sequencer .top_toolbar,
.audio_sequencer .bottom_toolbar{
    flex: 0 0 auto;
    display: flex;
    justify-content: center;
    flex-direction: column;
}


.audio_sequencer .main_section{
    flex: 1 1 auto;
    display: flex;
    height: calc(100% - 250px);
    position: relative;
}
.audio_sequencer .main_section .search_widget.floating_menu{
    position: absolute;
    bottom: 10px;
    left: calc(50% - 125px);
    width: 250px;
    z-index: 1;
    background: var(--color-gray-100);
}

.audio_sequencer .main_section .search_widget.floating_menu button label,
.audio_sequencer .bottom_toolbar button label,
.audio_sequencer .header_toolbar button label{
    display: none;
}

.audio_sequencer .search_widget button .icon,
.audio_sequencer .bottom_toolbar button .icon,
.audio_sequencer .header_toolbar button .icon{
    font-size: var(--text-3xl-px);
}

.audio_sequencer .header_toolbar button#showBeatifyMenuBtn .icon{
    position: relative;
}
.audio_sequencer .header_toolbar button#showBeatifyMenuBtn .icon .sub_icon{
    position: absolute;
    font-size: 40%;
    bottom: 0px;
    right: 0px;
}

.audio_sequencer .pe-7s-edit,
.createCapsulePage .pe-7s-edit{
    transform: rotate(90deg);
}

.sequencer_node .highlight_box,
.sequencer_node .search_highlight_box{
    position: absolute;
    width: 100%;
    height: 100%;
    top:0px;
    left: 0px;
    border-radius: 0px;
}

.sequencer_node.video_sequencer_node .highlight_box,
.sequencer_node.audio_sequencer_node .highlight_box,
.sequencer_node.wrap_sequencer_node_start .highlight_box,
.sequencer_node.wrap_sequencer_node_end .highlight_box{
    display: none;
}
[data-role=page].lighttext .sequencer_node_inspector .basic_inpector_param_view.audio_segment_param_view .action_btn_bar button,
body.darkTheme  .sequencer_node_inspector .basic_inpector_param_view.audio_segment_param_view .action_btn_bar button,
.c-bottom-sheet.darkTheme.active .sequencer_node_inspector .basic_inpector_param_view.audio_segment_param_view .action_btn_bar button{
    border:  var(--border-2) solid white;
    background-color: white;
}

.audio_buffer_region_selector_widget,
.audio_buffer_region_selector_widget .audio_buffer_region_selector_widget_content{
    display: flex;
    flex-direction: column;
    justify-content: center;
}


.audio_buffer_region_selector_widget:not(.loading) #loadingBoxWidget{
    display: none;
}
.audio_buffer_region_selector_widget.loading .audio_buffer_region_selector_widget_content{
    visibility: hidden;
}

.audio_buffer_waveform_minimap_info_container{
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    margin-bottom: 20px;
    padding-top: 10px;
    font-size: var(--text-xs-px);
}

[data-role="popup"] .audio_buffer_region_selector_widget{
    height: calc(100vh - 100px);
    width: calc(100% - 100px);
    margin: 50px;
}

[data-role="popup"] .audio_buffer_region_selector_widget .audio_buffer_waveform_container,
[data-role="popup"] .audio_buffer_region_selector_widget .audio_buffer_waveform_minimap_container{
    /*background-color: var(--color-white-10);*/
    background-color: white;
}

.audio_buffer_waveform_minimap_container{
    margin-top: 20px;
}

handle.wavesurfer-handle{
    background-color: transparent !important;
    width: 16px !important;
}

handle.wavesurfer-handle handle-bar{
    background-color: var(--trebble-primary);
    height: 100%;
    width: 8px;
    position: absolute;
}

handle.wavesurfer-handle handle-delimiter-up{
    position: absolute;
    background-color: var(--trebble-primary);
    height: 8px;
    width: 16px;
    top: 0px;
}

handle.wavesurfer-handle handle-delimiter-down{
    position: absolute;
    background-color: var(--trebble-primary);
    height: 8px;
    width: 16px;
    bottom: 0px;
}

handle.wavesurfer-handle.wavesurfer-handle-start handle-bar,
handle.wavesurfer-handle.wavesurfer-handle-start handle-delimiter-up,
handle.wavesurfer-handle.wavesurfer-handle-start handle-delimiter-down{
    left: 0px;
}

handle.wavesurfer-handle.wavesurfer-handle-end handle-bar,
handle.wavesurfer-handle.wavesurfer-handle-end handle-delimiter-up,
handle.wavesurfer-handle.wavesurfer-handle-end handle-delimiter-down{
    right: 0px;
}

.audio_buffer_waveform_container region.wavesurfer-region{

}
.audio_buffer_region_selector_widget .bottom_button_toolbar button .icon,
#decreaseZoomBtn .icon,
#increaseZoomBtn .icon{
    font-size: var(--text-3xl-px);
    position: relative;
}

body.mobileweb .audio_buffer_region_selector_widget .bottom_button_toolbar button:not(.disabled):hover,
.audio_buffer_region_selector_widget .bottom_button_toolbar button:not(.disabled):active{
    background-color: var(--color-white-10);
}

#decreaseZoomBtn .icon  span,
#increaseZoomBtn .icon  span{
    position: absolute;
    font-size: var(--text-2xs-px);
    left: 5px;
    top: 9px;
}

.bottom_button_toolbar button{
    width: 45px;
    height: 45px;
    outline: none;
    border: var(--border-3) solid transparent;
    margin: 0px 20px;
    display: flex;
    flex: 0 0 36px;
    justify-content: center;
    align-items: center;
    color: white;
    background-color: transparent;

}
.bottom_button_toolbar button label{
    display: none;
}

.audio_buffer_region_selector_widget #saveTrimBtn{

    background-color: var(--trebble-primary);
    border: var(--border-3) solid var(--trebble-primary);
    color: var(--trebble-primary);
    max-width: 400px;
    padding: 0px 40px;
    margin: 20px auto;
    display: flex;
    font-weight: bold;
    height: 45px;
    align-items: center;
    justify-content: center;
    min-width: 200px;
    box-sizing: border-box;

}

.audio_buffer_region_selector_widget #saveTrimBtn .icon{
    display: none;
}

.audio_buffer_region_selector_widget #saveTrimBtn label{
    font-weight: bold;
    text-transform: uppercase;
    padding: 0px;
    margin: 0px;
}

.audio_buffer_region_selector_widget .trim_info{

    display: flex;
    flex-direction: row;
    margin: 20px auto;
    align-items: center;
    justify-content: center;

}

.audio_buffer_region_selector_widget .trim_info .trim_info_details{
    display: flex;
    margin: 0px 10px;
    flex-direction: row;
}

.audio_buffer_region_selector_widget .trim_info .trim_info_details .trim_from_info,
.audio_buffer_region_selector_widget .trim_info .trim_info_details .trim_to_info{
    font-weight: bold;
}

.audio_buffer_region_selector_widget .trim_info .to_label{
    margin: 0px 10px;
}



.audio_buffer_region_selector_widget.region_playing #playRegionBtn,
.audio_buffer_region_selector_widget:not(.region_playing) #pauseRegionBtn{
    display: none;
}

.audio_buffer_region_selector_widget .top_button_toolbar{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}

.audio_buffer_region_selector_widget .bottom_button_toolbar{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.sequencer_node.video_sequencer_node.hover_highlighted .highlight_box,
.sequencer_node.audio_sequencer_node.hover_highlighted .highlight_box,
.sequencer_node.wrap_sequencer_node_start.hover_highlighted .highlight_box,
.sequencer_node.wrap_sequencer_node_end.with_drag_icon.hover_highlighted .highlight_box,
/*.sequencer_node.selected ~ .sequencer_node.audio_sequencer_node.selected .highlight_box,*/
/*.sequencer_node.audio_sequencer_node.selected .highlight_box,
.sequencer_node.wrap_sequencer_node_start.selected .highlight_box,
.sequencer_node.wrap_sequencer_node_end.selected .highlight_box,*/
.sequencer_node.video_sequencer_node.highlighted .highlight_box,
.sequencer_node.audio_sequencer_node.highlighted .highlight_box,
.sequencer_node.wrap_sequencer_node_start.highlighted .highlight_box,
.sequencer_node.wrap_sequencer_node_end.with_drag_icon.highlighted .highlight_box{
    /*height: 31px;*/
    height: 30px;
    width: 34px;
    top: -1px;
    /*left: -4px;*/
    left: -4px;
    display: block;
}

.shepherd-enabled.shepherd-element.sequencer-onboarding-popup .shepherd-arrow:before{
    background-color: var(--color-purple-400);
}

.shepherd-enabled.shepherd-element.sequencer-onboarding-popup.shepherd-has-title[data-popper-placement^=bottom] > .shepherd-arrow:before{
    background-color: var(--color-purple-400);
}

.shepherd-enabled.shepherd-element.sequencer-onboarding-popup,
.shepherd-enabled.shepherd-element.sequencer-onboarding-popup .shepherd-header,
.shepherd-enabled.shepherd-element.sequencer-onboarding-popup .shepherd-title{
    background-color: var(--color-purple-400);/*var(--trebble-purple-active);*/
    color: white;
}

.shepherd-enabled.shepherd-element.sequencer-onboarding-popup[data-popper-reference-hidden]:not(.shepherd-centered){
    opacity: 1;
    pointer-events: auto;
    visibility: visible;
}



.shepherd-enabled.shepherd-element.sequencer-onboarding-popup .shepherd-header .shepherd-title{
    font-weight: bold;
    font-size: 115%;
    width: 100%;
}

.shepherd-enabled.shepherd-element.sequencer-onboarding-popup .shepherd-button.shepherd-button-secondary{
    background-color: transparent;
    color: white;
    font-weight: bold;
}
.shepherd-enabled.shepherd-element.sequencer-onboarding-popup.shepherd-has-title .shepherd-content .shepherd-cancel-icon,
.shepherd-enabled.shepherd-element.sequencer-onboarding-popup .shepherd-text{
    color: white;
}
.shepherd-button,
.shepherd-enabled.shepherd-element.sequencer-onboarding-popup .shepherd-button{
  
  background-color: transparent;
  border-radius: 0px;
  color: white;
  font-weight: bold;
  font-size: 110%;

}
.shepherd-enabled.shepherd-element.sequencer-onboarding-popup .shepherd-button:not(.shepherd-button-secondary){
    background-color: var(--color-white-20);
}

.shepherd-enabled.shepherd-element.sequencer-onboarding-popup.shepherd-has-title .shepherd-content .shepherd-cancel-icon:hover,
.shepherd-enabled.shepherd-element.sequencer-onboarding-popup .shepherd-button.shepherd-button-secondary:hover{
    background-color: var(--color-white-20);
    cursor: pointer;
}
.shepherd-enabled.shepherd-element.sequencer-onboarding-popup .shepherd-button:hover{
  background-color: var(--color-white-40);
  cursor: pointer;
}

#audioEditorDemoPage .sequencer_node.wrap_sequencer_node_end.with_drag_icon.hover_highlighted .highlight_box,
#audioEditorDemoPage .sequencer_node.wrap_sequencer_node_end.with_drag_icon.highlighted .highlight_box{
    top: 0px;
}
.sequencer_node.wrap_sequencer_node_start.with_drag_icon.hover_highlighted .highlight_box,
.sequencer_node.wrap_sequencer_node_end.with_drag_icon.hover_highlighted .highlight_box,
.sequencer_node.wrap_sequencer_node_start.with_drag_icon.selected .highlight_box,
.sequencer_node.wrap_sequencer_node_end.with_drag_icon.selected .highlight_box{
    width: 44px;
}
.sequencer_node.wrap_sequencer_node_start.with_drag_icon,
.sequencer_node.wrap_sequencer_node_end.with_drag_icon{
    width: 34px;
}
.sequencer_node.wrap_sequencer_node_end.with_drag_icon{
    top: -2px;
}

#audioEditorDemoPage .sequencer_node.wrap_sequencer_node_end.with_drag_icon{
    top: -4px;   
}
/*deprecated*/
.sequencer_node.wrap_sequencer_node_end.hover_highlighted:not(.with_drag_icon) .highlight_box,
.sequencer_node.wrap_sequencer_node_end.highlighted:not(.with_drag_icon) .highlight_box{
    height: 30px;
    width: 32px;
    top: -0px;
    left: -4px;
    display: block;
}

.sequencer_node_container{
    text-align: left;
    overflow: auto;
}

.sequencer_node_inspector,
.sequencer_settings{
    width: calc(100% - 40px);
    margin: 20px;
    height: calc(100% - 40px);
    /*max-width: 400px;*/
    box-sizing: border-box;
}

[data-role=popup] .sequencer_settings,
.c-bottom-sheet.darkTheme.active .sequencer_settings{
    height: 100%;
    width: 100%;
    margin: 0px;
}

[data-role=popup] .sequencer_settings .settingSection .settingItemTitle{
    padding:10px 0px;
}

.sequencer_settings .settingSection .sectionTitle{
    /*color: var(--color-black);
    font-weight: 700;*/
}
.sequencer_node_inspector .basic_inpector_param_view:last-child, 
.sequencer_node_inspector .group_params:last-child{
    border-bottom: var(--border-0);
}

body:not(.darkTheme) .sequencer_node_inspector .sequencer_node_inpestor_details,
body:not(.darkTheme) .sequencer_settings .sequencer_settings_details{
    height: 100%;
    overflow: auto;
    border: var(--border-1) solid var(--color-gray-150);
}

.sequencer_settings .sequencer_settings_details > *{
    padding: 0px 10px;
    box-sizing: border-box;
    text-align: left;
    
}

.sequencer_settings .sequencer_settings_details > .settings_panel_title_container{
    text-align: center;
    font-weight: 700;
}
.sequencer_settings_details .settings_panel_title_container{
    border-bottom: var(--border-1) solid var(--color-gray-150);
    padding: 14px;
    box-sizing: border-box;
}

.sequencer_node_inspector .sequencer_node_inpestor_details .infoBoxWrapper {
    opacity: 0.7;
}

.sequencer_node_inspector .sequencer_node_inpestor_details .infoBoxWrapper  .content{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.sequencer_node_inspector .sequencer_node_inpestor_details .infoBoxWrapper  .content .iconWrapper{
    display: flex;
    font-size: var(--text-7xl-px);
}
.sequencer_node_inpestor_details.no_element_selected_inpector_container .params_list,
.sequencer_node_inpestor_details.no_element_selected_inpector_container .params_list > div,
.sequencer_node_inpestor_details.multiple_elements_selected_inpector_container .params_list,
.sequencer_node_inpestor_details.multiple_elements_selected_inpector_container .params_list > div,
.sequencer_node_inpestor_details.deleted_content_inpector_container .params_list,
.sequencer_node_inpestor_details.deleted_content_inpector_container .params_list > div{
    height: 100%;
} 

.sequencer_node_inspector .sequencer_node_inpestor_details   .infoBoxWrapper > .content > .message{
    font-size: var(--text-lg); 
}

.sequencer_node_inspector .basic_inpector_param_view{
    display: flex;
    flex-direction: row;
    width: 100%;
}
.sequencer_node_inspector .basic_inpector_param_view.two_rows{
    flex-direction: column;
}
.sequencer_node_inspector .basic_inpector_param_view.two_rows .first_row,
.sequencer_node_inspector .basic_inpector_param_view.two_rows .second_row{
  display: flex;
  flex-direction: row;
}

.sequencer_node_inspector .basic_inpector_param_view.two_rows .first_row{
    padding-bottom: 10px;
}

.sequencer_node_inspector .basic_inpector_param_view.two_rows .first_row .param_label{
    flex: 1 1 auto;
    box-sizing: border-box;
    padding-right: 10px;
}

.sequencer_node_inspector .slider_param_view .value_container .slide_value{
    min-width: 30px;
}

.sequencer_node_inspector .basic_inpector_param_view.two_rows .first_row .value_container{
    flex: 0 0 auto;
    width: auto;
    padding-left: 10px;
    box-sizing: border-box;
}

.sequencer_node_inspector .basic_inpector_param_view.two_rows .second_row .value_container {
    display: flex;width: 100%;
    flex-direction: row;
}

.sequencer_node_inspector .basic_inpector_param_view.two_rows.duration_param_view .second_row .value_container .min_value,
.sequencer_node_inspector .basic_inpector_param_view.two_rows.duration_param_view .second_row .value_container .max_value{
    flex: 0 0 auto;
}

.sequencer_node_inspector .basic_inpector_param_view.two_rows.duration_param_view .second_row .value_container .min_value{
    margin-right: 10px;
}

.sequencer_node_inspector .basic_inpector_param_view.two_rows.duration_param_view .second_row .value_container .max_value{
    margin-left: 10px;
}

.sequencer_node_inspector .basic_inpector_param_view.two_rows.duration_param_view .second_row .value_container .slider_container{
    flex: 1 1 100%;
}

.sequencer_node_inspector .basic_inpector_param_view.audio_segment_param_view{
    flex-direction: column;
    margin: 5px 0px;
}

.sequencer_node_inspector .basic_inpector_param_view.audio_segment_param_view[disable_audio_source_change=true] .audio_file_info,
.sequencer_node_inspector .basic_inpector_param_view.audio_segment_param_view[disable_reset_trim=true] #resetTrimBtn{
    display: none;
}

.sequencer_node_inspector .basic_inpector_param_view.audio_segment_param_view .param_label{
    margin: 5px 0px;
}

.sequencer_node_inspector .basic_inpector_param_view.audio_segment_param_view .audio_file_info,
.sequencer_node_inspector .basic_inpector_param_view.audio_segment_param_view .start_info,
.sequencer_node_inspector .basic_inpector_param_view.audio_segment_param_view .end_info{
    display: flex;
    flex-direction: row;
    margin: 5px 0px;
}

.sequencer_node_inspector .basic_inpector_param_view.audio_segment_param_view .action_btn_bar button,
.sequencer_node_default_context_menu button#correctBtn{
    display: flex;
    flex-direction: row;
    padding: 0px 20px;
    min-width: 100px;
    justify-content: center;
    align-items: center;
    margin: 5px 0px;
    width: fit-content;
    border:  var(--border-2) solid var(--color-black);
    outline: none;
}

.sequencer_node_default_context_menu button#correctBtn{
    color: var(--text-light);
    background-color: var(--trebble-primary);
    border-color: var(--trebble-primary);
    padding-right: 0px;
    padding-left: 0px;
}

.sequencer_node_default_context_menu button#correctBtn label{
    margin: 0px;
    color: var(--text-light);
}

.sequencer_node_default_context_menu #wrapWithMusicBtn .icon.pe-7s-music,
.sequencer_node_default_context_menu #insertBtn .icon.pe-7s-music,
.always_displayed_context_menu #wrapWithMusicBtn .icon.pe-7s-music,
.always_displayed_context_menu #insertBtn .icon.pe-7s-music{
    position: relative;
    width: 36px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.sequencer_node_default_context_menu #wrapWithMusicBtn .icon.pe-7s-music,
.sequencer_node_default_context_menu #insertBtn .icon.pe-7s-music{
    font-size: var(--text-md-px);
}

.always_displayed_context_menu #wrapWithMusicBtn .icon.pe-7s-music,
.always_displayed_context_menu #insertBtn .icon.pe-7s-music{
    font-size: var(--text-xl-px);

}
.sequencer_node_default_context_menu #wrapWithMusicBtn .icon.pe-7s-music .ion-navicon,
.always_displayed_context_menu #wrapWithMusicBtn .icon.pe-7s-music .ion-navicon{
    position: absolute;
    top: 18px;
    font-size: var(--text-base-px);
    left: 22px;
}

.sequencer_node_default_context_menu #insertBtn .icon.pe-7s-music .ion-plus,
.always_displayed_context_menu #insertBtn .icon.pe-7s-music .ion-plus{
    color: var(--text-dark);
    background-color: var(--color-gray-300);
    position: absolute;
    top: 22px;
    font-size: var(--text-xs-px);
    left: 20px;
    display: flex;
    height: 12px;
    justify-content: center;
    align-items: center;
    width: 12px;
    border-radius: 50%;
}

.sequencer_node_inspector .basic_inpector_param_view.audio_segment_param_view .action_btn_bar button#resetTrimBtn .icon.pe-7s-refresh{
    transform:  scaleX(-1) rotate(45deg);
}

.sequencer_node_inspector .basic_inpector_param_view.audio_segment_param_view .action_btn_bar button .icon{
    font-size: var(--text-xl-px);
}

.sequencer_node_inspector .basic_inpector_param_view.audio_segment_param_view .action_btn_bar button label{
    color: var(--color-black);
    margin: 0px 10px;
    font-size: var(--text-sm-px);
}

[data-role=page].lighttext  .sequencer_node_inspector .basic_inpector_param_view.audio_segment_param_view .action_btn_bar button .icon,
[data-role=page].lighttext  .sequencer_node_inspector .basic_inpector_param_view.audio_segment_param_view .action_btn_bar button label,
body.darkTheme .sequencer_node_inspector .basic_inpector_param_view.audio_segment_param_view .action_btn_bar button .icon,
body.darkTheme .sequencer_node_inspector .basic_inpector_param_view.audio_segment_param_view .action_btn_bar button label,
.c-bottom-sheet.darkTheme.active  .sequencer_node_inspector .basic_inpector_param_view.audio_segment_param_view .action_btn_bar button .icon,
.c-bottom-sheet.darkTheme.active  .sequencer_node_inspector .basic_inpector_param_view.audio_segment_param_view .action_btn_bar button label{
    color: var(--color-black);
}

.sequencer_node_inspector .basic_inpector_param_view.audio_segment_param_view .action_btn_bar{
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    padding: 10px 0px;
    align-items: flex-end;
}
.c-bottom-sheet .sequencer_node_inspector{
    width: 100%;
}

.sequencer_node_inspector .basic_inpector_param_view,
.sequencer_node_inspector .group_params{
    border-bottom: var(--border-1) solid var(--color-gray-150);
    padding: 14px;
    box-sizing: border-box;
}

.sequencer_node_inspector .group_params .basic_inpector_param_view{
    border-bottom: var(--border-0);
    padding: 10px 0px;
}



.sequencer_node_inspector .group_params .basic_inpector_param_view{
    border-bottom: var(--border-0);
}


.sequencer_node_inspector .basic_inpector_param_view .param_label{
    flex: 0 0 37%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    text-align: start;
    opacity: 0.5;
}


.sequencer_node_inspector .title_param_view .value_container.title_label{
    font-weight: bold;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.sequencer_node_inspector .basic_inpector_param_view .value_container{
    flex: 1 1 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 70%;
}

.sequencer_node_inspector .basic_inpector_param_view .value_container.custom_obj_value,
.sequencer_node_inspector .basic_inpector_param_view.audio_segment_param_view .value_container.audio_source_value{
    width: 70%;
}


.sequencer_node_inspector .basic_inpector_param_view  .ui-input-text{
    width: 70px;
    height: 40px;
    display: flex;
    justify-content:center;
    align-items:center;
    padding: 4px;
}

.sequencer_node_inspector .basic_inpector_param_view  .duration_value .ui-input-text::after{
    content : "s";
}

.sequencer_node_inspector .basic_inpector_param_view  .value_container.custom_obj_value .edit_btn,
.sequencer_node_inspector .basic_inpector_param_view.audio_segment_param_view  .value_container.audio_source_value .edit_btn{
    font-size: var(--text-xl-px);
    margin-left: 20px;
    cursor: pointer;
}

.sequencer_node_inspector .basic_inpector_param_view  .value_container.custom_obj_value .custom_obj_string_value,
.sequencer_node_inspector .basic_inpector_param_view.audio_segment_param_view  .value_container.audio_source_value .audio_source_string_value{
    /*opacity: 0.5;*/
}

.sequencer_node_inspector .basic_inpector_param_view  .value_container.custom_obj_value .custom_obj_string_value,
.sequencer_node_inspector .basic_inpector_param_view.audio_segment_param_view  .value_container.audio_source_value .audio_source_string_value{
    display: -webkit-box;
    -webkit-line-clamp: 2;
    height: 40px;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-align: end;
}

body.mobileweb .sequencer_node_inspector .basic_inpector_param_view  .value_container.custom_obj_value .edit_btn:hover,
.sequencer_node_inspector .basic_inpector_param_view  .value_container.custom_obj_value .edit_btn:active,
body.mobileweb .sequencer_node_inspector .basic_inpector_param_view.audio_segment_param_view  .value_container.audio_source_value .edit_btn:hover,
.sequencer_node_inspector .basic_inpector_param_view.audio_segment_param_view  .value_container.audio_source_value .edit_btn:active{
    color: var(--trebble-primary);
}

.sequencer_node_inspector .group_params_title{
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.audio_sequencer .playback_button_section button,
.audio_sequencer .playback_button_section button:focus,
body.mobileweb .audio_sequencer .playback_button_section button:hover,
.audio_sequencer .playback_button_section button:active{
    height: 50px;
    width: 50px;
    flex: 0 0 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: var(--text-3xl-px);
    border-radius: 50%;
    box-sizing: border-box;
    color: white;
    background-color: var(--trebble-primary);
}

.sequencer_node_inspector .slider_param_view .value_container{
    display: flex;
    justify-content: center;
    align-items: center;
}

.sequencer_node_inspector .slider_param_view .value_container .slider_container{
    flex: 1 1 100%;
    margin: 0px 10px;
}

.audio_sequencer .sequencer_node_inspector_container{
    width: 100%;
}
.audio_sequencer #playSequencerBtn,
.audio_sequencer #loading_indicator,
.audio_sequencer #stopSequencerBtn{
    display: none;
}
.audio_sequencer #playSequencerBtn:before{
    padding-left: 6px;
}
.audio_sequencer{
    display: flex;
    flex-direction: column;
}

.audio_sequencer[show_sequencer_node_inspector="true"] .right_section,
.audio_sequencer[show_sequencer_settings="true"] .left_section{
    /*flex: 0 0 35%;*/
    flex: 0 0 310px;
    display: flex;
}

.audio_sequencer[show_sequencer_node_inspector="true"] .right_section{
    flex-direction: column;
    overflow: auto;
}

.audio_sequencer[show_sequencer_node_inspector="true"][do_not_display_video="true"]  .right_section{
    flex-direction: row;
}

.audio_sequencer[show_sequencer_settings="true"] .left_section{
    flex-direction: row;
}

.audio_sequencer:not([show_sequencer_node_inspector="true"]) .right_section,
.audio_sequencer:not([show_sequencer_settings="true"]) .left_section{
    display: none;
}

.audio_sequencer .left_section .sequencer_settings_container{
    height: 100%;
}

@media(max-width: 500px), (max-height: 500px) and (orientation: landscape){
    .audio_sequencer[show_sequencer_node_inspector="true"] .right_section,
    .audio_sequencer[show_sequencer_settings="true"] .left_section{
        display: none;
    }
}

@media(min-width: 500px), (min-height: 500px) and (orientation: landscape){
   .audio_sequencer .always_displayed_context_menu{
    display: none;
}

}


.audio_sequencer[show_sequencer_node_inspector="true"] .right_section  .right_side_toolbar{
    flex: 0 0 60px;
    display: none;
}


.audio_sequencer[show_sequencer_node_inspector="true"] .main_section,
.audio_sequencer[show_sequencer_settings="true"] .main_section{
    display: flex; 
    flex-direction: row;
}

.audio_sequencer[show_sequencer_node_inspector="true"] .main_section .sequencer_node_container,
.audio_sequencer[show_sequencer_settings="true"] .main_section .sequencer_node_container{
    flex: 1 1 100%;
}

.audio_sequencer[transcribedAudioBeingAdded="true"] .main_section .sequencer_node_container{
    opacity: 0.5;
}

.audio_sequencer  .main_section > #loadingImg{
    position: absolute;
    left: calc(50% - (67px)/2 - (330px)/2);
    top: calc(50% - (67px/2));
}

@media (max-width: 500px),(max-height:500px) and (orientation:landscape) {
    .audio_sequencer .main_section > #loadingImg{
        left: calc(50% - (100px)/ 2);
    }
}

.audio_sequencer:not([transcribedAudioBeingAdded="true"])  .main_section >  #loadingImg{
    display: none;
}



.audio_sequencer[rendering_being_created="false"]:not([rendering_buffer_playing="true"]):not([rendered_buffer_playback_loading="true"]):not([is_there_pending_render_request="true"]) #playSequencerBtn,
.audio_sequencer[rendering_being_created="true"] #loading_indicator,
.audio_sequencer[is_there_pending_render_request="true"] #loading_indicator,
.audio_sequencer[rendered_buffer_playback_loading="true"] #loading_indicator,
.audio_sequencer[rendering_being_created="false"][rendering_buffer_playing="true"]:not([rendered_buffer_playback_loading="true"])  #stopSequencerBtn{
    display: flex;
}

[data-role=popup] .sequencer_node_inspector{
    width: calc(100% - 50px);
    margin: 40px 25px;
    max-width: 700px;
    height: fit-content;
}

[data-role=popup] .sequencer_node_inspector input{
    color: white;
}

/*.sequencer_node.selected .highlight_box{
    background: var(--trebble-primary);
    color: white;
    border-radius: 0px;
}*/

.sequencer_node.selected{
    background: var(--trebble-primary);
    color: white;
}

.sequencer_node.being_processed{
    animation: trebble_flash linear 2s infinite;
	animation: trebble_flash linear 2s infinite;
}

.sequencer_node.hover_highlighted .highlight_box{
    background: var(--gradient-pink-light);
    color: white;
    border-radius: 0px;
    z-index: 2;
}

.sequencer_node.search_highlighted .search_highlight_box{
 background: var(--color-warning-bright);
 border-radius: 0;
 color: var(--color-black);
 /*opacity: 0.7;*/
 /*width: calc(100% - 6px);*/
 left: 3px;
 z-index: 1;
}
.sequencer_node.pause_audio_segment_node.search_highlighted .search_highlight_box{
    left: 0px;
}
.sequencer_node.search_highlighted.selected .highlight_box{
    background-color: var(--trebble-primary);
    z-index: 2;
}

.sequencer_node.search_highlighted:not(.selected):not(.playing) .content,
.deleted_audio_segment_node.search_highlighted:not(.selected) .deleted_label,
.deleted_audio_segment_node.search_highlighted:not(.selected) .undo_deletion_icon{
    color: black
}

.paragraph_start_delimitation,
.paragraph_end_delimitation{
    height: 30px;
    width: 40px;
    line-height: 30px;
    display: inline-flex;
}

.paragraph_start_delimitation{
    display: none;
}

.sequencer_node.node_drag_over .highlight_box,
fullstop.sequencer_node.node_drag_over,
.paragraph_start_delimitation.node_drag_over,
.paragraph_end_delimitation.node_drag_over{
 border-left: var(--border-4) solid black;
}

.sequencer_node.node_drag_over_after .highlight_box,
fullstop.sequencer_node.node_drag_over_after,
.paragraph_start_delimitation.node_drag_over_after,
.paragraph_end_delimitation.node_drag_over_after{
 border-right: var(--border-4) solid black;
}

body.darkTheme [data-role=page]:not(.lighttext) .sequencer_node.node_drag_over .highlight_box,
body.darkTheme [data-role=page]:not(.lighttext) fullstop.sequencer_node.node_drag_over,
body.darkTheme [data-role=page]:not(.lighttext) .paragraph_start_delimitation.node_drag_over,
body.darkTheme [data-role=page]:not(.lighttext) .paragraph_end_delimitation.node_drag_over{
 border-left: var(--border-4) solid white;
}

body.darkTheme [data-role=page]:not(.lighttext) .sequencer_node.node_drag_over_after .highlight_box,
body.darkTheme [data-role=page]:not(.lighttext) fullstop.sequencer_node.node_drag_over_after,
body.darkTheme [data-role=page]:not(.lighttext) .paragraph_start_delimitation.node_drag_over_after,
body.darkTheme [data-role=page]:not(.lighttext) .paragraph_end_delimitation.node_drag_over_after{
 border-right: var(--border-4) solid white;
}

.sequencer_node.node_drag_over.wrap_sequencer_node_start .highlight_box,
.sequencer_node.node_drag_over.wrap_sequencer_node_end .highlight_box,
.sequencer_node.node_drag_over.audio_sequencer_node .highlight_box,
.sequencer_node.node_drag_over.video_sequencer_node .highlight_box{
    display: block;
    left: -6px;
    z-index: 2;
}

.sequencer_node.node_drag_over_after.wrap_sequencer_node_start .highlight_box,
.sequencer_node.node_drag_over_after.wrap_sequencer_node_end .highlight_box,
.sequencer_node.node_drag_over_after.audio_sequencer_node .highlight_box,
.sequencer_node.node_drag_over_after.video_sequencer_node .highlight_box{
    display: block;
    right: -6px;
    z-index: 2;
}

.sequencer_node.node_drag_at_the_end .highlight_box{
 border-right: var(--border-4) solid black;
}

[data-role=page].lighttext .sequencer_node.node_drag_over .highlight_box,
[data-role=page].lighttext fullstop.sequencer_node.node_drag_over,
[data-role=page].lighttext .paragraph_start_delimitation.node_drag_over,
[data-role=page].lighttext .paragraph_end_delimitation.node_drag_over{
 border-left: var(--border-4) solid white;
}

[data-role=page].lighttext .sequencer_node.node_drag_over_after .highlight_box,
[data-role=page].lighttext fullstop.sequencer_node.node_drag_over_after,
[data-role=page].lighttext .paragraph_start_delimitation.node_drag_over_after,
[data-role=page].lighttext .paragraph_end_delimitation.node_drag_over_after{
 border-right: var(--border-4) solid white;
}

[data-role=page].lighttext .sequencer_node.node_drag_at_the_end .highlight_box,
[data-role=page].lighttext fullstop.sequencer_node.node_drag_at_the_end,
[data-role=page].lighttext .paragraph_start_delimitation.node_drag_at_the_end,
[data-role=page].lighttext .paragraph_end_delimitation.node_drag_at_the_end{
 border-right: var(--border-4) solid white;
}

[data-role=page].lighttext .sequencer_node_inspector .sequencer_node_inpestor_details,
[data-role=page].lighttext .sequencer_settings .sequencer_settings_details,
body.darkTheme .sequencer_node_inspector .sequencer_node_inpestor_details,
.c-bottom-sheet.darkTheme.active .sequencer_node_inspector .sequencer_node_inpestor_details,
.c-bottom-sheet.darkTheme.active .sequencer_settings .sequencer_settings_details{
    border: var(--border-1) solid var(--color-white-20);
}

body [data-role=popup] .sequencer_settings .sequencer_settings_details,
[data-role=page].lighttext.ui-page-active  ~ div [data-role=popup] .sequencer_settings .sequencer_settings_details,
.c-bottom-sheet.darkTheme.active .sequencer_settings .sequencer_settings_details{
    border: var(--border-0);
}

body:not(.darkTheme) [data-role=page].lighttext .sequencer_node_inspector .basic_inpector_param_view, 
body.darkTheme .sequencer_node_inspector .basic_inpector_param_view, 
body:not(.darkTheme) [data-role=page].lighttext .sequencer_settings_details .settings_panel_title_container,
body:not(.darkTheme) [data-role=page].lighttext.ui-page-active ~ div [data-role=popup] .sequencer_settings_details .settings_panel_title_container,
body.darkTheme [data-role=popup] .sequencer_settings_details .settings_panel_title_container,
.c-bottom-sheet.darkTheme.active .sequencer_node_inspector .basic_inpector_param_view, 
.c-bottom-sheet.darkTheme.active .sequencer_node_inspector .group_params
.c-bottom-sheet.darkTheme.active .sequencer_settings_details .settings_panel_title_container,
.c-bottom-sheet.darkTheme.active .sequencer_settings_details .settings_panel_title_container,
body:not(.darkTheme) [data-role=page].lighttext .sequencer_node_inspector .group_params,
body.darkTheme  .sequencer_node_inspector .group_params{
    border-bottom: var(--border-1) solid var(--color-white-20);
}

.sequencer_node.playing, 
/*.sequencer_node.selected .content,*/
.sequencer_node.hover_highlighted /*.content*/{
    color : white;
}

.sequencer_node.playing .highlight_box{
 animation: medium_pulse 0.2s;
 background: var(--trebble-purple-active);
 border-radius: 2px;
 z-index: 2;
}

.sequencer_node.playing .icon,
.sequencer_node.playing .marker,
.audio_sequencer_node.playing .icon, 
.video_sequencer_node.playing .icon, 
.wrap_sequencer_node_end.with_drag_icon.playing .icon,
.wrap_sequencer_node_start.playing .icon{
    background: var(--trebble-purple-active) !important;
    
}


.sequencer_node.playing .marker{
    color:var(--trebble-purple-active);
}

.sequencer_node .content{
    z-index: 3;
}

.wrap_sequencer_node_start .icon,
.wrap_sequencer_node_end.with_drag_icon .icon,
.audio_sequencer_node .icon,
.video_sequencer_node .icon{
    height: 24px;
    width: 24px;
    justify-content: center;
    align-items: center;
    display: inline-flex;
    background-color: var(--color-gray-350);
    color: var(--text-light);
    border-radius: 3px;
    z-index: 1;
} 
.wrap_sequencer_node_start.with_drag_icon .icon.music_icon,
.wrap_sequencer_node_end.with_drag_icon .icon.drag_icon{

    padding: 0px 2px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;

}
.wrap_sequencer_node_start.with_drag_icon .icon.drag_icon,
.wrap_sequencer_node_end.with_drag_icon .icon.music_icon{
    border-top-left-radius: 0px;
    padding: 0px 2px;
    border-bottom-left-radius: 0px;

}

.wrap_sequencer_node_start.with_drag_icon .icon.drag_icon,
.wrap_sequencer_node_end.with_drag_icon .icon.drag_icon{
    border-radius: 0px;
}

.wrap_sequencer_node_start.with_drag_icon .icon.drag_icon,
.wrap_sequencer_node_end.with_drag_icon .icon.drag_icon{
    font-size: 70%;
}
/*
.sequencer_node .marker{
    width: 7px;
    height: 0px;
    margin-right: 2px;
    border-radius: 2px;
    background-color: var(--color-gray-350);
    color: var(--color-gray-350);
    display: inline-table;
    }*/

    .sequencer_node .marker{
        width: 100%;
        height: 24px;
        background-color: var(--color-gray-350);
        color: var(--color-gray-350);
        display: inline;
        border-radius: 2px;
        z-index: 1;
    }
    .wrap_sequencer_node_end:not(.with_drag_icon) {
        height: 24px;
        width: 24px;
        flex: 0 0 24px;
    }

    .wrap_sequencer_node_end{
      /*margin-right: 8px;
      margin-left: 8px;*/
  }
  .wrap_sequencer_node_start,
  .wrap_sequencer_node_end,
  .audio_sequencer_node,
  .video_sequencer_node{
      display: inline-flex;
      margin: 0px 4px;
      border-radius: 3px;
  }

  .wrap_sequencer_node_start,
  .wrap_sequencer_node_end.with_drag_icon,
  .audio_sequencer_node,
  .video_sequencer_node{
      width: 24px;
  }


  .end_music_wrap_audio_segment_node .space_holder{
    width: 10px;
    height: 0px;
    display: none;
}

.pause_audio_segment_node,
#pause_icons_display_container{
    /*white-space: pre;*/
    color: var(--color-gray-750);
    font-weight: 700;
}

/*.deleted_audio_segment_node{
    color: var(--color-gray-300);
    font-weight: bold;
    width: 40px;
    height: 20px;
    font-size: var(--text-lg-px);
    flex: 0 0 40px;
    cursor: pointer;
    white-space: normal;
}*/
.deleted_audio_segment_node{
    top: 0px;
    color: var(--color-gray-300);
    font-weight: bold;
    width: fit-content;
    cursor: pointer;
    white-space: normal;
    display: inline-block;
}


.audio_sequencer[delete_node_expanded="true"] .deleted_audio_segment_node .deleted_label.strikethrough-icon.content,
.deleted_audio_segment_node.expanded .deleted_label.strikethrough-icon.content{
    display: inline-block;
    font-size: var(--text-base-px);
    font-weight: 400;
    width: fit-content;
    white-space: break-spaces;
}

#restoreDetetedNodeBtn .pe-7s-refresh{
    transform: scalex(-1) rotate(67.5deg);
}

body.darkTheme .audio_sequencer[delete_node_expanded="true"] .deleted_audio_segment_node:not(.selected) .deleted_label.strikethrough-icon.content,
body.darkTheme .deleted_audio_segment_node.expanded:not(.selected) .deleted_label.strikethrough-icon.content,
[data-role=page].lighttext.transparent .audio_sequencer[delete_node_expanded="true"] .deleted_audio_segment_node:not(.selected) .deleted_label.strikethrough-icon.content,
[data-role=page].lighttext.transparent .deleted_audio_segment_node.expanded:not(.selected) .deleted_label.strikethrough-icon.content{
    color: var(--color-gray-550);
}
/*
.deleted_audio_segment_node.selected, .deleted_audio_segment_node.highlighted, .deleted_audio_segment_node.hover_highlighted{
    color: white;
}*/

/*.sequencer_node_container.do_not_show_non_editable_pause .pause_audio_segment_node[editable=false]{
    display:none;
}*/

.sequencer_node_container.do_not_show_pause_non_explicitely_displayed .pause_audio_segment_node[explicitly_displayed=false]{
    display:none;
}



.deleted_audio_segment_node .undo_deletion_icon,
/*body.browser.mobileweb .audio_sequencer:not([delete_node_expanded="true"]) .deleted_audio_segment_node:hover .deleted_label,
body.browser.mobileweb  .deleted_audio_segment_node:not(.expanded):hover .deleted_label,
.audio_sequencer:not([delete_node_expanded="true"]) .deleted_audio_segment_node:active .deleted_label,
.deleted_audio_segment_node:not(.expanded):active .deleted_label,*/
.deleted_audio_segment_node .deleted_label.strikethrough-icon.content,
.audio_sequencer[delete_node_expanded="true"] .deleted_audio_segment_node .deleted_label.strikethrough-icon.strike_icon,
.deleted_audio_segment_node.expanded .deleted_label.strikethrough-icon.strike_icon{
    display: none;

}
.deleted_audio_segment_node .deleted_label.strikethrough-icon.strike_icon{
    /*font-size: var(--text-lg-px);*/
    white-space: break-spaces;
}

/*.deleted_audio_segment_node .deleted_label,
body.browser.mobileweb .deleted_audio_segment_node:hover .undo_deletion_icon,
.deleted_audio_segment_node:active .undo_deletion_icon{

    width: 30px;
    height: 20px;
    cursor: pointer;
}*/
.deleted_audio_segment_node .deleted_label,
body.browser.mobileweb .deleted_audio_segment_node:hover .undo_deletion_icon,
.deleted_audio_segment_node:active .undo_deletion_icon{

    width: 30px;
    height: 30px;
    cursor: pointer;
}

/*
.deleted_audio_segment_node.selected .deleted_label,.deleted_audio_segment_node.selected .undo_deletion_icon,
.deleted_audio_segment_node.hover_highlighted .deleted_label,.deleted_audio_segment_node.hover_highlighted .undo_deletion_icon  {
    color: var(--text-light);
    
}*/
/*
.deleted_audio_segment_node .deleted_label,
.deleted_audio_segment_node .undo_deletion_icon{
    z-index: 1;
    position: absolute;
    top: 0px;

}*/

.deleted_audio_segment_node .deleted_label,
.deleted_audio_segment_node .undo_deletion_icon{
    z-index: 3;
    position: relative;
    top: 0;
}


.sequencer_node.deleted_audio_segment_node  .highlight_box{
    height:30px;
    top: 0px;
}

/*body.browser.mobileweb .deleted_audio_segment_node:hover{
    top: -1px;
    height: 27px;
}*/
/*
body.browser.mobileweb .deleted_audio_segment_node:hover .undo_deletion_icon,
.deleted_audio_segment_node:active .undo_deletion_icon{
    display: inline-block;
    font-size: var(--text-xl-px);
}
.deleted_audio_segment_node .undo_deletion_icon{

    font-size: var(--text-md-px);
}*/
.sequencer_node_default_context_menu.horizontal_menu{

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

}

.sequencer_node_default_context_menu.horizontal_menu .menu_item{
    display: flex;
    flex-direction: row;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    /*margin: 5px;*/
    cursor: pointer;
    min-width: 100px;
}

.sequencer_node_default_context_menu.horizontal_menu .menu_item .menu_icon{
    flex: 0 0 30px;
    height: 36px;
    width: 36px;
    font-size: var(--text-lg-px);
    justify-content: center;
    align-items: center;
    display: flex;
}

.sequencer_node_default_context_menu.horizontal_menu .menu_item .menu_icon.pe-7s-pen{
    font-weight: 400;
}

.sequencer_node_default_context_menu input#textCorrectionInput{
    margin: 0px 10px;
}

.sequencer_node_default_context_menu.horizontal_menu #insertBtn.menu_item .menu_icon{
    font-size: var(--text-2xl-px);
}

.sequencer_node_default_context_menu.horizontal_menu #saveAsClipBtn.menu_item .menu_icon.ion-paperclip{
  font-size: var(--text-2xl-px);
}

.sequencer_node_default_context_menu.horizontal_menu .menu_item .menu_label{
    padding: 0px 5px 0px 0px;
    flex: 0 0 auto;
}

.tippy-box > .tippy-arrow:before{
    color: var(--color-gray-200);
}

body[appname="trebble"] .ui-page-active.lighttext .tippy-box >.tippy-arrow:before,
body[appname="trebble"].darkTheme .tippy-box >.tippy-arrow:before{
    color: var(--gray-600-15);
}

.sequencer_node_default_context_menu.horizontal_menu .menu_item .menu_label{
    /*display: none;*/
}

body[appname="trebble"] .tippy-content{
    padding: 5px;
}

.wrap_sequencer_node_start.selected .icon,
.wrap_sequencer_node_end.selected .icon,
.audio_sequencer_node.selected .icon,
.audio_sequencer_node.selected.hover_highlighted .icon,
.video_sequencer_node.selected .icon,
.video_sequencer_node.selected.hover_highlighted .icon{
    background-color: var(--trebble-primary);
}

.icon.autoscroll-icon{
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    position: relative;
}

.icon.autoscroll-icon .arrow-up{

    font-size: var(--text-xl-px);
    position: absolute;
    margin-left: 10px;
    width: 20px;
    height: 20px;
    left: 0px;
    top: 0px;
    justify-content: center;
    align-items: center;
    display: flex;

}

.icon.autoscroll-icon .circle{
    font-size: var(--text-2xs-px);
    height: 4px;
    display: flex;
    width: 4px;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    border: var(--border-2) solid var(--text-dark);
}
[data-role=page].lighttext .icon.autoscroll-icon .circle{
    border-color: white;
}

#autoScrollBtn.active .icon.autoscroll-icon .circle{
    border: var(--border-2) solid var(--trebble-primary);
}

.icon.autoscroll-icon .arrow-down{

    font-size: var(--text-xl-px);
    position: absolute;
    margin-left: 10px;
    width: 20px;
    bottom: 0px;
    left: 0px;
    height: 20px;
    justify-content: center;
    align-items: center;
    display: flex;

}

.wrap_sequencer_node_start.highlighted .icon,
.wrap_sequencer_node_start.highlighted .marker,
.wrap_sequencer_node_end.highlighted .icon,
.wrap_sequencer_node_end.highlighted .marker,
.audio_sequencer_node.highlighted .icon,
.audio_sequencer_node.highlighted .marker,
.video_sequencer_node.highlighted .icon,
.video_sequencer_node.highlighted .marker{
    background-color: var(--color-gray-700);
}

.wrap_sequencer_node_start.highlighted .marker,
.wrap_sequencer_node_end.highlighted .marker{
    color: var(--color-gray-700);
}

.wrap_sequencer_node_start.hover_highlighted .icon,
.audio_sequencer_node.hover_highlighted .icon,
.video_sequencer_node.hover_highlighted .icon,
.wrap_sequencer_node_start.hover_highlighted .marker,
.audio_sequencer_node.hover_highlighted .marker,
.video_sequencer_node.hover_highlighted .marker,
.wrap_sequencer_node_end.hover_highlighted .icon,
.wrap_sequencer_node_end.hover_highlighted .marker{
   box-shadow: 0 0 2px 2px var(--trebble-primary-25);
   background-color: var(--color-gray-700);
}

.wrap_sequencer_node_end.hover_highlighted .marker{
   color:  var(--color-gray-700);
}


body.browser.mobileweb .pause_audio_segment_node:not(.not_selectable):hover,
body.browser.mobileweb .punctiation_audio_segment_node:not(.not_selectable):hover,
body.browser.mobileweb .unsupported_audio_segment_node:not(.not_selectable):hover,
body.browser.mobileweb .word_audio_segment_node:not(.not_selectable):hover{
    background:var(--color-gray-150);
    border-radius: 2px;
    color:var(--text-dark);
}
body.browser.mobileweb .deleted_audio_segment_node:not(.not_selectable):hover{
    background:var(--color-gray-150);
    border-radius: 2px;
    color: var(--pulse-gray);
}

.sequencer_node_container  .sequencer_node.punctiation_audio_segment_node{
    display: none;
}
.sequencer_node_container.break_down_text_automatically > fullstop.sequencer_node.punctiation_audio_segment_node:nth-of-type(5n),
.sequencer_node_container.break_down_text_automatically > fullstop.sequencer_node.punctiation_audio_segment_node:last-of-type{
    display: flex;
    height: 2em;
    width: 100%;
}

.pause_audio_segment_node,
.punctiation_audio_segment_node,
.unsupported_audio_segment_node,
.word_audio_segment_node{

}

.wrap_sequencer_node_end.sequencer_node.selected{
    background-color: transparent;
}

.wrap_sequencer_node_end.sequencer_node.selected .marker,
.wrap_sequencer_node_start.sequencer_node.selected .icon,
.wrap_sequencer_node_end.with_drag_icon.sequencer_node.selected .icon{
    background-color: var(--trebble-primary);
}

.wrap_sequencer_node_end.sequencer_node.selected .marker{
    color: var(--trebble-primary);
}


body.browser.mobileweb .pause_audio_segment_node:not(.highlighted):not(.selected):not(.not_selectable):hover,
body.browser.mobileweb .punctiation_audio_segment_node:not(.highlighted):not(.selected):not(.not_selectable):hover,
body.browser.mobileweb .unsupported_audio_segment_node:not(.highlighted):not(.selected):not(.not_selectable):hover,
body.browser.mobileweb .word_audio_segment_node:not(.highlighted):not(.selected):not(.not_selectable):hover,
body.browser.mobileweb .deleted_audio_segment_node:not(.highlighted):not(.selected):not(.not_selectable):hover{
  background:var(--color-gray-150);
  /*border-radius: 2px;*/
}


#commentOverviewBox * .commentBoxWrapper {
    width: 50%;
    background-color: transparent;
    color: white;
    bottom: 0px;
}
[data-role=popup] * .capsuleCard .commentBoxWrapper * #comment,
[data-role=page].userProfileDetailsPage * .capsuleCard .commentBoxWrapper * #comment,
[data-role=page].playlistDetailsPage * .capsuleCard .commentBoxWrapper * #comment{
  color: white;
}
#commentOverviewBox * .commentBoxWrapper * #comment {
    height: 39px;
    color: white;
    border: var(--border-0);
}


/** bottomSheet Css start **/


@keyframes ball1Move-0 {
  0% {
    transform: translate(0, 0);
}
62% {
    transform: translate(-6px, -3px);
}
100% {
    transform: translate(0, 0);
}
}
@keyframes ball2Move-0 {
  0% {
    transform: translate(0, 0);
}
62% {
    transform: translate(3px, 6px);
}
100% {
    transform: translate(0, 0);
}
}
@keyframes ballHover-0 {
  0% {
    transform: scale(1);
}
16% {
    transform: scale(1.016);
}
100% {
    transform: scale(1);
}
}
@keyframes fade {
  0% {
    opacity: 0;
}
100% {
    opacity: 1;
}
}
@keyframes horizontal-move {
  0% {
    transform: scale(2) translateX(0);
    opacity: 1;
    display: flex;
}
100% {
    transform: scale(2) translateX(6vw);
    opacity: 0;
    display: none;
}
}
@keyframes horizontal-move-2 {
  0% {
    transform: translateX(-15vw);
    opacity: 0;
}
100% {
    transform: translateX(0);
    opacity: 1;
}
}
@keyframes rotate180-0 {
  from {
    transform: rotate(-180deg);
}
to {
    transform: rotate(0deg);
}
}
@keyframes rotate180-1 {
  from {
    transform: rotate(0deg);
}
to {
    transform: rotate(-180deg);
}
}
@keyframes rotate180-reverse-0 {
  from {
    transform: rotate(180deg);
}
to {
    transform: rotate(0deg);
}
}
@keyframes rotate180-reverse-1 {
  from {
    transform: rotate(0deg);
}
to {
    transform: rotate(180deg);
}
}
@keyframes slideDown-1 {
  from {
    opacity: 0;
    transform: translateY(-100%);
}
to {
    opacity: 1;
    transform: translateY(0%);
}
}
@keyframes slideUp-0 {
  from {
    transform: translateY(0%);
}
to {
    transform: translateY(100%);
}
}
@keyframes slideUp-1 {
  from {
    transform: translateY(100%);
}
to {
    transform: translateY(0%);
}
}
@keyframes markChange-0 {
  0% {
    background: unset;
}
62% {
    background: var(--text-dark);
}
100% {
    background: unset;
}
}
@keyframes markChange-1 {}
@keyframes pop-countryselect-1 {
  from {
    transform: scale(1, 0.33) translate(0, 100%);
}
to {
    transform: scale(1, 1) translate(0, 0);
}
}
@keyframes pop-countryselect-0 {
  from {
    transform: scale(1, 1) translate(0, 0);
}
to {
    transform: scale(1, 0.33) translate(0, 100%);
}
}
:root {
  --translatey: 0px;
}

body {
  perspective: 1000px;
  font-family: "Roboto";
}

.c-bottom-sheet {
  pointer-events: none;
  visibility: hidden;
  overflow: hidden;
  position: fixed;
  top: 0;
  height: 100vh;
  width: 100vw;
  z-index: 15;
  transition: opacity, visibility 0.25s;
}
.c-bottom-sheet.active {
  visibility: visible;
  pointer-events: unset;
  z-index: 9999;
}
.c-bottom-sheet__scrim {
  opacity: 0;
  display: block;
  position: absolute;
  height: 100vh;
  width: 100vw;
  box-sizing: border-box;
  background-color: var(--color-black-30);
  transition: opacity 0.3s;
  top: 0;
}
.active .c-bottom-sheet__scrim {
  opacity: 1;
}
.c-bottom-sheet__sheet {
  display: inline-block;
  position: absolute;
  left: 0;
  bottom: -100px;
  box-sizing: border-box;
  width: 100%;
  min-height: 38vh;
  background-color: var(--color-white);
  border-radius: 12px 12px 0 0;
  padding: 0 1.5rem calc(1rem + 100px) 1.5rem;
  transition: transform 250ms cubic-bezier(0.4, 0, 0.2, 1);
  transform: translateY(100%);
}
.active .c-bottom-sheet__sheet {
  transform: translateY(0);
}
.c-bottom-sheet__sheet.active {
  transform: var(--translateY);
}
.c-bottom-sheet__handle {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 0.5rem;
  padding-bottom: 1rem;
}
.c-bottom-sheet__handle > span {
  display: block;
  height: 2px;
  width: 28px;
  margin-bottom: 2px;
  background-color: var(--color-black-30);
  border-radius: 2px;
}
.c-bottom-sheet__item {
  width: 100%;
  list-style: none;
  border-bottom: var(--border-1) solid var(--color-black-30);
}
.c-bottom-sheet__item:first-child {
  margin-top: 40px;
  border-top: var(--border-1) solid var(--color-black-30);
}
.c-bottom-sheet__item.active {
  font-family: sans-serif;
  font-weight: 900;
}
.c-bottom-sheet__link {
  display: block;
  padding: 1rem 0;
}
.c-bottom-sheet__link:hover, .c-bottom-sheet__link:visited {
  color: unset;
  text-decoration: none;
}
.c-bottom-sheet__list {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: 80vh;
  overflow: auto;
  margin-bottom: 40px;

}
.c-bottom-sheet__tick {
  display: none;
  font-size: var(--text-xl-px);
  object-fit: contain;
  vertical-align: middle;
}
.c-bottom-sheet__tick:before {
  color: var(--color-green-600);
}
.c-bottom-sheet__item.active .c-bottom-sheet__tick {
  display: block;
}

/*** bottomSheet Css End ***/
.commentBoxWrapper {
    height: 61px;
    width: 100%;
    box-sizing: border-box;
    padding-left: 10px;
    background-color: white;
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-direction: row;
    -ms-flex: 0 0 61px !important;
    flex: 0 0 61px !important;
    -webkit-flex: 0 0 61px !important;
    padding-right: 10px;
    position: fixed;
    bottom: 0px;
}
#songPoppupMenu * .commentBoxWrapper,
.popup-menu-content * .commentBoxWrapper {
    left: 0px;
}
.commentBoxWrapper * #comment {
   /* box-shadow: 0px 0px 0px 0px;*/
   max-height: 39px;
   color: var(--text-dark);
   font-size: var(--text-sm);
 /*
 border-color: var(--color-white-20);*/
}
.commentBoxWrapper > paper-input-decorator {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    -webkit-flex: 1 1 auto;
}
.commentBoxWrapper > #sendButton {
    color: var(--trebble-primary);
    -ms-flex: 0 0 50px;
    flex: 0 0 50px;
    -webkit-flex: 0 0 50px;
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-items: center;
    -webkit-justify-content: center;
}
.commentBoxWrapper > #sendButton.disabled {
    color: grey;
}
#commentListPopupContent {
    width: 100%;
    bottom: 0px;
    margin-top: 0px;
    padding: 0px;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex-direction: column;
}
#commentListPopupContent > div:first-child {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    -webkit-flex: 0 0 auto;
    height: 100%;
}
.commentListItem {
    box-sizing: border-box;
    padding: 6px;
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-direction: row;
}
/*
.commentListItem:active {
    background-color: var(--bg-light);
}
[data-role=popup] * .commentListItem:active {

    background-color: var(--color-white-20);
}
*/

.NotficationCard .userCoverImage,
.commentListItem > .userAvatar,
.userAvatar {
    border-radius: 50%;
    -ms-flex: 0 0 30px;
    flex: 0 0 30px;
    -webkit-flex: 0 0 30px;
    height: 30px;
    width: 30px;
    object-fit: cover;
}

.NotficationCard .userCoverImage,
.NotficationCard * .userAvatar {
    background-color: var(--color-gray-150);
}
[data-role=popup] * .commentListItem {
    border-bottom: var(--border-1) solid var(--color-white-20);
}
[data-role=page] * .commentListItem {
    border-bottom: var(--border-1) solid var(--color-black-5);
}
.commentListItem > .content {
    padding-right: 6px;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    -webkit-flex: 1 1 auto;
    box-sizing: border-box;
    padding-left: 6px;
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex-direction: column;
}
.commentListItem > .content > .commenterInfo .commentUsername {
    /* color: var(--trebble-primary) !important; */
    
    opacity: 0.5;
}
.commentListItem > .content > .commentText {
    font-size: var(--text-sm);
    text-align: left;
}
.commentListItem > .content > .socialInfos {
    -ms-flex-align: center;
    align-items: center;
    -webkit-align-items: center;
    -ms-flex: 0 0 30px;
    flex: 0 0 30px;
    -webkit-flex: 0 0 30px;
    height: 30px;
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-direction: row;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    -webkit-justify-content: flex-start;
    -webkit-align-items: stretch;
}

.commentListItem .sentViaAssistantInfo{
    font-size: 70%;
    opacity: 0.5;
    text-align: left;
}
.socialInfos > .transparent_button {
    margin: 0px;
    height: auto;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    display: -webkit-flex;
    font-size: var(--text-sm);
    line-height: 14px;
    padding: 0px 4px;
    color: var(--trebble-primary);
}
.socialInfos > .delimiter {
    margin-left: 10px;
    margin-right: 10px;
}
.commentListItem > .content > .socialInfos > div {
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-flex;
    -webkit-align-items: center;
    line-height: 14px;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    -webkit-flex: 0 0 auto;
}
.commentListItem > .content > .socialInfos > div.likesInfo {
    margin: 0px;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-align: end;
    align-items: flex-end;
    -webkit-align-items: flex-end;
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex-direction: column;
    opacity: 1;
}

[data-role=page] * .commentListItem,
[data-role=page] * .commentListItem * .itemTitle,
[data-role=page] * .commentListItem * .itemSubtitle {
    color: var(--text-dark) !important;
}
[data-role=page] * .lighttext * .commentListItem,
[data-role=page] * .lighttext * .commentListItem * .itemTitle,
[data-role=page] * .lighttext * .commentListItem * .itemSubtitle {
    color: white !important;
}
[data-role=page] * .commentListItem > .userAvatar {
    background-color: var(--color-gray-150);
}

[data-role=page].lighttext * .capsuleCard>.ownerInfo>.userAvatar{
    background-color:var(--color-white-10);
}

#collection_repeat_container {
    overflow: auto;
    text-align: center;
    overflow-x: hidden;
}

.collection_container[no_more_data_to_load=true] .sentinel_block_wrapper{
    display: none;
}

.load_more_button_block_wrapper #load_more_btn{
    display: flex;
    min-width: 200px;
    margin: auto;
    max-width: 300px;
    align-items: center;
    justify-content: center;
}

@media (min-width: 800px) {



  .playlistDetailsPage.publicPage #myCapsulesListWrapper * #collection_repeat_container  .capsuleCard,
  .playlistDetailsPage.publicPage #myArchivedCapsulesListWrapper * #collection_repeat_container  .capsuleCard  {
    width: 49%;
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    /* height: 470px; */
    vertical-align: middle;
    margin-left: 0.5%;
    margin-right: 0.5%;
    /* align-items: center; */
    /* justify-content: center; */
}
/*.userProfileDetailsPage  * #collection_repeat_container  .capsuleCard ,
.playlistDetailsPage.publicPage #myCapsulesListWrapper * #collection_repeat_container  .capsuleCard,
.playlistDetailsPage.publicPage #myArchivedCapsulesListWrapper * #collection_repeat_container  .capsuleCard{
    position: relative;
    width: 96%;
    display: -ms-flexbox;
    display: -webkit-box;
    display: flex;
    display: -webkit-flex;
    
    vertical-align: middle;
    margin: 2%;
    margin-top: 2%;
    margin-bottom: 2%;

    }*/

}
@media (min-width: 1200px) {
    .userProfileDetailsPage  * #collection_repeat_container  .capsuleCard ,
    .playlistDetailsPage.publicPage #myCapsulesListWrapper * #collection_repeat_container  .capsuleCard,
    .playlistDetailsPage.publicPage #myArchivedCapsulesListWrapper * #collection_repeat_container  .capsuleCard{
        width: 49%;
        display: -webkit-inline-box;
        display: -webkit-inline-flex;
        display: -ms-inline-flexbox;
        display: inline-flex;
        /* height: 470px; */
        vertical-align: middle;
        margin: 0.5%;

        /* align-items: center; */
        /* justify-content: center; */
    }
}

@media (min-width: 1300px) {
  #myCapsulesListWrapper *  #collection_repeat_container  .capsuleCard,
  .playlistDetailsPage.publicPage #myCapsulesListWrapper * #collection_repeat_container  .capsuleCard,
  .playlistDetailsPage.publicPage #myArchivedCapsulesListWrapper * #collection_repeat_container  .capsuleCard {
    width: 32%;
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    /* height: 470px; */
    vertical-align: middle;
    margin-left: 0.5%;
    margin-right: 0.5%;
    /* align-items: center; */
    /* justify-content: center; */
}
}
#myNotificationsListWrapper * #collection_repeat_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    display: -webkit-flex;
    -webkit-flex-direction: column;
    -webkit-align-items: center;
    display: -ms-flex;
    -ms-flex-direction: column;
    -ms-align-items: center;
    display: -moz-flex;
    -moz-flex-direction: column;
    -moz-align-items: center;
    background-color: var(--bg-light);
    height: calc(100vh - 65px);
}
.urlPreviewsPlaceholder > div {
    /*margin: 4px;*/
}

.urlPreviewsPlaceholder a,
span.linkAlternative {
    color: var(--trebble-primary) !important;
    font-weight: 700;
    cursor: pointer;
}
#myCapsulesListWrapper * #collection_repeat_container  .capsuleCard .urlPreviewsPlaceholder,
#myArchivedCapsulesListWrapper * #collection_repeat_container  .capsuleCard .urlPreviewsPlaceholder{
  height: 76px;
  cursor: pointer;

}
.capsuleCard .ui-select{
  padding:0px 10px;
}
.urlPreviewsPlaceholder{
    overflow: auto;
    flex: 0 0 76px;
    -webkit-flex: 0 0 76px;
    -moz-flex: 0 0 76px;
    -ms-flex: 0 0 76px;
}
.urlPreviewCard {
  cursor: pointer;
  display: -ms-flexbox;
  display: flex;
  display: -webkit-flex;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-direction: row;
  height: 68px;
  padding: 4px;
  box-sizing: border-box;
  width: calc(100% - 4px);
  border: var(--border-2) solid var(--bg-light);
}

[data-role=page].lighttext * .capsuleCard .urlPreviewCard{
  border-color:  var(--color-white-20);
}

body.browser.mobileweb [data-role=page].lighttext .urlPreviewCard:hover,
[data-role=page].lighttext .urlPreviewCard:active {
  background-color:  var(--bg-overlay-light);
}
.urlPreviewCard > .thumbnailWrapper {
    -ms-flex-align: center;
    align-items: center;
    -webkit-align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-justify-content: center;
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
    -ms-flex: 0 0 120px;
    flex: 0 0 120px;
    -webkit-flex: 0 0 120px;
}
.urlPreviewCard > .info {
    padding: 4px;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    -webkit-flex: 1 1 auto;
    overflow: hidden;
}
.urlPreviewCard > .thumbnailWrapper > .thumbnail {
    width: 100%;
    height: calc(68px - 8px - 8px);
    object-fit: contain;
}
.urlPreviewCard > .info > .title.itemTitle {
    font-size: var(--text-xs);
    opacity: 1;
    font-weight: 600;
}
.urlPreviewCard > .info > .description {
    opacity: 0.5;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: var(--text-xs);
    color: white;
    font-weight: 400;
    text-align: left;
    margin: 2px 0px;
    height: 30px;
}
.urlPreviewCard > .info > .itemSubtitle {
    margin-top: 4px;
    font-size: var(--text-2xs);
    opacity: 1;
}
body.browser.mobileweb  .urlPreviewCard:hover ,
.urlPreviewCard:active {
  background-color: var(--bg-light);
}
body.browser.mobileweb [data-role=popup] .urlPreviewCard:hover,
[data-role=popup] .urlPreviewCard:active,
body.browser.mobileweb [data-role=page].userProfileDetailsPage  .urlPreviewCard:hover,
[data-role=page].userProfileDetailsPage  .urlPreviewCard:active,
body.browser.mobileweb #player  .urlPreviewCard:hover,
#player .urlPreviewCard:active {
  background-color: var(--color-white-10);
}


body.browser.mobileweb  .urlPreviewCard:hover > .thumbnailWrapper > .thumbnail,
.urlPreviewCard:active > .thumbnailWrapper > .thumbnail{
  opacity: 0.8;
}
#comment {
    border-radius: 0px;
    margin: 0px;
    padding: 0px;
    margin-top: 5px;
}
paper-input-decorator > .unfocused-underline {
    background-color: transparent;
}
.songInfo {
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
    max-height: 70px;
}
.songInfo > img {
    object-fit: cover;
    -webkit-flex: 0 0 50px;
    -ms-flex: 0 0 50px;
    flex: 0 0 50px;
}
#commentListPopupContent * .songInfo {
    background-color: var(--bg-light);
}
.songInfo > .songAndArtist,
div.songLibraryWrapper.playlist > .infiniteListItem.textonly > .textwrapper {
    padding: 6px;
    box-sizing: border-box;
}
#commentListPopupContent .songInfo > .songAndArtist{
    width: calc(100% - 70px);
}

div.songLibraryWrapper.playlist > .infiniteListItem{
  padding : 0.2em;
}

div.songLibraryWrapper.playlist > .infiniteListItem.textonly > .textwrapper {
    padding: 6px;
    width: calc(100% - 60px - 0.4em);
    height: calc(60px - 6px - 1px);
    box-sizing: border-box;
}
.replySummary {
    -ms-flex-align: center;
    align-items: center;
    -webkit-align-items: center;
    display: -ms-flexbox;
    display: flex;
    height: 35px;
    display: -webkit-flex;
    color: var(--trebble-primary);
    cursor: pointer;
    padding:0px 4px;
    border-top: var(--border-1) solid var(--color-white-5);
}

body.mobileweb .replySummary:hover,
.replySummary:active{
    background-color: var(--trebble-primary);
    color: white;
}
[data-role=page] * .replySummary {
    border-top: var(--border-1) solid var(--color-black-5);
}
.replySummary:active {
    background-color: var(--color-white-20);
}


.capsuleCard > .actionButtonWrapper > .actionButton.ion-ios7-loop-strong.disabled:before{
    height: 1em;
    width: 1em;
    animation: rotation 1.4s infinite linear;
    animation: rotation 1.4s infinite linear;
}


@-webkit-keyframes rotation {
  to {
    transform: rotate(360deg);
    transform: rotate(360deg);
}
}

@keyframes rotation {
  to {
    transform: rotate(360deg);
    transform: rotate(360deg);
}
}

.floating{
    animation-name: floating;
    animation-name: floating;

    animation-duration: 1s;   
    animation-duration: 1s;

    animation-iteration-count: infinite;
    animation-iteration-count: infinite;

}
.unconsumed.ownerDataBubble,
.ownerDataBubble.unread{

    animation: greenpulse 1.3s infinite;
    animation: greenpulse 1.3s infinite;
}

@keyframes floating {
    0% {
        transform: translateY(0%);  
    }
    50% {
        transform: translateY(15%);  
    }   
    100% {
        transform: translateY(0%);
    }           
}

@-webkit-keyframes floating {
    0% {
        transform: translateY(0%);  
    }
    50% {
        transform: translateY(15%);  
    }   
    100% {
        transform: translateY(0%);
    }           
}



  @-webkit-keyframes trebble_flash {
	0% { opacity: 1; } 
	50% { opacity: .6; } 
	100% { opacity: 1; }
  }
  @keyframes trebble_flash {
	0% { opacity: 1; } 
	50% { opacity: .6; } 
	100% { opacity: 1; }
  }



body.browser.embedded:not(.darkTheme)  > [data-role=page] * #loadingImg,
body.browser.embedded:not(.darkTheme)  > [data-role=page] * #trebbleCustomLoadingWidget,
body.browser.embedded:not(.darkTheme)  > [data-role=popup] * #loadingImg,
body.browser.embedded:not(.darkTheme)  > [data-role=popup] * #trebbleCustomLoadingWidget,
body.browser:not(.darkTheme)  > [data-role=page] * #loadingImg,
body.browser:not(.darkTheme)  > [data-role=page] * #trebbleCustomLoadingWidget,
body.browser:not(.darkTheme)  > [data-role=popup] * #loadingImg,
body.browser:not(.darkTheme)  > [data-role=popup] * #trebbleCustomLoadingWidget {
  margin: 30px;
  border: var(--border-2) solid var(--color-black-20);
  border-top-color: var(--color-black-80);
}

body.browser.embedded.darkTheme  > [data-role=page].lighttext * #loadingImg,
body.browser.embedded.darkTheme  > [data-role=page].lighttext * #trebbleCustomLoadingWidget,
body.browser.embedded.darkTheme  > [data-role=popup].lighttext * #loadingImg,
body.browser.embedded.darkTheme  > [data-role=popup].lighttext * #trebbleCustomLoadingWidget,
body.browser.darkTheme  > [data-role=page].lighttext * #loadingImg,
body.browser.darkTheme  > [data-role=page].lighttext * #trebbleCustomLoadingWidget,
body.browser.darkTheme  > [data-role=popup].lighttext * #loadingImg,
body.browser.darkTheme  > [data-role=popup].lighttext * #trebbleCustomLoadingWidget,
body.browser.darkTheme  > [data-role=page].transparentBackground * #loadingImg,
body.browser.darkTheme  > [data-role=page].transparentBackground * #trebbleCustomLoadingWidget,
body.browser.darkTheme  > [data-role=popup].transparentBackground * #loadingImg,
body.browser.darkTheme  > [data-role=popup].transparentBackground * #trebbleCustomLoadingWidget,

body.browser.embedded  > [data-role=page].darkTheme * #loadingImg,
body.browser.embedded  > [data-role=page].darkTheme * #trebbleCustomLoadingWidget,
body.browser.embedded  > [data-role=popup].darkTheme * #loadingImg,
body.browser.embedded  > [data-role=popup].darkTheme * #trebbleCustomLoadingWidget,
body.browser  > [data-role=page].darkTheme * #loadingImg,
body.browser  > [data-role=page].darkTheme * #trebbleCustomLoadingWidget,
body.browser  > [data-role=popup].darkTheme * #loadingImg,
body.browser  > [data-role=popup].darkTheme * #trebbleCustomLoadingWidget,


body.browser.embedded  > [data-role=page].lighttext * #loadingImg,
body.browser.embedded  > [data-role=page].lighttext * #trebbleCustomLoadingWidget,
body.browser.embedded  > [data-role=popup].lighttext * #loadingImg,
body.browser.embedded  > [data-role=popup].lighttext * #trebbleCustomLoadingWidget,
body.browser  > [data-role=page].lighttext * #loadingImg,
body.browser  > [data-role=page].lighttext * #trebbleCustomLoadingWidget,
body.browser  > [data-role=popup].lighttext * #loadingImg,
body.browser  > [data-role=popup].lighttext * #trebbleCustomLoadingWidget,
body.browser  > [data-role=page].transparentBackground * #loadingImg,
body.browser  > [data-role=page].transparentBackground * #trebbleCustomLoadingWidget,
body.browser  > [data-role=popup].transparentBackground * #loadingImg,
body.browser  > [data-role=popup].transparentBackground * #trebbleCustomLoadingWidget {
  margin: 30px;
  border: var(--border-2) solid var(--color-white-20);
  border-top-color: var(--color-white-80);
}
.ui-loader.ui-corner-all.ui-body-t.ui-loader-default {
    /*opacity: 0.5;*/
    opacity: 1;
    background-color: transparent;
}
body.browser.darkTheme .c-bottom-sheet__sheet,
.c-bottom-sheet.darkTheme .c-bottom-sheet__sheet{
    color: white;
    background-color: var(--color-white-20);
    backdrop-filter: blur(30px);
}

@supports not (backdrop-filter: blur(20px)) {
   body.browser.darkTheme .c-bottom-sheet__sheet,
   .c-bottom-sheet.darkTheme .c-bottom-sheet__sheet{
    color: var(--color-black);
    background-color: var(--color-gray-100);

}
}

.notificationPreviewAlert {
    padding: 4px;
    border-radius: 2px;
    padding-left: 8px;
    padding-right: 8px;
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-direction: row;
    min-height: 60px;
    max-height: 150px;
    /*max-width: 300px;*/
    width: 100%;
    justify-content: center;
    align-items: center;
    background-color: var(--color-white-80);
    color: white;
    backdrop-filter: blur(20px);
    
}

@supports not (backdrop-filter: blur(20px)) {
    .notificationPreviewAlert {
        background-color: var(--text-dark);
    }
}
.notificationPreviewAlert * .highlight {
    color: var(--trebble-primary);
}
.notificationPreviewAlert > .actionNotificationIconWrapper {
    width: 60px;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    -webkit-flex: 0 0 auto;
    font-size: var(--text-xl);
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-justify-content: center;
    -webkit-align-items: center;
    color: var(--text-dark);
}
.notificationPreviewAlert > .notifcationInfo {
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-justify-content: center;
    padding-right: 10px;
    padding-left: 10px;
    padding: 6px;
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex-direction: column;
    font-size: var(--text-md);
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    -webkit-flex: 1 1 auto;
    max-width: 300px;
}
.notificationPreviewAlert > .notifcationInfo > .notificationMessage.itemTitle {
    font-size: var(--text-sm);
    color: var(--text-dark);
    white-space: normal;
}
.NotficationCard {
    /* display: flex; */
    /* justify-content: center; */
    /* flex-direction: column; */
    /* display: -webkit-flex; */
    /* -webkit-justify-content: center; */
    /* -webkit-flex-direction: column; */
    
    min-height: 250px;
    background-color: white;
    border-radius: 4px;
    color: var(--text-dark);
    box-sizing: border-box;
    padding: 10px;
    width: 100%;
    max-width: 500px;
}
#myNotificationsListWrapper * .NotficationCard {
    min-height: auto;
    max-width: 600px;
    border-bottom: var(--border-1) solid var(--color-black-5);
    border-radius: 0px;
}
.NotficationCard > .wrapper > .content.clickable{
    cursor: pointer;
}
.NotficationCard > .wrapper > .content {
    flex: 1 1 auto;
    -webkit-flex: 1 1 auto;
    -moz-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    width: calc(100% - 45px);
    text-align: left;
}
.NotficationCard > .wrapper > .content > .songInfo > .songAndArtist{
    width: calc(100% - 70px);
}
.NotficationCard.songPicked {
    min-height: 200px;
}
.NotficationCard > .wrapper {
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-direction: row;
}

.NotficationCard .message{
    text-align: left;
}

.NotficationCard .imageWrapper,
.NotficationCard > .wrapper > .illustration {
    box-sizing: border-box;
    padding-right: 10px;
    -ms-flex: 0 0 45px;
    flex: 0 0 45px;
    -webkit-flex: 0 0 45px;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    display: -ms-flexbox;
    display: flex;
    -webkit-justify-content: center;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-align-items: flex-start;
    display: -webkit-flex;
}
.NotficationCard > .wrapper > .content > .footer,
.NotficationCard > .content > .footer {
    display: -webkit-flex;
    padding-top: 4px;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    -webkit-flex: 0 0 auto;
    white-space: nowrap;
    font-size: var(--text-sm);
    padding-bottom: 4px;
    color: var(--color-gray-400);
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-align-itemsjustify-content: flex-end;
}
.NotficationCard > .wrapper > .illustration > i {
    font-size: var(--text-3xl);
}
.popupContent > .NotficationCard {
    font-size: var(--text-sm);
    margin-left: 10px;
    margin-right: 10px;
}
.popupContent > .NotficationCard.userFollowingNotification > .content > .subContent,
.popupContent > .NotficationCard.trebbleFollowingNotification > .content > .subContent {
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-direction: row;
    height: 200px;
    -ms-flex-align: center;
    align-items: center;
    -webkit-align-items: center;
}
.popupContent > .NotficationCard.userFollowingNotification > .content > .subContent > .imageWrapper,
.popupContent > .NotficationCard.trebbleFollowingNotification > .content > .subContent > .imageWrapper {
    -ms-flex: 0 0 60px;
    flex: 0 0 60px;
    -webkit-flex: 0 0 60px;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-justify-content: center;
    display: -webkit-flex;
    -webkit-flex-direction: column;
    -webkit-align-items: center;
}
.popupContent > .NotficationCard.userFollowingNotification > .content > .subContent > .imageWrapper > .userCoverImage,
.popupContent > .NotficationCard.trebbleFollowingNotification > .content > .subContent > .imageWrapper > .userCoverImage {
    -ms-flex: 0 0 45px;
    flex: 0 0 45px;
    -webkit-flex: 0 0 45px;
    width: 45px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    background-color: var(--color-gray-100);
    border-radius: 50%;
}
.NotficationCard > .message {
    /* font-size: var(--text-sm); */
}
.NotficationCard > .content > p,
.NotficationCard > .content > div {
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
    /*-webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-items: center;
    -webkit-justify-content: center;*/
}
.NotficationCard > .content > .message {
    display: block;
}
.NotficationCard > .content > .helpMessage {
    display: block;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-justify-content: flex-start;
}
.NotficationCard > .content > p > .iconWrapper,
.NotficationCard > .content > div > .iconWrapper {
    font-size: var(--text-7xl);
}
.homePanel {
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex-direction: column;
    background-color: white;
}
.homePanel * .menuBox {
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex-direction: column;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    -webkit-flex: 0 0 auto;
    color: var(--text-dark);
}
.homePanel * .menuBox > .panelMenuItem {
    padding-left: 4px;
    padding-right: 4px;
    -ms-flex: 0 0 45px;
    flex: 0 0 45px;
    -webkit-flex: 0 0 45px;
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-direction: row;
    -ms-flex-align: center;
    align-items: center;
    -webkit-align-items: center;
    cursor: pointer;
}

.homePanel * .menuBox > #downloadExtensionBtn,
.homePanel * .menuBox > #installPWABtn,
.homePanel * .menuBox > #upgradeBtn,
#mySubscriptions #showUserRefferalPageBtn,
#upgradeBtn,
.homePanel * .menuBox > #createMyOwnTrebbleBtn,
.homePanel * .menuBox > #createMyFirstJourneyBtn{

    color: white;
    background-color: white;
    border: var(--border-0);
    width: inherit;
    text-align: center;
    font-weight: 700;
    padding-top: 1.1em;
    padding-bottom: 1.1em;
    /*opacity: 0.8;*/
    
    max-width: 200px;
    cursor: pointer;
    background:-webkit-linear-gradient(to bottom right, var(--success-green), var(--success-green-alt));
    background:linear-gradient(to bottom right, var(--success-green), var(--success-green-alt));
    animation: gradient-text-bg 1s linear infinite;
    box-shadow: 0 0 10px 5px var(--color-black-5);

}

body.browser.mobileweb .homePanel * .menuBox > #downloadExtensionBtn:hover,
.homePanel * .menuBox > #downloadExtensionBtn:active,
body.browser.mobileweb .homePanel * .menuBox > #installPWABtn:hover,
.homePanel * .menuBox > #installPWABtn:active,
body.browser.mobileweb .homePanel * .menuBox > #upgradeBtn:hover,
.homePanel * .menuBox > #upgradeBtn:active,
body.browser.mobileweb .homePanel * .menuBox > #createMyOwnTrebbleBtn:hover,
.homePanel * .menuBox > #createMyOwnTrebbleBtn:active,
body.browser.mobileweb .homePanel * .menuBox > #createMyFirstJourneyBtn:hover,
.homePanel * .menuBox > #createMyFirstJourneyBtn:active{
  opacity: 1;
}

@media ( max-height: 470px) {
    .homePanel * .menuBox > .panelMenuItem {
        -ms-flex: 0 0 36px;
        flex: 0 0 36px;
        -webkit-flex: 0 0 36px;
    }
}


.homePanel * .menuBox > #downloadExtensionBtn,
.homePanel * .menuBox > #installPWABtn,
.homePanel * .menuBox > #upgradeBtn{
  display:none;
}

.panelMenuItem > .menuIcon.pe-7s-bell,
.panelMenuItem > .menuIcon.pe-7s-help2,
.panelMenuItem > .menuIcon.pe-7s-help1,
.settingMenuBox > .menuIcon.pe-7s-bell,
.settingMenuBox > .menuIcon.pe-7s-help2{
    position: relative
}
.panelMenuItem > .menuIcon.pe-7s-bell > .ownerDataBubble,
.panelMenuItem > .menuIcon.pe-7s-help2 > .ownerDataBubble,
.panelMenuItem > .menuIcon.pe-7s-help1 > .ownerDataBubble,
.settingMenuBox > .menuIcon.pe-7s-bell > .ownerDataBubble,
.settingMenuBox > .menuIcon.pe-7s-help2 > .ownerDataBubble{
    display: none;
    background-color: var(--trebble-primary);
    width: 10px;
    height: 10px;
    right: calc(50% - 10px);
    top: 0px;
    animation: redpulse 1.3s infinite;
    animation: redpulse 1.3s infinite;
}
.panelMenuItem > .menuIcon.pe-7s-help2 > .ownerDataBubble,
.panelMenuItem > .menuIcon.pe-7s-help1 > .ownerDataBubble,
.settingMenuBox > .menuIcon.pe-7s-help2 > .ownerDataBubble{
    /*background-color: var(--pulse-green);*/
    background-color: var(--trebble-primary);
    animation: greenpulse 1.3s infinite;
    animation: greenpulse 1.3s infinite;
}
.panelMenuItem > .menuIcon.pe-7s-bell > .ownerDataBubble.unread,
.panelMenuItem > .menuIcon.pe-7s-help2 > .ownerDataBubble.unread,
.panelMenuItem > .menuIcon.pe-7s-help1 > .ownerDataBubble.unread,
.settingMenuBox > .menuIcon.pe-7s-bell > .ownerDataBubble.unread,
.settingMenuBox > .menuIcon.pe-7s-help2 > .ownerDataBubble.unread{
    display: block;

}
.homePanel * .menuBox > .panelMenuItem.selected {
    color: var(--trebble-primary);
}
.homePanel * .menuBox > .panelMenuItem > .menuLabel,
.homePanel * .settingMenuBox {
  font-size: var(--text-sm);
  font-weight: 400;
  letter-spacing: 2px;
}
.homePanel * .menuBox > .panelMenuItem:active,
body.browser.mobileweb .homePanel * .menuBox > .panelMenuItem:hover {
    color: var(--trebble-primary);
    transform: translateZ(0);
    transform: translateZ(0);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -moz-osx-font-smoothing: grayscale;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-property: color, background-color;
    transition-property: color, background-color;
}
.homePanel * .menuBox > .panelMenuItem> i,
#upgradeBtn .btnIcon {
    margin-left: 14px;
    font-size: var(--text-xl);
    margin-right: 14px;
}

#upgradeBtn{
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    padding: 13px 13px;
}

.homePanel * .menuBox > #upgradeBtn{
    margin-top: 20px;
}
.homePanel * .menuBox > .panelMenuItem> i.menuIcon.fontello-icon-list-1 {
    margin-right: 5px;
    font-size: var(--text-md);
}
.homePanel * .loggedInUserInfoBox > .panelMenuItem{

    height: 40px;
    width: 40px;
    position: relative;
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
    display: -moz-flex;
    align-items: center;
    -webkit-align-items: center;
    -ms-align-items: center;
    -moz-align-items: center;
    font-size: var(--text-xl);
    justify-content: center;
    -webkit-justify-content: center;
    -ms-align-justify-content: center;
    -moz-align-justify-content: center;
    cursor: pointer;

}

.homePanel * .loggedInUserInfoBox > .panelMenuItem:active,
body.browser.mobileweb .homePanel * .loggedInUserInfoBox > .panelMenuItem:hover {
  color: var(--trebble-primary);
  transform: translateZ(0);
  transform: translateZ(0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: color, background-color;
  transition-property: color, background-color;
}

.homePanel * .loggedInUserInfoBox {
    -ms-flex-item-align: start;
    align-self: flex-start;
    -webkit-align-self: flex-start;
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex-direction: column;
    -ms-flex: 0 0 100px;
    flex: 0 0 100px;
    -webkit-flex: 0 0 100px;
    width: 100%;
    color: var(--text-dark);
    flex-direction: row;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    -moz-flex-direction: row;
    align-items: center;
    -webkit-align-items: center;
    -ms-align-items: center;
    -moz-align-items: center;
    justify-content: space-around;
    -webkit-justify-content: space-around;
    -moz-justify-content: space-around;
    -ms-justify-content: space-around;

}

.userCoverArtWrapper > #userProfileImage {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 50px;
    -ms-flex: 0 0 50px;
    flex: 0 0 50px;
    -webkit-flex: 0 0 50px;
    width: 50px;
    border-radius: 50%;
    margin: 0px;
    margin-left: 14px;
    margin-right: 14px;
    /*box-shadow: 1px 0 10px 0 var(--gray-light-60);*/
}
.loggedInUserInfoBox > .userCoverArtWrapper > #userProfileImage {
    cursor: pointer;
}
@media ( max-height: 470px) {
    .homePanel * .loggedInUserInfoBox {
        -ms-flex: 0 0 65px;
        flex: 0 0 65px;
        -webkit-flex: 0 0 65px;
    }
}
.ui-panel-inner {
    box-sizing: border-box;
    height: 100%;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex-direction: column;
    overflow: auto;
    box-sizing: border-box;
}
.homePanel * .settingMenuBox {
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-align-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-align-items: center;
    -webkit-align-content: center;
    bottom: 0px;
    -ms-flex: 0 0 60px;
    flex: 0 0 60px;
    -webkit-flex: 0 0 60px;
    width: 100%;
    color: var(--text-dark);
    padding-left: 4px;
    padding-right: 4px;
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-direction: row;
    cursor: pointer;
}
body.browser.mobileweb .homePanel * .settingMenuBox:hover,
.homePanel * .settingMenuBox:active {
    color: var(--trebble-primary)
}
@media ( max-height: 470px) {
    .homePanel * .settingMenuBox {
        -ms-flex: 0 0 45px;
        flex: 0 0 45px;
        -webkit-flex: 0 0 45px;
    }
}
.homePanel * .settingMenuBox > i {
    font-size: var(--text-xl);
    margin-right: 14px;
    margin-left: 14px;
    position: relative;
}
.homePanel * .loggedInUserInfoBox > .userCoverArtWrapper {
    -ms-flex-item-align: start;
    align-self: flex-start;
    -webkit-align-self: flex-start;
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-direction: row;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    -webkit-flex: 1 1 auto;
    height: 100px;
    width: 100%;
    -ms-flex-align: center;
    align-items: center;
    -webkit-align-items: center;
    overflow: hidden;
}
.homePanel * .loggedInUserInfoBox > .userCoverArtWrapper > .userInfo {
    -ms-flex-align: center;
    align-items: center;
    -webkit-align-items: center;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    color: var(--text-dark);
    cursor: pointer;
    overflow: hidden;
}
.homePanel * .loggedInUserInfoBox > .userCoverArtWrapper > .userInfo:active,
body.browser.mobileweb .homePanel * .loggedInUserInfoBox > .userCoverArtWrapper > .userInfo:hover {
    color: var(--trebble-primary) !important;
}
.homePanel * .loggedInUserInfoBox > .userCoverArtWrapper > .userInfo > .username.itemTitle {
    color: var(--text-dark);
    font-weight: 400;
    font-size: var(--text-base);
    text-overflow: ellipsis;
}
.homePanel * .loggedInUserInfoBox > .userCoverArtWrapper > .userInfo > .username.itemTitle:active,
body.browser.mobileweb .homePanel * .loggedInUserInfoBox > .userCoverArtWrapper > .userInfo > .username.itemTitle:hover {
    color: var(--trebble-primary);
}
.homePanel * .loggedInUserInfoBox > .userCoverArtWrapper * i {
    margin-left: 14px;
    font-size: var(--text-6xl);
    color: var(--color-gray-400);
    margin-right: 14px;
}
#PlaylistPodcastsPage * .ui-content,
.userProfileDetailsPage * .ui-content {
    padding: 0px;
}
div#player[data-role=page] > .ui-panel-wrapper {
    background-color: transparent;
}
@-webkit-keyframes pink-fade {
    0% {
        background: var(--trebble-primary-200);
    }
    100% {
        background: none;
    }
}
@keyframes pink-fade {
    0% {
        background: var(--trebble-primary-200);
    }
    100% {
        background: none;
    }
}
.hightlightAnim {
    animation: pink-fade 2.5s ease-in 1;
    animation: pink-fade 2.5s ease-in 1;
}
.twoButtonsSet {
    padding: 10px;
    height: 60px;
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-direction: row;
    -ms-flex-align: center;
    align-items: center;
    -webkit-align-items: center;
    width: 100%;
    max-width: 500px;
}
.twoButtonsSet > a.largeMenuButton.ui-link {
    margin: 0px;
    font-size: var(--text-base);
    margin-left: 5px;
    margin-right: 5px;
    padding: 10px;
    box-sizing: border-box;
    -ms-flex: 0 0 calc(50% - 10px);
    flex: 0 0 calc(50% - 10px);
    -webkit-flex: 0 0 calc(50% - 10px);
    height: 54px;
}
body.browser.mobileweb .twoButtonsSet > a.largeMenuButton.ui-link:hover {
    margin: 0px;
    font-size: var(--text-base);
    margin-left: 5px;
    margin-right: 5px;
    padding: 10px;
    box-sizing: border-box;
    -ms-flex: 0 0 calc(50% - 10px);
    flex: 0 0 calc(50% - 10px);
    -webkit-flex: 0 0 calc(50% - 10px);
    height: 54px;
    background-color: transparent;
    color: var(--trebble-primary);
    border: var(--border-2) solid var(--trebble-primary);
}
#continueAsGuessBtn {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-direction: row;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-align-items: center;
    display: -webkit-flex;
    background-color: var(--color-gray-550);
    font-size: var(--text-base);
    line-height: 16px;
    white-space: nowrap;
}
body.browser.mobileweb #continueAsGuessBtn:hover {
    color: white;
    background-color: var(--color-gray-600);
    transform: translateZ(0);
    transform: translateZ(0);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -moz-osx-font-smoothing: grayscale;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-property: color, background-color;
    transition-property: color, background-color;
}
.userListItem {
    box-sizing: border-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    display: -webkit-flex;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-direction: row;
    -ms-flex-align: center;
    align-items: center;
    -webkit-align-items: center;
    cursor: pointer;
    padding: 0.5em;
    border-bottom: var(--border-1) solid var(--color-black-5);
}
.userListItem:active {
    color: white;
    background-color: var(--trebble-primary);
    border-color: var(--trebble-primary);
}
.userListItem > div.imageContainer {
   -ms-flex: 0 0 calc(60px - 1em - 1px);
   -webkit-box-flex: 0;
   flex: 0 0 calc(60px - 1em - 1px);
   -webkit-flex: 0 0 calc(60px - 1em - 1px);
   width: calc(60px - 1em - 1px);
   height: calc(60px - 1em - 1px);
   border-radius: 50%;
   background-color: var(--color-gray-100);
}
.songLibraryWrapper.playlist > .infiniteListItem > div.imageContainer{
    -ms-flex: 0 0 calc(60px - 0.4em);
    flex: 0 0 calc(60px - 0.4em);
    -webkit-flex: 0 0 calc(60px - 0.4em);
    width: calc(60px - 0.4em);
    height: calc(60px - 0.4em - 1px);
    background-color: var(--color-gray-100);
}
.userListItem > div.imageContainer > .userCoverImage,
.ui-listview > li.ui-first-child img.userCoverImage:first-child:not(.ui-li-icon) {
    object-fit: cover;
    -ms-flex: 0 0 45px;
    flex: 0 0 45px;
    -webkit-flex: 0 0 45px;
    width: 45px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: var(--color-gray-100);
    border-radius: 50%;
    height: 45px;
}
.userListItem > .infoWrapper {
    padding-right: 10px;
    box-sizing: border-box;
    padding-left: 10px;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    -webkit-flex: 1 1 auto;
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex-direction: column;
}
.userListItem > .actionButtonWrapper {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    -webkit-flex: 0 0 auto;
    max-width: 60px;
}
.selectionListItem {
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
    height: 74px;
    -ms-flex-align: center;
    align-items: center;
    -webkit-align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-justify-content: center;
    -moz-justify-content: center;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    box-sizing: border-box;
    font-size: var(--text-base);
    padding: 10px;
    cursor: pointer;
}
.selectionListItem.selected {
    color: var(--trebble-primary);
}
.selectionListItem:active {
    background-color: var(--color-white-20);
}
.selectionListItem > .itemLabel {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    -webkit-flex: 1 1 auto;
    height: 100%;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -webkit-align-items: center;
    font-weight: 400;
    font-size: var(--text-4xl);
    text-align: left;
    cursor: pointer;
}
@media(max-width: 500px), (max-height: 500px) and (orientation: landscape){
    .selectionListItem > .itemLabel {
        font-size: var(--text-xl);
    }
    .selectionListItem {
       height: 60px;
   }
}
.selectionListItem > .buttons > #selectionButton.disabled {
    display: none;
}
.selectionListItem > .buttons > #selectionButton {
    font-size: var(--text-3xl);
    box-sizing: border-box;
    padding-left: 10px;
    padding-right: 10px;
}

.drop-content  .selectionListItem > .itemLabel{
  font-size: var(--text-sm-px);
}
.drop-content  .selectionListItem {
  padding:0px;
  height: 40px;
  margin:0px 20px;
}
.drop-content [data-role=header]{
  color: var(--text-dark);
  padding:0px;
  margin:0px 10px;
}

.drop-content  [data-role="popup"]  #closeBtn{
    border-radius: 50%;
    background-color: white;
    width: 20px;
    height: 20px;
    color: black;
    font-size: var(--text-xl);
    margin-top: 0.5em;
    z-index: 1;
}

body.browser.mobileweb  .drop-content   .selectionListItem:hover,
.drop-content  .selectionListItem:active {
  color: var(--trebble-primary) !important;
}


#ExplorePage * #filtersWrapper,
#Onboarding * #filtersWrapper{
    height: 60px;
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-direction: row;
    justify-content: center;
    width: 100%;
    overflow: auto;
    align-items: center;
    margin: auto;
}

#ExplorePage:not([showListView=true]).threeSelectorDisplayed * #filtersWrapper > div:first-child,
#Onboarding:not([showListView=true]).threeSelectorDisplayed * #filtersWrapper > div:first-child,
#Onboarding[showListView=true].darkTheme.threeSelectorDisplayed * #filtersWrapper > div:first-child,
#ExplorePage[showListView=true].darkTheme.threeSelectorDisplayed * #filtersWrapper > div:first-child{
    margin-left: 50px;
}

#ExplorePage:not([showListView=true]) * #filtersWrapper > div:last-child,
#Onboarding:not([showListView=true]) * #filtersWrapper > div:last-child,
#Onboarding[showListView=true].darkTheme * #filtersWrapper > div:last-child,
#ExplorePage[showListView=true].darkTheme * #filtersWrapper > div:last-child{
    margin-right: 50px;
}


html #ExplorePage[showListView=true].ui-page-theme-c,
html #ExplorePage[showListView=true].ui-page-theme-c .ui-bar-b,
html #Onboarding[showListView=true].ui-page-theme-c,
html body:not(.darkTheme) #subscriptionList,
html #Onboarding[showListView=true].ui-page-theme-c .ui-bar-b {
    color: var(--text-dark);
    background-color: var(--bg-light) !important;
}

html body:not(.darkTheme) #home[state=no_new_content] #subscriptionList{
    background-color: transparent !important;
}
html #ExplorePage[showListView=true].darkTheme.ui-page-theme-c,
html #ExplorePage[showListView=true].darkTheme.ui-page-theme-c .ui-bar-b,
html #Onboarding[showListView=true].darkTheme.ui-page-theme-c,
html #Onboarding[showListView=true].darkTheme.ui-page-theme-c .ui-bar-b {
    color: white;
    background-color: var(--color-black) !important;
}

#ExplorePage * #filtersWrapper > div,
#Onboarding * #filtersWrapper > div,
.TrebbleStatisticPage.lighttext * .CustomSelectFieldView,
#ExplorePage[showListView=true] * .CustomSelectFieldView,
#Onboarding[showListView=true] * .CustomSelectFieldView,
.createCapsulePage .CustomSelectFieldView,
.StatsPage * .CustomSelectFieldView {
    padding-left: 30px;
    box-sizing: border-box;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    /* padding-left: 5px; */
    /* padding-right: 5px; */
    
    -webkit-flex: 0 0 auto;
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-justify-content: center;
    -webkit-align-items: center;
    /*color: var(--text-muted);*/
    color: white;
    background-color: var(--color-white-10);
    /* border: var(--border-1) solid var(--color-white-70); */
    
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 10px;
    cursor: pointer;
    font-weight: 900;
    height: 50px;
    margin: 0px 10px;
}

#ExplorePage:not([showListView=true]) * #filtersWrapper > div,
#Onboarding:not([showListView=true]) * #filtersWrapper > div,
body.darkTheme #ExplorePage[showListView=true] * #filtersWrapper > div,
#ExplorePage[showListView=true].darkTheme * #filtersWrapper > div,
#Onboarding[showListView=true].darkTheme * #filtersWrapper > div{
    margin-left: 10px;
    color: white;
    background-color: var(--color-black-50);
    box-shadow: 0 5px 10px var(--color-black-50);
}


#ExplorePage #explorePlaylistsWrapper, #ExplorePage #trebbleCarouselWrapper,
#Onboarding #explorePlaylistsWrapper, #Onboarding #trebbleCarouselWrapper {
    height: calc(100% - 80px);
}

#ExplorePage #explorePlaylistsWrapper > .infoBoxWrapper,
#Onboarding #explorePlaylistsWrapper > .infoBoxWrapper {
    height: 100%;
    opacity: 0.8;
}

#ExplorePage #trebbleCarouselWrapper > div, #ExplorePage #trebbleCarouselWrapper > div > div, #ExplorePage #trebbleCarouselWrapper > div > div > div,
#Onboarding #trebbleCarouselWrapper > div, #Onboarding #trebbleCarouselWrapper > div > div, #Onboarding #trebbleCarouselWrapper > div > div > div {
    height: 100%;
    overflow: hidden;
    position: relative;
}

#ExplorePage #trebbleCarouselWrapper > div > div > div .slideItemWrapper,
#Onboarding #trebbleCarouselWrapper > div > div > div .slideItemWrapper {
    display: -ms-flexbox;
    display: -webkit-box;
    display: flex;
    display: -webkit-flex;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    -webkit-box-align: center;
    align-items: center;
    -webkit-justify-content: center;
    -webkit-align-items: center;
}

.TrebbleStatisticPage.lighttext * .CustomSelectFieldView,
#ExplorePage[showListView=true] * .CustomSelectFieldView,
#Onboarding[showListView=true] * .CustomSelectFieldView,
.StatsPage * .CustomSelectFieldView  {
    color: white;
    height: 50px;
    width: auto;
    margin: 0px;
    background-color:transparent;
}

.CustomSelectFieldView .labelText .icon,
.selectionListItem .itemLabel .icon{

    text-align: center;
}

.CustomSelectFieldView .labelText .icon{
    margin-right: 5px;
    margin-left: 5px;
}

.selectionListItem .itemLabel .icon{
    margin-right: 15px;
    margin-left: 15px;
    width: 30px;
}

body.browser.mobileweb .createCapsulePage .CustomSelectFieldView:hover,
body.browser.mobileweb .TrebbleStatisticPage.lighttext * .CustomSelectFieldView:hover,
body.browser.mobileweb #ExplorePage[showListView=true] * .CustomSelectFieldView:hover,
body.browser.mobileweb #Onboarding[showListView=true] * .CustomSelectFieldView:hover,
.createCapsulePage .CustomSelectFieldView:active,
.TrebbleStatisticPage.lighttext * .CustomSelectFieldView:active,
#ExplorePage[showListView=true] * .CustomSelectFieldView:active,
#Onboarding[showListView=true] * .CustomSelectFieldView:active {
  background-color: var(--color-white-20);
}

.createCapsulePage .CustomSelectFieldView .labelText{
    display: flex;
    flex-direction:row;
    justify-content: center;
    align-items: center;
}

.createCapsulePage .CustomSelectFieldView .icon,
.createCapsulePage .tag_button_list_wrapper .list_tag_button .icon{
    font-size: var(--text-xl);
    margin-right: 10px;
}

.createCapsulePage .loading_container{
    display: none;
}
.createCapsulePage[content_loading=true] .loading_container{
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
}

.TrebbleStatisticPage.lighttext * .CustomSelectFieldView > .pe-7s-angle-down,
#ExplorePage[showListView=true] * .CustomSelectFieldView > .pe-7s-angle-down,
#Onboarding[showListView=true] * .CustomSelectFieldView > .pe-7s-angle-down,
.StatsPage * .CustomSelectFieldView > .pe-7s-angle-down ,
.createCapsulePage .CustomSelectFieldView > .pe-7s-angle-down ,
.TrebbleStatisticPage.lighttext * .CustomSelectFieldView > .ion-chevron-down,
#ExplorePage[showListView=true] * .CustomSelectFieldView > .ion-chevron-down,
#Onboarding[showListView=true] * .CustomSelectFieldView > .ion-chevron-down,
.createCapsulePage .CustomSelectFieldView > .ion-chevron-down,
.StatsPage * .CustomSelectFieldView > .ion-chevron-down {
    /* background-color: var(--text-dark); */
    
    border: var(--border-2) solid white;
    border-radius: 50%;
    color: white;
    margin-left: 5px;
    margin-right: 5px;
    font-size: var(--text-xl);
    border-color: transparent;
}
#ExplorePage:not([showListView=true]) * #filtersWrapper > div:active, 
#ExplorePage:not([showListView=true]) * #filtersWrapper > div:active span,
#Onboarding:not([showListView=true]) * #filtersWrapper > div:active, 
#Onboarding:not([showListView=true]) * #filtersWrapper > div:active span,
body.browser.mobileweb #ExplorePage:not([showListView=true]) * #filtersWrapper > div:hover,
body.browser.mobileweb #ExplorePage:not([showListView=true]) * #filtersWrapper > div:hover span,
body.browser.mobileweb #Onboarding:not([showListView=true]) * #filtersWrapper > div:hover,
body.browser.mobileweb #Onboarding:not([showListView=true]) * #filtersWrapper > div:hover span,
body.darkTheme #ExplorePage[showListView=true] * #filtersWrapper > div:active, 
body.darkTheme #ExplorePage[showListView=true] * #filtersWrapper > div:active span,
body.darkTheme #Onboarding[showListView=true] * #filtersWrapper > div:active, 
body.darkTheme #Onboarding[showListView=true] * #filtersWrapper > div:active span,
body.browser.mobileweb.darkTheme #ExplorePage[showListView=true] * #filtersWrapper > div:hover,
body.browser.mobileweb.darkTheme #ExplorePage[showListView=true] * #filtersWrapper > div:hover span ,
body.browser.mobileweb.darkTheme #Onboarding[showListView=true] * #filtersWrapper > div:hover,
body.browser.mobileweb.darkTheme #Onboarding[showListView=true] * #filtersWrapper > div:hover span ,
#ExplorePage[showListView=true].darkTheme * #filtersWrapper > div:active, 
#ExplorePage[showListView=true].darkTheme * #filtersWrapper > div:active span,
#Onboarding[showListView=true].darkTheme * #filtersWrapper > div:active, 
#Onboarding[showListView=true].darkTheme * #filtersWrapper > div:active span,
body.browser.mobileweb #ExplorePage[showListView=true].darkTheme * #filtersWrapper > div:hover,
body.browser.mobileweb #ExplorePage[showListView=true].darkTheme * #filtersWrapper > div:hover span,
body.browser.mobileweb #Onboarding[showListView=true].darkTheme * #filtersWrapper > div:hover,
body.browser.mobileweb #Onboarding[showListView=true].darkTheme * #filtersWrapper > div:hover span  {
    color: var(--text-dark);
    background-color: white;
    cursor: pointer;
}


[data-role=page].lighttext .sequencer_node_inspector .basic_inpector_param_view .value_container input:disabled,
body.darkTheme  .sequencer_node_inspector .basic_inpector_param_view .value_container input:disabled,
body.darkTheme .userListItem > div.imageContainer,
body.darkTheme #trebbleInfoDetailWrapper,
body.darkTheme [data-role=page] * .capsuleCard,
body.darkTheme .listFooter,
body.darkTheme #passwordChangeFormWrapper,
body.darkTheme #redeemFormWrapper,
body.darkTheme #updateUserInfoForm,
body.darkTheme #updateShortcastInfoForm,
body.darkTheme .ui-listview > li.ui-first-child img.userCoverImage:first-child:not(.ui-li-icon), 
/*body.white-theme.darkTheme div[data-role=page]:not(.lighttext) .sequencer_node_inspector .basic_inpector_param_view.audio_segment_param_view .action_btn_bar button label,*/
body.darkTheme .userListItem > div.imageContainer > .userCoverImage{
    color: white;
}

body.darkTheme  .audio_sequencer .noUi-base,
[data-role=page].lighttext  .audio_sequencer .noUi-base,
body.darkTheme .sequencer_node_container,
[data-role=page].lighttext .sequencer_node_container,
body.darkTheme  #commentListPopupContent * .songInfo,
body.darkTheme div.songLibraryWrapper > .infiniteListItem > .imageContainer > img,
body.darkTheme .userListItem > div.imageContainer,
body.darkTheme .listFooter,
body.darkTheme .NotficationCard,
body.darkTheme [data-role=page] * .capsuleCard,
body.darkTheme .ui-listview > li.ui-first-child img.userCoverImage:first-child:not(.ui-li-icon), 
body.darkTheme .userListItem > div.imageContainer > .userCoverImage{
    background-color: var(--color-white-10);

}


body.darkTheme .songLibraryWrapper.playlist > .infiniteListItem > div.imageContainer,
body.darkTheme div.songLibraryWrapper > .infiniteListItem > .imageContainer,
body.darkTheme #trebbleInfoDetailWrapper,
body.darkTheme #myNotificationsListWrapper * #collection_repeat_container,
body.darkTheme #passwordChangeFormWrapper,
body.darkTheme #redeemFormWrapper,
body.darkTheme #updateUserInfoForm,
body.darkTheme #updateShortcastInfoForm{
    background-color: transparent;
}

body.darkTheme .listFooter{
    border-bottom: var(--border-0);
}

body.white-theme.darkTheme div.ui-header[data-role=header] ~ .ui-panel-wrapper > .ui-content > [data-role=tabs] * [data-role=tabs] > [data-role=navbar] > ul > li > a,
body.white-theme.darkTheme div.ui-header[data-role=header] ~ .ui-panel-wrapper > .ui-content > [data-role=tabs] > [data-role=navbar] > ul > li > a,
body.darkTheme div.ui-header[data-role=header] ~ .ui-panel-wrapper>.ui-content > [data-role=tabs] * [data-role=tabs] > [data-role=navbar]{
    background-color: transparent;
    color: white;
}
body.white-theme.browser div[data-role=page]:not(.lighttext):not(#FeedAndActivityPage):not(#featurePage):not(#ExplorePage):not(#Onboarding):not(#audioEditorDemoPage):not(#player) div.ui-header[data-role=header].hideShadow {
    box-shadow: none;
}

body.darkTheme #maliste.left-right-content-padding{
 background-color: transparent !important;
}
body.darkTheme .listFooter a.ui-btn.ui-btn-c{
    background-color: transparent;
    color: white;
}

body.darkTheme .NotficationCard,
body.darkTheme .capsuleCard > .title,
body.darkTheme [data-role=page] * .capsuleCard * .itemTitle,
body.darkTheme #footerCurrentSongTitle,
body.darkTheme .NotficationCard * .itemTitle, 
body.darkTheme .NotficationCard * .songTitle, 
body.darkTheme .itemTitle,
body.darkTheme .songTitle, 
body.darkTheme .ui-page:not(.lighttext) .itemTitle,
body.darkTheme .ui-page:not(.lighttext) .songTitle, 
body.darkTheme .userListItem>.infoWrapper>.userUsername,
body.darkTheme .infoBoxWrapper > .content *,
body.darkTheme .infoBoxWrapper > .content i{
    color: white;
}

body.darkTheme #footerCurrentSongArtist, 
body.darkTheme .NotficationCard * .artistAndAlbum, 
body.darkTheme .NotficationCard * .itemSubtitle,
body.darkTheme  .artistAndAlbum, .itemSubtitle,
body.darkTheme  .userListItem>.infoWrapper>.userFullname{
    color: white;
    opacity: 0.5;
}

body.darkTheme [data-role=page] * .capsuleCard * .itemTitle{
    color: white !important;
}

body.darkTheme #myNotificationsListWrapper * .NotficationCard{
    border-bottom: var(--border-1) solid var(--color-white-5);
}
[data-role=page].lighttext .audio_sequencer .bottom_toolbar button.disabled,
[data-role=page].lighttext .audio_sequencer .header_toolbar button.disabled,
[data-role=page].lighttext .audio_sequencer .bottom_toolbar button.disabled:hover,
[data-role=page].lighttext .audio_sequencer .header_toolbar button.disabled:hover,
[data-role=page].lighttext .audio_sequencer .bottom_toolbar button.disabled:active,
[data-role=page].lighttext .audio_sequencer .header_toolbar button.disabled:active,
.audio_buffer_region_selector_widget  button.disabled,
body.darkTheme .audio_sequencer .bottom_toolbar button.disabled,
body.darkTheme .audio_sequencer .header_toolbar button.disabled,
.audio_buffer_region_selector_widget  button.disabled:hover,
body.darkTheme .audio_sequencer .header_toolbar button.disabled:hover,
body.darkTheme .audio_sequencer .header_toolbar button.disabled:hover,
.audio_buffer_region_selector_widget  button.disabled:active,
body.darkTheme .audio_sequencer .bottom_toolbar button.disabled:active,
body.darkTheme .audio_sequencer .header_toolbar button.disabled:active{
    color: var(--color-gray-700);
    cursor: default;
}

[data-role=page].lighttext  .audio_sequencer[search_mode_on=true] .search_widget .search_input,
body.darkTheme   .audio_sequencer[search_mode_on=true] .search_widget .search_input,
[data-role=page].lighttext  .audio_sequencer[search_mode_on=true] .search_context_panel .search_input,
body.darkTheme   .audio_sequencer[search_mode_on=true] .search_context_panel .search_input{
    background-color: var(--color-white-30);
    color: white;
}

[data-role=page].lighttext  .audio_sequencer[search_mode_on=true] .search_widget .search_input::placeholder,
body.darkTheme   .audio_sequencer[search_mode_on=true] .search_widget .search_input::placeholder,
[data-role=page].lighttext  .audio_sequencer[search_mode_on=true] .search_context_panel .search_input::placeholder,
body.darkTheme   .audio_sequencer[search_mode_on=true] .search_context_panel .search_input::placeholder{

    color: var(--color-gray-300);
}


.audio_sequencer[search_mode_on=true]:not([search_type=text]) .search_widget .search_input,
.audio_sequencer[search_mode_on=true]:not([search_type=disfluency]) .search_widget .disfluency_search_label,
.audio_sequencer[search_mode_on=true]:not([search_type=filler_words]) .search_widget .filler_words_search_label,
.audio_sequencer[search_mode_on=true]:not([search_type=silences]) .search_widget .silences_search_label{
    display:none;
}

.audio_sequencer[search_mode_on=true][search_type=disfluency] .search_widget .disfluency_search_label,
.audio_sequencer[search_mode_on=true][search_type=text] .search_widget .search_input,
.audio_sequencer[search_mode_on=true][search_type=filler_words] .search_widget .filler_words_search_label,
.audio_sequencer[search_mode_on=true][search_type=silences] .search_widget .silences_search_label{
    display:flex;
    width: 100%;
    text-align: left;
}

.audio_sequencer[search_mode_on=true] .search_widget  .search-icon{
    font-size: var(--text-xl);
    width: 48px;
    height: 48px;
    justify-content: center;
    align-items: center;
    display: flex;
    flex: 0 0 48px;

}


[data-role=page].lighttext .audio_sequencer .main_section .search_widget.floating_menu,
body.darkTheme .audio_sequencer .main_section .search_widget.floating_menu{
    background-color:var(--color-olive-50);
    box-shadow: 0 5px 10px var(--color-black-50);
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);
}

@supports not (backdrop-filter: blur(20px)) {
 [data-role=page].lighttext .audio_sequencer .main_section .search_widget.floating_menu,
 body.darkTheme .audio_sequencer .main_section .search_widget.floating_menu{
    background-color:var(--color-olive);

}

}

#ExplorePage * #filtersWrapper > div >.pe-7s-angle-down,
#ExplorePage * #filtersWrapper > div >.ion-chevron-down,
#Onboarding * #filtersWrapper > div >.pe-7s-angle-down,
#Onboarding * #filtersWrapper > div >.ion-chevron-down  {
    font-size: var(--text-2xl);
    border-color: transparent;
    margin: 0px;
}

#ExplorePage * #filtersWrapper > div >.ion-chevron-down,
#Onboarding * #filtersWrapper > div >.ion-chevron-down{
    font-size: var(--text-lg);
    height: 27px;
    border-radius: 50%;
    width: 27px;
    /*margin: 0px 0px 0px 10px;*/
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 27px;
    border-color: transparent;
}
html .ui-page-theme-c#ExplorePage .ui-panel-wrapper ,
html .ui-page-theme-c#Onboarding .ui-panel-wrapper {
    background-color: transparent;
}
#backgroundBlurImage {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-size: cover;

}
#ExplorePage,
#Onboarding {
    /*-webkit-transition: background-color .3s ease-in, color .3s ease-in;
    -moz-transition: background-color .3s ease-in, color .3s ease-in;
    -o-transition: background-color .3s ease-in, color .3s ease-in;
    -ms-transition: background-color .3s ease-in, color .3s ease-in;
    transition: background-color .3s ease-in, color .3s ease-in;*/
}


@media(min-width: 300px), (max-height: 300px) and (orientation: landscape){
    #backgroundBlurImage {
        -webkit-transition: background-image .3s ease-in;
        -moz-transition: background-image .3s ease-in;
        -o-transition: background-image .3s ease-in;
        -ms-transition: background-image .3s ease-in;
        transition: background-image .3s ease-in;
    }
}

body.mobileweb #player {
   -webkit-transition: background-image .3s ease-in;
   -moz-transition: background-image .3s ease-in;
   -o-transition: background-image .3s ease-in;
   -ms-transition: background-image .3s ease-in;
   transition: background-image .3s ease-in;
}
#ExplorePage * .ui-flipswitch.ui-flipswitch-active ,
#Onboarding * .ui-flipswitch.ui-flipswitch-active {
    padding-left: 90px;
    /* Override this and width in previous rule if you use labels other than "on/off" and need more space */
    
    width: 1.875em;
    height: 35px;
    margin: 0px;
    background-color: var(--trebble-primary);
}
#ExplorePage * .ui-flipswitch, 
#Onboarding * .ui-flipswitch{
    padding-left: 0px;
    /* Override this and width in previous rule if you use labels other than "on/off" and need more space */
    
    width: 115px;
    vertical-align: middle;
    border: var(--border-0);
    /* background-color: var(--color-white-20);
    background-color: var(--bg-overlay-light);*/
    background-color: var(--color-black-50);
    /* border: var(--border-1) solid var(--color-white-20); */
    
    margin: 0px;
    margin-right: 2px;
    height: 32px;

    box-shadow: 0 5px 10px var(--color-black-50);
}
#ExplorePage * .ui-flipswitch:active ,
#Onboarding * .ui-flipswitch:active{
    background-color: var(--color-white-20);
}
#ExplorePage * .ui-flipswitch > .ui-flipswitch-on,
#Onboarding * .ui-flipswitch > .ui-flipswitch-on {
    /*color: var(--text-muted);*/
    color: white !important;
    text-indent: -85px;
    font-size: var(--text-sm);
    font-weight: 700;
    background-color: white;
    /*border: var(--border-1) solid var(--text-muted);*/
    border: transparent;
    margin: 0px;
    height: 32px;
    line-height: 32px;
    width: 24px;
    box-shadow: 0 5px 10px var(--color-black-50);
}
#ExplorePage * .ui-flipswitch > .ui-flipswitch-on:active,
#Onboarding * .ui-flipswitch > .ui-flipswitch-on:active {
    /*color: var(--color-white-90);*/
    background-color: var(--color-white-90);
    border: var(--border-1) solid var(--color-white-90);
}
#ExplorePage * .ui-flipswitch > .ui-flipswitch-off,
#Onboarding * .ui-flipswitch > .ui-flipswitch-off {
    /*color: var(--text-muted);*/
    color: white;
    width: 90px;
    font-size: var(--text-xs);
    text-align: left;
    margin: 0px;
    padding: 0px;
    border: var(--border-0);
    font-weight: 700;
    height: 32px;
    line-height: 32px;
    background-color: var(--color-white-10);
    /* display: block; */
}
#ExplorePage > div.ui-header[data-role=header] >:last-child > .ui-controlgroup-controls,
#ExplorePage > div.ui-header[data-role=header] >:last-child > .ui-controlgroup-controls > form,
#Onboarding > div.ui-header[data-role=header] >:last-child > .ui-controlgroup-controls,
#Onboarding > div.ui-header[data-role=header] >:last-child > .ui-controlgroup-controls > form {
    height: 100%;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-justify-content: center;
    -webkit-align-content: center;
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-direction: row;
}
#ExplorePage > div.ui-header[data-role=header] >:last-child > .ui-controlgroup-controls > form,
#Onboarding > div.ui-header[data-role=header] >:last-child > .ui-controlgroup-controls > form{
    height: 32px;
}

#ExplorePage > div.ui-header[data-role=header] > :last-child > .ui-controlgroup-controls> form,
#ExplorePage[showListView=true] #showListViewButton,
#ExplorePage:not([showListView=true]) #showCarouselViewButton,
#ExplorePage:not([showListView=true]) #trebbleListWrapper,
#ExplorePage[showListView=true] #backgroundBlurImage,
#ExplorePage[showListView=true] #trebbleCarouselWrapper,
#Onboarding > div.ui-header[data-role=header] > :last-child > .ui-controlgroup-controls> form,
#Onboarding[showListView=true] #showListViewButton,
#Onboarding:not([showListView=true]) #showCarouselViewButton,
#Onboarding:not([showListView=true]) #trebbleListWrapper,
#Onboarding[showListView=true] #backgroundBlurImage,
#Onboarding[showListView=true] #trebbleCarouselWrapper{
    display:none;
}
#ExplorePage #trebbleListWrapper,
#Onboarding #trebbleListWrapper{
    overflow: auto;
    padding: 1em;
}

#Onboarding #trebbleListWrapper{
    /*box-sizing: border-box;*/
    /*padding-bottom: 150px;*/
    box-sizing: border-box;
}
/*#Onboarding #trebbleListWrapper  .padding_block{
    height: 150px;
    width: 100%;
    }*/
    #Onboarding #trebbleListWrapper > div,
    #Onboarding #trebbleListWrapper > div #collection_repeat_container{
        height: 100%;
    }
    #filterSortLibraryOptionPopupContent {
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 0px;
        padding-bottom: 0px;
        max-width: 700px;
    }
    #filterSortLibraryOptionPopupContent > .left-right-content-padding {
        padding: 0px;
    }

    #filterSortLibraryOptionPopupContent > .left-right-content-padding > .listHeader {
        border-bottom: var(--border-1) solid var(--color-white-20);
    }
    .songSelectorWidget > .widgetHeader {
        display: -ms-flexbox;
        display: flex;
        display: -webkit-flex;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-flex-direction: column;
    }
    .songSelectorWidget > .widgetHeader {
        display: -ms-flexbox;
        display: flex;
        display: -webkit-flex;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-flex-direction: row;
    }
    .songSelectorWidget > .widgetHeader > #widgetTitle {
        -ms-flex: 1 1 100%;
        flex: 1 1 100%;
        -webkit-flex: 1 1 100%;
        display: -ms-flexbox;
        display: flex;
        display: -webkit-flex;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
    }
    .songSelectorWidget > .widgetHeader > #widgetTitle > form {
        width: 100%;
    }
    .songSelectorWidget > .widgetHeader > #widgetTitle > form > input {
        width: 100%;
        color: white;
        font-size: var(--text-3xl);
        font-weight: 900;
    }
    [data-role=page] * .songSelectorWidget > .widgetHeader > #widgetTitle > form > input {
        background-color: var(--color-gray-200);
        color: var(--text-dark);
    }
    .songSelectorWidget > .widgetHeader > .searchButtonWrapper {
        -ms-flex: 0 0 51px;
        flex: 0 0 51px;
        -webkit-flex: 0 0 51px;
    }
    [data-role=page]#NewLoginPage > .ui-content,
    [data-role=page]#newSignupPage > .ui-content,
    [data-role=page]#onboardingSignupPage > .ui-content,
    [data-role=page]#trebbleSetup > .ui-content,
    [data-role=page]#journeyCreation > .ui-content,
    [data-role=page].userInfoEditPage > .ui-content,
    [data-role=page].shortcastInfoEditPage > .ui-content,
    [data-role=page].settingsPage > .ui-content {
        display: -ms-flexbox;
        display: flex;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
        display: -webkit-flex;
        -webkit-flex-direction: column;
        -webkit-align-items: center;
        -webkit-justify-content: center;
    }
    [data-role=page]#NewLoginPage > .ui-content  #loginForm,
    [data-role=page]#onboardingSignupPage > .ui-content  #SignUpForm,
    [data-role=page]#newSignupPage > .ui-content  #SignUpForm {
        max-width: 400px;
        width: 100%;
        display: -ms-flexbox;
        display: flex;
        -webkit-flex-direction: column;
        -msp-flex-direction: column;
        flex-direction: column;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
        display: -webkit-flex;
        -webkit-flex-direction: column;
        -webkit-align-items: center;
        -webkit-justify-content: center;
        flex:0 0 auto;
    }
    [data-role=page]#NewLoginPage > .ui-content .twoButtonsSet > a.largeMenuButton.ui-link,
    [data-role=page]#onboardingSignupPage > .ui-content .twoButtonsSet > a.largeMenuButton.ui-link,
    [data-role=page]#newSignupPage > .ui-content .twoButtonsSet > a.largeMenuButton.ui-link{
      margin:0px;
  }

  [data-role=page]#NewLoginPage > .ui-content .twoButtonsSet > a.largeMenuButton.ui-link#SignUpBtn,
  [data-role=page]#onboardingSignupPage > .ui-content .twoButtonsSet > a.largeMenuButton.ui-link#SignUpBtn,
  [data-role=page]#newSignupPage > .ui-content .twoButtonsSet > a.largeMenuButton.ui-link#SignUpBtn {
    margin: auto;
}


#youtubeVideoLink {
    height: 100%;
    width: 100%;
    /*box-shadow: 0 0 50px var(--color-black-30);
    -moz-box-shadow: 0 0 50px var(--color-black-30);*/
}
body.embedded * #reponsivePlayerContent {
    background-image: none;
}
#reponsivePlayerContent {

    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    display: flex;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: -moz-flexbox;
    -webkit-justify-content: center;
    -webkit-flex-direction: row;
    -webkit-align-items: center;
    background-image: linear-gradient( var(--color-black-0), var(--color-black-10) 60%, var(--color-black-80) 100%);
    background-image: -webkit-linear-gradient( var(--color-black-0), var(--color-black-10) 60%, var(--color-black-80) 100%);
    background-image: -moz-linear-gradient( var(--color-black-0), var(--color-black-10) 60%, var(--color-black-80) 100%);
    background-image: -ms-linear-gradient( var(--color-black-0), var(--color-black-10) 60%, var(--color-black-80) 100%);
    background-image: -o-linear-gradient( var(--color-black-0), var(--color-black-10) 60%, var(--color-black-80) 100%);
}
@media all and (orientation: portrait) {
    /* Styles for Portrait screen */
    
    #reponsivePlayerContent {
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-flex-direction: column;
    }
    .playerCoverArtImage-portrait,
    #player * #albumArtImage {
        /*background-size: 95% auto;*/
        background-size: contain;
        transform: scale(0.85, 0.85);
        transform: scale(0.85, 0.85);
    }
}
#reponsivePlayerContent.always_portrait{
    flex-direction: column;
}

#reponsivePlayerContent.always_portrait > :nth-child(2){
    max-width: 500px;
}

@media all and (orientation: landscape) {
    /* Styles for Landscape screen */
    
    #reponsivePlayerContent:not(.always_portrait) {
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-flex-direction: row;
    }
    #reponsivePlayerContent:not(.always_portrait) > div:first-child,
    #reponsivePlayerContent:not(.always_portrait) > div:nth-child(2) {
        flex: 0 0 50%;
        -webkit-flex: 0 0 50%;
        -moz-flex: 0 0 50%;
        -ms-flex: 0 0 50%;
    }
    .playerCoverArtImage-portrait,
    #player * #albumArtImage {
        /*background-size: auto 90%;*/
        background-size: contain;
        transform: scale(0.90, 0.90);
        transform: scale(0.90, 0.90);
    }
}

@media (min-aspect-ratio: 5/2) {

    #reponsivePlayerContent:not(.always_portrait) > div:first-child{
        flex: 0 0 30%;
        -webkit-flex: 0 0 30%;
        -moz-flex: 0 0 30%;
        -ms-flex: 0 0 30%;
    }
    
    #reponsivePlayerContent:not(.always_portrait) > div:nth-child(2) {
        flex: 0 0 70%;
        -webkit-flex: 0 0 70%;
        -moz-flex: 0 0 70%;
        -ms-flex: 0 0 70%;
    }
    #reponsivePlayerContent:not(.always_portrait) #commentOverviewBox * .commentBoxWrapper{
        width: 70%;
    }
    
}
#songGradingView ,
#lifespanSelectionView{
    width: 100%;
}


#fullCoverArtImage > #editTrebbleCoverPicBtn{
   padding-top: 2px;
   padding-bottom: 2px;
   height: 30px;
   margin: 0px;
   font-weight: 300;
   color: white;
   line-height: 30px;
   margin-top: 50px;
   font-size: var(--text-5xl-px);
   width: fit-content;
   height: fit-content;
   cursor: pointer;
}
#fullCoverArtImage > #editTrebbleCoverPicBtn:before{
  font-size: var(--text-xl);
  font-weight: 400;
  
}
body.browser.mobilebrowser * #artistImage.editable *  #editTrebbleCoverPicBtn,
body.browser.mobileweb * #artistImage.editable:hover *  #editTrebbleCoverPicBtn,
#artistImage.editable:active * #editTrebbleCoverPicBtn,
#artistImage.editable #fullCoverArtImage.nocoverimage > #editTrebbleCoverPicBtn{
 display: -ms-flexbox;
 display: flex;
 display: -webkit-flex;
 -ms-flex-pack: center;
 justify-content: center;
 -webkit-justify-content: center;
 -ms-flex-direction: row;
 flex-direction: row;
 -webkit-flex-direction: row;
 font-size: var(--text-base);
 padding-left: 20px;
 padding-right: 20px;
 display: none;
}

#artistImage.editable #fullCoverArtImage.nocoverimage > #editTrebbleCoverPicBtn{
  display:none ;
}


body.browser.mobileweb * #trebbleInfoDetailWrapper > .coverArtWrapper:hover > .overlay,
* #trebbleInfoDetailWrapper > .coverArtWrapper:active > .overlay{
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: var(--color-black-60);
}
.darktext #fullCoverArtImage > #editTrebbleCoverPicBtn{
  color: var(--text-dark);
  border-color: white;
  background-color: white;
}

.darktext #fullCoverArtImage > #editTrebbleCoverPicBtn:active,
body.browser.mobileweb .darktext #fullCoverArtImage > #editTrebbleCoverPicBtn:hover{
  color: var(--trebble-primary) !important;
  border-color: white !important;
  background-color: white;
}

#fullCoverArtImage {
    /*z-index: 10;*/
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    left: auto;
    width: 100%;
    position: absolute;
    height: 280px;
    width: 280px;
    left: calc((100% - 280px)/2);
    display: flex;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: -moz-flexbox;
    box-sizing: border-box;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    -moz-justify-content: center;
    top: auto;
    z-index: 2;
    /*background-color: black;*/
    box-shadow: 0 0 50px var(--color-black-30);
    -moz-box-shadow: 0 0 50px var(--color-black-30);
}
#fullCoverArtImage.withShadow{
    box-shadow: 0 0 50px var(--color-black-30);
    -moz-box-shadow: 0 0 50px var(--color-black-30);
}
#fullCoverArtImage.inFront{
    z-index: 10;
}

.albumDetailsPage  .cornerDecorator,
.playlistDetailsPage  .cornerDecorator,
.artistDetailsPage  .cornerDecorator,
.playlistDetailsPage.journeyPage #albumList > .actionButtonWrapper,
.playlistDetailsPage:not(.journeyPage) #albumList > .actionButtonWrapper
/*,.playlistDetailsPage:not(.journeyPage) .actionButtonWrapper.floatingBtn*/{
    display:none ;
}

body.browser > .artistDetailsPage.full * div#fullCoverArtImage,
body.browser > .artistDetailsPage * div#fullCoverArtImage {

    /*   top: calc(50% - 200px);
    background-position: inherit; */
    
    background-size: cover;
    width: 280px;
    height: 280px;
    border-radius: 50%;
    left: calc((100% - 280px)/2);
    top: calc((100% - 280px)/2);
}



@media (min-width: 480px), (min-height: 480px) and   (orientation: landscape) {

    .artistDetailCoverArtWrapper,
    .userDetailCoverArtWrapper,
    .albumDetailCoverArtWrapper {
        height: 400px;
    }
}
@media (min-width: 1086px) and (max-width: 1285px), (min-width: 320px) and (max-width: 900px), (min-height: 1086px) and   (orientation: landscape) {
 body.browser > .artistDetailsPage.full * div#fullCoverArtImage,
 body.browser > .artistDetailsPage * div#fullCoverArtImage ,
 body.browser > .playlistDetailsPage * div#fullCoverArtImage{
    width: 280px;
    left: calc((100% - 280px)/2);
    top: calc((100% - 280px)/2);
    height: 280px;
    position: absolute;

}


}

@media (min-width: 860px) and (max-width: 1086px),(min-width: 300px) and (max-width: 900px),  (min-height: 860px) and   (orientation: landscape) {
  body.browser > .artistDetailsPage.full * div#fullCoverArtImage,
  body.browser > .artistDetailsPage * div#fullCoverArtImage,
  body.browser > .playlistDetailsPage * div#fullCoverArtImage {
    width: 280px;
    left: calc((100% - 280px)/2);
    top: calc((100% - 280px)/2);
    height: 280px;
    position: absolute;

}
}

/*
@media (min-width: 1285px), (min-height: 1285px) and   (orientation: landscape) {
   body.browser > .artistDetailsPage.full * div#fullCoverArtImage,
   body.browser > .artistDetailsPage * div#fullCoverArtImage,
   body.browser > .playlistDetailsPage * div#fullCoverArtImage{
    width: 450px;
    left: calc((100% - 450px)/2);
    height: 450px;
    position: absolute;
}

}
*/


@media (max-width: 480px), (max-height: 480px) and   (orientation: landscape) {
  body.browser > .artistDetailsPage.full * div#fullCoverArtImage,
  body.browser > .artistDetailsPage * div#fullCoverArtImage,
  body.browser > .playlistDetailsPage * div#fullCoverArtImage,
  #fullCoverArtImage{
    width: 200px;
    left: calc((100% - 200px)/2);
    top: calc((100% - 200px)/2);
    height: 200px;
}

.artistDetailCoverArtWrapper,
.userDetailCoverArtWrapper,
.albumDetailCoverArtWrapper {
    height: 380px;
}


}


@media (min-width: 900px) {
    .playlistDetailsPage:not(.publicPage) > #contentWrapper,
    .albumDetailsPage > #contentWrapper,
    .playlistDetailsPage .artistDetailsPage > #contentWrapper,
    .artistDetailsPage:not(.fulldetails) > #contentWrapper{
        display: -webkit-box;
        display: flex;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: -moz-flexbox;
        -webkit-flex-direction: row;
        -ms-flex-direction: row;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        flex-direction: row;
        -webkit-flex-direction: row;
    }

    .playlistDetailsPage.publicPage .actionButtonWrapper{
      /*flex-direction:row;*/
      flex-direction:column-reverse;
  }

  .playlistDetailsPage.publicPage > #contentWrapper > .flexUpperContentWrapper{
     -webkit-flex-direction: row;
     -ms-flex-direction: row;
     flex-direction: row;
     -webkit-flex-direction: row;
     -ms-flex-line-pack: center;
     -moz-flex-line-pack: center;
     align-content: center;
     -webkit-align-content: center;
     display: -ms-flexbox;
     display: -moz-flexbox;
     display: -webkit-box;
     display: flex;
     display: -webkit-flex;
     display: -moz-flex;
     -webkit-align-items: center;
     -ms-flex-align: center;
     -webkit-box-align: center;
     align-items: center;
     -webkit-justify-content: center;
     -ms-flex-pack: center;
     -webkit-box-pack: center;
     justify-content: center;
     -webkit-align-items: center;
     -webkit-justify-content: center;
 }



 .playlistDetailsPage.publicPage > #contentWrapper > .flexUpperContentWrapper #fullCoverArtImage{
    margin: 100px 50px 0px 10%;
    position:relative;
    left:0px;
    top:0px;
    flex: 0 0 auto;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
}
.playlistDetailsPage.publicPage:not(.showBackButton) > #contentWrapper > .flexUpperContentWrapper #fullCoverArtImage{
    margin:150px 50px 0px 10%;
}
.playlistDetailsPage.publicPage > #contentWrapper > .flexUpperContentWrapper .trebbleInfo{
   flex: 1 1 auto;
   -webkit-flex: 1 1 auto;
   -ms-flex: 1 1 auto;
   max-width:600px;
   margin-top: 100px;
   -ms-flex-line-pack: center;
   -moz-flex-line-pack: center;
   align-content: center;
   -webkit-align-content: center;
   -webkit-box-orient: horizontal;
   -webkit-box-direction: normal;
   display: -ms-flexbox;
   display: -moz-flexbox;
   display: -webkit-box;
   display: flex;
   display: -webkit-flex;
   display: -moz-flex;
   -webkit-align-items: center;
   -ms-flex-align: center;
   -webkit-box-align: center;
   align-items: center;
   -webkit-justify-content: center;
   -ms-flex-pack: center;
   -webkit-box-pack: center;
   justify-content: center;
   -webkit-align-items: center;
   -webkit-justify-content: center;
   -webkit-flex-direction: column;
   -ms-flex-direction: column;
   flex-direction: column;
   -webkit-flex-direction: column;
}
.playlistDetailsPage.publicPage:not(.showBackButton) > #contentWrapper > .flexUpperContentWrapper .trebbleInfo{
   margin-top: 150px;
}


.playlistDetailsPage.justify_design.publicPage > #contentWrapper > .flexUpperContentWrapper{

}

.playlistDetailsPage.justify_design.publicPage> #contentWrapper > .flexUpperContentWrapper #fullCoverArtImage{
    flex:0 0 auto;
    margin:0px;
    margin-right: 10px;
    margin-top: 120px;
}

.playlistDetailsPage.justify_design.publicPage:not(.showBackButton) > #contentWrapper > .flexUpperContentWrapper #fullCoverArtImage{
    margin-top: 170px;
}

.playlistDetailsPage.justify_design.publicPage .bioWrapper .trebbleName{
    text-align: start;
}

.playlistDetailsPage.justify_design.publicPage .flexUpperContentWrapper>div.trebbleInfo>.bioWrapper>.textContent  > * {
    margin: 0px;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
}

.playlistDetailsPage.justify_design.publicPage .flexUpperContentWrapper>div.trebbleInfo>.bioWrapper>.textContent  > .journey_summary_detail{
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    padding: 1em;
}

.playlistDetailsPage.justify_design.publicPage .flexUpperContentWrapper>div.trebbleInfo>.bioWrapper>.textContent .journey_other_info {
    width:fit-content;
    padding: 1em 0px;
}

.playlistDetailsPage.justify_design.publicPage .flexUpperContentWrapper>div.trebbleInfo>.bioWrapper>.textContent .journey_other_info > .journey_stats span{
  padding-top: 0em;
  padding-bottom: 0em;
}



.playlistDetailsPage.justify_design.publicPage > #contentWrapper > .flexUpperContentWrapper{
    margin-bottom: 0px;
    align-items: flex-start;
}

.playlistDetailsPage.justify_design.publicPage.journeyPage #albumList{
    margin-top: 0px;
}

.playlistDetailsPage.justify_design.publicPage .flexUpperContentWrapper>div.trebbleInfo>.bioWrapper>.textContent .journey_other_info .journey_stats{
    width:fit-content;
    padding:0px;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    display:flex;
}
.playlistDetailsPage.justify_design.publicPage .flexUpperContentWrapper>div.trebbleInfo>.bioWrapper> .textContent .actionButtonWrapper.floatingBtn{
    flex-direction: row-reverse;
    justify-content: flex-end;
}


.playlistDetailsPage.justify_design.publicPage .flexUpperContentWrapper>div.trebbleInfo>.bioWrapper>.textContent .actionButtonWrapper.floatingBtn >  #buyNowBtn,
.playlistDetailsPage.justify_design.publicPage .flexUpperContentWrapper>div.trebbleInfo>.bioWrapper>.textContent .actionButtonWrapper.floatingBtn >  #followBtn,
.playlistDetailsPage.justify_design.publicPage .flexUpperContentWrapper>div.trebbleInfo>.bioWrapper>.textContent .actionButtonWrapper.floatingBtn >  #followingBtn,
.playlistDetailsPage.justify_design.publicPage .flexUpperContentWrapper>div.trebbleInfo>.bioWrapper>.textContent .actionButtonWrapper.floatingBtn >  #playTrailerBtn,
.playlistDetailsPage.justify_design.publicPage .flexUpperContentWrapper>div.trebbleInfo>.bioWrapper>.textContent .actionButtonWrapper.floatingBtn >  #purchasedBtn{
    width: fit-content;
    margin: 1em 1em 1em 0px;
    min-width: 200px;
}

.playlistDetailsPage.justify_design.publicPage .actionButtonWrapper.floatingBtn{

    margin: 0px;
    display: flex;
    flex-direction: column-reverse;
    align-items: self-start;
    padding-left: 40px;
    width: 100%;
    box-sizing: border-box;
    justify-content: center;

}

.playlistDetailsPage.justify_design.publicPage.journeyPage .actionButtonWrapper.floatingBtn{
   padding-left: 0px;
}


.playlistDetailsPage.justify_design.publicPage:not(.journeyPage) .actionButtonWrapper.floatingBtn >  #buyNowBtn,
.playlistDetailsPage.justify_design.publicPage:not(.journeyPage)  .actionButtonWrapper.floatingBtn >  #followBtn,
.playlistDetailsPage.justify_design.publicPage:not(.journeyPage) .actionButtonWrapper.floatingBtn >  #followingBtn,
.playlistDetailsPage.justify_design.publicPage:not(.journeyPage) .actionButtonWrapper.floatingBtn >  #playTrailerBtn,
.playlistDetailsPage.justify_design.publicPage:not(.journeyPage) .actionButtonWrapper.floatingBtn >  #purchasedBtn{
    width: fit-content;
    margin: 0px;
}

.playlistDetailsPage.justify_design.publicPage:not(.journeyPage)  .flexUpperContentWrapper > div.trebbleInfo > .bioWrapper{
    padding: 0px 40px;
}

.playlistDetailsPage.justify_design.publicPage .flexUpperContentWrapper > div.trebbleInfo > .bioWrapper > .textContent{
    text-align: start;
}

.playlistDetailsPage.justify_design.publicPage .listeningInstructionsBox{
    margin: 40px 0px 10px;
}

.playlistDetailsPage.justify_design.publicPage:not(.journeyPage)  .listeningInstructionsBox{
    width: calc(100% - 120px);
}


.flexUpperContentWrapper {
    flex: 0 0 35%;
    -ms-flex: 0 0 35%;
    -webkit-flex: 0 0 35%;
    -moz-flex: 0 0 35%;
    display: flex;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: -moz-flexbox;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex-direction: column;
    justify-content: flex-start;
    -webkit-justify-content:flex-start;
    -ms-justify-content:flex-start;
    -moz-justify-content: flex-start;
    margin-bottom: 60px;
    height: fit-content;
}
.playlistDetailsPage .artistDetailCoverArtWrapper{
  flex: 0 0 640px;
  -ms-flex: 0 0 640px;
  -webkit-flex: 0 0 640px;
  -moz-flex: 0 0 640px;
  display: flex;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -moz-flexbox;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-justify-content: center;
  -webkit-align-items: center;
  padding: 0px;
}

.userDetailCoverArtWrapper,
.albumDetailCoverArtWrapper,
.artistDetailsPage:not(.fulldetails) .artistDetailCoverArtWrapper{
   flex: 0 0 35%;
   -ms-flex:  0 0 35%;
   -webkit-flex:  0 0 35%;
   -moz-flex:  0 0 35%;
   display: flex;
   display: -webkit-flex;
   display: -ms-flexbox;
   display: -moz-flexbox;
   -webkit-justify-content: center;
   -ms-flex-pack: center;
   justify-content: center;
   -webkit-align-items: center;
   -ms-flex-align: center;
   align-items: center;
   -webkit-justify-content: center;
   -webkit-align-items: center;
   padding: 0px;
}
/*
#fullCoverArtImage {
    width: 90%;
    left: auto;
    position: relative;
    height: 340px;
    box-shadow: none !important;
    background-color: transparent !important;
}
*/

.playlistDetailsPage  #albumList,
.albumDetailsPage  #albumList,
.artistDetailsPage:not(.fulldetails)  #albumList {
    -webkit-box-flex: 1;
    flex: 1 1 100%;
    -ms-flex: 1 1 100%;
    -webkit-flex: 1 1 100%;
    -moz-flex: 1 1 100%;
    margin-top: 50px;
}

.playlistDetailsPage .listHeader.center,
.albumDetailsPage .listHeader.center,
.artistDetailsPage:not(.fulldetails) .listHeader.center {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    -ms-justify-content: center;
}


.playlistDetailsPage .playlistShortcutButtonsWrapper {
    top: 60px;
    position: absolute;
}

.albumDetailsPage  .cornerDecorator,
.artistDetailsPage:not(.fulldetails)  .cornerDecorator,
.playlistDetailsPage  .cornerDecorator {
    position: absolute;
    height: 100%;
    width: 100%;
    right: 0px;
    top: 0px;
    display: block;
    background-image: linear-gradient(to left,var(--color-black-0),  var(--color-black) 100%);
}


body.browser * .basicAlbumInfo {
  height: 40%;
}

.playlistDetailsPage .artistDetailCoverArtWrapper,
.albumDetailCoverArtWrapper {
    height: 640px;
}

.userDetailCoverArtWrapper{
    height: 400px;
}
.playlistDetailsPage.publicPage .artistDetailCoverArtWrapper{
    height: 400px;
    top:0px;
}

.bioWrapper * .carouselItem > .textContent {
    font-size: var(--text-sm-px);
}





.playlistDetailsPage #myCapsulesListWrapper *  #collection_repeat_container  .capsuleCard,
.playlistDetailsPage #myArchivedCapsulesListWrapper *  #collection_repeat_container  .capsuleCard {
    width: 100%;
}

.journeyPage #myCapsulesListWrapper #collection_repeat_container{
    padding: 10px;
    box-sizing: border-box;
}

.playlistDetailsPage .actionButtonWrapper.floatingBtn {
    width: 90%;
    display: -webkit-box;
    display: flex;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: -moz-flexbox;
    margin-left: auto;
    margin-right: auto;
}

.playlistDetailsPage #albumList > .actionButtonWrapper {
    display: none;
}
}

.playlistDetailsPage.publicPage .infoBoxWrapper{
    height: fit-content !important;
    width: fit-content !important;
    min-height: 400px;
}

@media (max-width: 350px) , (max-height: 350px) and  (orientation: landscape) {
  #fullCoverArtImage {
   width: 200px;
   left: calc((100% - 200px)/2);
   top: calc((100% - 200px)/2);
   height: 200px;
}


.artistDetailCoverArtWrapper,
.userDetailCoverArtWrapper,
.albumDetailCoverArtWrapper {
    height: 400px;
}


}

@media (max-width: 300px) , (max-height: 300px) and  (orientation: landscape) {
  #fullCoverArtImage {
     width: 200px;
     left: calc((100% - 200px)/2);
     top: calc((100% - 200px)/2);
     height: 200px;
 }

 .artistDetailCoverArtWrapper,
 .userDetailCoverArtWrapper,
 .albumDetailCoverArtWrapper {
    height: 300px;
}


}

@media (max-width: 200px) , (max-height: 200px) and  (orientation: landscape) {
  #fullCoverArtImage {
    width: 180px;
    left: calc((100% - 180px)/2);
    height: 180px;
}

.artistDetailCoverArtWrapper,
.userDetailCoverArtWrapper,
.albumDetailCoverArtWrapper {
    height: 300px;
}


}



.swiper-button-next.ion-ios7-arrow-right,
.swiper-button-prev.ion-ios7-arrow-left,
.swiper-button-next.ion-ios7-arrow-down,
.swiper-button-next.ion-chevron-down,
.swiper-button-prev.ion-ios7-arrow-up,
.swiper-button-prev.ion-chevron-up  {
    background-image: none;
    font-size: var(--text-5xl);
    color: white;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    display: flex;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: -moz-flexbox;
    box-sizing: border-box;
    -webkit-justify-content: center;
    -webkit-align-items: center;
    border-radius: 50%;
    background-color: var(--color-black-30);
    height: 50px;
    width: 50px;
    flex: 0 0 50px;
    box-shadow: 0 5px 10px var(--color-black-50);
    backdrop-filter: blur(20px);
}

@supports not (backdrop-filter: blur(20px)) {
    .swiper-button-next.ion-ios7-arrow-right,
    .swiper-button-prev.ion-ios7-arrow-left,
    .swiper-button-next.ion-ios7-arrow-down,
    .swiper-button-next.ion-chevron-down,
    .swiper-button-prev.ion-ios7-arrow-up,
    .swiper-button-prev.ion-chevron-up  {
        background-color: var(--color-black-30);
    }

}
.swiper-button-next.ion-ios7-arrow-down,
.swiper-button-prev.ion-chevron-up,
.swiper-button-next.ion-ios7-arrow-down,
.swiper-button-prev.ion-chevron-down {
    height: 20px;
    height: 30px;
    left: calc(50% - 15px);
}

.swiper-button-next.ion-ios7-arrow-down,
.swiper-button-next.ion-chevron-down{
    top: 100%;
}


.swiper-button-prev.ion-ios7-arrow-up,
.swiper-button-prev.ion-chevron-up {
    top: 30px;
}
#ExplorePage[showListView=true] * .swiper-button-next.ion-ios7-arrow-right,
#ExplorePage[showListView=true] * .swiper-button-prev.ion-ios7-arrow-left,
#Onboarding[showListView=true] * .swiper-button-next.ion-ios7-arrow-right,
#Onboarding[showListView=true] * .swiper-button-prev.ion-ios7-arrow-left,
.TrebbleStatisticPage.lighttext * .swiper-button-next.ion-ios7-arrow-right,
.TrebbleStatisticPage.lighttext * .swiper-button-prev.ion-ios7-arrow-left {
    color: white;
}

#recordProgressContainer .swiper-button-next.ion-ios7-arrow-right,
#recordProgressContainer .swiper-button-prev.ion-ios7-arrow-left {
  font-size: var(--text-xl);
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  display: flex;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -moz-flexbox;
  box-sizing: border-box;
  -webkit-justify-content: center;
  -webkit-align-items: center;
  box-shadow: none;
  background-color: transparent;
}
#recordProgressContainer  .trackItem{
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    display: flex;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: -moz-flexbox;
    box-sizing: border-box;
    -webkit-justify-content: center;
    -webkit-align-items: center;
    height: 100%;
    padding: 0px 60px;
    box-sizing: border-box;
}

#recordProgressContainer #playButton  .ion-loading-c{
  left: -20px;
  position:relative;
}
#recordProgressContainer:not(.lighttext) svg:nth-child(2) path:first-child{
    stroke: var(--color-black-10);
}
.ui-page.lighttext #recordProgressContainer svg:nth-child(2) path:first-child,
body.darkTheme .ui-page:not(.lighttext) #recordProgressContainer svg:nth-child(2) path:first-child{
    stroke: var(--color-white-10);
}
.ui-page:not(.lighttext) .songTitle,
.ui-page:not(.lighttext) .itemTitle{
color: var(--text-dark);
}


#recordProgressContainer  .trackItem .downloadInfo{
    font-size: 80%;
}
#selectedBackgroundMusicSongWrapper {
    width: 100%;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    height: 80px;
    display: flex;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: -moz-flexbox;
    box-sizing: border-box;
    -webkit-justify-content: center;
    -webkit-align-items: center;
    -webkit-flex-direction: row;
    background-color: var(--color-white-10);
}
#selectedBackgroundMusicSongWrapper > #noSongSelectedInfoBox {

    width: 100%;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-items: center;
    -webkit-justify-content: center;
    display: flex;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: -moz-flexbox;
    font-size: var(--text-base);
}
#selectedBackgroundMusicSongWrapper > #selectedSongInfoPreview {
    width: 100%;
    box-sizing: border-box;
    padding: 10px;
    display: flex;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: -moz-flexbox;
}
.statsTabInfo {
  color: var(--color-white-50);
  border-bottom: var(--border-1) solid var(--color-white-50);
  box-sizing: border-box;
  font-size: var(--text-md);
  padding: 10px;
  cursor: pointer;
}
body.browser.mobileweb .statsTabInfo:hover{
  background-color: var(--color-white-10);
}


.statsTabInfo > .tabLabel > span {
    margin-right: 5px;
}
.swiper-slide-active > .statsTabInfo {
  color: white;
  border-bottom: var(--border-1) solid white;
}
.statsTabInfo > .totalCount {
    font-size: var(--text-6xl);
    font-weight: 700;
}
.carouselItem .urlPreviewWrapper,
.swiper-slide .urlPreviewWrapper{
    width: 100%;
}



.statsWrapper .ct-series-b .ct-bar,
.statsWrapper .ct-series-b .ct-line,
.statsWrapper .ct-series-b .ct-point,
.statsWrapper .ct-series-b .ct-slice-donut {
    stroke: var(--color-white-50);
    /*stroke-dasharray: 10px 20px;
    animation: dashoffset 1s linear infinite;
    -moz-animation: dashoffset 1s linear infinite;
    -o-animation: dashoffset 1s linear infinite;
    animation: dashoffset 1s linear infinite;
    stroke-dasharray: 10px 20px;*/
}
.statsWrapper .ct-series-a .ct-bar,
.statsWrapper .ct-series-a .ct-line,
.statsWrapper .ct-series-a .ct-point,
.statsWrapper .ct-series-a .ct-slice-donut {
    stroke: white;
    stroke-width: 3px;
}
#trebbleStatisticContent .ct-line{
  stroke-width: 5px;
}
.statsWrapper .ct-series-a .ct-bar {
    stroke-width: 10px;
}
@media (min-width: 700px) and (max-width: 1000px){
    .statsWrapper  .ct-bar {
        stroke-width: 20px !important;
    }
}
@media (min-width: 1000px) and (max-width: 1300px){
    .statsWrapper  .ct-bar {
        stroke-width: 30px !important;
    }
}
@media (min-width: 1300px) {
    .statsWrapper  .ct-bar {
        stroke-width: 40px !important;
    }
}
.statsWrapper .ct-series-a .ct-point {
    stroke-width: 8px;
}
.statsWrapper .ct-label {
    color: white;
    font-size: var(--text-base);
}
#trebbleStatisticContent {
    margin-top: 0px;
    padding: 0px;
    height: 513px;
}
#chartGeneralInfoWrapper,
#chartLegend {
    display: flex;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: -moz-flexbox;
}
#chartLegend {
    justify-content: center;
    align-items: flex-end;
    -webkit-justify-content: center;
    -webkit-align-items: flex-end;
    flex-direction: column;
    width: 100%;
    flex: 1 1 100%;
    -webkit-flex-direction: column;
    padding: 4px;
    padding-left: 10px;
    padding-right: 10px;
    color: white;
}
.legendColor {
    width: 10px;
    box-sizing: border-box;
    display: inline-block;
    padding: 5px;
    margin-left: 10px;
    height: 10px;
}

@media (max-width: 500px) ,(max-height: 500px) and (orientation: landscape) {
  .statsTabInfo {
    font-size: var(--text-sm);
}

.statsTabInfo > .totalCount {
    font-size: var(--text-2xl);
    font-weight: 700;
}

#trebbleStatisticContent .ct-line {
    stroke-width: 2px;
}

#statisticContent .legenItem,
#songStatisticContent .legenItem,
#trebbleStatisticContent .legenItem{
    font-size: var(--text-xs);
}

.statsWrapper .ct-label {
  font-size: var(--text-xs);
}

.statsWrapper .ct-series-b .ct-slice-donut {
    stroke-dasharray: 5px 10px;
}
}
.volatile-counter {
    position: absolute;
    top: -60px;
    left: calc(50% - 17px);
    overflow: visible;
    font-weight: 300;
    font-size: var(--text-xs);
}
#playerFooter.browser * .volatile-counter {
    top: -30px;
    font-size: var(--text-xs);
}
.volatile-counter > span {
    font-size: var(--text-lg);
}
#playerFooter.browser * .volatile-counter > span {
    font-size: var(--text-md);
}

#loginPopupContentWrapper {
    align-items: center;
    justify-content: center;
    display: flex;
    -webkit-align-items: center;
    -webkit-justify-content: center;
    -webkit-display: flex;
    -ms-align-items: center;
    -ms-justify-content: center;
    -ms-display: flex;
}
.sharedInfoWrapper {
    display: flex;
    display: -webkit-flex;
    display: -ms-flex;
    display: -moz-flex;
    justify-content: center;
    align-items: center;
    -webkit-justify-content: center;
    -webkit-align-items: center;
    -ms-justify-content: center;
    -ms-align-items: center;
    -moz-justify-content: center;
    -moz-align-items: center;
    flex-direction: column;
}
.sharedInfoWrapper > .ui-input-text {
    margin: 8px;
    border: var(--border-0) !important;
    flex: 0 0 auto;
    -webkit-flex:  0 0 auto;
    -ms-flex:  0 0 auto;
    -moz-flex:  0 0 auto;
    padding: 0px 10px;
    width: 100%;
    box-sizing: border-box;
}

.sharedInfoWrapper > .sharedInfoWrapper__button_group {
 display: flex;
 flex-direction: row;
 box-sizing: border-box;
 justify-content: center;
 align-items: center;
 padding: 10px 10px;
}
#shareTrebblePopupContent {
    width: 100%;
    box-sizing: border-box;
    max-width: 700px;
    padding: 10px;
    height: auto;
    overflow: auto;
}
.trebbleShareBoxWrapper {
    /*height: 80px;*/
}
.trebbleShareBoxWrapper a{
  font-weight:bold;
  color:white;
}

.trebbleShareBoxWrapper > .shareLinkLabel{
    text-align: left;
    padding: 0px 10px;
}

.trebbleShareBoxWrapper .htmlTableEmbed{
    width: 100%;
    padding: 10px;
    margin: 10px;
    background-color: var(--color-white-30);
    box-sizing: border-box;
}

.trebbleShareBoxWrapper .htmlTableEmbed td ,
.trebbleShareBoxWrapper .htmlTableEmbed th{
    padding:  0px;
}

[data-role=page].transparent [class*=" pe-7s-"],[data-role=page].transparent [class^=pe-7s-]{
    font-weight: 400;
}

#textToSpeechBlock,
#useVoiceActorBlock{
   text-align: justify;
   height: calc(100% - 50px);
   flex-direction: column;
   display: flex;
   padding: 20px;
}
#textToSpeechBlock #textToConvertFieldBox{
    flex: 1 1 100%;
}
#useVoiceActorBlock [for=textToConvertByVoiceActorFieldBox],
#useVoiceActorBlock [for=instructionsForVoiceActorFieldBox]{
    margin:0px;
}

[data-role=page].createCapsulePage label.label_description{
    font-size: 90%;
    opacity: 0.5;
}

[data-role=page].createCapsulePage .field_wrapper.fullLength{
    margin: 5px 0px;
}
[data-role=page].createCapsulePage .field_wrapper{
    margin: 5px auto;
}
#textToSpeechBlock #textToConvertFieldBox,
#textToSpeechBlock [for=ttsLanguageFld], 
[for=newsletterEmbedThemeSelector],
#useVoiceActorBlock #textToConvertFieldBox,
#useVoiceActorBlock [for=ttsLanguageForVoiceActorFld],
#useVoiceActorBlock [for=textToConvertByVoiceActorFieldBox],
#useVoiceActorBlock [for=instructionsForVoiceActorFieldBox],
#useVoiceActorBlock [for=voiceProfileFld],
#useVoiceActorBlock [for=voiceToneFld]{
    text-align: justify;
}

.response_box{
    display: flex;
    flex-direction: row;
}

@media (max-width: 500px), (max-height: 500px) and (orientation: landscape) { 
    .response_box{
     flex-direction: column;
 }
}

.response_box > *{
    flex: 1 1 100%;
}

.capsuleConfigWrapper,
.publishingSettingWrapper,
.schedulingSettingWrapper,
.schedulingTimezoneWrapper,
[for=capsuleLifeSpanFld],
[for=ttsLanguageFld],
[for=microphoneFld],
[for=newsletterEmbedThemeSelector],
[for=textToConvertFieldBox],
[for=titleFieldFld],
.privacySettingWrapper,
[for=privacySettingFld],
[for=capsuleTitleFld],
.categoryConfigWrapper,
.doNotAddOnAirCheckboxWrapper,
.schedulingSettingWrapper,
.publishingSettingWrapper,
#textToSpeechBlock,
#useVoiceActorBlock,
.titleFieldWrapper,
.languageUsedInAudioFldWrapper,
.ttsLanguageFieldWrapper,
[for=categoryFld],
.songToPlayAfterCapsuleFieldWrapper,
[for=songToPlayAfterCapsuleFld],
.backgroundMusicFieldWrapper,
[for=backgroundMusicFld],
#songToPlayAfterCapsuleInputPlaceholder,
#backgroundMusicInputPlaceholder{
  width: 100%;
  box-sizing: border-box;
  /*padding-top: 10px;*/
  padding-bottom: 4px;
  /*color: var(--text-dark);*/
  max-width: 700px;
  margin-right: auto;
  margin-left: auto;
}

.schedulingTimeWrapper #schedulingTimeFld{
    border: var(--border-0);
    height: 40px;
    background: var(--color-white-10);
    margin: 0px;
    text-align: center;
    color: white;
}

.capsuleCreationWrapper .capsuleInfo  *.isHidden,
#trebbleInfoDetailWrapper *.isHidden{
  display:none !important;
}

.capsuleCard #privacy,
.capsuleCard .privateIcon{
    color: var(--trebble-primary) !important;
    
}
.capsuleCard #privacy,
.capsuleCard .privateIcon,
[data-role=page] * .capsuleCard * .itemSubtitle#schedulingInfo,
[data-role=page] * .capsuleCard * .itemSubtitle#expirationDate{
   font-weight: 700;   
}

.capsuleCard .pe-7s-lock{
  font-size: var(--text-md);
  line-height: 18px;
  color: var(--trebble-primary);
  font-weight:bold;
}
.ui-field-contain > div >  div.ui-input-text {
  border-color: transparent !important;
}
#titleField{
  color: white;
  background-color: var(--color-white-10);
}
.ui-page.ui-page:not(.lighttext) #titleField{
    color: var(--text-dark);
}
.artistDetailsPage.full * #artistProfileWrapper,
.userProfileDetailsPage * #userOverviewInfoWrapper {
    display: flex;
    display: -webkit-flex;
    display: -ms-flex;
    display: -moz-flex;
    flex-direction: column;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    -moz-flex-direction: column;
}
.artistDetailsPage.full * #artistProfileWrapper > #albumAndTracksSummaryWrapper,
.artistDetailsPage.full * #artistProfileWrapper > #artistBioAndSocialInfoWrapper,
.userProfileDetailsPage * #userOverviewInfoWrapper > #capsuleHistoryWrapper, 
.userProfileDetailsPage * #userOverviewInfoWrapper > #songsAndTrebbleSummaryWrapper {

    display: inline-table;
    width: 100%;
    flex: 0 0 100%;
    -webkit-flex: 0 0 100%;
    -ms-flex: 0 0 100%;
    -moz-flex: 0 0 100%;
}
body.browser > .artistDetailsPage.full * #artistProfileWrapper > #artistBioAndSocialInfoWrapper,
body.browser > .userProfileDetailsPage * #userOverviewInfoWrapper > #songsAndTrebbleSummaryWrapper  {
    flex: 0 0 420px;
    -webkit-flex: 0 0 420px;
    display: block;
    -ms-flex: 0 0 420px;
    -moz-flex: 0 0 420px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}
body.browser > .artistDetailsPage.full * #artistProfileWrapper > #albumAndTracksSummaryWrapper,
body.browser > .userProfileDetailsPage * #userOverviewInfoWrapper > #capsuleHistoryWrapper {
    flex: 1 1 100%;
    -webkit-flex: 1 1 100%;
    -ms-flex: 1 1 100%;
    -moz-flex: 1 1 100%;
    /*min-width: 700px;*/
    display: block;
}

body.browser > .artistDetailsPage.full * #artistProfileWrapper, 
body.browser > .userProfileDetailsPage * #userOverviewInfoWrapper{
    display: flex;
    display: -webkit-flex;
    display: -ms-flex;
    display: -moz-flex;
    flex-direction: row;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    -moz-flex-direction: row;
}

[data-role=page].lighttext * #bioContainer {
    color: var(--color-white-80);
}
@media (max-width: 800px) {

    body.browser > .userProfileDetailsPage * #userOverviewInfoWrapper {
      flex-direction: column-reverse;
      -webkit-flex-direction: column-reverse;
      -ms-flex-direction: column-reverse;
      -moz-flex-direction: column-reverse;
  }
  body.browser > .artistDetailsPage.full * #artistProfileWrapper {
      flex-direction: column;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      -moz-flex-direction: column;
  }
  body.browser > .artistDetailsPage.full * #artistProfileWrapper > #albumAndTracksSummaryWrapper,
  body.browser > .userProfileDetailsPage * #userOverviewInfoWrapper > #capsuleHistoryWrapper ,
  body.browser > .artistDetailsPage.full * #artistProfileWrapper > #artistBioAndSocialInfoWrapper,
  body.browser > .userProfileDetailsPage * #userOverviewInfoWrapper > #songsAndTrebbleSummaryWrapper {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    -moz-flex: 0 0 auto;
}
}
#bioContainer {
    color: var(--text-dark);
    cursor: pointer;
    text-align: start;
}
.ellipsis {
    overflow: hidden;
    position: relative;
    height: 90px;
    line-height: 25px;
}
.ellipsis:before {
    content: "";
    float: left;
    width: 5px;
    height: 90px;
}
.ellipsis > *:first-child {
    float: right;
    width: 100%;
    margin-left: -5px;
}
.ellipsis:after {
    content: "\02026 Read More";
    box-sizing: content-box;
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    float: right;
    position: relative;
    top: -25px;
    left: 100%;
    width: 7em;
    margin-left: -7em;
    padding-right: 5px;
    text-align: right;
    background-size: 100% 100%;
    /* 1706x1 image, gradient for IE9. Transparent at 0% -> white at 15% -> white at 100%.*/
    
    background: -webkit-gradient(linear, left top, right top, from(var(--color-white-0)), to(var(--color-black)), color-stop(15%, var(--color-black)));
    background: -moz-linear-gradient(to right, var(--color-white-0), var(--color-black) 15%, var(--color-black));
    background: -o-linear-gradient(to right, var(--color-white-0), var(--color-black) 15%, var(--color-black));
    background: -ms-linear-gradient(to right, var(--color-white-0), var(--color-black) 15%, var(--color-black));
    background: linear-gradient(to right, var(--color-white-0), var(--color-black) 15%, var(--color-black));
}
.ellipsis a {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    text-indent: -9999px;
}
.ellipsis i,
.ellipsis:after {
    font-style: normal;
    color: var(--text-muted-dark);
}
.lighttext * .ellipsis i,
.ellipsis:after {
    font-style: normal;
    color: var(--color-white-30);
}
.ellipsis i:before {
    content: "Read More";
}
#player * #commentOverviewBox > div {
    width: 100%;
}
.artistPhotoAndName {
    display: flex;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    flex-direction: row;
    -webkit-flex-direction: row;
    -moz-flex-direction: row;
    -ms-flex-direction: row;
    align-items: center;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    height: 50px;
}
.artistPhotoAndName > div {
    padding-left: 10px;
    padding-right: 10px;
    box-sizing: border-box;
    font-weight: 600;
    opacity: 0.7;
}
.artistPhotoAndName > img {
    object-fit: cover;
    height: 50px;
    width: 50px;
    border-radius: 50%;
    flex: 0 0 50px;
    -webkit-flex: 0 0 50px;
    -moz-flex: 0 0 50px;
    -ms-flex: 0 0 50px;
}
.lighttext * .artistBioSummary {
    padding: 20px;
    color: white;
    max-width: 80%;
}
.artistSummaryItem#bioCarouselItem {
    display: flex;
    justify-content: center;
    align-items: center;
    display: -webkit-flex;
    -webkit-justify-content: center;
    -webkit-align-items: center;
    display: -moz-flex;
    -moz-justify-content: center;
    -moz-align-items: center;
    display: -ms-flex;
    -ms-justify-content: center;
    -ms-align-items: center;
}
.lighttext * .artistBioSummary > .bio {
    height: 90px;
    font-size: var(--text-sm);
    line-height: 25px;
    overflow: auto;
    padding: 10px;
}
#topSongsCarouselItem,
#albumsCarouselItem,
#youtubeVideosCarouselItem {
    padding-left: 50px;
    padding-right: 50px;
}
.lighttext * .swiper-pagination-bullet {
    background: var(--color-white-40);
}
.lighttext * .swiper-pagination-bullet.swiper-pagination-bullet-active {
    background: white;
}
#reponsivePlayerContent * .swiper-pagination-bullet {
    /*background-color: var(--color-white-30);*/
    background-color: transparent;
    border: var(--border-2) solid white;
    opacity: 1;
    width: 6px;
    height: 6px;
}
#reponsivePlayerContent * .swiper-pagination {
    top: 10px;
}
#reponsivePlayerContent * .swiper-pagination-bullet.swiper-pagination-bullet-active {
    background-color: white;
}
.tooltip {
  position: absolute;
  display: inline-block;
  min-width: 5em;
  padding: .5em;
  background: white;
  color: var(--trebble-primary);
  text-align: center;
  pointer-events: none;
  font-weight: 400;
  z-index: 1;
}

.tooltip:after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  width: 0;
  height: 0;
  margin-left: -6px;
  border: var(--border-4) solid transparent;
  border-top-color: white;
}
.sweet-alert * .uploadZonePlaceHolder{
    display: block;
}
.sweet-alert button{
    border-radius: 0px !important;
}

.sweet-alert.trebble_sweet_alert.show-input h2{
    padding: 0 25px;
}

.sweet-alert button.confirm[disabled].show_orginal_text ~ .la-ball-fall{
    display: none;
}

.sweet-alert button.confirm[disabled].show_orginal_text{
    color: var(--text-light);
}

[data-role=page].transparent .uploadZonePlaceHolder,
body.darkTheme  [data-role=page]:not(.transparent) .uploadZonePlaceHolder{
    color: white;
}

.uploadZonePlaceHolder {
    height: 200px;
    background-color: var(--bg-light);
    border: var(--border-2) dashed var(--trebble-primary-200);
    display: flex;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webtki-flex;
    -webtki-justify-content: center;
    -webtki-align-items: center;
    display: -moz-flex;
    -moz-justify-content: center;
    -moz-align-items: center;
    display: -ms-flex;
    -ms-justify-content: center;
    -ms-align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    cursor: pointer;
    /*border: var(--border-2) dashed var(--trebble-primary);*/
}

.uploadZonePlaceHolder > span.dz-message{
    cursor: pointer;
}
body.browser.mobileweb .uploadZonePlaceHolder:hover,
.uploadZonePlaceHolder:active,
.uploadZonePlaceHolder.dz-drag-hover{

    background-color: var(--text-dark);
    border: var(--border-2) dashed var(--color-gray-300);
    color: white!important;
    
}

.uploadZonePlaceHolder.dz-started > span,
.uploadZonePlaceHolder.dz-started > .uploadIcon {
    display: none;
}
.uploadZonePlaceHolder > span {
  display: block;
  padding: 10px;
  box-sizing: border-box;
  font-size: var(--text-md);
  font-weight: bold;
  
}
.uploadZonePlaceHolder > span > .detailMessage{
    font-weight: normal;
    font-size: 80%;
}

@media (max-width: 500px), (max-height: 500px) and (orientation: landscape)
{
    .uploadZonePlaceHolder > span {

      font-size: var(--text-xs);

  }
  
}
.uploadZonePlaceHolder > .dz-image-preview > .dz-image > img {
    height: 70px;
    width: 70px;
    object-fit: contain;
}
.uploadZonePlaceHolder > .dz-preview {
 display: flex;
 display: -ms-flex;
 display: -webkit-flex;
 display: -moz-flex;
 justify-content: center;
 -webkit-justify-content: center;
 -moz-justify-content: center;
 -ms-justify-content: center;
 -webkit-align-items: center;
 align-items: center;
 -moz-align-items: center;
 -ms-align-items: center;
 font-weight: 400;
 padding: 10px;
 box-sizing: border-box;
 -webkit-flex-direction: column;
 -ms-flex-direction: column;
 flex-direction: column;
 height: 100%;
 width: 100%;
}
.uploadZonePlaceHolder > .dz-preview > .dz-success-mark,
.uploadZonePlaceHolder > .dz-preview > .dz-error-mark {
    display: none;
}

.uploadZonePlaceHolder > .dz-preview .dz-error-message{
    background-color: var(--error);
    margin: 10px;
}

.uploadZonePlaceHolder > .dz-preview .dz-error-message > span{
    /*padding: 10px;*/
    display: flex;
}

.uploadZonePlaceHolder > .dz-preview > .dz-details {
    padding-left: 10px;
    padding-right: 10px;
    text-align: center;
    width: 100%;
}

.uploadZonePlaceHolder > .dz-preview > .dz-details >  *{
  text-overflow: ellipsis;
  overflow: hidden;
}
.uploadZonePlaceHolder > .dz-preview > .dz-details > .dz-size{
  line-height:16px;
  font-size: var(--text-sm-px);
}

.uploadZonePlaceHolder {
    color: var(--trebble-primary-200);
    font-size: var(--text-sm-px);
    text-decoration: none;
    font-weight: 600;
}


.uploadZonePlaceHolder > .dz-preview > .dz-details > .dz-filename{
  font-size: var(--text-md-px);
  line-height:20px;
  white-space: nowrap;
}

.uploadZonePlaceHolder > .dz-preview .dz-remove{

    color: var(--trebble-primary-200);
    font-size: var(--text-sm-px);
    font-weight: 600;
    text-decoration: none;

}
.dz-preview .dz-progress,
.uploadProgressInfo .progressBar,
.popupContent .progressBar {
    opacity: 1;
    z-index: 1000;
    pointer-events: none;
    height: 6px;
    margin: 10px;
    width: calc(100% - 20px);
    transform: scale(1);
    background-color: var(--color-gray-300);
    overflow: hidden;
}
.dz-preview .dz-progress .dz-upload,
.uploadProgressInfo .progressBar > .currentProgress,
.popupContent .progressBar > .currentProgress {
 background: var(--text-dark); 
 background: linear-gradient(to bottom, var(--color-gray-600), var(--text-dark)); 
 position: absolute; 
 top: 0; 
 left: 0; 
 bottom: 0;
 width: 0; 
 -webkit-transition: width 300ms ease-in-out;
 -moz-transition: width 300ms ease-in-out; 
 -ms-transition: width 300ms ease-in-out; 
 -o-transition: width 300ms ease-in-out; 
 transition: width 300ms ease-in-out; 
}

.ui-page.lighttext .uploadProgressInfo .progressBar,
.popupContent .progressBar {
  background-color: var(--color-white-20);
}
.ui-page.lighttext  .uploadProgressInfo .progressBar > .currentProgress,
body.darkTheme .ui-page:not(.lighttext) .uploadProgressInfo .progressBar > .currentProgress,
.popupContent .progressBar > .currentProgress{
  background: white;
}

.ui-page:not(.lighttext) .uploadProgressInfo .progressBar{
  background-color: var(--color-black-10);
}

.ui-page.lighttext .uploadProgressInfo .progressBar,
body.darkTheme .ui-page:not(.lighttext) .uploadProgressInfo .progressBar{
    background-color: var(--color-white-10);
  }
/*
.ui-page:not(.lighttext)  .uploadProgressInfo .progressBar > .currentProgress{
  background: var(--gradient-primary-alt);
}*/

@keyframes indeterminatePogressBarAnimation {
  0% {
    transform:  translateX(0) scaleX(0);
}
40% {
    transform:  translateX(0) scaleX(0.4);
}
100% {
    transform:  translateX(100%) scaleX(0.5);
}
}

.progressContentWrapper.inderterminate #percentageProgress{
    display: none;
}

.progressContentWrapper.inderterminate .currentProgress{
    animation: indeterminatePogressBarAnimation 1.5s infinite linear;
    transform-origin: 0% 50%;
}

@media (max-width: 500px), (max-height: 500px) and (orientation: landscape) {
    .progressContentWrapper #percentageProgress {
      font-weight: 900;
      font-size: var(--text-7xl-px);
  }

  .progressContentWrapper #progressMessage {
      font-weight: 600;
      font-size: var(--text-lg-px);
  }
  .progressContentWrapper #progressSubMessage {

      font-size: var(--text-sm-px);
  }

}


#showRecordSectionButton,
#showUploadSectionButton,
#recordControlsWrapper.uploadAvailable > [data-role=controlgroup] >:first-child > a#showUploadSectionButton,
#recordControlsWrapper.uploadAvailable > [data-role=controlgroup] >:first-child > o#showUploadSectionButton  {

    display: flex;
    display: -ms-flex;
    display: -webkit-flex;
    display: -moz-flex;
    justify-content: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    -ms-justify-content: center;
    -webkit-align-items: center;
    align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    font-weight: 400;

}
#showRecordSectionButton > #recordIcon,
#showUploadSectionButton > #uploadIcon{
    font-size: var(--text-3xl);
    margin-right: 10px;
    margin-left: 10px;

}

@media (max-width: 500px), (max-height: 500px) and (orientation: landscape) {
  #showUploadSectionButton{
    font-size: var(--text-xs-px) !important;
}
#showRecordSectionButton > #recordIcon, #showUploadSectionButton > #uploadIcon{
    font-size: var(--text-xl-px) !important;
}

#createCapsuleInfoHeader * .tag.actionButton  .pe-7s-upload{
    font-size: var(--text-6xl) !important;
}
}


body.browser.mobileweb .statsWrapper .ct-series-a .ct-bar:hover, 
body.browser.mobileweb .statsWrapper .ct-series-a .ct-point:hover,
body.browser.mobileweb  .statsWrapper .ct-series-a .ct-slice-donut:hover,
body.browser.mobileweb  .statsWrapper .ct-series-b .ct-bar:hover, 
body.browser.mobileweb .statsWrapper .ct-series-b .ct-point:hover,
body.browser.mobileweb .statsWrapper .ct-series-b .ct-slice-donut:hover,
body.browser.mobileweb .statsWrapper .ct-series-c .ct-bar:hover, 
body.browser.mobileweb .statsWrapper .ct-series-c .ct-point:hover,
body.browser.mobileweb  .statsWrapper .ct-series-c .ct-slice-donut:hover,
body.browser.mobileweb  .statsWrapper .ct-series-d .ct-bar:hover, 
body.browser.mobileweb .statsWrapper .ct-series-d .ct-point:hover,
body.browser.mobileweb .statsWrapper .ct-series-d .ct-slice-donut:hover{
  /*stroke-width: 20px;*/
  transform: translateZ(0);
  transform: translateZ(0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale;
  -webkit-transition-duration: 0.15s;
  transition-duration: 0.15s;
  -webkit-transition-property:all;
  transition-property: all;
  opacity: 0.6;
}
#trebbleStatisticContent .ct-grid{
  stroke: var(--color-white-70);
  stroke-width: 1px;
  stroke-dasharray: 2px;
}

#songStatisticContent .ct-grid {
  stroke: var(--color-white-20);
  stroke-width: 1px;
  stroke-dasharray: 2px;
}

#songStatisticContent .ct-series-a .ct-bar{
  stroke:var(--color-success-alt);
}
#songStatisticContent .legenItem .legendColor.trebbleContext{
  background-color:var(--color-success-alt);
}
#songStatisticContent .ct-series-b .ct-bar{
  stroke:var(--color-purple-550);
}
#songStatisticContent .legenItem .legendColor.sharedCapsuleContext{
  background-color:var(--color-purple-550);
}
#songStatisticContent .ct-series-c .ct-bar{
    stroke: var(--color-warning-400);
}
#songStatisticContent .legenItem .legendColor.sharedSongContext{
  background-color:var(--color-warning-400);
}

#statisticContent .legenItem,
#trebbleStatisticContent .legenItem,
#songStatisticContent .legenItem{
    text-align: right;
}

#songStatisticContent .ct-series-d .ct-bar{
  stroke:  var(--pink);
}
#songStatisticContent .legenItem .legendColor.executedBySongOwnerContext{
  background-color:var(--pink);
}

body.browser.mobileweb .hint:hover:before,
body.browser.mobileweb .hint:hover:after,
body.browser.mobileweb  [data-hint]:hover:before,  
body.browser.mobileweb [data-hint]:hover:after{
    -webkit-transition-delay: 100ms;
    -moz-transition-delay: 100ms;
    transition-delay: 100ms;
}

body.browser.mobileweb .hint:after,
body.browser.mobileweb [data-hint]:after {
    content: attr(data-hint);
    background: var(--color-gray-100);
    color:  var(--text-dark);
    padding: 8px 10px;
    /*font-size: var(--text-xs);*/
    line-height: 12px;
    white-space: nowrap;
    font-style: normal;
    font-weight: 400;
}

body.browser.mobileweb .audio_sequencer .hint:after,
body.browser.mobileweb .audio_sequencer [data-hint]:after {
    content: attr(data-hint);
    padding: 12px 14px;
    font-size: var(--text-sm-px);
}


body.browser.mobileweb .lighttext *  .hint--right:not(.light_theme):before,
body.browser.mobileweb #playerFooter:not(.transparent)  * .hint--right:before{
  border-right-color: var(--text-dark);
  
}

body.browser.mobileweb .lighttext *  .hint--left:not(.light_theme):before,
body.browser.mobileweb #playerFooter:not(.transparent)  * .hint--left:before{
  border-left-color: var(--text-dark);
}


body.browser.mobileweb .lighttext * .hint:after,
body.browser.mobileweb .lighttext *  [data-hint]:not(.light_theme):after,
body.browser.mobileweb #playerFooter:not(.transparent)  * .hint:after,
body.browser.mobileweb #playerFooter:not(.transparent)   *  [data-hint]:after {
  color: white;
  background: var(--text-dark);
}

body.browser.mobileweb .hint:after,
body.browser.mobileweb  [data-hint]:after,
body.browser.mobileweb  [data-hint]:before {
    text-shadow: none;
    box-shadow: 0 14px 42px 0 var(--color-black-20);
}
body.browser.mobileweb .hint--bottom,
body.browser.mobileweb  .hint--top,
body.browser.mobileweb   .hint--left,
body.browser.mobileweb  .hint--right{
    overflow: visible;

}
body.browser.mobileweb .hint--bottom:before {
    border-bottom-color: var(--color-gray-100);
}
body.browser.mobileweb .hint--left:before{
    border-left-color: var(--color-gray-100);
}
body.browser.mobileweb .hint--top:before{
    border-top-color: var(--color-gray-100);
}
body.browser.mobileweb .hint--right:before {
    border-right-color: var(--color-gray-100);
}

body.browser.mobileweb .lighttext * .hint--bottom:not(.light_theme):before {
    border-bottom-color: var(--text-dark);
}
body.browser.mobileweb .lighttext *  .hint--left:not(.light_theme):before{
    border-left-color: var(--text-dark);
}
body.browser.mobileweb .lighttext *  .hint--top:not(.light_theme):before{
    border-top-color: var(--text-dark);
}
body.browser.mobileweb .lighttext *  .hint--right:not(.light_theme):before {
    border-right-color: var(--text-dark);
}

body.browser.mobileweb t.hint--bottom,
body.browser.mobileweb  t.hint--top,
body.browser.mobileweb   t.hint--left,
body.browser.mobileweb  t.hint--right{
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
}

body.browser:not(.mobileweb) * .hint--bottom:before,
body.browser:not(.mobileweb) *  .hint--top:before,
body.browser:not(.mobileweb) *  .hint--left:before, 
body.browser:not(.mobileweb) *  .hint--right:before,
body.browser:not(.mobileweb) * .hint--bottom:after,
body.browser:not(.mobileweb) *  .hint--top:after,
body.browser:not(.mobileweb) *  .hint--left:after, 
body.browser:not(.mobileweb) *  .hint--right:after,
body:not(.browser) * .hint--bottom:before,
body:not(.browser) *  .hint--top:before,
body:not(.browser) *  .hint--left:before, 
body:not(.browser) *  .hint--right:before,
body:not(.browser) * .hint--bottom:after,
body:not(.browser) *  .hint--top:after,
body:not(.browser) *  .hint--left:after, 
body:not(.browser) *  .hint--right:after{
    display: none;

}


body.browser.mobileweb #addToTrebbleOptionButtuon.ion-ios7-checkmark-outline:hover:before,
#addToTrebbleOptionButtuon.ion-ios7-checkmark-outline:active:before,
#songIsFavoriteBtn:active > t.ion-ios7-checkmark-outline:before,
body.browser.mobileweb #songIsFavoriteBtn:hover > t.ion-ios7-checkmark-outline:before{
    content:'\f2bb';
}

body.browser.mobileweb #addToTrebbleOptionButtuon.ion-ios7-checkmark-outline.ion-loading-c:hover:before,
#addToTrebbleOptionButtuon.ion-ios7-checkmark-outline.ion-loading-c:active:before,
#songIsFavoriteBtn:active > t.ion-ios7-checkmark-outline.ion-loading-c:before,
body.browser.mobileweb #songIsFavoriteBtn:hover > t.ion-ios7-checkmark-outline.ion-loading-c:before{
    content: '\f29c';

}
.animate-spin,
body.browser.mobileweb #addToTrebbleOptionButtuon.ion-ios7-checkmark-outline.ion-loading-c:hover,
#addToTrebbleOptionButtuon.ion-ios7-checkmark-outline.ion-loading-c:active,
#songIsFavoriteBtn:active > t.ion-ios7-checkmark-outline.ion-loading-c,
body.browser.mobileweb #songIsFavoriteBtn:hover > t.ion-ios7-checkmark-outline.ion-loading-c,
body.browser.mobileweb #playButton.ion-loading-c:hover,
#playButton.ion-loading-c:active{
    background-color: transparent !important;
}

.trebbleExplicitIcon{
    display: flex;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-align-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-justify-content: center;
    background-color: var(--trebble-primary);
    width: 15px;
    height: 15px;
    border-radius: 2px;
    font-size: var(--text-2xs);
    line-height: 15px;
    color: white;
    align-self: center;
    -webkit-align-self: center;
    -moz-align-self: center;
    -ms-align-self: center;
}

.trebbleExplicitIcon:before{
    content: 'E';
}


#trebbleInfoDetailWrapper {
  max-width: 1000px;
  margin: auto;
  color: var(--text-dark);
  background-color: white;
  padding: 10px;

  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-align-content: center;
  -ms-flex-pack: center;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-item-align: center;
  align-self: center;
  -webkit-align-self: center;
  -moz-align-self: center;
  -ms-align-self: center;
  flex-direction: column;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;

}

@media (min-width: 800px), (min-height: 800px) and (orientation: landscape){
  #trebbleInfoDetailWrapper {
    flex-direction: row;
    -webkit-flex-direction: row;
    -moz-flex-direction: row;
    -ms-flex-direction: row;
}

#trebbleInfoDetailWrapper > .coverArtWrapper{
    width:fit-content;
    height:fit-content;
    margin:0px 10px;
    max-width: 35%;
}
}


.fieldDesc{
 font-size: small;
 color: var(--color-gray-500);
 padding: 0px 10px;
 /* background: var(--color-black-5); */
 /* padding: 8px; */
 /* padding-left: 28px; */
 margin-bottom: 20px;
}

#trebbleInfoDetailWrapper > .coverArtWrapper{
    position: relative;
    display: flex;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    flex-direction: column;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    background-color: white;
    margin: 20px;

}

#trebbleInfoDetailWrapper > .coverArtWrapper:before{
 color: var(--color-gray-300);
 font-size: var(--text-6xl);
 font-weight: 100;
 padding: 25px;
 position: absolute;
 top: calc(50% - 50px);
 left: calc(50% - 50px);
 /* border: var(--border-1) solid var(--color-gray-300); */
 /* background-color: var(--pulse-gray); */
 border-radius: 5px;
}

#trebbleInfoDetailWrapper > .coverArtWrapper > .trebbleCoverArt{
    object-fit: contain;
    height: 300px;
    /*margin: 20px 0px;*/
}

#trebbleInfoDetailWrapper > .coverArtWrapper > .trebbleCoverArt[src='../img/videoLoading.png'] ~ #editTrebbleCoverPicBtn{
   border-color: var(--color-white-100);
   background-color: var(--color-white-100);
   color: var(--text-dark) !important;
   font-family: "Montserrat",sans-serif;

}
body.browser.mobileweb #trebbleInfoDetailWrapper > .coverArtWrapper > .trebbleCoverArt[src='../img/videoLoading.png'] ~ #editTrebbleCoverPicBtn:hover,
#trebbleInfoDetailWrapper > .coverArtWrapper > .trebbleCoverArt[src='../img/videoLoading.png'] ~ #editTrebbleCoverPicBtn:active{
  background-color:transparent;
  border-color: white;
  color: white !important;
}
#trebbleInfoDetailWrapper > .coverArtWrapper > #editTrebbleCoverPicBtn{
  padding-top: 2px;
  padding-bottom: 2px;
  height: 100px;
  margin: 0px;
  font-weight: 300;
  line-height: 100px;
  margin-top: 50px;
  bottom: 20px;
  position: absolute;
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  display: -webkit-flex;
  -ms-flex-pack: center;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-direction: row;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
  -webkit-flex-direction: row;
  max-width: 100px;
  width: 100%;
  left: calc(50% - 61px);
  color: white;
  font-size: var(--text-sm);
  top: calc(50% - 111px);
  /* display: none; */
  font-size: var(--text-6xl-px);
  border:var(--border-0);
}


.audioPlaceholder > .content > a#editSweeperButton,
.audioPlaceholder > .content > a#deleteSweeperButton,
.audioPlaceholder > .content > a#editGreaterButton,
.audioPlaceholder > .content > a#deleteGreaterButton,
.audioPlaceholder > .content > a#editIntroButton,
.audioPlaceholder > .content > a#deleteIntroButton,
.audioPlaceholder > .content > a#editOutroButton,
.audioPlaceholder > .content > a#deleteOutroButton{

    flex: 0 0 50px;
    font-size: var(--text-3xl);
    padding: 1px;
    line-height: 40px;
    margin: 0px;
    background-color: transparent;
    height: 40px;
    border: var(--border-0);
    color: var(--trebble-primary);

}
.audioPlaceholder > .content > a#editSweeperButton:active,
.audioPlaceholder > .content > a#deleteSweeperButton:active,
body.browser.mobileweb .audioPlaceholder > .content > a#editSweeperButton:hover,
body.browser.mobileweb .audioPlaceholder > .content > a#deleteSweeperButton:hover,
.audioPlaceholder > .content > a#editGreaterButton:active,
.audioPlaceholder > .content > a#deleteGreaterButton:active,
body.browser.mobileweb .audioPlaceholder > .content > a#editGreaterButton:hover,
body.browser.mobileweb .audioPlaceholder > .content > a#deleteGreaterButton:hover,
.audioPlaceholder > .content > a#editIntroButton:active,
.audioPlaceholder > .content > a#deleteIntroButton:active,
body.browser.mobileweb .audioPlaceholder > .content > a#editIntroButton:hover,
body.browser.mobileweb .audioPlaceholder > .content > a#deleteIntroButton:hover,
.audioPlaceholder > .content > a#editOutroButton:active,
.audioPlaceholder > .content > a#deleteOutroButton:active,
body.browser.mobileweb .audioPlaceholder > .content > a#editOutroButton:hover,
body.browser.mobileweb .audioPlaceholder > .content > a#deleteOutroButton:hover{ 
    color: white !important;
    background-color: var(--trebble-primary) !important;
}


.audioPlaceholder > .content > .noAudioAvaliablePlaceholder,
.audioPlaceholder > .content > #playSweeperBtn,
.audioPlaceholder > .content > #playGreaterBtn,
.audioPlaceholder > .content > #playIntroBtn,
.audioPlaceholder > .content > #playOutroBtn{
    flex: 1 1 100%;
    -webkit-flex: 1 1 100%;
    -moz-flex: 1 1 100%;
    -ms-flex: 1 1 100%;
    -o-flex: 1 1 100%;

    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-items: center;
    -webkit-justify-content: center;
    padding-bottom: 5px;
    padding-top: 5px;
    text-align: center;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    -webkit-flex: 1 1 auto;
    /* height: 30px; */
    
    margin: 0px;
    padding-right: 0px;
    padding-left: 0px;
    font-weight: 300;
    /* background-color: var(--trebble-primary); */
    
    
    line-height: 25px;
    font-size: var(--text-sm);
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
    width: 100%;

}
.audioPlaceholder > .content,
.songSelectorPlaceholder > .content{
    display: flex;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    flex-direction: row;
    -webkit-flex-direction: row;
    -moz-flex-direction: row;
    -ms-flex-direction: row;
    /*padding: 0px 10px;*/
}
.audioPlaceholder > .content > #playSweeperBtn,
.audioPlaceholder > .content > #playGreaterBtn,
.audioPlaceholder > .content > #playIntroBtn,
.audioPlaceholder > .content > #playOutroBtn{
    border: var(--border-2) solid var(--trebble-primary);
    color: var(--trebble-primary);


}
.audioPlaceholder > .content > .noAudioAvaliablePlaceholder{
    background-color: var(--color-gray-100);
    color: var(--text-dark);
    display:none;

}
.audioPlaceholder > .content > #playSweeperBtn,
.audioPlaceholder > .content > #playGreaterBtn,
.audioPlaceholder > .content > #playIntroBtn,
.audioPlaceholder > .content > #playOutroBtn{

    cursor: pointer;
}
.audioPlaceholder > .content > #playSweeperBtn:active,
body.browser.mobileweb .audioPlaceholder > .content > #playSweeperBtn:hover,
.audioPlaceholder > .content > #playGreaterBtn:active,
body.browser.mobileweb .audioPlaceholder > .content > #playGreaterBtn:hover,
.audioPlaceholder > .content > #playIntroBtn:active,
body.browser.mobileweb .audioPlaceholder > .content > #playIntroBtn:hover,
.audioPlaceholder > .content > #playOutroBtn:active,
body.browser.mobileweb .audioPlaceholder > .content > #playOutroBtn:hover{
  cursor: pointer;
  transform: translateZ(0);
  transform: translateZ(0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: color, background-color;
  transition-property: color, background-color;
  background-color: var(--trebble-primary);
  border: var(--border-2) solid var(--trebble-primary);
  color: white;
}


.audioPlaceholder.noAudioAvailable > .content > .noAudioAvaliablePlaceholder{
    display: block;
}

.audioPlaceholder.noAudioAvailable > .content > #playSweeperBtn,
.audioPlaceholder.noAudioAvailable > .content > #playGreaterBtn,
.audioPlaceholder.noAudioAvailable > .content > #playIntroBtn,
.audioPlaceholder.noAudioAvailable > .content > #playOutroBtn{
    display: none;
}

.audioPlaceholder.noAudioAvailable > .content >  a#deleteSweeperButton,
.audioPlaceholder.noAudioAvailable > .content >  a#deleteGreaterButton,
.audioPlaceholder.noAudioAvailable > .content >  a#deleteIntroButton,
.audioPlaceholder.noAudioAvailable > .content >  a#deleteOutroButton{
    display: none;
}


.songSelectorPlaceholder.not_modifiable > .content > .noAudioAvaliablePlaceholder, 
.songSelectorPlaceholder.not_modifiable > .content > .selectedSongViewPlaceHolder{
    max-width: 100%;
}
.songSelectorPlaceholder > .content > #pickSongButton,
.songSelectorPlaceholder > .content > #removeSelectedButton{
    flex: 0 0 20px;
    font-size: var(--text-3xl);
    padding: 0px;
    line-height: 20px;
    margin: 0;
    background-color: transparent;
    height: 20px;
    border: var(--border-0);
    margin: 20px;
    color: var(--trebble-primary);
    display: flex;
    display: -moz-flex;
    align-items: center;
    justify-content: center;
    -webkit-align-items: center;
    -webkit-justify-content: center;
    cursor: pointer;

}

[data-role=page].transparent .songSelectorPlaceholder > .content > #pickSongButton,
[data-role=page].transparent .songSelectorPlaceholder > .content > #removeSelectedButton{
    color:white;
}
body.browser.mobileweb .lighttext .songSelectorPlaceholder > .content > #pickSongButton:hover,
body.browser.mobileweb .lighttext .songSelectorPlaceholder > .content > #removeSelectedButton:hover,
.lighttext .songSelectorPlaceholder > .content > #pickSongButton:active,
.lighttext .songSelectorPlaceholder > .content > #removeSelectedButton:active{
  color:white;
}

body.browser.mobileweb [data-role=page].transparent  .songSelectorPlaceholder > .content > #pickSongButton:hover,
body.browser.mobileweb [data-role=page].transparent  .songSelectorPlaceholder > .content > #removeSelectedButton:hover,
[data-role=page].transparent .songSelectorPlaceholder > .content > #pickSongButton:active,
[data-role=page].transparent .songSelectorPlaceholder > .content > #removeSelectedButton:active{
  color: var(--trebble-primary);
}


.selectedSongViewPlaceHolder > .songInfo{
  width:100%;
}

.selectedSongViewPlaceHolder > .songInfo > .songAndArtist{
    overflow: hidden;
}
.songSelectorPlaceholder > .content > .selectedSongViewPlaceHolder,
.songSelectorPlaceholder > .content > .noAudioAvaliablePlaceholder{
    flex: 1 1 100%;
    -webkit-flex: 1 1 100%;
    -moz-flex: 1 1 100%;
    -ms-flex: 1 1 100%;
    -o-flex: 1 1 100%;

    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-items: center;
    -webkit-justify-content: center;
    text-align: center;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    -webkit-flex: 1 1 auto;
    /* height: 30px; */
    
    margin: 0px;
    padding: 2px;

    
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
    width: 100%;
    height: 54px;
    max-width: calc(100% - 54px);

}

.songSelectorPlaceholder > .content > .selectedSongViewPlaceHolder{
    border: var(--border-2) solid var(--color-white-40);
}

.songSelectorPlaceholder > .content > .noAudioAvaliablePlaceholder{
  border: var(--border-2) solid var(--color-white-20);
  /*
  background-color: var(--color-white-20);*/
  opacity:0.5;
  color: var(--color-white-50);
}
.songSelectorPlaceholder.noSongSelected > .content > .selectedSongViewPlaceHolder,
.songSelectorPlaceholder.noSongSelected > .content > #removeSelectedButton{
    display: none;
}


.songSelectorPlaceholder.not_modifiable > .content >  #pickSongButton,
.songSelectorPlaceholder.not_modifiable > .content >  #removeSelectedButton,
.songSelectorPlaceholder:not(.noSongSelected) > .content > .noAudioAvaliablePlaceholder,
.songSelectorPlaceholder:not(.noSongSelected) > .content > #pickSongButton{
    display: none;
}


.audioPlaceholder > .content > #playSweeperBtn,
.audioPlaceholder > .content > #playGreaterBtn,
.audioPlaceholder > .content > #playIntroBtn,
.audioPlaceholder > .content > #playOutroBtn{
    color: var(--trebble-primary);
}

.audioPlaceholder > .content > #playSweeperBtn:before,
.audioPlaceholder > .content > #playGreaterBtn:before,
.audioPlaceholder > .content > #playIntroBtn:before,
.audioPlaceholder > .content > #playOutroBtn:before {
  font-size: var(--text-xl-px);
}

.icon-trebble-icon-2ton,
.icon-trebbleLogo{
    transition : color 0.5s ease;
    -webkit-transition : color 0.5s ease;
    -moz-transition : color 0.5s ease;
    -ms-transition : color 0.5s ease;
    -o-transition : color 0.5s ease;
}


@media (min-width: 800px) {
  .WelcomeBetaUserBox {
    width: 800px;
    padding-top: 100px;
    margin: auto;
}


div[data-role=popup].whatsnew  .WelcomeBetaUserBox .details {
    width: 800px;
    font-weight: 400;
    font-size: var(--text-lg);
    padding-left: 40px;
    padding-right: 40px;
    box-sizing: border-box;
    letter-spacing: 1px;
}

div[data-role=popup].whatsnew  .WelcomeBetaUserBox * .signature {
    text-align: right;
    font-weight: 600;
    font-size: var(--text-xl);
    padding-right: 40px;
}

.feature.infoBoxWrapper > .content > .message {
  color: white;
  max-width: 600px;
  margin: auto;
  font-size: var(--text-4xl);
  font-weight: 900;
}

.feature.infoBoxWrapper > .content > .helpMessage {
  color: white;
  font-size: var(--text-md);
  letter-spacing: 1px;
}

}
@media(max-width:500px) , (max-height: 500px) and (orientation: landscape){

 .feature.infoBoxWrapper > .content > .message {
    max-width: 300px;
    margin: 5px auto auto auto;

}

}

div[data-role=popup].whatsnew  .WelcomeBetaUserBox > .header {
    font-size: var(--text-4xl);
    font-weight: 700;
}

#trebbleStatisticContent .ct-series-b .ct-area, .ct-series-b .ct-slice-pie{
    fill: transparent;
}

#trebbleStatisticContent .ct-series-a .ct-area, .ct-series-a .ct-slice-pie{
    fill: white;
    fill-opacity: 0.2;
}
#passwordChangeFormWrapper,#redeemFormWrapper, #updateUserInfoForm, #updateShortcastInfoForm {
  max-width: 600px;
  margin: auto;
  background-color: white;
  padding: 10px;
  padding-top: 50px;
  padding-bottom: 50px;
  box-sizing: border-box;
  
}
@media(min-width:800px) , (min-height: 800px) and (orientation: landscape){
  #passwordChangeFormWrapper , #redeemFormWrapper, #updateUserInfoForm , #updateShortcastInfoForm{

      padding: 50px;
  }
}
.drop-content {
  background-color: white;
  color: var(--text-dark) !important;
  box-shadow: none;/*var(--color-black-20) 0px 2px 4px;*/
  background: var(--color-white-100);
  border: var(--border-0);/*1px solid var(--color-gray-300);*/
  outline: none;
}

.ui-page-active.lighttext ~ .drop-content {
  box-shadow: var(--color-black-20) 0px 2px 4px;
  border: var(--border-1) solid var(--color-gray-300);
}

body:not(.darkTheme) .drop-content  #songPoppupMenu > div > ul > li,
body:not(.darkTheme) .drop-content  .popup-menu-content > div > ul > li,
body:not(.darkTheme) .drop-content  #songPoppupMenu > div > ul > li > a,
body:not(.darkTheme) .drop-content  .popup-menu-content > div > ul > li > a,
body:not(.darkTheme) .drop-content  .popup-menu-content > div > ul > li > .toggle_switch_menu,
body:not(.darkTheme) .c-bottom-sheet:not(.darkTheme) ul.trebbleOptionList > li > .toggle_switch_menu,
body:not(.darkTheme) .drop-content  #songPoppupMenu > div > ul > li.ui-li-divider,
body:not(.darkTheme) .drop-content  .popup-menu-content > div > ul > li.ui-li-divider,
body:not(.darkTheme) .trebble-context-menu li.context-menu-item > a,
body:not(.darkTheme) .c-bottom-sheet:not(.darkTheme) ul.trebbleOptionList > li > a {
  padding-left: 0px;
  color: var(--text-dark) !important;
  /* margin-left: 15px; */
}

body.darkTheme .drop-content  #songPoppupMenu > div > ul > li.disabled,
body.darkTheme .drop-content  .popup-menu-content > div > ul > li.disabled,
body.darkTheme .drop-content  #songPoppupMenu > div > ul > li > a.disabled,
body.darkTheme .drop-content  .popup-menu-content > div > ul > li > a.disabled,
body.darkTheme .drop-content  .popup-menu-content > div > ul > li > a.disabled,
body.darkTheme .drop-content  .popup-menu-content > div > ul > li > .toggle_switch_menu.disabled,
body.darkTheme .drop-content  #songPoppupMenu > div > ul > li.ui-li-divider.disabled,
body.darkTheme .drop-content  .popup-menu-content > div > ul > li.ui-li-divider.disabled,
body.darkTheme .trebble-context-menu li.context-menu-item > a.disabled,
body.darkTheme .c-bottom-sheet ul.trebbleOptionList > li > a.disabled,
body.darkTheme .c-bottom-sheet ul.trebbleOptionList > li > .toggle_switch_menu.disabled,
body:not(.darkTheme) .drop-content  #songPoppupMenu > div > ul > li.disabled,
body:not(.darkTheme) .drop-content  .popup-menu-content > div > ul > li.disabled,
body:not(.darkTheme) .drop-content  #songPoppupMenu > div > ul > li > a.disabled,
body:not(.darkTheme) .drop-content  .popup-menu-content > div > ul > li > a.disabled,
body:not(.darkTheme) .drop-content  .popup-menu-content > div > ul > li > a.disabled,
body:not(.darkTheme) .drop-content  .popup-menu-content > div > ul > li > .toggle_switch_menu.disabled,
body:not(.darkTheme) .drop-content  #songPoppupMenu > div > ul > li.ui-li-divider.disabled,
body:not(.darkTheme) .drop-content  .popup-menu-content > div > ul > li.ui-li-divider.disabled,
body:not(.darkTheme) .trebble-context-menu li.context-menu-item > a.disabled,
body:not(.darkTheme) .c-bottom-sheet ul.trebbleOptionList > li > a.disabled,
body:not(.darkTheme) .c-bottom-sheet ul.trebbleOptionList > li > .toggle_switch_menu.disabled {
  opacity: 0.4;
}

.drop-content  .popup-menu-content.hideHeading > div > ul > li.ui-li-divider{
    display: none;
}

.drop-content .popup-menu-content> div > ul > li.toggle_switch_menu_wrapper,
.c-bottom-sheet ul.trebbleOptionList > li.toggle_switch_menu_wrapper{
    display: flex;
    background-color: transparent;
    border: var(--border-0);
    flex-direction: row;
}

.ui-listview > .ui-li-static.toggle_switch_menu_wrapper{
    padding: 0px;
}

.drop-content .popup-menu-content> div > ul > li.toggle_switch_menu_wrapper .toggle_switch_menu,
.c-bottom-sheet ul.trebbleOptionList > li.toggle_switch_menu_wrapper .toggle_switch_menu{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: var(--text-sm);
}


body.browser.mobileweb:not(.darkTheme)  .ui-page-active:not(.lighttext) .drop-content  #songPoppupMenu > div > ul > li > a:not(.disabled):hover,
body:not(.darkTheme) .ui-page-active:not(.lighttext) .drop-content #songPoppupMenu > div > ul > li > a:not(.disabled):active,
body.browser.mobileweb:not(.darkTheme) .ui-page-active:not(.lighttext)  .drop-content  .popup-menu-content > div > ul > li > a:not(.disabled):hover,
body:not(.darkTheme) .ui-page-active:not(.lighttext) .drop-content .popup-menu-content > div > ul > li > a:not(.disabled):active,
body:not(.darkTheme) .ui-page-active:not(.lighttext) .trebble-context-menu li.context-menu-item > a:not(.disabled):active,
body:not(.darkTheme) .c-bottom-sheet ul.trebbleOptionList > li > a:not(.disabled):active {

  color: var(--text-dark)!important;
  background-color: var(--color-black-5);
}

body.browser.mobileweb  .ui-page-active.lighttext ~ .drop-content  #songPoppupMenu > div > ul > li > a:not(.disabled):hover,
.ui-page-active.lighttext ~ .drop-content #songPoppupMenu > div > ul > li > a:not(.disabled):active,
body.browser.mobileweb  .ui-page-active.lighttext ~ .drop-content  .popup-menu-content > div > ul > li > a:not(.disabled):hover,
.ui-page-active.lighttext ~ .drop-content .popup-menu-content > div > ul > li > a:not(.disabled):active,
body:not(.darkTheme) .ui-page-active:not(.lighttext) .trebble-context-menu li.context-menu-item > a:not(.disabled):active,
body:not(.darkTheme) .c-bottom-sheet ul.trebbleOptionList > li > a:not(.disabled):active {
  color: var(--trebble-primary) !important;
  /* background-color: transparent;*/
  background-color: var(--color-black-5);
}

.drop-content #songPoppupMenu,
.drop-content #songPoppupMenu,
.drop-content .popup-menu-content {
  width: inherit;
  height: inherit;
  /* padding: 10px; */
  padding-top: 10px;
  padding-bottom: 10px;
  color: var(--text-dark) ;
  width: auto;
  height: auto;
}
.drop-content .popup-menu-content.SequencerNodeBasicContextOptionPopup ul{
    flex-direction: row;
    display: flex;
    max-width: 100%;

}

.drop-content .popup-menu-content.SequencerNodeBasicContextOptionPopup ul li .menu_label{
    display:  none;
}
.drop-content .popup-menu-content.SequencerNodeBasicContextOptionPopup.popup-menu-content > div > ul > li > a{
    min-width: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0px;
    padding: 10px;
}

.drop-content .popup-menu-content.SequencerNodeBasicContextOptionPopup.popup-menu-content > div > ul > li > a .menu_icon{
    padding:0px;
}


.drop-content .trebblePopupHeader,
.drop-content  #pageHeaderTitleWrapper.trebblePopupHeader{
  /*color: var(--trebble-primary);*/
  color: white;
  opacity: 0.7;
  position: relative;
  padding: 0px;
  font-size: var(--text-md);
  font-weight: bold;
  width: calc(100% - 40px);
  margin: 0px 20px;
  box-sizing: border-box;
  text-align: left;
  justify-content: flex-start;
  -webkit-justify-content: flex-start;
  -moz-justify-content: flex-start;
  -ms-justify-content: flex-start;
  /*border-bottom: var(--border-1) solid var(--color-black-10);*/
  /* margin-bottom: 10px; */
}

.drop-content #songPoppupMenu > div > ul,
.drop-content .popup-menu-content > div > ul {
  padding-left: 10px;
  padding-right: 10px;
}

.drop-content #songPoppupMenu > div,
.drop-content .popup-menu-content > div{
    position:relative !important;
}

.tippy-content .trebble-context-menu{
    margin: 5px 1px;
}

.c-bottom-sheet  ul.trebbleOptionList,
.c-bottom-sheet  .trebble-context-menu{
    width:100%;
}
.trebble-context-menu li.context-menu-item > a,
.drop-content #songPoppupMenu > div > ul > li > a,
.drop-content .popup-menu-content > div > ul > li > a,
.c-bottom-sheet ul.trebbleOptionList > li > a {
  padding: 0px;
  padding-left: 0px;
  padding-right: 40px;
  height: 45px;
  line-height: 45px;
  font-weight: 400;
  font-size: var(--text-sm);
  min-width: 200px;
}
.trebble-context-menu li.context-menu-item > a > i,
.drop-content .popup-menu-content > div > ul > li > a > i,
.drop-content .popup-menu-content > div > ul > li > a > .menu_icon,
.c-bottom-sheet  ul.trebbleOptionList > li > a > .menu_icon{
  font-size: var(--text-xl-px) !important;
  margin:0px !important;
  margin-right: 10px !important;
  /*display:none;*/
}
/*.drop-content .popup-menu-content > div > ul > li > a > i.pe-7s-camera{
  margin-right: 2px !important;
  left: -5px;
  position: relative;
  }*/

  body[appname="trebble"] .drop-element.drop-theme-arrows .drop-content,
  body[appname="trebble"] .ui-page-active.lighttext .tippy-box,
  body[appname="trebble"] .ui-page-active.lighttext ~ .tippy-box{
    /*background: white;*/
    background: var(--color-white-10);
    backdrop-filter: blur(20px);
    -webkit-filter: none;
    filter: none;
}

body[appname="trebble"]  .tippy-box{
    background: var(--color-gray-100);
    backdrop-filter: blur(20px);
    -webkit-filter: none;
    filter: none;
}

body .drop-element ~ div[data-tippy-root]{
    z-index: 999999999999999 !important;
}

@supports not (backdrop-filter: blur(20px)) {
   body[appname="trebble"] .drop-element.drop-theme-arrows .drop-content,
   body[appname="trebble"] .ui-page-active.lighttext .tippy-box,
   body[appname="trebble"] .ui-page-active.lighttext ~ .tippy-box,
   body[appname="trebble"] .tippy-box{
    /*background: white;*/
    background: var(--color-gray-100);
}
}

body[appname="trebble"] .tippy-box{
    color:  var(--color-black);
}
body[appname=trebble] .tippy-box[data-theme="tippy-trebble-tooltip"] {
    background-color: var(--text-dark);
    color: white;
}

body[appname=trebble] .tippy-box[data-theme="tippy-trebble-tooltip"] .tippy-arrow:before{
    color:  var(--text-dark);
}

body[appname=trebble] .tippy-box[data-theme="tippy-trebble-tooltip"] .tooltip_message,
body[appname=trebble] .tippy-box[data-theme="tippy-trebble-tooltip"] .keyboard_shortcut{
    color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 0px 5px;
}

body[appname=trebble] .ui-page-active.lighttext ~ div .tippy-box[data-theme="tippy-trebble-tooltip"]{
 background-color:var(--color-gray-150);
 color: var(--color-black);
}
body[appname=trebble] .ui-page-active.lighttext ~ div .tippy-box[data-theme="tippy-trebble-tooltip"] .tippy-arrow:before {
 color:var(--color-gray-150) !important;
}

body[appname=trebble] .ui-page-active.lighttext ~ div .tippy-box[data-theme="tippy-trebble-tooltip"] .tooltip_message,
body[appname=trebble] .ui-page-active.lighttext ~ div .tippy-box[data-theme="tippy-trebble-tooltip"] .keyboard_shortcut{
    color: var(--color-black);
}



body[appname=trebble] .tippy-box[data-theme="tippy-trebble-tooltip"] {
    font-weight: 400;
}

body[appname=trebble] .tippy-box[data-theme="tippy-trebble-tooltip"] .keyboard_shortcut{
    font-weight: 400;
}


body[appname="trebble"] .tippy-box .tippy-arrow:before{
    color: var(--color-black-5);
}

body[appname="trebble"]:not(.darkTheme) .ui-page-active:not(.lighttext) ~ .drop-element.drop-theme-arrows .drop-content,
body[appname=trebble]:not(.darkTheme) .ui-page-active:not(.lighttext) .tippy-box,
body[appname=trebble]:not(.darkTheme) .ui-page-active:not(.lighttext) ~ div .tippy-box{
    box-shadow:   0 6px 16px 0 var(--color-black-10),0 3px 6px -4px var(--color-black-10),0 9px 28px 8px var(--color-black-5);
}

body[appname="trebble"] .drop-element.drop-theme-arrows .drop-content:before,
body[appname="trebble"] .drop-element.drop-theme-arrows.drop-element-attached-top.drop-element-attached-right.drop-target-attached-bottom .drop-content:before{
    /*border-bottom-color: white;*/
    border-bottom-color: var(--color-black-5);
    right: 10px;
    border-width: var(--border-8);
}

.drop-element.drop-theme-arrows.drop-element-attached-left.drop-element-attached-middle.sequencerNodeMoreOptionPopupDrop .drop-content:before,
.drop-element.drop-theme-arrows.drop-element-attached-left.drop-element-attached-middle.SequencerAutomatedOperationsPopupDrop .drop-content:before{
    border-right-color: var(--color-black-5);
    border-bottom-color: transparent !important;
}


body[appname="trebble"] .drop-element.drop-theme-arrows.drop-element-attached-bottom.drop-element-attached-right.drop-target-attached-top .drop-content:before{
    /*border-top-color: white;*/
    border-top-color: var(--color-black-5);
    top: 100%;
    right: 10px;
    border-bottom: var(--border-0);
}
.ui-page-active.lighttext  ~ .drop-element.drop-theme-arrows .drop-content,
body.darkTheme   .drop-element.drop-theme-arrows .drop-content,
body.darkTheme[appname="trebble"] .tippy-box,
.ui-page-active.lighttext .tippy-box{
  background-color: var(--color-white-10);
  color: white !important;
  border: var(--border-0);
  backdrop-filter: blur(20px);
  box-shadow: none;
}

.c-bottom-sheet ul.trebbleOptionList  > li > a{
    color:var(--color-black) !important;
    font-size: var(--text-base);
}

@supports not (backdrop-filter: blur(20px)) {
 .ui-page-active.lighttext  ~ .drop-element.drop-theme-arrows .drop-content,
 body.darkTheme   .drop-element.drop-theme-arrows .drop-content,
 body.darkTheme[appname="trebble"] .tippy-box,
 .ui-page-active.lighttext .tippy-box{
  background-color: var(--color-gray-100);

}
.c-bottom-sheet ul.trebbleOptionList  > li > a,
.c-bottom-sheet.darkTheme.active .sequencer_settings .settings_panel_title,
.c-bottom-sheet.darkTheme.active .settingSection .settingItemTitle .itemTitle,
[data-role=page].lighttext.ui-page-active ~ div [data-role=popup] .sequencer_settings .settings_panel_title,
[data-role=page].lighttext.ui-page-active ~ div [data-role=popup] .settingSection .settingItemTitle .itemTitle {
 color:var(--color-black) !important;
}
}

.ui-page-active.lighttext  ~ .drop-element.drop-theme-arrows .drop-content:before,
body.darkTheme .drop-element.drop-theme-arrows .drop-content:before  {
  border-bottom-color: var(--color-white-5) !important;
  background-color: transparent !important;
  color: transparent !important;
}

.ui-page-active.lighttext  ~ .drop-element.drop-theme-arrows.drop-element-attached-left.drop-element-attached-middle.sequencerNodeMoreOptionPopupDrop .drop-content:before,
.ui-page-active.lighttext  ~ .drop-element.drop-theme-arrows.drop-element-attached-left.drop-element-attached-middle.SequencerAutomatedOperationsPopupDrop .drop-content:before,
body.darkTheme .drop-element.drop-theme-arrows.drop-element-attached-left.drop-element-attached-middle.sequencerNodeMoreOptionPopupDrop .drop-content:before,
body.darkTheme .drop-element.drop-theme-arrows.drop-element-attached-left.drop-element-attached-middle.SequencerAutomatedOperationsPopupDrop .drop-content:before,
.ui-page-active.lighttext  ~  .drop-element.drop-theme-arrows.drop-element-attached-left.drop-element-attached-middle.soundEnhancerSettingsPopupDrop .drop-content:before,
body.darkTheme  .drop-element.drop-theme-arrows.drop-element-attached-left.drop-element-attached-middle.soundEnhancerSettingsPopupDrop .drop-content:before{
    border-right-color: var(--color-white-5);
    border-bottom-color: transparent !important;
}

body[appname="trebble"] .ui-page-active.lighttext .tippy-box,
.ui-page-active.lighttext  ~ .trebble-context-menu li.context-menu-item,
.ui-page-active.lighttext  ~ .trebble-context-menu li.context-menu-item > a,
.ui-page-active.lighttext  ~ .trebble-context-menu li.ui-li-divider,
.ui-page-active.lighttext   .trebble-context-menu li.context-menu-item,
.ui-page-active.lighttext   .trebble-context-menu li.context-menu-item > a,
.ui-page-active.lighttext   .trebble-context-menu li.ui-li-divider,
.ui-page-active.lighttext  ~ .drop-element.drop-theme-arrows .drop-content .drop-content .popup-menu-content > div > ul > li,
.ui-page-active.lighttext  ~ .drop-element.drop-theme-arrows .drop-content .popup-menu-content > div > ul > li > a, 
 .c-bottom-sheet ul.trebbleOptionList  > li > a, 
.ui-page-active.lighttext  ~ .drop-element.drop-theme-arrows  .drop-content .popup-menu-content > div > ul > li.ui-li-divider,
.ui-page-active.lighttext  ~ .drop-element.drop-theme-arrows  .drop-content .popup-menu-content .userListItem > .infoWrapper >.userUsername,
.ui-page-active.lighttext  ~ .drop-element.drop-theme-arrows  .drop-content .popup-menu-content .userListItem > .infoWrapper >.userFullname,
.ui-page-active.lighttext  ~ .drop-element.drop-theme-arrows  .drop-content .popup-menu-content > div > ul > li.toggle_switch_menu_wrapper .toggle_switch_menu,
.c-bottom-sheet ul.trebbleOptionList  > li.toggle_switch_menu_wrapper .toggle_switch_menu,
.ui-page-active.lighttext  ~ .drop-element.drop-theme-arrows  .drop-content .popup-menu-content .trim_silences_context_menu_container,
body.darkTheme .drop-element.drop-theme-arrows .drop-content .drop-content .popup-menu-content > div > ul > li,
body.darkTheme .drop-element.drop-theme-arrows .drop-content .popup-menu-content > div > ul > li > a, 
body.darkTheme .c-bottom-sheet ul.trebbleOptionList > li > a, 
body.darkTheme .drop-element.drop-theme-arrows  .drop-content .popup-menu-content > div > ul > li.ui-li-divider,
body.darkTheme .drop-element.drop-theme-arrows .trebble-context-menu li.context-menu-item  > li > a,
body.darkTheme .drop-element.drop-theme-arrows .trebble-context-menu li.context-menu-item  > li.ui-li-divider,
body.darkTheme .drop-element.drop-theme-arrows  .drop-content .popup-menu-content .userListItem > .infoWrapper >.userUsername,
body.darkTheme .drop-element.drop-theme-arrows  .drop-content .popup-menu-content .userListItem > .infoWrapper >.userFullname,
body.darkTheme .drop-element.drop-theme-arrows  .drop-content .popup-menu-content > div > ul > li.toggle_switch_menu_wrapper .toggle_switch_menu,
body.darkTheme .c-bottom-sheet ul.trebbleOptionList  > li.toggle_switch_menu_wrapper .toggle_switch_menu,
body.darkTheme .drop-element.drop-theme-arrows  .drop-content .popup-menu-content .trim_silences_context_menu_container,
body.darkTheme .trebble-context-menu li.context-menu-item,
body.darkTheme .trebble-context-menu li.context-menu-item > a, 
body.darkTheme .trebble-context-menu li.context-menu-item.ui-li-divider,
.c-bottom-sheet.darkTheme .trebble-context-menu li.context-menu-item,
.c-bottom-sheet.darkTheme .trebble-context-menu li.context-menu-item > a, 
.c-bottom-sheet.darkTheme .trebble-context-menu li.context-menu-item.ui-li-divider{

  color: white !important;

}
@supports not (backdrop-filter: blur(20px)) {
    body[appname=trebble] .ui-page-active.lighttext .tippy-box,
    .ui-page-active.lighttext  ~ .drop-element.drop-theme-arrows .drop-content .drop-content .popup-menu-content > div > ul > li,
    .ui-page-active.lighttext  ~ .drop-element.drop-theme-arrows .drop-content .popup-menu-content > div > ul > li > a, 
    .c-bottom-sheet ul.trebbleOptionList > li > a, 
    .ui-page-active.lighttext  ~ .drop-element.drop-theme-arrows  .drop-content .popup-menu-content > div > ul > li.ui-li-divider,
    .ui-page-active.lighttext  ~ .drop-element.drop-theme-arrows  .drop-content .popup-menu-content .userListItem > .infoWrapper >.userUsername,
    .ui-page-active.lighttext  ~ .drop-element.drop-theme-arrows  .drop-content .popup-menu-content .userListItem > .infoWrapper >.userFullname,
    body.darkTheme .drop-element.drop-theme-arrows .drop-content .drop-content .popup-menu-content > div > ul > li,
    body.darkTheme .drop-element.drop-theme-arrows .drop-content .popup-menu-content > div > ul > li > a, 
    body.darkTheme .c-bottom-sheet ul.trebbleOptionList > li > a, 
    body.darkTheme .drop-element.drop-theme-arrows .trebble-context-menu li.context-menu-item  > li > a,
    body.darkTheme .drop-element.drop-theme-arrows .trebble-context-menu li.context-menu-item  > li.ui-li-divider,
    body.darkTheme .drop-element.drop-theme-arrows  .drop-content .popup-menu-content > div > ul > li.ui-li-divider,
    body.darkTheme .drop-element.drop-theme-arrows  .drop-content .popup-menu-content .userListItem > .infoWrapper >.userUsername,
    body.darkTheme .drop-element.drop-theme-arrows  .drop-content .popup-menu-content .userListItem > .infoWrapper >.userFullname{
      color: var(--color-black) !important;
  }
}

.trebble-context-menu li.context-menu-item > a {
    font-size: var(--text-sm-px);
    margin: 0px;
}

body .drop-content .popup-menu-content > div > ul > li > a.menu_with_keyboard_shortcut,
.c-bottom-sheet ul.trebbleOptionList > li > a.menu_with_keyboard_shortcut,
.trebble-context-menu li.context-menu-item > a.menu_with_keyboard_shortcut{
    text-decoration: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0px 8px;
    box-sizing: border-box;
}


.c-bottom-sheet ul.trebbleOptionList > li > a.menu_with_keyboard_shortcut .keyboard_shortcut{
    display: none;
}

.c-bottom-sheet ul.trebbleOptionList > li > a.menu_with_keyboard_shortcut .menu_desc,
.drop-content .popup-menu-content > div > ul > li > a.menu_with_keyboard_shortcut .menu_desc,
.trebble-context-menu li.context-menu-item > a.menu_with_keyboard_shortcut .menu_desc{
    padding-right: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.trebble-context-menu li.context-menu-item > a.menu_with_keyboard_shortcut .menu_desc{
    margin-left: 5px;
}


.c-bottom-sheet ul.trebbleOptionList > li > a.menu_with_keyboard_shortcut .menu_desc > i,
.drop-content .popup-menu-content > div > ul > li > a.menu_with_keyboard_shortcut .menu_desc > i,
.trebble-context-menu li.context-menu-item > a.menu_with_keyboard_shortcut .menu_desc > i{
  width: 24px;
  font-size: var(--text-xl-px);
  margin-right: 12px;
  align-items: center;
  justify-content: center;
  display: inline-flex;
}

.drop-content .popup-menu-content > div > ul > li > a.menu_without_icon .menu_desc,
body .drop-content .popup-menu-content > div > ul > li.toggle_switch_menu_wrapper .toggle_switch_menu,
.trebble-context-menu li.context-menu-item > a.menu_without_icon .menu_desc{
    padding: 0px 0.5em;
}


.drop-content .popup-menu-content > div > ul > li > a.menu_with_keyboard_shortcut .keyboard_shortcut,
.trebble-context-menu li.context-menu-item > a.menu_with_keyboard_shortcut .keyboard_shortcut{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: var(--text-xs-px);
    opacity: 0.5;
}

.keyboard_shortcut .key_button.soundwave-command-apple-key.icon_key_button{
    font-size: 80%;
}

.keyboard_shortcut .key_button.soundwave-shift-apple-key.icon_key_button{
    font-size: 100%;
}

.keyboard_shortcut .key_button.soundwave-backspace-icon.icon_key_button{
    font-size: 110%;
}

.menu_icon.soundwave-live-transcribe-svgrepo-com-2{
    font-weight: bold;
}

.keyboard_shortcut .add_key_button {
    margin: 0 3px;
    font-size: 90%;
    display:none;
}

.keyboard_shortcut .key_button{
    margin: 0px;
    margin-right: 5px;
}

.ui-page-active.lighttext  ~ .drop-element.drop-theme-arrows  .drop-content .popup-menu-content .userListItem:active,
body.darkTheme .drop-element.drop-theme-arrows  .drop-content .popup-menu-content .userListItem:active{
  background-color: transparent;
  color: white;
  border-color: transparent;
}

.ui-page-active.lighttext  ~ .drop-element.drop-theme-arrows  .drop-content .popup-menu-content .userListItem > .infoWrapper >.userFullname,
body.darkTheme .drop-element.drop-theme-arrows  .drop-content .popup-menu-content .userListItem > .infoWrapper >.userFullname{

   opacity: 0.5;

}
/*
body.browser.mobileweb > .ui-page-active.lighttext ~ .drop-element.drop-theme-arrows .drop-content .popup-menu-content > div > ul > li > a:hover, 
.ui-page-active.lighttext ~ .drop-element.drop-theme-arrows .drop-content .popup-menu-content > div > ul > li > a:active{
  background-color: transparent;
  }*/


  body .drop-element.drop-theme-arrows .drop-content,
  body .drop-element.drop-theme-arrows-bounce .drop-content {
      border-radius: 0px;
      padding: 0px;
  }

  body .drop-element {
    z-index: 999999999999999;
}

.trebblePopupHeader{
  width: calc(100% - 100px);
  padding-right: 50px;
  top: -1px;
  height: 52px;
  font-weight: bold;
  padding-left: 50px;
  font-size: var(--text-3xl);
  position: absolute;
  display: flex;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  justify-content: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  align-items: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  white-space: nowrap;

}

[data-role=popup]  .popupContent.top .trebblePopupHeader ~ .ui-content{
  margin-top: 52px !important;
}
#GetStartedForCuratorsPage .helpContent {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  color: white!important;
  width: 100%;
}

#GetStartedForCuratorsPage .helpContent > .helpDetail > .title {
  text-align: center;
  margin: 0px;
  padding: 0px 10px;
  font-weight: 700;
  font-size: var(--text-xl);
  max-width: 600px;
  padding-bottom: 10px;
}

#GetStartedForCuratorsPage .helpContent > .helpDetail > .helpMessage {
  text-align: center;
  padding: 0 10px;
  margin: 0px;
  font-weight: 300;
  font-size: var(--text-base);
  max-width: 600px;
}

#GetStartedForCuratorsPage .helpContent > .helpImage,
#GetStartedForCuratorsPage .helpContent > .helpDetail {
  -webkit-box-flex: 1;
  flex: 1 1 100%;
  -webkit-flex: 1 1 100%;
  -moz-flex: 1 1 100%;
  -ms-flex: 1 1 100%;
}
#GetStartedForCuratorsPage .helpContent > .helpDetail > .helpMessage.optional{
  padding: 0px 40px;
}

@media (max-width:600px) , (max-height: 600px) and (orientation: landscape) {
   #GetStartedForCuratorsPage .helpContent > .helpDetail > .helpMessage.optional{
      display: none;
  }
}

@media (min-width:900px) , (min-height: 900px) and (orientation: landscape) {
  #GetStartedForCuratorsPage .helpContent {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    flex-direction: row-reverse;
    -webkit-flex-direction: row-reverse;
    -moz-flex-direction: row-reverse;
    -ms-flex-direction: row-reverse;
}

#GetStartedForCuratorsPage .helpContent > .helpDetail > .title {
    text-align: start;
    margin: 0px;
    padding: 0px 40px;
    font-weight: 700;
    font-size: var(--text-6xl);
    max-width: inherit;
    padding-bottom: 20px;
}

#GetStartedForCuratorsPage .helpContent > .helpDetail > .helpMessage {
    text-align: start;
    padding: 0px 40px;
    margin: 0px;
    font-weight: 400;
    font-size: var(--text-lg);
    max-width: inherit;
}

#GetStartedForCuratorsPage .helpContent > .helpDetail > .actionButtonWrapper {
  -ms-flex-pack: flex-start;
  -webkit-box-pack: flex-start;
  justify-content: flex-start;
  -webkit-justify-content: flex-start;
  padding: 0px 40px;
}
}

#GetStartedForCuratorsPage {
  background-color: var(--bg-warm-yellow);
  background-color: var(--color-purple-450);
  background: var(--gradient-primary-alt);
}

#getStartedBtn {
  cursor: pointer;
  padding: 16px;
  background-color: white;
  color: var(--color-orange-200);
  max-width: 200px;
  text-align: center;
  font-weight: bold;
  margin: auto;
}

.helpImage > video {
  border-radius: 5px;
  border: var(--border-4) solid white;
}

body.browser.mobileweb #getStartedBtn:hover,
#getStartedBtn:active {
  background-color: transparent;
  border: var(--border-2) solid white;
  color: white;
}

#youtubeVideoLink > iframe{
    box-shadow: var(--color-black-60) 0px 0px 50px;
}
/*
#player.notReady  #playerControlsWrapper,
#player.notReady #playerSongPositionWrapper,
#player.notReady #playerSocialInfoWrapper{
  display: none;
}
*/
#player #playerSongMoreButton,
#player #playerMoreInformationButton,
#playerFooter #playerSongMoreButton,
#playerFooter #playerMoreInformationButton,
#player > .infoBoxWrapper,
#player.notReady > .ui-content,
#player[no_audio_loaded=true] > .ui-content{
    display: none;
}

#player[audio_type_loaded=Song] #playerSongMoreButton,
#player[audio_type_loaded=Capsule] #playerMoreInformationButton,
#playerFooter[audio_type_loaded=Song] #playerSongMoreButton,
#playerFooter[audio_type_loaded=Capsule] #playerMoreInformationButton{
    display: flex;
}



#player[no_audio_loaded=true].playlistIsLoading > .infoBoxWrapper{
   display: flex;
   justify-content: center;
   align-items: center;
   position: absolute;
   margin: auto;
   top: 0px;
   height: 100vh;
   max-width: unset;
}


[data-role=page].ui-page:not(.ui-page-active) [role=tabpanel],
[data-role=page].ui-page:not(.ui-page-active) #maliste{
    display: none !important;
}

#reponsivePlayerContent.activeCapsule #viewCapsuleDetailsBtn{
  display: flex;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  position: absolute;
  width: calc(100% - 30px);
  bottom: 40px;
  left: 15px;
  height: 42px;
  font-size: var(--text-sm);
  font-weight: 600;
  align-items: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  margin: 0px;
  box-sizing: border-box;
  background-color: var(--color-black-10);
}

#reponsivePlayerContent.activeCapsule #viewCapsuleDetailsBtn{
    display: none !important;
}
body.browser.mobileweb #reponsivePlayerContent.activeCapsule #viewCapsuleDetailsBtn:hover, 
#reponsivePlayerContent.activeCapsule #viewCapsuleDetailsBtn:active{
    background-color: white
}


#reponsivePlayerContent:not(.activeCapsule) #viewCapsuleDetailsBtn{
    display:none;
}

.owl-page.active span{
    background-color: white !important;
} 

select{
  font-size: var(--text-lg-px) !important;
}

select > option{
    color: var(--text-dark);
}

select > option:checked{
    color: var(--trebble-primary);
}



#player  #reponsivePlayerContent:not(.activeCapsule) > div:nth-child(2) #viewCapsuleDetailsBtn,
#player  #reponsivePlayerContent > div:nth-child(2)  #mediaSourceBtn {
  display: none !important;
}

@media (max-height: 120px) {
    body.embedded #player #reponsivePlayerContent > :nth-child(2) #playerSocialInfoWrapper .ui-block-a{
        display: none;

    }
    #player #reponsivePlayerContent > div:nth-child(1) >  #resizeVideoFullBtn,
    #youtubeVideoLinkWrapper ~ #mediaSourceBtn{
      display:none !important;
  }


  .notifyjs-trebbleNotification-base{
    display:none !important;
}

.volatile-counter{
    top: -20px !important;
    
}
.popupContent #shareableWidgetLandscapeWrapper,
.popupContent #shareableWidgetSquareWrapper,
.popupContent .trebblePopupHeader{
    display: none;
}
.popupContent #songGradingView > .tagPlaceholder,
.popupContent #lifespanSelectionView > .tagPlaceholder{
  flex-direction: row;
  -webkit-flex-direction: row;
  -moz-flex-direction: row;
  -ms-flex-direction: row;
}
.popupContent .tag{
    height: 30px;
    line-height: 14px;
    font-size: 90%;
    max-width: 130px;
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 100%;
    -ms-flex: 1 1 100%;
    flex: 1 1 100%;
}
}

@media (max-height: 120px) and (max-width: 1000px) {
  #player  #reponsivePlayerContent.activeCapsule > div #viewCapsuleDetailsBtn{
     display: none !important;
 }

}

@media (max-height: 120px) and (min-width: 800px) {
 body.embedded #player #reponsivePlayerContent > :nth-child(2)  > #playerControlsWrapper {
    max-width: 260px;
}
body.embedded #player #reponsivePlayerContent > :nth-child(2) >  :nth-child(2),
body.embedded #player #reponsivePlayerContent > :nth-child(2) >   #playerSocialInfoWrapper {
    max-width: 350px;

}
}

@media (max-height: 120px)   and (max-width: 800px){
    /*#player #reponsivePlayerContent > :nth-child(2)  > #playerControlsWrapper  #converseButton {
      display: none;
  }
  #player #reponsivePlayerContent > :nth-child(2)  > #playerControlsWrapper{
      max-width: 170px;
  }
  #playerControlsWrapper > [data-role=controlgroup] >:first-child > a, #recordControlsWrapper > [data-role=controlgroup] >:first-child > a, #recordControlsWrapper > [data-role=controlgroup] >:first-child > o{
      width: 25%;
      }*/
      
      /*body.embedded #player #reponsivePlayerContent > :nth-child(2) #playerSongPositionWrapper{
        display: none;
        }*/
        #player #reponsivePlayerContent > :nth-child(2)  > #playerControlsWrapper{
          max-width: 260px;
      }
  }

  @media (min-height:70px) and (max-height: 120px)   and (max-width: 800px){

    #audioInfoAndSliderWrapper{
        flex-direction: column;
    }

}

@media (max-height:70px)    and (max-width: 1200px){

  body.embedded #player #reponsivePlayerContent > :nth-child(2) #playerSongPositionWrapper{
    display: none;
}

}

@media(max-height: 70px){
    #audioInfoAndSliderWrapper{
        flex-direction: row !important;
    }
}




@media (max-height: 120px) and (min-width: 700px) {
 #player > [data-role=header],
 #player  #reponsivePlayerContent > div:nth-child(1) > #mediaSourceBtn,
 #player  #reponsivePlayerContent > div:nth-child(1)> #viewCapsuleDetailsBtn {
    display: none !important;
}


#player {
    height: 116px;
}

#player {
    height: 116px;
}

#player #reponsivePlayerContent {
    display: flex;
    height: 116px;
    background: transparent!important;
}

#player > .ui-content {
    background: transparent !important;
    background-color: var(--color-black-20) !important;
}

#player #reponsivePlayerContent > #coverArtPlayerWrapper {
    height: 116px;
    width: 116px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 116px;
    -ms-flex: 0 0 116px;
    flex: 0 0 116px;
}

body:not(.embedded) #player #reponsivePlayerContent > :nth-child(2) {
    height: 116px !important;
    width: calc(100% - 116px) !important;
    -webkit-box-flex: 0 !important;
    -webkit-flex: 0 0 calc(100% - 116px) !important;
    -ms-flex: 0 0 calc(100% - 116px) !important;
    flex: 0 0 calc(100% - 116px) !important;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    /* autoprefixer: ignore next */
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
}



body:not(.embedded) #player #reponsivePlayerContent > :nth-child(2) > :nth-child(2) {
    height: 116px !important;
    -webkit-box-flex: 0 !important;
    -webkit-flex: 0 0 116px !important;
    -ms-flex: 0 0 116px !important;
    flex: 0 0 116px !important;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
}

body.embedded #player #reponsivePlayerContent > :nth-child(2) {
    height: 116px !important;
    width: calc(100% - 116px) !important;
    -webkit-box-flex: 0 !important;
    -webkit-flex: 0 0 calc(100% - 116px) !important;
    -ms-flex: 0 0 calc(100% - 116px) !important;
    flex: 0 0 calc(100% - 116px) !important;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    /* autoprefixer: ignore next */
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
}

#audioInfoAndSliderWrapper{
    flex-direction: column;
    max-width: 100% !important;
    flex: 1 1 auto;
}

body:not(.embedded) #player #reponsivePlayerContent > :nth-child(2) > :nth-child(2) > :nth-child(1),
body:not(.embedded) #player #reponsivePlayerContent > :nth-child(2) > :nth-child(2)  #playerSocialInfoWrapper {
    max-width: 300px;
    margin: 0px !important;
}

body:not(.embedded) #player #reponsivePlayerContent > :nth-child(2) > :nth-child(2) > #playerControlsWrapper {
    max-width: 260px;
    margin: 0px !important;
}

body:not(.embedded) #player #reponsivePlayerContent > :nth-child(2) > :nth-child(2) #playerSocialInfoWrapper  > :nth-child(3),
body:not(.embedded) #player #reponsivePlayerContent > :nth-child(2) > :nth-child(2) #playerSocialInfoWrapper  > :nth-child(5),
body.embedded #player #reponsivePlayerContent  #playlistInfoWrapper {
    display: none !important;
}

body.embedded #player #reponsivePlayerContent > :nth-child(2) >  :nth-child(2),
body.embedded #player #reponsivePlayerContent > :nth-child(2) >   #playerSocialInfoWrapper {
    /*max-width: 350px;*/
    min-width: 300px;
    margin: 0px !important;
    height: 100% !important;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

body.embedded #player #reponsivePlayerContent > :nth-child(2)  > #playerControlsWrapper {
    max-width: 260px;
    margin: 0px !important;
    height: 100% !important;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

body.embedded #player #reponsivePlayerContent > :nth-child(2)  #playerSocialInfoWrapper  > :nth-child(3),
body.embedded #player #reponsivePlayerContent > :nth-child(2) #playerSocialInfoWrapper  > :nth-child(5),
body.embedded #player #reponsivePlayerContent  #playlistInfoWrapper {
    display: none !important;
}

#player #artistSocialInfoAndCapsuleOverviewBox,
#player #commentOverviewBox {
    display: none !important;
}
}

@media (max-height: 120px) and (min-width:500px) and (max-width:640px)   {
    #player #reponsivePlayerContent > :nth-child(2)  > #playerControlsWrapper  #converseButton {
      display: none;
  }
  #player #reponsivePlayerContent > :nth-child(2)  > #playerControlsWrapper{
      max-width: 170px;
  }
  #playerControlsWrapper > [data-role=controlgroup] >:first-child > a, 
  #recordControlsWrapper > [data-role=controlgroup] >:first-child > a,
  #recordControlsWrapper > [data-role=controlgroup] >:first-child > o{
      width: 25%;
      max-width: 25%;
  }
}

@media (max-height: 120px) and (max-width:500px)   {
    /*#player #reponsivePlayerContent > :nth-child(2)  > #playerControlsWrapper  #capsuleButton,*/
    #player #reponsivePlayerContent > :nth-child(2)  > #playerControlsWrapper  #sendCapsuleToHostButton,
    #player #reponsivePlayerContent > :nth-child(2)  > #playerControlsWrapper  #liveLikeButton,
    #player #reponsivePlayerContent > :nth-child(2)  > #playerControlsWrapper  #converseButton,
    #player #trebbleIconWrapper,
    #player #reponsivePlayerContent > :nth-child(2)  > #playerControlsWrapper  #nextButton,
    #playerSocialInfoWrapper > .ui-block-d {

      display: none!important;
  }
  #player #reponsivePlayerContent > :nth-child(2)  > #playerControlsWrapper{
      max-width: 50px;
  }
  #playerControlsWrapper > [data-role=controlgroup] >:first-child > a,
  #recordControlsWrapper > [data-role=controlgroup] >:first-child > a,
  #recordControlsWrapper > [data-role=controlgroup] >:first-child > o{
      width: 100%;
      max-width: none;
  }
}


@media (max-height: 120px) and (min-width: 200px){
  #player> [data-role=header] {
    display: none;
}

#player {
    height: 58px;
}

#player {
    height: 58px;
}

#player #reponsivePlayerContent {
    height: 58px;
    background: transparent!important;
}

#player > .ui-content {
    background: transparent !important;
    background-color: var(--color-black-20) !important;
}

#player #reponsivePlayerContent > #coverArtPlayerWrapper {
    height: 58px;
    width: 58px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 58px;
    -ms-flex: 0 0 58px;
    flex: 0 0 58px;
}

body:not(.embedded) #player #reponsivePlayerContent > :nth-child(2) {
    height: 58px !important;
    width: calc(100% - 58px) !important;
    /*-webkit-box-flex: 0 !important;
    -webkit-flex: 0 0 calc(100% - 58px) !important;
    -ms-flex: 0 0 calc(100% - 58px) !important;
    flex: 0 0 calc(100% - 58px) !important;*/
    -webkit-box-flex: 1 1 auto !important;
    -webkit-flex: 1 1 auto !important;
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    /* autoprefixer: ignore next */
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
}

#audioInfoAndSliderWrapper{
    flex-direction: column;
    max-width: 100% !important;
    flex: 1 1 auto;
}

body.embedded #player #reponsivePlayerContent > :nth-child(2) {
    height: 58px !important;
    width: calc(100% - 58px) !important;
    /*-webkit-box-flex: 0 !important;
    -webkit-flex: 0 0 calc(100% - 58px) !important;
    -ms-flex: 0 0 calc(100% - 58px) !important;
    flex: 0 0 calc(100% - 58px) !important;*/
    -webkit-box-flex: 1 1 auto !important;
    -webkit-flex: 1 1 auto !important;
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    /* autoprefixer: ignore next */
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
}

body:not(.embedded) #player #reponsivePlayerContent > :nth-child(2) > :nth-child(2) {
    height: 58px !important;
    -webkit-box-flex: 0 !important;
    -webkit-flex: 0 0 58px !important;
    -ms-flex: 0 0 58px !important;
    flex: 0 0 58px !important;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
}


}

@media(max-height: 120px) and (max-width: 700px){
  body:not(.embedded) #player #reponsivePlayerContent > :nth-child(2) > :nth-child(2) #playerSocialInfoWrapper  > :nth-child(3),
  body:not(.embedded) #player #reponsivePlayerContent > :nth-child(2) > :nth-child(2) #playerSocialInfoWrapper  > :nth-child(5),
  body:not(.embedded) #player #reponsivePlayerContent > :nth-child(2) #playerSongPositionWrapper,
  body.embedded #player #reponsivePlayerContent  #playlistInfoWrapper {
    display: none !important;
}

body.embedded #player #reponsivePlayerContent > :nth-child(2)  #playerSocialInfoWrapper  > :nth-child(3),
body.embedded #player #reponsivePlayerContent > :nth-child(2) #playerSocialInfoWrapper  > :nth-child(5),
body.embedded #player #reponsivePlayerContent > :nth-child(2) #playerSongPositionWrapper,
body.embedded #player #reponsivePlayerContent  #playlistInfoWrapper {
    display: none !important;
}
}


@keyframes gradient-text-bg {
    0%{
        background-position:0% 50%;
    }
    100%{
        background-position:100% 50%;
    }
}

.chromeExtensionHelperContent{
   display: -webkit-box;
   display: -webkit-flex;
   display: -ms-flexbox;
   display: flex;
   -webkit-box-pack: center;
   -webkit-justify-content: center;
   -ms-flex-pack: center;
   justify-content: center;
   -webkit-box-align: center;
   -webkit-align-items: center;
   -ms-flex-align: center;
   align-items: center;
   -webkit-box-orient: horizontal;
   -webkit-box-direction: normal;
   -webkit-flex-direction: row-reverse;
   -ms-flex-direction: row-reverse;
   flex-direction: row-reverse;
}

.chromeExtensionHelperContent > p,
.chromeExtensionHelperContent > div{
    flex: 0 0 45%;
}


.chromeExtensionHelperContent  .title {
  text-align: center;
  margin: 0px;
  padding: 0px 10px;
  font-weight: 700;
  font-size: var(--text-6xl);
  max-width: 900px;
  padding-bottom: 10px;
}
.chromeExtensionHelperContent  .helpMessage {
  text-align: center;
  font-size: var(--text-lg);
  
}

.chromeExtensionHelperContent #downloadExtensionBtn{

    color: var(--pink);
    background-color: white;
    border: var(--border-3) solid white;
    width: inherit;
    padding-left: 2em;
    padding-right: 2em;
    padding-top: 1em;
    padding-bottom: 1em;

}

body.browser.mobileweb .chromeExtensionHelperContent #downloadExtensionBtn:hover,
.chromeExtensionHelperContent #downloadExtensionBtn:active{
    color: white;
    background-color: transparent;
    border: var(--border-3) solid white;
}

@keyframes xfade{
  0% {
    opacity: 1;
}

23% {
    opacity: 1;
}

25% {
    opacity: 0;
}

99.99% {
    opacity: 0;
}

100% {
    opacity: 1;
}
}


.css-slideshow{
 position: relative;
}
.css-slideshow .slide{
 margin: 0;
 position: absolute;
 width: 100%;
}

.css-slideshow .slide{
 opacity:0;
}


.css-slideshow .slide:nth-child(1) {
 animation: xfade 16s 0s infinite;
}
.css-slideshow .slide:nth-child(2) {
 animation: xfade 16s 4s infinite;
}
.css-slideshow .slide:nth-child(3) {
 animation: xfade 16s 8s infinite;
}
.css-slideshow .slide:nth-child(4) {
 animation: xfade 16s 12s infinite;
}

.trebbleCustomUrlDisplayField{ 
    font-size: small;
    color: var(--trebble-primary);
    padding: 0px 10px;
    user-select: text;  /* Chrome 49+ */
    -moz-user-select: text;     /* Firefox 43+ */
    -ms-user-select: text;      /* No support yet */
    user-select: text;  
}

.trebbleCustomUrlDisplayField > #urlPrefix,
.trebbleCustomUrlDisplayField > #currentCustomURL{
    display: inline;
}

.trebbleCustomUrlDisplayField > #currentCustomURL{
    font-weight: 600;
}

.trebbleCustomUrlDisplayField > .description{
    display: block;
}

.trebbleCustomUrlDisplayField > .detail{

    display: inline;
    margin-left: 4px;
    color: var(--color-gray-500);

}



::selection {
  background: var(--trebble-primary); /* WebKit/Blink Browsers */
  color:white !important;
}
::-moz-selection {
  background: var(--trebble-primary); /* Gecko Browsers */
  color:white;
}
div[data-role=header] input::selection  {
  background:var(--trebble-primary-dark) ; 
  color:white;
}

div[data-role=header] input::-moz-selectionn  {
  background:var(--trebble-primary-dark) ; 
  color:white;
}

.autoplaySuggestionContent,
.trebbleSubscribeSuggestionContent{

    max-width: 900px;
    font-size: var(--text-md-px);
    padding: 40px;
    width: 100%;


}
.autoplaySuggestionContent #contenOwnerInfo,
.autoplaySuggestionContent #upNextContentInfo,
.trebbleSubscribeSuggestionContent #contenOwnerInfo,
.trebbleSubscribeSuggestionContent #upNextContentInfo{
   display: -webkit-box;
   display: -webkit-flex;
   display: -ms-flexbox;
   display: flex;
   -webkit-box-pack: center;
   -webkit-justify-content: center;
   -ms-flex-pack: center;
   justify-content: center;
   -webkit-box-align: center;
   -webkit-align-items: center;
   -ms-flex-align: center;
   align-items: center;
   height: 140px;
}
.trebbleSubscribeSuggestionContent #TrebbleSubscribeSuggestionMessage{
    max-width: 600px;
    font-size: var(--text-2xl-px);
    font-weight: 700;
    margin: auto;
}

@media (max-width: 500px), (max-height: 500px) and (orientation: landscape) {
    .trebbleSubscribeSuggestionContent #TrebbleSubscribeSuggestionMessage{

        font-size: var(--text-lg-px);

    }
}

.autoplaySuggestionContent #userProfileImage{

  border-radius: 50%;
  

}
.autoplaySuggestionContent #userProfileImage,
.trebbleSubscribeSuggestionContent #trebbleCoverArtImage{
  height: 100px;
  background-size: cover;
  width: 100px;
  margin: 20px auto;
  background-position: center;
  background-color: var(--color-white-20);
  background-repeat: no-repeat;

}
.trebbleSubscribeSuggestionContent #trebbleCoverArtImage{
    background-size: contain;
}

.autoplaySuggestionContent .actionButtons,
.autoplaySuggestionContent .secondaryActionButtons,
.trebbleSubscribeSuggestionContent .actionButtons,
.trebbleSubscribeSuggestionContent .secondaryActionButtons{
   display: -webkit-box;
   display: -webkit-flex;
   display: -ms-flexbox;
   display: flex;
   -webkit-box-pack: center;
   -webkit-justify-content: center;
   -ms-flex-pack: center;
   justify-content: center;
   -webkit-box-align: center;
   -webkit-align-items: center;
   -ms-flex-align: center;
   align-items: center;
   flex-direction: row;
   -webkit-flex-direction: row;
   -ms-flex-direction: row;
   -moz-flex-direction: row;
   margin-top: 30px;
}

.autoplaySuggestionContent  .autoplayMessage,
.trebbleSubscribeSuggestionContent  .autoplayMessage{

    text-align: center;

}
.autoplaySuggestionContent .actionButtons > div,
.trebbleSubscribeSuggestionContent .actionButtons > div{

    flex: 1 1 calc(100% - 10px);
    -webkit-flex: 1 1 calc(100% - 10px);
    -moz-flex: 1 1 calc(100% - 10px);
    -ms-flex: 1 1 calc(100% - 10px);

    margin: 0px 5px 0px 5px;

}

.autoplaySuggestionContent .secondaryActionButtons > div,
.trebbleSubscribeSuggestionContent .secondaryActionButtons > div{
    margin:0px;
}

.trebbleSubscribeSuggestionContent  div#shareOnFacebookBtn,
.trebbleSubscribeSuggestionContent  div#shareOnTwitterBtn{
  border-color: white;
  color: white;
}
.autoplaySuggestionContent  div#shareOnFacebookBtn,
body.browser.mobileweb .trebbleSubscribeSuggestionContent  div#shareOnFacebookBtn:hover,
.trebbleSubscribeSuggestionContent  div#shareOnFacebookBtn:active {
  background-color: var(--text-dark);
  border-color: var(--text-dark);
  color: white !important;
}

.autoplaySuggestionContent  div#shareOnTwitterBtn,
body.browser.mobileweb  .trebbleSubscribeSuggestionContent  div#shareOnTwitterBtn:hover,
.trebbleSubscribeSuggestionContent  div#shareOnTwitterBtn:active {
  background-color: var(--color-twitter);
  border-color: var(--color-twitter);
  color:white !important;
}

.autoplaySuggestionContent  div#shareOnFacebookBtn,
.trebbleSubscribeSuggestionContent  div#shareOnFacebookBtn,
.autoplaySuggestionContent  div#shareOnTwitterBtn,
.trebbleSubscribeSuggestionContent  div#shareOnTwitterBtn{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: var(--text-base-px);
    padding: 6px;
    font-weight: bold;
    flex: 0 0 calc(50% - 10px);
    max-width: 200px;
    min-width: 100px;
    margin: 5px;
    height: 42px;
    padding: 0px;

}

.trebbleSubscribeSuggestionContent #followBtn,
.trebbleSubscribeSuggestionContent #buyNowBtn{
    width: auto;
    min-width: 200px;
    height: 46px !important;
    display: flex;
    flex: 0 0 100%;
    background-color: var(--trebble-primary);
    justify-content: center;
    align-items: center;
}

.trebbleSubscribeSuggestionContent #followBtn:hover,
.trebbleSubscribeSuggestionContent #buyNowBtn:hover,
.trebbleSubscribeSuggestionContent #followBtn:active,
.trebbleSubscribeSuggestionContent #buyNowBtn:active,
.trebbleSubscribeSuggestionContent #buyNowBtn:hover,
.trebbleSubscribeSuggestionContent #buyNowBtn:active{
    background-color: transparent !important;
    color: var(--trebble-primary) !important;
}

.autoplaySuggestionContent  div#shareOnFacebookBtn t,
.trebbleSubscribeSuggestionContent  div#shareOnFacebookBtn t,
.autoplaySuggestionContent  div#shareOnTwitterBtn t,
.trebbleSubscribeSuggestionContent  div#shareOnTwitterBtn t{
    font-size: var(--text-xl-px);
    padding:4px;

}
.trebbleSubscribeSuggestionContent 
.autoplaySuggestionContent .actionButtons,
.trebbleSubscribeSuggestionContent .secondaryActionButtons{

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    padding: 10px;
    box-sizing: border-box;
    width: 100%;

}


body.mobileweb .autoplaySuggestionContent div#shareOnFacebookBtn:hover,
body.mobileweb .autoplaySuggestionContent  div#shareOnTwitterBtn:hover,
.autoplaySuggestionContent  div#shareOnFacebookBtn:active,
.autoplaySuggestionContent  div#shareOnTwitterBtn:active,
body.mobileweb .trebbleSubscribeSuggestionContent  div#shareOnFacebookBtn:hover,
body.mobileweb .trebbleSubscribeSuggestionContent div#shareOnTwitterBtn:hover,
.trebbleSubscribeSuggestionContent  div#shareOnFacebookBtn:active,
.trebbleSubscribeSuggestionContent div#shareOnTwitterBtn:active{
 background-color: white;
 border-color:white;
 color: var(--text-dark) !important;
}

.autoplaySuggestionContent .secondaryActionButtons,
.trebbleSubscribeSuggestionContent .secondaryActionButtons{
   -webkit-box-pack: center;
   -webkit-justify-content: center;
   -ms-flex-pack: center;
   justify-content: center;
   max-width: 200px;
   margin: 30px auto;
}

.autoplaySuggestionContent #upNextContentInfo,
.trebbleSubscribeSuggestionContent #upNextContentInfo{
    margin-top: 40px;
    flex-direction: column;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    -moz-flex-direction: column;
    -webkit-box-pack: space-between;
    -webkit-justify-content: space-between;
    -ms-flex-pack: space-between;
    justify-content: space-between;
}
.autoplaySuggestionContent .toPlayNext,
.trebbleSubscribeSuggestionContent .toPlayNext{
    font-weight: bold;
    cursor: pointer;
}
.autoplaySuggestionContent,
.trebbleSubscribeSuggestionContent{

    box-sizing: border-box;
    padding: 10px;
    

}
.autoplaySuggestionContent #autoplayMessage,
.trebbleSubscribeSuggestionContent #autoplayMessage{
    text-align: center;
}
body.mobileweb  .autoplaySuggestionContent .toPlayNext:hover,
.autoplaySuggestionContent .toPlayNext:active,
body.mobileweb  .trebbleSubscribeSuggestionContent .toPlayNext:hover,
.trebbleSubscribeSuggestionContent .toPlayNext:active{
    background-color: var(--color-white-20);
}

.transparent_button.ion-button > span.buttonLabel{
   margin: 0px 5px 0px 5px;
}


@keyframes gradient-text {
  0% {
    background-position: 0% 50%;
}

25% {
    background-position: 50% 50%;
}


50% {
    background-position: 50% 50%;
}

75% {
    background-position: 50% 50%;
}

100% {
    background-position: 100% 50%;
}
}


@keyframes gradient-text-bg-float {
  0% {
    background-position: 0% 50%;
    box-shadow: 0 5px 15px 0px var(--color-black-20);
    transform: translatey(0px);
    transform: translatey(0px);
}

25% {
    background-position: 50% 50%;
    box-shadow: 0 25px 15px 0px var(--overlay-dark-15);
    transform: translatey(-10px);
    transform: translatey(-10px);
}


50% {
    background-position: 50% 50%;
    box-shadow: 0 25px 15px 0px var(--color-black-10);
    transform: translatey(-20px);
    transform: translatey(-20px);
}

75% {
    background-position: 50% 50%;
    box-shadow: 0 25px 15px 0px var(--color-black-20);
    transform: translatey(-10px);
    transform: translatey(-10px);
}

100% {
    background-position: 100% 50%;
    box-shadow: 0 5px 15px 0px var(--color-black-20);
    transform: translatey(0px);
    transform: translatey(0px);
}
}


#whatsacapsuleButton {
  /* display: none; */
  position: absolute;
  right: 0px;
  top: 170px;
  animation: gradient-text-bg-float 8s linear infinite;
  animation: gradient-text-bg-float 8s linear infinite;
  padding: 0px;
  background: var(--color-purple-400); /* fallback for old browsers */
  background: -webkit-linear-gradient(to left, var(--color-purple-400) , var(--trebble-gradient-purple)); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to left, var(--color-purple-400) , var(--trebble-gradient-purple)); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  
  
  background-size: 400% 300%;
  border: var(--border-0);
  box-shadow: 0 5px 15px 0px var(--color-black-20);
}

.createCapsulePage #whatsacapsuleButton{
  top:30px;
}


#whatsacapsuleButton > div {
  mix-blend-mode: screen;
  background-color: white;
  color: black;
  padding: 10px 30px 10px 0px;
}

#whatsacapsuleButton > span {
  background-color: white;
  color: black;
  mix-blend-mode: screen;
  padding: 10px 30px 10px 30px;
  font-size: var(--text-3xl-px);
}

  
  .waterpulse {
    margin: 100px;
    display: block;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    background: var(--pulse-yellow);
    cursor: pointer;
    box-shadow: 0 0 0 rgba(var(--pulse-yellow-rgb), var(--pulse-opacity));
    animation: waterpulse var(--pulse-duration) infinite;
}

.waterpulse:hover {
    animation: none;
  }

/* Consolidated pulse animations for different colors */
@keyframes waterpulse {
    0% { box-shadow: 0 0 0 0 var(--pulse-yellow-alpha); }
    70% { box-shadow: 0 0 0 var(--pulse-size) var(--pulse-yellow-base); }
    100% { box-shadow: 0 0 0 0 var(--pulse-yellow-base); }
}

@keyframes greenpulse {
    0% { box-shadow: 0 0 0 0 var(--pulse-green-alpha); }
    70% { box-shadow: 0 0 0 var(--pulse-size) var(--pulse-green-base); }
    100% { box-shadow: 0 0 0 0 var(--pulse-green-base); }
}

@keyframes graypulse {
    0% { box-shadow: 0 0 0 0 var(--pulse-gray-alpha); }
    70% { box-shadow: 0 0 0 var(--pulse-size) var(--pulse-gray-base); }
    100% { box-shadow: 0 0 0 0 var(--pulse-gray-base); }
}

@keyframes redpulse {
    0% { box-shadow: 0 0 0 0 rgba(var(--trebble-primary-rgb), var(--pulse-opacity)); }
    70% { box-shadow: 0 0 0 var(--pulse-size) var(--trebble-primary-0); }
    100% { box-shadow: 0 0 0 0 var(--trebble-primary-0); }
}
  
  /* Scale animations */
  @keyframes pulse {
    from { transform: scale3d(1, 1, 1); }
    50% { transform: scale3d(var(--pulse-scale-small), var(--pulse-scale-small), var(--pulse-scale-small)); }
    to { transform: scale3d(1, 1, 1); }
  }
  
  @keyframes medium_pulse {
    from { transform: scale3d(1, 1, 1); }
    50% { transform: scale3d(var(--pulse-scale-medium), var(--pulse-scale-medium), var(--pulse-scale-medium)); }
    to { transform: scale3d(1, 1, 1); }
  }
  
  /* Animation classes */
  .pulse {
    animation-name: pulse;
  }
  
  .lowpulse {
    animation: pulse 1.5s infinite;
  }

@keyframes blink-animation {
 from { opacity: 1; }
 to { opacity: 0; }
}
@-webkit-keyframes blink-animation {
 from { opacity: 1; }
 to { opacity: 0; }
}

.blink {
  animation:  blink-animation 1.5s cubic-bezier(.5, 0, 1, 1) infinite alternate;
  animation: blink-animation  1.5s cubic-bezier(.5, 0, 1, 1) infinite alternate;
}

#player.fullscreen   #reponsivePlayerContent {
  -ms-flex-direction: column;
  /* autoprefixer: ignore next */
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-flex-direction: column;
}

#player.fullscreen > [data-role=header] {
  display: none !important;
}

#player{
  height: 100%;
}
#player.fullscreen,
#player.fullscreen .ui-content,
#player.fullscreen .ui-content > div,
#player.fullscreen .ui-content > div > #reponsivePlayerContent {
  width: 100%;
  height: 100%;
}


#player #extraPlayerControlWrapper{
    height: 40px;
    display: flex; 
    justify-content: center;
    align-items: center;
    width: 100%;
}

#player #extraPlayerControlWrapper #listenOnVoiceEnabledDeviceBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    cursor: pointer;
    position: relative;
}

#player #capsuleCarouselItem .capsuleCard  .title{
    display: none;
}


#player #playerControlsWrapper #playButton .play_button_bg_circle,
#playerFooter #playerControlsWrapper #playButton .play_button_bg_circle{
    border: var(--border-1) solid white;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    display: flex;
    background-color: white;
    color: var(--text-dark);
    box-shadow: 0 5px 20px var(--color-black-20);
}
#player #playerControlsWrapper #playButton .play_button_bg_circle{
    height: 60px;
    width: 60px;
    flex: 0 0 60px;
}
#playerFooter #playerControlsWrapper #playButton .play_button_bg_circle,
body.embedded #player #playerControlsWrapper #playButton .play_button_bg_circle{
 height: 40px;
 width: 40px;
 flex: 0 0 40px;
}

#playerFooter:not(.transparent) #playerControlsWrapper #playButton .play_button_bg_circle{
    border: var(--border-1) solid var(--text-dark);
    background-color: var(--text-dark);
}
#playerFooter:not(.transparent) #playerControlsWrapper #playButton .ion-ios7-pause,
#playerFooter:not(.transparent) #playerControlsWrapper #playButton .ion-ios7-play,
#playerFooter:not(.transparent) #playerControlsWrapper #playButton .ion-loading-c {
    color:white;
}


#player #playerControlsWrapper #playButton .ion-ios7-play,
#player #playerControlsWrapper #playButton .ion-ios7-pause,
#player #playerControlsWrapper #playButton .pe-7s-play,
#player #playerControlsWrapper #playButton .pe-7s-pause{
    padding-top: 5px;
    color: var(--text-dark);
}
body.embedded #player #playerControlsWrapper #playButton .pe-7s-play,
body.embedded #player #playerControlsWrapper #playButton .pe-7s-pause{
    padding-top: 0px;
}
#player #playerControlsWrapper #playButton .ion-ios7-play,
#player #playerControlsWrapper #playButton .pe-7s-play{
    padding-left: 5px;
}

#playerFooter #playerControlsWrapper #playButton .ion-ios7-play,
#playerFooter #playerControlsWrapper #playButton .ion-ios7-pause{
    padding-top: 3px;
    color: var(--text-dark);
}
#playerFooter #playerControlsWrapper #playButton .ion-ios7-play{
    padding-left: 4px;
}


#player #extraPlayerControlWrapper #listenOnVoiceEnabledDeviceBtn  t{
 font-size: var(--text-xl-px);
 margin: 0px 10px;
}

#player #extraPlayerControlWrapper #listenOnVoiceEnabledDeviceBtn span{
    font-size: var(--text-sm);
}

#player.fullscreen   #reponsivePlayerContent > div:first-child {
  width: 100% !important;
  -webkit-box-flex: 0 !important;
  -webkit-flex: 0 0 100% !important;
  -ms-flex: 0 0 100% !important;
  flex: 0 0 100% !important;
  height: 100% !important;
  position: absolute !important;
  top: 0px;
  left: 0px;
}

#player.fullscreen     #reponsivePlayerContent > div:nth-child(2) > div{
  display: none;
}

#player.fullscreen     #reponsivePlayerContent > div:nth-child(2) {
  position: absolute;
  bottom: 0px;
  display: block;
  left: 0px;
}

body:not(.embedded) #player.fullscreen     #reponsivePlayerContent > div:nth-child(2) {
  height: 161px;
}

body.embedded #player.fullscreen     #reponsivePlayerContent > div:nth-child(2) {
  height: 110px;
}

#player.fullscreen     #reponsivePlayerContent > div:nth-child(2):hover  {
  background: -webkit-linear-gradient( var(--color-black-0), var(--color-black-60) 30%, var(--color-black-100) 100%);
  background: -webkit-linear-gradient( var(--color-black-0), var(--color-black-60) 30%, var(--color-black-100) 100%);
  background: -o-linear-gradient( var(--color-black-0), var(--color-black-60) 30%, var(--color-black-100) 100%);
  background: linear-gradient( var(--color-black-0), var(--color-black-60) 30%, var(--color-black-100) 100%);
  
}

#player.fullscreen     #reponsivePlayerContent > div:nth-child(2):hover   > div {

  display: block;
  animation: fadeIn 0.5s;
  animation: fadeIn 0.5s;
  
}

#resizeVideoFullBtn,
#resizeVideoFullBtnWrapper,
#player.fullscreen #resizeVideoFullBtn,
#player.fullscreen #resizeVideoFullBtnWrapper {
  display: none !important;
}

#player:not(.fullscreen) #exitVideoFullscreenBtn{
    display: none;
}


#exitVideoFullscreenBtn{
    padding: 0px;
    background-color: transparent;
    border: var(--border-0);
    margin: 0em;
    width: 1.7em;
    height: 1.7em;
    text-align: center;
    line-height: 1.5em;
    position: absolute;
    top: 40px;
    left: 0px;
    opacity: 0.8;
    font-size: var(--text-2xl);
    display: block;
}

#player.fullscreen{
  padding: 0px !important;
}

body:not(.embedded) #player:not(.fullscreen) {
  padding-top: 60px !important;
}




#player.fullscreen #reponsivePlayerContent > div:nth-child(1)  #mediaSourceBtn {
  top: 50px;
  bottom: auto;

}

#player.fullscreen  #reponsivePlayerContent.activeCapsule > div:nth-child(2) div#viewCapsuleDetailsBtn{
  display: none !important;
}

#player.fullscreen  #reponsivePlayerContent.activeCapsule > div:nth-child(1) div#viewCapsuleDetailsBtn{
  display: block;
  max-width: 200px;
  right: 0px;
  left: auto;
}

body:not(.embedded) #player.fullscreen  #reponsivePlayerContent.activeCapsule > div:nth-child(1) div#viewCapsuleDetailsBtn{
  bottom: 161px;
}

body.embedded #player.fullscreen  #reponsivePlayerContent.activeCapsule > div:nth-child(1) div#viewCapsuleDetailsBtn{
  bottom: 110px;
}
div#player>div.ui-header[data-role=header]>#playlistInfoWrapper{

    position: absolute;
    width: 100%;
    padding: 0px 200px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 100%;

}

div#player #songInfoWrapper,
div#playerFooter #songInfoWrapper{

    height: 60px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

}
body.embedded div#player #songInfoWrapper{
    height: 100%;
}
div#player #songInfoWrapper > div,
div#playerFooter #songInfoWrapper > div,
div#playerFooter #footerCurrentSongInfo #footerCurrentSongTitle,
div#playerFooter #footerCurrentSongInfo #footerCurrentSongArtist{
    width: 100%;
    text-overflow:ellipsis;
    text-align: center;

}

div#player #songInfoWrapper > div#playerCurrentSongTitle,
div#playerFooter #songInfoWrapper > div#playerCurrentSongTitle{
    font-size: var(--text-md);
    font-weight:900 !important;
    
}

@media (max-width: 500px), (max-height: 500px) and (orientation: landscape) {
  #player.fullscreen  #reponsivePlayerContent.activeCapsule > div:nth-child(1) div#viewCapsuleDetailsBtn {
    width: calc(100% - 20px);
    right: 10px;
}

div#player>div.ui-header[data-role=header]>#playlistInfoWrapper{
    padding: 0px 102px;
}


}

body.embedded  #player #reponsivePlayerContent > div:nth-child(1) #resizeVideoFullBtn,
body.embedded  #player #reponsivePlayerContent > div:nth-child(1) #resizeVideoFullBtnWrapper{
    display: none;
}

#player.fullscreen     #reponsivePlayerContent > div:nth-child(2) > div#artistSocialInfoAndCapsuleOverviewBox,
#player.fullscreen     #reponsivePlayerContent > div:nth-child(2) > div#commentOverviewBox{
    display: none !important;
}

body.embedded #player.fullscreen #albumArtImage {
  margin: auto;
  max-height: calc(100% - 110px - 110px);
}
body:not(.embedded) #player.fullscreen #albumArtImage {
  margin: auto;
  max-height: calc(100% - 161px - 161px);
  min-height: 200px;
}

@media  (min-height: 120px){
    #player  #reponsivePlayerContent  div:nth-child(2) div#viewCapsuleDetailsBtn{
      display:none !important;
  }
}

#playerSocialInfoWrapper #resizeVideoFullBtn{

    position: relative;
    text-align: center;
    font-weight: 400;
    
    display: flex;
    display: -webkit-flex;
    display: -moz-flex;
    display: -o-flex;
    justify-content: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    -o-justify-content: center;
    align-items: center;
    -webkit-align-items: center;
    -moz-align-items: center;
    -o-align-items: center;
    overflow: visible;


}
/*Buttons for trebblePlayer Mode*/
#player[playermode=trebblePlayer][audio_can_be_interacted_with=true] #liveLikeButton,
/*#player[playermode=trebblePlayer] #capsuleButton,*/
/*#player[playermode=trebblePlayer][audio_can_be_interacted_with=true] #sendCapsuleToHostButton,*/
#player[playermode=trebblePlayer][audio_can_be_interacted_with=true] #shareBtn,
/*Buttons for capsuleFeedPlayer Mode*/
#player[playermode=capsuleFeedPlayer][audio_can_be_interacted_with=true] #liveLikeButton,
#player[playermode=categoryShortcastRadio][audio_can_be_interacted_with=true] #liveLikeButton,
/*#player[playermode=capsuleFeedPlayer] #capsuleButton,
#player[playermode=capsuleFeedPlayer][audio_can_be_interacted_with=true] #sendCapsuleToHostButton,*/
#player[playermode=capsuleFeedPlayer][audio_can_be_interacted_with=true] #shareBtn,
#player[playermode=categoryShortcastRadio][audio_can_be_interacted_with=true] #shareBtn{
    display: -webkit-box !important;
    display: -webkit-flex !important;
    display: -ms-flexbox !important;
    display: flex !important;
}


body.mobilebrowser:not(.embedded) #player[playermode=trebblePlayer][audio_can_be_interacted_with=true] #converseButton,
body:not(.mobilebrowser):not(.embedded) #player[playermode=trebblePlayer][audio_can_be_interacted_with=true] #converseButton,
body.embedded #player[playermode=trebblePlayer][audio_can_be_interacted_with=true] #converseButton,
body #player.fullscreen[playermode=trebblePlayer][audio_can_be_interacted_with=true] #converseButton,
body.mobilebrowser:not(.embedded) #player[playermode=capsuleFeedPlayer][audio_can_be_interacted_with=true] #converseButton,
body.mobilebrowser:not(.embedded) #player[playermode=categoryShortcastRadio][audio_can_be_interacted_with=true] #converseButton,
body:not(.mobilebrowser):not(.embedded) #player[playermode=capsuleFeedPlayer][audio_can_be_interacted_with=true] #converseButton,
body:not(.mobilebrowser):not(.embedded) #player[playermode=categoryShortcastRadio][audio_can_be_interacted_with=true] #converseButton,
body.embedded #player[playermode=capsuleFeedPlayer][audio_can_be_interacted_with=true] #converseButton,
body.embedded #player[playermode=categoryShortcastRadio][audio_can_be_interacted_with=true] #converseButton,
body #player.fullscreen[playermode=capsuleFeedPlayer][audio_can_be_interacted_with=true] #converseButton,
body #player.fullscreen[playermode=categoryShortcastRadio][audio_can_be_interacted_with=true] #converseButton,

#player[playermode=trebblePlayer] #loopButton, 
#player[playermode=trebblePlayer] #previousButton, 
/*body.browser:not(.mobilebrowser):not(.embedded) #player:not(.fullscreen)[playermode=trebblePlayer]#converseButton,*/
#player[playermode=trebblePlayer] #shareBtn,
#player[playermode=trebblePlayer] #shuffleButton,
#player[playermode=capsuleFeedPlayer] #loopButton, 
#player[playermode=categoryShortcastRadio] #loopButton, 
#player[playermode=capsuleFeedPlayer] #previousButton, 
#player[playermode=categoryShortcastRadio] #previousButton, 
/*body.browser:not(.mobilebrowser):not(.embedded) #player:not(.fullscreen)[playermode=capsuleFeedPlayer] #converseButton,
body.browser:not(.mobilebrowser):not(.embedded) #player:not(.fullscreen)[playermode=categoryShortcastRadio] #converseButton,*/
#player[playermode=capsuleFeedPlayer] #shareBtn,
#player[playermode=categoryShortcastRadio] #shareBtn,
/*#player[playermode=categoryShortcastRadio] [data-role=header] #subscribeButton,
#player[playermode=categoryShortcastRadio] [data-role=header] #unsubscribeButton,*/
#player:not([playermode=capsuleFeedPlayer]) .listen_on_voice_enabled_devices_tooltip,
#player:not([playermode=categoryShortcastRadio]) .listen_on_voice_enabled_devices_tooltip,
#player:not([playermode=capsuleFeedPlayer]) #listenOnVoiceEnabledDeviceBtn,
#player[playermode=capsuleFeedPlayer] #shuffleButton,
#player[playermode=categoryShortcastRadio] #shuffleButton{
    display: none !important;
}


#player[audio_can_be_interacted_with=false] #liveLikeButton,
#player[audio_can_be_interacted_with=false] #converseButton,
#player[audio_can_be_interacted_with=false] #shuffleButton,
#player[audio_can_be_interacted_with=false] #capsuleButton,
#player[audio_can_be_interacted_with=false] #sendCapsuleToHostButton,
#player[audio_can_be_interacted_with=false] #loopButton,
#player[audio_can_be_interacted_with=false] #shareBtn,

#playerFooter[audio_can_be_interacted_with=false] #liveLikeButton,
#playerFooter[audio_can_be_interacted_with=false] #converseButton,
#playerFooter[audio_can_be_interacted_with=false] #shuffleButton,
#playerFooter[audio_can_be_interacted_with=false] #capsuleButton,
#playerFooter[audio_can_be_interacted_with=false] #sendCapsuleToHostButton,
#playerFooter[audio_can_be_interacted_with=false] #loopButton,
#playerFooter[audio_can_be_interacted_with=false] #shareBtn{
    display: none !important;
}

#player[playermode=trebblePlayer] #tuninButton,
#player[playermode=trebblePlayer] #header-queue-button,#player #unsubscribeButton,
#player[playermode=capsuleFeedPlayer] #tuninButton,
#player[playermode=categoryShortcastRadio] #tuninButton,
#player[playermode=trebblecapsuleFeedPlayerPlayer] #header-queue-button,
#player #unsubscribeButton,
#player #subscribeButton{
    display: none;
}

#player #tuninButton{
    display: none;
}

#player[playermode=trebblePlayer][canbesubscribedto=true][subscribed=true] #unsubscribeButton,
#player[playermode=trebblePlayer][canbesubscribedto=true][subscribed=false] #subscribeButton,
#player[playermode=capsuleFeedPlayer][canbesubscribedto=true][subscribed=true] #currentAudioLoadedInfoSection #unsubscribeButton,
#player[playermode=capsuleFeedPlayer][canbesubscribedto=true][subscribed=false] #currentAudioLoadedInfoSection #subscribeButton,
#player[playermode=categoryShortcastRadio][canbesubscribedto=true][subscribed=true] #currentAudioLoadedInfoSection #unsubscribeButton,
#player[playermode=categoryShortcastRadio][canbesubscribedto=true][subscribed=false] #currentAudioLoadedInfoSection #subscribeButton{
    display: -webkit-box ;
    display: -webkit-flex ;
    display: -ms-flexbox ;
    display: flex ;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    height: 40px;
}


#player #subscribeButton > .extensibleLabel,
#player #unsubscribeButton > .extensibleLabel {
  font-size: var(--text-base);
}

#player #subscribeButton > .iconWrapper,
#player #unsubscribeButton > .iconWrapper{
  font-size: var(--text-xl);
}

#songPoppupMenu > div > ul >li.show_on_mobile_only{
    display: none

}

.display_on_desktop{
    display: flex  !important;
}

.display_on_mobile{
    display: none !important;
}



/*Buttons for capsulePlayer Mode*/
#player[playermode=capsulePlayer][audio_can_be_interacted_with=true] #liveLikeButton,
#player[playermode=capsulePlayer][audio_can_be_interacted_with=true] #shareBtn{
    display: block !important;
}

body.mobilebrowser:not(.embedded)  #player[playermode=capsulePlayer][audio_can_be_interacted_with=true] #converseButton,
body:not(.mobilebrowser):not(.embedded)  #player[playermode=capsulePlayer][audio_can_be_interacted_with=true] #converseButton,
body.embedded #player[playermode=capsulePlayer][audio_can_be_interacted_with=true] #converseButton,
body #player.fullscreen[playermode=capsulePlayer][audio_can_be_interacted_with=true] #converseButton,
#player[playermode=capsulePlayer] #loopButton, 
/*#player[playermode=capsulePlayer] #previousButton,*/ 
/*body.browser:not(.mobilebrowser):not(.embedded) #player:not(.fullscreen)[playermode=capsulePlayer][audio_can_be_interacted_with=true] #converseButton,*/
/*#player[playermode=capsulePlayer] #capsuleButton,*/
#player[playermode=capsulePlayer][audio_can_be_interacted_with=true] #sendCapsuleToHostButton,
#player[playermode=capsulePlayer] #shuffleButton{
    display: none !important;
}

/*Buttons for songPlayer Mode*/
#player[playermode=songPlayer][audio_can_be_interacted_with=true] #liveLikeButton,
#player[playermode=songPlayer][audio_can_be_interacted_with=true] #shareBtn,
#player[playermode=songPlayer][audio_can_be_interacted_with=true] #previousButton{
    display: -webkit-box !important;
    display: -webkit-flex !important;
    display: -ms-flexbox !important;
    display: flex !important;
}

body.mobilebrowser:not(.embedded)  #player[playermode=songPlayer][audio_can_be_interacted_with=true] #converseButton,
body:not(.mobilebrowser):not(.embedded)  #player[playermode=songPlayer][audio_can_be_interacted_with=true] #converseButton,
body.embedded #player[playermode=songPlayer][audio_can_be_interacted_with=true] #converseButton,
body #player.fullscreen[playermode=songPlayer][audio_can_be_interacted_with=true] #converseButton,
#player[playermode=songPlayer] #loopButton, 
#player[playermode=songPlayer] #shuffleButton,
/*#player[playermode=songPlayer] #capsuleButton, */
#player[playermode=songPlayer][audio_can_be_interacted_with=true] #sendCapsuleToHostButton, 
/*body.browser:not(.mobilebrowser):not(.embedded) #player:not(.fullscreen)[playermode=songPlayer][audio_can_be_interacted_with=true] #converseButton,*/
#player[playermode=songPlayer][audio_can_be_interacted_with=true] #nextButton{
    display: none !important;
}

/*Buttons for defaultPlayer Mode*/

#player[playermode=defaultPlayer] #loopButton,
#player[playermode=defaultPlayer] #previousButton,
#player[playermode=defaultPlayer] #shuffleButton{
    display: -webkit-box !important;
    display: -webkit-flex !important;
    display: -ms-flexbox !important;
    display: flex !important;
}

#player[playermode=defaultPlayer][audio_can_be_interacted_with=true] #liveLikeButton, 
#player[playermode=defaultPlayer][audio_can_be_interacted_with=true] #converseButton, 
#player[playermode=defaultPlayer][audio_can_be_interacted_with=true] #shareBtn,
/*#player[playermode=defaultPlayer] #capsuleButton*/
#player[playermode=defaultPlayer][audio_can_be_interacted_with=true] #sendCapsuleToHostButton{
    display: none !important;
}

body.browser:not(.mobilebrowser):not(.embedded)  #player:not(.fullscreen):not([playermode=defaultPlayer]) #playerControlsWrapper > [data-role=controlgroup] > :first-child > a,
body.browser:not(.mobilebrowser):not(.embedded)  #player:not(.fullscreen):not([playermode=defaultPlayer]) #playerControlsWrapper > [data-role=controlgroup] > :first-child > o{
    width: 24%;
}

paper-input::shadow input::-webkit-input-placeholder{
  opacity: 0.5;
}

*::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  opacity: 0.5;
}

*:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  opacity: 0.5;
}

*::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  opacity: 0.5;
}

*:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  opacity: 0.5;
}

.linkButton{
    font-size: small;
    color: var(--trebble-primary);
    font-weight: bold;
    margin: 0px 10px 0px 10px;
    cursor: pointer;
}

.fieldDesc .linkButton{
    color: var(--text-dark);
}

.linkButton.helper{
    color: white;
    animation: gradient-text-bg 8s linear infinite;
    animation: gradient-text-bg 8s linear infinite;
    padding: 0px;
    background: var(--color-purple-400);   /* fallback for old browsers */
    background: linear-gradient(to left, var(--color-purple-400) , var(--trebble-gradient-purple));   /* Chrome 10-25, Safari 5.1-6 */
    background: -webkit-linear-gradient(to left, var(--color-purple-400) , var(--trebble-gradient-purple));   /* Chrome 10-25, Safari 5.1-6 */
    background: -webkit-linear-gradient(right, var(--color-purple-400) , var(--trebble-gradient-purple));
    background: linear-gradient(to left, var(--color-purple-400) , var(--trebble-gradient-purple));   /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    background-size: 400% 300%;

}

.linkButton > span.iconButton{
  padding-right: 5px;
  font-size: medium;
}

.linkButton.helper > span{
    background-color: white;
    mix-blend-mode: screen;
    color: black;

}

.fabButton {
  background-color: var(--trebble-primary);
  box-shadow: 0px 5px 11px -2px var(--color-black-30), 
  0px 4px 12px -7px var(--color-black-30);
  border-radius: 50%;
  display: block;
  margin: 20px auto 0;
  position: relative;
  -webkit-transition: all .1s ease-out;
  transition: all .1s ease-out;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: white !important;
  height: 50px;
  width: 50px;
  font-size: var(--text-3xl-px);
  background: var(--gradient-primary-alt);
}




.fabButton:active,
.fabButton:focus,
body.mobileweb .fabButton:hover {
  box-shadow: 0 0 4px var(--color-black-30), 0 4px 8px var(--color-black-40);
}

.fabButton:not(:last-child) {
  margin: 20px auto 0;
  opacity: 0;
  transform: translateY(50px);
  -ms-transform: translateY(50px);
  transform: translateY(50px);
}

/*.fabButtonContainer {
  position: fixed;
  top: 570px;
  right: 20px;
  }*/

  .fabButtonContainer.expanded
  .fabButton:not(:last-child) {
      opacity: 1;
      transform: none;
      -ms-transform: none;
      transform: none;
      margin: 15px auto 0;
  }

  /* Unessential styling for sliding up buttons at differnt speeds */

  .fabButton:nth-last-child(1) {
      -webkit-transition-delay: 25ms;
      transition-delay: 25ms;
      font-size: var(--text-3xl-px);
  }

  .fabButton:not(:last-child):nth-last-child(2) {
      -webkit-transition-delay: 50ms;
      transition-delay: 20ms;
      font-size: var(--text-lg-px);
      height: 60px;
      width: 60px;

  }

  .fabButton:not(:last-child):nth-last-child(3) {
      -webkit-transition-delay: 75ms;
      transition-delay: 40ms;
      font-size: var(--text-lg-px);
      height: 60px;
      width: 60px;
  }

  .fabButton:not(:last-child):nth-last-child(4) {
      -webkit-transition-delay: 100ms;
      transition-delay: 60ms;
      font-size: var(--text-lg-px);
  }
  .fabButtonContainer.expanded  .fabButton:last-child{
      background-color: var(--trebble-primary-dark);
      background-color: white;
      /*color:  var(--trebble-primary) !important;*/
      color:  white !important;
  }

  .fabButtonContainer.expanded .fabButton{
      cursor: pointer;
  }

  #addContentFabBtn.fabButton > .ion-ios7-close-empty{
    display: none;
}

.fabButtonContainer.expanded #addContentFabBtn.fabButton > .ion-ios7-plus-empty,
.fabButtonContainer.expanded #addContentFabBtn.fabButton > .ion-contrast{
    display: none;
}

body[appName=trebble] .ion-contrast:before {
  content: "\e901";
  font-family: 'soundwave' !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transform: rotate(0deg);
  transform: rotate(0deg);
}

.fabButtonContainer.expanded #addContentFabBtn.fabButton > .ion-ios7-close-empty{
    display: block;
}

.fabButtonContainer:not(.expanded) > .fabButton:nth-child(2),
.fabButtonContainer:not(.expanded) > .fabButton:nth-child(1){
  display:none;
}

body.browser.mobileweb  .fabButtonContainer:hover > .fabButton:nth-child(2),
body.browser.mobileweb  .fabButtonContainer:hover > .fabButton:nth-child(1),
.fabButtonContainer:active > .fabButton:nth-child(2),
.fabButtonContainer:active > .fabButton:nth-child(1){
  display:flex;
  display:-webkit-flex;
  display:-moz-flex;
  display:-ms-flex;
}

body.browser.mobileweb .fabButton.hint:hover:before, 
body.browser.mobileweb .fabButton.hint:hover:after, 
body.browser.mobileweb  .fabButton[data-hint]:hover:before, 
body.browser.mobileweb .fabButton[data-hint]:hover:after{
    -webkit-transition-delay: 0ms;
    transition-delay: 0ms;
}
#fabAddButtonContainer,
#createNewCapsuleFloatingBtnWrapper,
#playMyFeedFloatingBtnWrapper{
 top: auto;
 bottom: 80px;
 right: 20px;
 position: fixed;
 z-index: 2;
}

body.useFloatingPlayer #fabAddButtonContainer,
body.useFloatingPlayer #createNewCapsuleFloatingBtnWrapper,
body.useFloatingPlayer #playMyFeedFloatingBtnWrapper{

 bottom: 160px;

}

#playMyFeedFloatingBtnWrapper .fabButton,
#createNewCapsuleFloatingBtnWrapper #addCapsuleFabBtn,
#fabAddButtonContainer #addContentFabBtn{
    width:70px;
    height:70px;
}

#playMyFeedFloatingBtnWrapper .fabButton t {
    font-size: var(--text-lg);
}

#createNewCapsuleFloatingBtnWrapper #addCapsuleFabBtn t{
    font-size: var(--text-md) ;
}

#createNewCapsuleFloatingBtnWrapper #addCapsuleFabBtn t.ion-contrast{

    position: relative;
    font-size: var(--text-xl);
}

#createNewCapsuleFloatingBtnWrapper #addCapsuleFabBtn t.ion-contrast i.ion-ios7-plus-empty{
    position: absolute;
    font-size: var(--text-xl-px);
    color: white;
    height: 15px;
    -webkit-text-stroke-width: thin;
    align-items: center;
    bottom: 10px;
    right: 0px;
    display: flex;
    justify-content: center;
    width: 15px;
    border-radius: 50%;
}

#fabAddButtonContainer #addContentFabBtn t{
    font-size: var(--text-sm) !important;
}
#fabAddButtonContainer #addContentFabBtn t b{
    top: -12px !important;
    left: -12px !important;
    font-size: var(--text-base) !important;
}
#fabAddButtonContainer #addContentFabBtn t.ion-ios7-close-empty,
#fabAddButtonContainer #addContentFabBtn t.ion-ios7-plus-empty{
    font-size: var(--text-lg) !important;
}


#createNewCapsuleFloatingBtnWrapper #addCapsuleFabBtn,
#playMyFeedFloatingBtnWrapper .playIconWrapper,
#playMyFeedFloatingBtnWrapper #pauseIconWrapper,
#play_feed_btn .playIconWrapper,
#play_feed_btn #pauseIconWrapper{
    cursor: pointer;
}
#play_feed_btn .playIconWrapper,
#play_feed_btn #pauseIconWrapper{
    text-transform: uppercase;
}
#playMyFeedFloatingBtnWrapper #playMyFeedBtn .pe-7s-play{
    margin-left: 4px;
    /*margin-top: 6px;*/
}

.tabheader{
  line-height: 40px;
  vertical-align: middle;
  font-size: var(--text-md);
  margin-top: 4px;
  margin-bottom: 4px;
  border-bottom: var(--border-1) solid var(--color-black-5);
  color: var(--color-black-30);
  -ms-flex-line-pack: center;
  -moz-flex-line-pack: center;
  align-content: center;
  -webkit-align-content: center;
  -ms-flex-direction: row;
  -moz-flex-direction: row;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
  -webkit-flex-direction: row;
  display: -ms-flexbox;
  display: -moz-flexbox;
  display: -webkit-box;
  display: flex;
  display: -webkit-flex;
  display: -moz-flex;
  height: 40px;
  background-color: transparent;
  font-weight: 600;
  -webkit-align-items: center;
  -ms-flex-align: center;
  -webkit-box-align: center;
  align-items: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -webkit-justify-content: center;
}

.tabheader > div {
  width: auto;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  min-width: 100px;
  padding: 0px 20px 0px 20px;
  box-sizing: border-box;
  cursor: pointer;
  letter-spacing: 4px;
  text-transform: uppercase;
  font-size: 80%;
  z-index: 2;
}

body.mobileweb .tabheader > div:hover,
.tabheader > div:active{
  color: white;
}


.tabheader > div.selected{
  color: var(--trebble-primary);
  border-bottom: var(--border-3) solid;
}

.journeyPage .highlight_button{
    background-color: white; 
}


#shareTrebbleBtn > span,
#shareTrebbleBtn > t,
.journeyPage .highlight_button > span ,
.journeyPage .highlight_button > t{
  background-color: white;
  color: black;
  mix-blend-mode: screen;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items:center;

}
[data-role=page].createCapsulePage[saveAsDraft=true] .doNotAddOnAirCheckboxWrapper,
[data-role=page].createCapsulePage[saveAsDraft=true] .doNotAddOnAirCheckboxWrapper.field_wrapper,
[data-role=page].createCapsulePage[saveAsDraft=true] .songToPlayAfterCapsuleFieldWrapper.field_wrapper{
    display: none !important;
}



@media (max-width: 500px){
  .extensibleBtn  .extensibleLabel{
    display: none !important;
}


[data-role=header] .highlight_button.extensibleBtn{
    background-color: white;
    color: black !important;
    width: auto;
}

[data-role=header] .highlight_button.extensibleBtn .extensibleLabel{
    display: flex !important;
    color: black !important;
    font-size: var(--text-md-px);
    mix-blend-mode: normal;
}


[data-role=header] .highlight_button.extensibleBtn t{
    /*font-size: 80%;*/
    color: black !important;
    mix-blend-mode: normal;
}

[data-role=header] .extensibleBtn.hide_on_mobile{
    display: none !important;
}

[data-role=header] .extensibleBtn.hide_on_mobile{
    display: none !important;
}

#songPoppupMenu > div > ul > li.show_on_mobile_only{
    display: block;
}

.display_on_desktop{
    display: none !important;
}

.display_on_mobile{
    display: flex !important;
}


#shareTrebbleBtn > span,
#shareTrebbleBtn >  t,
.journeyPage .highlight_button > span,
.journeyPage .highlight_button > t {
    background-color: white;
    /*color: white;*/
}

/*.playlistDetailsPage:not(.journeyPage) [data-role=header] #shareTrebbleBtn{*/
    .playlistDetailsPage [data-role=header] #shareTrebbleBtn{
        background: transparent;
        border: var(--border-0);
    }

    /*.playlistDetailsPage:not(.journeyPage) [data-role=header] #shareTrebbleBtn t{*/
        .playlistDetailsPage [data-role=header] #shareTrebbleBtn t{
            background-color: transparent;
            color: white;
            font-weight: bold;
        }
        /*
        .playlistDetailsPage #shareTrebbleBtn > t{
            background-color:transparent;
            color: white;
            }*/

            .createCapsulePage.lighttext.transparent [data-role=header] * #nextButton,
            .createCapsulePage.lighttext.transparent [data-role=header] * #saveButtonIcon{
                color: white !important;
                background-color: transparent;
            }

            #player #subscribeButton, #player #unsubscribeButton{
                padding:0px;
                border:var(--border-0); 
            }
        }

        #songPoppupMenu-popup #songPoppupMenu > div > .ui-content,
        #songPoppupMenu-popup #songPoppupMenu > div > ul,
        #songPoppupMenu-popup #songPoppupMenu > div > #shareTrebblePopupContent {
            padding: 20px;
            margin:20px;
            width:calc(100% - 40px);
            box-sizing: border-box;
            max-width: 800px;
        }

        body:not(.embedded) #songPoppupMenu-popup #songPoppupMenu > div > .ui-content,
        body:not(.embedded) #songPoppupMenu-popup #songPoppupMenu > div > ul,
        body:not(.embedded) #songPoppupMenu-popup #songPoppupMenu > div > #shareTrebblePopupContent {
            border: var(--border-4) solid white;
        }


        @media (min-width: 500px){




          [data-role=controlgroup].extensibleButtons {
              float: right;
              flex: 1 1 auto;
              margin: 0px;
          }



          .extensibleBtn .extensibleLabel{
              font-size: var(--text-md-px);
              font-weight:normal;
          }

          [data-role=header] .extensibleBtn  .extensibleLabel,
          [data-role=footer] .extensibleBtn  .extensibleLabel,
          .playlistDetailsPage.publicPage #exploreTrebblesBtn{
              font-weight:bold;
              /*font-size: var(--text-lg-px);*/
          }

          [data-role=page].transparent [data-role=header] .extensibleBtn,
          [data-role=page].transparent div[data-role=header] * .ui-btn.extensibleBtn,
          [data-role=page].transparent [data-role=header] * #editButton.extensibleBtn,
          [data-role=page].transparent [data-role=header] * #menuOptionButton.extensibleBtn, 
          [data-role=page].transparent [data-role=header] * #filterButton.extensibleBtn, 
          [data-role=page].transparent [data-role=header] * #deleteButton.extensibleBtn, 
          [data-role=page].transparent [data-role=header] * #saveButtonIcon.extensibleBtn{
            width: auto !important;
            flex: 0 0 auto !important;
            -webkit-flex: 0 0 auto !important;
            -ms-flex: 0 0 auto !important;
            -moz-flex: 0 0 auto !important;
            align-items: center!important;
            -webkit-align-items: center!important;
            -ms-align-items: center!important;
            -moz-align-items: center!important;
            flex-direction: row!important;
            -webkit-flex-direction: row!important;
            -ms-flex-direction: row!important;
            -moz-flex-direction: row!important;
            justify-content: center !important;
            -webkit-justify-content: center !important;
            -ms-justify-content: center !important;
            -moz-justify-content: center !important;
            display: -ms-flexbox;
            display: flex;
            display: -webkit-flex;
            padding: 0px 15px !important;
            border: var(--border-2) solid white !important;
            margin-right: 10px!important;
            margin-top: 10px!important;
            height: 40px !important;
            background-color: var(--text-dark);
            border-color: transparent;
        }

        .extensibleBtn:hover,
        .extensibleBtn:active,
        .extensibleBtn.ui-btn:hover,
        .extensibleBtn.ui-btn:active{
            background-color: var(--text-dark);
            border-color: transparent;
        }

        html body .ui-group-theme-c .ui-btn.disabled:hover{
            background-color: transparent;
        }

        .extensibleBtn.hint--left:before,
        .extensibleBtn.hint--left:after{
            display: none !important;
        }

        .extensibleBtn,
        div[data-role=header] * .ui-btn.extensibleBtn,
        [data-role=header] * #editButton.extensibleBtn,
        [data-role=header] * #menuOptionButton.extensibleBtn, 
        [data-role=header] * #filterButton.extensibleBtn, 
        [data-role=header] * #deleteButton.extensibleBtn, 
        [data-role=header] * #saveButtonIcon.extensibleBtn,
        [data-role=footer].extensibleButtons * .actionButton.extensibleBtn{
           width: auto !important;
           flex: 0 0 auto !important;
           -webkit-flex: 0 0 auto !important;
           -ms-flex: 0 0 auto !important;
           -moz-flex: 0 0 auto !important;
           align-items: center!important;
           -webkit-align-items: center!important;
           -ms-align-items: center!important;
           -moz-align-items: center!important;
           flex-direction: row!important;
           -webkit-flex-direction: row!important;
           -ms-flex-direction: row!important;
           -moz-flex-direction: row!important;
           justify-content: center !important;
           -webkit-justify-content: center !important;
           -ms-justify-content: center !important;
           -moz-justify-content: center !important;
           display: -ms-flexbox;
           display: flex;
           display: -webkit-flex;
           padding: 0px 20px !important;
           height: 50px;
           margin: 0px;

       }
       .extensibleBtn  t,
       .extensibleBtn  i,
       .extensibleBtn  .iconWrapper {
          margin-left:10px;
          /*margin-right:10px;*/


      }


      .playlistDetailsPage #shareTrebbleBtn{
         background-color: var(--trebble-primary);
         margin: 4px;
         height: 40px;
         display: flex;
     }

     .journeyPage  [data-role=header] .ui-btn.extensibleBtn{
        margin: 4px;
        height: 40px; 
        padding: 0px !important;
    }



    #featurePage * .tag.actionButton > i.ion-ios7-arrow-thin-left,
    #GetStartedForCuratorsPage * .tag.actionButton > i.ion-ios7-arrow-thin-left,
    #createCapsuleInfoHeader  * .tag.actionButton > i.ion-ios7-arrow-thin-left {
      margin-right: 10px;
      margin-left: 0px; 
  }
}



[data-role=header] #shareTrebbleBtn, .journeyPage [data-role=header]  .highlight_button{
    animation: gradient-text 8s linear infinite;
    animation: gradient-text 8s linear infinite;
    /*background: var(--color-purple-400);*/ /* fallback for old browsers */
    /*background: -webkit-linear-gradient(to left, var(--color-purple-400) , var(--trebble-gradient-purple));*/ /* Chrome 10-25, Safari 5.1-6 */
    /*background: linear-gradient(to left, var(--color-purple-400) , var(--trebble-gradient-purple));*/
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items:center;
    padding: 0px !important;
    overflow: visible;
    background-size: 400% 300%;
    margin: 4px;
    background-color: white;
    width: auto;
    border: var(--border-2) solid white;
    background-color: var(--text-dark);
}



#shareTrebbleBtn > span,
.journeyPage .highlight_button > span {
    padding: 0px 20px 0px 20px;
    height: 100%;
}

#shareTrebbleBtn > t,
.journeyPage .highlight_button > t{
    padding: 0px 20px 0px 10px;
    height: 100%;
    margin: 0px;
}
.playlistItem.withLargeAlbumArt #shareTrebbleBtn{
  mix-blend-mode: normal;
  background: transparent;
  color:white !important;
  border-color:transparent;
  
  
}

.playlistItem.withLargeAlbumArt .buttonBar{

    align-content: center;
    flex:  0 0 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;

}

.playlistItem.withLargeAlbumArt .buttonBar div.transparent_button,
.playlistItem.withLargeAlbumArt .buttonBar div.transparent_button:hover{
    width: fit-content;
    text-align:center;
    margin: auto;
    /*min-width: 190px;*/
    font-weight: bold;
    padding: 0px;
    text-transform: uppercase;

    justify-content: center;
    align-items: center;
    display: flex;

    overflow: hidden;
}
.playlistItem.withLargeAlbumArt .buttonBar  #buyNowBtn.loading > #loadingImg{
    margin: 0px 70px;
}

.playlistItem.withLargeAlbumArt .buttonBar div.transparent_button#followingBtn,
.playlistItem.withLargeAlbumArt .buttonBar div.transparent_button#purchasedBtn,
.playlistItem.withLargeAlbumArt .buttonBar div.transparent_button#followingBtn:hover,
.playlistItem.withLargeAlbumArt .buttonBar div.transparent_button#purchasedBtn:hover{
    background-color: var(--color-gray-100);
    border-color: var(--color-gray-100);
    color: var(--text-dark);
}
.playlistItem.withLargeAlbumArt .buttonBar div.transparent_button > span{
    display: flex;
    padding: 0px 40px;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.playlistItem.withLargeAlbumArt .buttonBar div.transparent_button#buyNowBtn div.button_content{
 padding: 0 40px;
}

.playlistItem.withLargeAlbumArt .buttonBar #followBtn,
.playlistItem.withLargeAlbumArt .buttonBar #buyNowBtn{
    /*background-color: var(--trebble-primary);*/
    background-color: transparent;
    border-color: var(--trebble-primary);
    /*color: white;*/
    color: var(--trebble-primary);
    font-weight: bold;
    border-color: var(--bg-warm);
}
.playlistItem.withLargeAlbumArt .buttonBar .followBtnLabel,
.playlistItem.withLargeAlbumArt .buttonBar .button_content{
    /*background-color: var(--trebble-primary);
    color: white;*/
    color: var(--trebble-primary);
    background-color: var(--bg-warm);
}

.playlistItem.withLargeAlbumArt .buttonBar .followingBtnLabel,
.playlistItem.withLargeAlbumArt .buttonBar .purchasedBtnLabel{
    /*background-color: var(--trebble-primary);
    color: white;*/
    background-color: var(--color-gray-100);
    color: var(--text-dark);

}

body.mobileweb  .playlistItem.withLargeAlbumArt .buttonBar #followBtn:hover,
.playlistItem.withLargeAlbumArt .buttonBar #followBtn:active,
body.mobileweb  .playlistItem.withLargeAlbumArt .buttonBar #buyNowBtn:hover,
.playlistItem.withLargeAlbumArt .buttonBar #buyNowBtn:active{
    background-color: transparent;
}
body.mobileweb .playlistItem.withLargeAlbumArt .buttonBar #followBtn.transparent_button > span:hover,
.playlistItem.withLargeAlbumArt .buttonBar #followBtn.transparent_button > span:active,
body.mobileweb .playlistItem.withLargeAlbumArt .buttonBar #buyNowBtn.transparent_button > .button_content:hover,
.playlistItem.withLargeAlbumArt .buttonBar #buyNowBtn.transparent_button > button_content:active{
   background-color: transparent;
   border-color: var(--trebble-primary);
   color: var(--trebble-primary) !important;

}

body.mobileweb .playlistItem.withLargeAlbumArt .buttonBar #followingBtn.transparent_button:hover,
body.mobileweb .playlistItem.withLargeAlbumArt .buttonBar #purchasedBtn.transparent_button:hover,
.playlistItem.withLargeAlbumArt .buttonBar #followingBtn.transparent_button:active,
.playlistItem.withLargeAlbumArt .buttonBar #purchasedBtn.transparent_button:active{
 /*background-color: var(--trebble-primary);
 border-color: var(--trebble-primary);
 color: white !important;*/
 font-weight: bold;
 color: var(--trebble-primary) !important;
 background-color: var(--bg-warm);
 border-color: var(--bg-warm);

}

.journeyPage .subscribe_tooltip,
.playlistDetailsPage .subscribe_tooltip{
    right: calc(50% - 100px);
    background: var(--color-purple-400);
    color: var(--text-light);
    width: 200px;
}
.journeyPage [data-role=header] .subscribe_tooltip, .playlistDetailsPage [data-role=header] .subscribe_tooltip{
    right: 10px;
    width: 200px;
}

.journeyPage .subscribe_tooltip:after,
.playlistDetailsPage .subscribe_tooltip:after{
    border-color: transparent transparent var(--color-purple-400);
}


.playlistItem.withLargeAlbumArt .buttonBar .subscribe_tooltip.center_arrow{
    right: calc(50% - 125px);
}
.playlistItem.withLargeAlbumArt:not(.isCurrentlyPlaying) .buttonBar .subscribe_tooltip.center_arrow,
[data-role=page].do_not_show_subscribe_tooltip .subscribe_tooltip{
    display: none !important;
}


body.white-theme.browser.mobileweb .playlistItem.withLargeAlbumArt #shareTrebbleBtn:hover,
.playlistItem.withLargeAlbumArt #shareTrebbleBtn:active{
   background: var(--trebble-primary);
   border-color:transparent;
}
.playlistItem.withLargeAlbumArt #shareTrebbleBtn  > t{
    background: transparent;
    color:white !important;
    padding: 0px;
}


.drop-element.drop-theme-arrows-dark .drop-content {

    -webkit-transition: opacity cubic-bezier(0,0,.3,1) .25s,transform cubic-bezier(0,0,.3,1) .25s;
    -moz-transition: opacity cubic-bezier(0,0,.3,1) .25s,-moz-transform cubic-bezier(0,0,.3,1) .25s;
    -o-transition: opacity cubic-bezier(0,0,.3,1) .25s,-o-transform cubic-bezier(0,0,.3,1) .25s;
    -ms-transition: opacity cubic-bezier(0,0,.3,1) .25s,-ms-transform cubic-bezier(0,0,.3,1) .25s;
    transition: opacity cubic-bezier(0,0,.3,1) .25s,transform cubic-bezier(0,0,.3,1) .25s;

}

.feature.infoBoxWrapper > .content > p > .iconWrapper{
    height: 159px;
    display: -ms-flexbox;
    display: -moz-flexbox;
    display: -webkit-box;
    display: flex;
    display: -webkit-flex;
    display: -moz-flex;
    -webkit-align-items: center;
    -ms-flex-align: center;
    -webkit-box-align: center;
    align-items: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-align-items: center;
    -webkit-justify-content: center;
    -ms-flex-line-pack: center;
    -moz-flex-line-pack: center;
    align-content: center;
    -webkit-align-content: center;


}

.capsuleSendIntroMessage .userCoverArtWrapper{
    padding: 20px;
}


.capsuleSendIntroMessage .userCoverArtWrapper #userProfileImage{
    height: 100px;
    margin: auto;
    width: 100px;
}

@media (min-width: 800px){
    .capsuleSendIntroMessage .userCoverArtWrapper #userProfileImage{
        height: 160px;
        margin: auto;
        width: 160px;
    }
}

body.browser.mobileweb [data-role=page] .infoBoxWrapper.capsuleSendIntroMessage > .content > .actionButtonWrapper {
    max-width: 200px;
}

body.browser.mobileweb [data-role=page] .infoBoxWrapper.capsuleSendIntroMessage > .content > .actionButtonWrapper > a.actionButton:hover,
body.browser.mobileweb [data-role=page] .infoBoxWrapper.capsuleSendIntroMessage > .content > .actionButtonWrapper > a.actionButton:active{
 border: var(--border-2) solid white; 
 color: white;
 background-color: transparent;
 font-weight: bold;
}

@media (max-width: 450px) {
    #mylibrary [role=tablist] > li,
    #mySubscriptions [role=tablist] > li ,
    #myJourneys [role=tablist] > li {
      width: 50%;
  }

  #mylibrary #capsuleList  [role=tablist] > li {
    width: 25%;
}
}

.darkscreen{

    width: 100%;
    position: absolute;
    top: 0px;
    height: 100%;
    background-color: black;
    opacity: 0.3;
}

.userProfileDetailsPage #shareUserCmailUrlBtn,
.userProfileDetailsPage #sendCmailToUserBtn{
    position: absolute;
    top:60px;
    right:0px;
    margin:0px 10px;
    z-index: 1;
}

.feature.infoBoxWrapper.center.capsuleSendIntroMessage > .content > .message,
.feature.infoBoxWrapper.center.capsuleSendIntroMessage > .content > .helpMessage{
  color:white;
}


[data-role=page] > .featuresCarousel, #NewLoginPage, #newSignupPage, #onboardingSignupPage,#trebbleSetup,#journeyCreation{
    position: fixed !important;
}
/*
.playlistDetailsPage > .ui-content{
    position: fixed !important;
    overflow: auto;
    -webkit-overflow-scrolling: touch;


    }*/

    #globalSearch > .ui-panel-wrapper{
        position: fixed !important;
        overflow: auto;
    }

    [data-role=page] > .ui-content,
    [data-role=page] > #contentWrapper,
    [data-role=popup] > .popupContent,
    [data-role=page] > .ui-panel-wrapper{

       height: calc(100% - 55px);
       position: fixed !important;
       overflow: auto;
       -webkit-overflow-scrolling: touch;
       width: 100%;
   }

   /* Ipod Touc Media query */
   @media only screen and (min-width: 240px) and (max-width: 320px)  {


      body {
        font-size: var(--text-sm)! important;
        line-height: 1.2 ! important;
    }


    body.browser > .artistDetailsPage.full * div#fullCoverArtImage,
    body.browser > .artistDetailsPage * div#fullCoverArtImage {
      /* background-position: inherit; */
      background-size: cover;
      width: 240px;
      height: 240px;
      border-radius: 50%;
      left: calc(50% - 120px);
  }

}
[data-role=page].createCapsulePage #textToConvertField::-webkit-input-placeholder,
[data-role=page].createCapsulePage #textToConvertByVoiceActorField::-webkit-input-placeholder,
[data-role=page].createCapsulePage #instructionsForVoiceActorField::-webkit-input-placeholder,
[data-role=page].createCapsulePage #titleField::-webkit-input-placeholder,
[data-role=page].createCapsulePage #comment::-webkit-input-placeholder{
    color: white;
}

body:not(.darkTheme) [data-role=page].createCapsulePage.ui-page:not(.lighttext) #textToConvertField::-webkit-input-placeholder,
body:not(.darkTheme) [data-role=page].createCapsulePage.ui-page:not(.lighttext) #textToConvertByVoiceActorField::-webkit-input-placeholder,
body:not(.darkTheme) [data-role=page].createCapsulePage.ui-page:not(.lighttext) #instructionsForVoiceActorField::-webkit-input-placeholder,
body:not(.darkTheme) [data-role=page].createCapsulePage.ui-page:not(.lighttext) #titleField::-webkit-input-placeholder,
body:not(.darkTheme) [data-role=page].createCapsulePage.ui-page:not(.lighttext) #comment::-webkit-input-placeholder,
body:not(.darkTheme) [data-role=page].createCapsulePage.ui-page:not(.lighttext) .capsuleCreationWrapper #noteSection .note_field_wrapper textarea::-webkit-input-placeholder{
    color: var(--color-gray-800);
}

body:not(.darkTheme) [data-role=page].createCapsulePage.ui-page:not(.lighttext) #textToConvertField,
body:not(.darkTheme) [data-role=page].createCapsulePage.ui-page:not(.lighttext) #textToConvertByVoiceActorField,
body:not(.darkTheme) [data-role=page].createCapsulePage.ui-page:not(.lighttext) #instructionsForVoiceActorField,
body:not(.darkTheme) [data-role=page].createCapsulePage.ui-page:not(.lighttext) #titleField,
body:not(.darkTheme) [data-role=page].createCapsulePage.ui-page:not(.lighttext) #comment,
body:not(.darkTheme) [data-role=page].createCapsulePage.ui-page:not(.lighttext) .capsuleCreationWrapper #noteSection .note_field_wrapper textarea{
    color: var(--text-dark);
    background-color: white;
}

[data-role=page].createCapsulePage[creationMode=fromFile],
[data-role=page].createCapsulePage[creationMode=fromText],
[data-role=page].createCapsulePage[creationMode=fromTranscript],
[data-role=page].createCapsulePage[creationMode=fromVoiceActor]{
    background: none;
    background-color: var(--color-black) !important;
}
[data-role=page].createCapsulePage{
   -webkit-transition: background-image .3s ease-in;
   -moz-transition: background-image .3s ease-in;
   -o-transition: background-image .3s ease-in;
   -ms-transition: background-image .3s ease-in;
   transition: background-image .3s ease-in;
}
body:not(.darkTheme) [data-role=page].createCapsulePage:not(.lighttext) .ui-content{
    background-color: var(--bg-light);
}

body:not(.darkTheme) [data-role=page].createCapsulePage:not(.lighttext){
    background-color: var(--bg-light);
    padding-top: 54px !important;
}

[data-role=page].createCapsulePage[creationMode=fromRecording].lighttext{
    /*background: -webkit-linear-gradient(-45deg, #fc466b, var(--trebble-blue)) var(--color-black);background: linear-gradient(-45deg, #fc466b, var(--trebble-blue)) var(--color-black);*/
    background-color: var(--color-black) !important;
    height: 100%;
}
body:not(.darkTheme) [data-role=page].createCapsulePage:not(.lighttext){
    background-color: var(--bg-light) !important;
}

.playlistDetailsPage #startRadioBtn.notplayable,
.playlistDetailsPage #startRadioBtn.notplayable:hover,
.playlistDetailsPage #startRadioBtn.notplayable:active,
.playlistDetailsPage .actionButtonWrapper.actionButton.trebble_disabled,
.playlistDetailsPage .actionButtonWrapper.actionButton.trebble_disabled:hover,
.playlistDetailsPage .actionButtonWrapper.actionButton.trebble_disabled:active {
    opacity:0.3;
    cursor:initial;
    background-color: var(--trebble-primary);
    border-color: var(--trebble-primary);
    border: var(--border-2) solid var(--trebble-primary);
    color: white;
}

.playlistDetailsPage #startRadioBtn.notplayable,
.playlistDetailsPage #startRadioBtn.notplayable:hover,
.playlistDetailsPage #startRadioBtn.notplayable:active{
  background-color: transparent;
  border-color: white;
}

.journeyPage.editable .journey_summary_detail .jouney_summary_detail_item.goal_info,
.playlistDetailsPage #pageHeaderTitleWrapper > .editable,
.playlistDetailsPage .playlistDescription.editable,
.userProfileDetailsPage  #pageHeaderTitleWrapper > .editable{
  cursor: pointer;
  overflow: visible;
}

.playlistDetailsPage .trebbleInfo .playlistDescription{
    max-width: 400px;
    margin: auto;
}

.playlistDetailsPage .description_box_wrapper .playlistDescription{
    max-width: 900px;
    margin: auto;
    padding: 10px 40px 40px 40px;
    box-sizing: border-box;
}


.journeyPage.editable .journey_summary_detail .jouney_summary_detail_item.goal_info:hover,
.playlistDetailsPage #pageHeaderTitleWrapper > .editable:hover,
.playlistDetailsPage .playlistDescription.editable:hover,
.playlistDetailsPage #pageHeaderTitleWrapper > .editable:active,
.playlistDetailsPage .playlistDescription.editable:active,
.userProfileDetailsPage #pageHeaderTitleWrapper > .editable:hover,
.userProfileDetailsPage #pageHeaderTitleWrapper > .editable:active {
   color: var(--color-white-70);
}

.journeyPage.editable .actionButtonWrapper.floatingBtn,
.journeyPage.playlistDetailsPage:not(.isCurrentlyPlaying):not(.isCurrentlyLoading):not(.publicPage) #startRadioBtn,
.journeyPage.playlistDetailsPage:not(.isCurrentlyPlaying):not(.isCurrentlyLoading):not(.publicPage) #loadingRadioBtn,
.journeyPage.playlistDetailsPage:not(.isCurrentlyPlaying):not(.isCurrentlyLoading):not(.publicPage) #pauseRadioBtn,
.journeyPage.playlistDetailsPage.publicPage:not(.isCurrentlyPlaying):not(.isCurrentlyLoading) #fullCoverArtImage #playbTrebbletn{
    display: none;
}

.journeyPage #albumList{
    margin-bottom: 70px;
}


textarea.materialize-textarea.invalid+label{
    width: 100%;
}

textarea.materialize-textarea.invalid+label::after{
    top: calc(100% - 12px);
}

.playlistDescription > div{
    overflow: hidden;
    text-overflow: ellipsis;
    /* autoprefixer: ignore next */
    -webkit-box-orient: vertical;
    display: -webkit-box;
    font-size: var(--text-sm-px);
    /*-webkit-line-clamp: 3;*/

}

.playlistDetailsPage .bioWrapper .owl-wrapper-outer{
    /*overflow: visible;*/
}

body.footerplayerdisplayed .albumDetailsPage  #albumList #albumSongListWrapper,
body.footerplayerdisplayed  #artistProfileWrapper,
body.footerplayerdisplayed .userProfileDetailsPage #userOverviewInfoWrapper,
body.footerplayerdisplayed .playlistDetailsPage #myCapsulesListWrapper,
body.footerplayerdisplayed .playlistDetailsPage #myArchivedCapsulesListWrapper,
body.footerplayerdisplayed .playlistDetailsPage #songListWrapper{
    margin-bottom: 60px;
}

body.white-theme .ui-page-theme-a.userProfileDetailsPage .ui-panel-wrapper{
    background-color: var(--color-black);
}

.userProfileDetailsPage .ui-panel-wrapper{
    overflow: hidden;
}

.playlistDetailsPage #trebblewarning{

    /*height: 50px;
    background-color: var(--color-purple-500);*/
    background-color: var(--color-black-20);
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);
    margin-top: 60px;
    display:none;
    -webkit-align-items: center;
    -ms-flex-align: center;
    -webkit-box-align: center;
    align-items: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-align-items: center;
    -webkit-justify-content: center;
    -ms-flex-line-pack: center;
    -moz-flex-line-pack: center;
    align-content: center;
    -webkit-align-content: center;

}

@supports not (backdrop-filter: blur(20px)) {
    .playlistDetailsPage #trebblewarning{
        background-color: var(--color-purple-500);
    }
}

.playlistDetailsPage #trebblewarning.show{
 display: -ms-flexbox;
 display: -moz-flexbox;
 display: -webkit-box;
 display: flex;
 display: -webkit-flex;
 display: -moz-flex;
 width: 100%;
 position: fixed;
 z-index: 1000;
}


.playlistDetailsPage:not(.publicPage) #trebblewarning.show  ~ #contentWrapper{
 margin-top: 60px;
 height: calc( 100% - 60px) !important;
}

.playlistDetailsPage #trebblewarning > #messageicon{
    color: white;
    font-size: var(--text-3xl-px);
    width: 50px;
    height: 50px;
    display: -ms-flexbox;
    display: -moz-flexbox;
    display: -webkit-box;
    display: flex;
    display: -webkit-flex;
    display: -moz-flex;
    -webkit-align-items: center;
    -ms-flex-align: center;
    -webkit-box-align: center;
    align-items: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-align-items: center;
    -webkit-justify-content: center;
    -ms-flex-line-pack: center;
    -moz-flex-line-pack: center;
    align-content: center;
    -webkit-align-content: center;
}

.playlistDetailsPage #trebblewarning > #message{

    color: white;
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    font-weight: bold;
    padding: 10px;
    overflow: hidden;

}

#whatsacapsuleinfobtn{
  border: var(--border-3) solid white;
  padding:8px;
  margin-right:10px;
  color:white;
  cursor:pointer;
  flex: 0 0 auto;
  display: -ms-flexbox;
  display: -moz-flexbox;
  display: -webkit-box;
  display: flex;
  display: -webkit-flex;
  display: -moz-flex;
}
#whatsacapsuleinfobtn > span{
  margin:0px 10px;
}

#whatsacapsuleinfobtn:hover,
#whatsacapsuleinfobtn:active{
  background-color:white;
  color:var(--color-purple-500);
}

@media( max-width: 580px) , (max-height: 580px)  and (orientation: landscape){
  #whatsacapsuleinfobtn{
    display: none; 
}
.playlistDetailsPage #trebblewarning > #message{
    font-size: var(--text-sm);
}
}
/*
.playlistDetailsPage{
  height: 100%;
  }*/
  #nointernetconnectionindicator{

    position: absolute;
    z-index: 999999;
    background-color: var(--color-red-800);
    width: 100%;
    color: white;
    text-align: center;
    padding: 2px;
    font-size: var(--text-xs-px);
    font-weight: bold;
    height: 24px;
    box-sizing: border-box;

}
#nointernetconnectionindicator ~ [data-role=page].ui-page-active ,
body[display_transcription_minutes_left_warning="true"][istemporaryuser="false"]:not([userprofiletype="listener"]) #warning_for_free_transcription_minutes_left ~ [data-role=page].ui-page-active,
#nointernetconnectionindicator ~ [data-role=page].ui-page-active > div.ui-header[data-role=header],
body[display_transcription_minutes_left_warning="true"][istemporaryuser="false"]:not([userprofiletype="listener"]) #warning_for_free_transcription_minutes_left ~ [data-role=page].ui-page-active > div.ui-header[data-role=header]{
    margin-top: 24px;
}

/*#nointernetconnectionindicator ~  .playlistDetailsPage #trebblewarning,
body[display_transcription_minutes_left_warning="true"][istemporaryuser="false"]:not([userprofiletype="listener"]) #warning_for_free_transcription_minutes_left ~  .playlistDetailsPage #trebblewarning,*/
#nointernetconnectionindicator ~   .playlistDetailsPage:not(.publicPage) #trebblewarning.show ~ #contentWrapper,
body[display_transcription_minutes_left_warning="true"][istemporaryuser="false"]:not([userprofiletype="listener"]) #warning_for_free_transcription_minutes_left ~  .playlistDetailsPage:not(.publicPage) #trebblewarning.show ~ #contentWrapper{
    margin-top: 84px;
}

#nointernetconnectionindicator ~   .playlistDetailsPage:not(.publicPage) #trebblewarning.show ~ #contentWrapper,
body[display_transcription_minutes_left_warning="true"][istemporaryuser="false"]:not([userprofiletype="listener"]) #warning_for_free_transcription_minutes_left ~  .playlistDetailsPage:not(.publicPage) #trebblewarning.show ~ #contentWrapper{
    height: calc(100% - 84px)!important;

}
#warning_for_free_transcription_minutes_left,
.warning_header_message{
    display: none;
}

body[display_transcription_minutes_left_warning="true"][istemporaryuser="false"]:not([userprofiletype="listener"]) #warning_for_free_transcription_minutes_left,
.audio_sequencer[show_switch_to_chrome_warning="true"] .warning_header_message{
    position: absolute;
    z-index: 1000;
    background: var(--gradient-primary-alt);
    width: 100%;
    color: white;
    text-align: center;
    padding: 2px;
    font-weight: bold;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    background-size: 200% 200%;
    animation: gradientanimation 30s ease infinite;
    -moz-animation: gradientanimation 30s ease infinite;
    animation: gradientanimation 30s ease infinite;
    height: 24px;
    box-sizing: border-box;
}
.audio_sequencer[show_switch_to_chrome_warning="true"] #warning_trebble_works_best_in_other_browsers.warning_header_message{
    position: relative;
    display: block;
    background: var(--warning-90);
    height: fit-content;
}

#warning_for_free_transcription_minutes_left label,
.warning_header_message label{
    color:  white;
    margin: 0px;
    font-size: var(--text-xs-px);
}
[data-role=page].transparent .warning_header_message label,
[data-role=page].transparent .warning_header_message a.action_link,
.warning_header_message label,
.warning_header_message  .warning_message{
    color: var(--color-black);
    font-size: 90%;
}


#warning_for_free_transcription_minutes_left .upgrade_message_link,
.warning_header_message .action_link{
 text-decoration: underline;
 margin-left: 4px;
 cursor: pointer;
}
#warning_for_free_transcription_minutes_left .warning_message,
#warning_for_free_transcription_minutes_left .upgrade_message_link,
.warning_header_message .warning_message,
.warning_header_message .action_link{
    display: inline-flex;
}
.warning_header_message a.action_link{
    padding: 0px;
}

#warning_for_free_transcription_minutes_left:not([show_link_to_upgrade=true]) .upgrade_message_link,
.warning_header_message:not([show_action_link=true]) .action_link{
    display: none;
}

.trebbleAudioPreviewLoader {
  height: 2px;
  width: 100%;
  position: relative;
  overflow: hidden;
  background-color: var(--color-gray-150);
}

.playlistItem.transparent .trebbleAudioPreviewLoader,
body.darkTheme .playlistItem .trebbleAudioPreviewLoader,
[data-role=page].darkTheme .playlistItem .trebbleAudioPreviewLoader{
    background-color: var(--color-white-40);
}
.trebbleAudioPreviewLoader:before{
  display: block;
  position: absolute;
  content: "";
  left: 0px;
  width: 0px;
  height: 2px;
  background-color:var(--trebble-primary);
  
}
.playlistItem.transparent .trebbleAudioPreviewLoader:before,
body.darkTheme .playlistItem .trebbleAudioPreviewLoader:before,
[data-role=page].darkTheme .playlistItem .trebbleAudioPreviewLoader:before{
    background-color:white;
}
.playlistItem  .trebbleAudioPreviewLoaderWrapper{
    display: none;
}
.playlistItem.previewLoading  .trebbleAudioPreviewLoaderWrapper {
    display: block;
    position: absolute;
    top: 0px;
    left: 0px;
    height: 2px;
    width: 100%;

}
.playlistItem.previewLoading  .trebbleAudioPreviewLoaderWrapper > .trebbleAudioPreviewLoader:before{
    animation: audioPreviewLoading 2s linear 1;
}

@keyframes audioPreviewLoading {
    from {width: 0%;}
    to {width: 100%;}
}
@-webkit-keyframes audioPreviewLoading {
    from {width: 0%;}
    to {width: 100%;}
}


.trebbleBarLoader {
  height: 4px;
  width: 100%;
  position: relative;
  overflow: hidden;
  background-color: var(--color-gray-150);
}
.trebbleBarLoader:before{
  display: block;
  position: absolute;
  content: "";
  left: -200px;
  width: 200px;
  height: 4px;
  background-color: var(--trebble-primary);
  animation: trebbleBarLoadingAnim 2s linear infinite;
}

@keyframes trebbleBarLoadingAnim {
    from {left: -200px; width: 30%;}
    50% {width: 30%;}
    70% {width: 70%;}
    80% { left: 50%;}
    95% {left: 120%;}
    to {left: 100%;}
}


.playlistDetailsPage:not(.editable).showCapsulesOnly #songListWrapper{
    display: none !important;
}
.playlistDetailsPage:not(.editable).showCapsulesOnly #myCapsulesListWrapper,
.playlistDetailsPage:not(.editable).showCapsulesOnly #myArchivedCapsulesListWrapper{
 display: -ms-flexbox !important;
 display: flex !important;
 display: -webkit-flex !important;
}

.playlistDetailsPage:not(.editable).showSongsOnly #myCapsulesListWrapper,
.playlistDetailsPage:not(.editable).showSongsOnly #myArchivedCapsulesListWrapper{
    display: none !important;
}

.playlistDetailsPage:not(.editable).songOrCapsuleLoading #myCapsulesListWrapper,
.playlistDetailsPage:not(.editable).songOrCapsuleLoading #myArchivedCapsulesListWrapper,
.playlistDetailsPage:not(.editable).songOrCapsuleLoading #songListWrapper{
    display: none !important;
}

.playlistDetailsPage:not(.editable).showSongsOnly #songListWrapper{
 display: -ms-flexbox !important;
 display: flex !important;
 display: -webkit-flex !important;
}

.playlistDetailsPage:not(.editable) #dataLoadingBlock{
    width: 100%;
    height: calc(100% - 60px - 60px);
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    -webkit-justify-content: center;
    -webkit-align-items: flex-start;
    -ms-justify-content: center;
    -ms-align-items: flex-start;
}
.playlistDetailsPage.editable #dataLoadingBlock,
.playlistDetailsPage:not(.editable).showSongsOnly #dataLoadingBlock,
.playlistDetailsPage:not(.editable).showCapsulesOnly #dataLoadingBlock{

    display: none !important;
}


body.white-theme div.ui-header[data-role=header] ~ .ui-panel-wrapper > .ui-content > [data-role=tabs] > [data-role=navbar] > ul > li > a,
body.white-theme div.ui-header[data-role=header] ~ .ui-panel-wrapper > .ui-content > [data-role=tabs] > [data-role=navbar]{
  background-color: var(--bg-light);
  border-color: var(--bg-light);
  box-shadow:none;
}

body.white-theme div.ui-header[data-role=header] ~ .ui-panel-wrapper > .ui-content > [data-role=tabs] > [data-role=navbar] > ul > li > a,
body.white-theme  div.ui-header[data-role=header] ~ .ui-panel-wrapper > .ui-content > [data-role=tabs] * [data-role=tabs] > [data-role=navbar] > ul > li > a{
  color:var(--text-dark);
}

body.white-theme div.ui-header[data-role=header] ~ .ui-panel-wrapper > .ui-content > [data-role=tabs] > [data-role=navbar] > ul > li > a.ui-btn-active,
body.white-theme div.ui-header[data-role=header] ~ .ui-panel-wrapper > .ui-content > [data-role=tabs] * [data-role=tabs] > [data-role=navbar] > ul > li > a.ui-btn-active{
  color:var(--trebble-primary-300);
  border-color: var(--trebble-primary-300);
}
#mySubscriptions  #playMyFeedButton,
#mySubscriptions #pauseMyFeedButton,
#mySubscriptions  #loadingMyFeedButton,
#mySubscriptions[play_button_in_on_your_feed_today_box_displayed=true] #playMyFeedFloatingBtnWrapper,
.listening_to_player_events  #playMyFeedButton,
.listening_to_player_events #pauseMyFeedButton,
.listening_to_player_events  #loadingMyFeedButton,
.listening_to_player_events[play_button_in_on_your_feed_today_box_displayed=true] #playMyFeedFloatingBtnWrapper,
div[data-role=page]:not(.editMode):not(.savingMode) div[data-role=header] .editPageTitle,
div[data-role=page].editMode div[data-role=header] .defaultPageTitle,
div[data-role=page].savingMode div[data-role=header] .defaultPageTitle,
div[data-role=page].editMode div[data-role=header] #homeHeaderButton,
div[data-role=page].savingMode div[data-role=header] #homeHeaderButton,
div[data-role=page]:not(.editMode) #cancelChangeButton,
div[data-role=page]:not(.savingMode) #savingChangesLabel,
div[data-role=page]:not(.editMode) #saveChangeButton,
div[data-role=page]:not(.editMode):not(.savingMode):not(.alwaysInEditMode) .changeSubscriptionOrderInstructionBox,
div[data-role=page].editMode #playMyFeedButton,
div[data-role=page].editMode .listeningInstructionWrapper,
div[data-role=page].editMode #changeOrderButton,
div[data-role=page].editMode #addTrebbleToSubscriptionsBtn,
div[data-role=page].editMode #homeHeaderButton,
div[data-role=page].editMode .listenOnVoiceEnabledDeviceBoxWrapper,
div[data-role=page].editMode .showUserRefferalPageBoxWrapper,
div[data-role=page].editMode .upgradeToTrebblePaidSubscriptionBoxWrapper,
div[data-role=page].editMode #playMyFeedFloatingBtnWrapper,
div[data-role=page].editMode #play_feed_btn,
div[data-role=page].editMode #onYourFeedTodayBoxWrapper,
div[data-role=page].savingMode .listenOnVoiceEnabledDeviceBoxWrapper,
div[data-role=page].savingMode .showUserRefferalPageBoxWrapper,
div[data-role=page].savingMode .upgradeToTrebblePaidSubscriptionBoxWrapper,
div[data-role=page].savingMode #playMyFeedFloatingBtnWrapper,
div[data-role=page].savingMode #play_feed_btn,
div[data-role=page].savingMode #onYourFeedTodayBoxWrapper,
div[data-role=page].savingMode #homeHeaderButton,
div[data-role=page].savingMode .listeningInstructionWrapper,
div[data-role=page].editMode .addShortcutToPlayCapsuleFeedSuggestionBoxWrapper,
div[data-role=page].savingMode .addShortcutToPlayCapsuleFeedSuggestionBoxWrapper,
div[data-role=page].editMode .enableDisableDailyNotificationBox,
div[data-role=page].savingMode .enableDisableDailyNotificationBox,
div[data-role=page].editMode .shortcutPhraseToPlayCapsuleFeedBoxWrapper,
div[data-role=page].savingMode .shortcutPhraseToPlayCapsuleFeedBoxWrapper,
div[data-role=page].savingMode #playMyFeedButton,
div[data-role=page].alwaysInEditMode #changeOrderButton,
div[data-role=page].savingMode #changeOrderButton,
div[data-role=page].savingMode #addTrebbleToSubscriptionsBtn{
    display: none !important;
}

#subscriptionList{
    height: calc(100vh - 55px);
    box-sizing: border-box;
}

body.footerplayerdisplayed #subscriptionList{
    height: calc(100vh - 115px);
    box-sizing: border-box;
}


div[data-role=page].savingMode #savingChangesLabel,
div[data-role=page].editMode #cancelChangeButton,
div[data-role=page].editMode #saveChangeButton{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px;
    padding: 0px 15px;
}

[data-role=page].no_subscription #changeOrderButton,
[data-role=page] .listeningInstructionWrapper,
[data-role=page].loading_subscriptions .listeningInstructionWrapper,
[data-role=page].no_subscription .listeningInstructionWrapper,
[data-role=page].loading_subscriptions .addShortcutToPlayCapsuleFeedSuggestionBoxWrapper,
[data-role=page].no_subscription .addShortcutToPlayCapsuleFeedSuggestionBoxWrapper,
[data-role=page].loading_subscriptions .enableDisableDailyNotificationBox,
[data-role=page].no_subscription .enableDisableDailyNotificationBox,
[data-role=page].loading_subscriptions .shortcutPhraseToPlayCapsuleFeedBoxWrapper,
[data-role=page].no_subscription .shortcutPhraseToPlayCapsuleFeedBoxWrapper,
[data-role=page].no_subscription .addToSiriBtn.fixed,
[data-role=page].loading_subscriptions .addToSiriBtn.fixed,
[data-role=page].no_subscription .listenOnVoiceEnabledDeviceBoxWrapper,
[data-role=page].loading_subscriptions .showUserRefferalPageBoxWrapper,
[data-role=page].loading_subscriptions .upgradeToTrebblePaidSubscriptionBoxWrapper,
[data-role=page].no_subscription .listenOnVoiceEnabledDeviceBoxWrapper,
[data-role=page].loading_subscriptions .showUserRefferalPageBoxWrapper,
[data-role=page].loading_subscriptions .upgradeToTrebblePaidSubscriptionBoxWrapper,
[data-role=page].no_subscription #onYourFeedTodayBoxWrapper,
[data-role=page].loading_subscriptions #onYourFeedTodayBoxWrapper,
[data-role=page].no_subscription .upgradeToTrebblePaidSubscriptionBoxWrapper,
[data-role=page].loading_subscriptions .upgradeToTrebblePaidSubscriptionBoxWrapper{
    display: none;
}


body.white-theme div[data-role=page]:not(.lighttext):not(#featurePage):not(#ExplorePage):not(#Onboarding):not(#home)  div.ui-header[data-role=header],
body.white-theme #myLibraryHeader,
body.white-theme #mySubscriptionsHeader,
body.white-theme #myShortcatsHeader,
body.white-theme  #globalSearchPageHeader{
  background-color: var(--bg-light);
  border-color: var(--bg-light);
  color:var(--text-dark);
}

body.white-theme.browser div[data-role=page]:not(.lighttext):not(#FeedAndActivityPage):not(#featurePage)/*:not(#mylibrary)*/:not(#ExplorePage):not(#Onboarding):not(#player):not(#audioEditorDemoPage):not(#audioEditorDemoPage):not(.createCapsulePage)  div.ui-header[data-role=header]{
    box-shadow: 2px 0 20px 0 var(--gray-light-30);
}

body.white-theme #globalSearch * .ui-content{
  box-shadow:none !important;
}
body.darkTheme #pageHeaderTitleWrapper,
[data-role=page].darkTheme #pageHeaderTitleWrapper,
body.darkTheme   #homeHeaderButton,
body.darkTheme   [data-role=header] .ui-btn,
body  [data-role=page].darkTheme #homeHeaderButton,
body  [data-role=page].darkTheme [data-role=header] .ui-btn,
body.white-theme  #ExplorePage:not([showListView=true]) #homeHeaderButton,
body.white-theme  #ExplorePage:not([showListView=true]) [data-role=header] .ui-btn,
body.white-theme  #Onboarding:not([showListView=true]) #homeHeaderButton,
body.white-theme  #Onboarding:not([showListView=true]) [data-role=header] .ui-btn{
    color: white;
}

body.darkTheme .addShortcutToPlayCapsuleFeedSuggestionBoxWrapper,
body.darkTheme .listenOnVoiceEnabledDeviceBoxWrapper,
body.darkTheme .showUserRefferalPageBoxWrapper,
body.darkTheme .upgradeToTrebblePaidSubscriptionBoxWrapper,
body.darkTheme .shortcutPhraseToPlayCapsuleFeedBoxWrapper,
body.darkTheme .changeSubscriptionOrderInstructionBox,
body.darkTheme .listeningInstructionWrapper{
    background-color: var(--color-white-20);
}

body.darkTheme #mySubscriptions .playlistItem.withLargeAlbumArt.smallcardsize:before,
body.darkTheme #myJourneys .playlistItem.withLargeAlbumArt.smallcardsize:before{
    background-color: var(--color-gray-850);
    color:  var(--trebble-primary);
}

body.darkTheme .settingSection .settingItemTitle,
body.darkTheme .settingSection .settingItemTitle .itemTitle{
    color: white;
}

#Onboarding #pageHeaderTitleWrapper{
    padding-left: 51px !important;
    width: 100%;
    text-align: center;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

body.white-theme #mylibrary > div[data-role=header] * .ui-btn, 
body.white-theme #mySubscriptions > div[data-role=header] * .ui-btn, 
body.white-theme #myJourneys > div[data-role=header] * .ui-btn, 
body.white-theme #home > div[data-role=header] * .ui-btn,
html body.white-theme .ui-page-theme-c div[data-role=header] * .ui-btn,
body.white-theme #globalSearch > div[data-role=header] * .ui-btn{
  color:var(--text-dark);
}

body.white-theme #home[state=no_new_content] > div[data-role=header] * .ui-btn,
body.white-theme #home[state=error] > div[data-role=header] * .ui-btn{
    color: white;
}

body.white-theme.darkTheme #mylibrary > div[data-role=header] * .ui-btn, 
body.white-theme.darkTheme #mySubscriptions > div[data-role=header] * .ui-btn, 
body.white-theme.darkTheme #myJourneys > div[data-role=header] * .ui-btn, 
body.white-theme.darkTheme #home > div[data-role=header] * .ui-btn,
html body.white-theme.darkTheme .ui-page-theme-c div[data-role=header] * .ui-btn,
body.white-theme.darkTheme #globalSearch > div[data-role=header] * .ui-btn,
html body.white-theme .ui-page-theme-c.darkTheme div[data-role=header] * .ui-btn{
  color: white;
}

body.white-theme.mobileweb #mylibrary > div[data-role=header] * .ui-btn:hover, 
body.white-theme.mobileweb #mySubscriptions > div[data-role=header] * .ui-btn:hover, 
body.white-theme.mobileweb #myJourneys > div[data-role=header] * .ui-btn:hover, 
body.white-theme.mobileweb #home > div[data-role=header] * .ui-btn:hover,
html body.white-theme.mobileweb .ui-page-theme-c div[data-role=header] * .ui-btn:hover,
body.white-theme.mobileweb #globalSearch > div[data-role=header] * .ui-btn:hover,
body.white-theme.mobileweb div[data-role=page]:not(.lighttext):not(#player) #header-back-button:hover,
body.white-theme.mobileweb div[data-role=page]:not(.lighttext):not(#player) #header-queue-button:hover,
body.white-theme.mobileweb div[data-role=page]:not(.lighttext) [data-role=header] * #editButton:hover,
body.white-theme.mobileweb div[data-role=page]:not(.lighttext):not(#player) [data-role=header] * #menuOptionButton:hover,
body.white-theme.mobileweb div[data-role=page]:not(.lighttext)  [data-role=header] * #filterButton:hover,
body.white-theme.mobileweb div[data-role=page]:not(.lighttext)   [data-role=header] * #deleteButton:hover,
body.white-theme.mobileweb div[data-role=page]:not(.lighttext)    [data-role=header] * #saveButtonIcon:hover,
body.white-theme.mobileweb div[data-role=page]:not(.lighttext)     [data-role=header] * #showRecordSectionButton.tag.actionButton:hover,
body.white-theme.mobileweb div[data-role=page]:not(.lighttext)      [data-role=header] * #nextButton.tag.actionButton:hover,
body.white-theme.mobileweb div[data-role=page]:not(.lighttext)      [data-role=header] * #showUploadSectionButton.tag.actionButton:hover,
body.white-theme #mylibrary > div[data-role=header] * .ui-btn:active,
body.white-theme #mySubscriptions > div[data-role=header] * .ui-btn:active,
body.white-theme #myJourneys > div[data-role=header] * .ui-btn:active,
body.white-theme #home > div[data-role=header] * .ui-btn:active,
html body.white-theme .ui-page-theme-c div[data-role=header] * .ui-btn:active,
body.white-theme #globalSearch > div[data-role=header] * .ui-btn:active,
body.white-theme div[data-role=page]:not(.lighttext):not(#player) #header-back-button:active,
body.white-theme div[data-role=page]:not(.lighttext):not(#player) #header-queue-button:active,
body.white-theme div[data-role=page]:not(.lighttext) [data-role=header] * #editButton:active,
body.white-theme div[data-role=page]:not(.lighttext):not(#player) [data-role=header] * #menuOptionButton:active,
body.white-theme div[data-role=page]:not(.lighttext)  [data-role=header] * #filterButton:active,
body.white-theme div[data-role=page]:not(.lighttext)   [data-role=header] * #deleteButton:active,
body.white-theme div[data-role=page]:not(.lighttext)    [data-role=header] * #saveButtonIcon:active,
body.white-theme div[data-role=page]:not(.lighttext)     [data-role=header] * #showRecordSectionButton.tag.actionButton:active,
body.white-theme div[data-role=page]:not(.lighttext)      [data-role=header] * #nextButton.tag.actionButton:active,
body.white-theme div[data-role=page]:not(.lighttext)      [data-role=header] * #showUploadSectionButton.tag.actionButton:active{
  color:var(--trebble-primary) !important;
  background-color: transparent;

}
body.white-theme div[data-role=page]:not(.lighttext):not(#player) #header-back-button,
body.white-theme div[data-role=page]:not(.lighttext):not(#player) #header-queue-button,
body.white-theme div[data-role=page]:not(.lighttext) [data-role=header] * #editButton,
body.white-theme div[data-role=page]:not(.lighttext):not(#player) [data-role=header] * #menuOptionButton,
body.white-theme div[data-role=page]:not(.lighttext)  [data-role=header] * #filterButton,
body.white-theme div[data-role=page]:not(.lighttext)   [data-role=header] * #deleteButton,
body.white-theme div[data-role=page]:not(.lighttext)    [data-role=header] * #saveButtonIcon,
body.white-theme div[data-role=page]:not(.lighttext)    [data-role=header] * #loadingIndicatorBtn,
body.white-theme div[data-role=page]:not(.lighttext)    [data-role=header] * #loadingIndicatorBtn:hover,
body.white-theme div[data-role=page]:not(.lighttext)    [data-role=header] * #loadingIndicatorBtn:active,
body.white-theme div[data-role=page]:not(.lighttext)     [data-role=header] * #showRecordSectionButton.tag.actionButton,
body.white-theme div[data-role=page]:not(.lighttext)      [data-role=header] * #nextButton.tag.actionButton,
body.white-theme div[data-role=page]:not(.lighttext)      [data-role=header] * #showUploadSectionButton.tag.actionButton {
  color: var(--text-dark);
}
body.white-theme #moreSubTabs > div[role=tabpanel], 
body.white-theme #mylibrary #capsuleTabs > div[role=tabpanel]{
  box-shadow:none;
}

body.white-theme  #songList,
body.white-theme #albumList,
body.white-theme #artistList,
body.white-theme #moreList,
/*body.white-theme #capsuleList,*/
body.white-theme #myFeedList,
body.white-theme #myNotificationsList{
    box-shadow:inset 2px 0 20px 0 var(--gray-light-30);
}

body.white-theme #mylibrary > div.ui-header[data-role=header] ~ .ui-panel-wrapper > .ui-content * [data-role=tabs] > [data-role=navbar] > ul > li > a{
  padding: 0px;
  letter-spacing: 1px;
  border-bottom-width: var(--border-1);
  padding-bottom: 2px;
  border-color: transparent;
}
body.white-theme #mylibrary > div.ui-header[data-role=header] ~ .ui-panel-wrapper > .ui-content * [data-role=tabs] > [data-role=navbar] > ul > li > a.ui-btn-active{
    border-color: var(--trebble-primary-300);
}

body.white-theme.browser.mobileweb * div.ui-header[data-role=header] ~ .ui-panel-wrapper > .ui-content > [data-role=tabs] > [data-role=navbar] > ul {
  padding:12px;
}
body.white-theme.browser.mobileweb * div.ui-header[data-role=header] ~ .ui-panel-wrapper > .ui-content > [data-role=tabs] > [data-role=navbar] > ul > li {
  padding: 0px 20px;
  box-sizing: border-box;
}
body.white-theme.browser.mobileweb div.ui-header[data-role=header] ~ .ui-panel-wrapper > .ui-content > [data-role=tabs] * [data-role=tabs] > [data-role=navbar] > ul > li  > a{
   padding:0px;
   letter-spacing: 1px;
   border-bottom-width: var(--border-1);
   padding-bottom: 2px;
}

body.white-theme div.ui-header[data-role=header] ~ .ui-panel-wrapper > .ui-content > [data-role=tabs] * [data-role=tabs] > [data-role=navbar] > ul > li {

    padding: 8px 20px;
}

html body.white-theme .ui-overlay-a,
html body.white-theme .ui-page-theme-a,
html body.white-theme .ui-page-theme-a .ui-panel-wrapper{
  background-color:  var(--bg-light);
}


body.white-theme  div.ui-header[data-role=header] ~ .ui-panel-wrapper > .ui-content > [data-role=tabs] > [data-role=navbar] > ul > li > a{

  width: fit-content;
  padding: 0px;
  letter-spacing: 1px;
  border-bottom-width: var(--border-1);
  padding-bottom: 2px;
}

@media (max-width: 450px) {
  body.white-theme #mylibrary [role=tablist] > li,
  body.white-theme #mySubscriptions [role=tablist] > li,
  body.white-theme #myJourneys [role=tablist] > li {
    width: 50%;
    
    -ms-flex-line-pack: center;
    -moz-flex-line-pack: center;
    align-content: center;
    -webkit-align-content: center;
    -ms-flex-direction: row;
    -moz-flex-direction: row;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-direction: row;
    -webkit-flex-direction: row;
    display: -ms-flexbox;
    display: -moz-flexbox;
    display: -webkit-box;
    display: flex;
    display: -webkit-flex;
    display: -moz-flex;

    -webkit-align-items: center;
    -ms-flex-align: center;
    -webkit-box-align: center;
    align-items: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-align-items: center;
    -webkit-justify-content: center;
    padding: 8px 20px
}

body.white-theme #mylibrary #capsuleList  [role=tablist] > li {
    width: 25%;
    -ms-flex-line-pack: center;
    -moz-flex-line-pack: center;
    align-content: center;
    -webkit-align-content: center;
    -ms-flex-direction: row;
    -moz-flex-direction: row;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-direction: row;
    -webkit-flex-direction: row;
    display: -ms-flexbox;
    display: -moz-flexbox;
    display: -webkit-box;
    display: flex;
    display: -webkit-flex;
    display: -moz-flex;

    -webkit-align-items: center;
    -ms-flex-align: center;
    -webkit-box-align: center;
    align-items: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-align-items: center;
    -webkit-justify-content: center;
    padding: 8px 8px
}

/*body.white-theme #mylibrary.showScheduleCapsules #capsuleList  [role=tablist] > li{
    width: 20%;
    font-size: 80%;
    padding: 8px 2px;
    }*/
    body.white-theme #mylibrary.showScheduleCapsules #capsuleList ul{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        overflow: scroll;
    }

    body.white-theme #mylibrary.showScheduleCapsules #capsuleList [role=tablist]>li {
        width: auto;
        font-size: 80%;
        padding: 8px 6px;
    }
}


body.white-theme #mylibrary:not(.showScheduleCapsules) #capsuleList li[aria-labelledby=myScheduledCapsulesTab]{
  display:none;
}



body.white-theme .tabheader > div{
  border:var(--border-0);
  padding: 0px 12px;
  letter-spacing: 1px;
}

body.white-theme .tabheader > div.selected > span{
  border-bottom: var(--border-1) solid;
  padding-bottom: 3px;
}



body.white-theme input.customSearchField[type=search],
body.white-theme input.shareableLink {
  border-radius: 2px;
  font-weight: 400;
  color: var(--text-dark);
  /*color: var(--text-muted);*/
  border: var(--border-0);
  padding: 0px;
  background-color: transparent;
  /* background-color: white; */
  border-bottom: var(--border-1) solid var(--color-black-5)!important;
  /* border: var(--border-1) solid var(--trebble-primary-dark); */
  font-size: var(--text-base);
}
body.white-theme [data-role=popup] * input.customSearchField[type=search],body.white-theme [data-role=popup] * input.shareableLink{
    background-color:var(--color-white-20);
    color:var(--text-light);
    font-size: var(--text-3xl-px);
}


body.white-theme [data-role=popup] * input.shareableLink{
    font-size: var(--text-base-px);
}

body.white-theme [data-role=popup] * input.customSearchField[type=search]:focus,body.white-theme [data-role=popup] * input.shareableLink:focus{
    background-color:var(--color-white-20);
    color:var(--text-light)
}

body.white-theme [data-role=popup] * input.customSearchField[type=search],
body.white-theme [data-role=popup] * input.shareableLink {
  background-color: var(--color-white-20);
  color: var(--color-white-60);
}

body.white-theme [data-role=popup] * input.customSearchField[type=search]:focus,
body.white-theme [data-role=popup] * input.shareableLink:focus {

  background-color: var(--color-white-20);
}

body.white-theme input.customSearchField[type=search]:focus,
body.white-theme input.shareableLink:focus {
  background-color: transparent;
  border-bottom: var(--border-1) solid var(--color-red-600) !important;
  color: var(--text-dark);
}

body.white-theme.browser.mobileweb * div.ui-header[data-role=header] ~ .ui-panel-wrapper > .ui-content > [data-role=tabs] > [data-role=navbar] > ul > li > a:hover,
body.white-theme.browser.mobileweb div.ui-header[data-role=header] ~ .ui-panel-wrapper > .ui-content > [data-role=tabs] * [data-role=tabs] > [data-role=navbar] > ul > li > a:hover,
body.white-theme * div.ui-header[data-role=header] ~ .ui-panel-wrapper > .ui-content > [data-role=tabs] > [data-role=navbar] > ul > li > a:active,
body.white-theme div.ui-header[data-role=header] ~ .ui-panel-wrapper > .ui-content > [data-role=tabs] * [data-role=tabs] > [data-role=navbar] > ul > li > a:active {
  color: var(--trebble-primary-300) !important;
}
body.white-theme.browser.mobilebrowser div.ui-header[data-role=header] ~ .ui-panel-wrapper > .ui-content > [data-role=tabs] > [data-role=navbar] > ul > li > a ,
body.white-theme.browser.mobilebrowser  div.ui-header[data-role=header] ~ .ui-panel-wrapper > .ui-content > [data-role=tabs] * [data-role=tabs] > [data-role=navbar] > ul > li > a {
  font-weight: 600;
  margin: auto;
}

html body.white-theme.browser  [data-role=page]:not(.lighttext) .capsuleCard {
    -webkit-box-shadow: 2px 0 12px 0 var(--shadow-color-light);
    box-shadow: 2px 0 12px 0 var(--shadow-color-light);
    /*-webkit-transition: -webkit-box-shadow .25s ease-in-out;
    transition: -webkit-box-shadow .25s ease-in-out;
    transition: box-shadow .25s ease-in-out;
    transition: box-shadow .25s ease-in-out,-webkit-box-shadow .25s ease-in-out;*/
    border: var(--border-0);
}
.outline_item{
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 90px;
    align-items: center;
    flex: 1 1 auto;
    border-bottom: var(--border-1) solid var(--color-black-5);

}


.outline_item .day_number_info{
    flex: 0 0 auto;
    width: 75px;
    opacity: 0.5;
}

.outline_item .day_number_info .day_label{
    font-size: 80%;
    text-transform: uppercase;
}

.outline_item .day_number_info .day_number{    
    font-size: var(--text-2xl-px);
    font-weight: bold;
    padding: 10px 0px;

}
.outline_item .buttonGroup{
    flex: 0 0 auto;
}
.outline_item .outline_info{

    flex: 1 1 auto;
    text-align: left;
    height: 100%;
    align-items: flex-start;
    justify-content: center;
    display: flex;
    flex-direction: column;
    overflow: hidden;

}
.outline_item .outline_info .outline_title{
    font-size: var(--text-base-px);
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    /* autoprefixer: ignore next */
    -webkit-box-orient: vertical;
}
.outline_item .outline_info .outline_detail{
    opacity: .5;
    font-size: 90%;
    width: 100%;
    display: block;
    white-space: nowrap;
    margin: 4px 0px;
    overflow: hidden;
    text-overflow: ellipsis;


}

.outline_item:not(.editable) .removeFromOutlineBtn,
.outline_item:not(.locked) .locked_btn,
.outline_item.locked .playback_completed_btn_wrapper,
.outline_item.locked .play_btn,
.outline_item.locked .disabled_play_btn,
.outline_item.locked .moreOptionBtn,
.outline_item.hide_day_info .day_number_info,
.outline_item.hide_more_button .moreOptionBtn,
.outline_item:not(.allowedToPlay) .moreOptionBtn,
.outline_item.allowedToPlay .disabled_play_btn,
.outline_item.editable .disabled_play_btn,
.outline_item.completed .disabled_play_btn,
.outline_item:not(.allowedToPlay) .play_btn,
.outline_item.locked .moreOptionButtonWrapper,
.outline_item:not(.editable) .moreOptionButtonWrapper,
.outline_item:not(.completed) .playback_completed_btn_wrapper,
.outline_item.isPlaying .playback_completed_btn_wrapper,
.outline_item.completed:not(.isPlaying) .play_btn{
    display: none;
} 
.outline_item .buttonGroup .playback_completed_btn_wrapper #playback_completed_btn,
.outline_item .buttonGroup .play_btn #playCapsuleBtn,
.outline_item .buttonGroup .play_btn #removeFromOutlineBtn,
.outline_item .disabled_play_btn i{

    height: 36px;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    width: 36px;
    display: flex;
    flex: 0 0 36px;
    font-size: var(--text-base-px);
    top: auto;
    border-radius: 50%;
    border: var(--border-2) solid;
    cursor: pointer;

}

.outline_item .disabled_play_btn i{
    cursor: default;
    opacity: 0.5;
}
.outline_item .buttonGroup #moreOptionButtonWrapper{
    cursor: pointer;
    width: 40px;
    height: 40px;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    display: flex;

}

.outline_item .drag_btn_wrapper{
    height: 40px;
    cursor: pointer;
}

body.browser.mobileweb .outline_item .drag_btn_wrapper:hover,
.outline_item .drag_btn_wrapper:active,
body.browser.mobileweb .outline_item .buttonGroup #moreOptionButtonWrapper:hover,
.outline_item .buttonGroup #moreOptionButtonWrapper:active{
    background-color: var(--color-white-10);
}

.outline_item .buttonGroup .playback_completed_btn_wrapper #playback_completed_btn{
    background-color: var(--color-green-400);
    border-color: var(--color-green-400);
}

.outline_item .buttonGroup .play_btn #playCapsuleBtn{
    background-color:  var(--trebble-primary);
    border-color:  var(--trebble-primary);

}

.outline_item .buttonGroup .play_btn #playCapsuleBtn i.ion-ios7-play{
    padding-left: 4px;
}

.outline_item .buttonGroup .play_btn #playCapsuleBtn i.fontello-icon-stop{
 padding-left: 2px;   
}

.outline_item .buttonGroup .play_btn #playCapsuleBtn  i.ion-ios7-gear.animate-spin {
    width: 17px;
    height: 18px;
}

.outline_item .buttonGroup{
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
}

@media(max-width: 500px), (max-height: 500px) and (orientation: landscape){
    .outline_item{
        height: 110px;
    }

    .outline_item .buttonGroup{
        flex-direction: column;
    }

    .outline_item .outline_info .outline_title{
        -webkit-line-clamp: 3;
    }
}


.outline_item .buttonGroup div{
    top: auto;
    flex: 0 0 50px;
    height: 50px;
    width: 50px;
    justify-content: center;
    align-items: center;
    display: flex;
    margin: 0px 5px;
}
.outline_item .buttonGroup .locked_btn i,
.outline_item .buttonGroup .moreOptionBtn i{
    font-size: var(--text-2xl-px);
}

.outline_item .buttonGroup .locked_btn i,
.outline_item .buttonGroup .moreOptionBtn i{
    /*-webkit-text-stroke-width: medium;*/
}

html   [data-role=page].lighttext .outline_item{
    color: white;
    border-bottom: var(--border-1) solid var(--color-white-10);
}

body.white-theme :focus{
  outline:none;
}

.playlistDetailsPage #playPreviewBtn {
    display: none;
}

html body.white-theme.browser:not(.darkTheme)   [data-role=page]:not(.lighttext) .capsuleCard:hover {
    -webkit-box-shadow: 1px -1px 10px 6px var(--gray-light-15);
    box-shadow: 1px -1px 10px 6px var(--gray-light-15);
    border: var(--border-0);
}


.homePanel * .settingMenuBox{
  /*visibility: hidden;*/
}


#fabAddButtonContainer{
   /* display: none !important;*/
}

#exploreBtn.panelMenuItem{
    /*display: none !important;*/
}

#mySubscriptionsBtn.panelMenuItem{
   /*display: none !important;*/
}

.upgrade_creator_subscription_widget {
    padding: 20px;
    box-sizing: border-box;
    background-color: white;
    width: calc(100vw - 100px);
    min-height: 600px;
    display: flex;
}
.upgrade_creator_subscription_widget.show_gradient_background{
    background: url("./images/gradient_02_a.jpg");
    background-size: cover;
    background-position-x: center;
    background-position-y: top;
}

.upgrade_creator_subscription_widget.show_gradient_background .creator_plans_listing_container #all_creator_plans_container .creator_plan_box{
    background-color: white;
}

.upgrade_creator_subscription_widget.show_gradient_background .creator_plans_listing_container #upgrade_ad_message_box .header_message{
    color: var(--color-black);
    -webkit-text-fill-color: var(--color-black);
}

body[appname=trebble] .upgrade_creator_subscription_widget.show_gradient_background  .switch label input[type=checkbox]:checked+.lever:after{
    background-color: var(--color-black);
}

body[appname=trebble]   .upgrade_creator_subscription_widget.show_gradient_background  .switch label input[type=checkbox]:checked+.lever{
    background-color: var(--color-gray-150);
}

.upgrade_creator_subscription_widget.show_gradient_background .creator_plans_listing_container #upgrade_ad_message_box  .choose_monthly_or_yearly{
    font-weight: bold;
}

.upgrade_creator_subscription_widget[is_content_loading="true"]{
    display: flex;
    justify-content: center;
    align-items: center;
}

.upgrade_creator_subscription_widget .creator_plans_listing_container,
.upgrade_creator_subscription_widget .payment_section .payment_section_content{
    color: var(--color-black);
    display: flex;
    flex-direction: row;
    overflow: auto;
    margin: auto;
    width: 100%;
    /* align-items: center;*/
}

.upgrade_creator_subscription_widget .payment_section,
.upgrade_creator_subscription_widget .changePaymentMethodSection{
    justify-content: center;
    width: 100%;
    color: var(--color-black);
    display: flex;
    flex-direction: column;
    overflow: auto;
    
}


.creator_plans_listing_container #upgrade_ad_message_box{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 330px;
    padding: 20px;
    box-sizing: border-box;
    text-align: start;
    flex: 0 0 100%;
}

.creator_plans_listing_container #upgrade_ad_message_box  .header_message,
.payment_section  .header_section {
    font-weight: 900;
    color: var(--color-black);
    font-size: 140%;
    margin: 0px;
    padding: 0px;  
}

.payment_section  .header_section {
    display: flex;    
}

.creator_plans_listing_container #upgrade_ad_message_box  .header_message{
    display: block;
}

.creator_plans_listing_container #upgrade_ad_message_box  .header_message{
    font-size: 200%;
    background: var(--gradient-primary-alt);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    background-size: 150% 150%;
    animation: gradientanimation 5s ease infinite;
    animation: gradientanimation 5s ease infinite;
}

.creator_plans_listing_container #upgrade_ad_message_box  .header_message,
.creator_plans_listing_container #upgrade_ad_message_box  .descrpition_message{
    margin-bottom: 20px;
}

.creator_plans_listing_container #upgrade_ad_message_box  .choose_monthly_or_yearly{

    display: flex;
    flex-direction: row;

}

.creator_plans_listing_container #all_creator_plans_container{

    display: flex;
    flex-direction: row;

}

.creator_plans_listing_container #all_creator_plans_container .creator_plan_box{

    padding: 20px;
    padding-top: 40px;
    box-sizing: border-box;
    border: var(--border-4) solid var(--color-black);
    margin: 10px;
    flex-direction: column;
    display: flex;
    text-align: start;
    width: 300px;
    position: relative;
}

.creator_plans_listing_container #all_creator_plans_container .creator_plan_box .plan_name{
 font-size: 130%;
 font-weight: bold;

}
.creator_plans_listing_container #all_creator_plans_container .creator_plan_box .buy_subscription_button,
.payment_section .complete_subscription_payment_button,
.payment_section .confirm_change_plan_btn,
.update_payment_method_widget .updatePaymentMethodBtn, 
.update_payment_method_widget .cancelBtn{
    margin: 20px 0px;
    background-color: var(--color-black);
    color: white;
    font-weight: bold;
    outline: none;
    border: var(--border-3) solid var(--color-black);
    height: 40px;
    padding: 0px;
    cursor: pointer;

}
.payment_section .complete_subscription_payment_button,
.payment_section .confirm_change_plan_btn{
    width: 100%;
    max-width: 300px;
}
.update_payment_method_widget .updatePaymentMethodBtn, 
.update_payment_method_widget .cancelBtn{
    padding: 0px 20px;
}
.creator_plans_listing_container #all_creator_plans_container .creator_plan_box .buy_subscription_button:hover,
.payment_section .complete_subscription_payment_button:hover,
.payment_section .complete_subscription_payment_button:hover,
.payment_section .confirm_change_plan_btn:hover,
.update_payment_method_widget .updatePaymentMethodBtn:hover,
.update_payment_method_widget .cancelBtn:hover{
    color:  var(--color-black);
    background-color:transparent;
}

@media(max-width: 1200px){
    .upgrade_creator_subscription_widget .creator_plans_listing_container,
    .upgrade_creator_subscription_widget .payment_section .payment_section_content,
    .upgrade_creator_subscription_widget .creator_plans_listing_container #all_creator_plans_container{
        flex-direction: column;
        align-items: center;
    }

    div[data-role=popup].upgradeCreatorSubscriptionPopup > a#closeBtn{
        color:  var(--color-black);
    }

    .replacePaymentForCreatorSubscriptionPopup .popupContent {
        padding: 80px;
        box-sizing: border-box
    }

    #songPoppupMenu.replacePaymentForCreatorSubscriptionPopup .update_payment_method_widget{
        max-width: 400px;
    }

    .upgrade_creator_subscription_widget{
        overflow: auto;
        min-height: auto;
        font-size: 90%;
        width: 100vw;
    }

    .creator_plans_listing_container #all_creator_plans_container .creator_plan_box{
        width: 100%;
        padding: 20px 10px 10px;
    }

    .creator_plans_listing_container #all_creator_plans_container .creator_plan_box .plan_header.visible{
        padding-left: 10px;
    }

    .payment_section {
       padding: 20px;
       box-sizing:border-box;
   }

   .payment_section .selected_upgrade_plan_info, 
   .payment_section .payment_form{
    width: 100%;
    max-width: 400px;
}

.payment_section .changePaymentMethodSection{
    flex: 0 0 auto;
    max-width: 400px;
    margin: auto;
}
}

body.browser.mobileweb #songPoppupMenu.replacePaymentForCreatorSubscriptionPopup .update_payment_method_widget .cancelBtn,
body.browser.mobileweb .upgrade_creator_subscription_widget .transparent_button.toggled{
    border: var(--border-3) solid var(--color-black);
    color: var(--color-black);
    background-color: transparent;
}

body.browser.mobileweb #songPoppupMenu.replacePaymentForCreatorSubscriptionPopup .update_payment_method_widget .cancelBtn:hover,
body.browser.mobileweb .upgrade_creator_subscription_widget .transparent_button.toggled:hover{
    border: var(--border-3) solid var(--color-black);
    background-color:  var(--color-black);
}

.payment_section .selected_upgrade_plan_info .header_section{
    margin: 10px 20px;
    display: flex;
    color: var(--color-black);
    align-items: center;
    flex-direction: row;
}

.payment_section .selected_upgrade_plan_info .header_section .back_to_plan_listing_btn{
    font-size: var(--text-lg-px);
    height: 30px;
    width: 30px;
    justify-content: center;
    align-items: center;
    display: flex;
    cursor: pointer;
}
.replacePaymentForCreatorSubscriptionPopup .update_payment_method_widget,
.payment_section .payment_form,
.payment_section .changePaymentMethodSection{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    flex: 1 1 100%;
    padding-top: 30px;
    height: fit-content;
    background: var(--bg-light);
}
.replacePaymentForCreatorSubscriptionPopup .update_payment_method_widget{
    flex: 0 0 auto;
    padding: 20px;
}

#songPoppupMenu.replacePaymentForCreatorSubscriptionPopup,
#songPoppupMenu.replacePaymentForCreatorSubscriptionPopup #closeBtn {
    color: var(--color-black);
    background-color: white;
}
#songPoppupMenu.replacePaymentForCreatorSubscriptionPopup .update_payment_method_widget{
    max-width: 500px;
    width: 100%;
}
.payment_section  .selected_upgrade_plan_info{
    display: flex;
    flex: 0 0 50%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.payment_section  .selected_upgrade_plan_info .default_payment_method{
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
    margin: 10px;
    max-width: 400px;
    padding: 20px 10px;
    box-sizing: border-box;
}
.payment_section  .selected_upgrade_plan_info .invoice_placeholder{
    width: 100%;
    max-width: 400px;
}
.stripe_invoice_widget{
    display: flex;
    flex-direction: column;
    text-align: left;
    padding: 20px;
    width: 100%;
    box-sizing: border-box;
    background-color: var(--bg-light);
}
.stripe_invoice_widget .invoice_item{
    margin: 10px 0px;
}

.stripe_invoice_widget  .invoice_item .invoice_item_header{
    font-size: 85%;
    opacity: 0.5;
    text-transform: uppercase;
}

.stripe_invoice_widget  .invoice_item .summary_item{
    display: flex;
    flex-direction: row;
}

.stripe_invoice_widget  .invoice_item .summary_item .summary_item_description{
    flex: 1 1 auto;
}

.stripe_invoice_widget  .invoice_item .summary_item .summary_item_value{
    flex: 0 0 auto;
    text-transform: uppercase;
}

.stripe_invoice_widget .total_summary{
    flex: 0 0 auto;
    text-transform: uppercase;


    padding-top: 20px;
    margin-top: 20px;
    border-top: var(--border-1) solid var(--color-gray-150);

}

.stripe_invoice_widget .total_summary .summary_item.amount_due{
    font-weight: bold;
}



.creator_plans_listing_container #all_creator_plans_container .creator_plan_box .plan_price_monthly .price,
.creator_plans_listing_container #all_creator_plans_container .creator_plan_box .plan_price_yearly .price{
 font-size: 200%;
 font-weight: 900;

}

.creator_plans_listing_container #all_creator_plans_container .creator_plan_box .plan_price_monthly .per_month_label,
.creator_plans_listing_container #all_creator_plans_container .creator_plan_box .plan_price_yearly .per_month_label{
    opacity: 0.7;
    margin-left: 10px;
    font-weight: bold;
}



.creator_plans_listing_container #all_creator_plans_container .creator_plan_box .plan_header.visible{
    position: absolute;
    top: 0px;
    width: 100%;
    background: var(--color-black);
    color: white;
    left: 0px;
    box-sizing: border-box;
    padding-left: 20px;
    font-size: 85%;

}


.creator_plans_listing_container .creator_plan_box .current_plan_selected_label,
.creator_plans_listing_container[current_subscribed_tier="pro_tier_yearly"][yearly_billing_selected="true"] #all_creator_plans_container .creator_plan_box.pro_tiers_info .buy_subscription_button,
.creator_plans_listing_container[current_subscribed_tier="pro_tier_monthly"][yearly_billing_selected="false"] #all_creator_plans_container .creator_plan_box.pro_tiers_info .buy_subscription_button,
.creator_plans_listing_container[current_subscribed_tier="pro_plus_tier_yearly"][yearly_billing_selected="true"] #all_creator_plans_container .creator_plan_box.pro_plus_tiers_info .buy_subscription_button,
.creator_plans_listing_container[current_subscribed_tier="pro_plus_tier_monthly"][yearly_billing_selected="false"] #all_creator_plans_container .creator_plan_box.pro_plus_tiers_info .buy_subscription_button,
.creator_plans_listing_container[current_subscribed_tier="enterprise_tier_yearly"][yearly_billing_selected="true"] #all_creator_plans_container .creator_plan_box.enterprise_tiers_info .buy_subscription_button,
.creator_plans_listing_container[current_subscribed_tier="enterprise_tier_monthly"][yearly_billing_selected="false"] #all_creator_plans_container .creator_plan_box.enterprise_tiers_info .buy_subscription_button,
.creator_plans_listing_container[current_subscribed_tier="free_tier"] #all_creator_plans_container .creator_plan_box.free_tiers_info .buy_subscription_button{
    display: none;
}

.creator_plans_listing_container[current_subscribed_tier="pro_tier_yearly"][yearly_billing_selected="true"] #all_creator_plans_container .creator_plan_box.pro_tiers_info .current_plan_selected_label,
.creator_plans_listing_container[current_subscribed_tier="pro_tier_monthly"][yearly_billing_selected="false"] #all_creator_plans_container .creator_plan_box.pro_tiers_info .current_plan_selected_label,
.creator_plans_listing_container[current_subscribed_tier="pro_plus_tier_yearly"][yearly_billing_selected="true"] #all_creator_plans_container .creator_plan_box.pro_plus_tiers_info .current_plan_selected_label,
.creator_plans_listing_container[current_subscribed_tier="pro_plus_tier_monthly"][yearly_billing_selected="false"] #all_creator_plans_container .creator_plan_box.pro_plus_tiers_info .current_plan_selected_label,
.creator_plans_listing_container[current_subscribed_tier="enterprise_tier_yearly"][yearly_billing_selected="true"] #all_creator_plans_container .creator_plan_box.enterprise_tiers_info .current_plan_selected_label,
.creator_plans_listing_container[current_subscribed_tier="enterprise_tier_monthly"][yearly_billing_selected="false"] #all_creator_plans_container .creator_plan_box.enterprise_tiers_info .current_plan_selected_label,
.creator_plans_listing_container[current_subscribed_tier="free_tier"] #all_creator_plans_container .creator_plan_box.free_tiers_info .current_plan_selected_label{
    display: flex;
}


.creator_plans_listing_container #all_creator_plans_container .creator_plan_box .current_plan_selected_label{
  margin: 20px 0px;
  background-color: var(--color-gray-100);
  color: var(--pulse-gray);
  font-weight: bold;
  outline: none;
  border: var(--border-3) solid var(--color-gray-100);
  height: 40px;
  align-items: center;
  justify-content: center;
}
.upgrade_creator_subscription_widget[pay_using_existing_payment_method="false"] .change_plan_section,
.upgrade_creator_subscription_widget[pay_using_existing_payment_method="true"] .payment_form,
.update_payment_method_widget[is_content_loading="true"] .update_payment_method_container,
.upgrade_creator_subscription_widget[is_content_loading="true"] .creator_plans_listing_container,
.upgrade_creator_subscription_widget[is_content_loading="true"] .payment_section,
.upgrade_creator_subscription_widget[is_content_loading="true"] .changePaymentMethodSection,
.update_payment_method_widget:not([is_content_loading="true"]) #loadingImg,
.upgrade_creator_subscription_widget:not([is_content_loading="true"]) #loadingImg,
.upgrade_creator_subscription_widget:not([displayed_section="payment_section"]) .payment_section,
.upgrade_creator_subscription_widget:not([display_update_payment_section="true"]) .changePaymentMethodSection,
.upgrade_creator_subscription_widget:not([displayed_section="plan_listing_section"]) .creator_plans_listing_container,
.creator_plans_listing_container[yearly_billing_selected="true"] #all_creator_plans_container .creator_plan_box .plan_price_monthly,
.creator_plans_listing_container[yearly_billing_selected="true"] #all_creator_plans_container .creator_plan_box .plan_price_monthly_desc,
.creator_plans_listing_container:not([yearly_billing_selected="true"]) #all_creator_plans_container .creator_plan_box .plan_price_yearly,
.creator_plans_listing_container:not([yearly_billing_selected="true"]) #all_creator_plans_container .creator_plan_box .plan_price_yearly_desc{
 display: none;

}
.upgrade_creator_subscription_widget[pay_using_existing_payment_method="true"]:not([display_update_payment_section="true"])  .payment_section .selected_upgrade_plan_info{
    flex: 1 1 auto;
}

.update_payment_method_widget .button_bar > button {
    justify-content: center;
    align-items: center;
    display: inline-flex;
}

.payment_section .selected_upgrade_plan_info .change_plan_section  {
    max-width: 400px;
    width: 100%;
}
.payment_section .selected_upgrade_plan_info  .change_plan_section .default_payment_method   .info_block{
    flex: 1 1 auto;
    text-align: left
}

.creator_plans_listing_container #all_creator_plans_container .creator_plan_box .plan_renewal_or_end_date{
    font-size: 80%;
    text-align: center;
    opacity: 0.5;
    height: 20px;
    margin-bottom: 10px;
}

.creator_plans_listing_container #all_creator_plans_container .creator_plan_box .plan_price_monthly_desc,
.creator_plans_listing_container #all_creator_plans_container .creator_plan_box .plan_price_yearly_desc{
 font-size: 85%;
 opacity: 0.7;
}
.creator_plans_listing_container #all_creator_plans_container .creator_plan_box  .feature_list{
    display: flex;
    flex-direction: column;
    text-align: start;
}

.creator_plans_listing_container #all_creator_plans_container .creator_plan_box  .feature_list .feature_item{
    font-size: 85%;
    color: var(--color-black);
    display: flex;
    flex-direction: row;

}

.creator_plans_listing_container #all_creator_plans_container .creator_plan_box  .feature_list .feature_item span{
    display: flex;
    align-items: center;
    justify-content: center;
}

.creator_plans_listing_container #all_creator_plans_container .creator_plan_box  .feature_list .feature_item .checkmark_icon{
    height: 30px;
    margin-right: 10px;
    font-size: 200%;

}


.StatsPage .statsTabInfo {
  color: var(--text-dark);
  border-bottom: var(--border-1) solid var(--color-black-5);
  box-sizing: border-box;
  font-size: var(--text-md);
  padding: 10px;
  cursor: pointer;
}

body.browser.mobileweb .StatsPage .statsTabInfo:hover {
  background-color: var(--color-black-5);
}

.StatsPage .swiper-slide-active > .statsTabInfo {
  color: var(--trebble-primary);;
  border-bottom: var(--border-1) solid var(--trebble-primary);;
}



.StatsPage .statsWrapper .ct-series-b .ct-bar,
.StatsPage .statsWrapper .ct-series-b .ct-line,
.StatsPage .statsWrapper .ct-series-b .ct-point,
.StatsPage .statsWrapper .ct-series-b .ct-slice-donut {
  stroke: var(--color-black-50);

}

.StatsPage .statsWrapper .ct-series-a .ct-bar,
.StatsPage .statsWrapper .ct-series-a .ct-line,
.StatsPage .statsWrapper .ct-series-a .ct-point,
.StatsPage .statsWrapper .ct-series-a .ct-slice-donut {
  stroke: var(--text-dark);
  
}


.StatsPage .statsWrapper .ct-label {
  color: var(--text-dark);
  font-size: var(--text-base);
  font-weight: 900;
  margin-top: 4px;
}

.StatsPage #statisticContent .ct-grid {
  stroke: var(--color-black-10);
  stroke-width: 1px;
  stroke-dasharray: 2px;
}

.StatsPage #statisticContent .ct-series-a .ct-point,
.StatsPage #statisticContent .ct-series-b .ct-point, 
.StatsPage #statisticContent .ct-series-c .ct-point, 
.StatsPage #statisticContent .ct-series-d .ct-point, 
.StatsPage #statisticContent .ct-series-e .ct-point,
.StatsPage #statisticContent .ct-series-f .ct-point {
  stroke-width: 16px ;
}
.StatsPage #statisticContent .ct-series-a .ct-line,
.StatsPage #statisticContent .ct-series-b .ct-line, 
.StatsPage #statisticContent .ct-series-c .ct-line, 
.StatsPage #statisticContent .ct-series-d .ct-line, 
.StatsPage #statisticContent .ct-series-e .ct-line,
.StatsPage #statisticContent .ct-series-f .ct-line {
  stroke-width: 6px ;
}

.StatsPage #statisticContent .ct-series-a .ct-area,
.StatsPage #statisticContent .ct-series-b .ct-area, 
.StatsPage #statisticContent .ct-series-c .ct-area, 
.StatsPage #statisticContent .ct-series-d .ct-area, 
.StatsPage #statisticContent .ct-series-e .ct-area,
.StatsPage #statisticContent .ct-series-f .ct-area {
  fill-opacity: 0.05;
}

.StatsPage #statisticContent .ct-series-a .ct-bar,
.StatsPage #statisticContent .ct-series-a .ct-line,
.StatsPage #statisticContent .ct-series-a .ct-point{
  stroke: var(--color-purple-400);
  
}
.StatsPage #statisticContent .ct-series-a .ct-area {
  fill: var(--color-purple-400);
}


.StatsPage  #statisticContent .legenItem.ct-series-a  .legendColor,
#statsTooltip .ct-series-a .legendColor {
  background-color: var(--color-purple-400);
}

.StatsPage  #statisticContent .ct-series-b .ct-bar,
.StatsPage  #statisticContent .ct-series-b .ct-line,
.StatsPage  #statisticContent .ct-series-b .ct-point{
  stroke: var(--trebble-gradient-pink);
  
}
.StatsPage #statisticContent .ct-series-b .ct-area {
  fill:var(--trebble-gradient-pink);
}

.StatsPage #statisticContent .legenItem.ct-series-b .legendColor,
#statsTooltip .ct-series-b .legendColor {
  background-color: var(--trebble-gradient-pink);
}

.StatsPage #statisticContent .ct-series-c .ct-bar,
.StatsPage #statisticContent .ct-series-c .ct-line,
.StatsPage #statisticContent .ct-series-c .ct-point{
  stroke: var(--color-blue-300);
}
.StatsPage #statisticContent .ct-series-c .ct-area {
  fill: var(--color-blue-300);
}


.StatsPage #statisticContent .legenItem.ct-series-c .legendColor,
#statsTooltip .ct-series-c .legendColor {
  background-color:var(--color-blue-300);
}

.StatsPage #statisticContent .legenItem {
  text-align: right;
}

.StatsPage #statisticContent .ct-series-d .ct-bar,
.StatsPage #statisticContent .ct-series-d .ct-line,
.StatsPage #statisticContent .ct-series-d .ct-point {
  stroke: var(--color-purple-300);
}
.StatsPage #statisticContent .ct-series-d .ct-area {
  fill: var(--color-purple-300);
}


.StatsPage #statisticContent .legenItem.ct-series-d .legendColor,
#statsTooltip .ct-series-d .legendColor {
  background-color: var(--color-purple-300);
}

.StatsPage #statisticContent .ct-series-e .ct-bar,
.StatsPage #statisticContent .ct-series-e .ct-line,
.StatsPage #statisticContent .ct-series-e .ct-point {
  stroke: var(--color-warning-500);
  
}
.StatsPage #statisticContent .ct-series-e .ct-area {

  fill: var(--color-warning-500);
}

.StatsPage #statisticContent .legenItem.ct-series-e .legendColor,
#statsTooltip .ct-series-e .legendColor {
  background-color: var(--color-warning-500);
}


.StatsPage #statisticContent .ct-series-f .ct-bar,
.StatsPage #statisticContent .ct-series-f .ct-line,
.StatsPage #statisticContent .ct-series-f .ct-point {
  stroke: var(--color-green-500);
  
}
.StatsPage #statisticContent .ct-series-f .ct-area {

  fill: var(--color-green-500);
}

.StatsPage #statisticContent .legenItem.ct-series-f .legendColor,
#statsTooltip .ct-series-f .legendColor {
  background-color: var(--color-green-500);
}


.StatsPage * .swiper-button-next.ion-ios7-arrow-right,
.StatsPage * .swiper-button-prev.ion-ios7-arrow-left {
    color: var(--text-dark);
    background-color: var(--color-black-5);
    box-shadow: 0 5px 10px var(--color-black-10);
}

#ExplorePage[showListView=true]:not(.darkTheme) * #filtersWrapper > div > .ion-chevron-down,
#ExplorePage[showListView=true]:not(.darkTheme) * #filtersWrapper > div,
#Onboarding[showListView=true]:not(.darkTheme) * #filtersWrapper > div > .ion-chevron-down,
#Onboarding[showListView=true]:not(.darkTheme) * #filtersWrapper > div,
.StatsPage * .CustomSelectFieldView ,
#ExplorePage[showListView=true]:not(.darkTheme) * .CustomSelectFieldView,
#Onboarding[showListView=true]:not(.darkTheme) * .CustomSelectFieldView {
    color: var(--text-dark);
    background-color: var(--color-gray-100);

}

#ExplorePage[showListView=true].darkTheme * #filtersWrapper > div > .ion-chevron-down,
#ExplorePage[showListView=true].darkTheme * #filtersWrapper > div,
#Onboarding[showListView=true].darkTheme * #filtersWrapper > div > .ion-chevron-down,
#Onboarding[showListView=true].darkTheme * #filtersWrapper > div,
body.darkTheme .StatsPage * .CustomSelectFieldView ,
body.darkTheme .createCapsulePage * .CustomSelectFieldView,
#ExplorePage[showListView=true].darkTheme * .CustomSelectFieldView,
#Onboarding[showListView=true].darkTheme * .CustomSelectFieldView {
    color: white;

}

body.browser.mobileweb:not(.darkTheme)  .CustomSelectFieldView:active > .ion-chevron-down,
body:not(.darkTheme) .CustomSelectFieldView:hover > .ion-chevron-down{
  color: var(--trebble-primary) !important;
  background-color: var(--bg-warm) !important;
}

body.browser.mobileweb #trebbleInfoDetailWrapper > .coverArtWrapper,
body.browser.mobileweb .StatsPage * .CustomSelectFieldView:hover,
.StatsPage * .CustomSelectFieldView:active,
body.browser.mobileweb .createCapsulePage * .CustomSelectFieldView:hover,
.createCapsulePage * .CustomSelectFieldView:active,
body.browser.mobileweb #ExplorePage[showListView=true] * #filtersWrapper .CustomSelectFieldView:hover,
#ExplorePage[showListView=true] * #filtersWrapper .CustomSelectFieldView:active,
body.browser.mobileweb #Onboarding[showListView=true] * #filtersWrapper .CustomSelectFieldView:hover,
#Onboarding[showListView=true] * #filtersWrapper .CustomSelectFieldView:active   {
  /*background-color: var(--color-black-5);*/
  color: var(--trebble-primary);
  background-color: var(--bg-warm);
}

.StatsPage * .CustomSelectFieldView > .pe-7s-angle-down,
.StatsPage * .CustomSelectFieldView > .ion-chevron-down,
.createCapsulePage * .CustomSelectFieldView > .pe-7s-angle-down,
.createCapsulePage * .CustomSelectFieldView > .ion-chevron-down,
#ExplorePage[showListView=true] * .CustomSelectFieldView > .pe-7s-angle-down,
#ExplorePage[showListView=true] * .CustomSelectFieldView > .ion-chevron-down,
#Onboarding[showListView=true] * .CustomSelectFieldView > .pe-7s-angle-down,
#Onboarding[showListView=true] * .CustomSelectFieldView > .ion-chevron-down {
    /* background-color: var(--text-dark); */
    border: var(--border-2) solid  var(--text-dark);
    color: var(--text-dark);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 22px;
    width: 22px;
    flex: 0 0 22px;
    border-color: transparent;
}

body.darkTheme .StatsPage * .CustomSelectFieldView > .pe-7s-angle-down,
body.darkTheme .StatsPage * .CustomSelectFieldView > .ion-chevron-down,
.createCapsulePage * .CustomSelectFieldView > .pe-7s-angle-down,
.createCapsulePage * .CustomSelectFieldView > .ion-chevron-down,
#ExplorePage[showListView=true].darkTheme * .CustomSelectFieldView > .pe-7s-angle-down,
#ExplorePage[showListView=true].darkTheme * .CustomSelectFieldView > .ion-chevron-down,
#Onboarding[showListView=true].darkTheme * .CustomSelectFieldView > .pe-7s-angle-down,
#Onboarding[showListView=true].darkTheme * .CustomSelectFieldView > .ion-chevron-down {
    border: var(--border-2) solid  white;
    color: white;
    border-color: transparent;
    
}
.createCapsulePage * .CustomSelectFieldView > .ion-chevron-down,
.StatsPage * .CustomSelectFieldView > .ion-chevron-down,
#ExplorePage[showListView=true] * .CustomSelectFieldView > .ion-chevron-down,
#Onboarding[showListView=true] * .CustomSelectFieldView > .ion-chevron-down {
    font-size: var(--text-sm-px);
    margin-left: 10px;
}

html body.white-theme .StatsPage.ui-page-theme-a  > .ui-panel-wrapper {
  background-color: var(--bg-light);
}


.StatsPage .tooltip {
  background-color: white;
  color: var(--text-dark);
  text-align: start;
  box-shadow: 2px 0 20px 0 var(--gray-light-30);
}

.StatsPage .tooltip:after {
  border-top-color: white;
  box-shadow: 2px 0 20px 0 var(--gray-light-30);
}

#statsTooltip  .legendColor{
  margin-right:4px;
}

.StatsPage #chartLegend {
  color: var(--text-dark);
}

.StatsPage .warningMessageWrapper{
  display:none;
}
.StatsPage .warningMessageWrapper.nodata,
.StatsPage .warningMessageWrapper.loading{
 -ms-flex-line-pack: center;
 -moz-flex-line-pack: center;
 align-content: center;
 -webkit-align-content: center;
 -ms-flex-direction: column;
 -moz-flex-direction: column;
 -webkit-box-orient: horizontal;
 -webkit-box-direction: normal;
 flex-direction: column;
 -webkit-flex-direction: column;
 display: -ms-flexbox;
 display: -moz-flexbox;
 display: -webkit-box;
 display: flex;
 display: -webkit-flex;
 display: -moz-flex;
 -webkit-align-items: center;
 -ms-flex-align: center;
 -webkit-box-align: center;
 align-items: center;
 -webkit-justify-content: center;
 -ms-flex-pack: center;
 -webkit-box-pack: center;
 justify-content: center;
 -webkit-align-items: center;
 -webkit-justify-content: center;
 width: 100%;
 height: 100%;
}
.StatsPage .warningMessageWrapper #trebbleCustomLoadingWidget,
.StatsPage .warningMessageWrapper .loadingMessage,
.StatsPage .warningMessageWrapper .message{
  display:none;
}
.StatsPage .warningMessageWrapper.nodata > .message{
  font-size: var(--text-3xl-px);
  display:block;
  font-weight: bold;
}
.StatsPage .warningMessageWrapper.loading #trebbleCustomLoadingWidget,
.StatsPage .warningMessageWrapper.loading .loadingMessage{
  display:block;
}

#trebblewarning #whatsacapsuleinfobtn{
    /*display: none;*/
}


.onboardingSignupFormWrapper .trebbleCategoryWrapper .ui-select{
  width:100%;
  /* max-width:500px; */
  margin:  0px auto;
}
.onboardingSignupFormWrapper .trebbleCategoryWrapper .ui-select span.browser-default{
  margin-top:3px;
}

.onboardingSignupFormWrapper .trebbleCategoryWrapper .CustomSelectFieldView{
    color:var(--trebble-primary)!important;
    font-size: var(--text-lg-px);
    padding:0;
    background-color:transparent;
    border:var(--border-2) solid var(--trebble-primary);
    width:calc(100% - 20px);
    margin:10px;
    box-sizing:border-box;
    font-weight:700;
}
[data-role=page].trebbleEditInfoPage .CustomSelectFieldView{
    color: var(--trebble-primary)!important;
    text-align: center;
    padding: 0;
    background-color: transparent;
    border: var(--border-2) solid var(--trebble-primary);
    box-sizing: border-box;
    width: 100%;
    margin: 0;
    height: 46px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    font-size: var(--text-xs-px);
}

.onboardingSignupFormWrapper #trebbleCategoryField-button ,
.onboardingSignupFormWrapper #trebbleLanguageField-button ,
.onboardingSignupFormWrapper #journeyCostField-button ,
.onboardingSignupFormWrapper #isJourneyFreeField-button ,
.onboardingSignupFormWrapper #trebbleVisibiltiyField-button ,
.onboardingSignupFormWrapper #trebbleTimezoneField-button {
  margin: 10px;
  color: var(--trebble-primary) !important;
  font-size: var(--text-lg-px);
  padding: 0px;
  background-color: transparent;
  border-width: var(--border-2);
  height: 50px;
  width: auto;
  /*display:flex;
  justify-content:center;
  align-items:center;
  flex-direction:row;*/
}

.onboardingSignupFormWrapper .welcomeMessageWrapper,
.onboardingSignupFormWrapper .chooseProfileTypeWrapper,
.onboardingSignupFormWrapper .chooseProfileTypeWrapper .buttonOptions,
.onboardingSignupFormWrapper .trebbleNameInfoWrapper,
.onboardingSignupFormWrapper .trebbleCustomUrlInfoWrapper,
.onboardingSignupFormWrapper .rssFeedURLInfoWrapper,
.onboardingSignupFormWrapper .trebbleDescriptionfoWrapper,
.onboardingSignupFormWrapper .journeyGoalWrapper,
.onboardingSignupFormWrapper .trebbleCategoryWrapper
,.onboardingSignupFormWrapper .signUpFormWrapper{
    -ms-flex-line-pack: center;
    -moz-flex-line-pack: center;
    align-content: center;
    -webkit-align-content: center;
    -ms-flex-direction: column;
    -moz-flex-direction: column;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-direction: column;
    -webkit-flex-direction: column;
    display: -ms-flexbox;
    display: -moz-flexbox;
    display: -webkit-box;
    display: flex;
    display: -webkit-flex;
    display: -moz-flex;
    -webkit-align-items: center;
    -ms-flex-align: center;
    -webkit-box-align: center;
    align-items: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-align-items: center;
    -webkit-justify-content: center;
    width: 100%;
    height: 100%;
    max-width: 1050px;
    margin: auto;
    padding: 20px;
    box-sizing: border-box;

}

.onboardingSignupFormWrapper .chooseProfileTypeWrapper{
    height: fit-content;
}

#onboardingSignupPage .chooseProfileTypeWrapper a.actionButton{
    flex: 0 0 150px;
    color: var(--trebble-primary);
    font-weight: 400;
    background-color: transparent;
    border: var(--border-3) solid var(--trebble-primary);
    flex-direction:column;
}

.onboardingSignupFormWrapper .chooseProfileTypeWrapper .buttonOptions{
    flex-direction: column;
}

#onboardingSignupPage .chooseProfileTypeWrapper a.actionButton i{
    font-size: var(--text-3xl-px);
    margin-bottom: 5px;
}

body.browser.mobileweb  #onboardingSignupPage .chooseProfileTypeWrapper a.actionButton:hover,
#onboardingSignupPage .chooseProfileTypeWrapper a.actionButton:active{
    color: white;
    background-color: var(--trebble-primary);
    border: var(--border-2) solid var(--trebble-primary);
}

#onboardingSignupPage .ui-content.disableIOSScrolling{
    -webkit-overflow-scrolling: auto;
}

@media(min-width: 500px), (min-height: 500px) and (orientation: landscape){
    .onboardingSignupFormWrapper .chooseProfileTypeWrapper .buttonOptions{
        flex-direction: row;
    }

    #onboardingSignupPage .chooseProfileTypeWrapper a.actionButton{
        height: 300px;
        flex: 0 0 30%;
        font-size: var(--text-base-px);
        font-weight: 400;
        /*color: var(--trebble-primary);
        background-color: transparent;
        border: var(--border-2) solid var(--trebble-primary);
        flex-direction:column;*/
    }
    /*body.browser.mobileweb  #onboardingSignupPage .chooseProfileTypeWrapper a.actionButton:hover,
    #onboardingSignupPage .chooseProfileTypeWrapper a.actionButton:active{
        color: white;
        background-color: var(--trebble-primary);
        border: var(--border-2) solid var(--trebble-primary);
        }*/


        #onboardingSignupPage .chooseProfileTypeWrapper a.actionButton i{
            font-size: var(--text-6xl-px);
            margin-bottom: 10px;
        }
    }

    .onboardingSignupFormWrapper .largeLabel{
        font-size: var(--text-6xl-px);
        font-weight: 900;
        text-align: left;
        letter-spacing: 1px;
        line-height: 1em;
        padding-bottom: 10px;
        padding: 0 10px;
        margin-bottom: 20px !important;
    }
    .onboardingSignupFormWrapper .chooseProfileTypeWrapper .largeLabel{
        text-align: center;
    }


    .onboardingSignupFormWrapper .fieldDesc{
        text-align: left;
        font-size: var(--text-lg-px);
    }

    .onboardingSignupFormWrapper .trebbleCustomUrlInfoWrapper .urlPrefix{
      font-size: var(--text-3xl-px);
      display:flex;
      justify-content:center;
      align-items:center;
      opacity:0.8;
      font-weight:900;
      padding:0px 5px;
  }


  .onboardingSignupFormWrapper .trebbleCustomUrlInfoWrapper .formField.input-field{
      display:flex;
      flex-direction:row;
      justify-content:center;
      align-items:center;
  }

  .onboardingSignupFormWrapper .trebbleNameInfoWrapper input,
  .onboardingSignupFormWrapper .carouselItem > div:not(.signUpFormWrapper) input,
  .onboardingSignupFormWrapper .trebbleCustomUrlInfoWrapper input{
    font-size: var(--text-3xl-px);
    font-weight: 900;
    color: var(--trebble-primary);
}

.onboardingSignupFormWrapper .trebbleDescriptionfoWrapper textarea,
.onboardingSignupFormWrapper .journeyGoalWrapper textarea{
    font-size: var(--text-lg-px);
    color: var(--trebble-primary);
    height: 100px !important;
}


.trebbleNameInfoWrapper > div,
.trebbleCustomUrlInfoWrapper > div,
.rssFeedURLInfoWrapper > div,
.trebbleDescriptionfoWrapper > div,
.journeyGoalWrapper > div,
.trebbleCategoryWrapper > div  {
   -webkit-align-self: flex-start;
   -ms-flex-item-align: flex-start;
   align-self: flex-start;
}

.carouselItem .hintBox{
    color: var(--color-blue-300);
    padding: 0px 20px;
    text-align: left;
    margin-bottom: 10px;
}

.carouselItem.showWaitMessage .hintBox,
.carouselItem.successValidation .hintBox,
.carouselItem.failValidation .hintBox,
.carouselItem:not(.showWaitMessage) .waitMessageBox,
.carouselItem:not(.successValidation) .successValidationMessageBox,
.carouselItem:not(.failValidation) .failValidationMessageBox,
.trebbleCustomUrlInfoWrapper:not(.showWaitMessage) .waitMessageBox,
.trebbleCustomUrlInfoWrapper:not(.successValidation) .successValidationMessageBox,
.trebbleCustomUrlInfoWrapper:not(.failValidation) .failValidationMessageBox,
.rssFeedURLInfoWrapper:not(.showWaitMessage) .waitMessageBox,
.rssFeedURLInfoWrapper:not(.successValidation) .successValidationMessageBox,
.rssFeedURLInfoWrapper:not(.failValidation) .failValidationMessageBox,
.trebbleDescriptionfoWrapper:not(.successValidation) .successValidationMessageBox,
.trebbleDescriptionfoWrapper:not(.failValidation) .failValidationMessageBox,
.journeyGoalWrapper:not(.successValidation) .successValidationMessageBox,
.journeyGoalWrapper:not(.failValidation) .failValidationMessageBox{
    display: none;
}

.trebbleNameInfoWrapper.showWaitMessage .waitMessageBox,
.trebbleCustomUrlInfoWrapper.showWaitMessage .waitMessageBox,
.rssFeedURLInfoWrapper.showWaitMessage .waitMessageBox{
    display: -ms-flexbox;
    display: -moz-flexbox;
    display: -webkit-box;
    display: flex;
    display: -webkit-flex;
    display: -moz-flex;
    padding: 0px 20px;
    box-sizing: border-box;
}

.trebbleNameInfoWrapper.successValidation .successValidationMessageBox,
.trebbleCustomUrlInfoWrapper.successValidation .successValidationMessageBox,
.rssFeedURLInfoWrapper.successValidation .successValidationMessageBox,
.trebbleDescriptionfoWrapper.successValidation .successValidationMessageBox,
.journeyGoalWrapper.successValidation .successValidationMessageBox{

    display: -ms-flexbox;
    display: -moz-flexbox;
    display: -webkit-box;
    display: flex;
    display: -webkit-flex;
    display: -moz-flex;
    padding: 0px 20px;
    box-sizing: border-box;
    color: var(--color-green-500);
}
.trebbleNameInfoWrapper.failValidation .failValidationMessageBox,
.trebbleCustomUrlInfoWrapper.failValidation .failValidationMessageBox,
.rssFeedURLInfoWrapper.failValidation .failValidationMessageBox,
.trebbleDescriptionfoWrapper.failValidation .failValidationMessageBox,
.journeyGoalWrapper.failValidation .failValidationMessageBox{
    display: -ms-flexbox;
    display: -moz-flexbox;
    display: -webkit-box;
    display: flex;
    display: -webkit-flex;
    display: -moz-flex;
    padding: 0px 20px;
    box-sizing: border-box;
    color: var(--error);
}


.trebbleNameInfoWrapper.showWaitMessage .waitMessageBox #trebbleCustomLoadingWidget,
.trebbleCustomUrlInfoWrapper.showWaitMessage .waitMessageBox #trebbleCustomLoadingWidget,
.rssFeedURLInfoWrapper.showWaitMessage .waitMessageBox #trebbleCustomLoadingWidget{
    display: inline-block;
    font-size: var(--text-3xs-px);
    margin: 0px;
    margin-right: 10px;
}

.trebbleNameInfoWrapper.successValidation .successValidationMessageBox #checkIcon,
.trebbleNameInfoWrapper.failValidation .failValidationMessageBox #failureIcon,
.trebbleCustomUrlInfoWrapper.successValidation .successValidationMessageBox #checkIcon,
.trebbleCustomUrlInfoWrapper.failValidation .failValidationMessageBox #failureIcon,
.rssFeedURLInfoWrapper.successValidation .successValidationMessageBox #checkIcon,
.rssFeedURLInfoWrapper.failValidation .failValidationMessageBox #failureIcon,
.trebbleDescriptionfoWrapper.successValidation .successValidationMessageBox #checkIcon,
.trebbleDescriptionfoWrapper.failValidation .failValidationMessageBox #failureIcon,
.journeyGoalWrapper.successValidation .successValidationMessageBox #checkIcon,
.journeyGoalWrapper.failValidation .failValidationMessageBox #failureIcon{
    display: inline-block;
    font-size: var(--text-3xl-px);
    margin: 0px;
    margin-right: 10px;
}

.trebbleNameInfoWrapper.showWaitMessage .waitMessageBox  .label,
.trebbleNameInfoWrapper.successValidation .successValidationMessageBox .label,
.trebbleNameInfoWrapper.failValidation .failValidationMessageBox .label,
.trebbleCustomUrlInfoWrapper.showWaitMessage .waitMessageBox  .label,
.trebbleCustomUrlInfoWrapper.successValidation .successValidationMessageBox .label,
.trebbleCustomUrlInfoWrapper.failValidation .failValidationMessageBox .label,
.rssFeedURLInfoWrapper.showWaitMessage .waitMessageBox  .label,
.rssFeedURLInfoWrapper.successValidation .successValidationMessageBox .label,
.rssFeedURLInfoWrapper.failValidation .failValidationMessageBox .label,
.trebbleDescriptionfoWrapper.successValidation .successValidationMessageBox .label,
.trebbleDescriptionfoWrapper.failValidation .failValidationMessageBox .label,
.journeyGoalWrapper.successValidation .successValidationMessageBox .label,
.journeyGoalWrapper.failValidation .failValidationMessageBox .label{
    -ms-flex-line-pack: center;
    -moz-flex-line-pack: center;
    align-content: center;
    -webkit-align-content: center;
    -ms-flex-direction: column;
    -moz-flex-direction: column;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-direction: column;
    -webkit-flex-direction: column;
    display: -ms-flexbox;
    display: -moz-flexbox;
    display: -webkit-box;
    display: flex;
    display: -webkit-flex;
    display: -moz-flex;
    -webkit-align-items: center;
    -ms-flex-align: center;
    -webkit-box-align: center;
    align-items: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-align-items: center;
    -webkit-justify-content: center;
    text-align: start;
}

body div#onboardingSignupPage[data-role=page]:not(.lighttext)      [data-role=header] * #nextButton.tag.actionButton,
body div#onboardingSignupPage[data-role=page]:not(.lighttext)      [data-role=header] * #previousButton,
body div#trebbleSetup[data-role=page]:not(.lighttext)      [data-role=header] * #nextButton.tag.actionButton,
body div#trebbleSetup[data-role=page]:not(.lighttext)      [data-role=header] * #saveButtonIcon,
body div#trebbleSetup[data-role=page]:not(.lighttext)      [data-role=header] * #previousButton,
body div#journeyCreation[data-role=page]:not(.lighttext)      [data-role=header] * #nextButton.tag.actionButton,
body div#journeyCreation[data-role=page]:not(.lighttext)      [data-role=header] * #saveButtonIcon,
body div#journeyCreation[data-role=page]:not(.lighttext)      [data-role=header] * #previousButton{
    color: var(--trebble-primary) !important;
    border: var(--border-3) solid var(--trebble-primary);
    background-color: transparent;
    margin-right: 4px;
    margin-left: 4px;
    margin-top: 4px;
    box-sizing: border-box;
    height: 50px;
    width: auto;
    flex: 0 0 auto;
} 
body.browser.mobileweb div#onboardingSignupPage[data-role=page]:not(.lighttext)      [data-role=header] * #nextButton.tag.actionButton:not(.trebble_disabled):hover,
body div#onboardingSignupPage[data-role=page]:not(.lighttext)      [data-role=header] * #nextButton.tag.actionButton:not(.trebble_disabled):active,
body.browser.mobileweb  div#onboardingSignupPage[data-role=page]:not(.lighttext)      [data-role=header] * #previousButton:not(.trebble_disabled):hover,
body div#onboardingSignupPage[data-role=page]:not(.lighttext)      [data-role=header] * #previousButton:not(.trebble_disabled):active,
body.browser.mobileweb div#trebbleSetup[data-role=page]:not(.lighttext)      [data-role=header] * #nextButton.tag.actionButton:not(.trebble_disabled):hover,
body div#trebbleSetup[data-role=page]:not(.lighttext)      [data-role=header] * #nextButton.tag.actionButton:not(.trebble_disabled):active,
body.browser.mobileweb  div#trebbleSetup[data-role=page]:not(.lighttext)      [data-role=header] * #previousButton:not(.trebble_disabled):hover,
body div#trebbleSetup[data-role=page]:not(.lighttext)      [data-role=header] * #previousButton:not(.trebble_disabled):active,
body.browser.mobileweb div#journeyCreation[data-role=page]:not(.lighttext)      [data-role=header] * #nextButton.tag.actionButton:not(.trebble_disabled):hover,
body div#journeyCreation[data-role=page]:not(.lighttext)      [data-role=header] * #nextButton.tag.actionButton:not(.trebble_disabled):active,
body.browser.mobileweb  div#journeyCreation[data-role=page]:not(.lighttext)      [data-role=header] * #previousButton:not(.trebble_disabled):hover,
body div#journeyCreation[data-role=page]:not(.lighttext)      [data-role=header] * #previousButton:not(.trebble_disabled):active{
    color: white !important;
    border: var(--border-3) solid var(--trebble-primary);
    background-color: var(--trebble-primary);

} 
body div#onboardingSignupPage[data-role=page]:not(.lighttext)      [data-role=header] * #previousButton,
body div#trebbleSetup[data-role=page]:not(.lighttext)      [data-role=header] * #previousButton,
body div#journeyCreation[data-role=page]:not(.lighttext)      [data-role=header] * #previousButton{
 padding:0px;
 padding-left:10px;
}

.sharedInfoWrapper > textarea.ui-input-text{
  resize: none;
  background-color: var(--color-white-20);
  border: var(--border-0);
  color: white;
  min-height: 140px;
}

@media(max-width: 500px), (max-height: 500px) and (orientation: landscape){

    .rating_summary_card .rating_summary_card_content{
        display: flex;
        flex-direction: column;
    }

    .rating_summary_card .rating_average_section .rating_average{
        font-size: var(--text-xl-px);
    }

    .rating_summary_card .rating_average_section .separator{
        display: block;
        padding: 0px 5px;
    }

    .rating_summary_card .rating_summary_card_content .rating_average_section{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
    }

    .rating_summary_card .rating_average_section .separator{
        padding: 0px 10px;
        flex-direction: row;
        display: flex;
        align-items: center;
    }

    .rating_summary_card .rating_average_section{
        padding: 0px;
    }

    .onboardingSignupFormWrapper .largeLabel{
        font-size: var(--text-xl-px);
        line-height: 29px;
        font-weight: 900;
        margin-bottom: 20px !important;
        padding: 0 10px;
    }
    .onboardingSignupFormWrapper .signUpFormWrapper{
        display: block;
        width: 100%;
        height: 100%;
        max-width: 1050px;
        margin: auto;
        padding: 20px;
        box-sizing: border-box;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
    }

    .onboardingSignupFormWrapper #trebbleCategoryField-button,
    .onboardingSignupFormWrapper #trebbleLanguageField-button,
    .onboardingSignupFormWrapper #isJourneyFreeField-button,
    .onboardingSignupFormWrapper #trebbleVisibiltiyField-button,
    .onboardingSignupFormWrapper #journeyCostField-button,
    .onboardingSignupFormWrapper #trebbleTimezoneField-button,
    .onboardingSignupFormWrapper .trebbleCategoryWrapper .CustomSelectFieldView{
        font-size: var(--text-base-px);
    }

    .onboardingSignupFormWrapper .fieldDesc{
        font-size: var(--text-sm-px);
    }
    .onboardingSignupFormWrapper .trebbleCustomUrlInfoWrapper .urlPrefix{
        font-size: var(--text-md-px);
        padding-bottom:10px;
    }
    .onboardingSignupFormWrapper .trebbleNameInfoWrapper input ,
    .onboardingSignupFormWrapper .carouselItem input ,
    .onboardingSignupFormWrapper .trebbleCustomUrlInfoWrapper input{
        font-size: var(--text-md-px);

    }

    .trebbleNameInfoWrapper.successValidation .successValidationMessageBox #checkIcon,
    .trebbleNameInfoWrapper.failValidation .failValidationMessageBox #failureIcon,
    .trebbleDescriptionfoWrapper.successValidation .successValidationMessageBox #checkIcon,
    .trebbleDescriptionfoWrapper.failValidation .failValidationMessageBox #failureIcon ,
    .journeyGoalWrapper.successValidation .successValidationMessageBox #checkIcon,
    .journeyGoalWrapper.failValidation .failValidationMessageBox #failureIcon {
      font-size: var(--text-base-px);
      margin: 0px;
      margin-right: 6px;
  }

  .onboardingSignupFormWrapper .trebbleDescriptionfoWrapper textarea,
  .onboardingSignupFormWrapper .journeyGoalWrapper textarea {
    font-size: var(--text-base-px);

}




body.white-theme div#onboardingSignupPage[data-role=page]:not(.lighttext) [data-role=header] * #nextButton.tag.actionButton, 
body.white-theme div#onboardingSignupPage[data-role=page]:not(.lighttext) [data-role=header] * #previousButton,
body.white-theme div#trebbleSetup[data-role=page]:not(.lighttext) [data-role=header] * #nextButton.tag.actionButton, 
body.white-theme div#trebbleSetup[data-role=page]:not(.lighttext) [data-role=header] * #previousButton,
body.white-theme div#trebbleSetup[data-role=page]:not(.lighttext) [data-role=header] * #saveButtonIcon,
body.white-theme div#journeyCreation[data-role=page]:not(.lighttext) [data-role=header] * #nextButton.tag.actionButton, 
body.white-theme div#journeyCreation[data-role=page]:not(.lighttext) [data-role=header] * #previousButton,
body.white-theme div#journeyCreation[data-role=page]:not(.lighttext) [data-role=header] * #saveButtonIcon{
  padding: 0px 10px;
  border: var(--border-0);

}

.trebbleNameInfoWrapper.showWaitMessage .waitMessageBox #trebbleCustomLoadingWidget {
    display: inline-block;
    margin: 0px;
    margin-right: 10px;
    width: 23px;
    height: 14px;
}
.inline_flex_collection{
    flex-direction:column;
    align-items: center;
}

}


.onboardingSignupFormWrapper.listenerOnboardinOnly  .trebbleNameInfoWrapper,
.onboardingSignupFormWrapper.listenerOnboardinOnly  .trebbleDescriptionfoWrapper,
.onboardingSignupFormWrapper.listenerOnboardinOnly  .journeyGoalWrapper,
.onboardingSignupFormWrapper.listenerOnboardinOnly  .trebbleCategoryWrapper,
.onboardingSignupFormWrapper.listenerOnboardinOnly  .trebbleCustomUrlInfoWrapper,
.onboardingSignupFormWrapper.listenerOnboardinOnly  .rssFeedURLInfoWrapper,
.onboardingSignupFormWrapper.creatorWithoutFlashBriefing  .rssFeedURLInfoWrapper,
.onboardingSignupFormWrapper.creatorOnboardingOnly  .chooseProfileTypeWrapper,
.onboardingSignupFormWrapper:not(.showWelcomeMessage) .welcomeMessageWrapper {
    visibility: hidden;

}


#onboardingSignupPage .chooseProfileTypeWrapper a.actionButton,
#onboardingSignupPage .welcomeMessageWrapper a.actionButton,
#trebbleSetup .chooseProfileTypeWrapper a.actionButton,
#trebbleSetup .welcomeMessageWrapper a.actionButton,
#journeyCreation .chooseProfileTypeWrapper a.actionButton,
#journeyCreation .welcomeMessageWrapper a.actionButton{
  max-width: 300px;
}

.onboardingSignupFormWrapper .welcomeMessageWrapper .largeLabel {
  text-align:center;
}

.onboardingSignupFormWrapper .chooseProfileTypeWrapper .largeLabel,
.onboardingSignupFormWrapper .welcomeMessageWrapper .largeLabel{
    margin-bottom: 30px;
}

.onboardingSignupFormWrapper .welcomeMessageWrapper .fieldDesc{
    text-align: center;
    max-width:700px;
    margin-bottom:30px;
}
.onboardingSignupFormWrapper .chooseProfileTypeWrapper .fieldDesc{
    margin-top: 30px;
    font-size: var(--text-sm-px);
}

.onboardingSignupFormWrapper.listenerOnboardinOnly .signUpFormWrapper .largeLabel,
.onboardingSignupFormWrapper.listenerOnboardinOnly .signUpFormWrapper .fieldDesc{
    display: none;
}

.onboardingSignupFormWrapper:not(.listenerOnboardinOnly) .signUpFormWrapper .icon-trebbleLogo{
  display:none !important;
}

.onboardingSignupFormWrapper  .owl-wrapper-outer,
.onboardingSignupFormWrapper .owl-wrapper-outer .owl-wrapper,
.onboardingSignupFormWrapper .owl-wrapper-outer .owl-wrapper .owl-item
{
  height: 100%;
  height: calc(100vh - 100px);
  overflow: auto;
}


[data-role=page]#onboardingSignupPage > .ui-content  #SignUpForm{
  display:block;
  margin: auto;
}
[data-role=page]#onboardingSignupPage > .ui-content  #SignUpForm > a.actionButton{
  height: 50px;
}


.menuIcon.pe-7s-photo-gallery,
.menuIcon.pe-7s-albums {
    position: relative
}


#fabAddButtonContainer #addCapsuleFabBtn > .pe-7s-micro,
#fabAddButtonContainer #addSongFabBtn > .pe-7s-photo-gallery{
  font-size: var(--text-xl-px);
}

.menuIcon.pe-7s-photo-gallery>.ion-contrast,
.menuIcon.pe-7s-albums>.pe-7s-radio{
    position: absolute;
    font-size: var(--text-2xs-px);
    left: 9px;
    top: 9px
}
.menuIcon.pe-7s-albums>.pe-7s-radio{
    left: 7px;
}

.playlistDetailsPage .textContent .socialInfos{
    display: none;
}

.playlistDetailsPage .actionButtonWrapper{
  /*flex-direction:column;*/
  flex-direction:column-reverse;
}

.playlistDetailsPage.publicPage:not(.showBackButton)  #exploreTrebblesBtn{

    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 20px;
    border: var(--border-2) solid white;
    margin: 4px;
    color: white;
    cursor: pointer;
    display: none !important;

}
body.mobileweb .playlistDetailsPage.publicPage:not(.showBackButton)  #exploreTrebblesBtn:hover,
.playlistDetailsPage.publicPage:not(.showBackButton)  #exploreTrebblesBtn:active{
 background-color: white;
 color:var(--trebble-primary);
}


.playlistDetailsPage:not(.publicPage) .bioWrapper .trebbleName,
/*.playlistDetailsPage:not(.publicPage)  .icon-trebbleLogo,*/
.playlistDetailsPage.showBackButton .icon-trebbleLogo,
.playlistDetailsPage:not(.publicPage)  .actionButtonWrapper #followingBtn,
.playlistDetailsPage:not(.publicPage)  .actionButtonWrapper #purchasedBtn,
.playlistDetailsPage:not(.publicPage)  .actionButtonWrapper #followBtn,
.playlistDetailsPage:not(.publicPage)  .actionButtonWrapper #buyNowBtn,
.playlistDetailsPage:not(.publicPage)  .trebbleInfo .bioWrapper .listeningInstructionsBox,
.playlistDetailsPage:not(.publicPage) #exploreTrebblesBtn,
.playlistDetailsPage.showBackButton #exploreTrebblesBtn,
.playlistDetailsPage.publicPage .trebbleInfo .bioWrapper  .listeningInstructionsBox,
.playlistDetailsPage.publicPage #trebblewarning.show {
    display: none !important;
}

.playlistDetailsPage:not(.publicPage) .actionButtonWrapper{
  /*flex-direction:column;*/
  flex-direction:column-reverse;
}
.playlistDetailsPage.publicPage .bioWrapper .trebbleName{
    display: block;
    font-size: var(--text-4xl);
    font-weight: 900;
}

.playlistDetailsPage.publicPage #myCapsulesListWrapper > div > div,
.playlistDetailsPage.publicPage #myArchivedCapsulesListWrapper > div > div{
    height: fit-content !important;
}
.playlistDetailsPage.publicPage #myCapsulesListWrapper #collection_repeat_container,
.playlistDetailsPage.publicPage #myArchivedCapsulesListWrapper #collection_repeat_container{
    text-align:center;
}

.trebble_audio_list_item.isCurrentlyLoading .playIconWrapper,
.trebble_audio_list_item.isCurrentlyLoading .pauseIconWrapper,
.trebble_audio_list_item.isCurrentlyPlaying .playIconWrapper,
.trebble_audio_list_item:not(.isCurrentlyPlaying) .pauseIconWrapper,
.trebble_audio_list_item:not(.isCurrentlyLoading) .loadingIconWrapper,
.playlistDetailsPage:not(.publicPage) #fullCoverArtImage #playbTrebbletn,
.playlistDetailsPage:not(.publicPage) #fullCoverArtImage #loadingIndicator,
.playlistDetailsPage:not(.publicPage) #fullCoverArtImage #stopTrebbletn,
.journeyPage.playlistDetailsPage.publicPage.isCurrentlyPlaying #fullCoverArtImage #stopTrebbletn,
.playlistDetailsPage.publicPage.isCurrentlyPlaying #fullCoverArtImage #playbTrebbletn,
.playlistDetailsPage.publicPage.isCurrentlyLoading #fullCoverArtImage #playbTrebbletn,
.playlistItem.withLargeAlbumArt.isCurrentlyPlaying > .itemContent > .coverArt > div > .playButton,
.playlistItem.withLargeAlbumArt.isCurrentlyLoading > .itemContent > .coverArt > div > .playButton,
.playlistDetailsPage.isCurrentlyPlaying:not(.publicPage) #startRadioBtn,
.playlistDetailsPage.isCurrentlyLoading:not(.publicPage) #startRadioBtn,
.playlistDetailsPage.publicPage:not(.isCurrentlyPlaying) #fullCoverArtImage #stopTrebbletn,
.playlistItem.withLargeAlbumArt:not(.isCurrentlyPlaying) > .itemContent > .coverArt > div > .stopButton,
.playlistItem.withLargeAlbumArt:not(.isCurrentlyPlaying) > .itemContent > .coverArt > div > .loadingIndicator,
.playlistDetailsPage.publicPage:not(.isCurrentlyLoading) #fullCoverArtImage #loadingIndicator,
.playlistDetailsPage:not(.isCurrentlyPlaying):not(.publicPage)  #pauseRadioBtn,
.playlistDetailsPage:not(.isCurrentlyLoading):not(.publicPage)  #loadingRadioBtn {
  display: none;
}

.playlistItem.notPlayable .playButton,
.playlistItem.notPlayable .stopButton,
.playlistItem.notPlayable .loadingIndicator {
    display: none !important;
}

.playlistDetailsPage.publicPage:not(.isCurrentlyPlaying):not(.isCurrentlyLoading) #fullCoverArtImage #playbTrebbletn,
.playlistDetailsPage.publicPage.isCurrentlyPlaying #fullCoverArtImage #stopTrebbletn,
.playlistDetailsPage.publicPage.isCurrentlyLoading #fullCoverArtImage #loadingIndicator{

    margin: auto;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: var(--color-black-20);

}

/** THE FOLLOWING CSS HIGHTLIGHTS THE MAIN BUTTONS ON A TREBBLE PAGE - START ***/
.playlistDetailsPage.publicPage.isCurrentlyPlaying #fullCoverArtImage #stopTrebbletn,
.playlistDetailsPage.publicPage:not(.isCurrentlyPlaying):not(.isCurrentlyLoading) #fullCoverArtImage #playbTrebbletn {
    background-color: var(--trebble-primary);
    border-color:var(--trebble-primary);
    transition: all .1s ease-in-out;
    color: white;
    box-shadow: 0 5px 20px var(--color-black-20);
}
.playlistDetailsPage #followingBtn,
.playlistDetailsPage #purchasedBtn{
 /* border-color:var(--trebble-primary);
 background-color: var(--trebble-primary); 
 color: white;*/
 border-color:var(--color-gray-150);
 background-color: var(--color-gray-150);
 color: var(--text-dark);
 padding: 0px;
}
/*
body.browser.mobileweb .playlistDetailsPage #followBtn:hover,
.playlistDetailsPage #followBtn:active{
    border-color: var(--trebble-primary);
    background-color: transparent;
    color: var(--trebble-primary) !important;
    }*/
    .hightlightBtn > span,
    .playlistDetailsPage #followBtn > span,
    .playlistDetailsPage #buyNowBtn > span{
        display: flex;
        padding: 4px 40px;
    }
    .hightlightBtn > span{
     padding: 4px 20px;
 }

 body.mobileweb .playlistDetailsPage.publicPage.isCurrentlyPlaying #fullCoverArtImage #stopTrebbletn:hover,
 body.mobileweb .playlistDetailsPage.publicPage:not(.isCurrentlyPlaying):not(.isCurrentlyLoading) #fullCoverArtImage #playbTrebbletn:hover,
 .playlistDetailsPage.publicPage.isCurrentlyPlaying #fullCoverArtImage #stopTrebbletn:active,
 .playlistDetailsPage.publicPage:not(.isCurrentlyPlaying):not(.isCurrentlyLoading) #fullCoverArtImage #playbTrebbletn:active{
    transform: scale(1.09);
}
/** THE FOLLOWING CSS HIGHTLIGHTS THE MAIN BUTTONS ON A TREBBLE PAGE - END ***/

.playlistDetailsPage:not(.isCurrentlyPlaying):not(.isCurrentlyLoading):not(.publicPage)  #startRadioBtn,
.playlistDetailsPage.isCurrentlyPlaying:not(.publicPage)  #pauseRadioBtn,
.playlistDetailsPage.isCurrentlyLoading:not(.publicPage)  #loadingRadioBtn {
  display:flex;
  width: 240px;

}
.playlistDetailsPage:not(.publicPage) #myCapsulesListWrapper #collection_repeat_container,
.playlistDetailsPage:not(.publicPage) #myArchivedCapsulesListWrapper #collection_repeat_container{
  text-align: center;
}
.playlistDetailsPage.publicPage #fullCoverArtImage #playbTrebbletn > i,
.playlistDetailsPage.publicPage #fullCoverArtImage #stopTrebbletn > i,
.playlistDetailsPage.publicPage.isCurrentlyLoading #fullCoverArtImage #loadingIndicator {
  color: white;
  font-size: var(--text-6xl-px);

}
.playlistDetailsPage.publicPage #fullCoverArtImage #playbTrebbletn > i{

  padding-left: 10px;
}



.playlistDetailsPage.publicPage #startRadioBtn,
.playlistDetailsPage.publicPage #loadingRadioBtn,
.playlistDetailsPage.publicPage #pauseRadioBtn,
[data-role=page].playlistDetailsPage.publicPage:not(.journeyPage) .flexUpperContentWrapper > div.trebbleInfo > .bioWrapper #followingBtn,
[data-role=page].playlistDetailsPage.publicPage:not(.journeyPage) .flexUpperContentWrapper > div.trebbleInfo > .bioWrapper #purchasedBtn,
[data-role=page].playlistDetailsPage.publicPage:not(.journeyPage) .flexUpperContentWrapper > div.trebbleInfo > .bioWrapper #followBtn,
[data-role=page].playlistDetailsPage.publicPage:not(.journeyPage) .flexUpperContentWrapper > div.trebbleInfo > .bioWrapper #buyNowBtn {
    display: none !important;
}

.playlistDetailsPage.publicPage:not(.showBackButton) .icon-trebbleLogo{
    position: absolute;
    font-size: var(--text-xl-px);
    top:0px;
    color: white;
    opacity: 1;
    margin: auto;
    margin-top: 60px;
    text-align: center;
    width: 200px;
    left: calc( 50% - 100px);
    -ms-flex-line-pack: center;
    -moz-flex-line-pack: center;
    align-content: center;
    -webkit-align-content: center;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    display: -ms-flexbox;
    display: -moz-flexbox;
    display: -webkit-box;
    display: flex;
    display: -webkit-flex;
    display: -moz-flex;
    -webkit-align-items: center;
    -ms-flex-align: center;
    -webkit-box-align: center;
    align-items: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-align-items: center;
    -webkit-justify-content: center;
    z-index: 2;
}

.playlistDetailsPage.publicPage:not(.showBackButton) > div[data-role=header]  #header-back-button,
.playlistDetailsPage.publicPage > div[data-role=header]  #pageHeaderTitleWrapper,
.playlistDetailsPage.publicPage > div[data-role=header]  #menuOptionButton{
    display: none;
}

.flexUpperContentWrapper > div.trebbleInfo > .bioWrapper{
    padding: 20px 40px;
    box-sizing:border-box;
    text-align:center;
    position: relative;
    z-index: 2;
    width: 100%;

}
.flexUpperContentWrapper > div.trebbleInfo > .bioWrapper > .textContent {
    padding: 10px 0px;
}

.playlistDetailsPage:not([trailer_available=true]) #playTrailerBtn{
    display: none;
}

.hightlightBtn,
.playlistDetailsPage #playTrailerBtn,
.playlistDetailsPage #followBtn,
.playlistDetailsPage #buyNowBtn,
.playlistDetailsPage #followingBtn,
.playlistDetailsPage #purchasedBtn,
.trebbleSubscribeSuggestionContent #followBtn,
.trebbleSubscribeSuggestionContent #buyNowBtn{
    width: fit-content;
    max-width: 200px;
    text-align: center;
    height: 44px;
    margin: auto;
    z-index: 2;

}

.hightlightBtn,
.playlistDetailsPage #playTrailerBtn,
.playlistDetailsPage #followBtn, 
.playlistDetailsPage #buyNowBtn, 
.playlistDetailsPage #followingBtn,
.playlistDetailsPage #purchasedBtn{
    background-color: white;
    border-color: white;
    color: black !important;
    margin-top: 5px;
    margin-bottom: 5px; 
}

.playlistDetailsPage #purchasedBtn{
    color: white !important;
    background-color: transparent;
    border-color: transparent;
    cursor: default;
    height: 20px;
}

body.browser.mobileweb  .playlistDetailsPage #purchasedBtn:hover,
.playlistDetailsPage #purchasedBtn:active{
   color: white !important;
}

.playlistDetailsPage #playTrailerBtn{
    background-color: transparent;
    border-color: white;
    color: white !important;
    margin-top: 5px;
    margin-bottom: 5px; 
}

.hightlightBtn,
.playlistDetailsPage #playTrailerBtn, 
.playlistDetailsPage #followBtn,
.playlistDetailsPage #buyNowBtn, 
.playlistDetailsPage #followingBtn,
.playlistDetailsPage #purchasedBtn{
    max-width: 300px;
    width: 100%;
}

@media (max-width: 500px), (max-height: 500px) and (orientation: landscape){
    .hightlightBtn,
    .playlistDetailsPage #playTrailerBtn,
    .playlistDetailsPage #followBtn,
    .playlistDetailsPage #buyNowBtn, 
    .playlistDetailsPage #followingBtn,
    .playlistDetailsPage #purchasedBtn{
        max-width: 500px;
        width: 100%;
    }
}

.playlistDetailsPage:not(.journeyPage) .actionButtonWrapper > #playTrailerBtn,
.playlistDetailsPage:not(.journeyPage) .actionButtonWrapper > #followBtn, 
.playlistDetailsPage:not(.journeyPage) .actionButtonWrapper > #buyNowBtn, 
.playlistDetailsPage:not(.journeyPage) .actionButtonWrapper > #followingBtn,
.playlistDetailsPage:not(.journeyPage) .actionButtonWrapper > #purchasedBtn{
    width: calc(100% - 40px);
}

.playlistDetailsPage #followingBtn, 
.playlistDetailsPage #purchasedBtn, 
.trebbleSubscribeSuggestionContent #followBtn,
.trebbleSubscribeSuggestionContent #buyNowBtn{
    padding: 4px 40px;
}
[data-role=page].lighttext .flexUpperContentWrapper > div.trebbleInfo > .bioWrapper,
[data-role=page].lighttext.playlistDetailsPage #contentWrapper {
    color: white;
}

.playlistDetailsPage.journeyPage .left-right-content-padding{
    max-width: 900px;
    margin: auto;
}

.playlistDetailsPage .artistDetailCoverArtWrapper{
  position:absolute;
  width: 100%;
  background-size: 500%;
  /*filter: blur(20px);*/
}
.flexUpperContentWrapper > #fullCoverArtImage,
body.browser > .playlistDetailsPage * div#fullCoverArtImage{
    position:relative;
    top:auto ;
    margin-top: 100px;
}

.flexUpperContentWrapper{ 
    position: relative;
    -ms-flex-direction: column;
    -moz-flex-direction: column;
    -webkit-box-orient: horizontal;
    flex-direction: column;
    -webkit-flex-direction: column;
    display: -ms-flexbox;
    display: -moz-flexbox;
    display: -webkit-box;
    display: flex;
    display: -webkit-flex;
    display: -moz-flex;
}
/*
.playlistDetailsPage .flexUpperContentWrapper > #fullCoverArtImage > .overlay,
.playlistDetailsPage .flexUpperContentWrapper > #fullCoverArtImage > .playButton{
    display: none;
}

.playlistDetailsPage .flexUpperContentWrapper > #fullCoverArtImage:hover > .overlay,
.playlistDetailsPage .flexUpperContentWrapper > #fullCoverArtImage:active > .overlay{
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: var(--color-black-60);
}
.playlistDetailsPage .flexUpperContentWrapper > #fullCoverArtImage:hover > .playButton,
.playlistDetailsPage .flexUpperContentWrapper > #fullCoverArtImage:active > .playButton{

    font-size: var(--text-7xl-px);
    color: white;
    font-weight: bold;
    width: 70px;
    height: 70px;
    -ms-flex-line-pack: center;
    -moz-flex-line-pack: center;
    align-content: center;
    -webkit-align-content: center;
    -ms-flex-direction: column;
    -moz-flex-direction: column;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-direction: column;
    -webkit-flex-direction: column;
    display: -ms-flexbox;
    display: -moz-flexbox;
    display: -webkit-box;
    display: flex;
    display: -webkit-flex;
    display: -moz-flex;
    -webkit-align-items: center;
    -ms-flex-align: center;
    -webkit-box-align: center;
    align-items: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-align-items: center;
    -webkit-justify-content: center;
    border-radius: 50%;
    border: var(--border-2) solid white;
    z-index: 1;

}

*/

.playlistDetailsPage > #contentWrapper > .flexUpperContentWrapper #fullCoverArtImage.nocoverimage{
    background-color: var(--color-white-10);
}
.playlistDetailsPage:not(.publicPage) #fullCoverArtImage.nocoverimage:hover > #editTrebbleCoverPicBtn {
  display:block;
  border: var(--border-0);
}

.playlistDetailsPage .playlistShortcutButtonsWrapper #showStatistics{
    display:none;
}
.WelcomeBetaUserBox{
    display: flex;
    flex-direction: column;
    padding: 30px;
    border: var(--border-4) solid white;
}

.WelcomeBetaUserBox .utterance{
  font-weight: bold;
}

.instructionBox  .utterance{
    color: black;
    font-size: 80%;
    display: block;
    font-weight: normal;
}

.instructionBox  .utterance a{
    text-decoration: underline;
    color: black;
    font-weight: bold;
}
.listeningInstructionBox{

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;

}
.listeningInstructionBox .assistantNameTitle{
    font-size: 80%;
    font-weight: 900;
    margin-bottom:20px;
}

.listeningInstructionBox .instructionBox{
    box-sizing: border-box;
    flex:0 0 50%;
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.listeningInstructionBox .googleAssistantLogo.assistantLogo{

    width: 40px;
    height: 40px;
    margin: 20px 0px;

}

.listeningInstructionBox .bixbyLogo.assistantLogo{

    width: 30px;
    height: 30px;
    margin: 25px 0px;

}

.listeningInstructionBox .alexaLogo.assistantLogo{

    width: 50px;
    height: 50px;
    margin: 15px 0px;


}

.chatbubble,
a.chatbubble,
html .ui-page-theme-a .chatbubble,
html .ui-page-theme-a a.chatbubble{    
    border: var(--border-2) solid white;
    max-width: 300px;
    background: var(--text-light);
    padding: 14px;
    text-align: center;
    font-weight: 900;
    color: var(--text-dark);
    position: relative;
}
.chatbubble.left:before{
    content: "";
    width: 0px;
    height: 0px;
    position: absolute;
    border-left: var(--border-4) solid  var(--text-light);
    border-right: var(--border-4) solid transparent;
    border-top: var(--border-4) solid  var(--text-light);
    border-bottom: var(--border-4) solid transparent;
    left: 19px;
    bottom: -10px;
}
.chatbubble.right:before{
    content: "";
    width: 0px;
    height: 0px;
    position: absolute;
    border-left: var(--border-4) solid transparent;
    border-right: var(--border-4) solid var(--text-light);
    border-top: var(--border-4) solid var(--text-light);
    border-bottom: var(--border-4) solid transparent;
    right: 19px;
    bottom: -10px;
}
.chatbubble.right.small:before{
    bottom: -10px;
    right: 10px;
}
.chatbubble.left.small:before{
    left: 10px;
    bottom: -10px;
}
.chatbubble{
    width: calc(100% - 40px);
}

body.browser.mobileweb .chatbubble.clickable:hover,
.chatbubble.clickable:active{
    background-color:transparent;
    color:white;
    cursor:pointer;
    border-color:white;
}

body.browser.mobileweb [data-role=page]:not(.lighttext) .chatbubble.clickable:hover,
[data-role=page]:not(.lighttext) .chatbubble.clickable:active{
    background-color:transparent;
    color:var(--text-dark);
    cursor:pointer;
    border-color:var(--text-dark);
}


body.browser.mobileweb [data-role=page]:not(.lighttext) .chatbubble.clickable.right:hover:before,
[data-role=page]:not(.lighttext) .chatbubble.clickable.right:active:before{
    border-top-color:var(--text-dark);
    border-right-color:var(--text-dark);
}

body.browser.mobileweb [data-role=page]:not(.lighttext) .chatbubble.clickable.left:hover:before,
[data-role=page]:not(.lighttext) .chatbubble.clickable.left:active:before{
    border-top-color:var(--text-dark);
    border-left-color:var(--text-dark);
}

html .chatbubble.alexaInstruction,
html a.chatbubble.alexaInstruction{
    color: var(--color-blue-500);
}



html .chatbubble.googleInstruction,
html a.chatbubble.googleInstruction{
    color: var(--trebble-primary-dark);
}

.listeningInstructionsBox.transparentChatbubble  .chatbubble{
    background-color: transparent;
    color: white !important;
}

.listeningInstructionsBox[instructiontoshow=all] .listeningInstructionBox .instructionBox .utteranceInstruction,
.listeningInstructionsBox[instructiontoshow=all] .showAllInstructionsButton,
.listeningInstructionsBox  .utteranceAdditionalInstruction{
    display: none;

}
.listeningInstructionsBox[instructiontoshow=all] .instructions{
    height: 90px;
}

.listeningInstructionsBox:not([instructiontoshow=all]) .instructions{
    height: auto;
}


body.mobileweb .listeningInstructionsBox[instructiontoshow=all] .instructionBox:hover,
.listeningInstructionsBox[instructiontoshow=all] .instructionBox:active{
    background-color:var(--color-white-10);
}

.jouney_summary_detail_item {
   display: block;
   text-align: center;
   /*font-size: var(--text-base-px);*/
   margin: 5px 0;
}

.jouney_summary_detail_item .logo_icon{    
    margin-right: 10px;
    margin-left: 10px;
    -webkit-text-stroke-width: 1px;
    display: inline;
}

.jouney_summary_detail_item.number_of_days_info{
    display: none;
}




.listeningInstructionsBox:not([instructiontoshow=all]) .showAllInstructionsButton{
    display: block;
    background-color: transparent;
    border: var(--border-2) solid var(--bg-light);
    color: white;
    padding: 10px;
    font-weight: 900;
    max-width: 170px;
    margin: 10px auto;
    cursor: pointer;
    font-size: 90%;
}

body.mobileweb .listeningInstructionsBox:not([instructiontoshow=all]) .showAllInstructionsButton:hover,
.listeningInstructionsBox:not([instructiontoshow=all]) .showAllInstructionsButton:active{
    background-color: var(--bg-light);
    border: var(--border-2) solid var(--bg-light);
    color: var(--text-dark);
}

.listeningInstructionsBox[instructiontoshow=all] .listeningInstructionBox .instructionBox{
    flex: 0 0 auto;
    min-width: 80px;
}



.listeningInstructionsBox[instructiontoshow=googleassistant] .listeningInstructionBox .instructionBox:not(.googleInstructionBox){
    display: none;
}

.listeningInstructionsBox[instructiontoshow=googleassistant] .listeningInstructionBox .instructionBox.googleInstructionBox{
    flex: 0 0 50%;
}
.listeningInstructionsBox[instructiontoshow=googleassistant] .listeningInstructionBox .instructionBox.googleInstructionBox .utteranceInstruction{
    display: block;
}

.listeningInstructionsBox[instructiontoshow=googleassistant] .utteranceAdditionalInstruction.googleInstructionBox,
.listeningInstructionsBox[instructiontoshow=amazonalexa]  .utteranceAdditionalInstruction.amazonInstructionBox,
.listeningInstructionsBox[instructiontoshow=samsungbixby]  .utteranceAdditionalInstruction.samsungInstructionBox
{
    display: block;
    max-width: 400px;
    margin: auto;
}


.listeningInstructionsBox[instructiontoshow=amazonalexa] .listeningInstructionBox .instructionBox:not(.amazonInstructionBox){
    display: none;
}

.listeningInstructionsBox[instructiontoshow=amazonalexa] .listeningInstructionBox .instructionBox.amazonInstructionBox{
    flex: 0 0 50%;
}
.listeningInstructionsBox[instructiontoshow=amazonalexa] .listeningInstructionBox .instructionBox.amazonInstructionBox .utteranceInstruction{
    display: block;
}





.listeningInstructionsBox[instructiontoshow=samsungbixby] .listeningInstructionBox .instructionBox:not(.samsungInstructionBox){
    display: none;
}

.listeningInstructionsBox[instructiontoshow=samsungbixby] .listeningInstructionBox .instructionBox.samsungInstructionBox{
    flex: 0 0 50%;
}
.listeningInstructionsBox[instructiontoshow=samsungbixby] .listeningInstructionBox .instructionBox.samsungInstructionBox .utteranceInstruction{
    display: block;
}



body.browser.mobileweb .WelcomeBetaUserBox .actionButtonSet .transparent_button:hover,
body.browser.mobileweb .WelcomeBetaUserBox .actionButtonSet .transparent_button:active{
  background-color:transparent;
  color: white !important;
}

.WelcomeBetaUserBox .details > div{
  padding:10px 0px;
}

.WelcomeBetaUserBox .actionButtonSet .transparent_button{
  background-color:white;
  color: var(--text-dark);
}

.WelcomeBetaUserBox #whatsacapsuleBtn span,
.WelcomeBetaUserBox #createMyFirstCapsule span{
    margin-right: 10px;
    font-size: var(--text-xl-px);
}

.WelcomeBetaUserBox .details > div > a{
  color:white;
  text-decoration: underline;
}

.WelcomeBetaUserBox .actionButtonSet{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}
.WelcomeBetaUserBox .actionButtonSet .transparent_button{
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-width: var(--border-3);
    font-size: var(--text-md);
    font-weight:900;
    padding: 0px 40px;
}

@media(max-width: 500px){

 .WelcomeBetaUserBox{
    border:var(--border-0);
    padding: 10px;
}

.WelcomeBetaUserBox .details > div{
    padding:8px 0px;
}

div[data-role=popup].whatsnew .WelcomeBetaUserBox  .details{
    font-size: var(--text-sm-px);
    padding: 8px;
}

div[data-role=popup].whatsnew .WelcomeBetaUserBox > .header{
    font-size: var(--text-xl);
}
.WelcomeBetaUserBox .actionButtonSet {
    flex-direction:column;
}

.WelcomeBetaUserBox .actionButtonSet .transparent_button{
    margin:10px 0px;
}

.listeningInstructionBox .assistantNameTitle{
    margin: 10px;
}


}

.playlistDetailsPage .listeningInstructionsBox .trebbleName {
    /* font-weight:700 */
}
.playlistDetailsPage .listeningInstructionsBox .trebbleName::before{
    content: "\"";
}

.playlistDetailsPage .listeningInstructionsBox .trebbleName::after{
    content: "\"";
}

.playlistDetailsPage .listeningInstructionsBox .utterance .trebbleName::after,
.playlistDetailsPage .listeningInstructionsBox .utterance .trebbleName::before,
.playlistDetailsPage .listeningInstructionsBox .chatbubble .trebbleName::after, 
.playlistDetailsPage .listeningInstructionsBox .chatbubble .trebbleName::before{
    content: "";
}

.playlistDetailsPage .listeningInstructionsBox .supportedAssistants .alexaLogo,
.operationProgressPopupContent .listeningInstructionsBox .supportedAssistants .alexaLogo{

    width: 50px;
    height: 50px;

}

.playlistDetailsPage .listeningInstructionsBox .supportedAssistants .googleAssistantLogo,
.operationProgressPopupContent .listeningInstructionsBox .supportedAssistants .googleAssistantLogo{

    width: 30px;
    height: 30px;
    padding: 10px;


}

@media(min-width: 500px), (min-height: 500px) and (orientation: landscape){
    .operationProgressPopupContent .listeningInstructionsBox .supportedAssistants .alexaLogo{

        width: 100px;
        height: 100px;

    }

    .operationProgressPopupContent .listeningInstructionsBox .supportedAssistants .googleAssistantLogo{

        width: 60px;
        height: 60px;
        padding: 20px;


    }
    .listeningInstructionBox .googleAssistantLogo.assistantLogo{

        width: 50px;
        height: 50px;
        margin: 20px 0px;

    }

    .listeningInstructionBox .bixbyLogo.assistantLogo{

        width: 40px;
        height: 40px;
        margin: 25px 0px;

    }

    .listeningInstructionBox .alexaLogo.assistantLogo{

        width: 60px;
        height: 60px;
        margin: 15px 0px;


    }
}
@media (max-width: 480px), (max-height: 480px) and   (orientation: landscape) {
    .playlistDetailsPage.publicPage:not(.showBackButton) .icon-trebbleLogo{
        font-size: var(--text-xl-px);
    }

    body.browser > .playlistDetailsPage:not(.showBackButton) .flexUpperContentWrapper > #fullCoverArtImage, 
    body.browser > .playlistDetailsPage:not(.showBackButton) * div#fullCoverArtImage {
        margin-top: 100px;
    }

    .flexUpperContentWrapper > #fullCoverArtImage, 
    body.browser > .playlistDetailsPage * div#fullCoverArtImage {

        margin-top: 60px;
    }
    .playlistDetailsPage .description_box_wrapper .playlistDescription{
        padding: 10px 20px 20px;
    }
    /*.playlistDescription > div,*/
    .playlistDetailsPage .listeningInstructionsBox {

        font-size: 90%;

    }

    .flexUpperContentWrapper > div.trebbleInfo > .bioWrapper{
        padding: 20px 20px;
    }
}


#loginPopupContentWrapper > div:first-child{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

}

#loginPopupContentWrapper #loginForm > a.actionButton{
    height: 48px;
    padding: 10px;
    width: auto;
}
#shareOptions {
  max-width: 800px;
  height: calc(100% - 120px);
  text-align: center;
  display: block;
  margin: 60px 30px;
}
#shareOptions .shareOption{

  border: var(--border-3) solid white;
  
  display:inline-flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  box-sizing: border-box;
  
  cursor:pointer;
}

#shareOptions #sharePlayerLinkBtn i.shareOption_icon.pe-7s-phone{
 position: relative;
 display: flex;
 justify-content: center;
 align-items: center;
}

#shareOptions #sharePlayerLinkBtn i.shareOption_icon.pe-7s-phone > i{
 font-size: 30%;
 position: absolute;
 font-weight: 900;
 padding-bottom: 5px;
}



@media (min-width: 800px){
  #shareOptions .shareOption {
    width: 300px;
    height: 300px;
    padding: 20px;
    margin: 20px;
}

.shareOption .shareOption_title {
  font-weight: bold;
  font-size: var(--text-lg-px);
  height: 100px;
}

.shareOption .shareOption_icon {
  font-size: var(--text-7xl-px);
  margin: 10px;
}

}

@media (max-width: 800px){
 #shareOptions .shareOption {
    width: calc(100% - 40px);
    height: auto;
    display: block;
    padding: 40px 20px;
    margin: 20px;
    box-sizing: border-box;
}

.shareOption .shareOption_title {
    font-size: var(--text-md-px);
    font-weight: 600;
    height: 100px;
}
.shareOption .shareOption_icon {
  font-size: var(--text-3xl-px);
  margin: 10px;
}

}


#shareOptions .shareOption:hover,
#shareOptions .shareOption:active {
  background-color: white;
  color: var(--trebble-primary);
}


#shareOptions.showSmartSpeakerInstructions,
#shareOptions.showSubscribePage ,
#shareOptions.showLinkToPlayer ,
#shareOptions.showSendCapsuleLink ,
#shareOptions.showFlashBriefingRSSLink ,
#shareOptions.showEmbedCode,
#shareOptions:not(.showSmartSpeakerInstructions) ~ #shareTrebblePopupContent,
#shareOptions:not(.showSubscribePage) ~ #shareTrebblePopupContent ,
#shareOptions:not(.showLinkToPlayer) ~  #shareTrebblePopupContent ,
#shareOptions:not(.showSendCapsuleLink) ~  #shareTrebblePopupContent ,
#shareOptions:not(.showFlashBriefingRSSLink) ~  #shareTrebblePopupContent ,
#shareOptions:not(.showEmbedCode) ~ #shareTrebblePopupContent  ,
#shareOptions:not(.showSmartSpeakerInstructions) ~ #shareTrebblePopupContent  #shareableGoogleHomeAmazonEchoLinkWrapper,
#shareOptions:not(.showSubscribePage) ~  #shareTrebblePopupContent #shareableSubscribePageLinkWrapper,
#shareOptions:not(.showLinkToPlayer) ~ #shareTrebblePopupContent  #shareableLinkWrapper,
#shareOptions:not(.showSendCapsuleLink) ~ #shareTrebblePopupContent  #sendCapsulePageLinkWrapper,
#shareTrebblePopupContent  #shareableWidgetNewsletterWrapper,
#shareableWidgetNewsletterWrapper[show_embedded_code=true] .htmlTableEmbed,
#shareableWidgetNewsletterColoredWrapper[show_embedded_code=true] .htmlTableEmbed,
#shareableWidgetNewsletterWrapper:not([show_embedded_code=true]) .shareableLink,
#shareableWidgetNewsletterColoredWrapper:not([show_embedded_code=true]) .shareableLink,

#shareableWidgetNewsletterWrapper[show_embedded_code=true] .shareNativeLinkButton,
#shareableWidgetNewsletterColoredWrapper[show_embedded_code=true] .shareNativeLinkButton,
#shareableWidgetNewsletterWrapper[show_embedded_code=true] .showEmbeddedCodeButton,
#shareableWidgetNewsletterColoredWrapper[show_embedded_code=true] .showEmbeddedCodeButton,
#shareableWidgetNewsletterWrapper:not([show_embedded_code=true]) .copyLinkButton,
#shareableWidgetNewsletterColoredWrapper:not([show_embedded_code=true]) .copyLinkButton,
#shareableWidgetNewsletterWrapper:not([show_embedded_code=true]) .showPreviewButton,
#shareableWidgetNewsletterColoredWrapper:not([show_embedded_code=true]) .showPreviewButton,

#shareTrebblePopupContent  #shareableWidgetNewsletterColoredWrapper,
#shareOptions:not(.showFlashBriefingRSSLink) ~ #shareTrebblePopupContent  #flashBriefingRssLinkWrapper,
#shareOptions:not(.showEmbedCode) ~ #shareTrebblePopupContent .newsletterEmbedThemeSelectorWrapper,
#shareOptions:not(.showEmbedCode) ~ #shareTrebblePopupContent  #trebblePlayerFooterAlwaysVisibleWidgetWrapper,
#shareOptions:not(.showEmbedCode) ~ #shareTrebblePopupContent   #shareableWidgetLandscapeWrapper,
#shareOptions:not(.showEmbedCode) ~ #shareTrebblePopupContent  #shareableWidgetSquareWrapper{
    display: none;
}


#shareOptions.showEmbedCode ~ #shareTrebblePopupContent[newsletter_theme=transparent]   #shareableWidgetNewsletterWrapper,
#shareOptions.showEmbedCode ~ #shareTrebblePopupContent[newsletter_theme=coloured]   #shareableWidgetNewsletterColoredWrapper,
#shareOptions.showSmartSpeakerInstructions ~ #shareTrebblePopupContent,
#shareOptions.showSubscribePage ~ #shareTrebblePopupContent ,
#shareOptions.showLinkToPlayer ~  #shareTrebblePopupContent,
#shareOptions.showSendCapsuleLink ~  #shareTrebblePopupContent,
#shareOptions.showFlashBriefingRSSLink ~  #shareTrebblePopupContent,
#shareOptions.showEmbedCode ~  #shareTrebblePopupContent {
    display: block;
}

.shareOption_icon.pe-7s-signal,
#onboardingSignupPage .chooseProfileTypeWrapper a.actionButton i.pe-7s-signal{
    transform: rotate(45deg);
}

#shareOptions.showSubscribePage ~ #shareableSubscribePageLinkWrapper{
    display: block;
}


#shareOptions.showLinkToPlayer ~ #shareableLinkWrapper{
    display: block;
}


#shareOptions.showSendCapsuleLink ~ #sendCapsulePageLinkWrapper{
    display: block;
}

#shareOptions.showFlashBriefingRSSLink ~ #flashBriefingRssLinkWrapper{
    display: block;
}


#shareOptions.showEmbedCode ~ #trebblePlayerFooterAlwaysVisibleWidgetWrapper,
#shareOptions.showEmbedCode ~ #shareableWidgetLandscapeWrapper,
#shareOptions.showEmbedCode ~ #shareableWidgetSquareWrapper{
    display: block;
}

#shareOptions.showSmartSpeakerInstructions ~ #shareableGoogleHomeAmazonEchoLinkWrapper{
    display: block;
}


#shareOptions.showSubscribePage ~ #shareableSubscribePageLinkWrapper{
    display: block;
}


#shareOptions.showLinkToPlayer ~ #shareableLinkWrapper{
    display: block;
}



#shareOptions.showEmbedCode ~ #trebblePlayerFooterAlwaysVisibleWidgetWrapper,
#shareOptions.showEmbedCode ~ #shareableWidgetLandscapeWrapper,
#shareOptions.showEmbedCode ~ #shareableWidgetSquareWrapper{
    display: block;
}


.operationProgressPopupContent[pending-operation-state=pending] #successBoxWrapper,
.operationProgressPopupContent[pending-operation-state=pending] #errorBoxWrapper,
.operationProgressPopupContent[pending-operation-state=success] #loadingBoxWrapper,
.operationProgressPopupContent[pending-operation-state=success] #errorBoxWrapper,
.operationProgressPopupContent[pending-operation-state=error] #loadingBoxWrapper,
.operationProgressPopupContent[pending-operation-state=error] #successBoxWrapper{
    display: none;
}



.operationProgressPopupContent[pending-operation-state=pending] #loadingBoxWrapper,
.operationProgressPopupContent[pending-operation-state=success] #successBoxWrapper,
.operationProgressPopupContent[pending-operation-state=error] #errorBoxWrapper{
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
}
#OperationProgressPage .ui-panel-wrapper{
    background-color: white;
}
#successBoxWrapper .wrapper,
#errorBoxWrapper .wrapper{
    overflow: auto;
}
@media (min-width: 500px){
    .operationProgressPopupContent[pending-operation-state=pending] #loadingBoxWrapper .message,
    .operationProgressPopupContent[pending-operation-state=success] #successBoxWrapper .message,
    .operationProgressPopupContent[pending-operation-state=error] #errorBoxWrapper .message{
        font-size: var(--text-3xl-px);
        font-weight: 900;
        max-width: 700px;
        text-align: center;
    }

    .operationProgressPopupContent[pending-operation-state=pending] #loadingBoxWrapper .progressContentWrapper,
    [data-role=popup] .progressContentWrapper{
        min-width: 500px;
    }

    



    .operationProgressPopupContent[pending-operation-state=pending] #loadingBoxWrapper .messageDetails,
    .operationProgressPopupContent[pending-operation-state=success] #successBoxWrapper .messageDetails,
    .operationProgressPopupContent[pending-operation-state=error] #errorBoxWrapper .messageDetails{
        max-width: 700px;
        text-align: center;
        font-size: var(--text-lg-px);
        padding: 20px;
        box-sizing: border-box;
    }

}


body.browser .operationProgressPopupContent #loadingImg{
  border: var(--border-2) solid var(--color-white-20);
  border-top-color: var(--color-white-90);

}

.operationProgressPopupContent .utterance{
  font-weight:bold;
}
a.utterance,
.playlistDetailsPage  .listeningInstructionsBox a.utterance{
  color: white ;
}
.operationProgressPopupContent{
    padding: 20px;
    height: 100%;
    
    box-sizing: border-box;

}

.playlistDetailsPage:not(.publicPage) .loggedInUserAvatar{
    display: none;
}

.playlistDetailsPage.publicPage .loggedInUserAvatar{
    display: block;
    position: absolute;
    top: 5px;
    left: 15px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    cursor: pointer;
    object-fit: cover;
    box-shadow: 0 0 50px var(--color-black-30);
    display: none;
}

.playlistDetailsPage.publicPage .loggedInUserAvatar.noAvatarImage{
    font-size: var(--text-5xl-px);
    box-shadow: none;
}

.playlistDetailsPage.lighttext.publicPage .loggedInUserAvatar.noAvatarImage{
    color: white;
}

@media (max-width: 500px), (max-height: 500px) and (orientation: landscape){
    .operationProgressPopupContent[pending-operation-state=pending] #loadingBoxWrapper .message,
    .operationProgressPopupContent[pending-operation-state=success] #successBoxWrapper .message,
    .operationProgressPopupContent[pending-operation-state=error] #errorBoxWrapper .message{
        font-size: var(--text-lg-px);
        margin-top: 30px;
        font-weight: 900;
        max-width: 700px;
        text-align: center;
    }



    .operationProgressPopupContent[pending-operation-state=pending] #loadingBoxWrapper .messageDetails,
    .operationProgressPopupContent[pending-operation-state=success] #successBoxWrapper .messageDetails,
    .operationProgressPopupContent[pending-operation-state=error] #errorBoxWrapper .messageDetails{
        max-width: 700px;
        text-align: center;
        font-size: var(--text-xs-px);
        padding: 10px 0px;
        box-sizing: border-box;
    }

    div[data-role=popup] .chatbubble{
        font-size: var(--text-sm-px);
    }

    .operationProgressPopupContent .listeningInstructionsBox, .playlistDetailsPage .listeningInstructionsBox{
        padding: 12px 6px;
    }

}

input+label:after{
  white-space:nowrap;
}


#schedulingSettingFld{
    color: white;
    text-align: center;
}
#schedulingSettingFld{
    height: 40px;
    background-color: var(--color-white-10);
    cursor: pointer;
}

.scheduleInfoWrapper{
    display: flex;
    flex-direction: row;
    max-width: 700px;
    margin: auto;
}

@media (max-width: 500px), (max-height: 500px) and (orientation: landscape){
    .scheduleInfoWrapper{
        flex-direction: column;
    }
}

.clockpicker-display{
    font-weight: 700;
}

.clockpicker-canvas-bearing {
  stroke: none;
  fill: var(--trebble-primary);
}

.clockpicker-canvas-bg {
  stroke: none;
  fill: var(--trebble-primary);
  color: white;
}

.clockpicker-canvas-bg-trans {
  fill:  var(--trebble-primary);
}

.clockpicker-canvas line {
  stroke: var(--trebble-primary);
  stroke-width: 4;
  stroke-linecap: round;
}
.clockpicker-tick.active, .clockpicker-tick:hover{
    background-color: var(--trebble-primary-25);
}
.picker__holder{
    max-width: 100%;
    max-height: 100%;
}

.picker__year-display,
.picker__weekday-display,
.picker__month-display,
.picker__day-display{
    font-weight: 900;
}

.picker__date-display{
    /*background-color: var(--trebble-primary);*/
    background-color: var(--text-dark);

}

.picker__close, .picker__today, .picker__clear{
    color: var(--trebble-primary);
    font-weight: 700;
}
.picker__day--selected, .picker__day--selected:hover, .picker--focused .picker__day--selected{
  background-color: var(--trebble-primary);
  font-weight: 700;
  width: 40px;
  height: 40px;
  padding: 0px;
  align-items: center;
  display: flex;
  justify-content: center;
}

.picker__nav--prev:hover, .picker__nav--next:hover{

    background-color: var(--color-black-5);
}




.picker__box{
  border: var(--border-0);
}
.picker__close:hover, .picker__today:hover, .picker__clear:hover,
button.picker__today:focus, button.picker__clear:focus, button.picker__close:focus{
  background-color: var(--color-black-5);
}
.picker__day.picker__day--today{
    color: var(--trebble-primary);
}

.picker__nav--prev, .picker__nav--next{
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}



#addOrScheduleCapsuleToTrebbleFormWrapper label,
#recordingSettingFormWrapper label{
    text-align: left;
}


#addOrScheduleCapsuleToTrebbleFormWrapper  #addToTrebbleBtn,
#recordingSettingFormWrapper  #saveBtn{
    border: var(--border-2) solid white;
    background-color: white;
    font-size: var(--text-md);
    font-weight: 900;
    min-width: 140px;
}

.signup_fine_print{
 font-size: var(--text-2xs-px);
 margin: 10px auto;
 padding: 10px;
 opacity: .4;
 max-width: 400px;
 text-align: center;
 width: calc(100% - 50px);
}


#mySubscriptions #addTrebbleToSubscriptionsBtn,
#myJourneys #createJourneyBtn{
    border: var(--border-0);
}


[data-role="page"][id*=followedTrebblesPageFor] .playlistItem.withLargeAlbumArt,
#ExplorePage[showListView=true]  .playlistItem.withLargeAlbumArt,
#Onboarding[showListView=true]  .playlistItem.withLargeAlbumArt,
#mySubscriptionsListWrapper  .playlistItem.withLargeAlbumArt,
#myJourneysListWrapper  .playlistItem.withLargeAlbumArt{
    display: inline-table;
}

@media(max-width: 500px), (max-height: 500px) and (orientation: landscape){
    [data-role="page"][id*=followedTrebblesPageFor] .playlistItem.withLargeAlbumArt,
    #ExplorePage[showListView=true]  .playlistItem.withLargeAlbumArt,
    #Onboarding[showListView=true]  .playlistItem.withLargeAlbumArt,
    #mySubscriptionsListWrapper  .playlistItem.withLargeAlbumArt,
    #myJourneysListWrapper  .playlistItem.withLargeAlbumArt{
        display: inline-flex;
        flex-direction: column;
    }

}
/*#mySubscriptionsListWrapper #collection_repeat_container,*/
#ExplorePage[showListView=true] #collection_repeat_container,
#Onboarding[showListView=true] #collection_repeat_container{
    padding: 20px 0px;

}
#mySubscriptionsListWrapper,
#myJourneysListWrapper,
#ExplorePage[showListView=true],
#Onboarding[showListView=true] {
    text-align:center;
}

@media (max-width: 500px), (max-height: 500px) and (orientation: landscape){
    #mySubscriptionsListWrapper  .playlistItem.withLargeAlbumArt,
    #myJourneysListWrapper  .playlistItem.withLargeAlbumArt,
    #ExplorePage[showListView=true]  .playlistItem.withLargeAlbumArt,
    #Onboarding[showListView=true]  .playlistItem.withLargeAlbumArt{
      width: calc( 100% - 20px)
  }
}


.listening_to_player_events #loadingMyFeedButton,
/*.listening_to_player_events.isCurrentlyLoading #playMyFeedBtn,
.listening_to_player_events.isCurrentlyLoading #playMyFeedBtn .loadingIconWrapper,*/
.listening_to_player_events.isCurrentlyLoading .play_feed_btn,
.listening_to_player_events.isCurrentlyLoading .play_feed_btn .loadingIconWrapper{
    cursor: default;
}
body.browser.mobileweb #mySubscriptions #loadingMyFeedButton:hover,
#mySubscriptions #loadingMyFeedButton:active,
body.browser.mobileweb .listening_to_player_events #loadingMyFeedButton:hover,
.listening_to_player_events #loadingMyFeedButton:active{
    color: var(--text-dark) !important;
}

.listening_to_player_events:not(.isCurrentlyPlaying):not(.isCurrentlyLoading) .play_feed_btn .loadingIcon,
.listening_to_player_events:not(.isCurrentlyPlaying):not(.isCurrentlyLoading) .play_feed_btn .loadingIconWrapper,
.listening_to_player_events:not(.isCurrentlyPlaying):not(.isCurrentlyLoading) .play_feed_btn .pauseIcon,
.listening_to_player_events:not(.isCurrentlyPlaying):not(.isCurrentlyLoading) .play_feed_btn .pauseIconWrapper,
.listening_to_player_events:not(.isCurrentlyPlaying):not(.isCurrentlyLoading) #loadingMyFeedButton,
.listening_to_player_events:not(.isCurrentlyPlaying):not(.isCurrentlyLoading) #pauseMyFeedButton{
    display: none;
}


.listening_to_player_events.isCurrentlyPlaying .play_feed_btn .loadingIcon,
.listening_to_player_events.isCurrentlyPlaying .play_feed_btn .loadingIconWrapper,
.listening_to_player_events.isCurrentlyPlaying .play_feed_btn .playIcon,
.listening_to_player_events.isCurrentlyPlaying .play_feed_btn .playIconWrapper,
.listening_to_player_events.isCurrentlyPlaying #loadingMyFeedButton,
.listening_to_player_events.isCurrentlyPlaying #playMyFeedButton{
    display: none;
}

.listening_to_player_events.isCurrentlyLoading .play_feed_btn .pauseIcon,
.listening_to_player_events.isCurrentlyLoading .play_feed_btn .pauseIconWrapper,
.listening_to_player_events.isCurrentlyLoading .play_feed_btn .playIcon,
.listening_to_player_events.isCurrentlyLoading .play_feed_btn .playIconWrapper,
.listening_to_player_events.isCurrentlyLoading #pauseMyFeedButton,
.listening_to_player_events.isCurrentlyLoading #playMyFeedButton{
    display: none;
}

#home[state=content_loaded] #homeHeader #pageHeaderTitleWrapper,
#home[state=loading] #homeHeader #pageHeaderTitleWrapper{
    color: var(--text-dark);
}
#home #onYourFeedTodayBoxWrapper  .play_feed_btn,
#home #onYourFeedTodayBoxWrapper  .play_feed_btn .playback_state_btn{
    height: 160px;
    width: 160px;
    flex: 0 0 160px;
}
#home #onYourFeedTodayBoxWrapper  .play_feed_btn{
    border-radius: 50%;
}

#home #onYourFeedTodayBoxWrapper  .play_feed_btn .playback_state_btn t{
    font-size: var(--text-7xl-px);
}



#home #onYourFeedTodayBoxWrapper  .play_feed_btn .playback_state_btn t.playIcon{
    padding-left: 10px;
}

#home #onYourFeedTodayBoxWrapper  .play_feed_btn .playback_state_btn > span{
    display: none;
}
#home #onYourFeedTodayBoxWrapper .trebble_thumnails_preview_box{
    /*height: 100px;
    flex: 0 0 100px;
    margin: 20px 0px;    
    display: block;
    justify-content: center;
    align-items: center;
    height: 90px;
    margin: 0px;
    margin-bottom: 30px;*/

    flex: 0 0 100px;
    display: block;
    align-items: center;
    height: 90px;
    margin: 0 0 30px;
    max-width: 690px;


}

#home [data-role=header],
#home .ui-content,
#home .ui-panel-wrapper{
    background-color: transparent;
    border-color: transparent;
    padding: 0px;
}

#home #onYourFeedTodayBoxWrapper .trebble_thumnails_preview_box .image_wrapper{
    /*height: 83px;
    width: 83px;*/
    height: 78px;
    width: 78px;
    flex: 0 0 78px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: var(--gradient-primary-alt);
    margin: 0px 4px;

}
#home #onYourFeedTodayBoxWrapper .trebble_thumnails_preview_box .image_wrapper img{
    /*width: 70px;
    height: 70px;*/
    width: 62px;
    height: 62px;
    /* margin: 0 4px; */
    border: var(--border-4) solid white;
}

body.darkTheme #home #onYourFeedTodayBoxWrapper .trebble_thumnails_preview_box img{
    border: var(--border-4) solid var(--text-dark);
}

#home #onYourFeedTodayBoxWrapper .play_feed_btn,
#home #onYourFeedTodayBoxWrapper .start_category_shortcast_radio_btn{
    color: white;
}




#home #onYourFeedTodayBoxWrapper{
    background: transparent;
    box-shadow: none;
    color: var(--text-dark);
    padding: 0px;
    max-width: 100%;
}

#home[state=no_new_content]{
    background: linear-gradient(to bottom right,var(--success-green),var(--success-green-alt));
    color: var(--text-light);
    background-size: 200% 200%;
    animation: gradientanimation 30s ease infinite;
    animation: gradientanimation 30s ease infinite;
}
#home[state=error]{
    background: var(--gradient-primary);
    color: var(--text-light);
    background-size: 200% 200%;
    animation: gradientanimation 30s ease infinite;
    animation: gradientanimation 30s ease infinite;
}

#home[state=no_new_content]{
    background: linear-gradient(to bottom right,var(--success-green),var(--success-green-alt));
    color: var(--text-light);
    background-size: 200% 200%;
    animation: gradientanimation 30s ease infinite;
    animation: gradientanimation 30s ease infinite;
}
#home[state=no_new_content] #onYourFeedTodayBoxWrapper[state=no_new_content],
#home[state=error] #onYourFeedTodayBoxWrapper[state=error]{
    background: transparent;
    box-shadow: none;
    color: var(--text-light);
}

#home #onYourFeedTodayBoxWrapper{
    height: calc(100% - 50px);
}


#home #onYourFeedTodayBoxWrapper  .play_feed_btn,
#home #onYourFeedTodayBoxWrapper .start_category_shortcast_radio_btn{
    background: var(--gradient-primary-alt);
    box-shadow: 0 5px 20px var(--color-black-20);
    background-size: 200% 200%;
    margin-top: 20px;
    border:var(--border-0);
}
#home #onYourFeedTodayBoxWrapper  .play_feed_btn,
.journeyPage #createNewCapsuleFloatingBtnWrapper{
    animation: gradientanimation 10s ease infinite, pulse 1.5s infinite;
    animation: gradientanimation 10s ease infinite, pulse 1.5s infinite;
}

#home #onYourFeedTodayBoxWrapper .start_category_shortcast_radio_btn{
    height: 50px;
    flex:  0 0 50px;
    margin: 50px;
    box-sizing: border-box;
    padding: 20px 40px;
    min-width: 200px;
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    animation: gradientanimation 10s ease infinite;
    animation: gradientanimation 10s ease infinite;
}

#home #onYourFeedTodayBoxWrapper .start_category_shortcast_radio_btn t{
    margin-right: 10px;
    font-weight: bold;
    font-size: var(--text-xl);
}
.start_category_shortcast_radio_content_wrapper #pageHeaderTitleWrapper{
    line-height: 1.4em;
    max-width: 700px;
    white-space: normal;
    flex: 0 0 auto;
    height: auto;
    padding: 0px;
    padding-bottom: 30px;
    box-sizing: border-box;
    position: relative;
}


.start_category_shortcast_radio_content_wrapper .category_list_wrapper{

    flex: 1 1 100%;


}

.start_category_shortcast_radio_content_wrapper{
    height: calc(100% - 100px);
}
.start_category_shortcast_radio_content_wrapper[isLoading=false] .content{
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    display: flex;
    height: 100%;

}

.start_category_shortcast_radio_content_wrapper[isLoading=false] .loading_info_box,
.start_category_shortcast_radio_content_wrapper:not([isLoading=false]) .content{
    display: none;
}

.start_category_shortcast_radio_content_wrapper:not([isLoading=false]) .loading_info_box{
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

}

.start_category_shortcast_radio_content_wrapper .start_radio_button_wrapper{
    visibility: hidden;
}

#home #onYourFeedTodayBoxWrapper[state=content_loaded] > div.feedOverviewBox{
    min-height: calc(100% - 50px);
    justify-content: start;
    height: 100%;
}
#home .feedOverviewBox .feed_description{
    display: none;
}

.feedOverviewBox .bottomSpacer{
    width: 100%;
    flex: 0 0 100px;
}

#playerFloating{
    position: absolute;
    bottom: 80px;
    right: 20px;
    z-index: 1000;
    border-radius: 50px;
    cursor: pointer;
    background-color: var(--color-purple-400);
    border:var(--border-0);
    background-size: cover;
    box-shadow: 0 5px 11px -2px var(--color-black-30), 0 4px 12px -7px var(--color-black-30);
}

#playerFloating.useblurryBackgroundImage,
#playerFloating.useDefaultGradientColorForBackground{
    background: var(--gradient-pink-purple);
}

#playerFooter .menuBox{
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: center;

}

#playerFooter .menuBox .panelMenuItem{
   flex: 1 1 100%;
   align-items: center;
   justify-content: center;
   display: flex;
   cursor: pointer;
   flex-direction: column;
   height: 100%;
}
#playerFooter .menuBox{
    color: var(--text-dark);
}
#playerFooter .menuBox .panelMenuItem .menuIcon{
    font-size: var(--text-2xl-px);
}

#playerFooter .menuBox .panelMenuItem .menuIcon,
#playerFooter .menuBox .panelMenuItem .menuIcon c{
    font-weight: 900;
}

#playerFooter .menuBox .panelMenuItem .menuLabel{
    margin-top: 4px;
    font-size: var(--text-2xs-px);
    font-weight: 400;
}


#playerFooter .menuBox .panelMenuItem.selected,
#playerFooter.transparent .menuBox .panelMenuItem.selected{
    color: var(--trebble-primary);
}


body.darkTheme #playerFooter.footer_menu:not(.transparent) .menuBox ,
[data-role=page].ui-page-active.darkTheme ~ #playerFooter.footer_menu:not(.transparent) .menuBox  {
   color: white;
}

#playerFooter.footer_menu{
    box-shadow: 0 -3px 8px 0 var(--overlay-gray-05);
    border-top:var(--border-0);
}

#playerFooter.transparent .menuBox .panelMenuItem{
    color: white;
}
body.footerMenuBardisplayed:not([userprofiletype=creator]):not(.alwaysDisplayHelp):not([userprofiletype=journey_creator]):not([userprofiletype=shortcast_creator]) [data-role=header] #helpHeaderButton,
body.footerMenuBardisplayed:not([userprofiletype=creator]):not([userprofiletype=journey_creator]):not([userprofiletype=shortcast_creator]) [data-role=header] #homeHeaderButton,
body.footerMenuBardisplayed:not([userprofiletype=creator]):not(.alwaysDisplayHelp):not([userprofiletype=journey_creator]):not([userprofiletype=shortcast_creator]) [data-role=header] .homeButtonWrapper{
    display: none;
}

body.footerMenuBardisplayed:not([userprofiletype=creator]):not(.alwaysDisplayHelp):not([userprofiletype=journey_creator]):not([userprofiletype=shortcast_creator]) [data-role=header] #pageHeaderTitleWrapper{
    padding-left: 20px;
}

body[playerfootershouldbetransparent=false]:not(darkTheme){
    background-color: white !important;
}

body[playerfootershouldbetransparent=true]{
    background-color: var(--color-black) !important;
}
body[selected_home_menu=homeBtn] [data-role=footer] #homeBtn,
body[selected_home_menu=mySubscriptionsBtn] [data-role=footer] #mySubscriptionsBtn,
body[selected_home_menu=myJourneysBtn] [data-role=footer] #myJourneysBtn,
body[selected_home_menu=exploreBtn] [data-role=footer] #exploreBtn,
body[selected_home_menu=searchBtn] [data-role=footer] #searchBtn,
body[selected_home_menu=myProfileBtn] [data-role=footer] #myProfileBtn{
    color: var(--trebble-primary);
}

body[selected_home_menu=homeBtn]:not(.darkTheme) ,
body[selected_home_menu=mySubscriptionsBtn]:not(.darkTheme) ,
body[selected_home_menu=searchBtn]:not(.darkTheme){
    background-color: white !important;
    background-image: none !important;
}

body[selected_home_menu=myProfileBtn],
body[selected_home_menu=exploreBtn],
body[selected_home_menu=homeBtn].darkTheme ,
body[selected_home_menu=mySubscriptionsBtn].darkTheme,
body[selected_home_menu=searchBtn].darkTheme{
    background-color: var(--color-black) !important;
    background-image: none !important;
}


body.footerMenuBardisplayed[userprofiletype=creator] div[data-role=panel] #homeBtn,
body.footerMenuBardisplayed[userprofiletype=journey_creator] div[data-role=panel] #homeBtn,
body.footerMenuBardisplayed[userprofiletype=shortcast_creator] div[data-role=panel] #homeBtn,
body.footerMenuBardisplayed[userprofiletype=creator] div[data-role=panel] #mySubscriptionsBtn,
body.footerMenuBardisplayed[userprofiletype=journey_creator] div[data-role=panel] #mySubscriptionsBtn,
body.footerMenuBardisplayed[userprofiletype=shortcast_creator] div[data-role=panel] #mySubscriptionsBtn,
body.footerMenuBardisplayed[userprofiletype=creator] div[data-role=panel] #exploreBtn,
body.footerMenuBardisplayed[userprofiletype=journey_creator] div[data-role=panel] #exploreBtn,
body.footerMenuBardisplayed[userprofiletype=shortcast_creator] div[data-role=panel] #exploreBtn,
body.footerMenuBardisplayed[userprofiletype=creator] div[data-role=panel] #searchBtn,
body.footerMenuBardisplayed[userprofiletype=journey_creator] div[data-role=panel] #searchBtn,
body.footerMenuBardisplayed[userprofiletype=shortcast_creator] div[data-role=panel] #searchBtn,
body.footerMenuBardisplayed[userprofiletype=creator] div[data-role=panel] #referBtn,
body.footerMenuBardisplayed[userprofiletype=journey_creator] div[data-role=panel] #referBtn,
body.footerMenuBardisplayed[userprofiletype=shortcast_creator] div[data-role=panel] #referBtn{
    display: none !important;
}

#playerFloating.useMovingBackground{
    background-size: 200% 200% !important;
    animation: gradientanimation 20s ease infinite;
    animation: gradientanimation 20s ease infinite;
}

#playerFloating .dark_shade_background{
    position: absolute;
    z-index: -1;
    width: 100%;
    background-color: var(--color-black-50);
    height: 100%;
    border-radius: 50px;
    border:var(--border-0);
    
}
#playerFloating:not(.useblurryBackgroundImage) .dark_shade_background{
    display: none;
}

#playerFloating .spacerBackground{
    position: absolute;
    /*background-color: var(--color-purple-400);*/
    background-color: transparent;
    top: 0px;
    left: 35px;
    height: 70px;
    z-index: -1;
    width: 70px;
    cursor: pointer;
}

#playerFloating #footerCurrentSongInfo{
    display: none !important;
}

#playerFloating .coverArtWrapper{

    padding: 10px;
    box-sizing: border-box;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    display: flex;
    height: 70px;
    width: 70px;
    /*background-color: var(--color-purple-400);*/
    background-color: transparent;

}

#playerFloating #footerCurrentSongImg{
    width: 40px;
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
    height: 40px;
    border-radius: 50%;
    background-size: cover;
    cursor: pointer;
    background-color: var(--color-white-30);
}


#playerFloating .spacer{
    width: 0px;
    cursor: pointer;
}

#playerFloating .actionButtonWrapper{
    padding: 10px;
    width: 70px;
    box-sizing: border-box;
    height: 70px;
    border-radius: 50%;
    /*background-color: var(--color-purple-400);*/
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

#playerFloating .actionButtonWrapper #playPauseBtn{
    font-size: var(--text-3xl-px);
    padding: 0px;
    margin: 0px;
    cursor: pointer;
}


body.mobileweb #playerFloating .actionButtonWrapper #playPauseBtn:hover,
#playerFloating .actionButtonWrapper #playPauseBtn:active{
    background-color: transparent;
    border-color: transparent;
}

@media (max-width: 500px){

    #ExplorePage.threeSelectorDisplayed * #filtersWrapper,
    #Onboarding.threeSelectorDisplayed * #filtersWrapper {
        overflow-x:scroll;
    }

    #ExplorePage.threeSelectorDisplayed * #filtersWrapper,
    #Onboarding.threeSelectorDisplayed * #filtersWrapper{
        justify-content: start;
    }

    #ExplorePage * #filtersWrapper > div,
    #Onboarding * #filtersWrapper > div,
    .StatsPage * .CustomSelectFieldView, 
    .TrebbleStatisticPage.lighttext * .CustomSelectFieldView,
    #ExplorePage[showListView=true] * .CustomSelectFieldView,
    #Onboarding[showListView=true] * .CustomSelectFieldView{
        font-size: var(--text-sm-px);
        padding-left: 10px;
        margin: 0px 10px;
    }

    #home[lessThanFourShortcastsInFeed=false] #onYourFeedTodayBoxWrapper .trebble_thumnails_preview_box{
        justify-content: start;
    }

    #home[lessThanFourShortcastsInFeed=false] #onYourFeedTodayBoxWrapper .trebble_thumnails_preview_box .image_wrapper:first-child{
       margin-left: 20px;
   }

   #home[lessThanFourShortcastsInFeed=false] #onYourFeedTodayBoxWrapper .trebble_thumnails_preview_box .image_wrapper:last-child{
       margin-right: 20px;
   }

}

.userProfileDetailsPage .textContent .socialInfos,
body[isTemporaryUser=true] #ExplorePage [data-role=header] > div:first-child,
body[isTemporaryUser=true] #Onboarding [data-role=header] > div:first-child,
body[isTemporaryUser=true] #homeHeaderButton,
body[isTemporaryUser=true] .capsuleCard #addToTrebbleOptionButtonWrapper,
body[isTemporaryUser=true] .capsuleCard #deleteButton,
body[isTemporaryUser=true] .capsuleCard #commentButton,
/*body[userprofiletype=creator]:not([journey_feature_enabled=true]) div[data-role=panel] #myJourneysBtn,
body[userprofiletype=journey_creator]:not([journey_feature_enabled=true]) div[data-role=panel] #myJourneysBtn,*/
body[userprofiletype=listener] div[data-role=panel] #myJourneysBtn,
body[userprofiletype=shortcast_creator] div[data-role=panel] #myJourneysBtn,
body[userProfileType=listener] .homePanel * .menuBox #trebblesBtn,
body[userProfileType=journey_creator] .homePanel * .menuBox #trebblesBtn,
body[userProfileType=shortcast_creator] .homePanel * .menuBox #trebblesBtn,
body[userProfileType=listener] .userProfileDetailsPage.loggedInUserProfilePage #userProfileWrapper,
body[userProfileType=listener] .userProfileDetailsPage.loggedInUserProfilePage .textContent,
body[userProfileType=listener] .userProfileDetailsPage.loggedInUserProfilePage #shareUserCmailUrlBtn,
body[userProfileType=listener] .homePanel * .menuBox #myLibraryBtn,
/*body[userProfileType=journey_creator] .homePanel * .menuBox #myLibraryBtn,*/
body[userprofiletype="listener"] .capsuleCard > .ownerInfo > .buttonGroup > i#addToTrebbleOptionButtonWrapper,
body:not([userprofiletype="creator"]) #CapsuleOptionPopup #addToChannel,
body:not([userprofiletype="creator"]) #CapsuleOptionPopup #removeFromChannel,
body[userprofiletype="journey_creator"] .capsuleCard > .ownerInfo > .buttonGroup > i#addToTrebbleOptionButtonWrapper,
body[userprofiletype="shortcast_creator"] .capsuleCard > .ownerInfo > .buttonGroup > i#addToTrebbleOptionButtonWrapper,
body:not([userProfileType=listener]) .homePanel * .menuBox #createMyOwnTrebbleBtn:not(.forceDisplay),
body:not([userProfileType=journey_creator]) .homePanel * .menuBox #createMyOwnTrebbleBtn:not(.forceDisplay),
body:not([userProfileType=shortcast_creator]) .homePanel * .menuBox #createMyOwnTrebbleBtn:not(.forceDisplay),
body:not([userProfileType=listener]) .homePanel * .menuBox #createMyFirstJourneyBtn:not(.forceDisplay),
body:not([journey_feature_enabled=true]) .homePanel * .menuBox #myJourneysBtn,
/*body:not([journey_feature_enabled=true]) #onboardingSignupPage #iAmAJourneyCreatorBtn,*/
body:not([journey_feature_enabled=true]) #settings #createYourFirstJourneyLnk{
    display: none !important;
}

body[userprofiletype=shortcast_creator] div[data-role=panel] #mySubscriptionsBtn,
body[userprofiletype=shortcast_creator] div[data-role=panel] #exploreBtn,
body[userprofiletype=shortcast_creator] div[data-role=panel] #homeBtn,
body[userprofiletype=shortcast_creator] div[data-role=panel] #searchBtn{
    display: none!important;
}



body .homePanel * .menuBox #createMyOwnTrebbleBtn:not(.forceDisplay),
body .homePanel * .menuBox #createMyFirstJourneyBtn:not(.forceDisplay){
    display: none !important;
}
.ui-content  > .loginFormWrapper,
.ui-content  > .signupFormWrapper{
    display: flex;
    flex-direction: column;
    overflow: auto;
    width: 100%;
}

.ui-content  > .loginFormWrapper{   
    max-width: 400px;
}

.loginFormWrapper:not(.hideLoginForm) #showEmailLoginFormBtn,
.loginFormWrapper.hideLoginForm #usernameField,
.loginFormWrapper.hideLoginForm #passwordFieldWrapper,
.loginFormWrapper.hideLoginForm #orField,
.loginFormWrapper.hideLoginForm #LoginBtn,
.loginFormWrapper.hideLoginForm .forgotPasswordLink,
.loginFormWrapper.hideLoginForm #dontHaveAnAccountLabel,
.loginFormWrapper.hideLoginForm #SignUpBtn,
.loginFormWrapper.hideLoginForm .twoButtonsSet,
.loginFormWrapper.hideLoginForm #UpdateGuessProfileBtn{
    display: none !important;
}

#songPoppupMenu-popup #songPoppupMenu>div>.ui-content#commentListPopupContent{
    border: var(--border-0);
}


body.isPreviewPlaying #previewPlayingButton{
 background-color:var(--trebble-primary);
 border-radius: 50%;
 width: 50px;
 height: 50px;
 z-index: 99999;
 position: absolute;
 right: 20px;
 top: 80px;
 display: block;
 cursor: pointer;
 box-shadow: 0 5px 11px -2px var(--color-black-30), 0 4px 12px -7px var(--color-black-30);
}

body:not(.isPreviewPlaying) #previewPlayingButton{
    display: none;
}

#previewPlayingButton .soundPlayingIconWrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

#previewPlayingButton .soundPlayingIconWrapper #soundbars{
    position: relative;
    left: auto;
    display: block;
    height: 20px;
}

#soundbars{
    width: 25px;
    margin: 0px 5px;
}



.bar {
   background: white;
   bottom: 1px;
   height: 3px;
   position: absolute;
   width: 4px;
   animation: sound 100ms -850ms linear infinite alternate;
}

@keyframes sound {
    0% {
       opacity: .35;
       height: 3px; 
   }
   100% {
    opacity: 1;       
    height: 16px;        
}
}

.bar:nth-child(1)  { left: 1px; animation-duration: 474ms; }
.bar:nth-child(2)  { left: 7px; animation-duration: 433ms; }
.bar:nth-child(3)  { left: 13px; animation-duration: 407ms; }
.bar:nth-child(4)  { left: 19px; animation-duration: 458ms; }
.bar:nth-child(5)  { left: 25px; animation-duration: 400ms; }
.bar:nth-child(6)  { left: 31px; animation-duration: 427ms; }
.bar:nth-child(7)  { left: 37px; animation-duration: 441ms; }
.bar:nth-child(8)  { left: 43px; animation-duration: 419ms; }
.bar:nth-child(9)  { left: 49px; animation-duration: 487ms; }
.bar:nth-child(10) { left: 55px; animation-duration: 442ms; }


html .ui-page-theme-c#ExplorePage .swiper-slide-active  .playlistItem.transparent.withLargeAlbumArt,
html .ui-page-theme-c#Onboarding .swiper-slide-active  .playlistItem.transparent.withLargeAlbumArt  {
    /* border: var(--border-2) solid var(--color-white-20);
    background-color:  var(--bg-overlay-light) !important; */
}


.ui-page-theme-c#ExplorePage .swiper-slide-visible  .playlistItem.transparent.withLargeAlbumArt,
.ui-page-theme-c#Onboarding .swiper-slide-visible  .playlistItem.transparent.withLargeAlbumArt,
body.darkTheme .ui-page-theme-c#ExplorePage .swiper-slide-visible  .playlistItem.withLargeAlbumArt,
.ui-page-theme-c#ExplorePage.darkTheme .swiper-slide-visible  .playlistItem.withLargeAlbumArt,
.ui-page-theme-c#Onboarding.darkTheme .swiper-slide-visible  .playlistItem.withLargeAlbumArt{
    transition: all .3s ease-in-out;
}
.ui-page-theme-c#ExplorePage .swiper-slide-active  .playlistItem.transparent.withLargeAlbumArt,
.ui-page-theme-c#Onboarding .swiper-slide-active  .playlistItem.transparent.withLargeAlbumArt,
body.darkTheme  .ui-page-theme-c#ExplorePage .swiper-slide-active  .playlistItem.withLargeAlbumArt,
.ui-page-theme-c#ExplorePage.darkTheme .swiper-slide-active  .playlistItem.withLargeAlbumArt,
.ui-page-theme-c#Onboarding.darkTheme .swiper-slide-active  .playlistItem.withLargeAlbumArt {
    transform: scale(1.09);

}
.swiper-button-prev.swiper-button-disabled,
.swiper-button-next.swiper-button-disabled{
    display: none;
}
#ExplorePage .swiper-wrapper.vertical .playlistItem.transparent.withLargeAlbumArt,
#Onboarding .swiper-wrapper.vertical .playlistItem.transparent.withLargeAlbumArt,
body.darkTheme  #ExplorePage .swiper-wrapper.vertical .playlistItem.withLargeAlbumArt,
body.darkTheme  #Onboarding .swiper-wrapper.vertical .playlistItem.withLargeAlbumArt,
#ExplorePage.darkTheme .swiper-wrapper.vertical .playlistItem.withLargeAlbumArt,
#Onboarding.darkTheme .swiper-wrapper.vertical .playlistItem.withLargeAlbumArt{
    max-width: calc(100% - 50px);
}
@media (max-width: 500px), (max-height: 500px) and (orientation: landscape){
    #ExplorePage .swiper-wrapper .playlistItem.transparent.withLargeAlbumArt,
    body.darkTheme  #ExplorePage .swiper-wrapper .playlistItem.withLargeAlbumArt,
    #ExplorePage.darkTheme .swiper-wrapper .playlistItem.withLargeAlbumArt,
    #Onboarding .swiper-wrapper .playlistItem.transparent.withLargeAlbumArt,
    body.darkTheme  #Onboarding .swiper-wrapper .playlistItem.withLargeAlbumArt,
    #Onboarding.darkTheme .swiper-wrapper .playlistItem.withLargeAlbumArt{
        max-width: calc(100% - 100px);
    }
}

.listeningInstructionsBox .instructionTitle,
.WelcomeBetaUserBox .instructionTitle{
    font-weight: bold;
    margin: 8px 0px;
    font-size: 110%;
}

.subscribe_tooltip,
.listen_on_voice_enabled_devices_tooltip,
.turn_explore_auto_preview_tooltip,
.playlistDetailsPage.editable .share_my_trebble_tooltip,
.tap_below_to_open_player_tooltip{
    background: white;
    color: var(--color-black);
    position: absolute;
    top: 52px;
    right: 0px;
    white-space: normal;
    line-height: 20px;
    width: 250px;
    box-sizing: border-box;
    padding: 18px;
    box-shadow: 0px 10px 30px 0px var(--color-black-30);
    animation-name: tooltipfloating;
    animation-name: tooltipfloating;
    animation-duration: 1.5s;
    animation-duration: 1.5s;
    font-size: var(--text-sm-px);
    animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    animation-timing-function:ease-in-out;
    text-align: center;
    cursor: pointer;
    text-transform: none;
}
html body.mobileweb .playlistItem.withLargeAlbumArt:hover .subscribe_tooltip{

    animation-name: none;
    animation-name: none;

}

.subscribe_tooltip:after,
.listen_on_voice_enabled_devices_tooltip:after,
.turn_explore_auto_preview_tooltip:after,
.share_my_trebble_tooltip:after,
.tap_below_to_open_player_tooltip:after{
    bottom: 100%;
    left: calc(100% - 23px);
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: transparent;
    border-bottom-color: white;
    border-width: var(--border-8);
    margin-left: -10px;

}
#player #playerSocialInfoWrapper,
#player #playerSocialInfoWrapper .ui-block-a,
#player #playerSocialInfoWrapper .ui-block-a a{
    overflow: visible;
}

#player #playerSocialInfoWrapper .ui-block-a a{
    position: relative;
    height: 100%;
    width: 100%;
}
#player #playerSocialInfoWrapper .subscribe_tooltip.upsidedown{
    left: 0px;
}
#player #playerSocialInfoWrapper .subscribe_tooltip.upsidedown:after{
    left: 26px;
}
.subscribe_tooltip.upsidedown,
.listen_on_voice_enabled_devices_tooltip.upsidedown,
.turn_explore_auto_preview_tooltip.upsidedown,
.share_my_trebble_tooltip.upsidedown,
.tap_below_to_open_player_tooltip.upsidedown{
    bottom: 52px;
    top: auto;
}
.subscribe_tooltip.upsidedown:after,
.listen_on_voice_enabled_devices_tooltip.upsidedown:after,
.turn_explore_auto_preview_tooltip.upsidedown:after,
.share_my_trebble_tooltip.upsidedown:after,
.tap_below_to_open_player_tooltip.upsidedown:after{
    top: 100%;
    bottom: auto;
    border-color: var(--text-light)transparent transparent transparent;

}

.subscribe_tooltip .closeTooltipButton,
.listen_on_voice_enabled_devices_tooltip .closeTooltipButton,
.turn_explore_auto_preview_tooltip .closeTooltipButton,
.share_my_trebble_tooltip .closeTooltipButton,
.tap_below_to_open_player_tooltip .closeTooltipButton{
    position: absolute;
    right: 4px;
    top: 4px;
    font-size: var(--text-lg-px);
    cursor: pointer;
}

body.mobileweb .subscribe_tooltip .closeTooltipButton:hover,
body.mobileweb .listen_on_voice_enabled_devices_tooltip .closeTooltipButton:hover,
body.mobileweb .turn_explore_auto_preview_tooltip .closeTooltipButton:hover,
body.mobileweb .share_my_trebble_tooltip .closeTooltipButton:hover,
body.mobileweb .tap_below_to_open_player_tooltip .closeTooltipButton:hover,
.subscribe_tooltip .closeTooltipButton:active,
.listen_on_voice_enabled_devices_tooltip .closeTooltipButton:active,
.turn_explore_auto_preview_tooltip .closeTooltipButton:active,
.share_my_trebble_tooltip .closeTooltipButton:active,
.tap_below_to_open_player_tooltip .closeTooltipButton:active{
   color: var(--trebble-primary);
}


.subscribe_tooltip.center_arrow:after,
.listen_on_voice_enabled_devices_tooltip.center_arrow:after,
.turn_explore_auto_preview_tooltip.center_arrow:after,
.share_my_trebble_tooltip.center_arrow:after,
.tap_below_to_open_player_tooltip.center_arrow:after{
    left: auto;
}
.subscribe_tooltip.center_arrow,
.listen_on_voice_enabled_devices_tooltip.center_arrow,
.turn_explore_auto_preview_tooltip.center_arrow,
.share_my_trebble_tooltip.center_arrow,
.tap_below_to_open_player_tooltip.center_arrow{
    right: auto;
}
.playlistDetailsPage.editable .share_my_trebble_tooltip{
    background-color: var(--color-purple-500);
    color: white;
}

.playlistDetailsPage.editable .share_my_trebble_tooltip:after{
    border-bottom-color: var(--color-purple-500);

}

@keyframes tooltipfloating {
    0% {
        transform: translateY(0%);  
    }
    50% {
        transform: translateY(5px);  
    }   
    100% {
        transform: translateY(0%);
    }           
}

@-webkit-keyframes tooltipfloating {
    0% {
        transform: translateY(0%);  
    }
    50% {
        transform: translateY(5px);  
    }   
    100% {
        transform: translateY(0%);
    }           
}

.subscribe_tooltip.hide,
.listen_on_voice_enabled_devices_tooltip.hide,
.turn_explore_auto_preview_tooltip.hide,
.share_my_trebble_tooltip.hide,
.tap_below_to_open_player_tooltip.hide,
.playlistDetailsPage:not(.editable) .share_my_trebble_tooltip{
    display: none !important;
}
.turn_explore_auto_preview_tooltip .soundPlayingAnimation .soundPlayingIconWrapper{
    position: relative;
}
#playerFloating .tap_below_to_open_player_tooltip{
    right: 6px;
    bottom: 82px;
    width: 200px;
    /*font-weight: bold;*/
}
.turn_explore_auto_preview_tooltip .soundPlayingAnimation .soundPlayingIconWrapper .bar{
    background: var(--trebble-primary);
}

.subscribe_tooltip .assistantLogos,
.listen_on_voice_enabled_devices_tooltip .assistantLogos,
.turn_explore_auto_preview_tooltip .soundPlayingAnimation{

    display: flex;
    flex-direction: row;
    height: 40px;
    justify-content: center;
    align-items:center;

}

.subscribe_tooltip .assistantLogos img,
.listen_on_voice_enabled_devices_tooltip .assistantLogos img{

    width: 30px;
    height: 30px;
    padding: 4px;
    object-fit: contain;
}

.listen_on_voice_enabled_devices_tooltip .assistantLogos img.bixbyLogo{
    width: 20px;
    height: 20px;
}

body[appName=trebble] .ui-footer-fullscreen, 
body[appName=trebble] .ui-header-fullscreen{
    opacity: 1;
}

.addShortcutToPlayCapsuleFeedSuggestionBoxWrapper,
.listenOnVoiceEnabledDeviceBoxWrapper,
.showUserRefferalPageBoxWrapper,
.upgradeToTrebblePaidSubscriptionBoxWrapper,
.shortcutPhraseToPlayCapsuleFeedBoxWrapper,
.changeSubscriptionOrderInstructionBox,
.listeningInstructionWrapper{

    background-color: var(--bg-light);
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 500px;
    margin: 14px auto;
    box-sizing:border-box;
    /* border: var(--border-4) solid var(--text-dark); */
}

#home .upgradeToTrebblePaidSubscriptionBoxWrapper{
    margin:0px;
}

.listeningInstructionWrapper,
.listeningInstructionsBox{
    width: calc(100% - 40px);
}

#mySubscriptions:not(.no_subscription) .listenOnVoiceEnabledDeviceBoxWrapper,
#mySubscriptions:not(.no_subscription) .showUserRefferalPageBoxWrapper,
#mySubscriptions:not(.no_subscription) .upgradeToTrebblePaidSubscriptionBoxWrapper{
 /* padding: 20px;
 background-color: white;
 border: var(--border-1) solid var(--bg-light);
 background-color: var(--bg-light);*/
 background-color: transparent;
}

#mySubscriptions .listenOnVoiceEnabledDeviceBoxWrapper #listenOnVoiceEnabledDeviceBtn{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px 30px;
    border: var(--border-2) solid var(--trebble-primary);
}
#mySubscriptions .showUserRefferalPageBoxWrapper #showUserRefferalPageBtn{

    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 310px;
    flex: 1 1 auto;
    padding: 15px 30px;
    border:var(--border-2) solid var(--success-green-alt);

}
.upgradeToTrebblePaidSubscriptionBoxWrapper #upgradeBtn{

    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 310px;
    flex: 1 1 auto;
    padding: 13px 13px;
    border:var(--border-2) solid var(--success-green-alt);

}


body.browser.mobileweb #mySubscriptions .listenOnVoiceEnabledDeviceBoxWrapper #listenOnVoiceEnabledDeviceBtn:hover,
#mySubscriptions .listenOnVoiceEnabledDeviceBoxWrapper #listenOnVoiceEnabledDeviceBtn:active{
    background-color:var(--trebble-primary);
    color:white;
}
body.browser.mobileweb #mySubscriptions .showUserRefferalPageBoxWrapper #showUserRefferalPageBtn:hover,
#mySubscriptions .showUserRefferalPageBoxWrapper #showUserRefferalPageBtn:active{
   background:transparent;
   color:var(--success-green-alt);
}
body.browser.mobileweb  .upgradeToTrebblePaidSubscriptionBoxWrapper #upgradeBtn:hover,
.upgradeToTrebblePaidSubscriptionBoxWrapper #upgradeBtn:active{
   background:transparent;
   color:var(--success-green-alt);
}
#mySubscriptions .listenOnVoiceEnabledDeviceBoxWrapper #listenOnVoiceEnabledDeviceBtn  t,
#mySubscriptions .showUserRefferalPageBoxWrapper #showUserRefferalPageBtn  t,
.upgradeToTrebblePaidSubscriptionBoxWrapper #upgradeBtn  t{
    font-size: var(--text-2xl-px);
    margin-right: 20px;
}
.upgradeToTrebblePaidSubscriptionBoxWrapper #upgradeBtn  t{
    font-weight: 600;
}

#mySubscriptions .listenOnVoiceEnabledDeviceBoxWrapper #listenOnVoiceEnabledDeviceBtn  .label,
#mySubscriptions .showUserRefferalPageBoxWrapper #showUserRefferalPageBtn  .label,
.upgradeToTrebblePaidSubscriptionBoxWrapper #upgradeBtn  .label{
    text-align: center;
}

body.mobileweb #mySubscriptions .listenOnVoiceEnabledDeviceBoxWrapper #listenOnVoiceEnabledDeviceBtn:hover,
#mySubscriptions .listenOnVoiceEnabledDeviceBoxWrapper #listenOnVoiceEnabledDeviceBtn:active{
    color: var(--trebble-primary);
}

body.mobileweb #mySubscriptions .showUserRefferalPageBoxWrapper #showUserRefferalPageBtn:hover,
#mySubscriptions .showUserRefferalPageBoxWrapper #showUserRefferalPageBtn:active{
    color: var(--trebble-primary);
}

body.mobileweb  .upgradeToTrebblePaidSubscriptionBoxWrapper #upgradeBtn:hover,
.upgradeToTrebblePaidSubscriptionBoxWrapper #upgradeBtn:active{
    color: var(--trebble-primary);
}




.enableDisableDailyNotificationBox{
    position: relative;
    display: flex;
    flex-direction: row;
    color: white;
    max-width: 500px;
    box-sizing: border-box;
    margin: auto;
    padding: 20px;
    background-color: var(--color-purple-500);
    justify-content: center;
    align-items: center;
    font-weight: 900;
    box-shadow: 2px 0 12px 0 var(--shadow-color-light);

}
.enableDisableDailyNotificationBox .closeButton{
    position: absolute;
    top: 0px;
    right: 5px;
}
.enableDisableDailyNotificationBox .closeButton i{
    font-size: var(--text-2xl-px);
}
.enableDisableDailyNotificationBox .switch label .lever{
    background-color: var(--color-purple-200);
}
.enableDisableDailyNotificationBox .switch label input[type=checkbox]:checked+.lever{
    background-color: var(--color-purple-350);
}

body.darkTheme .switch label .lever,
[data-role=page].lighttext .switch label .lever{
    background-color: var(--color-white-40);
}

.enableDisableDailyNotificationBox .switch label input[type=checkbox]:checked+.lever:after{
    background-color: var(--color-purple-700);
}

.changeSubscriptionOrderInstructionBox{
    padding: 20px;
}
.changeSubscriptionOrderInstructionBox,
.listeningInstructionWrapper{

    margin: 10px auto;
}


.addShortcutToPlayCapsuleFeedSuggestionBox,
.shortcutPhraseToPlayCapsuleFeedBox{
    display: flex;
    background-color: white;
    padding: 20px;
    /* margin: 20px; */
    justify-content: center;
    align-items: center;
    flex-direction: column;
    max-width: 500px;
    box-shadow: 0 5px 20px var(--color-black-10);
    width: calc(100% - 20px);
    background-size: cover;
    color: white;
    background-image: url("./images/siriBackground.jpg");

}

.addShortcutToPlayCapsuleFeedSuggestionBox .label,
.shortcutPhraseToPlayCapsuleFeedBox .label{
    padding: 10px 10px;
    max-width: 250px;
    text-align: center;
}

.shortcutPhraseToPlayCapsuleFeedBox .siriPhrase,
.shortcutPhraseToPlayCapsuleFeedBox .customPhrase{
    font-weight:bold;
}


.addShortcutToPlayCapsuleFeedSuggestionBox .title{
    font-weight:bold;
    text-align: center;
    margin-bottom: 20px;
}

.addToSiriBtn{
    background-color: var(--color-black);
    color: white;
    height: 48px;
    display: flex;
    padding: 20px;
    /* font-size: 85%; */
    margin: 10px 10px;
    font-weight: bold;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    border: var(--border-2) solid var(--color-black);
    cursor: pointer;
    /* min-width: 150px; */
}

body.mobileweb .addToSiriBtn:hover,
.addToSiriBtn:active{
    background-color: white;
    color: var(--color-black);
}
.addToSiriBtn.fixed{
    position: absolute;
    top: 0px;
    right: 0px;
}
.addToSiriBtn .label{
    margin: 0px 10px;
}

.addToSiriBtn .siriLogo{
    height: 30px;
    padding: 0px 0px 0px 10px;
    object-fit: contain;
}

.addShortcutToPlayCapsuleFeedSuggestionBoxWrapper.hide,
.enableDisableDailyNotificationBox.hide,
.shortcutPhraseToPlayCapsuleFeedBoxWrapper.hide,
.addToSiriBtn.hide{
    display: none !important;
}

@media (max-width:400px){
    .playlistItem.withLargeAlbumArt > .itemContent >.coverArt,
    .playlistItem.transparent.withLargeAlbumArt > .itemContent >.coverArt,
    body.darkTheme .playlistItem.withLargeAlbumArt > .itemContent >.coverArt,
    body.darkTheme.playlistItem.transparent.withLargeAlbumArt > .itemContent >.coverArt,
    [data-role=page].darkTheme .playlistItem.withLargeAlbumArt > .itemContent >.coverArt,
    [data-role=page].darkTheme .playlistItem.transparent.withLargeAlbumArt > .itemContent >.coverArt{
        height:200px;
        width: 100%;
        max-width: 200px;
    }

    .playlistItem.withLargeAlbumArt>.itemContent{
        height:390px;

    }
    html .ui-page-theme-a .playlistItem.withLargeAlbumArt, html .ui-page-theme-c .playlistItem.withLargeAlbumArt{
        height:420px;
    }

    html .ui-page-theme-a .playlistItem.withLargeAlbumArt.journeyCard, html .ui-page-theme-c .playlistItem.withLargeAlbumArt.journeyCard{
        height:480px;
    }


    html .ui-page-theme-a .playlistItem.withLargeAlbumArt.journeyCard.rectangularImage, html .ui-page-theme-c .playlistItem.withLargeAlbumArt.journeyCard.rectangularImage{
        height:410px;
    }



    html .ui-page-theme-a .playlistItem.withLargeAlbumArt.journeyCard.doNotShowSubscribeButton, html .ui-page-theme-c .playlistItem.withLargeAlbumArt.journeyCard.doNotShowSubscribeButton{
        height:430px;
    }


    html .ui-page-theme-a .playlistItem.withLargeAlbumArt.journeyCard.rectangularImage.doNotShowSubscribeButton, html .ui-page-theme-c .playlistItem.withLargeAlbumArt.journeyCard.rectangularImage.doNotShowSubscribeButton{
        height:360px;
    }

    html .ui-page-theme-a .playlistItem.withLargeAlbumArt.journeyCard.smallcardsize, html .ui-page-theme-c .playlistItem.withLargeAlbumArt.journeyCard.smallcardsize,
    html .ui-page-theme-a .playlistItem.withLargeAlbumArt.journeyCard.rectangularImage.smallcardsize, html .ui-page-theme-c .playlistItem.withLargeAlbumArt.journeyCard.rectangularImage.smallcardsize{
        height: 196px;
        
    }

    html .ui-page-theme-a .playlistItem.withLargeAlbumArt.journeyCard.smallcardsize.doNotShowSubscribeButton, html .ui-page-theme-c .playlistItem.withLargeAlbumArt.journeyCard.smallcardsize.doNotShowSubscribeButton,
    html .ui-page-theme-a .playlistItem.withLargeAlbumArt.journeyCard.rectangularImage.smallcardsize.doNotShowSubscribeButton, html .ui-page-theme-c .playlistItem.withLargeAlbumArt.journeyCard.rectangularImage.smallcardsize.doNotShowSubscribeButton{
        height:150px;
        
    }


    .playlistItem.withLargeAlbumArt.journeyCard.rectangularImage > .itemContent >.coverArt,
    .playlistItem.transparent.withLargeAlbumArt.journeyCard.rectangularImage > .itemContent >.coverArt,
    body.darkTheme .playlistItem.withLargeAlbumArt.journeyCard.rectangularImage > .itemContent >.coverArt,
    body.darkTheme.playlistItem.transparent.withLargeAlbumArt.journeyCard.rectangularImage > .itemContent >.coverArt,
    [data-role=page].darkTheme .playlistItem.withLargeAlbumArt.journeyCard.rectangularImage > .itemContent >.coverArt,
    [data-role=page].darkTheme .playlistItem.transparent.withLargeAlbumArt.journeyCard.rectangularImage > .itemContent >.coverArt{
        height:150px;
        width: 100%;
        max-width: 100%;
    }

    html .playlistItem.withLargeAlbumArt.smallcardsize {
        height: 120px;
    }

}

@media (min-width:400px) and (max-width:500px){
    .playlistItem.withLargeAlbumArt > .itemContent >.coverArt,
    .playlistItem.transparent.withLargeAlbumArt>.itemContent>.coverArt,
    body.darkTheme .playlistItem.withLargeAlbumArt > .itemContent >.coverArt,
    body.darkTheme.playlistItem.transparent.withLargeAlbumArt > .itemContent >.coverArt,
    [data-role=page].darkTheme .playlistItem.withLargeAlbumArt > .itemContent >.coverArt,
    [data-role=page].darkTheme .playlistItem.transparent.withLargeAlbumArt > .itemContent >.coverArt{
        height:240px;
        width: 100%;
        max-width: 240px;
    }

    .playlistItem.withLargeAlbumArt>.itemContent{
        height:430px;

    }
    html .ui-page-theme-a .playlistItem.withLargeAlbumArt, html .ui-page-theme-c .playlistItem.withLargeAlbumArt{
        height:460px;
    }


    html .ui-page-theme-a .playlistItem.withLargeAlbumArt.journeyCard, html .ui-page-theme-c .playlistItem.withLargeAlbumArt.journeyCard{
        height:520px;
    }


    html .ui-page-theme-a .playlistItem.withLargeAlbumArt.journeyCard.rectangularImage, html .ui-page-theme-c .playlistItem.withLargeAlbumArt.journeyCard.rectangularImage{
        height:450px;
    }

    html .ui-page-theme-a .playlistItem.withLargeAlbumArt.journeyCard.doNotShowSubscribeButton, html .ui-page-theme-c .playlistItem.withLargeAlbumArt.journeyCard.doNotShowSubscribeButton{
        height:470px;
    }


    html .ui-page-theme-a .playlistItem.withLargeAlbumArt.journeyCard.rectangularImage.doNotShowSubscribeButton, html .ui-page-theme-c .playlistItem.withLargeAlbumArt.journeyCard.rectangularImage.doNotShowSubscribeButton{
        height:400px;
    }


    html .ui-page-theme-a .playlistItem.withLargeAlbumArt.journeyCard.smallcardsize, html .ui-page-theme-c .playlistItem.withLargeAlbumArt.journeyCard.smallcardsize,
    html .ui-page-theme-a .playlistItem.withLargeAlbumArt.journeyCard.rectangularImage.smallcardsize, html .ui-page-theme-c .playlistItem.withLargeAlbumArt.journeyCard.rectangularImage.smallcardsize{
        height: 196px;
        
    }

    html .ui-page-theme-a .playlistItem.withLargeAlbumArt.journeyCard.smallcardsize.doNotShowSubscribeButton, html .ui-page-theme-c .playlistItem.withLargeAlbumArt.journeyCard.smallcardsize.doNotShowSubscribeButton,
    html .ui-page-theme-a .playlistItem.withLargeAlbumArt.journeyCard.rectangularImage.smallcardsize.doNotShowSubscribeButton, html .ui-page-theme-c .playlistItem.withLargeAlbumArt.journeyCard.rectangularImage.smallcardsize.doNotShowSubscribeButton{
        height:150px;
        
    }


    .playlistItem.withLargeAlbumArt.journeyCard.rectangularImage > .itemContent >.coverArt,
    .playlistItem.transparent.withLargeAlbumArt.journeyCard.rectangularImage > .itemContent >.coverArt,
    body.darkTheme .playlistItem.withLargeAlbumArt.journeyCard.rectangularImage > .itemContent >.coverArt,
    body.darkTheme.playlistItem.transparent.withLargeAlbumArt.journeyCard.rectangularImage > .itemContent >.coverArt,
    [data-role=page].darkTheme .playlistItem.withLargeAlbumArt.journeyCard.rectangularImage > .itemContent >.coverArt,
    [data-role=page].darkTheme .playlistItem.transparent.withLargeAlbumArt.journeyCard.rectangularImage > .itemContent >.coverArt{
        height:190px;
        width: 100%;
        max-width: 100%;
    }


    html .ui-page-theme-a .playlistItem.withLargeAlbumArt.smallcardsize,
    html .ui-page-theme-c .playlistItem.withLargeAlbumArt.smallcardsize {
        height: 120px
    }

}

@media (min-width:500px) {
    .playlistItem.withLargeAlbumArt > .itemContent >.coverArt,
    .playlistItem.transparent.withLargeAlbumArt>.itemContent>.coverArt,
    body.darkTheme .playlistItem.withLargeAlbumArt > .itemContent >.coverArt,
    body.darkTheme.playlistItem.transparent.withLargeAlbumArt > .itemContent >.coverArt,
    [data-role=page].darkTheme .playlistItem.withLargeAlbumArt > .itemContent >.coverArt,
    [data-role=page].darkTheme .playlistItem.transparent.withLargeAlbumArt > .itemContent >.coverArt{
        height:340px;
        width: 100%;
        max-width: 340px;
    }

    .playlistItem.withLargeAlbumArt>.itemContent{
        height:530px;

    }
    html .ui-page-theme-a .playlistItem.withLargeAlbumArt, html .ui-page-theme-c .playlistItem.withLargeAlbumArt{
        height:560px;
    }


    html .ui-page-theme-a .playlistItem.withLargeAlbumArt.journeyCard, html .ui-page-theme-c .playlistItem.withLargeAlbumArt.journeyCard{
        height:620px;
    }


    html .ui-page-theme-a .playlistItem.withLargeAlbumArt.journeyCard.rectangularImage, html .ui-page-theme-c .playlistItem.withLargeAlbumArt.journeyCard.rectangularImage{
        height:450px;
    }

    html .ui-page-theme-a .playlistItem.withLargeAlbumArt.journeyCard.doNotShowSubscribeButton, html .ui-page-theme-c .playlistItem.withLargeAlbumArt.journeyCard.doNotShowSubscribeButton{
        height: 570px;
    }


    html .ui-page-theme-a .playlistItem.withLargeAlbumArt.journeyCard.rectangularImage.doNotShowSubscribeButton, html .ui-page-theme-c .playlistItem.withLargeAlbumArt.journeyCard.rectangularImage.doNotShowSubscribeButton{
        height:400px;
    }


    html .ui-page-theme-a .playlistItem.withLargeAlbumArt.journeyCard.smallcardsize, html .ui-page-theme-c .playlistItem.withLargeAlbumArt.journeyCard.smallcardsize,
    html .ui-page-theme-a .playlistItem.withLargeAlbumArt.journeyCard.rectangularImage.smallcardsize, html .ui-page-theme-c .playlistItem.withLargeAlbumArt.journeyCard.rectangularImage.smallcardsize{
        height: 196px;
        
    }

    html .ui-page-theme-a .playlistItem.withLargeAlbumArt.journeyCard.smallcardsize.doNotShowSubscribeButton, html .ui-page-theme-c .playlistItem.withLargeAlbumArt.journeyCard.smallcardsize.doNotShowSubscribeButton,
    html .ui-page-theme-a .playlistItem.withLargeAlbumArt.journeyCard.rectangularImage.smallcardsize.doNotShowSubscribeButton, html .ui-page-theme-c .playlistItem.withLargeAlbumArt.journeyCard.rectangularImage.smallcardsize.doNotShowSubscribeButton{
        height:150px;
        
    }

    .playlistItem.withLargeAlbumArt.journeyCard:not(.smallcardsize) .journey_goal_wrapper,
    .playlistItem.withLargeAlbumArt.journeyCard:not(.smallcardsize) .buttonBar{
        display: none;
    }

    .playlistItem.withLargeAlbumArt.journeyCard.rectangularImage > .itemContent >.coverArt,
    .playlistItem.transparent.withLargeAlbumArt.journeyCard.rectangularImage > .itemContent >.coverArt,
    body.darkTheme .playlistItem.withLargeAlbumArt.journeyCard.rectangularImage > .itemContent >.coverArt,
    body.darkTheme.playlistItem.transparent.withLargeAlbumArt.journeyCard.rectangularImage > .itemContent >.coverArt,
    [data-role=page].darkTheme .playlistItem.withLargeAlbumArt.journeyCard.rectangularImage > .itemContent >.coverArt,
    [data-role=page].darkTheme .playlistItem.transparent.withLargeAlbumArt.journeyCard.rectangularImage > .itemContent >.coverArt{
        height:200px;
        width: 100%;
        max-width: 100%;
    }

    html .ui-page-theme-a .playlistItem.withLargeAlbumArt.smallcardsize,
    html .ui-page-theme-c .playlistItem.withLargeAlbumArt.smallcardsize {
        height: 120px
    }

}


#trebbleCarouselWrapper .playlistItem.withLargeAlbumArt .info{
    flex: 0 0 190px;
}

#trebbleCarouselWrapper .playlistItem.withLargeAlbumArt{
    height: 90%;
}

#trebbleCarouselWrapper .playlistItem.withLargeAlbumArt .itemContent.withLargeAlbumArt{
    height:100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
#ExplorePage #trebbleCarouselWrapper .playlistItem.withLargeAlbumArt .coverArt,
#Onboarding #trebbleCarouselWrapper .playlistItem.withLargeAlbumArt .coverArt{
    height: 100px;
    width: 100px;
}

#ExplorePage #trebbleCarouselWrapper .playlistItem.withLargeAlbumArt .info,
#Onboarding #trebbleCarouselWrapper .playlistItem.withLargeAlbumArt .info{
    width: calc(100% - 100px);
}


@media (min-width: 200px) and (orientation: portrait) and (min-height:450px), (min-height: 450px) and (orientation: landscape){
    #ExplorePage #trebbleCarouselWrapper .playlistItem.withLargeAlbumArt .coverArt,
    #Onboarding #trebbleCarouselWrapper .playlistItem.withLargeAlbumArt .coverArt{
        height: 100px;
        width: 100px;
        max-height: 100px;
        max-width: 100px;

    }
}

@media (min-width: 250px) and (orientation: portrait) and (min-height:500px), (min-height: 500px) and (orientation: landscape){
    #ExplorePage #trebbleCarouselWrapper .playlistItem.withLargeAlbumArt .coverArt,
    #Onboarding #trebbleCarouselWrapper .playlistItem.withLargeAlbumArt .coverArt{
        height: 150px;
        width: 150px;
        max-height: 150px;
        max-width: 150px;
    }
}

@media (min-width: 300px) and (orientation: portrait) and (min-height:600px), (min-height: 600px) and (orientation: landscape){
    #ExplorePage #trebbleCarouselWrapper .playlistItem.withLargeAlbumArt .coverArt,
    #Onboarding #trebbleCarouselWrapper .playlistItem.withLargeAlbumArt .coverArt{
        height: 200px;
        width: 200px;
        max-height: 200px;
        max-width: 200px;
        
    }
}

@media (min-width: 350px) and (orientation: portrait) and (min-height:650px), (min-height: 650px) and (orientation: landscape){
    #ExplorePage #trebbleCarouselWrapper .playlistItem.withLargeAlbumArt .coverArt,
    #Onboarding #trebbleCarouselWrapper .playlistItem.withLargeAlbumArt .coverArt{
        height: 250px;
        width: 250px;
        max-height: 250px;
        max-width: 250px;
    }
}

@media (min-width: 400px) and (orientation: portrait) and (min-height:700px), (min-height: 700px) and (orientation: landscape){
    #ExplorePage #trebbleCarouselWrapper .playlistItem.withLargeAlbumArt .coverArt,
    #Onboarding #trebbleCarouselWrapper .playlistItem.withLargeAlbumArt .coverArt{
        height: 300px;
        width: 300px;
        max-height: 300px;
        max-width: 300px;
    }
}


@media (min-width: 450px) and (orientation: portrait) and (min-height:750px), (min-height: 750px) and (orientation: landscape){
    #ExplorePage #trebbleCarouselWrapper .playlistItem.withLargeAlbumArt .coverArt,
    #Onboarding #trebbleCarouselWrapper .playlistItem.withLargeAlbumArt .coverArt{
        height: 350px ;
        width: 350px ;
        max-height: 350px;
        max-width: 350px;
        
    }
}
/*
@media (min-width: 550px) and (orientation: portrait) and (min-height:850px), (min-height: 850px) and (orientation: landscape){
 #ExplorePage #trebbleCarouselWrapper .playlistItem.withLargeAlbumArt .coverArt,
 #Onboarding #trebbleCarouselWrapper .playlistItem.withLargeAlbumArt .coverArt{
    height: 450px;
    width: 450px;
    max-height: 450px;
    max-width: 450px;

}

}
*/


.setup_voice_enabled_device_wrapper:not([step="show_google_instructions"])  .google_assistant_instructions,
.setup_voice_enabled_device_wrapper:not([step="show_bixby_instructions"])  .samsung_bixby_instructions,
.setup_voice_enabled_device_wrapper:not([step="show_alexa_instructions"])  .amazon_alexa_instructions,
.setup_voice_enabled_device_wrapper:not([step="show_siri_instructions"])  .siri_instructions,
.setup_voice_enabled_device_wrapper:not([step="choose_from_device_list"])  #device_connection_list_wrapper,
.setup_voice_enabled_device_wrapper .siri_instructions:not([state="no_shortcut_setup"]) .add_to_siri_section_wrapper,
.setup_voice_enabled_device_wrapper .siri_instructions:not([state="shortcut_succesfully_setup"]) .siri_shortcut_successfully_setup,
.setup_voice_enabled_device_wrapper .siri_instructions:not([state="shorcut_phrase_instructions"]) .siri_shortcut_modification_instuction,
.setup_voice_enabled_device_wrapper.siri_not_available .siri_instructions,
.setup_voice_enabled_device_wrapper.bixby_not_available .samsung_bixby_instructions,
.setup_voice_enabled_device_wrapper.siri_not_available #listenOnSiriBtn.device_option{
    display: none;
}
.setup_voice_enabled_device_wrapper .instructions_setup{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    max-width: 600px;
    height: 100%;
    width: 100%;
    margin: auto;
    box-sizing: border-box;

}
.siri_instructions .instruction_wrapper{


    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

}
.siri_instructions .instruction_wrapper .instruction{
    padding: 40px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    display: flex;
}

.siri_instructions .instruction_wrapper .instruction div{
    padding:20px 0px;
}
.siri_shortcut_successfully_setup.instruction_wrapper .assistant_icon t{
    font-size: var(--text-10xl-px);
}
.siri_shortcut_successfully_setup.instruction_wrapper .assistant_icon .label{

    font-size: var(--text-5xl-px);
    font-weight: 700;

}
.instruction_wrapper{
    font-size: var(--text-md-px);
    padding: 30px;
    box-sizing: border-box;
    height: 100%;
    width: 100%;
}

.instruction_wrapper .instruction_step_number{
 font-size: var(--text-xl-px);
 font-weight: 900;
 margin: 40px 0;
 border-radius: 50%;
 border: var(--border-2) solid white;
 width: 40px;
 height: 40px;
 display: flex;
 justify-content: center;
 align-items: center;
 color: var(--text-dark);
 background-color: white;

}

.instructions_setup a.largeMenuButton.ui-link.acknoledgement_btn,
.instructions_setup a.largeMenuButton.ui-link.try_on_google_assistant_btn,
.instructions_setup a.largeMenuButton.ui-link.actionButton{
 max-width: 200px;
 width: 100%;
 margin: 40px 0 20px;
 font-size: var(--text-md-px);
 height: 45px;
 box-sizing: border-box;
 flex: 0 0 45px;
}

.device_connection_list .device_option img.option_icon{
    height: 30px;
    width: 30px;
    margin: 10px 20px 10px 10px;
    object-fit: contain;
    box-sizing: border-box;
}


.instruction_step .instruction{

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;


}
.instruction_step .instruction i{
    background-color: white;
    color: var(--text-dark);
    padding: 2px;
    font-weight: 900;
    font-size: var(--text-base);
    border-radius: 2px;
}
.instruction_step{

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    
}
.instruction_wrapper .instruction_step:last-child{
    padding-bottom:20px;
}

.instruction_step .instruction.textOnly{
    display: block;
}
.instructions_setup .help_link{
    cursor: pointer;
    font-size: 80%;
    text-decoration: underline;

}
.instruction_step .instruction .chatbubble{
    margin: 20px 0px;
}
.setup_voice_enabled_device_wrapper .instructions_setup .assistant_icon{
    flex: 0 0 30%;
    position: relative;
}
.setup_voice_enabled_device_wrapper .instructions_setup .title{

    font-size: var(--text-5xl-px);
    font-weight: 900;
    max-width: 900px;
    margin: 20px;

}

.setup_voice_enabled_device_wrapper{
    height: 100vh;
    justify-content: center;
    align-items: center;
    display: flex;
    padding-top: 50px;
    overflow: auto;
    box-sizing: border-box;
    width: 100%;
}

#device_connection_list_wrapper .instruction_wrapper{
    flex: 1 1 100%;
    padding: 0px;
}

#device_illustrations{

  position: relative;
  display: flex;
  width: 100%;
  flex: 0 0 250px;

}
.amazon_alexa_instructions.instructions_setup,
.samsung_bixby_instructions.instructions_setup,
.google_assistant_instructions.instructions_setup{
    display: block;
}

[data-role=page] .setup_voice_enabled_device_wrapper:not([step=choose_from_device_list]) .instructions_setup  img{
    width: 100%;
}

[data-role=page] .setup_voice_enabled_device_wrapper .instructions_setup .help_link{
    color: var(--trebble-primary);
}

.google_assistant_instructions.instructions_setup  .google_home_device{
   width: 140px;
   height: 150px;
   object-fit: contain;
}

.amazon_alexa_instructions.instructions_setup  .amazon_echo_device{
 width: 150px;
 height: 200px;
 object-fit: contain;
}

.samsung_bixby_instructions.instructions_setup  .samsung_bixby_device{
 width: 100px;
 height: 100px;
 object-fit: contain;
}
.siri_instructions.instructions_setup .iphone_with_mic_device{
  width: 100px;

  height: 120px;
  object-fit: contain;
}
#device_illustrations .google_home_device{

    width: 140px;
    height: 150px;
    flex: 0 0 140px;
    position: absolute;
    bottom: 0px;
    left: calc(50% - 70px);

}
/*
#device_illustrations .amazon_echo_device{


    width: 200px;
    height: 250px;
    position: absolute;
    flex: 0 0 200px;
    bottom: -20px;
    left: calc(50% - 180px);


    }*/

    #device_illustrations .amazon_echo_device{

        width: 150px;
        height: 200px;
        position: absolute;
        flex: 0 0 200px;
        bottom: 37px;
        left: calc(50% - 165px);


    }

    #device_illustrations .samsung_bixby_device{

      width: 140px;
      height: 150px;
      flex: 0 0 140px;
      position: absolute;
      bottom: 0px;
      left: calc(50% - 70px);


  }

  #device_illustrations .iphone_with_mic_device{

    width: 100px;
    flex: 0 0 100px;
    height: 120px;
    position: absolute;
    padding-bottom: 15px;
    bottom: 0px;
    right: calc(50% - 120px);



}

@media (max-width: 500px), (max-height: 500px) and (orientation: landscape){
    .setup_voice_enabled_device_wrapper .instructions_setup{
        flex-direction:column;
    }

    .setup_voice_enabled_device_wrapper .instructions_setup .title{
        font-size: var(--text-2xl-px);
    }

    .instruction_wrapper,
    #device_connection_list_wrapper .device_option .label{
        font-size: var(--text-sm-px);
    }

    .setup_voice_enabled_device_wrapper #device_connection_list_wrapper{
        padding: 30px;
        box-sizing: border-box;
    }

    .instruction_wrapper .instruction_step_number{
        margin: 20px 0px;
    }
    .siri_instructions .instruction_wrapper .instruction{
        padding: 20px;
    }
    .siri_shortcut_successfully_setup.instruction_wrapper .assistant_icon t{
        font-size: var(--text-8xl-px);
    }
    .siri_shortcut_successfully_setup.instruction_wrapper .assistant_icon .label{

        font-size: var(--text-lg-px);
        font-weight: 700;

    }

}

.setup_voice_enabled_device_wrapper .device_connection_list .device_option{
    cursor: pointer;
    height: 50px;
    font-size: var(--text-lg-px);
    width: 100%;
    text-align: left;
    display: flex;
    justify-content: center;
    align-items: center;
}
.setup_voice_enabled_device_wrapper .device_connection_list .device_option .ion-ios7-arrow-right{
    font-size: var(--text-3xl-px);
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.setup_voice_enabled_device_wrapper .device_connection_list .device_option .label{
    flex: 1 1 100%;
    padding-right: 20px;
}



.feedbackFormWrapper{
    display:flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
}


.feedbackFormWrapper .largeLabel,
.feedbackFormWrapper .smallLabel,
.feedbackFormWrapper #feedbackFieldDecorator{
    max-width: 500px;
}

.feedbackFormWrapper .gif_illustration{
    margin: 20px 0px;
}

.feedbackFormWrapper .actionButton{
    width: calc(100% - 40px);
    max-width: 400px;
}

.feedbackFormWrapper .largeLabel{
    font-weight: 900;
    font-size: 120%;

}



@media(max-height: 300px){
    .autoplaySuggestionContent,
    .trebbleSubscribeSuggestionContent{
        display:flex;
        flex-direction:row;
        align-items: center;
        justify-content: center;
    }

    .autoplaySuggestionContent #contenOwnerInfo,
    .autoplaySuggestionContent #autoplayMessage,
    .autoplaySuggestionContent .secondaryActionButtons,
    .autoplaySuggestionContent #upNextContentInfo{
     display:none;
 }


}

body .swiper-button-next:after, .swiper-container-rtl .swiper-button-prev:after,
body .swiper-button-prev:after, .swiper-container-rtl .swiper-button-next:after{
    content: "";
}


.upgradeToPaidAccountSubBtn{
    width: calc(100% - 40px);
    padding: 1em;
    margin: 1em 0em;
    text-align: left;
    position: relative;
    box-shadow: 2px 0 12px 0 var(--shadow-color-light);

    /*border: var(--border-2) solid var(--trebble-primary);
    background-color: white;
    color:var(--text-dark);*/
    border: var(--border-3) solid white;
    background-color: var(--color-white-10);
    color:white;
    
}

body.browser.mobileweb #paidAccountSubsPopup.coloredBackground .upgradeToPaidAccountSubBtn:hover,
#paidAccountSubsPopup.coloredBackground  .upgradeToPaidAccountSubBtn:active{
    cursor: pointer;
    border: var(--border-3) solid var(--trebble-primary);
}


.upgradeToPaidAccountSubBtn .promotionMessage{
    position: absolute;
}

.upgradeToPaidAccountSubBtn .subscriptionType{
    font-size: var(--text-lg);
    font-weight: bold;
    margin: 0.3em 0em;
}
.upgradeToPaidAccountSubBtn .subscriptionCostWrapper{
    margin: 0.3em 0em;
}
.upgradeToPaidAccountSubBtn .subscriptionCostWrapper .cost{
    font-size: var(--text-xl);
    color: var(--trebble-primary);
    font-weight: bold;

}

.upgradeToPaidAccountSubBtn .subscriptionCostWrapper .duration{
    font-size: var(--text-lg);
    
}

.upgradeToPaidAccountSubBtn .subscriptionCostWrapper .separator{
    font-size: var(--text-lg);
    margin-right: 0.3em;
    margin-left: 0.3em;
    
}
#paidAccountSubsPopupWrapper {
    max-width: 500px;
    margin: auto;
    /*height: fit-content;*/
    height: auto;
    width: calc(100% - 0px);
    
}
#paidAccountSubsPopupWrapper .loading_info_box,
#paidAccountSubsPopupWrapper .error_loading_box{
    text-align: center;
    padding: 30px;
    box-sizing: border-box;
}

.paidAccountSubsPopupContent #headlineWrapper{
    font-size: var(--text-2xl);
    font-weight: 900;
    text-align: left;
    margin: 40px 25px 40px 0px;
}

.upgradeToPaidAccountSubBtn .fineprint{
    font-size: var(--text-sm);
}

.paidAccountSubsPopupContent #footerOptions{
    margin: 20px;
}
.upgradeToPaidAccountSubBtn .promotionMessage{
    position: absolute;
    right: -3px;
    top: -10px;
    /*background: var(--color-purple-500);*/
    background-color: var(--color-purple-500);
    padding: 5px 20px;
    color: white;
}
#settings .upgradeToTrebblePaidSubscriptionBoxWrapper{
    background-color: transparent;
    margin-bottom: 40px;
}

#settings .ui-content{
    padding: 30px;
    box-sizing: border-box;
}

#settings #creatorSubscriptionBillingSection .current_creator_plan_name{
    font-weight: bold;
}

.payment_section .complete_subscription_payment_button,
.payment_section .confirm_change_plan_btn,
#settings #creatorSubscriptionBillingSection #upgradeCreatorPlanBtn,
#settings #creatorSubscriptionBillingSection #reactivateCreatorPlanBtn,
.payment_section .complete_subscription_payment_button.show_outline_only:hover,
.payment_section .confirm_change_plan_btn.show_outline_only:hover,
#settings #creatorSubscriptionBillingSection #upgradeCreatorPlanBtn.show_outline_only:hover,
#settings #creatorSubscriptionBillingSection #reactivateCreatorPlanBtn.show_outline_only:hover{
    background: var(--gradient-primary-alt-reverse);
    background-size: 140%;
    cursor: pointer;
    border: var(--border-3) solid var(--color-black);
    color: white;
    -webkit-text-fill-color: white;
    border-image-source: var(--gradient-primary-alt-reverse);
    border-image-slice: 1;
    animation: gradientanimation 5s ease infinite;
    -moz-animation: gradientanimation 5s ease infinite;
    animation: gradientanimation 5s ease infinite;
    text-transform: uppercase;
    display: block;
}

.payment_section .complete_subscription_payment_button.show_outline_only,
.payment_section .confirm_change_plan_btn.show_outline_only,
#settings #creatorSubscriptionBillingSection #upgradeCreatorPlanBtn.show_outline_only,
#settings #creatorSubscriptionBillingSection #reactivateCreatorPlanBtn.show_outline_only,
.payment_section .complete_subscription_payment_button:hover,
.payment_section .confirm_change_plan_btn:hover,
#settings #creatorSubscriptionBillingSection #upgradeCreatorPlanBtn:hover,
#settings #creatorSubscriptionBillingSection #reactivateCreatorPlanBtn:hover{
    background: var(--gradient-primary-alt);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    background-size: 140%;
    background-color: transparent;
    cursor: pointer;
    border: var(--border-3) solid var(--color-black);
    border-image-source: var(--gradient-primary-alt-reverse);
    border-image-slice: 1;
    text-transform: uppercase;
    display: block;
}
.upgrade_creator_subscription_widget:not([display_payment_error="true"]) #payment-error-message,
.update_payment_method_widget:not([display_payment_error="true"]) #payment-error-message{
    display: none;
}

#payment-error-message{
    padding: 20px;
    max-width: 200px;
    font-size: 85%;
    color: white;
    margin: 20px auto;
}


html body .ui-page-theme-a#settings .settingSection .ui-select > div.ui-btn.ui-btn-b{
    background-color: transparent;
    border-color: transparent;
    color: var(--trebble-primary) !important;
    padding: 10px;
}

.settingSection,#settings .userInfoSection{
    max-width: 500px;
    box-sizing: border-box;
    margin: auto;
    border-bottom: var(--border-1) solid var(--color-black-5);
}
#settings .userInfoSection{
    display: flex;
    flex-direction: row;
    padding: 20px 15px;
    cursor: pointer;
}
#settings .userInfoSection .userInfoDetails{
    padding: 20px 15px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    flex: 1 1 100%;
}

#settings .userInfoSection .userDetailCoverArtWrapper {
    height: 80px;
    width: 80px;
    flex: 0 0 80px;
    border-radius: 50%;
    box-sizing: border-box;
    background-color: var(--color-black-10);
    background-size: cover;
}

#settings .userInfoSection .actionButtonWrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 0 0 auto;
}

#settings .userInfoSection .userInfoDetails{
    flex: 1 1 100%;
}
#settings .userInfoSection .userInfoDetails .userFullname{
    color: var(--trebble-primary);
    font-size: var(--text-base);
}

.settingSection .sectionTitle{
    color: var(--trebble-primary);
    font-size: var(--text-base);
    margin: 20px 0;
}

.settingSection .settingItemTitle{
    box-sizing: border-box;
    padding: 20px 15px;
    color: var(--text-dark);
    cursor: pointer;
}

.settingSection .settingItemTitle.no_vertical_padding{
    padding: 0px 15px;
}
.settingSection .settingItemTitle.small_vertical_padding{
    padding: 10px 15px;
}

#home #settingMenuBtn t{
    font-weight: 600;
}
body.browser.mobileweb .settingSection .settingItemTitle:not(.unclickable):hover,
.settingSection .settingItemTitle:not(.unclickable):active,
body.browser.mobileweb #settings .userInfoSection:hover,
#settings .userInfoSection:active{
    background-color: var(--bg-overlay-dark);
}


.settingSection .settingItemTitle .itemTitle{
   font-size: var(--text-sm);
   color: var(--text-dark);
   white-space: normal;

}
[data-role=page].lighttext.ui-page-active ~ div [data-role=popup] .settingSection .settingItemTitle .itemTitle,
[data-role=page].lighttext.ui-page-active ~ div [data-role=popup] .sequencer_settings .settings_panel_title,
body.darkTheme  div [data-role=popup] .settingSection .settingItemTitle .itemTitle,
body.darkTheme  div [data-role=popup] .sequencer_settings .settings_panel_title,
.c-bottom-sheet.darkTheme.active .settingSection .settingItemTitle .itemTitle,
.c-bottom-sheet.darkTheme.active  .sequencer_settings .settings_panel_title{
    color:  white;
    font-size: var(--text-sm);
}

.settingSection .settingItemTitle.with_input_block{
    display: flex;
    flex-direction: row;
}

.settingSection .settingItemTitle.with_input_block.vertical_align{
    flex-direction: column;
}

.settingSection .settingItemTitle.with_input_block .info_block{
    flex: 1 1 auto;
}

.settingSection .settingItemTitle.with_input_block .info_block.center_vertical_align{
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.settingSection .settingItemTitle.with_input_block .input_block{
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;

}

.settingSection .settingItemTitle.with_input_block .input_block .itemTitle{
    color:  var(--color-gray-500);
}

.settingSection .settingItemTitle.with_input_block .input_block.constains_mulitple_buttons{
    flex-direction: column;
    align-items: flex-end;
}

.settingSection .settingItemTitle.with_input_block .input_block.constains_mulitple_buttons  .transparent_button{
    margin: 5px 0px;
}

.settingSection .settingItemTitle.with_input_block .input_block.constains_mulitple_buttons  .transparent_button#deleteStripeAccountBtn,
.settingSection .settingItemTitle.with_input_block .input_block  .transparent_button.toggled.no_outline{
    border-color: transparent;
}

.settingSection .settingItemTitle.with_input_block .input_block  .transparent_button.toggled.no_outline{
    min-width: auto;
}
.transcription_remaning_container{
    padding: 10px;
    box-sizing: border-box;
    width: 100%;
}


.transcription_remaning_container .transcription_info{
    margin: 10px 0px;
    text-align: right;
    font-weight: bold;
}

.transcription_remaning_container  .progress_bar_container{
    width: 100%;
    height: 5px;
    background: var(--trebble-primary-20);
    position: relative;
}

.transcription_remaning_container  .progress_bar_container .progress_bar_element{
    background: var(--gradient-primary-alt-reverse);
    position: absolute;
    left: 0px;
    height: 100%;
}


body.browser.mobileweb  .settingSection .settingItemTitle.with_input_block .input_block.constains_mulitple_buttons  .transparent_button#deleteStripeAccountBtn:active,
.settingSection .settingItemTitle.with_input_block .input_block.constains_mulitple_buttons  .transparent_button#deleteStripeAccountBtn:hover,
body.browser.mobileweb  .settingSection .settingItemTitle.with_input_block .input_block  .transparent_button.toggled.no_outline:active,
.settingSection .settingItemTitle.with_input_block .input_block  .transparent_button.toggled.no_outline:hover{
    border-color: var(--trebble-primary);
}

.settingSection .settingItemTitle.with_input_block .input_block .switch label{
    padding: 0px;
}

.settingSection .switch label input[type=checkbox]:checked+.lever,
body[appname=trebble] .switch label input[type=checkbox]:checked+.lever{
    background-color: var(--color-red-300);
}
.settingSection .switch label input[type=checkbox]:checked+.lever:after,
body[appname=trebble] .switch label input[type=checkbox]:checked+.lever:after{
    background-color:var(--color-red-600);
}
.settingSection .settingItemTitle .itemDescription,
#settings .userInfoSection .userInfoDetails .viewProlileLabel{
    color: var(--color-gray-500);
    font-size: var(--text-xs);
    
}

.settingSection .transparent_button{
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 100px;
}


.button{
    color: white;
    background-color: var(--trebble-primary);
    font-weight: bold;
    border: var(--border-2) solid var(--trebble-primary);
    cursor: pointer;
}

.settingSection #upgradeNowLnk .itemTitle,
#body[state=content_loaded] div[data-role=header] .ui-controlgroup-controls  #upgradeButton{
    color: var(--success-green-alt);
}

#home:not([state=content_loaded]) [data-role=header] .ui-controlgroup-controls #upgradeButton .label,
#home:not([state=content_loaded]) [data-role=header] .ui-controlgroup-controls #upgradeButton t{
    color: white;
}

#home > div[data-role=header] .ui-controlgroup-controls #upgradeButton{
    font-size: var(--text-base);
    /* border: var(--border-3) solid var(--success-green-alt);*/
    height: 44px;
    margin: 4px;
    box-sizing: border-box;
}

[data-role=header] .ui-controlgroup-controls  #upgradeButton .label{

    font-weight: 900;
    margin: 0px 10px;
    font-size: var(--text-md);
    color: var(--success-green-alt);

}

[data-role=header] .ui-controlgroup-controls  #upgradeButton t{

    font-weight: 600;
    font-size: var(--text-2xl);
    color: var(--success-green-alt);


}

#settings #logOutBtn{
    max-width: 500px;
    margin: 40px auto;
    border-bottom: var(--border-1) solid var(--color-black-5);
    height: 25px;
    border: var(--border-2) solid var(--trebble-primary);
    align-items: center;
    justify-content: center;
    color: white;
    display: flex;
    background-color: var(--trebble-primary);
}

/*body #ExplorePage [data-role=header] #searchBtn t.pe-7s-search{
    font-weight: 600;
    }*/
    body:not(.footerMenuBardisplayed) #ExplorePage [data-role=header] #searchBtn{
        display: none;
    }
    t.pe-7s-search{
        font-weight: 900;
    }

    .choose_background_music_popup_content_wrapper,
    .capsule_list_content_wrapper{
        height: 100%;
        width: 100%;  
    }

    [data-role=popup].chooseCapsuleFromLibrayPopup .choose_background_music_popup_content_wrapper,
    [data-role=popup].chooseMyDrafJourneysPopup .choose_background_music_popup_content_wrapper{
        height: calc(100% - 52px);
        margin-top: 52px; 
    }

    .choose_background_music_popup_content_wrapper{
        max-width: 700px;
    }

    .sound_source_credit_wrapper{
        text-align: center;
        font-size: 85%;
        margin: 8px 0px;
    }


    .capsule_list_content_wrapper{
        margin: auto;
    }

    .capsule_list_content_wrapper .selected_content_wrapper .collection_content_wrapper .capsuleCard{
        display: inline-flex;
    }

    .music_sounds_and_upload_widget:not([is_loading=true]) .loading_info_box{
        display: none;
    }

    .music_sounds_and_upload_widget > .content{
        height: calc(100vh - 100px);
        width: calc(100vw - 20px);
        margin-top: 50px;
        display: flex;
        flex-direction: column;
    }
    .music_sounds_and_upload_widget .trebble_tab_group{
        flex: 0 0 60px;
        height: 60px;
        width: fit-content;
        overflow: auto;
        max-width: 100%;
        margin-left: auto;
        margin-right: auto;
    }

    .music_sounds_and_upload_widget .tab_content{

        /* flex: 0 0 calc(100% - 60px); */
        height: calc(100% - 60px);
        overflow: auto;

    }
    .music_sounds_and_upload_widget .tab_content > section{
        height: 100%;
    }

    .choose_background_music_popup_content_wrapper .content,
    .capsule_list_content_wrapper .content{
        display: flex;
        flex-direction: column;
        height: 100%;
    }
    .choose_background_music_popup_content_wrapper .fixed_section > .ui-select,
    .capsule_list_content_wrapper .fixed_section > .ui-select,
    .capsule_list_content_wrapper.hide_category_list #category_fld,
    .capsule_list_content_wrapper.hide_category_list #ategory_fld-button,
    .capsule_list_content_wrapper.hide_category_list .tag_button_list_wrapper{
        display: none;
    }

    .tab_content .tab_content_item{
        justify-content: center;
    }

    .choose_background_music_popup_content_wrapper .content .fixed_section,
    .capsule_list_content_wrapper .content .fixed_section{
        display: flex;
        flex: 0 0 auto;
        flex-direction: column;
    }

    .trebble_tab_group{
        display: flex;
        flex-direction: row;
        margin-bottom: 10px;
    }

    .trebble_tab_group .trebble_tab{
        display: inline;
        padding: 10px 20px;
        min-width: 60px;
        height: 45px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        border-bottom: var(--border-3) solid transparent;
        cursor: pointer;
        box-sizing: border-box;
    }


    .trebble_tab_group .trebble_tab.selected{
        border-bottom: var(--border-3) solid white;
    }

    .choose_background_music_popup_content_wrapper .content .fixed_section #pageHeaderTitleWrapper{
        position: relative;
    }

    .choose_background_music_popup_content_wrapper .content .selected_content_wrapper{
       flex: 0 0 calc(100% - 70px);
       height: calc(100% - 70px);
       overflow: scroll;
       max-width: 600px;
       width: 100%;
       margin: auto;
   }

   .capsule_list_content_wrapper .content .selected_content_wrapper{
       flex: 0 0 calc(100% - 70px);
       height: calc(100% - 70px);
       overflow: scroll;
       /*max-width: 500px;*/
       width: 100%;
       margin: auto;
   }

   #mylibrary .capsule_list_content_wrapper .content .selected_content_wrapper .capsuleCard{
    margin: 10px;
}


.capsule_list_content_wrapper.hide_category_list .content .selected_content_wrapper{
 flex: 0 0 100%;
 height: 100%;
}

.choose_background_music_popup_content_wrapper .content .search_field_wrapper{
    max-width: calc(100% - 40px);
    margin: auto;
}

.capsule_list_content_wrapper .content .search_field_wrapper{
   max-width: calc(100% - 40px);
   margin: auto;
}


.choose_background_music_popup_content_wrapper .content .background_music_list{
    box-sizing: border-box;
    padding: 20px;
}

.capsule_list_content_wrapper .content .background_music_list{
    box-sizing: border-box;
    padding: 20px;
}


body.mobileweb .scrollable-y::-webkit-scrollbar ,
body.mobileweb .scrollable::-webkit-scrollbar {
    appearance: none;
}

body.mobileweb .scrollable-y::-webkit-scrollbar:vertical,
body.mobileweb .scrollable::-webkit-scrollbar:vertical {
    width: 10px;
}

body.mobileweb .scrollable-y::-webkit-scrollbar:horizontal,
body.mobileweb .scrollable::-webkit-scrollbar:horizontal {
    height: 10px;
}

body.mobileweb .scrollable-y::-webkit-scrollbar-thumb,
body.mobileweb .scrollable::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: var(--border-2) solid white; /* should match background, can't be transparent */
    background-color: var(--color-black-30);
}

body.mobileweb [data-role=popup] .scrollable-y::-webkit-scrollbar,
body.mobileweb [data-role=popup] .scrollable::-webkit-scrollbar {
    appearance: none;
}

body.mobileweb [data-role=popup] .scrollable-y::-webkit-scrollbar:vertical,
body.mobileweb [data-role=popup] .scrollable::-webkit-scrollbar:vertical {
    width: 10px;
    background-color: var(--color-white-1);
}

body.mobileweb [data-role=popup] .scrollable-y::-webkit-scrollbar:horizontal,
body.mobileweb [data-role=popup] .scrollable::-webkit-scrollbar:horizontal {
    height: 10px;
    background-color: var(--color-white-1);
}

body.mobileweb [data-role=popup] .scrollable-y::-webkit-scrollbar-thumb,
body.mobileweb [data-role=popup] .scrollable::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: var(--border-2) solid transparent; /* should match background, can't be transparent */
    background-color: var(--color-white-3);
}


.choose_background_music_popup_content_wrapper:not([userUploadDisplayed=true]) .content .showImportYourOwnMusicButton{
    display: none;
}
.trebble_audio_list_item{
    height: 60px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    box-sizing: border-box;
    padding: 5px;
    margin: 10px auto;
    max-width: 400px;

}

.trebble_audio_list_item .play_audio_btn{
    background-color: var(--color-white-100);
    height: 40px;
    width: 40px;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: var(--color-black);
    margin: auto;
    cursor: pointer;
    z-index: 1;
}

.trebble_audio_list_item.trebble_compilation_audio_list_item:not(.covertArtNotDisplayed) .play_audio_btn{
    background-color: var(--color-black-20);
    height: 100%;
    width: 100%;
    color: white;
    border-radius: 0%;
}

.trebble_audio_list_item.trebble_compilation_audio_list_item.notPlayable .play_audio_btn{
    display: none;
}

.trebble_compilation_audio_list_item .audio_compilation_title,
.trebble_compilation_audio_list_item .audio_compilation_description{
    text-align: start;
}
.trebble_compilation_audio_list_item .audio_compilation_description{
    font-size: 85%;
    opacity: 0.5;
}
.trebble_audio_list_item.is_video.transparent .coverArtWrapper{
    background-size: contain;
    background-position: center;
    height: 68px;
    flex: 1 1 120px;
    margin: auto;
    background-repeat: no-repeat;
    background-color: black;
}

.trebble_audio_list_item.is_video{
    height: 80px;
    max-width: 500px;
}

.trebble_audio_list_item.transparent.is_video .audioInfo{
        width: calc(100% - 260px);
}

.trebble_audio_list_item.is_video .play_audio_btn {
    background-color: transparent;
    color: white;
}

.trebble_audio_list_item.is_video .video_duration{
    position: absolute;
    bottom: 2px;
    left: 2px;
    font-size: 80%;
    background-color: var(--color-black-50);
    padding: 2px;
}

.trebble_audio_list_item.doNotShowArtistName .audioInfo > div.audio_artist{
    display: none;
}
.trebble_audio_list_item .play_audio_btn .playIconWrapper{
    margin-left: 3px;
    margin-top: 3px;

}
.trebble_audio_list_item .play_audio_btn .playIconWrapper,
.trebble_audio_list_item .play_audio_btn .loadingIconWrapper,
.trebble_audio_list_item .play_audio_btn .pauseIconWrapper{
    font-size: var(--text-2xl-px);
}
.trebble_audio_list_item.transparent{
    border: var(--border-1) solid var(--color-white-10);
    /* background-color: var(--color-white-5)!important; */
}

.trebble_audio_list_item.transparent .coverArtWrapper{
    display: flex;
    height: 50px;
    width: 50px;
    flex: 0 0 50px;
    justify-content: center;
    align-items: center;
    position: relative;
}

.trebble_audio_list_item.transparent .coverArtWrapper img{
    height: 100%;
    position: absolute;
    width: 100%;
}

.trebble_audio_list_item.transparent .audioInfo{

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex: 1  1 auto;
    width: calc(100% - 150px);

}
.trebble_audio_list_item.transparent .audioInfo > div{
    white-space: nowrap;
    width: calc(100% - 40px);
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 4px 20px;
}

.trebble_audio_list_item.transparent .audioInfo > div.audio_artist{
    font-size: 85%;
    opacity: 0.7;
}

.trebble_audio_list_item:not(.canBeDeleted) .delete_button,
.trebble_audio_list_item:not(.canBeSelected) .select_button,
.trebble_audio_list_item:not(.canBeSelected) .unselect_button,
.trebble_audio_list_item.canBeSelected[is_selected=true] .select_button,
.trebble_audio_list_item.canBeSelected[is_selected=false] .unselect_button,
.trebble_audio_list_item:not(.canShowMoreOptions) .more_options_button{
    display: none;
}  
.trebble_audio_list_item.transparent .button_bar{

    display: flex;
    flex: 0 0 auto;
    flex-direction: row;
    justify-content: center;
    align-items: center;

}

.trebble_audio_list_item.transparent .button_bar .icon_button{

    font-size: var(--text-2xl-px);
    padding: 0px 10px;
    cursor: pointer;

}

.choose_background_music_popup_content_wrapper .content  .showImportYourOwnMusicButton{

    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    flex: 0 0 50px;
    height: 50px;
    box-sizing: border-box;
    border: var(--border-2) solid;
    max-width: 400px;
    /*margin: 0.5em auto;*/
    margin: auto;
    border: var(--border-0);
    cursor: pointer;
    font-weight: 900;
    text-transform: uppercase;
    background-color: var(--text-light);
    color: var(--text-dark);
}

.showImportYourOwnMusicButton .upload_icon{
    font-size: var(--text-xl-px);
    margin-left: 10px;
    margin-right: 10px;
}

.choose_background_music_popup_content_wrapper:not([isLoading=true]) .loading_info_box,
.choose_background_music_popup_content_wrapper[isLoading=true] .content,
.capsule_list_content_wrapper:not([isLoading=true]) .loading_info_box,
.capsule_list_content_wrapper[isLoading=true] .content{
    display: none;
}
body #background_music_category_fld,
body #background_music_category_fld-button,
body #category_fld-button/*,
body.mobileweb .tag_button_list_wrapper*/{
    display: none !important;
}
#background_music_category_fld-button{
    width: calc(100% - 40px);
    margin: auto;
    box-sizing: border-box;
    max-width: 400px;
    height: 50px;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    display: flex;
    font-weight: bold;
    border: var(--border-0);
    background-color: white;
    color: var(--color-black);

}

[data-role=popup] * #background_music_category_fld-button > span{

    color: var(--color-black);
    font-weight: 900;
    text-transform: uppercase;

}

#background_music_category_fld-button i{
  display: inline-block;
  border-radius: 50%;
  border: var(--border-2) solid var(--color-black);
  display: inline-block;
  width: 20px;
  font-weight: bold;
  font-size: var(--text-sm-px);
  height: 20px;
  align-items: center;
  justify-content: center;
  margin: 0px 10px;
  display: flex;
}

.tag_button_list_wrapper{
    display: flex;
    flex-direction: row;
    flex: 0 0 60px;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 0px 20px;
    box-sizing: border-box;
    margin: auto;
    overflow: auto;
    max-width: 800px;
    justify-content: center;
}

.tag_button_list_wrapper.size_large{
    font-size: var(--text-lg-px);
}

.tag_button_list_wrapper.size_large .list_tag_button{
    height: 50px;
    font-weight: 900;
}

.tag_button_list_wrapper.no_max_width{
    max-width: unset;
}

[data-role=popup] .tag_button_list_wrapper{
    justify-content: start;
    max-width: 450px;
}

.tag_button_list_wrapper .list_tag_button{
    display: flex;
    box-sizing: border-box;
    padding: 0px 20px;
    height: 40px;
    justify-content: center;
    align-items: center;
    background-color: var(--color-gray-100);
    margin: 0px 5px;
    cursor: pointer;
    border-radius: 2px;
    white-space: nowrap;
    font-weight: bold;
    cursor: pointer;
    font-size: 85%;
}

.link_lookalike{
    color: var(--trebble-primary-200);
}

@media (max-width: 500px), (max-height: 500px) and (orientation: landscape){
    .tag_button_list_wrapper .list_tag_button{
        padding: 0px 20px;
    }
    .tag_button_list_wrapper{
     justify-content: start;
 }
}

[data-role=popup] .tag_button_list_wrapper .list_tag_button,
[data-role=page].lighttext .tag_button_list_wrapper .list_tag_button,
body.darkTheme .tag_button_list_wrapper .list_tag_button:not(.selected){
    background-color: var(--color-white-20);
    color:  white;
}

.tag_button_list_wrapper .list_tag_button.selected,
[data-role=page].lighttext .tag_button_list_wrapper .list_tag_button.selected{
    color: var(--trebble-primary);
    background-color: var(--bg-warm);
}
/*
[data-role=popup] .tag_button_list_wrapper .list_tag_button.selected{
   background-color: white;
   color:var(--color-black);
   }*/

   .journeyPage #createNewCapsuleFloatingBtnWrapper{
    border-radius: 50%;
    z-index: 99999;
    background: linear-gradient( 135deg ,#fc466b 25%,var(--trebble-blue) 100%) var(--color-black);
    box-shadow: 0 5px 20px var(--color-black-20);
    background-size: 200% 200%;
    height: 75px;
    width: 75px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    cursor: pointer;
}

.journeyPage #whatsacapsuleinfobtn{
    display: none !important;
}

.journeyPage #createNewCapsuleFloatingBtnWrapper t{
    position: relative;
    font-size: var(--text-6xl-px);
}


.journeyPage.editable[journey_status=DRAFT] #publishButton,
.journeyPage.editable[journey_status=SCHEDULED] #revertToDraftButton,
.journeyPage[journey_status=SCHEDULED] #shareTrebbleBtn,
.journeyPage[journey_status=AVAILABLE] #shareTrebbleBtn,
.journeyPage.editable[journey_status=AVAILABLE] #archiveBtn,
.journeyPage.editable[journey_status=ARCHIVED] #removeFromArchiveButton,
.journeyPage.editable[journey_status=UNKNOWN] #createDraftButton{
    display: flex;
    color: var(--text-light);
    width: auto;
}


.journeyPage:not([schedule_date_warning_should_be_displayed=true]) .schedule_date_message_wrapper,
.journeyPage.editable[journey_status=DRAFT] #editButton,
.journeyPage[journey_status=DRAFT] #revertToDraftButton,
.journeyPage[journey_status=DRAFT] #removeFromArchiveButton,
.journeyPage[journey_status=DRAFT]:not(.publicPage)  #shareTrebbleBtn,
.journeyPage[journey_status=DRAFT] #archiveBtn,
.journeyPage[journey_status=SCHEDULED] #editButton,
.journeyPage[journey_status=SCHEDULED] #archiveBtn,
.journeyPage[journey_status=SCHEDULED] #publishButton,
.journeyPage[journey_status=AVAILABLE] #editButton,
.journeyPage[journey_status=AVAILABLE] #publishButton,
.journeyPage[journey_status=AVAILABLE] #revertToDraftButton,
.journeyPage[journey_status=AVAILABLE] #removeFromArchiveButton,
.journeyPage[journey_status=AVAILABLE] #archiveBtn,
.journeyPage[journey_status=ARCHIVED] #editButton,
.journeyCard[journey_status=ARCHIVED] #followBtn,
.journeyCard[journey_status=ARCHIVED] #buyNowBtn,
.journeyPage[journey_status=ARCHIVED] #publishButton,
.journeyPage[journey_status=ARCHIVED] #revertToDraftButton,
.journeyPage[journey_status=ARCHIVED] #archiveBtn,
.journeyPage[journey_status=ARCHIVED] #menuOptionButton,
.journeyPage[journey_status=ARCHIVED]:not(.publicPage) #shareTrebbleBtn,
.journeyPage[journey_status=DRAFT] #createDraftButton,
.journeyPage[journey_status=SCHEDULED] #createDraftButton,
.journeyPage[journey_status=SCHEDULED] #removeFromArchiveButton,
.journeyPage[journey_status=AVAILABLE] #createDraftButton,
.journeyPage[journey_status=ARCHIVED] #createDraftButton,
.journeyPage[journey_status=UNKNOWN] #editButton,
.journeyPage[journey_status=UNKNOWN] #publishButton,
.journeyPage[journey_status=UNKNOWN] #revertToDraftButton,
.journeyPage[journey_status=UNKNOWN]:not(.publicPage)  #shareTrebbleBtn,
.journeyPage[journey_status=UNKNOWN]:not(.publicPage)  #shareTrebbleBtn,
.journeyPage[journey_status=UNKNOWN] #archiveBtn,
.journeyPage:not([journey_status=DRAFT]) #createNewCapsuleFloatingBtnWrapper,
.journeyPage:not([journey_status=DRAFT]) #previewJourneyButton,
.journeyPage[journey_status=UNKNOWN] #removeFromArchiveButton{
    display: none !important;
}

.journeyPage [data-role=header] #pageHeaderTitleWrapper > span{
    display: none;
}

.journeyPage.playlistDetailsPage:not(.editable).showCapsulesOnly #myCapsulesListWrapper{
    max-width: 900px;
    margin: auto;
}

.journeyPage:not(.editable) #contentWrapper #pageHeaderTitleWrapper,
.journeyPage:not(.editable) #contentWrapper .archivedCapsulesBlock{
    display: none;
}

.journeyPage #contentWrapper #pageHeaderTitleWrapper {
    overflow: visible;
}

.journeyPage #contentWrapper #pageHeaderTitleWrapper > span{
    justify-content: center;
    margin: auto;
    white-space: normal;
    line-height: 1em;
    margin: 10px auto;
}

.journeyPage #contentWrapper #pageHeaderTitleWrapper > span.journey_title{
    white-space: normal;
    line-height: 1em;
    display: inline-block; 
}

.journeyPage .listeningInstructionsBox{
    display: none;
}

.journeyPage .journey_status_tag,
.journeyCard .journey_status_tag{

    font-size: var(--text-2xs-px);
    background-color: var(--color-warning-400);
    padding: 4px;
    color:black;
    border-radius: 2px;
    margin: 0px 10px;
    text-transform: uppercase;
    width: fit-content;
    font-weight: bold;

}

[data-role=page]:not(#myJourneys) .journeyCard .journey_status_tag{
    display: none;
}

html .ui-page-theme-a#myJourneys .playlistItem.withLargeAlbumArt.journeyCard.smallcardsize.doNotShowSubscribeButton[show_journey_status_tag=true], html .ui-page-theme-c .playlistItem.withLargeAlbumArt.journeyCard.smallcardsize.doNotShowSubscribeButton[show_journey_status_tag=true],
html .ui-page-theme-a#myJourneys .playlistItem.withLargeAlbumArt.journeyCard.rectangularImage.smallcardsize.doNotShowSubscribeButton[show_journey_status_tag=true], html .ui-page-theme-c .playlistItem.withLargeAlbumArt.journeyCard.rectangularImage.smallcardsize.doNotShowSubscribeButton[show_journey_status_tag=true]{
    height:180px;

}

#home[role="presentation"]{
    background-color: transparent;
    padding: 0px;
}

/*** DARK THEME START ****/
html body.white-theme.darkTheme .ui-overlay-a, 
html body.white-theme.darkTheme .ui-page-theme-a[data-role=page]:not(.lighttext), 
html body.white-theme.darkTheme .ui-page-theme-a[data-role=page]:not(.lighttext) .ui-panel-wrapper,
body.white-theme.darkTheme #globalSearchPageHeader, 
body.white-theme.darkTheme #myLibraryHeader, 
body.white-theme.darkTheme #mySubscriptionsHeader, 
body.white-theme.darkTheme #myJourneysHeader, 
body.white-theme.darkTheme div[data-role=page]:not(.lighttext):not(#featurePage):not(#ExplorePage):not(#Onboarding) div.ui-header[data-role=header],
body.darkTheme [data-role=page]:not(.lighttext) .ui-content,
body.darkTheme [data-role=page]:not(.lighttext) .listHeader,
body.darkTheme [data-role=page]:not(.lighttext) .infiniteListInnerContainer{
    background-color:var(--color-black)!important;
    border:transparent;
    color: white;
}

body.darkTheme [data-role=page]:not(.lighttext) .songLibraryWrapper{
    background-color:var(--color-black)!important;
    color: white;
}

body.darkTheme [data-role=page]:not(.lighttext) .listHeader{
    color: var(--color-gray-300);
}

body.white-theme.darkTheme div.ui-header[data-role=header]~.ui-panel-wrapper>.ui-content>[data-role=tabs]>[data-role=navbar], 
body.white-theme.darkTheme div.ui-header[data-role=header]~.ui-panel-wrapper>.ui-content>[data-role=tabs]>[data-role=navbar]>ul>li>a{
    background-color:var(--color-black)!important;
    border:transparent;
}

body.darkTheme #addToTrebbleOptionButtuon,
body.darkTheme #gradeOptionButtuon,
body.darkTheme .showCommentBtnWrapper #showCommentOptionButton,
body.darkTheme .showInfoBtnWrapper #showInfoOptionButton,
body.darkTheme #songCapsuleOptionButtuon,
body.darkTheme #songLibraryOptionButtuon{
    color: white !important;
}

body.darkTheme #home[state=content_loaded] #onYourFeedTodayBoxWrapper[state=content_loaded],
body.darkTheme #home[state=content_loaded] #homeHeader #pageHeaderTitleWrapper,
body.darkTheme #home[state=loading] #homeHeader #pageHeaderTitleWrapper{
    color: white;
}

body.darkTheme .outline_stats_table .outline_stats_header_row,
body.darkTheme .specific_journey_stats_summary{
    background-color: var(--color-white-5);
}

/*body.darkTheme .sequencer_node_inspector .basic_inpector_param_view.audio_segment_param_view .action_btn_bar button{
    background-color: var(--color-white-20);
}*/

body.darkTheme .outline_stats_table{
    border: var(--border-1) solid var(--color-white-5);
}
body.darkTheme .outline_stats_table .outline_stats .day_number, body.darkTheme  .outline_stats_table .outline_stats_header_row .day_number,
body.darkTheme .outline_stats_table .outline_stats .shortcast_title, body.darkTheme  .outline_stats_table .outline_stats_header_row .shortcast_title{
    border-right: var(--border-1) solid var(--color-white-5);
}

body.darkTheme .outline_stats_table .outline_stats , body.darkTheme  .outline_stats_table .outline_stats_header_row {
    border-bottom: var(--border-1) solid var(--color-white-5);
}

body.darkTheme #home #onYourFeedTodayBoxWrapper .play_feed_btn,
body.darkTheme #home #onYourFeedTodayBoxWrapper .start_category_shortcast_radio_btn{
    box-shadow: 0 5px 20px var(--color-white-20);
}

body.white-theme.darkTheme input.customSearchField[type=search], body.white-theme.darkTheme input.shareableLink,
body.darkTheme [data-role=page] * .songInfo * .itemSubtitle,
body.darkTheme .songArtistLibrary{
    color:var(--color-white-70);
}
body.white-theme.darkTheme input.customSearchField[type=search]:focus, body.white-theme.darkTheme input.shareableLink:focus,
body.darkTheme.notificationTitle.itemTitle,body.darkTheme [data-role=page] * .songInfo * .itemTitle,
body.darkTheme .songTitleLibrary{
    color:white;
}


body.darkTheme.mobileweb .scrollable-y::-webkit-scrollbar {
    appearance: none;
}

body.darkTheme.mobileweb .scrollable-y::-webkit-scrollbar:vertical {
    width: 11px;
    background-color: var(--color-white-1);
}

body.darkTheme.mobileweb .scrollable-y::-webkit-scrollbar:horizontal {
    height: 11px;
    background-color: var(--color-white-1);
}

body.darkTheme.mobileweb  .scrollable-y::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: var(--border-2) solid transparent; /* should match background, can't be transparent */
    background-color: var(--color-white-3);
}



body.white-theme.darkTheme div[data-role=page]:not(.lighttext) [data-role=header] * #deleteButton,
body.white-theme.darkTheme div[data-role=page]:not(.lighttext) [data-role=header] * #editButton,
body.white-theme.darkTheme div[data-role=page]:not(.lighttext) [data-role=header] * #filterButton,
body.white-theme.darkTheme div[data-role=page]:not(.lighttext) [data-role=header] * #loadingIndicatorBtn,
body.white-theme.darkTheme div[data-role=page]:not(.lighttext) [data-role=header] * #loadingIndicatorBtn:active,
body.white-theme.darkTheme div[data-role=page]:not(.lighttext) [data-role=header] * #loadingIndicatorBtn:hover,
body.white-theme.darkTheme div[data-role=page]:not(.lighttext) [data-role=header] * #nextButton.tag.actionButton,
body.white-theme.darkTheme div[data-role=page]:not(.lighttext) [data-role=header] * #saveButtonIcon, 
body.white-theme.darkTheme div[data-role=page]:not(.lighttext) [data-role=header] * #showRecordSectionButton.tag.actionButton, 
body.white-theme.darkTheme div[data-role=page]:not(.lighttext) [data-role=header] * #showUploadSectionButton.tag.actionButton, 
body.white-theme.darkTheme div[data-role=page]:not(.lighttext):not(#player) #header-back-button,
body.white-theme.darkTheme div[data-role=page]:not(.lighttext):not(#player) #header-queue-button, 
body.white-theme.darkTheme div[data-role=page]:not(.lighttext):not(#player) [data-role=header] * #menuOptionButton{
    color:white;
}
body.darkTheme .homePanel * .loggedInUserInfoBox,
body.darkTheme .homePanel * .loggedInUserInfoBox>.userCoverArtWrapper>.userInfo>.username.itemTitle,
body.darkTheme .homePanel,
body.darkTheme .homePanel * .menuBox,
body.darkTheme .homePanel .settingMenuBox{
    background-color:var(--color-black);
    color:white;
}

body.darkTheme .formLabel,
body.darkTheme label.formLabel {
    font-weight: 700;
    color: white;
}

body.darkTheme .userListItem,
body.darkTheme #settings .userInfoSection,
body.darkTheme .settingSection,
body.darkTheme .listHeader,
body.darkTheme .songLibraryWrapper,
body.darkTheme [data-role=page]:not(.lighttext) .songLibraryWrapper{
    border-bottom: var(--border-1) solid var(--color-white-5);
}

/*
#onYourFeedTodayBoxWrapper{
    display: none !important;
}
*/

#onYourFeedTodayBoxWrapper[state=loading] > div:not(.loading_info_box),
#paidAccountSubsPopupWrapper[state=loading] > div:not(.loading_info_box),
#onYourFeedTodayBoxWrapper[state=error] > div:not(.error_loading_box),
#paidAccountSubsPopupWrapper[state=error] > div:not(.error_loading_box),
#onYourFeedTodayBoxWrapper[state=no_new_content] > div:not(.all_caught_up_box),
#onYourFeedTodayBoxWrapper[state=content_loaded] > div:not(.feedOverviewBox),
#paidAccountSubsPopupWrapper[state=content_loaded] > div:not(.paidAccountSubsPopupContent){
    display: none;
}

#onYourFeedTodayBoxWrapper[state=loading] > div.loading_info_box,
#paidAccountSubsPopupWrapper[state=loading] > div.loading_info_box,
#onYourFeedTodayBoxWrapper[state=error] > div.error_loading_box,
#paidAccountSubsPopupWrapper[state=error] > div.error_loading_box,
#onYourFeedTodayBoxWrapper[state=no_new_content] > div.all_caught_up_box,
#onYourFeedTodayBoxWrapper[state=content_loaded] > div.feedOverviewBox,
#paidAccountSubsPopupWrapper[state=content_loaded] > div.paidAccountSubsPopupContent{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    text-align: center;
}
#payment-error-message,
#onYourFeedTodayBoxWrapper[state=error],
#paidAccountSubsPopupWrapper[state=error]{
    background: var(--gradient-error);
    color: white;
    background-size: 200% 200%;
    animation: gradientanimation 30s ease infinite;
    -moz-animation: gradientanimation 30s ease infinite;
    animation: gradientanimation 30s ease infinite;
}

#onYourFeedTodayBoxWrapper[state=content_loaded]{
    background: var(--gradient-primary);
    color: white;
    background-size: 400% 400%;
    animation: gradientanimation 30s ease infinite;
    -moz-animation: gradientanimation 30s ease infinite;
    animation: gradientanimation 30s ease infinite;
}

#Onboarding #playMyFeedForFirstTimeButton{
    align-items: center;
    height: 45px;
    font-size: var(--text-base-px);
    padding: 0px 30px;
    min-width: 200px;
    font-weight: 700;
    box-sizing: border-box;
    justify-content: center;
    display: flex;
    background: var(--gradient-primary);
    border: var(--border-0);
    color: var(--text-light);
    background-size: 300% 300%;
    animation: gradientanimation 30s ease infinite;
    animation: gradientanimation 30s ease infinite;
}

#Onboarding #playMyFeedForFirstTimeButton.disabled,
body.mobileweb #Onboarding #playMyFeedForFirstTimeButton.disabled:hover,
#Onboarding #playMyFeedForFirstTimeButton.disabled:active{
    /*background: transparent;
    color: var(--color-gray-300);
    border: var(--border-2) solid var(--color-gray-300);*/
    background: var(--color-gray-900);
    color: black !important;
    border: var(--border-2) solid var(--color-gray-900);
    cursor: default;
}

#Onboarding  #playMyFeedForFirstTimeButtonWrapper{

    position:absolute;
    bottom: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
    width: 100%;
    box-sizing: border-box;
    z-index: 9999;
    backdrop-filter: blur(20px);
    background: linear-gradient(0deg, var(--color-black-100) 0%, var(--color-black-80) 50%, var(--color-black-0) 100%);
}

@supports not (backdrop-filter: blur(20px)) {
 #Onboarding  #playMyFeedForFirstTimeButtonWrapper{
    background: linear-gradient(0deg, var(--color-black-100) 0%, var(--color-black-80) 50%, var(--color-black-30) 100%);
}
}


#Onboarding.showNoContent .ui-userWelcomeMessageBoxWrapper ,
#Onboarding.showNoContent .ui-header ,
#Onboarding.showNoContent #playMyFeedForFirstTimeButtonWrapper,
#Onboarding.showNoContent .ui-content,
#Onboarding.showNoContent .ui-panel-wrapper,

#Onboarding.showWelcomeMessage .ui-header ,
#Onboarding.showWelcomeMessage #playMyFeedForFirstTimeButtonWrapper,
#Onboarding.showWelcomeMessage .ui-content,
#Onboarding.showWelcomeMessage .ui-panel-wrapper{
 display:none;
}

#Onboarding.showWelcomeMessage #userWelcomeMessageBoxWrapper{ 
    height: 800px;
    width: 100%;
    align-items: center;
    display: flex;
    min-height: 100%;
    flex: 0 0 100%;
    justify-content: center;
    flex-direction: column;
    max-width: 500px;
    margin: auto;
}

#Onboarding.showWelcomeMessage #userWelcomeMessageBoxWrapper .userWelcomeMessage{

    font-size: var(--text-3xl-px);
    font-weight: 900;
    text-align: left;
    padding: 40px;
    box-sizing: border-box;

}

#Onboarding.showWelcomeMessage #userWelcomeMessageBoxWrapper .userWelcomeSmallMessage{

    font-size: var(--text-md-px);
    font-weight: 400;
    text-align: left;
    padding: 40px;
    box-sizing: border-box;

}
#Onboarding.showWelcomeMessage #userWelcomeMessageBoxWrapper .buttonWrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc(100% - 100px);
    margin-top: 40px;
}

#Onboarding #startSetupBtn{
 color: white;
 border: var(--border-0);
 font-size: var(--text-lg-px);
 display: flex;
 background: var(--trebble-gradient-pink);
 text-align: center;
 height: 50px;
 justify-content: center;
 align-items: center;
 width: calc(100% - 100px);
 flex: 0 0 100%;
 box-sizing: border-box;
 margin: auto;
}
#Onboarding:not(.showWelcomeMessage) #userWelcomeMessageBoxWrapper{
 display:none;
}

#Onboarding  #guideMessageBox{
  padding: 20px;
  text-align: left;
  color: var(--text-light);
  margin: 0px auto;
  margin-bottom: 10px;
  max-width: 250px;
  text-align: center;
  font-weight: bold;
  background-color: var(--color-white-5);
  box-sizing: border-box;
  min-height: 80px;
}

#onYourFeedTodayBoxWrapper[state=loading]{
    background-color: white;
    color: var(--text-dark);
    box-shadow: 2px 0 12px 0 var(--shadow-color-light);
}

#onYourFeedTodayBoxWrapper[state=no_new_content]{
    background: linear-gradient(to bottom right,var(--success-green),var(--success-green-alt));
    color: white;
    background-size: 200% 200%;
    animation: gradientanimation 30s ease infinite;
    -moz-animation: gradientanimation 30s ease infinite;
    animation: gradientanimation 30s ease infinite;
}
#onYourFeedTodayBoxWrapper[state=content_loaded] > div.feedOverviewBox:not(.center){
    align-items: flex-start;
}

#onYourFeedTodayBoxWrapper .closeButton{
    position: absolute;
    top: 0;
    right: 5px;
    display: block !important;
    cursor: pointer;
}

#onYourFeedTodayBoxWrapper .closeButton i{
    font-size: var(--text-2xl-px);
}

.feedOverviewBox.center .feed_description{
    font-size: var(--text-base-px);
    text-align: center;
}

#onYourFeedTodayBoxWrapper .play_feed_btn{
    display: flex;
    border: var(--border-2) solid white;
    width: calc(100% - 4px);
    margin: 15px 0px;
    flex-direction: row;
    background-color: white;
    color: var(--trebble-primary);
    font-weight: 900;
}

body.browser.mobileweb #onYourFeedTodayBoxWrapper .play_feed_btn:hover,
#onYourFeedTodayBoxWrapper .play_feed_btn:active{
    background-color: transparent;
    color: white;

}

.play_feed_btn > .playback_state_btn{

    display: flex;
    justify-content: center;
    align-items: center;
    height: 45px;
    width: 100%;
}

/*
#onYourFeedTodayBoxWrapper[state=error] > div.error_loading_box,
#onYourFeedTodayBoxWrapper[state=no_new_content] .all_caught_up_box{

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
*/

#onYourFeedTodayBoxWrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 370px;
    margin: 14px auto 30px auto;
    box-sizing: border-box;
    padding: 30px;
    box-shadow: 0 5px 20px var(--color-black-20);
    position: relative;

}

#onYourFeedTodayBoxWrapper .error_loading_box .error_icon,
#paidAccountSubsPopupWrapper .error_loading_box .error_icon,
#onYourFeedTodayBoxWrapper .all_caught_up_box .caught_up_icon{

    font-size: var(--text-7xl-px);
    margin: 10px 0px;

}

#onYourFeedTodayBoxWrapper .error_loading_box .error_message,
#onYourFeedTodayBoxWrapper .loading_info_box .message,
#paidAccountSubsPopupWrapper .error_loading_box .error_message,
#paidAccountSubsPopupWrapper .loading_info_box .message,
#onYourFeedTodayBoxWrapper .all_caught_up_box .description{
    font-size: var(--text-base-px);
}

#onYourFeedTodayBoxWrapper .loading_info_box .message{
    opacity: 0.5;
    margin: 10px 0px;
    font-size: var(--text-sm-px);
}

#onYourFeedTodayBoxWrapper .title{
    font-weight: 900;
    font-size: var(--text-lg);
    padding: 15px 0px;
    max-width: 500px;
    margin: 0px 20px;

}

#home[state=content_loaded] #onYourFeedTodayBoxWrapper .title{
    margin: 0px 45px;
    background: var(--gradient-primary-alt);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    background-size: 200% 200%;
    animation: gradientanimation 10s ease infinite;
    animation: gradientanimation 10s ease infinite;
}

#play_feed_btn > span > t{
    margin: 0px 10px;
    font-size: var(--text-xl-px);

}

.feedOverviewBox .text_summary{
    padding-left: 20px;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    /* autoprefixer: ignore next */
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    font-size: var(--text-sm-px);
    overflow: hidden;
    
}

.feedOverviewBox  .feed_description{
    font-size: var(--text-sm-px);
}
#onYourFeedTodayBoxWrapper .all_caught_up_box .description,
#onYourFeedTodayBoxWrapper .error_loading_box .error_message{
    text-align: center;
    padding: 0px 40px;
    color: white;
}

#onYourFeedTodayBoxWrapper .trebble_thumnails_preview_box{
    display: block;
    overflow: hidden;
    height: 45px;
    flex: 0 0 45px;
    margin: 10px 0px;
    width: 100%;

}

#onYourFeedTodayBoxWrapper .trebble_thumnails_preview_box.scroll{

    white-space: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
}

#onYourFeedTodayBoxWrapper .trebble_thumnails_preview_box img{
    border-radius: 50%;
    margin: 0 3px;
    width: 40px;
    height: 40px;
    object-fit: cover;
    display: inline-block;
    border: var(--border-2) solid var(--text-light);
}

.userReferralPage .infoBoxWrapper > .content> .actionButtonWrapper > a.actionButton{
    padding: 15px;
    height: 36px;
    flex-direction: column;
    width: auto;
    min-width: 50px;
}

.userReferralPage .infoBoxWrapper > .content> .actionButtonWrapper > a.actionButton > .label{
    font-size: 80%;
}

.userReferralPage .infoBoxWrapper > .content > div.link_to_share{

    max-width: calc(100% - 40px);
    color: var(--color-gray-850);
    margin: auto;
    overflow: hidden;
    text-overflow: ellipsis;

}


.feed_description .text_summary_item{
    display: flex;
    flex-direction: row;
}

#paidAccountSubsPopup{
    height: auto;
    padding: 25px;
    box-sizing: border-box;
}
#paidAccountSubsPopup.coloredBackground{
    color: var(--text-dark);
    background-color: var(--color-gray-100);
}

#paidAccountSubsPopup.coloredBackground #closeBtn{
    color: var(--text-dark);
}

#paidAccountSubsPopup.coloredBackground .upgradeToPaidAccountSubBtn{
    color: var(--text-dark);
    background-color: white;
    border: var(--border-3) solid var(--text-dark);
}


@-webkit-keyframes gradientanimation {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}
@-moz-keyframes gradientanimation {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}
@keyframes gradientanimation {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}


/*** DARK THEME END ****/




/********* ANT DESIGN UI CUSTOM CSS *****/


.ant-notification  .ant-notification-notice-wrapper .trebble-custom-react-notification  .ant-notification-notice-with-icon .ant-notification-notice-description {
    margin-inline-end: 36px;
}