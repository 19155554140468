@font-face {
	font-family: 'icomoon';
	src:url('fonts/icomoon.eot?-fkwl1b');
	src:url('fonts/icomoon.eot?#iefix-fkwl1b') format('embedded-opentype'),
		url('fonts/icomoon.ttf?-fkwl1b') format('truetype'),
		url('fonts/icomoon.woff?-fkwl1b') format('woff'),
		url('fonts/icomoon.svg?-fkwl1b#icomoon') format('svg');
	font-weight: normal;
	font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
	font-family: 'icomoon';
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
.icon-trebble-icon-2ton{
}

.icon-trebble-icon-2ton .path1:before {
	content: "\e60b";
	
}
.icon-trebble-icon-2ton .path2:before {
	content: "\e60c";
	margin-left: -1em;

}
.icon-trebble-icon-2ton .path3:before {
	content: "\e60d";
	margin-left: -1em;
}
.icon-trebble-icon-2ton .path4:before {
	content: "\e60e";
	margin-left: -1em;
}
.icon-trebble-icon-2ton .path5:before {
	content: "\e60f";
	margin-left: -1em;
	/*color: rgb(48, 48, 48);*/
	opacity: 0.2;
}
.icon-trebble-icon-2ton .path6:before {
	content: "\e610";
	margin-left: -1em;
	/*color: rgb(48, 48, 48);*/
	opacity: 0.2;
}
.icon-trebble-icon-2ton .path7:before {
	content: "\e611";
	margin-left: -1em;
	/*color: rgb(48, 48, 48);*/
	opacity: 0.2;
}
.icon-trebble-icon-2ton .path8:before {
	content: "\e612";
	margin-left: -1em;
	/*color: rgb(48, 48, 48);*/
	opacity: 0.2;
}
.icon-trebble-icon-2ton .path9:before {
	content: "\e613";
	margin-left: -1em;
	/*color: rgb(48, 48, 48);*/
	opacity: 0.2;
}
.icon-trebble-icon-2ton .path10:before {
	content: "\e614";
	margin-left: -1em;
	/*color: rgb(48, 48, 48);*/
	opacity: 0.2;
}
.icon-trebble-icon-2ton .path11:before {
	content: "\e615";
	margin-left: -1em;
	/*color: rgb(48, 48, 48);*/
	opacity: 0.2;
}
.icon-trebble-icon-1ton .path1:before {
	content: "\e600";
	color: rgb(68, 68, 68);
}
.icon-trebble-icon-1ton .path2:before {
	content: "\e601";
	margin-left: -1em;
	color: rgb(68, 68, 68);
}
.icon-trebble-icon-1ton .path3:before {
	content: "\e602";
	margin-left: -1em;
	color: rgb(68, 68, 68);
}
.icon-trebble-icon-1ton .path4:before {
	content: "\e603";
	margin-left: -1em;
	color: rgb(68, 68, 68);
}
.icon-trebble-icon-1ton .path5:before {
	content: "\e604";
	margin-left: -1em;
	color: rgb(68, 68, 68);
}
.icon-trebble-icon-1ton .path6:before {
	content: "\e605";
	margin-left: -1em;
	color: rgb(68, 68, 68);
}
.icon-trebble-icon-1ton .path7:before {
	content: "\e606";
	margin-left: -1em;
	color: rgb(68, 68, 68);
}
.icon-trebble-icon-1ton .path8:before {
	content: "\e607";
	margin-left: -1em;
	color: rgb(68, 68, 68);
}
.icon-trebble-icon-1ton .path9:before {
	content: "\e608";
	margin-left: -1em;
	color: rgb(68, 68, 68);
}
.icon-trebble-icon-1ton .path10:before {
	content: "\e609";
	margin-left: -1em;
	color: rgb(68, 68, 68);
}
.icon-trebble-icon-1ton .path11:before {
	content: "\e60a";
	margin-left: -1em;
	color: rgb(68, 68, 68);
}